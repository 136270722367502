<template>
  <div class="row">
    <div class="col-md-12">
      <h5 class="px-5 mb-15">Campaign Duration</h5>

      <div class="d-flex flex-column gap-3">
        <div class="d-flex gap-3">
          <label class="form-control-label">Start</label>
          <date-picker
            type="date"
            value-type="format"
            format="YYYY-MM-DD"
            v-model="start_date"
            :disabled="disabled"
            :confirm="true"
            :clearable="false"
            placeholder="Select date"
            :disabled-date="disabledBeforeToday"
            @confirm="update('start_date')"
          />

          <label class="form-control-label">No. days per period</label>
          <input
            min="1"
            max="365"
            type="number"
            :disabled="disabled"
            class="form-control w-100"
            v-model.number="days_per_period"
            @change="update('days_per_period')"
          />

          <label class="form-control-label">No. period</label>
          <select
            :disabled="disabled"
            v-model.number="no_period"
            class="form-control w-100 mr-10"
            @change="update('no_period')"
          >
            <option value="4">4</option>
            <option value="8">8</option>
            <option value="12">12</option>
          </select>
        </div>

        <div class="d-flex">
          <label class="form-control-label">
            End Date: {{ endDateString }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { weeklyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      no_period: 4,
      end_date: null,
      start_date: null,
      days_per_period: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },

    endDateString() {
      const { end_date } = this.campaign;

      const d = moment(end_date);

      return d.isValid() ? d.format("YYYY-MM-DD") : "-";
    },
  },

  mounted() {
    this.oldValue = Object.assign({}, this.campaign);
  },

  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < moment(today).add(1, "day");
    },

    async update(name) {
      try {
        const form = {};
        form[name] = this[name];
        const { id } = this.campaign;

        await weeklyLoginService.updateCampaignInfo(id, form);

        this.oldValue = Object.assign({}, this.campaign, { ...form });

        this.$emit("updated");
        this.$notify(`Campaign duration updated.`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Campaign duration update error.`);
      }
    },
  },

  mounted() {
    const { start_date, end_date, no_period, days_per_period } = this.campaign;

    this.end_date = end_date;
    this.no_period = no_period;
    this.start_date = start_date;
    this.days_per_period = days_per_period;
  },
};
</script>

<style></style>

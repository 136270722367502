import qs from "qs";
import HttpRequest from "./request";

export class LuckyLoginProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/luckylogin`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async createCampaign(body) {
    const { data } = await this.create("/", body);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async updateCampaignInfo(campaignId, body) {
    const { data } = await this.update(`/${campaignId}`, body);
    return data;
  }

  async uploadCampaignImage(campaignId, body) {
    const { data } = await this.upload(`/${campaignId}/upload`, body);
    return data;
  }

  async getLuckyNumbers(campaignId) {
    const { data } = await this.get(`/${campaignId}/luckynumber`);
    return data;
  }

  async getLuckyNumberById(campaignId, id) {
    const { data } = await this.get(`/${campaignId}/luckynumber/${id}`);
    return data;
  }

  async addLuckyNumber(campaignId) {
    const { data } = await this.create(`/${campaignId}/luckynumber`);
    return data;
  }

  async bulkLuckyNumber(campaignId, body) {
    const { data } = await this.upload(
      `/${campaignId}/luckynumber/upload`,
      body
    );
    return data;
  }

  async updateLuckyNumber(campaignId, id, body) {
    const { data } = await this.update(
      `/${campaignId}/luckynumber/${id}`,
      body
    );
    return data;
  }

  async removeLuckyNumber(campaignId, id) {
    return await this.delete(`/${campaignId}/luckynumber/${id}`);
  }

  async uploadLuckyNumberPicture(campaignId, id, body) {
    const { data } = await this.upload(
      `/${campaignId}/luckynumber/${id}/upload`,
      body
    );
    return data;
  }

  async getTaskById(campaignId, taskId) {
    const { data } = await this.get(`/${campaignId}/task/${taskId}`);
    return data;
  }

  async updateTaskInfo(campaignId, taskId, body) {
    const { data } = await this.update(`/${campaignId}/task/${taskId}`, body);
    return data;
  }

  async uploadTaskImage(campaignId, taskId, body) {
    const { data } = await this.upload(
      `/${campaignId}/task/${taskId}/upload`,
      body
    );
    return data;
  }

  async addTask(campaignId) {
    const { data } = await this.create(`/${campaignId}/task`);
    return data;
  }

  async removeTask(campaignId, taskId) {
    return await this.delete(`/${campaignId}/task/${taskId}`);
  }

  async getChoices(campaignId, taskId) {
    const { data } = await this.get(`/${campaignId}/task/${taskId}/choice`);
    return data;
  }

  async addChoice(campaignId, taskId) {
    return await this.create(`/${campaignId}/task/${taskId}/choice`);
  }

  async updateChoice(campaignId, taskId, id, body) {
    const { data } = await this.update(
      `/${campaignId}/task/${taskId}/choice/${id}`,
      body
    );
    return data;
  }

  async removeChoice(campaignId, taskId, id) {
    return await this.delete(`/${campaignId}/task/${taskId}/choice/${id}`);
  }

  async getWinnerList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/winner${queryString}`);
    return data;
  }

  async getWinnerById(id) {
    const { data } = await this.get(`/winner/${id}`);
    return data;
  }

  async downloadWinner(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(`/winner/download${queryString}`, {
      responseType: "blob",
    });
  }

  async getReviewList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/review${queryString}`);
    return data;
  }

  async downloadReview(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(`/review/download${queryString}`, {
      responseType: "blob",
    });
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-modal" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "panel panel-bordered mb-0" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c("cropper", {
                staticClass: "cropper",
                attrs: {
                  src: _vm.image,
                  "stencil-props": {
                    aspectRatio: 1 / 1,
                  },
                },
                on: { change: _vm.change },
              }),
              _c("div", { staticClass: "action-modal mx-10 my-15" }, [
                _c(
                  "label",
                  {
                    staticClass: "btn btn-link btn-block",
                    attrs: { for: "file-upload" },
                  },
                  [
                    _c("input", {
                      ref: "file",
                      staticClass: "btn btn-round btn-block btn-default",
                      attrs: {
                        id: "file-upload",
                        type: "file",
                        accept: "image/gif, image/jpeg, image/png",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.uploadImage($event)
                        },
                      },
                    }),
                    _c("span", [_vm._v("Browse file")]),
                  ]
                ),
                _vm.errorFileUpload
                  ? _c("p", { staticClass: "text-danger text-center" }, [
                      _vm._v(" " + _vm._s(_vm.errorFileUpload) + " "),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "panel-footer" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-round btn-default mx-10",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-round btn-primary",
                  attrs: { type: "button", disabled: !_vm.croped },
                  on: { click: _vm.submit },
                },
                [_vm._v(" " + _vm._s(_vm.submitButtonText || "Upload") + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Upload")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <h5 class="col-md-12">Lucky Login Numbers</h5>
    <div class="form-group">
      <label
        for="bulk_upload_lucky_number"
        class="btn btn-default mx-10"
        :class="{ disabled: !canEdit }"
      >
        <i class="icon md-upload" aria-hidden="true"></i> Bulk upload
      </label>
      <input
        type="file"
        v-if="canEdit"
        class="invisible"
        @change="onFileSelected"
        id="bulk_upload_lucky_number"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </div>
    <a
      target="_blank"
      :href="sampleTaskFileUploadUri"
      class="btn btn-link btn-round"
    >
      <i class="fa fa-file-excel-o"></i>
      Click to download template
    </a>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

export default {
  name: "BulkUploadLuckyNumber",

  props: ["campaignId", "campaign"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },

    sampleTaskFileUploadUri() {
      return process.env.VUE_APP_SAMPLE_TASK_FILE_UPLOAD_URI;
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];

      if (!this.file) return;
      event.target.value = null;

      const options = {
        title: "Bulk upload",
        okLabel: "ลบรูปทั้งหมด",
        cancelLabel: "ไม่ลบรูป",
      };

      this.$dialogs
        .confirm("ต้องการให้ล้างค่ารูปหรือไม่ ?", options)
        .then((res) => {
          if (res.ok != undefined) {
            this.upload(res.ok ? 0 : 1);
          }
        });
    },

    async upload(keep_picture) {
      try {
        if (!this.file) return;

        const form = new FormData();

        form.append("excel", this.file);
        form.append("keep_picture", +keep_picture);

        await luckyLoginService.bulkLuckyNumber(this.campaignId, form);

        this.$emit("uploaded");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Bulk upload error.`);
      }
    },
  },
};
</script>

<style scoped>
[type="file"] {
  width: 50px;
}
</style>

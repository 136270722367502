var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("table", { staticClass: "table" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", { attrs: { "width-150px": "" } }, [
            _vm._v("End of Campaign Notification"),
          ]),
          _c("td", [
            _c("div", { staticClass: "col-md-8" }, [
              _c("label", { staticClass: "switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lot.end_notification,
                      expression: "lot.end_notification",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "true-value": 1,
                    "false-value": 0,
                    disabled: !_vm.canSwitchOnOff,
                  },
                  domProps: {
                    checked: Array.isArray(_vm.lot.end_notification)
                      ? _vm._i(_vm.lot.end_notification, null) > -1
                      : _vm._q(_vm.lot.end_notification, 1),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.lot.end_notification,
                          $$el = $event.target,
                          $$c = $$el.checked ? 1 : 0
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.lot,
                                "end_notification",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.lot,
                                "end_notification",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.lot, "end_notification", $$c)
                        }
                      },
                      _vm.update,
                    ],
                  },
                }),
                _c("span", { staticClass: "slider round" }),
              ]),
              _c("p", { staticClass: "mx-10 d-inline" }, [
                _vm._v(
                  " " +
                    _vm._s(+_vm.lot.end_notification === 1 ? "ON" : "OFF") +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("td", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "form-control-label px-0" }, [
                _vm._v("End targets"),
              ]),
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "radio-custom radio-primary" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lot.end_target,
                        expression: "lot.end_target",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "end_target",
                      value: "participants",
                      id: "end_targets_participants",
                      disabled:
                        !_vm.canSwitchOnOff || +_vm.lot.end_notification !== 1,
                    },
                    domProps: {
                      checked: _vm._q(_vm.lot.end_target, "participants"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(_vm.lot, "end_target", "participants")
                        },
                        _vm.updateEndTarget,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "end_targets_participants" } }, [
                    _vm._v("Participants"),
                  ]),
                ]),
                _c("div", { staticClass: "radio-custom radio-primary mx-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lot.end_target,
                        expression: "lot.end_target",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "end_target",
                      value: "all",
                      id: "end_targets_all_users",
                      disabled:
                        !_vm.canSwitchOnOff || +_vm.lot.end_notification !== 1,
                    },
                    domProps: { checked: _vm._q(_vm.lot.end_target, "all") },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(_vm.lot, "end_target", "all")
                        },
                        _vm.updateEndTarget,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "end_targets_all_users" } }, [
                    _vm._v("All users"),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "d-block" }, [
                _vm.lot.end_image
                  ? _c("img", {
                      staticClass: "img img-fluid",
                      attrs: { src: _vm.lot.end_image },
                    })
                  : _vm._e(),
              ]),
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: "file",
                  accept: "image/*",
                  disabled: +_vm.lot.end_notification !== 1,
                },
                on: { change: _vm.upload },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  attrs: { type: "button", disabled: !_vm.lot.end_image },
                  on: { click: _vm.clearImage },
                },
                [
                  _c("i", {
                    staticClass: "icon md-close",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Clear image "),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "mx-5", attrs: { for: "lot_end_image" } },
      [
        _vm._v(" Rich Message "),
        _c("span", [
          _vm._v("(Recommended size 670x434 pixels / less than 1 MB)"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
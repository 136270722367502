var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Bulk Operation")]),
        _c("breadcrumb", { attrs: { name: "POINT_BULK_OPERATION" } }),
        _c("div", { staticClass: "page-header-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-icon btn-primary btn-round mx-10",
              attrs: { type: "button" },
              on: { click: _vm.addTask },
            },
            [
              _c("i", {
                staticClass: "icon md-plus",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Task "),
            ]
          ),
        ]),
        _c("div", { staticClass: "page-header-actions" }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c("vue-good-table", {
                ref: "table",
                attrs: {
                  rows: _vm.list,
                  columns: _vm.columns,
                  "sort-options": {
                    enabled: true,
                    initialSortBy: { field: "id", type: "desc" },
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function (props) {
                      return [
                        props.column.label == "Action"
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-upload mx-3",
                                  attrs: {
                                    disabled: !_vm.canUpload(props.row),
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "file-upload-task-" + props.row.id,
                                      },
                                    },
                                    [_vm._v(" Upload ")]
                                  ),
                                ]
                              ),
                              _c("input", {
                                staticClass: "input-select-file",
                                attrs: {
                                  type: "file",
                                  "data-task-id": props.row.id,
                                  disabled: !_vm.canUpload(props.row),
                                  id: "file-upload-task-" + props.row.id,
                                  accept:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                },
                                on: { change: _vm.onFileSelected },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-primary mx-3",
                                  attrs: {
                                    disabled: !_vm.canExecute(props.row),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.executeTask(props.row.id)
                                    },
                                  },
                                },
                                [_vm._v(" Execute ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default btn-round mx-3",
                                  attrs: {
                                    disabled: !_vm.canDownload(props.row),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(props.row.id)
                                    },
                                  },
                                },
                                [_vm._v(" Download ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-default mx-3",
                                  attrs: {
                                    disabled: !_vm.canDelete(props.row),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTask(props.row.id)
                                    },
                                  },
                                },
                                [_vm._v(" Delete ")]
                              ),
                            ])
                          : props.column.field == "name"
                          ? _c("div", [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  "data-task-key": "name",
                                  "data-task-id": props.row.id,
                                },
                                domProps: { value: props.row.name },
                                on: {
                                  focus: function ($event) {
                                    _vm.tempTask = props.row
                                  },
                                  blur: _vm.updateTask,
                                },
                              }),
                            ])
                          : props.column.field == "status"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toUpperCase")(props.row.status)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
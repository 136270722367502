<template>
  <div class="page" v-if="campaign">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="E_COUPON_CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Campaign Information - {{ campaign.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="campaign">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <CampaignName
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />
                  <CampaignDuration
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  ></CampaignDuration>

                  <div class="row">
                    <div class="col-6">
                      <CouponExpiration
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                    <div class="col-6">
                      <CouponCollectQuota
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />
                  <CouponInformation
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  ></CouponInformation>
                  <hr />
                  <LineNotification
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  ></LineNotification>
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";
import CouponExpiration from "./components/CouponExpiration.vue";
import CouponInformation from "./components/CouponInformation.vue";
import CouponCollectQuota from "./components/CouponCollectQuota.vue";
import LineNotification from "./components/LineNotification.vue";
import CampaignName from "@/views/ecoupon/campaign/components/CampaignName.vue";
import StatusDropdown from "@/views/ecoupon/campaign/components/StatusDropdown.vue";
import CampaignDuration from "@/views/ecoupon/campaign/components/CampaignDuration.vue";

export default {
  name: "ECouponEdit",

  data() {
    return {
      campaign: null,
    };
  },

  components: {
    CampaignName,
    StatusDropdown,
    CouponExpiration,
    CouponCollectQuota,
    CampaignDuration,
    CouponInformation,
    LineNotification,
  },

  computed: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await ecouponService.getById(id);
    },
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}
</style>

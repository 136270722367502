import Index from "@/views/samurai/Index.vue";
import Themes from "@/views/samurai/Themes/Index.vue";
import ThemesEdit from "@/views/samurai/Themes/Edit.vue";
import Settings from "@/views/samurai/Settings/Index.vue";

export default {
  path: "/game-samurai",
  name: "GameSamuraiIndex",
  component: Index,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "themes",
      name: "GameSamuraiThemes",
      component: Themes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "themes/edit/:id",
      name: "GameSamuraiThemeEdit",
      component: ThemesEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "settings",
      name: "GameSamuraiSettings",
      component: Settings,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h1", { staticClass: "page-title" }, [_vm._v("UserGroup")]),
          _c("breadcrumb", { attrs: { name: "USERGROUP_LIST" } }),
          _c("div", { staticClass: "page-header-actions" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-icon btn-dark btn-outline btn-round mx-10",
                attrs: { type: "button" },
                on: { click: _vm.reloadTable },
              },
              [
                _c("i", {
                  staticClass: "icon md-refresh-alt",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Reload table "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-dark btn-outline mx-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.hideActive = !_vm.hideActive
                  },
                },
              },
              [
                _vm.hideActive
                  ? _c("i", {
                      staticClass: "icon md-eye",
                      attrs: { "aria-hidden": "true" },
                    })
                  : _c("i", {
                      staticClass: "icon md-eye-off",
                      attrs: { "aria-hidden": "true" },
                    }),
                _vm._v(
                  " " +
                    _vm._s(_vm.hideActive ? "Show all" : "Hide Inactive") +
                    " "
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.openCreateModal },
              },
              [
                _c("i", {
                  staticClass: "icon md-plus",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "page-content page-content-table" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-12 col-lg-12" }, [
              _c("div", { staticClass: "panel" }, [
                _c(
                  "div",
                  { staticClass: "panel-body p-0" },
                  [
                    _c("vue-good-table", {
                      attrs: {
                        columns: _vm.columns,
                        rows: _vm.list,
                        "search-options": { enabled: true },
                        "sort-options": _vm.sortOption,
                        "pagination-options": _vm.pagination,
                      },
                      on: {
                        "on-row-click": _vm.goToEdit,
                        "on-sort-change": _vm.onSortChange,
                        "on-page-change": _vm.onPageChange,
                        "on-per-page-change": _vm.onPageChange,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "table-row",
                          fn: function (props) {
                            return [
                              props.column.field == "description"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        props.row.description
                                          ? props.row.description
                                          : ""
                                      ),
                                    },
                                  })
                                : props.column.field == "status"
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toUpperCase")(
                                            props.row.status
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : props.column.field == "group_code"
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge badge-lg badge-primary",
                                      },
                                      [_vm._v(_vm._s(props.row.group_code))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: { name: "create-usergroup-modal", width: 900, height: "auto" },
        },
        [_c("CreateModal", { on: { submit: _vm.fetchUserGroup } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-12 col-lg-12" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
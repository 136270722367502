<template>
  <div class="form-group col-md-6">
    <div class="px-3">
      <label class="form-control-label">
        <h5>Summary</h5>
      </label>
      <textarea
        rows="4"
        ref="summary"
        maxlength="999"
        @change="update"
        v-model="summary"
        :disabled="!canEdit"
        class="form-control"
      />
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  props: ["campaign"],

  data() {
    return {
      id: null,
      summary: null,
    };
  },

  validations: {
    summary: {
      required,
      minLength: minLength(0),
      maxLength: maxLength(999),
    },
  },

  computed: {
    canEdit() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },
  },

  mounted() {
    this.id = this.campaign.id;
    this.summary = this.campaign.summary;
  },

  methods: {
    async isFormValid() {
      if (this.$v.summary.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Campaign summary ให้ถูกต้อง");
        this.$refs.summary.focus();
        return false;
      }

      return true;
    },

    async update() {
      const formValid = await this.isFormValid();
      if (!formValid) return;

      await luckyLoginService.updateCampaignInfo(this.campaign.id, {
        summary: this.summary,
      });
    },
  },
};
</script>

<style></style>

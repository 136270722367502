var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _c("div", { staticClass: "panel-body" }, [
          _c("div", { staticClass: "container" }, [
            _c("h4", [_vm._v("Lot")]),
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.winner.lot_id))]),
                  _c("td", [_vm._v(_vm._s(_vm.winner.lot_name))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm._f("dateTime")(_vm.winner.start_date)) + " น."
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm._f("dateTime")(_vm.winner.end_date)) + " น."
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("hr"),
            _c("h4", [_vm._v("Winner Info")]),
            _c("table", { staticClass: "table" }, [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.winner.sfdc_user_id))]),
                  _c("td", [_vm._v(_vm._s(_vm.winner.contact_id))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.winner.firstname) +
                        " " +
                        _vm._s(_vm.winner.lastname)
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.winner.name))]),
                ]),
              ]),
            ]),
            _c("hr"),
            _c("h4", [_vm._v("Contact Info")]),
            _c("table", { staticClass: "table" }, [
              _vm._m(2),
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.winner.firstname) +
                        " " +
                        _vm._s(_vm.winner.lastname)
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.winner.telephone))]),
                  _c("td", [_vm._v(_vm._s(_vm.address))]),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "table" }, [
              _vm._m(3),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.winner.citizen_id))]),
                  _c("td", {
                    domProps: { innerHTML: _vm._s(_vm.taxHandling) },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._m(4),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("ID")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Start")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("End")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("User ID")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Contact ID")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Name")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", "td-width-30": "" } }, [
          _vm._v("Name"),
        ]),
        _c("th", { attrs: { scope: "col", "td-width-30": "" } }, [
          _vm._v("Tel. No."),
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Address")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", "td-width-60": "" } }, [
          _vm._v("Citizen ID"),
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Tax Handling")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
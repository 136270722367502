var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-bordered mb-0" }, [
    _vm._m(0),
    _c("div", { staticClass: "panel-body" }, [
      _c("form", { staticClass: "form-horizontal" }, [
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { ".is-invalid": _vm.$v.campaign.name.$error },
          },
          [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.campaign.name,
                      expression: "campaign.name",
                    },
                  ],
                  ref: "name",
                  staticClass: "form-control form__input",
                  attrs: { type: "text", autocomplete: "off" },
                  domProps: { value: _vm.campaign.name },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.campaign, "name", $event.target.value)
                      },
                      function ($event) {
                        return _vm.showValid("name")
                      },
                    ],
                  },
                }),
                _vm.showErrorNameValidation
                  ? _c("nameValidation", { attrs: { $v: _vm.$v } })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "form-group row" }, [
          _c("h5", { staticClass: "col-md-12" }, [_vm._v("Copy From")]),
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.copyFromId,
                    expression: "copyFromId",
                  },
                ],
                staticClass: "form-control col-md-12",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.copyFromId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "", disabled: "" } }, [
                  _vm._v("Select Copy From"),
                ]),
                _vm._l(_vm.campaigns, function (campaign) {
                  return _c(
                    "option",
                    { key: campaign.id, domProps: { value: campaign.id } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(" " + campaign.id + " - " + campaign.name) +
                          " "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-default mx-10",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _c("i", {
              staticClass: "icon md-close",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Cancel "),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.addCampaign },
          },
          [
            _c("i", {
              staticClass: "icon md-check",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Create "),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("Create Weekly Login Campaign"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Campaign Name")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-5" },
        _vm._l(
          _vm.gameConfig.sunset_drive.side_object.left,
          function (sideObject, index) {
            return _c("div", { key: index, staticClass: "d-block" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { attrs: { "my-15px": "" } }, [
                  _c("label", { staticClass: "form-control-label" }, [
                    _c("h5", [
                      _vm._v(" Left side object #" + _vm._s(index + 1) + " "),
                      _c("span", { staticClass: "small light" }, [
                        _vm._v(" (recommended size 571x1000)"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "d-block" }, [
                      _vm.picture
                        ? _c("img", {
                            staticClass: "img img-fluid image-preview",
                            attrs: {
                              src: _vm.picture.side_object.left[index].picture,
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "file",
                        accept: "image/*",
                        "data-side": "left",
                        "data-number": index + 1,
                        disabled: _vm.disabled,
                      },
                      on: { change: _vm.upload },
                    }),
                  ]),
                ]),
              ]),
            ])
          }
        ),
        0
      ),
      _c(
        "div",
        { staticClass: "col-md-5" },
        _vm._l(
          _vm.gameConfig.sunset_drive.side_object.right,
          function (sideObject, index) {
            return _c("div", { key: index, staticClass: "d-block" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { attrs: { "my-15px": "" } }, [
                  _c("label", { staticClass: "form-control-label" }, [
                    _c("h5", [
                      _vm._v(" Right side object #" + _vm._s(index + 1) + " "),
                      _c("span", { staticClass: "small light" }, [
                        _vm._v(" (recommended size 571x1000)"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "d-block" }, [
                      _vm.picture
                        ? _c("img", {
                            staticClass: "img img-fluid image-preview",
                            attrs: {
                              src: _vm.picture.side_object.right[index].picture,
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "file",
                        accept: "image/*",
                        "data-side": "right",
                        "data-number": index + 1,
                        disabled: _vm.disabled,
                      },
                      on: { change: _vm.upload },
                    }),
                  ]),
                ]),
              ]),
            ])
          }
        ),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import BudgetControlIndex from "@/views/budget-control/Index.vue";
import InstanceList from "@/views/budget-control/instance/List.vue";
import InstanceEdit from "@/views/budget-control/instance/Edit.vue";

import BudgetStatus from "@/views/budget-control/status/Index.vue";
import BudgetStatusDetail from "@/views/budget-control/status/Detail.vue";

export default {
  path: "/budget-control",
  name: "BudgetControlIndex",
  component: BudgetControlIndex,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "instance",
      name: "budget-instance-list",
      component: InstanceList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "instance/edit/:id",
      name: "budget-instance-edit",
      component: InstanceEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "status",
      name: "budget-status",
      component: BudgetStatus,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "status/:id",
      name: "budget-status-detail",
      component: BudgetStatusDetail,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

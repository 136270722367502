<template>
  <div class="px-15" v-if="budget">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ budget.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="budget.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="budget.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="budget.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublish
        </a>
        <a
          v-if="budget.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon mdi mdi-cancel" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="budget.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('paused')"
        >
          <i class="icon mdi mdi-pause" aria-hidden="true"></i>Pause
        </a>
        <a
          v-if="budget.status == 'paused'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('ongoing')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="budget.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["budget"],

  methods: {
    validate(status) {
      const { name } = this.budget;

      switch (status) {
        case "active":
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Theme name.");
            return false;
          }
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("Do you want to abort this campaign?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.budget;
        const form = { status };

        await budgetService.updateBudget(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);

        this.$emit("error");
      }
    },
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c("div", { staticClass: "flex-fit-content" }, [
        _c("div", { staticClass: "form-group-row" }, [
          _c("label", { staticClass: "mb-0" }, [_vm._v("Text box 1")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.stamp.text1,
                expression: "stamp.text1",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "text1",
              maxlength: "255",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.stamp.text1 },
            on: {
              change: _vm.update,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.stamp, "text1", $event.target.value)
              },
            },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import money from "v-money";
import VModal from "vue-js-modal";
import Vuelidate from "vuelidate";
import InputTag from "vue-input-tag";
import VueTheMask from "vue-the-mask";
import Paginate from "vuejs-paginate";
import VueChartist from "vue-chartist";
import SlimDialog from "v-slim-dialog";
import Notifications from "vue-notification";
import ToggleButton from "vue-js-toggle-button";
import VueQRCodeComponent from "vue-qrcode-component";

import App from "./App.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

import Loader from "@/components/shared/Loader.vue";
import BaseIcon from "@/components/shared/BaseIcon.vue";
import FileUpload from "@/components/shared/FileUpload.vue";
import BaseSelect from "@/components/shared/BaseSelect.vue";
import MonthSelect from "@/components/shared/MonthSelect.vue";
import BaseCropUpload from "@/components/shared/BaseCropUpload";
import SortTableQuery from "@/components/shared/SortTableQuery.vue";
import BirthdaySelect from "@/components/shared/BirthdaySelect.vue";
import CkeditorWrapper from "@/components/shared/CkeditorWrapper.vue";

import Breadcrumb from "@/components/layouts/Breadcrumb.vue";

import VueGoodTablePlugin from "vue-good-table";
import VTooltip from "v-tooltip";
// import the styles
import "vue-good-table/dist/vue-good-table.css";
import "v-tooltip/dist/v-tooltip.css";

//Date time
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.component("Loader", Loader);
Vue.component("BaseIcon", BaseIcon);
Vue.component("paginate", Paginate);
Vue.component("input-tag", InputTag);
Vue.component("FileUpload", FileUpload);
Vue.component("Breadcrumb", Breadcrumb);
Vue.component("BaseSelect", BaseSelect);
Vue.component("MonthSelect", MonthSelect);
Vue.component("SortTableQuery", SortTableQuery);
Vue.component("BirthdaySelect", BirthdaySelect);
Vue.component("BaseCropUpload", BaseCropUpload);
Vue.component("ckeditor", CkeditorWrapper);

Vue.component("datetime", Datetime);
Vue.component("date-picker", DatePicker);
Vue.component("qr-code", VueQRCodeComponent);

Vue.component("v-popover", VTooltip.VPopover);

Vue.component("DefaultLayout", DefaultLayout);

import "./mixins";
import "./filters";
import "./directives";
import store from "./store";
import router from "./router";
import { apolloProvider } from "./utils/graphql";

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VueChartist);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(SlimDialog);
Vue.use(ToggleButton);
Vue.use(VTooltip);
Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    clickToClose: false,
  },
});
Vue.use(money, { precision: 4 });
Vue.use(VueGoodTablePlugin);

new Vue({
  store,
  router,
  apolloProvider: apolloProvider,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="form-group-row row">
    <label>Stamp No.</label>

    <select
      name="stamp_no"
      @change="update"
      :disabled="disabled"
      class="form-control"
      v-model.number="reward.stamp_no"
    >
      <option :value="null" disabled>Not Select</option>
      <option
        v-for="(n, i) in campaign.stamp.no_stamp"
        :value="n"
        :key="i"
        :disabled="selected(n)"
      >
        {{ n }}
      </option>
    </select>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  data() {
    return {
      message: {
        error: `Update Stamp no of Reward #${this.reward.id} error.`,
        success: `Update Stamp no of Reward #${this.reward.id} success.`,
      },
    };
  },
};
</script>

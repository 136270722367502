var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-12" }, [
    _c(
      "div",
      {
        staticClass:
          "reward-picture d-flex flex-column align-items-center justify-content-center",
        attrs: { "my-15px": "" },
      },
      [
        _vm._m(0),
        _c("div", { staticClass: "image-preview" }, [
          _vm.picture
            ? _c("img", {
                staticClass: "img img-fluid",
                attrs: { src: _vm.picture },
              })
            : _c("div", { staticClass: "image-placeholder" }),
        ]),
        _c("input", {
          ref: "prize_picture",
          staticClass: "form-control",
          attrs: {
            type: "file",
            accept: "image/*",
            name: "prize_picture",
            disabled: _vm.disabled,
          },
          on: { change: _vm.uploadImage },
        }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("h4", [
        _vm._v(" Picture "),
        _c("span", { staticClass: "small light" }, [
          _vm._v(" (Recommended size 300 x 300 pixels / less than 1 MB) "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./RewardExpiration.vue?vue&type=template&id=4fb8a318&"
import script from "./RewardExpiration.vue?vue&type=script&lang=js&"
export * from "./RewardExpiration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fb8a318')) {
      api.createRecord('4fb8a318', component.options)
    } else {
      api.reload('4fb8a318', component.options)
    }
    module.hot.accept("./RewardExpiration.vue?vue&type=template&id=4fb8a318&", function () {
      api.rerender('4fb8a318', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/progressbar/Stamp/components/RewardExpiration.vue"
export default component.exports
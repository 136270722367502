<template>
  <div v-if="config">
    <label class="mx-5">
      {{ config.name }}
      <span class="light"> {{ config.label }}</span>
    </label>

    <div class="form-group">
      <div class="img-upload-wrapper">
        <img
          class="img img-fluid image-preview"
          v-if="picture"
          :src="picture"
        />
        <div v-else class="img-fluid image-preview"></div>
      </div>
      <input
        type="file"
        accept="image/*"
        class="form-control"
        @change="uploadImage"
        :ref="config.ref"
      />
    </div>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";

import warningChangeMixins from "../mixins/warning";
import { responseErrorMapping } from "../model/error";

export default {
  name: "PictureControl",

  props: ["theme", "config"],

  mixins: [warningChangeMixins],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    picture() {
      return this.theme[this.config.field];
    },
  },

  methods: {
    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      if (this.shouldWarning) {
        const ok = await this.warning();

        if (!ok) {
          event.target.files[0].value = "";
          this.file = null;
          return;
        }
      }

      try {
        if (!file) return;
        const form = new FormData();

        const { id } = this.theme;
        form.append(this.config.field, file);

        await gameSamuraiService.uploadThemeImage(id, form);

        this.$emit("updated");
        this.$notify(`${this.config.name} picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(`Upload ${this.config.name} picture error.`);
      } finally {
        this.file = null;
        this.clearInputRef();
      }
    },

    clearInputRef() {
      this.$nextTick(() => {
        this.$refs[this.config.ref].value = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  max-width: 200px;
}

.img-upload-wrapper {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.img-upload-wrapper,
input[type="file"] {
  width: 400px;
  max-width: 400px;
  text-align: center;
  background-color: #fafafa;
}
</style>

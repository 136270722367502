export const transactionLogTableColumns = [
  {
    label: "Time stamp",
    field: "ts",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "QR Code",
    field: "qrcode",
  },
  {
    label: "App ID",
    field: "app_id",
  },
  {
    label: "App ref",
    field: "app_reference",
  },
  {
    label: "Campaign ID",
    field: "cp_id",
    type: "number",
  },
  {
    label: "Amount",
    field: "amount",
    type: "number",
  },
  {
    label: "Action",
    field: "action",
  },
  {
    label: "ซื้อขั้นต่ำ (แถว)",
    field: "minimum_purchase",
  },
  {
    label: "Giver ISMS",
    field: "giver_isms",
  },
  {
    label: "Giver Name",
    field: "giver_name",
  },
  {
    label: "Receiver ISMS",
    field: "receiver_isms",
  },
  {
    label: "Receiver Name",
    field: "receiver_name",
  },
];

<template>
  <div class="form-group col-12">
    <label class="mx-5">
      <h4>Award Details</h4>
    </label>
    <ckeditor
      v-model="value"
      :read-only="disabled"
      @blur="handleDescriptionChange"
    />
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      value: "",
      message: {
        error: `Update Award details error.`,
        success: `Update Award details success.`,
      },
    };
  },

  methods: {
    initial() {
      this.value = this.theme.award_details;
    },

    handleDescriptionChange() {
      if (this.value == this.theme.award_details) return;

      const event = {
        target: {
          value: this.value,
          name: "award_details",
        },
      };

      this.update(event);
    },
  },
};
</script>

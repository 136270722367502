var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8" }, [
    _c("div", { staticClass: "ml-10" }, [
      _c("h5", [_vm._v(" Button Action ")]),
      _c("table", { staticClass: "table table-responsive table-borderless" }, [
        _c("tr", [
          _c("td", [_vm._v("First page")]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.button_action_first_page,
                  expression: "campaign.button_action_first_page",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "25",
                name: "button_action_first_page",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.campaign.button_action_first_page },
              on: {
                change: function ($event) {
                  return _vm.update($event)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "button_action_first_page",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _c("td", [_vm._v("Play page")]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.button_action_play_page,
                  expression: "campaign.button_action_play_page",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "25",
                name: "button_action_play_page",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.campaign.button_action_play_page },
              on: {
                change: function ($event) {
                  return _vm.update($event)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "button_action_play_page",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Text color")]),
          _c("td", [
            _c("div", { staticClass: "color-picker" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.button_action_text_color,
                    expression: "campaign.button_action_text_color",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "button_action_text_color",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.button_action_text_color },
                on: {
                  change: function ($event) {
                    return _vm.update($event, "color")
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "button_action_text_color",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.button_action_text_color,
                    expression: "campaign.button_action_text_color",
                  },
                ],
                attrs: {
                  type: "color",
                  name: "button_action_text_color",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.button_action_text_color },
                on: {
                  change: function ($event) {
                    return _vm.update($event, "color")
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "button_action_text_color",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _c("td", [_vm._v("Text size")]),
          _c("td", [
            _c("div", { staticClass: "css-input-text" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.button_action_text_size,
                    expression: "campaign.button_action_text_size",
                  },
                ],
                attrs: {
                  type: "number",
                  name: "button_action_text_size",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.button_action_text_size },
                on: {
                  change: function ($event) {
                    return _vm.update($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "button_action_text_size",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" rem "),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "ml-10" }, [
      _c("h5", [_vm._v(" Already Played ")]),
      _c("table", { staticClass: "table table-responsive table-borderless" }, [
        _c("tr", [
          _c("td", [_vm._v("Text content")]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.already_played_text,
                  expression: "campaign.already_played_text",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "25",
                name: "already_played_text",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.campaign.already_played_text },
              on: {
                change: function ($event) {
                  return _vm.update($event)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "already_played_text",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _c("td"),
          _c("td"),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Text color")]),
          _c("td", [
            _c("div", { staticClass: "color-picker" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.already_played_text_color,
                    expression: "campaign.already_played_text_color",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "already_played_text_color",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.already_played_text_color },
                on: {
                  change: function ($event) {
                    return _vm.update($event, "color")
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "already_played_text_color",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.already_played_text_color,
                    expression: "campaign.already_played_text_color",
                  },
                ],
                attrs: {
                  type: "color",
                  name: "already_played_text_color",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.already_played_text_color },
                on: {
                  change: function ($event) {
                    return _vm.update($event, "color")
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "already_played_text_color",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _c("td", [_vm._v("Text size")]),
          _c("td", [
            _c("div", { staticClass: "css-input-text" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.already_played_text_size,
                    expression: "campaign.already_played_text_size",
                  },
                ],
                attrs: {
                  type: "number",
                  name: "already_played_text_size",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.already_played_text_size },
                on: {
                  change: function ($event) {
                    return _vm.update($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "already_played_text_size",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" rem "),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "ml-10" }, [
      _c("h5", [_vm._v(" View winner list / T&C ")]),
      _c("table", { staticClass: "table table-responsive table-borderless" }, [
        _c("tr", [
          _c("td", [_vm._v("Text color")]),
          _c("td", [
            _c("div", { staticClass: "color-picker" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.view_winner_text_color,
                    expression: "campaign.view_winner_text_color",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "view_winner_text_color",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.view_winner_text_color },
                on: {
                  change: function ($event) {
                    return _vm.update($event, "color")
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "view_winner_text_color",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.view_winner_text_color,
                    expression: "campaign.view_winner_text_color",
                  },
                ],
                attrs: {
                  type: "color",
                  name: "view_winner_text_color",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.view_winner_text_color },
                on: {
                  change: function ($event) {
                    return _vm.update($event, "color")
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "view_winner_text_color",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _c("td", [_vm._v("Text size")]),
          _c("td", [
            _c("div", { staticClass: "css-input-text" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.view_winner_text_size,
                    expression: "campaign.view_winner_text_size",
                  },
                ],
                attrs: {
                  type: "number",
                  name: "view_winner_text_size",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.campaign.view_winner_text_size },
                on: {
                  change: function ($event) {
                    return _vm.update($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.campaign,
                      "view_winner_text_size",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" rem "),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "ml-10" }, [
      _c("h5", [_vm._v(" Congratulation ")]),
      _c("table", { staticClass: "table table-responsive table-borderless" }, [
        _c("tr", [
          _c("td", [_vm._v("Text content")]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.congratulation_text,
                  expression: "campaign.congratulation_text",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "25",
                name: "congratulation_text",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.campaign.congratulation_text },
              on: {
                change: function ($event) {
                  return _vm.update($event)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "congratulation_text",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _c("td"),
          _c("td"),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "ml-10" }, [
      _c("h5", [_vm._v(" Consolidation ")]),
      _c("table", { staticClass: "table table-responsive table-borderless" }, [
        _c("tr", [
          _c("td", [_vm._v("Text content")]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.consolidation_text,
                  expression: "campaign.consolidation_text",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "25",
                name: "consolidation_text",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.campaign.consolidation_text },
              on: {
                change: function ($event) {
                  return _vm.update($event)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "consolidation_text",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _c("td"),
          _c("td"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
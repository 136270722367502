var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c("div", { staticClass: "flex-fit-content" }, [
        _c("div", { staticClass: "form-group-row" }, [
          _c("label", { staticClass: "mb-0" }, [_vm._v("Text color")]),
          _c("div", { staticClass: "color-picker" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.color,
                  expression: "color",
                },
              ],
              attrs: {
                type: "text",
                name: "text_color",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.color },
              on: {
                change: function ($event) {
                  return _vm.update($event, "color")
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.color = $event.target.value
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.color,
                  expression: "color",
                },
              ],
              attrs: {
                type: "color",
                name: "text_color",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.color },
              on: {
                change: function ($event) {
                  return _vm.update($event, "color")
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.color = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme
    ? _c("div", { staticClass: "px-15" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-round dropdown-toggle",
              staticStyle: { "text-transform": "uppercase" },
              attrs: {
                type: "button",
                "data-toggle": "dropdown",
                "aria-expanded": "false",
              },
            },
            [_vm._v(" " + _vm._s(_vm.theme.status) + " ")]
          ),
          _c("div", { staticClass: "dropdown-menu", attrs: { role: "menu" } }, [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                class: { disabled: _vm.theme.status === "active" },
                attrs: { href: "javascript:void(0)", role: "menuitem" },
                on: {
                  click: function ($event) {
                    return _vm.update("active")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "icon md-long-arrow-up",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v("Active "),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                class: { disabled: _vm.theme.status === "inactive" },
                attrs: { href: "javascript:void(0)", role: "menuitem" },
                on: {
                  click: function ($event) {
                    return _vm.update("inactive")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "icon md-long-arrow-down",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v("Inactive "),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">LIFF Access Setting</h1>
      <breadcrumb name="LIFF_ACCESS_SETTINGS"></breadcrumb>
    </div>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-bordered">
            <div class="panel-body">
              <form class="form-horizontal mb-5">
                <div class="row">
                  <label class="form-control-label">
                    <h4>Progress Bar</h4>
                  </label>
                </div>

                <div class="row">
                  <div class="col-2 col-md-3 offset-md-1">
                    <div class="d-flex align-items-center">
                      Delisted Date<br />(after campaign ends)
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex align-items-center gap-4">
                      <input
                        min="0"
                        type="number"
                        @change="updateConfig"
                        class="form-control w-100"
                        v-model.number="config.delist_days"
                      />
                      <span>day(s)</span>
                    </div>
                  </div>
                </div>

                <div class="row-space"></div>

                <div class="row">
                  <div
                    class="col-2 col-md-3 offset-md-1 d-flex flex-column justify-content-center"
                  >
                    <div>Order of display for multiple campaigns</div>
                  </div>
                  <div class="col">
                    <div class="d-flex gap-5">
                      <div class="radio-custom radio-primary">
                        <input
                          type="radio"
                          value="ascending"
                          id="order_ascending"
                          @change="updateConfig"
                          v-model="config.order_of_display"
                        />
                        <label for="order_ascending">Ascending</label>
                      </div>
                      <div class="radio-custom radio-primary mx-10">
                        <input
                          type="radio"
                          value="descending"
                          id="order_descending"
                          @change="updateConfig"
                          v-model="config.order_of_display"
                        />
                        <label for="order_descending">Descending</label>
                      </div>

                      <select
                        class="form-control w-200"
                        @change="updateConfig"
                        v-model="config.order_of_display_by"
                      >
                        <option value="" disabled>Please select sort by</option>
                        <option
                          v-for="choice in choices"
                          :key="choice.value"
                          :value="choice.value"
                        >
                          {{ choice.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="panel-footer">
              <div class="d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { liffAccessService } from "@/api/client";

export default {
  name: "LIFFAccessSetting",

  data() {
    return {
      choices: [
        { name: "Campaign Start Date", value: "start_date" },
        { name: "Campaign End Date", value: "end_date" },
        { name: "Campaign Duration", value: "duration" },
        { name: "Campaign Name", value: "campaign_name" },
      ],
      config: {
        delist_days: 1,
        order_of_display: "ascending",
        order_of_display_by: "end_date",
      },
    };
  },

  methods: {
    validate() {
      if (this.config.delist_days < 0) {
        throw new Error("Delisted Date must be start with 0");
      }
    },

    async fetchConfig() {
      this.config = await liffAccessService.getConfig();
    },

    async updateConfig() {
      try {
        this.validate();

        await liffAccessService.updateConfig(this.config);

        this.$notify("Config Updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify(error.message);
      }
    },
  },

  created() {
    this.fetchConfig();
  },
};
</script>

<style lang="scss" scoped>
.row-space {
  height: 2rem;
}
</style>

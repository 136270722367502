<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Winner</h1>

      <breadcrumb name="LUCKY_LOGIN_WINNER_LIST"></breadcrumb>

      <div class="Excel">
        <button @click="exportToExcel" class="btn btn-default">
          <i class="fa fa-file-excel-o"></i>
          Export to Excel
        </button>
      </div>

      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="fromTo"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          class="mx-3"
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions"></div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              :columns="columns"
              :rows="listwinner"
              @on-row-click="goToDetail"
              :search-options="{
                enabled: true,
                placeholder: 'Search this',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
              }"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="detail-modal" :width="900" height="auto">
      <DetailModal :winner="winner" />
    </modal>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";
import { winnerTableColumns } from "@/views/luckylogin/winners/model";

import DetailModal from "@/views/luckylogin/winners/Detail.vue";

export default {
  name: "WinnerList",

  components: { DetailModal },

  data() {
    return {
      winner: null,

      fromTo: [],
      download: [],
      listwinner: [],
      columns: winnerTableColumns,
    };
  },

  watch: {
    fromTo() {
      this.update();
    },
  },

  methods: {
    async exportToExcel() {
      try {
        const response = await luckyLoginService.downloadWinner({
          from: this.fromTo[0],
          to: this.fromTo[1],
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `export_lucky_login_winners_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    async update() {
      this.listwinner = await luckyLoginService.getWinnerList({
        from: this.fromTo[0],
        to: this.fromTo[1],
      });
    },

    async goToDetail(event) {
      const id = event.row.id;
      this.winner = await luckyLoginService.getWinnerById(id);

      this.$modal.show(`detail-modal`);
    },

    lastmonth() {
      var today = new Date();
      var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 30 * 3)
        .toJSON()
        .slice(0, 10);
      return lastmonth;
    },

    today() {
      var today = new Date().toJSON().slice(0, 10);
      return today;
    },
  },

  created() {
    this.fromTo = [this.lastmonth(), this.today()];
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin-top: 35px;
}
</style>

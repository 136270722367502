<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">History</h1>
      <breadcrumb name="HISTORY_LIST"></breadcrumb>
      <div class="Excel">
        <input
          type="checkbox"
          id="checkbox"
          v-model="selectall"
          @click="change"
        />
        <label class="all">Select All</label>
        <button
          type="button"
          :disabled="ids.length <= 0"
          class="btn btn-round btn-default mx-10"
          @click="exportToExcel"
        >
          <i class="fa fa-file-excel-o"></i>
          Export to Excel
        </button>
      </div>
      <div class="page-header-right">
        Selected Period
        <date-picker
          type="date"
          v-model="from_to"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions">
        <button
          type="button"
          @click="filter = null"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          Show All
        </button>
        <button
          type="button"
          @click="filter = 'lucky'"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          Lucky Draw
        </button>
        <button
          type="button"
          @click="filter = 'auction'"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          Auction
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              :columns="columns"
              :rows="listHistory"
              @on-cell-click="goToView"
              :select-options="selectOption"
              :search-options="searchOption"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
              @on-selected-rows-change="selectionChanged"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { historyService } from "@/api/client";
import { historyTableColumns } from "@/views/history/model";

export default {
  name: "HistoryList",
  data() {
    return {
      ids: [],
      list: [],
      show: "",
      confirm: "",
      from_to: [],
      filter: null,
      historyService,
      listHistory: [],
      selectall: false,
      columns: historyTableColumns,

      selectOption: {
        enabled: true,
        selectOnCheckboxOnly: true,
      },
      searchOption: {
        enabled: true,
      },
      pagination: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
      },
    };
  },

  beforeMount() {
    this.initialTableState();
  },

  created() {
    const from = this.$route.query.from || this.lastmonth();
    const to = this.$route.query.to || this.today();
    this.from_to = [from, to];
  },

  watch: {
    listHistory() {
      this.resetselect();
    },

    from_to() {
      this.update();
      this.onPeriodChange();
    },

    filter() {
      this.showtype();
    },

    async "$route.query"() {
      this.initialTableState();
    },
  },

  methods: {
    async exportToExcel() {
      try {
        if (!this.ids || this.ids.length == 0) {
          return;
        }

        const response = await historyService.download(this.ids);

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "Export table.xlsx";
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    resetselect() {
      var all = this.listHistory.length;
      for (var i = 0; i < all; i++) {
        this.$set(this.listHistory[i], "vgtSelected", false);
      }
    },

    change() {
      this.selectall = !this.selectall;
      this.onSelectAll(this.selectall);
    },

    onSelectAll(bool) {
      var all = this.listHistory.length;
      if (bool) {
        this.resetselect();
      }
      for (var i = 0; i < all; i++) {
        this.$set(this.listHistory[i], "vgtSelected", bool);
      }
    },

    async update() {
      if (this.from_to[0] == null || this.from_to[1] == null) {
        this.list = await historyService.getHistoryDate();
      } else {
        this.list = await historyService.getHistoryDate(
          this.from_to[0],
          this.from_to[1]
        );
      }

      this.listHistory = this.list;
    },

    showtype() {
      if (this.filter == "lucky") {
        this.listHistory = this.list.filter((g) => g.type == "luckydraw");
      } else if (this.filter == "auction") {
        this.listHistory = this.list.filter((g) => g.type == "auction");
      } else {
        this.listHistory = this.list;
      }
    },

    selectionChanged(params) {
      this.ids = params.selectedRows.map((l) => l.lot_id);
    },

    lastmonth() {
      var today = new Date();
      var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 12)
        .toJSON()
        .slice(0, 10);
      return lastmonth;
    },

    today() {
      var today = new Date().toJSON().slice(0, 10);
      return today;
    },

    goToView(event) {
      this.$router.push({
        name: "HistoryView",
        params: {
          id: event.row.lot_id,
          from: this.from_to[0],
          to: this.from_to[1],
        },
      });
    },

    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            to: this.from_to[1],
            from: this.from_to[0],
          }),
        })
        .catch((err) => {});
    },

    onSortChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            sortBy: params[0].field,
            sortDir: params[0].type,
          }),
        })
        .catch((err) => {});
    },

    onPageChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            perPage: params.currentPerPage,
            page: params.currentPage,
          }),
        })
        .catch((err) => {});
    },

    initialTableState() {
      //default
      if (!this.$route.query.sortBy || !this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: "lot_id",
          type: "asc",
        };

      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = parseInt(this.$route.query.page);
      if (this.$route.query.perPage)
        this.pagination.perPage = parseInt(this.$route.query.perPage);
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin-top: 35px;
}
.all {
  margin-left: 5px;
  margin-right: 10px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body mb-0" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              {
                staticClass: "form-group row",
                class: { ".is-invalid": _vm.$v.lot.name.$error },
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lot.name,
                          expression: "lot.name",
                        },
                      ],
                      ref: "name",
                      staticClass: "form-control form__input",
                      attrs: { type: "text", autocomplete: "off" },
                      domProps: { value: _vm.lot.name },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.lot, "name", $event.target.value)
                          },
                          function ($event) {
                            return _vm.showValid("name")
                          },
                        ],
                      },
                    }),
                    _vm.showErrorNameValidation
                      ? _c("nameValidation", { attrs: { $v: _vm.$v } })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "form-group row" }, [
              _c("h5", { staticClass: "col-md-12" }, [_vm._v("Lot Type")]),
              _c("div", { staticClass: "col-md-1" }),
              _c("div", { staticClass: "col-md-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lot.type,
                      expression: "lot.type",
                    },
                  ],
                  attrs: {
                    name: "lotType",
                    type: "radio",
                    id: "one",
                    value: "auction",
                    checked: "checked",
                  },
                  domProps: { checked: _vm._q(_vm.lot.type, "auction") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.lot, "type", "auction")
                    },
                  },
                }),
                _c("label", { staticClass: "mx-10", attrs: { for: "one" } }, [
                  _vm._v("Auction"),
                ]),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lot.type,
                      expression: "lot.type",
                    },
                  ],
                  attrs: {
                    name: "lotType",
                    type: "radio",
                    id: "two",
                    value: "luckydraw",
                  },
                  domProps: { checked: _vm._q(_vm.lot.type, "luckydraw") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.lot, "type", "luckydraw")
                    },
                  },
                }),
                _c("label", { staticClass: "mx-10", attrs: { for: "two" } }, [
                  _vm._v("Lucky Draw"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("h5", { staticClass: "col-md-12" }, [_vm._v("Copy From")]),
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.copyFromId,
                        expression: "copyFromId",
                      },
                    ],
                    staticClass: "form-control col-md-12",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.copyFromId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.list, function (lot) {
                    return _c(
                      "option",
                      { key: lot.id, domProps: { value: lot.id } },
                      [
                        _vm._v(
                          " " + _vm._s(" " + lot.id + " - " + lot.name) + " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-close",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Cancel "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.addLot },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Create New Lot")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Lot Name")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
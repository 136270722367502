<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Samurai</h1>
      <breadcrumb name="GAME_SAMURAI_SETTINGS"></breadcrumb>
      <div class="page-header-actions"></div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="panel">
              <div class="panel-body p-0">
                <vue-good-table
                  :rows="rows"
                  :columns="columns"
                  @on-row-click="openConfigModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :name="modalName" :width="860" height="auto" @closed="clearSelected">
      <ConfigurationModal
        :name="modalName"
        :themes="choices"
        @updated="onConfigUpdated"
        :environment="selected.environment"
      />
    </modal>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";
import { configTableColumns as columns } from "@/views/samurai/Settings/model";
import ConfigurationModal from "@/views/samurai/Settings/components/ConfigurationModal.vue";

export default {
  model: "GameSamuraiSettings",

  data() {
    return {
      rows: [],
      choices: [],
      columns: columns,
      selected: { environment: null },
      modalName: "samurai-configuration-modal",
    };
  },

  components: { ConfigurationModal },

  methods: {
    async openConfigModal(event) {
      this.selected = { environment: event.row };
      this.$modal.show(this.modalName);
    },

    async fetchEnvironment() {
      const groups = await gameSamuraiService.getAllGroup();
      this.rows = groups;
    },

    async fetchChoices() {
      const choices = await gameSamuraiService.getThemeChoice();
      this.choices = choices;
    },

    clearSelected() {
      this.selected.environment = null;
    },

    // Events
    onConfigUpdated() {
      this.fetchEnvironment();
      this.clearSelected();
    },
  },

  created() {
    this.fetchChoices();
    this.fetchEnvironment();
  },
};
</script>

<style lang="scss"></style>

<template>
  <form class="form-horizontal" v-if="campaign">
    <div class="row">
      <div class="form-group col-md-6">
        <CampaignName :campaign="campaign" />
      </div>
      <div class="form-group col-md-3">
        <StatusDropdown :campaign="campaign" @updated="$emit('updated')" />
      </div>
    </div>
    <hr />

    <div class="row">
      <CampaignDuration :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <BannerImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <BackgroundImage :campaign="campaign" @updated="$emit('updated')" />
      <BackgroundColor :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <Textbox1 :campaign="campaign" @updated="$emit('updated')" />
      <Textbox2 :campaign="campaign" @updated="$emit('updated')" />
    </div>

    <div class="row">
      <TextColor :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <NoStamp :campaign="campaign" @updated="$emit('updated')" />
    </div>

    <div class="row">
      <StampEmptyImage :campaign="campaign" @updated="$emit('updated')" />
      <StampTakenImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <NumbersOnEmptyStamp :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <RewardList :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <RewardDetailsSection :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <BarcodeScanner :campaign="campaign" @updated="$emit('updated')" />
      <ScannerImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <Audience :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />

    <div class="row">
      <TargetSKU :campaign="campaign" />
    </div>
  </form>
</template>

<script>
import NoStamp from "./components/NoStamp.vue";
import Textbox1 from "./components/Textbox1.vue";
import Textbox2 from "./components/Textbox2.vue";
import Audience from "./components/Audience.vue";
import TargetSKU from "./components/TargetSKU.vue";
import TextColor from "./components/TextColor.vue";
import RewardList from "./components/RewardList.vue";
import BannerImage from "./components/BannerImage.vue";
import ScannerImage from "./components/ScannerImage.vue";
import BarcodeScanner from "./components/BarcodeScanner.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import StampEmptyImage from "./components/StampEmptyImage.vue";
import StampTakenImage from "./components/StampTakenImage.vue";
import BackgroundImage from "./components/BackgroundImage.vue";
import BackgroundColor from "./components/BackgroundColor.vue";
import CampaignDuration from "./components/CampaignDuration.vue";
import NumbersOnEmptyStamp from "./components/NumbersOnEmptyStamp.vue";
import RewardDetailsSection from "./components/RewardDetailsSection.vue";
import CampaignName from "@/views/progressbar/components/CampaignName.vue";

export default {
  name: "StampForm",

  props: ["campaign"],

  components: {
    NoStamp,
    Textbox1,
    Textbox2,
    Audience,
    TargetSKU,
    TextColor,
    RewardList,
    BannerImage,
    ScannerImage,
    CampaignName,
    BarcodeScanner,
    StatusDropdown,
    StampEmptyImage,
    StampTakenImage,
    BackgroundImage,
    BackgroundColor,
    CampaignDuration,
    NumbersOnEmptyStamp,
    RewardDetailsSection,
  },
};
</script>

<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('aborted')"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'aborted'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Nothing
        </a>
        <a
          v-if="campaign.status == 'end'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Nothing
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["campaign"],

  methods: {
    validate(status) {
      const {
        video_url,
        start_date,
        picture_url,
        picture_or_video,
        lucky_number_list,
      } = this.campaign;

      switch (status) {
        case "scheduled":
          if (picture_or_video == "video" && !video_url) {
            this.$dialogs.alert("กรุณาระบุ Video Url.");
            return false;
          }

          if (picture_or_video == "image" && !picture_url) {
            this.$dialogs.alert("กรุณาระบุ Picture Url.");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องยังเป็นอนาคต.");
            return false;
          }

          for (let index = 0; index < lucky_number_list.length; index++) {
            const luckyNumber = lucky_number_list[index];
            const { lucky_no, reward_type, sfdc_point, picture } = luckyNumber;

            if (
              reward_type == "sfdc" &&
              (+sfdc_point < 0 ||
                !(Number(sfdc_point) === sfdc_point && sfdc_point % 1 === 0))
            ) {
              this.$dialogs.alert(
                "กรุณาระบุ คะแนน SF point เป็นเลขจำนวนเต็มและมากกว่า 0"
              );
              return false;
            }

            if (reward_type == "other" && !picture) {
              this.$dialogs.alert(
                `กรุณาระบุ Reward picture (Lucky No. ${lucky_no})`
              );
              return false;
            }
          }
          break;
      }

      return true;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await luckyLoginService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    countDownToNextStatus() {
      if (!["scheduled", "ongoing"].includes(this.campaign.status)) {
        return;
      }

      setInterval(() => {
        const isPassTime =
          new Date(this.campaign.start_date).getTime() < new Date().getTime();

        if (isPassTime) {
          this.$emit("updated");
        }
      }, 5000);
    },
  },

  created() {
    this.countDownToNextStatus();
  },
};
</script>

<style></style>

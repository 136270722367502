<template>
  <div>
    <div class="row">
      <label class="form-control-label">
        <h4>Obstacles</h4>
      </label>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table">
          <tr
            v-for="(obstacle, index) in gameConfig.sunset_drive.obstacle"
            :key="index"
          >
            <td>No. {{ index + 1 }}</td>
            <td class="center w-400">
              <p>{{ obstacleName[index] }}</p>
              <img
                :src="obstacle.picture"
                class="img w-100"
                :class="{ 'w-250 h-auto': index == 2 }"
              />
            </td>
            <td class="v-align-middle">
              <div>
                <div class="d-flex align-items-center mb-10">
                  <p class="w-120 mx-10 mb-0">No. Occurrence</p>
                  <input
                    min="0"
                    type="number"
                    class="form-control w-100"
                    :disabled="disabled"
                    @change="update(index)"
                    v-model="obstacle.no_occurrence"
                  />
                </div>
                <div class="d-flex align-items-center mb-10">
                  <p class="w-120 mx-10 mb-0">Penalty per object</p>
                  <input
                    max="0"
                    min="-999"
                    type="number"
                    class="form-control w-100"
                    :disabled="disabled"
                    @change="update(index)"
                    v-model="obstacle.penalty_per_object"
                  />
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

const obstacleName = ["Cone", "Construction", "Barricade"];

export default {
  props: ["gameConfig"],

  data() {
    return { obstacleName: obstacleName };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },

  methods: {
    async update(index) {
      try {
        const valid = await this.validate(index);
        if (!valid) return;

        const { id } = this.gameConfig;
        const {
          no_occurrence,
          penalty_per_object,
        } = this.gameConfig.sunset_drive.obstacle[index];

        await httpService.put(
          `/game/${id}/sunset_drive/obstacle/${index + 1}`,
          { no_occurrence, penalty_per_object }
        );

        this.$emit("updated");
        this.$notify(`Update Obstacle config success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Obstacle config error.`);
      }
    },

    async validate(index) {
      const { game_duration } = this.gameConfig.sunset_drive;
      const {
        no_occurrence,
        penalty_per_object,
      } = this.gameConfig.sunset_drive.obstacle[index];

      if (
        no_occurrence < 0 ||
        no_occurrence > game_duration * 10 ||
        isNaN(+no_occurrence)
      ) {
        await this.$dialogs.alert(
          "กรุณาระบุ No. Occurrence: ใส่เป็นตัวเลขตั้งแต่ 0 ถึง Game duration x 10"
        );
        return false;
      }

      if (
        penalty_per_object > 0 ||
        penalty_per_object < -999 ||
        isNaN(+penalty_per_object)
      ) {
        await this.$dialogs.alert(
          "กรุณาระบุ Penalty per object: ใส่เป็นตัวเลขตั้งแต่ 0 ถึง -999"
        );
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
table.table {
  margin-left: 2em;
}
td.center {
  text-align: center;
}
td.v-align-middle {
  vertical-align: middle;
}
</style>

<template>
  <div class="form-group col-12">
    <div class="form-group-row">
      <label>
        <h4 class="mx-5">Claim Expiration</h4>
      </label>
      <input
        min="1"
        max="10000"
        type="number"
        @change="update"
        class="form-control"
        :disabled="disabled"
        name="claim_expiration"
        v-model="theme.claim_expiration"
      />
      <span>days after notifying (end of day)</span>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: `Update Claim Expiration error.`,
        success: `Update Claim Expiration success.`,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 2rem;

  h4,
  label {
    margin-bottom: 0px;
  }
}

[type="number"] {
  max-width: 100px;
}
</style>

<template>
  <div v-if="campaign">
    <div class="row">
      <label class="form-control-label">
        <h4>Campaign Duration</h4>
      </label>
    </div>
    <div class="row">
      <label class="d-inline mx-10 form-control-label"> Start </label>
      <div class="d-inline mx-10">
        <DatePicker
          width-200px
          type="datetime"
          :confirm="true"
          @change="update('start_date')"
          placeholder="n/a"
          ref="start_date"
          format="DD/MM/YYYY HH:mm"
          value-type="YYYY-MM-DD HH:mm"
          :disabled="disabled"
          v-model="campaign.start_date"
          :disabled-date="disabledStartDate"
          :disabled-time="disabledStartTime"
        />
      </div>
      <label class="d-inline mx-10 form-control-label"> End </label>
      <div class="d-inline mx-10">
        <DatePicker
          width-200px
          type="datetime"
          :confirm="true"
          @change="update('end_date')"
          placeholder="n/a"
          ref="end_date"
          format="DD/MM/YYYY HH:mm"
          value-type="YYYY-MM-DD HH:mm"
          :disabled="disabled"
          v-model="campaign.end_date"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import { leaderBoardService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
      start_date: null,
      end_date: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  mounted() {
    const { start_date, end_date } = this.campaign;
    this.oldValue = { start_date, end_date };
  },

  methods: {
    disabledStartDate(date) {
      const today = dayjs().toDate();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    disabledStartTime(date) {
      return date < Date.now();
    },

    async isFormValid(field) {
      switch (field) {
        case "start_date":
          // ถ้าไม่ระบุวันที่ start date
          if (!this.campaign.start_date) {
            await this.$dialogs.alert("กรุณาระบุค่า Start date");

            this.$refs.start_date.focus();

            const { start_date } = this.oldValue;
            this.campaign.start_date = start_date;

            return false;
          }

          // https://stackoverflow.com/a/5646753
          if (
            dayjs(
              new Date(this.campaign.start_date.replace(/-/g, "/")).setSeconds(
                0,
                0
              )
            ).diff(new Date().setSeconds(0, 0), "minutes") < 1
          ) {
            await this.$dialogs.alert(
              "Start date ใส่ค่าได้ตั้งแต่วันที่ในอนาคตเท่านั้น"
            );

            this.$refs.start_date.focus();

            const { start_date } = this.oldValue;
            this.campaign.start_date = start_date;

            return false;
          }

          // ถ้าไม่ระบุวันที่ start date มากกว่าวันที่ end date
          if (
            dayjs(this.campaign.start_date).diff(
              new Date(this.campaign.end_date.replace(/-/g, "/")),
              "minutes"
            ) >= 0 &&
            this.campaign.end_date != null
          ) {
            await this.$dialogs.alert(
              "วันเริ่มต้นต้องน้อยกว่าวันสิ้นสุด campaign"
            );

            const { start_date } = this.oldValue;

            this.campaign.start_date = start_date;

            return false;
          }

          return true;

        case "end_date":
          // ถ้าไม่ระบุวันที่ end date
          if (this.oldValue.end_date && !this.campaign.end_date) {
            await this.$dialogs.alert("ต้องระบุค่า End date");

            const { end_date } = this.oldValue;
            this.campaign.end_date = end_date;

            return false;
          }

          // ถ้าไม่ระบุวันที่ start date มากกว่าวันที่ end date
          if (
            dayjs(this.campaign.start_date).diff(
              new Date(this.campaign.end_date.replace(/-/g, "/")),
              "minutes"
            ) >= 0
          ) {
            await this.$dialogs.alert(
              "วันสิ้นสุดต้องมากกว่าวันเริ่มต้น campaign"
            );

            const { end_date } = this.oldValue;

            this.campaign.end_date = end_date;

            return false;
          }

          return true;
      }
    },

    async update(field) {
      try {
        const formValid = await this.isFormValid(field);
        if (!formValid) return;

        const { id } = this.campaign;

        const form = {};
        form[field] = this.campaign[field];

        await leaderBoardService.updateCampaign(id, form);

        this.oldValue[field] = this.campaign[field];

        this.$emit("updated");
        this.$notify(`Campaign Duration updated.`);
      } catch (error) {
        this.$dialogs.alert(`Campaign Duration update error.`);
      }
    },
  },
};
</script>

<style></style>

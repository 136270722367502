<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Discount Report</h1>
      <breadcrumb name="POINT_DISCOUNT_REPORT"></breadcrumb>
      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="fromTo"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-lg-5">
                <div id="chart-pie"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list || []"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import vueGoodTableMixins from "@/mixins/vue-good-table";

import { walletService } from "@/api/client";
import { discountReportTableColumns } from "@/views/point/model";

export default {
  name: "TransactionLog",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      pie: {},
      list: [],
      fromTo: [],
      transactions: [],
      columns: discountReportTableColumns,
    };
  },

  watch: {
    fromTo() {
      this.onPeriodChange();
    },

    transactions(v) {
      this.list = v;
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    isValidPeriod() {
      const { from, to } = this.$route.query;

      if (!from || !moment(from).isValid()) {
        return false;
      }

      if (!to || !moment(to).isValid()) {
        return false;
      }

      return true;
    },

    async fetch() {
      if (!this.isValidPeriod()) {
        return;
      }

      const params = this.getParams();

      const {
        data,
        pie_chart,
        total_records,
      } = await walletService.getDiscountReport(params);

      this.pie = pie_chart;
      this.transactions = data;
      this.totalRecords = total_records;
      this.createPieChart();
    },

    async exportToExcel() {
      try {
        const response = await walletService.downloadDiscountReport({
          from: this.fromTo[0],
          to: this.fromTo[1],
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `discount_report_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    insertLinebreaks(t, words) {
      var el = d3.select(t);
      el.text("");

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", 0).attr("dy", "15");
      }
    },

    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
          }),
        })
        .catch((err) => {});
    },

    createPieChart() {
      const _self = this;

      let columns = [];

      this.pie.labels.map(function (label, index) {
        return columns.push([label, _self.pie.data[index]]);
      });

      c3.generate({
        bindto: "#chart-pie",
        size: {
          height: 400,
          width: 620,
        },
        legend: {
          show: false,
        },
        color: {
          pattern: ["#f44336", "#009688", "#2196f3", "#e91e63"],
        },
        data: {
          columns: columns,
          type: "pie",
        },
        label: {
          threshold: 0.1,
          format: function (value, ratio, id) {
            ratio = d3.format("%")(ratio); // format ratio
            return [id, value, ratio].join(); // used to pass values to the onrender function
          },
        },
        onrendered: function () {
          const _t = _self;
          const sum = columns.reduce((s, c, i) => (s += c[1]), 0);

          this.svg.selectAll("g.c3-target text").each(function (d) {
            const value = d.data.values[0].value;
            const percentage = ((value / sum) * 100).toFixed(1);
            _t.insertLinebreaks(this, [d.data.id, `${percentage}%`, value]);
          });
        },
      });
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
</style>

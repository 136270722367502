<template>
  <div class="col-md-12" v-if="team">
    <label class="mx-5">
      <h4 class="mb-0">
        Text area
      </h4>
    </label>

    <div class="ml-10">
      <h5>
        Text Group 1: Free Text
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td colspan="5">
            <input
              type="text"
              class="form-control"
              name="text1_content"
              width-300px
              @change="update($event)"
              v-model="team.text1_content"
              :disabled="disabled"
            />
          </td>
        </tr>
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text1_color"
                @change="update($event, 'color')"
                v-model="team.text1_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text1_color"
                @change="update($event, 'color')"
                v-model="team.text1_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text1_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text1_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text1_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text1_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text1_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text1_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text1_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text1_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text1_size"
                v-model="team.text1_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text1_stroke_width"
                v-model="team.text1_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text1_shadow_width"
                v-model="team.text1_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 2: SFPoint XXX คะแนน, Reward
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text2_color"
                @change="update($event, 'color')"
                v-model="team.text2_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text2_color"
                @change="update($event, 'color')"
                v-model="team.text2_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text2_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text2_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text2_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text2_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text2_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text2_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text2_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text2_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text2_size"
                v-model="team.text2_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text2_stroke_width"
                v-model="team.text2_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text2_shadow_width"
                v-model="team.text2_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 3: Free text
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td colspan="5">
            <input
              type="text"
              class="form-control"
              name="text3_content"
              width-300px
              @change="update($event)"
              v-model="team.text3_content"
              :disabled="disabled"
            />
          </td>
        </tr>
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text3_color"
                @change="update($event, 'color')"
                v-model="team.text3_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text3_color"
                @change="update($event, 'color')"
                v-model="team.text3_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text3_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text3_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text3_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text3_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text3_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text3_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text3_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text3_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text3_size"
                v-model="team.text3_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text3_stroke_width"
                v-model="team.text3_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text3_shadow_width"
                v-model="team.text3_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 4: New line & free text
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td colspan="5">
            <input
              type="text"
              class="form-control"
              name="text4_content"
              width-300px
              @change="update($event)"
              v-model="team.text4_content"
              :disabled="disabled"
            />
          </td>
        </tr>
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text4_color"
                @change="update($event, 'color')"
                v-model="team.text4_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text4_color"
                @change="update($event, 'color')"
                v-model="team.text4_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text4_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text4_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text4_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text4_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text4_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text4_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text4_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text4_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text4_size"
                v-model="team.text4_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text4_stroke_width"
                v-model="team.text4_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text4_shadow_width"
                v-model="team.text4_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 5: Target
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text5_color"
                @change="update($event, 'color')"
                v-model="team.text5_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text5_color"
                @change="update($event, 'color')"
                v-model="team.text5_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text5_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text5_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text5_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text5_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text5_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text5_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text5_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text5_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text5_size"
                v-model="team.text5_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text5_stroke_width"
                v-model="team.text5_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text5_shadow_width"
                v-model="team.text5_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 6: แถว
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text6_color"
                @change="update($event, 'color')"
                v-model="team.text6_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text6_color"
                @change="update($event, 'color')"
                v-model="team.text6_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text6_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text6_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text6_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text6_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text6_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text6_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text6_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text6_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text6_size"
                v-model="team.text6_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text6_stroke_width"
                v-model="team.text6_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text6_shadow_width"
                v-model="team.text6_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 7: New line & free text
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td colspan="5">
            <input
              type="text"
              class="form-control"
              name="text7_content"
              width-300px
              @change="update($event)"
              v-model="team.text7_content"
              :disabled="disabled"
            />
          </td>
        </tr>
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text7_color"
                @change="update($event, 'color')"
                v-model="team.text7_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text7_color"
                @change="update($event, 'color')"
                v-model="team.text7_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text7_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text7_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text7_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text7_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text7_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text7_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text7_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text7_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text7_size"
                v-model="team.text7_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text7_stroke_width"
                v-model="team.text7_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text7_shadow_width"
                v-model="team.text7_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 8: Progress Result (ตรวจแล้ว... รอการตรวจ)
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text8_color"
                @change="update($event, 'color')"
                v-model="team.text8_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text8_color"
                @change="update($event, 'color')"
                v-model="team.text8_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text8_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text8_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text8_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text8_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text8_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text8_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text8_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text8_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text8_size"
                v-model="team.text8_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text8_stroke_width"
                v-model="team.text8_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text8_shadow_width"
                v-model="team.text8_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-10">
      <h5>
        Text Group 9: Progress Value (XXXXX)
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text9_color"
                @change="update($event, 'color')"
                v-model="team.text9_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text9_color"
                @change="update($event, 'color')"
                v-model="team.text9_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text stroke</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text9_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text9_stroke_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text9_stroke_color"
                @change="update($event, 'color')"
                v-model="team.text9_stroke_color"
                :disabled="disabled"
              />
            </div>
          </td>

          <td>Text shadow</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="text9_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text9_shadow_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="text9_shadow_color"
                @change="update($event, 'color')"
                v-model="team.text9_shadow_color"
                :disabled="disabled"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text9_size"
                v-model="team.text9_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
          <td>Stroke width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text9_stroke_width"
                v-model="team.text9_stroke_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>

          <td>Shadow width</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="text9_shadow_width"
                v-model="team.text9_shadow_width"
                :disabled="disabled"
              />
              px
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      team: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update(event, type) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        const form = {};
        form[name] = value;

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await progressBarService.updateCampaignInfo(id, { team: form });

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
  },

  mounted() {
    this.team = this.campaign.team;

    this.team.text1_color = `#${this.team.text1_color}`;
    this.team.text1_stroke_color = `#${this.team.text1_stroke_color}`;
    this.team.text1_shadow_color = `#${this.team.text1_shadow_color}`;

    this.team.text2_color = `#${this.team.text2_color}`;
    this.team.text2_stroke_color = `#${this.team.text2_stroke_color}`;
    this.team.text2_shadow_color = `#${this.team.text2_shadow_color}`;

    this.team.text3_color = `#${this.team.text3_color}`;
    this.team.text3_stroke_color = `#${this.team.text3_stroke_color}`;
    this.team.text3_shadow_color = `#${this.team.text3_shadow_color}`;

    this.team.text4_color = `#${this.team.text4_color}`;
    this.team.text4_stroke_color = `#${this.team.text4_stroke_color}`;
    this.team.text4_shadow_color = `#${this.team.text4_shadow_color}`;

    this.team.text5_color = `#${this.team.text5_color}`;
    this.team.text5_stroke_color = `#${this.team.text5_stroke_color}`;
    this.team.text5_shadow_color = `#${this.team.text5_shadow_color}`;

    this.team.text6_color = `#${this.team.text6_color}`;
    this.team.text6_stroke_color = `#${this.team.text6_stroke_color}`;
    this.team.text6_shadow_color = `#${this.team.text6_shadow_color}`;

    this.team.text7_color = `#${this.team.text7_color}`;
    this.team.text7_stroke_color = `#${this.team.text7_stroke_color}`;
    this.team.text7_shadow_color = `#${this.team.text7_shadow_color}`;

    this.team.text8_color = `#${this.team.text8_color}`;
    this.team.text8_stroke_color = `#${this.team.text8_stroke_color}`;
    this.team.text8_shadow_color = `#${this.team.text8_shadow_color}`;

    this.team.text9_color = `#${this.team.text9_color}`;
    this.team.text9_stroke_color = `#${this.team.text9_stroke_color}`;
    this.team.text9_shadow_color = `#${this.team.text9_shadow_color}`;
    
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

table tr td {
  &:nth-child(1) {
    width: 10%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 12%;
  }
  &:nth-child(6) {
    width: 20%;
  }
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

.css-input-text {
  input[type="number"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem;
    margin-right: 6px;
  }
}

input[type="text"],
input[type="color"],
input[type="number"] {
  &:disabled {
    background-color: #efefef;
  }
}
</style>

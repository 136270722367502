export const peddlerColumns = [
  {
    label: "PID",
    field: "peddler_id",
    type: "number",
  },
  {
    label: "PLogin",
    field: "username",
  },
  {
    label: "Peddler Name",
    field: "peddler_name",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Shop Name",
    field: "shop_name",
  },
  {
    label: "Province",
    field: "province",
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => {
      return +value === 0
        ? "INACTIVE"
        : +value === 1
        ? "ACTIVE"
        : value.toUpperCase();
    },
  },
  {
    label: "Last Login",
    field: "cur_login",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Modified by",
    field: "modified_by_username",
  },
];

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.monthlyshort
    ? _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { attrs: { "my-15px": "" } }, [
            _vm._m(0),
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "radio-custom radio-primary" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.monthlyshort.progress_reset,
                      expression: "monthlyshort.progress_reset",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "progress_reset",
                    value: "none",
                    disabled: _vm.disabled,
                    id: "reset_none",
                  },
                  domProps: {
                    checked: _vm._q(_vm.monthlyshort.progress_reset, "none"),
                  },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(
                          _vm.monthlyshort,
                          "progress_reset",
                          "none"
                        )
                      },
                      _vm.update,
                    ],
                  },
                }),
                _c("label", { attrs: { for: "reset_none" } }, [_vm._v("none")]),
              ]),
              _c("div", { staticClass: "radio-custom radio-primary mx-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.monthlyshort.progress_reset,
                      expression: "monthlyshort.progress_reset",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "progress_reset",
                    value: "month",
                    disabled: _vm.disabled,
                    id: "reset_every_month",
                  },
                  domProps: {
                    checked: _vm._q(_vm.monthlyshort.progress_reset, "month"),
                  },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(
                          _vm.monthlyshort,
                          "progress_reset",
                          "month"
                        )
                      },
                      _vm.update,
                    ],
                  },
                }),
                _c("label", { attrs: { for: "reset_every_month" } }, [
                  _vm._v("Every month"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v(" Progress & Target Reset ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import Lot from "@/views/lot/Index.vue";
import LotList from "@/views/lot/List.vue";
import LotEdit from "@/views/lot/Edit.vue";

export default {
  path: "/lot",
  name: "Lot",
  component: Lot,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/",
      name: "LotList",
      component: LotList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "edit/:id",
      name: "LotEdit",
      component: LotEdit,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAdminOrSupervisor
    ? _c(
        "div",
        { attrs: { id: "prize-status-bulk-upload" } },
        [
          _c("div", { staticClass: "inner" }, [
            _vm.status != null
              ? _c("div", [
                  _vm.status.active === 0
                    ? _c("div", { staticClass: "wrapper-upload-form" }, [
                        _c(
                          "label",
                          {
                            staticClass: "btn btn-round btn-upload",
                            attrs: {
                              disabled: _vm.loading,
                              for: "bulk-upload-file-input",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-upload",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" Bulk update "),
                          ]
                        ),
                        _c("input", {
                          staticClass: "input-select-file",
                          attrs: { type: "file", id: "bulk-upload-file-input" },
                          on: { change: _vm.onFileSelected },
                        }),
                      ])
                    : _vm.status.active === 1 &&
                      _vm.status.type == "pool" &&
                      _vm.status.state != "execute"
                    ? _c("div", { staticClass: "wrapper-upload-form" }, [
                        _vm.status.state == "verify"
                          ? _c("label", { staticClass: "label-orange" }, [
                              _vm._v(" Verifying change "),
                            ])
                          : _vm.status.state == "done-verify" && _vm.loading
                          ? _c("label", { staticClass: "label-blue" }, [
                              _vm._v(" Loading... "),
                            ])
                          : _vm.status.state == "done-verify" && !_vm.loading
                          ? _c("label", { staticClass: "label-blue" }, [
                              _vm._v(" Click to commit change "),
                            ])
                          : _vm._e(),
                        _vm.status.state == "verify"
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-round btn-primary mx-10",
                                  attrs: { type: "button", disabled: "" },
                                },
                                [_vm._v(" Commit ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-default",
                                  attrs: { type: "button", disabled: "" },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ])
                          : _vm.status.state == "done-verify"
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-round btn-primary mx-10",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.loading,
                                  },
                                  on: { click: _vm.getResult },
                                },
                                [_vm._v(" Commit ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-default",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.loading,
                                  },
                                  on: { click: _vm.cancelProcess },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm.status.active === 1 &&
                      _vm.status.type == "pool" &&
                      _vm.status.state == "execute"
                    ? _c("div", { staticClass: "wrapper-upload-form" }, [
                        _c("label", { staticClass: "label-orange" }, [
                          _vm._v(" Processing change. "),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "dropdown" }, [
              _vm._m(0),
              _c("div", { staticClass: "dropdown-menu" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.downloadExampleLink("add") },
                  },
                  [_c("b", [_vm._v("Add")]), _vm._v(" action template ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.downloadExampleLink("update") },
                  },
                  [_c("b", [_vm._v("Update")]), _vm._v(" action template ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.downloadExampleLink("remove") },
                  },
                  [_c("b", [_vm._v("Remove")]), _vm._v(" action template ")]
                ),
              ]),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                " Last updated: " +
                  _vm._s(_vm.detail.last_pool_upload) +
                  " by " +
                  _vm._s(_vm.detail.last_pool_upload_username || "-") +
                  " "
              ),
            ]),
          ]),
          _c(
            "modal",
            {
              attrs: {
                width: 600,
                height: "auto",
                clickToClose: false,
                name: _vm.modalName,
              },
            },
            [
              _c("TryUploadModal", {
                attrs: { result: _vm.result, name: _vm.modalName },
                on: { confirm: _vm.confirmUpload },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-primary dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", {
          staticClass: "mdi mdi-file-excel-outline",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" Download template "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export const FormCreateValidateRule = {
  name: {
    required,
    minLength: minLength(0),
    maxLength: maxLength(256),
  },
};

export const FormEditValidateRule = {
  name: {
    required,
    minLength: minLength(0),
    maxLength: maxLength(256),
  },
};

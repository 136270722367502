<template>
  <div class="col-md-8" v-if="stamp">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Banner
            <span class="small light">Recommended size 375 x 188 pixels</span>
          </h4>
        </label>

        <div class="form-group">
          <div class="image-preview">
            <img class="img img-fluid" v-if="picture" :src="picture" />
            <div v-else class="image-placeholder"></div>
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="uploadImage"
            ref="banner_picture"
            name="banner_picture"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Banner picture error.",
        success: "Upload Banner picture success.",
      },
    };
  },

  computed: {
    picture() {
      return this.campaign.stamp.banner_picture;
    },
  },

  methods: {
    validateFileUpload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        event.target.files[0].value = "";
        throw new Error("File shall not exceed 1 MB");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/upload.form.scss";
</style>

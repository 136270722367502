<template>
  <div class="row">
    <div class="col-4">
      <div class="radio-input-row radio-custom radio-primary">
        <input
          type="radio"
          value="ecoupon"
          v-model="reward.reward_type"
          :name="`reward_type_${index}`"
          :id="`reward_type_ecoupon_${index}`"
          @change="update($event, 'reward_type')"
          :disabled="disabled"
        />
        <label :for="`reward_type_ecoupon_${index}`">E-Coupon</label>
      </div>
    </div>
    <div class="col-8">
      <div class="form-group-row mb-5">
        <label>Face Value</label>
        <input
          min="1"
          max="999999"
          width-100px
          type="number"
          @change="update"
          class="form-control"
          name="ecoupon_face_value"
          v-model.number="reward.ecoupon_face_value"
          :disabled="disabled || reward.reward_type !== 'ecoupon'"
        />
        <div class="unit">bht</div>
      </div>
      <div class="form-group-row">
        <label>Top Up</label>
        <input
          min="0"
          max="999999"
          width-100px
          type="number"
          @change="update"
          name="ecoupon_topup"
          class="form-control"
          v-model.number="reward.ecoupon_topup"
          :disabled="disabled || reward.reward_type !== 'ecoupon'"
        />
        <div class="unit">bht</div>
      </div>
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  data() {
    return {
      message: {
        error: `Update E-Coupon of Reward #${this.reward.id} error.`,
        success: `Update E-Coupon of Reward #${this.reward.id} success.`,
      },
    };
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columnConfig.column_display
    ? _c(
        "div",
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                mode: "remote",
                rows: _vm.list,
                columns: _vm.columns,
                totalRows: _vm.totalRecords,
                "sort-options": _vm.sortOption,
                "search-options": _vm.searchOption,
                "pagination-options": _vm.pagination,
              },
              on: {
                "on-search": _vm.onSearch,
                "on-sort-change": _vm.onSortChange,
                "on-page-change": _vm.onPageChange,
                "on-per-page-change": _vm.onPerPageChange,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function (props) {
                      return [
                        props.column.field == "status"
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toUpperCase")(props.row.status)
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                3152798427
              ),
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c("ImportProgress", {
                    attrs: {
                      campaign: _vm.campaign,
                      columnConfig: _vm.columnConfig,
                    },
                    on: { updated: _vm.fetch },
                  }),
                  _c("div", { staticClass: "Excel" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: { click: _vm.exportToExcel },
                      },
                      [
                        _c("i", { staticClass: "fa fa-file-excel-o" }),
                        _vm._v(" Export to Excel "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="col-md-12" v-if="monthly">
    <label class="mx-5">
      <h4 class="mb-0">Extra text and Reward Extra</h4>
    </label>
    <div class="ml-10 extra-text-and-reward-extra-table">
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content 1</td>
          <td>
            <input
              type="text"
              class="form-control"
              name="text6_content1"
              width-200px
              @change="update($event)"
              v-model="monthly.text6_content1"
              :disabled="disabled"
            />
          </td>
          <td align="right">
            <label for="text6_target_extra">Target extra</label>
          </td>
          <td>
            <div class="form-group">
              <div class="checkbox-custom checkbox-primary">
                <input
                  type="checkbox"
                  :true-value="1"
                  :false-value="0"
                  id="text6_target_extra"
                  name="text6_target_extra"
                  @change="update($event)"
                  v-model="monthly.text6_target_extra"
                  :disabled="disabled"
                />
                <label for="enable_quiz_checkbox"></label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Text content 3</td>
          <td>
            <input
              type="text"
              class="form-control"
              name="text6_content3"
              width-200px
              @change="update($event)"
              v-model="monthly.text6_content3"
              :disabled="disabled"
            />
          </td>
          <td align="right">Target unit</td>
          <td>
            <input
              type="text"
              class="form-control"
              name="text6_target_unit"
              @change="update($event)"
              v-model="monthly.text6_target_unit"
              :disabled="disabled"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />
    <label class="mx-5">
      <h4>Monthly Summary</h4>
    </label>

    <div class="ml-10 monthly-summary-table">
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Month 1</td>
          <td>
            <input
              type="text"
              class="form-control"
              name="text7_month1"
              width-200px
              @change="update($event)"
              v-model="monthly.text7_month1"
              :disabled="disabled"
            />
          </td>
          <td>Month 2</td>
          <td>
            <input
              type="text"
              class="form-control"
              name="text7_month2"
              width-200px
              @change="update($event)"
              v-model="monthly.text7_month2"
              :disabled="disabled"
            />
          </td>
          <td>Month 3</td>
          <td>
            <input
              type="text"
              class="form-control"
              name="text7_month3"
              width-200px
              @change="update($event)"
              v-model="monthly.text7_month3"
              :disabled="disabled"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      monthly: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update(event, type) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        const form = {};
        form[name] = this.monthly[name];

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await progressBarService.updateCampaignInfo(id, { monthly: form });

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
  },

  mounted() {
    this.monthly = this.campaign.monthly;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.extra-text-and-reward-extra-table table tr td {
  &:nth-child(1) {
    width: 110px;
  }
  &:nth-child(2) {
    // width: 20%;
  }
  &:nth-child(3) {
    width: 110px;
  }
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 12%;
  }
  &:nth-child(6) {
    width: 20%;
  }
}

.monthly-summary-table table tr td {
  &:nth-child(even) {
    width: 240px;
  }
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

.css-input-text {
  input[type="number"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem;
    margin-right: 6px;
  }
}

input[type="text"],
input[type="color"],
input[type="number"] {
  &:disabled {
    background-color: #efefef;
  }
}

input[type="text"] {
  max-width: 120px;
}
</style>

<template>
  <div class="col-md-12" v-if="campaign">
    <label class="form-control-label">
      <h4>Campaign Duration</h4>
    </label>
    <label class="d-inline mx-10 form-control-label">
      Start
    </label>
    <div class="d-inline mx-10">
      <DatePicker
        :confirm="true"
        :clearable="false"
        type="datetime"
        width-150px
        @change="update('start_date')"
        placeholder="n/a"
        ref="start_date"
        format="YYYY-MM-DD HH:mm"
        value-type="YYYY-MM-DD HH:mm"
        :disabled="disabled"
        v-model="campaign.start_date"
      />
    </div>
    <label class="d-inline mx-10 form-control-label">
      End
    </label>
    <div class="d-inline mx-10">
      <DatePicker
        :confirm="true"
        :clearable="false"
        type="datetime"
        width-150px
        @change="update('end_date')"
        placeholder="n/a"
        ref="end_date"
        format="YYYY-MM-DD HH:mm"
        value-type="YYYY-MM-DD HH:mm"
        :disabled="disabled"
        v-model="campaign.end_date"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { luckyDrawService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  mounted() {
    const { start_date, end_date } = this.campaign;
    this.oldValue = { start_date, end_date };
  },

  methods: {
    async isFormValid(key) {
      switch (key) {
        case "start_date":
          if (
            new Date().setHours(0, 0, 0, 0) >=
            new Date(this.campaign.start_date)
          ) {
            await this.$dialogs.alert("Start date ต้องเป็นวันที่ในอนาคต.");

            const { start_date, end_date } = this.oldValue;
            this.setDefaultValues(start_date, end_date);

            return false;
          }

          if (
            new Date(this.campaign.start_date) >=
            new Date(this.campaign.end_date)
          ) {
            await this.$dialogs.alert(
              "กรุณาระบุวันเริ่มที่น้อยกว่าวันสิ้นสุด campaign"
            );
            const { start_date, end_date } = this.oldValue;
            this.setDefaultValues(start_date, end_date);

            return false;
          }

          return true;

        case "end_date":
          if (
            new Date(this.campaign.start_date) >=
            new Date(this.campaign.end_date)
          ) {
            await this.$dialogs.alert(
              "กรุณาระบุวันเริ่มที่น้อยกว่าวันสิ้นสุด campaign"
            );
            const { start_date, end_date } = this.oldValue;
            this.setDefaultValues(start_date, end_date);

            return false;
          }

          return true;

        default:
          return false;
      }
    },

    async update(key) {
      try {
        const formValid = await this.isFormValid(key);
        if (!formValid) return;

        const { id, start_date, end_date } = this.campaign;

        const form = {};

        if (key == "end_date")
          form[key] = moment(this.campaign[key]).format("YYYY-MM-DD HH:mm");

        if (key == "start_date")
          form[key] = moment(this.campaign[key]).format("YYYY-MM-DD HH:mm");

        await luckyDrawService.updateCampaignInfo(id, form);

        this.oldValue = { start_date, end_date };

        this.$emit("updated");
        this.$notify(`Campaign Duration updated.`);
      } catch (error) {
        this.$dialogs.alert(`Campaign Duration update error.`);
      }
    },

    setDefaultValues(start, end) {
      this.campaign.end_date = end;
      this.campaign.start_date = start;

      this.duration = moment(new Date(end)).diff(new Date(start), "days") + 1;
    },
  },
};
</script>

<style></style>

import WeeklyLoginIndex from "./Index.vue";
import WeeklyLoginList from "./campaign/Index.vue";
import WeeklyLoginEdit from "./campaign/Edit.vue";
import WeeklyLoginTransactionLog from "./transaction-log/Index.vue";

export default {
  path: "/weekly-login",
  name: "WeeklyLogin",
  component: WeeklyLoginIndex,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaign",
      name: "WeeklyLoginList",
      component: WeeklyLoginList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "edit/:id",
      name: "WeeklyLoginEdit",
      component: WeeklyLoginEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "transaction-log",
      name: "WeeklyLoginTransactionLog",
      component: WeeklyLoginTransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Discount Report")]),
        _c("breadcrumb", { attrs: { name: "POINT_DISCOUNT_REPORT" } }),
        _c(
          "div",
          { staticClass: "page-header-right" },
          [
            _vm._v(" Selected Period "),
            _c("date-picker", {
              attrs: {
                type: "date",
                "value-type": "format",
                format: "YYYY-MM-DD",
                range: "",
                placeholder: "Select date range",
              },
              model: {
                value: _vm.fromTo,
                callback: function ($$v) {
                  _vm.fromTo = $$v
                },
                expression: "fromTo",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "page-header-actions" }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    rows: _vm.list || [],
                    columns: _vm.columns,
                    totalRows: _vm.totalRecords,
                    "sort-options": _vm.sortOption,
                    "search-options": _vm.searchOption,
                    "pagination-options": _vm.pagination,
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPerPageChange,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c("div", { staticClass: "Excel" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: { click: _vm.exportToExcel },
                          },
                          [
                            _c("i", { staticClass: "fa fa-file-excel-o" }),
                            _vm._v(" Export to Excel "),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel" }, [
      _c("div", { staticClass: "panel-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-5" }, [
            _c("div", { attrs: { id: "chart-pie" } }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
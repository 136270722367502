import qs from "qs";
import HttpRequest from "./request";

export class CharacterProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/character`);
  }

  async getCharacter(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createCharacter(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editCharacter(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }
}

<template>
  <div class="col-md-10 mx-10" v-if="campaign">
    <label class="label">LUM Groups</label>
    <div class="form-group">
      <Selectize
        multiple
        :disabled="disabled"
        :settings="groupSelectizeSetting"
        v-model="selected"
      >
        <option v-for="group in lumUserGroup" :key="group.id" :value="group.id">
          {{ ` ${group.id} - ${group.group_name}` }}
        </option>
      </Selectize>
    </div>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { luckyDrawService, userGroupService } from "@/api/client";

export default {
  name: "LUMGroupSetting",

  props: ["campaign"],

  components: {
    Selectize,
  },

  data() {
    return {
      selected: [],
      lumUserGroup: [],
      groupSelectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        onChange: this.update,
      },
    };
  },

  computed: {
    disabled() {
      return !["draft"].includes(this.campaign.status);
    },
  },

  async created() {
    await this.fetchLUMGroup();
  },

  mounted() {
    this.selected = this.campaign.line_lum.map((o) => o.lum_id);
  },

  methods: {
    async update(groups) {
      try {
        const { id } = this.campaign;
        const form = { lum_id: groups.map((o) => +o) };

        await luckyDrawService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`LUM group Updated.`);
      } catch (error) {
        this.$dialogs.alert(`LUM group update error.`);
      }
    },

    async fetchLUMGroup() {
      this.lumUserGroup = await userGroupService.getLUMUserGroup();
    },
  },
};
</script>

<style></style>

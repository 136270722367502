<template>
  <div class="col-md-10" v-if="barcode">
    <div class="mx-5">
      <div class="d-flex justify-content-between">
        <h4>
          Targets and Rewards (targets are cumulative, rewards are incremental)
        </h4>
        <div class="d-flex align-items-center">
          <span class="w100">No. Targets:</span>
          <select
            @change="update"
            name="no_targets"
            :disabled="disabled"
            class="form-control w100"
            v-model.number="barcode.no_targets"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      targets: [],
      barcode: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update() {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        const form = {};
        form[name] = value;

        await progressBarService.updateCampaignInfo(id, { barcode: form });

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
    async fetchTarget() {
      const campaignId = this.campaign.id;
      this.targets = await progressBarService.getTargetRewardList(
        campaignId,
        "barcode"
      );
    },
  },

  mounted() {
    this.barcode = this.campaign.barcode;
    this.fetchTarget();
  },
};
</script>

<style lang="scss" scoped>
.w100 {
  width: 100px;
}
</style>

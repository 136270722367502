var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _c("h3", { staticClass: "panel-title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
        ]),
        _c("div", { staticClass: "panel-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "panel-footer p-10" }, [
        _c("div", { staticClass: "d-flex justify-content-between px-30" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-primary w-100",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-check",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" OK "),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-default mx-10 w-100",
              attrs: { type: "button" },
              on: { click: _vm.close },
            },
            [
              _c("i", {
                staticClass: "icon md-close",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Cancel "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="form-group">
    <label class="mx-5">
      <h5>Description</h5>
    </label>
    <ckeditor
      v-model="value"
      :read-only="disabled"
      @blur="handleDescriptionChange"
    />
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      value: "",
      message: {
        error: `Update Prize description error.`,
        success: `Update Prize description success.`,
      },
    };
  },

  methods: {
    initial() {
      this.value = this.pool.description;
    },

    handleDescriptionChange() {
      if (this.value == this.pool.description) return;

      const event = {
        target: {
          name: "description",
          value: this.value,
        },
      };

      this.update(event);
    },
  },
};
</script>

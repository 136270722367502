<template>
  <div id="pool-status-info" class="container-fluid" v-if="info">
    <div class="row">
      <div class="col-12">
        <div class="top-row">
          <h4>{{ info.name }} - {{ info.id }}</h4>
          <div class="status-type">
            <h4>Status - {{ info.status | toUpperCase }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="counter-info">
          <div class="info-column">
            <label>Limit (bht)</label>
            <div>{{ info.budget_limit | numberWithCommas }}</div>
          </div>
          <div class="info-column">
            <label>Balance (bht)</label>
            <div>{{ info.balance | numberWithCommas }}</div>
          </div>
          <div class="info-column">
            <label>No. Reward Granted</label>
            <div>{{ info.no_reward_granted | numberWithCommas }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info"],
};
</script>

<style lang="scss" scoped>
#pool-status-info {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;

  .top-row {
    display: flex;
    padding: 0px 1rem;
    justify-content: space-between;
    
  }

  .info-row {
    gap: 1rem;
    display: flex;
    margin-left: 1rem;

    label {
      color: #444;
      width: 100px;
      font-weight: bold;
    }

    [prize-detail] {
      width: 560px;
      height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .counter-info {
    gap: 3rem;
    display: flex;
    margin-top: 1.5rem;
  }

  .info-column {
    gap: 0.5rem;
    display: flex;
    margin-left: 1rem;

    label {
      color: #444;
      font-weight: bold;
    }

    .green {
      color: #4caf50;
    }
  }

  .status-type {
    max-width: 200;

    gap: 2rem;
    display: flex;
    justify-content: flex-start;
  }
}
</style>

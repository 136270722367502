<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading" @click.stop="togglePanelBody">
          <h3 class="panel-title">Round {{ index + 1 }} - {{ round.name }}</h3>
          <div class="panel-actions panel-actions-keep">
            <button class="btn btn-default mr-5" @click.stop="togglePanelBody">
              <i v-if="isPanelBodyVisible" class="mdi mdi-chevron-up"></i>
              <i v-else class="mdi mdi-chevron-down"></i>
            </button>
            <button
              class="btn btn-default"
              :disabled="!canRemoveRound"
              @click.stop="removeRound(round)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="panel-body" v-if="isPanelBodyVisible">
          <RoundName :round="round" />
          <RoundDuration :round="round" />
          <RanksAndRewards :round="round" />
          <RoundQuestions :round="round" />
        </div>
      </div>
    </div>
    <div class="panel-footer p-10"></div>
  </div>
</template>

<script>
import RoundName from "./RoundName.vue";
import RoundDuration from "./RoundDuration.vue";
import RanksAndRewards from "./RanksAndRewards.vue";
import RoundQuestions from "./RoundQuestions.vue";

export default {
  props: ["campaign", "round", "index"],

  components: {
    RoundName,
    RoundDuration,
    RanksAndRewards,
    RoundQuestions,
  },

  data() {
    return {
      isPanelBodyVisible: this.index === 0,
    };
  },

  computed: {
    canRemoveRound() {
      return this.$parent.rounds.length > 1;
    },
  },

  methods: {
    removeRound(round) {
      if (this.canRemoveRound) this.$emit("remove", round);
    },
    togglePanelBody() {
      this.isPanelBodyVisible = !this.isPanelBodyVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-body {
  gap: 2rem;
  display: flex;
  flex-direction: column;

  .panel-heading {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
</style>

<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'end'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Nothing
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { weeklyLoginService } from "@/api/client";

export default {
  props: ["campaign"],
  methods: {
    validate(status) {
      const { name, start_date, days_per_period } = this.campaign;

      switch (status) {
        case "scheduled":
          // Name
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign name");
            return false;
          }

          if (name.length > 255) {
            this.$dialogs.alert(
              "Campaign name ต้องมีความยาวตัวอักษรไม่เกิน 255 ตัวอักษร"
            );
            return false;
          }

          // Date
          if (!start_date) {
            this.$dialogs.alert("กรุณากรอก Start date");
            return false;
          }

          if (
            moment(start_date).diff(new Date().setHours(0, 0, 0, 0), "days") < 1
          ) {
            this.$dialogs.alert("กรุณาระบุ Start date เป็นวันที่ในอนาคต");
            return false;
          }

          // Days per Period
          if (
            !days_per_period ||
            isNaN(+days_per_period) ||
            days_per_period < 1 ||
            days_per_period > 365
          ) {
            this.$dialogs.alert(
              "กรุณาระบุ Days per Period ที่มีค่าตั้งแต่ 1 - 365"
            );
            return false;
          }

          break;
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await weeklyLoginService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

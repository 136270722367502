var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lot
    ? _c("div", { staticClass: "mx-10 col-6" }, [
        _vm._m(0),
        _c("br"),
        _c("label", { staticClass: "switch" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.require_address,
                expression: "require_address",
              },
            ],
            attrs: {
              type: "checkbox",
              "true-value": 1,
              "false-value": 0,
              disabled: !_vm.canEdit,
            },
            domProps: {
              checked: Array.isArray(_vm.require_address)
                ? _vm._i(_vm.require_address, null) > -1
                : _vm._q(_vm.require_address, 1),
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.require_address,
                    $$el = $event.target,
                    $$c = $$el.checked ? 1 : 0
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.require_address = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.require_address = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.require_address = $$c
                  }
                },
                _vm.update,
              ],
            },
          }),
          _c("span", { staticClass: "slider round" }),
        ]),
        _c("p", { staticClass: "mx-10 d-inline" }, [
          _vm._v(" " + _vm._s(+_vm.require_address === 1 ? "ON" : "OFF") + " "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-label" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Require Address")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="col-md-6">
    <div class="form-group" my-15px>
      <label class="mx-5">
        <h4>
          Icon Earned
          <span class="small light"> (Recommended size 108 x 108 pixels) </span>
        </h4>
      </label>

      <div class="form-group">
        <div class="image-preview">
          <img class="img img-fluid" v-if="picture" :src="picture" />
          <div v-else class="image-placeholder"></div>
        </div>
        <input
          type="file"
          accept="image/*"
          class="form-control"
          @change="uploadImage"
          ref="icon_earned"
          name="icon_earned"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Icon Earned picture error.",
        success: "Upload Icon Earned picture success.",
      },
    };
  },

  computed: {
    picture() {
      return this.reward.icon_earned;
    },
  },

  methods: {
    validateFileUpload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        event.target.files[0].value = "";
        throw new Error("File shall not exceed 1 MB");
      }
    },
  },
};
</script>

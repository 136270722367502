<template>
  <div class="px-15" v-if="pool">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ pool.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="pool.status == 'active'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('inactive')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>
          INACTIVE
        </a>
        <a
          v-if="pool.status == 'inactive'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('active')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>
          ACTIVE
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["pool"],

  methods: {
    validate(status) {
      const { prize_name } = this.pool;

      switch (status) {
        case "active":
          if (!prize_name) {
            this.$dialogs.alert("กรุณาระบุ Prize name.");
            return false;
          }
      }

      return true;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.pool;
        const form = { status };

        await prizepoolService.updatePrizePool(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.admin
    ? _c("div", { staticClass: "page" }, [
        _c("div", { staticClass: "page-main" }, [
          _c("div", { staticClass: "page-header" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
              },
              [
                _c("h1", { staticClass: "page-title" }, [_vm._v("Admin User")]),
                _c("breadcrumb", {
                  attrs: { name: "ADMIN_EDIT", withLast: _vm.admin.username },
                  on: {
                    "update:withLast": function ($event) {
                      return _vm.$set(_vm.admin, "username", $event)
                    },
                    "update:with-last": function ($event) {
                      return _vm.$set(_vm.admin, "username", $event)
                    },
                  },
                }),
                _c("div", { staticClass: "page-title page-header-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-round btn-default mx-10",
                      attrs: { type: "button" },
                      on: { click: _vm.goBack },
                    },
                    [
                      _c("i", {
                        staticClass: "icon md-chevron-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" Back "),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "page-content container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                },
                [
                  _c("div", { staticClass: "panel panel-bordered" }, [
                    _c("div", { staticClass: "panel-heading" }, [
                      _c("div", { staticClass: "panel-title" }, [
                        _c("h4", [
                          _vm._v(
                            "Admin User Information - " + _vm._s(_vm.admin.id)
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "panel-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("h5", [_vm._v("Username")]),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                              },
                              [_vm._v(" " + _vm._s(_vm.admin.username) + " ")]
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("h5", [_vm._v("Status")]),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-round",
                                class: {
                                  "btn-default": +_vm.admin.active === 0,
                                  "btn-primary": +_vm.admin.active === 1,
                                },
                                attrs: { type: "button" },
                                on: { click: _vm.toggleActive },
                              },
                              [
                                _c("i", {
                                  staticClass: "icon",
                                  class: {
                                    "md-block": +_vm.admin.active === 0,
                                    "md-check": +_vm.admin.active === 1,
                                  },
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      +_vm.admin.active === 1
                                        ? "ACTIVE"
                                        : "INACTIVE"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("h5", [_vm._v("Description")]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.admin.description,
                                  expression: "admin.description",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                rows: "3",
                                maxlength: "80",
                                placeholder: "Name or description",
                              },
                              domProps: { value: _vm.admin.description },
                              on: {
                                change: _vm.update,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.admin,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("h5", [_vm._v("Role")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.admin.role,
                                    expression: "admin.role",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.admin,
                                        "role",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.update,
                                  ],
                                },
                              },
                              _vm._l(
                                ["admin", "report", "supervisor"],
                                function (role) {
                                  return _c(
                                    "option",
                                    { key: role, domProps: { value: role } },
                                    [_vm._v(" " + _vm._s(role) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("h5", [_vm._v("Password")]),
                            !_vm.formResetActive
                              ? _c(
                                  "div",
                                  { staticClass: "form-reset-password" },
                                  [
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Click to reset user password"),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-round",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            _vm.formResetActive = true
                                          },
                                        },
                                      },
                                      [_vm._v(" Reset ")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "form-reset-password",
                                    class: { active: _vm.formResetActive },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Enter new password (8 characters)"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password,
                                            expression: "password",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          maxlength: "8",
                                          minlength: "8",
                                        },
                                        domProps: { value: _vm.password },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password = $event.target.value
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            type: "button",
                                            disabled: !_vm.readyUpdatePassword,
                                          },
                                          on: { click: _vm.changePassword },
                                        },
                                        [_vm._v(" Confirm ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-default",
                                          attrs: { type: "button" },
                                          on: {
                                            click: _vm.dismissFormResetPassword,
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-link",
                                        attrs: { type: "button" },
                                        on: { click: _vm.autoGeneratePassword },
                                      },
                                      [_vm._v(" Click to auto generate ")]
                                    ),
                                  ]
                                ),
                          ]),
                        ]),
                        _c("hr"),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import LeaderBoardIndex from "@/views/leaderboard/Index.vue";

import CampaignList from "@/views/leaderboard/campaign/List.vue";
import CampaignEdit from "@/views/leaderboard/campaign/Edit.vue";
import LeaderboardStatus from "@/views/leaderboard/status/Index.vue";

export default {
  path: "/game-leaderboard",
  name: "LeaderBoardIndex",
  component: LeaderBoardIndex,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaign",
      name: "leaderboard-campaign-list",
      component: CampaignList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "campaign/edit/:id",
      name: "leaderboard-campaign-edit",
      component: CampaignEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "status",
      name: "leaderboard-status",
      component: LeaderboardStatus,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

import qs from "qs";
import HttpRequest from "./request";

export class PrizePoolProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/prize_pool`);
  }

  async getVendors() {
    const { data } = await this.get(`/vendor`);
    return data;
  }

  async getPrizePoolList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/pool${queryString}`);
    return data;
  }

  async createNewPrizePool(body) {
    const { data } = await this.create(`/pool`, body);
    return data;
  }

  async getPrizePoolById(id) {
    const { data } = await this.get(`/pool/${id}`);
    return data;
  }

  async updatePrizePool(id, body) {
    const { data } = await this.update(`/pool/${id}`, body);
    return data;
  }

  async uploadPicture(id, form) {
    const { data } = await this.upload(`/pool/${id}/upload`, form);
    return data;
  }

  async getPrizePoolStatus() {
    const { data } = await this.get(`/status`);
    return data;
  }

  async downloadPrizePoolStatus() {
    return await this.axiosInstance.get(`/status/download`, {
      responseType: "blob",
    });
  }

  async getPoolStatusInfo(id) {
    const { data } = await this.get(`/status/${id}/info`);
    return data;
  }

  async getPoolStatusTable(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/status/${id}/table${queryString}`);
    return data;
  }

  async downloadPrizePoolStatusDetail(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return await this.axiosInstance.get(
      `/status/${id}/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async getPrizeThemeList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/theme${queryString}`);
    return data;
  }

  async createNewPrizeTheme(body) {
    const { data } = await this.create(`/theme`, body);
    return data;
  }

  async getPrizeThemeById(id) {
    const { data } = await this.get(`/theme/${id}`);
    return data;
  }

  async updatePrizeTheme(id, body) {
    const { data } = await this.update(`/theme/${id}`, body);
    return data;
  }

  async uploadThemePicture(id, form) {
    const { data } = await this.upload(`/theme/${id}/upload`, form);
    return data;
  }

  async getUploadStatus(poolId) {
    const { data } = await this.get(`/status/${poolId}/upload_status`);
    return data;
  }

  async uploadStatus(poolId, form) {
    const { data } = await this.upload(`/status/${poolId}/upload`, form);
    return data;
  }

  async tryUploadStatus(poolId, form) {
    const { data } = await this.upload(`/status/${poolId}/try_upload`, form);
    return data;
  }

  async tryUploadResultStatus(poolId) {
    const { data } = await this.axiosInstance.post(
      `/status/${poolId}/try_upload_result`
    );
    return data;
  }

  async tryUploadCancelStatus(poolId) {
    const { data } = await this.axiosInstance.post(
      `/status/${poolId}/try_upload_cancel`
    );
    return data;
  }

  async tryUploadConfirmStatus(poolId) {
    const { data } = await this.axiosInstance.post(
      `/status/${poolId}/try_upload_confirm`
    );
    return data;
  }
}

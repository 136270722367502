import { numberWithCommas } from "@/filters";

export const tableColumns = [
  {
    label: "Budget ID",
    field: "id",
    type: "number",
  },
  {
    label: "Budget Name",
    field: "name",
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Limit (bht)",
    field: "budget_limit",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Balance (bht)",
    field: "balance",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "No. Reward Granted",
    field: "no_reward_granted",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Start Time",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End Time",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
];

export const detailTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
  {
    label: "ContactID",
    field: "contact_id",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Request ID",
    field: "request_id",
    type: "number",
  },
  {
    label: "Type",
    field: "reward_type",
  },
  {
    label: "Change (bht)",
    field: "change",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Balance (bht)",
    field: "balance",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "App Name",
    field: "app_id",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "App Campaign ID",
    field: "app_campaign_id",
    type: "number",
  },
  {
    label: "App Ref",
    field: "app_ref",
    type: "number",
  },
  {
    label: "Theme ID",
    field: "theme_id",
    type: "number",
  },
  {
    label: "Pool ID",
    field: "pool_id",
    type: "number",
  },
  {
    label: "Item ID",
    field: "item_id",
    type: "number",
  },
];

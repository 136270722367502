<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyDrawService } from "@/api/client";

export default {
  props: ["campaign"],

  methods: {
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    async validate(status) {
      switch (status) {
        case "scheduled":
          if (
            new Date().setHours(0, 0, 0, 0) >=
            new Date(this.campaign.start_date).setHours(0, 0, 0, 0)
          ) {
            this.$dialogs.alert("Start date ต้องเป็นวันที่ในอนาคต.");
            return false;
          }

          if (
            new Date(this.campaign.start_date).setHours(0, 0, 0, 0) >=
            new Date(this.campaign.end_date).setHours(0, 0, 0, 0)
          ) {
            this.$dialogs.alert("End date ต้องมากกว่าหรือเท่ากับ Start date.");
            return false;
          }

          if (!this.campaign.linenoti_date) {
            this.$dialogs.alert("กรุณาระบุ LINE Reminder date");
            return false;
          }

          if (!this.campaign.term_condition) {
            this.$dialogs.alert("กรุณาระบุ Terms and condition");
            return false;
          }

          if (!this.campaign.background_picture) {
            this.$dialogs.alert("กรุณาระบุ Background picture");
            return false;
          }

          if (!this.campaign.headline_picture) {
            this.$dialogs.alert("กรุณาระบุ Headline picture");
            return false;
          }

          if (!this.campaign.button_picture) {
            this.$dialogs.alert("กรุณาระบุ Button picture");
            return false;
          }

          if (!this.campaign.prize_frame_picture) {
            this.$dialogs.alert("กรุณาระบุ Prize frame picture");
            return false;
          }

          if (!this.campaign.highlighted_frame_picture) {
            this.$dialogs.alert("กรุณาระบุ Highlighted frame picture");
            return false;
          }

          if (!this.campaign.linenoti_picture) {
            this.$dialogs.alert("กรุณาระบุ LINE Reminder Picture");
            return false;
          }

          for (let index = 0; index < this.campaign.prize.length; index++) {
            const prize = this.campaign.prize[index];

            const isValid = await this.validatePrize(prize);

            if (!isValid) {
              return false;
            }
          }

          if (!this.campaign.no_play) {
            this.$dialogs.alert("กรุณาระบุ No. Tries per period");
            return false;
          }

          if (!this.campaign.period_duration) {
            this.$dialogs.alert("กรุณาระบุ Period duration");
            return false;
          }

          break;
      }

      return true;
    },

    async validatePrize(prize) {
      if (prize.lucky_num == null || prize.lucky_num == undefined) {
        await this.$dialogs.alert(
          `Prize No. ${prize.prize_order} กรุณาระบุ Lucky Number เป็นเลขจำนวนเต็ม ที่ 0 - 999`
        );

        return false;
      }

      if (
        prize.prize_order < 11 &&
        prize.lucky_onoff === 1 &&
        (prize.sfdc_point == null ||
          prize.sfdc_point == undefined ||
          prize.sfdc_point < 1)
      ) {
        await this.$dialogs.alert(
          `Prize No. ${prize.prize_order} กรุณาระบุ SF Point เป็นเลขจำนวนเต็ม ที่ 1 - 1000000`
        );

        return false;
      }

      if (
        prize.prize_order === 11 &&
        (prize.sfdc_point == null ||
          prize.sfdc_point == undefined ||
          prize.sfdc_point < 0)
      ) {
        await this.$dialogs.alert(
          `Consolation Prize กรุณาระบุ SF Point เป็นเลขจำนวนเต็ม ที่ 0 - 1000000`
        );

        return false;
      }

      if (!prize.prize_name || prize.prize_name.length > 40) {
        await this.$dialogs.alert(
          `Prize No. ${prize.prize_order} กรุณาระบุ Prize Name เป็นข้อความที่ยาวไม่เกิน 40 ตัวอักษร`
        );

        return false;
      }

      if (
        prize.prize_order < 10 &&
        (prize.num_items <= 0 || this.isFloat(prize.num_items))
      ) {
        await this.$dialogs.alert(
          `Prize No. ${prize.prize_order} กรุณาระบุ No. Items เป็นเลขจำนวนเต็ม ที่มากกว่า 0`
        );

        return false;
      }

      if (
        prize.chance_weight <= 0 ||
        +prize.chance_weight > 1000000 ||
        this.isFloat(prize.chance_weight)
      ) {
        await this.$dialogs.alert(
          `Prize No. ${prize.prize_order} กรุณาระบุ Chance Weight เป็นเลขจำนวนเต็ม ที่ 1 - 1000000`
        );

        return false;
      }

      return true;
    },

    async update(status) {
      try {
        const valid = await this.validate(status);
        if (!valid) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await luckyDrawService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

import Vue from "vue";
import moment from "moment";
import { DateTime } from "luxon";

export function numberWithCommas(value) {
  try {
    let parts = value.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  } catch (error) {
    return "-";
  }
}

const toValidDate = (value) => {
  try {
    if (moment.isMoment(value)) return value.toISOString();

    // Invalid date in safari
    // https://stackoverflow.com/a/5646753
    const d = new Date(value.replace(/-/g, "/"));

    return DateTime.isDateTime(d) ? d : new Date(d).toISOString();
  } catch (error) {
    return new Date();
  }
};

Vue.filter("numberWithCommas", numberWithCommas);

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`.replace(`_`, ` `);
});

Vue.filter("toLowerCase", function (value) {
  if (!value) return "";
  return value.toLowerCase();
});

Vue.filter("toUpperCase", function (value) {
  if (!value) return "";
  return value.toUpperCase();
});

Vue.filter("camelize", function (value) {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
});

Vue.filter("date", function (value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATE_FULL);
});

Vue.filter("dateTime", function (value) {
  const d = DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATE_FULL);

  const t = DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.TIME_24_SIMPLE);

  return moment(value).isValid() ? `${d} ${t}` : "-";
});

Vue.filter("dateTimeMedium", function (value) {
  return moment(value).format("DD MMM YYYY HH:mm");
});

Vue.filter("DATE_HUGE", function (value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATE_HUGE);
});

Vue.filter("dateTimeFromTimestamp", function (value) {
  return DateTime.fromMillis(value)
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATETIME_SHORT);
});

Vue.filter("audienceStatusText", function (value) {
  return value ? "พร้อมดาว์นโหลด" : "กำลังสร้าง";
});

Vue.filter("genderTH", function (value) {
  let genderTH = "-";
  switch (value) {
    case "male":
      genderTH = "ชาย";
      break;
    case "female":
      genderTH = "หญิง";
      break;
  }
  return genderTH;
});

Vue.filter("progressbarUserType", function (value) {
  if (!value || !["contact_id", "isms_code"].includes(value)) return "";
  return value === "contact_id" ? "Panel" : "Non-Panel";
});

Vue.filter("absolute", function (value) {
  if (!value) return;
  return Math.abs(parseInt(value));
});

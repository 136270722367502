<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Bulk update</h3>
        </div>
        <div class="panel-body">
          <h4 class="col-md-12">{{ result.message }}</h4>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="confirm"
              :disabled="result.error == 1"
            >
              <i class="icon md-check" aria-hidden="true"></i> Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["result", "name"],

  computed: {},

  methods: {
    close() {
      this.$emit("confirm", "n");
      this.$modal.hide(this.name);
    },

    confirm() {
      this.$emit("confirm", "y");
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm._m(0),
    _c("div", { staticClass: "flex-fit-content" }, [
      _c("div", { staticClass: "form-group-row" }, [
        _c("div", { staticClass: "left-box" }, [
          _c("label", { staticClass: "switch" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.pool.require_address,
                  expression: "pool.require_address",
                  modifiers: { number: true },
                },
              ],
              attrs: {
                type: "checkbox",
                "true-value": 1,
                "false-value": 0,
                disabled: _vm.disabled,
                name: "require_address",
              },
              domProps: {
                checked: Array.isArray(_vm.pool.require_address)
                  ? _vm._i(_vm.pool.require_address, null) > -1
                  : _vm._q(_vm.pool.require_address, 1),
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.pool.require_address,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = _vm._n(null),
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.pool,
                            "require_address",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.pool,
                            "require_address",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.pool, "require_address", $$c)
                    }
                  },
                  function ($event) {
                    return _vm.update(
                      $event,
                      "checkbox",
                      _vm.pool.require_address
                    )
                  },
                ],
              },
            }),
            _c("span", { staticClass: "slider round" }),
          ]),
          _c("p", { staticClass: "mx-10 d-inline" }, [
            _vm._v(
              " " + _vm._s(_vm.pool.require_address === 1 ? "ON" : "OFF") + " "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h5", [_vm._v("Require Address")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
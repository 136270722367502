<template>
  <div class="col-md-12" v-if="stamp">
    <label class="mx-5">
      <h4>Barcode Scanner</h4>
    </label>

    <div class="flex-fit-content">
      <div class="form-group-row">
        <div class="left-box">
          <p class="mx-10 d-inline">Hidden</p>
          <label class="switch">
            <input
              type="checkbox"
              :true-value="1"
              :false-value="0"
              :disabled="disabled"
              name="show_barcode_scanner"
              v-model.number="stamp.show_barcode_scanner"
              @change="update($event, 'checkbox', stamp.show_barcode_scanner)"
            />
            <span class="slider round"></span>
          </label>
          <p class="mx-10 d-inline">Shown</p>
        </div>

        <div class="right-box" v-if="!isNonPanelUser">
          <label class="mb-0">Barcode Scanner Group</label>

          <select
            type="text"
            @change="update"
            class="form-control"
            name="barcode_scanner_group"
            :disabled="disabledGroupSelector"
            v-model.number="stamp.barcode_scanner_group"
          >
            <option value="gt">GT</option>
            <option value="lamp">LAMP</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      message: {
        error: "Update Barcode scanner error",
        success: "Update Barcode scanner success",
      },
    };
  },

  computed: {
    disabledGroupSelector() {
      return this.disabled || this.stamp.show_barcode_scanner !== 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
  margin-bottom: 1rem;
  padding: 0px 1.25rem;
}

.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-bottom: 0px;
  }
}

.left-box,
.right-box {
  min-width: 230px;
}

.right-box {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 5rem;
  border-left: 1px solid #ccc;

  > * {
    flex: 1 0 auto;
  }
}

.switch {
  margin: 1rem 1.25rem;
  margin-top: 0px;
}

select {
  max-width: 150px;
}
</style>

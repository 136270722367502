<template>
  <div id="application-picker">
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-bordered mb-0">
          <div class="panel-heading">
            <h3 class="panel-title">
              Application campaign
              <p class="light">
                Please choose application and specific campaign
              </p>
            </h3>
          </div>
          <div class="panel-body" v-if="current">
            <label>Application</label>

            <select class="form-control" v-model.number="current.app_id">
              <option :value="null" disabled>Not Select</option>
              <option v-for="(v, i) in applications" :value="v.app_id" :key="i">
                {{ v.display_name }}
              </option>
            </select>

            <div class="spacer"></div>

            <label>App Campaign ID</label>

            <input
              min="0"
              type="number"
              class="form-control"
              v-model.number="current.campaign_id"
            />

            <p class="error" v-if="isCampaignError">
              Please specific App campaign ID as Number more than 0
            </p>

            <div class="spacer"></div>
          </div>
          <div class="panel-footer">
            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-round btn-default"
                @click="close"
              >
                <i class="icon md-close" aria-hidden="true"></i>
                Cancel
              </button>
              <button
                type="button"
                @click="confirm"
                :disabled="!readyToSubmit"
                class="btn btn-round btn-primary"
              >
                <i class="icon md-check" aria-hidden="true"></i> Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "target", "applications"],

  data() {
    return {
      current: null,
    };
  },

  computed: {
    readyToSubmit() {
      if (!this.current) return false;

      const { app_id, campaign_id } = this.current;

      return app_id != null && +campaign_id > 0;
    },
    isCampaignError() {
      if (!this.current) return false;

      const { campaign_id } = this.current;

      return campaign_id && +campaign_id < 1;
    },
  },

  methods: {
    close() {
      this.$modal.hide(this.name);
    },

    confirm() {
      const { app_id, campaign_id } = this.current;

      if (
        app_id !== this.target.app_id ||
        campaign_id !== this.target.campaign_id
      ) {
        this.$emit("confirm", this.current);
      }

      this.$modal.hide(this.name);
    },
  },

  mounted() {
    this.current = { ...this.target };
  },
};
</script>

<style lang="scss" scoped>
#application-picker {
  .panel-title {
    p {
      font-size: 16px;
      margin-bottom: 0px;
      margin-top: 0.5rem;
    }
  }

  .panel-body {
    padding: 1rem 2rem;
    max-height: 280px;
    overflow-y: auto;
  }

  .panel-footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .spacer {
    width: 100%;
    height: 1.5rem;
  }

  .empty {
    text-align: center;

    i {
      color: #e4eaec;
      font-size: 72px;
    }
  }

  .error {
    color: #d32f2f;
  }
}
</style>

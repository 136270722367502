import qs from "qs";
import HttpRequest from "./request";

export class DealerProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/dealers`);
  }

  async getDealers(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createDealer(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editDealer(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }
}

<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ecouponService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["campaign"],
  methods: {
    validate(status) {
      const {
        end_date,
        start_date,
        name,
        image,
        custom_headline_text,
        expiration_reminders1,
        expiration_reminders2,
        expiration_reminders3,
        coupon_collection_expiration,
      } = this.campaign;

      switch (status) {
        case "scheduled":
          // Name
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign name");
            return false;
          }

          if (name.length > 256) {
            this.$dialogs.alert(
              "Campaign name มีความยาวตัวอักษรไม่เกิน 256 ตัว"
            );
          }
          // Picture
          if (!image) {
            this.$dialogs.alert("กรุณาใส่ Announcement picture");
            return false;
          }

          // Custom headline text
          if (!custom_headline_text) {
            this.$dialogs.alert("กรุณาระบุ Custom headline text");
            return false;
          }

          if (custom_headline_text.length > 40) {
            this.$dialogs.alert(
              "Custom headline text มีความยาวตัวอักษรไม่เกิน 40 ตัว"
            );
          }
          // Date
          if (!start_date) {
            this.$dialogs.alert("กรุณากรอก Start date");
            return false;
          }
          if (!end_date) {
            this.$dialogs.alert("กรุณากรอก End date");
            return false;
          }
          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องยังเป็นอนาคต.");
            return false;
          }

          //  End date >= Start
          if (moment(end_date) < moment(start_date)) {
            this.$dialogs.alert("End date ต้องมากกว่าหรือเท่ากับ Start date.");
            return false;
          }

          // Coupon collect expiration
          if (!coupon_collection_expiration) {
            this.$dialogs.alert("กรุณาระบุ Coupon collect expiration");

            return false;
          }

          if (new Date(coupon_collection_expiration) < new Date(start_date)) {
            this.$dialogs.alert(
              "Coupon collect expiration ต้องไม่น้อยกว่า campaign start date"
            );

            return false;
          }

          if (new Date(coupon_collection_expiration) > new Date(end_date)) {
            this.$dialogs.alert(
              "Coupon collect expiration ต้องไม่เกิน campaign end date"
            );

            return false;
          }

          //Expiration Reminders
          if (this.isBetween(expiration_reminders1)) {
            this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            return false;
          }
          if (this.isBetween(expiration_reminders2)) {
            this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            return false;
          }
          if (this.isBetween(expiration_reminders3)) {
            this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            return false;
          }
          // มี file Target upload และจำนวน Targets ในไฟล์ของ User ทุกคนตรงกับ No. Targets ที่ควรจะเป็น
          // not implement
          break;
      }

      return true;
    },
    isBetween(date) {
      return (
        moment(date) < moment(this.campaign.start_date) ||
        moment(date) > moment(this.campaign.end_date)
      );
    },
    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };
        await ecouponService.updateCampaign(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-primary btn-round dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
      style="text-transform: uppercase;"
    >
      {{ status }}
    </button>

    <div class="dropdown-menu" role="menu">
      <a
        v-if="status == 'draft'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'scheduled')"
      >
        <i class="icon md-long-arrow-up" aria-hidden="true"></i>
        Publish
      </a>
      <a
        v-if="status == 'draft'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'archived')"
      >
        <i class="icon md-archive" aria-hidden="true"></i>
        Archive
      </a>
      <a
        v-if="status == 'archived'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'draft')"
      >
        <i class="icon md-long-arrow-up" aria-hidden="true"></i>
        Unarchive
      </a>
      <a
        v-if="status == 'scheduled'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'draft')"
      >
        <i class="icon md-long-arrow-down" aria-hidden="true"></i>
        Unpublish
      </a>
      <a
        v-if="status == 'ongoing'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'paused')"
      >
        <i class="icon md-block" aria-hidden="true"></i>
        Pause
      </a>
      <a
        v-if="status == 'ongoing'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'end')"
      >
        <i class="icon md-archive" aria-hidden="true"></i>
        Stop
      </a>
      <a
        v-if="status == 'paused'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'ongoing')"
      >
        <i class="icon md-refresh" aria-hidden="true"></i>
        Restart
      </a>
      <a
        v-if="status == 'paused'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
        @click="$emit('change', 'end')"
      >
        <i class="icon md-archive" aria-hidden="true"></i>
        Stop
      </a>
      <a
        v-if="status == 'aborted'"
        class="dropdown-item"
        href="javascript:void(0)"
        role="menuitem"
      >
        <i class="icon md-archive" aria-hidden="true"></i>
        Nothing
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["status"],
};
</script>

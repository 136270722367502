<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create Admin User</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.username.$error }"
            >
              <label class="col-md-0 form-control-label form__label">
                <h5>Username (cannot change later)</h5>
              </label>
              <p class="mx-20">
                Allow a-z (lower case), 0-9(number), _(underscore), .(dot)
                maximum 20 characters
              </p>

              <div class="col-md-12">
                <input
                  type="text"
                  ref="username"
                  class="form-control form__input"
                  v-model="username"
                  autocomplete="off"
                  @input="showValid('username')"
                />

                <usernameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">Role</h5>
              <div class="col-md-12">
                <select class="form-control col-md-12" v-model="role">
                  <option
                    v-for="role in ['admin', 'report', 'supervisor']"
                    :key="role"
                    :value="role"
                  >
                    {{ role }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="createAdmin"
              :disabled="!readySubmit"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminService } from "@/api/client";
import { responseErrorMapping } from "./model/error";
import usernameValidation from "./validation/username.vue";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "CreateAdmin",

  data() {
    return {
      role: "",
      username: "",
      showErrorNameValidation: false,
    };
  },

  validations: validateRules,

  components: {
    usernameValidation,
  },

  computed: {
    readySubmit() {
      return (
        ["admin", "report", "supervisor"].includes(this.role) &&
        this.username != "" &&
        !this.$v.username.$invalid
      );
    },
  },

  methods: {
    async isFormValid() {
      if (this.$v.username.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Username ให้ถูกต้อง");
        this.$refs.username.focus();
        return false;
      }
      if (!this.role) {
        await this.$dialogs.alert("กรุณาระบุ Role");
        return false;
      }
      return true;
    },

    close() {
      this.$modal.hide("create-admin-modal");
    },

    async getFormData() {
      return {
        role: this.role,
        username: this.username,
      };
    },

    async createAdmin() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const { id } = await adminService.createAdmin(body);
        await this.$dialogs.alert("Create Admin Success.");

        this.$router
          .push({ name: "AdminEdit", params: { id } })
          .catch((err) => {});
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "username") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="input-group mb-2 mr-sm-2">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <span class="material-icons">
            {{ icon }}
          </span>
        </div>
      </div>
      <select class="form-control" v-model="selected" @change="optionSelected">
        <slot></slot>
        <option v-for="({ text, value }, idx) in options" :value="value" :key="idx">
          {{ text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    options: {
      required: true,
      type: Array,
      default: [],
    },
    defaultOption: {
      default: "",
    },
    defaultSelected: {
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  created() {
    this.selected = this.defaultSelected ? this.defaultSelected : "";
  },
  methods: {
    optionSelected() {
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  .input-group-prepend {
    border-right: none;
    .input-group-text {
      background-color: transparent;
    }
  }

  .form-control {
    appearance: none;
    border-left: none;
    &:focus {
      border-color: #ced4da;
      border-left-color: transparent;
    }
  }
}
</style>

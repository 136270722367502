import moment from "moment";

export const campaignTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Campaign Name",
    field: "name",
  },
  {
    label: "Type",
    field: "type",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Start",
    field: "start_date",
    formatFn: function (value) {
      const date = moment(value);
      return date.isValid() ? date.format("YYYY-MM-DD") : "";
    },
  },
  {
    label: "End",
    field: "end_date",
    formatFn: function (value) {
      const date = moment(value);
      return date.isValid() ? date.format("YYYY-MM-DD") : "";
    },
  },
  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

export const barcodeTableColumns = [
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "Target 1",
    field: "target1",
  },
  {
    label: "Target 2",
    field: "target2",
  },
  {
    label: "Target 3",
    field: "target3",
  },
  {
    label: "Target 4",
    field: "target4",
  },
  {
    label: "Target 5",
    field: "target5",
  },
  {
    label: "Progress_1",
    field: "progress_1",
  },
  {
    label: "Progress_2",
    field: "progress_2",
  },
  {
    label: "Progress_3",
    field: "progress_3",
  },
  {
    label: "SF Point Granted",
    field: "point_total",
  },
];

export const monthlyTableColumns = [
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "Target 1",
    field: "target1",
  },
  {
    label: "Target 2",
    field: "target2",
  },
  {
    label: "Target 3",
    field: "target3",
  },
  {
    label: "Target 4",
    field: "target4",
  },
  {
    label: "Target 5",
    field: "target5",
  },
  {
    label: "Target Unit",
    field: "target_unit",
  },
  {
    label: "Target Ex",
    field: "target_ex",
  },
  {
    label: "Progress",
    field: "progress",
  },
  {
    label: "Prog_1",
    field: "progress_1",
  },
  {
    label: "Prog_2",
    field: "progress_2",
  },
  {
    label: "Prog_3",
    field: "progress_3",
  },
  {
    label: "Prog_ex",
    field: "progress_ex",
  },
  {
    label: "SF Point Granted",
    field: "point_total",
  },
];

export const taskTableColumns = [
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "Target 1",
    field: "target1",
  },
  {
    label: "Progress",
    field: "progress",
  },
  {
    label: "SF Point Granted",
    field: "point_total",
  },
];

export const leaderBoardTableColumns = [
  {
    label: "Rank",
    field: "rank",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "Progress",
    field: "progress",
  },
  {
    label: "SF Point Granted",
    field: "point_total",
  },
  {
    label: "Reward",
    field: "reward",
  },
];

export const transactionLogTableColumns = [
  {
    label: "Timestamp",
    field: "event_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "ISMS Code",
    field: "isms_code",
  },
  {
    label: "Campaign ID",
    field: "campaign_id",
  },
  {
    label: "Campaign Type",
    field: "type",
  },
  {
    label: "SF Point",
    field: "success_point",
  },
  {
    label: "Coupon Value",
    field: "coupon_value",
  },
  {
    label: "Coupon App Ref",
    field: "coupon_app_ref",
  },
  {
    label: "Prize Theme ID",
    field: "prize_theme_id",
  },
  {
    label: "Reward Request ID",
    field: "reward_request_id",
  },
  {
    label: "Reward App Ref",
    field: "prize_award_ref",
  },
  {
    label: "Note",
    field: "note",
  },
];

export const teamBarcodeTableColumns = [
  { label: "Team ID", field: "team_id", sortable: true },
  { label: "Team Target", field: "target", sortable: true },
  { label: "Team Progress", field: "progress", sortable: false },
  {
    label: "Minimum Contribution",
    field: "minimum_contribution",
    sortable: true,
  },
  { label: "Team SF Point Granted", field: "point_total", sortable: true },
];

export const stampTableColumns = [
  {
    label: "Contact ID",
    field: "isms_code",
  },
  {
    label: "No. Stamps",
    field: "progress",
    globalSearchDisabled: true,
  },
  {
    label: "No. Rewards",
    field: "reward_count",
    globalSearchDisabled: true,
  },
  {
    label: "SF Point Granted",
    field: "point_total",
    globalSearchDisabled: true,
  },
  {
    label: "Coupon value Granted",
    field: "face_value_total",
    globalSearchDisabled: true,
  },
];

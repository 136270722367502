var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm._m(0),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.pool.prize_name,
          expression: "pool.prize_name",
        },
      ],
      staticClass: "form-control",
      attrs: {
        type: "text",
        maxlength: "60",
        name: "prize_name",
        disabled: _vm.disabled,
      },
      domProps: { value: _vm.pool.prize_name },
      on: {
        change: _vm.update,
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.pool, "prize_name", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [_c("h5", [_vm._v("Name")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
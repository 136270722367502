<template>
  <div>
    <label class="form-control-label">
      <h4>Game Instance Name</h4>
    </label>
    <input
      ref="name"
      type="text"
      v-model="name"
      maxlength="255"
      @change="update"
      :disabled="!canEdit"
      class="form-control"
    />
    <nameValidation :$v="$v" />
  </div>
</template>

<script>
import { gameService } from "@/api/client";

import nameValidation from "../validation/name.vue";
import { FormEditValidateRule as validateRules } from "../validation";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  components: {
    nameValidation,
  },

  validations: { name: validateRules.name },

  data() {
    return {
      name: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.gameConfig.status);
    },
  },

  mounted() {
    this.name = this.gameConfig.name;
  },

  methods: {
    async isFormValid() {
      if (this.$v.name.$invalid) {
        await this.$dialogs.alert("กรุณาระบุ Game Instance Name ให้ถูกต้อง");
        this.name = this.gameConfig.name;
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await httpService.put(`/game/${this.gameConfig.id}`, {
          name: this.name,
        });

        this.$notify(`Game Instance name updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Game Instance  name error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding: 0px;

  h4 {
    margin-bottom: 0.5em;
  }
}
</style>

<template>
  <div class="col-md-8">
    <div class="form-group">
      <div my-15px>
        <label class="form-control-label">
          <h4>
            Splash Screen
            <span class="small light"> (recommended size 1624 x 750 )</span>
          </h4>
        </label>

        <div class="form-group">
          <div class="d-block">
            <img
              class="img img-fluid image-preview"
              v-if="picture"
              :src="picture"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="upload"
            ref="splash_screen_picture"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return {
      file: null,
    };
  },
  computed: {
    picture() {
      return this.gameConfig.sunset_drive.splash_screen_picture;
    },

    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },
  methods: {
    async upload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("splash_screen_picture", file);

        const { id } = this.gameConfig;

        await httpService.post(`/game/${id}/sunset_drive/upload`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.$emit("updated");
        this.$notify(`Background picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload background picture error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  max-width: 620px;
}

.form-control-label {
  padding: 0px;

  h4 {
    margin-bottom: 0.5em;
  }
}
</style>

<template>
  <div class="row" v-if="item">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Task {{ item.id }} - {{ item.name }}</h3>
        </div>
        <div class="panel-body">
          <div class="form-horizontal">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-control-label">
                    <h5>Instruction</h5>
                  </label>
                  <div class="col-md-12">
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      v-model="item.instruction"
                      @change="updateInstruction"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">
                    <h5>Task Description</h5>
                  </label>
                  <div class="col-md-12">
                    <textarea
                      rows="3"
                      ref="question"
                      autocomplete="off"
                      class="form-control"
                      v-model="item.description"
                      @change="updateDescription"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">
                    <h5>Choice</h5>
                  </label>
                  <div class="col-12">
                    <table class="table">
                      <tr v-for="(choice, index) in choices" :key="index">
                        <td>{{ index + 1 }}.</td>
                        <td width-250px>
                          <input
                            type="text"
                            class="form-control"
                            :value="choice.answer"
                            @change="updateAnswer"
                            :data-choice-id="choice.id"
                          />
                        </td>
                        <td>
                          <button
                            class="btn btn-default"
                            @click="removeChoice(choice.id)"
                          >
                            <i class="icon md-delete"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <button
                            @click="addChoice"
                            class="btn btn-round w-80"
                            :disabled="
                              choices.length === 4 ||
                              (choices.length === 3 &&
                                item.correct_answer == 'fill')
                            "
                          >
                            +
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <table class="table">
                    <tr>
                      <td>
                        <h5>Correct Answer</h5>
                      </td>
                      <td>
                        <select
                          width-100px
                          class="form-control correct-choice"
                          v-model="item.correct_answer"
                          @change="updateCorrectAnswer"
                        >
                          <option value="none">
                            None
                          </option>

                          <option v-if="choices.length >= 1" value="1">
                            1
                          </option>
                          <option v-if="choices.length >= 2" value="2">
                            2
                          </option>
                          <option v-if="choices.length >= 3" value="3">
                            3
                          </option>
                          <option v-if="choices.length >= 4" value="4">
                            4
                          </option>

                          <option value="fill" :disabled="choices.length === 4">
                            Fill
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Submit Button Delay</h5>
                      </td>
                      <td>
                        <input
                          width-100px
                          type="number"
                          ref="submitButtonDelay"
                          v-model="submitButtonDelay"
                          @change="updateSubmitButtonDelay"
                          class="form-control d-inline"
                        />
                        <span class="mx-10">seconds</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="row" my-15px>
                    <input
                      type="radio"
                      value="none"
                      class="mr-10"
                      name="image_or_video"
                      id="image_or_video_none"
                      v-model="item.image_or_video"
                      @change="updateImageVideoType"
                    />
                    <label for="image_or_video_none">None</label>
                  </div>
                  <div class="row" my-15px>
                    <input
                      type="radio"
                      value="image"
                      class="mr-10"
                      name="image_or_video"
                      id="image_or_video_image"
                      v-model="item.image_or_video"
                      @change="updateImageVideoType"
                    />
                    <div class="form-group">
                      <label for="image_or_video_image">
                        Image Only
                      </label>

                      <div class="d-block">
                        <img
                          class="img img-fluid"
                          v-if="item.image_url"
                          :src="item.image_url"
                          :disabled="item.image_or_video != 'image'"
                        />
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        class="form-control"
                        @change="uploadImage"
                        ref="picture_url_upload_input"
                        :disabled="item.image_or_video != 'image'"
                      />
                    </div>
                  </div>
                  <div class="row" my-15px>
                    <input
                      type="radio"
                      value="video"
                      class="mr-10"
                      name="image_or_video"
                      id="image_or_video_video"
                      v-model="item.image_or_video"
                      @change="updateImageVideoType"
                    />
                    <div class="form-group">
                      <label for="image_or_video_video">VDO URL Only</label>
                      <div>
                        <input
                          type="text"
                          width-300px
                          ref="video_url"
                          maxlength="255"
                          class="form-control"
                          v-model="item.video_url"
                          @change="updateVideoURL"
                          :disabled="item.image_or_video != 'video'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button @click="close" class="btn btn-round btn-primary">
              <i class="icon md-check" aria-hidden="true"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

export default {
  name: "EditTaskModal",

  props: ["task"],

  data() {
    return {
      item: null,
      choices: [],
      correct_answer: "none",
      submitButtonDelay: 0.0,
    };
  },

  async mounted() {
    await this.fetchTask();
    await this.fetchChoices();
    this.submitButtonDelay = this.item.submit_button_delay / 1000;
  },

  methods: {
    validateVideoURL() {
      if (!this.item.video_url) {
        this.$dialogs.alert(`Video URL is required.`).then(() => {
          this.$refs.video_url.focus();
        });
        return false;
      }

      return true;
    },

    async updateVideoURL() {
      try {
        if (!this.validateVideoURL()) return;

        const { campaign_id, id, video_url } = this.item;
        const form = { video_url: video_url };

        await luckyLoginService.updateTaskInfo(campaign_id, id, form);

        this.notifySuccess(`Video URL`);
      } catch (error) {
        this.$dialogs.alert(`Update Video URL error.`);
      }
    },

    async updateImageVideoType() {
      const { campaign_id, id, image_or_video } = this.item;
      const form = { image_or_video: image_or_video };

      await luckyLoginService.updateTaskInfo(campaign_id, id, form);

      this.$notify("Task updated.");
    },

    async fetchChoices() {
      const { campaign_id, id } = this.task;
      this.choices = await luckyLoginService.getChoices(campaign_id, id);
    },

    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File is too big");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("image", file);

        const { campaign_id, id } = this.task;

        await luckyLoginService.uploadTaskImage(campaign_id, id, form);
        await this.fetchTask();

        this.notifySuccess(`Task image`);
      } catch (error) {
        console.error(error);
        this.$notify("Upload task image error");
      }
    },

    async updateInstruction() {
      const { campaign_id, id, instruction } = this.item;
      if (!instruction) return;

      await luckyLoginService.updateTaskInfo(campaign_id, id, {
        instruction: instruction,
      });
      this.notifySuccess(`Instruction`);
    },

    async updateDescription() {
      const { campaign_id, id, description } = this.item;
      if (!description) return;

      await luckyLoginService.updateTaskInfo(campaign_id, id, {
        description: description,
      });

      this.notifySuccess(`Description`);
    },

    async updateSubmitButtonDelay() {
      const { campaign_id, id } = this.item;

      if (isNaN(parseInt(this.submitButtonDelay))) {
        await this.$dialogs.alert("Submit button delay ต้องเป็นตัวเลขเท่านั้น");
        this.submitButtonDelay = this.task.submit_button_delay / 1000;
        this.$refs.submitButtonDelay.focus();
        return;
      }

      if (parseInt(this.submitButtonDelay) < 0) {
        await this.$dialogs.alert(
          "Submit button delay ต้องมากกว่าหรือเท่ากับ 0"
        );
        this.submitButtonDelay = this.task.submit_button_delay / 1000;
        this.$refs.submitButtonDelay.focus();
        return;
      }

      await luckyLoginService.updateTaskInfo(campaign_id, id, {
        submit_button_delay: this.submitButtonDelay * 1000,
      });

      this.notifySuccess(`Submit Button Delay`);
    },

    async updateCorrectAnswer() {
      try {
        const { campaign_id, id, correct_answer } = this.item;
        const form = { correct_answer: correct_answer };

        await luckyLoginService.updateTaskInfo(campaign_id, id, form);
        await this.fetchTask();

        this.$notify(`Correct answer updated`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Correct answer update error.`);
      }
    },

    async fetchTask() {
      const { campaign_id, id } = this.task;
      this.item = await luckyLoginService.getTaskById(campaign_id, id);
    },

    async addChoice() {
      const { campaign_id, id } = this.task;
      await luckyLoginService.addChoice(campaign_id, id);
      await this.fetchChoices();

      this.$notify(`Choice Added`);
    },

    async updateAnswer(event) {
      const { campaign_id, id } = this.task;
      const idx = event.target.getAttribute("data-choice-id");
      const value = event.target.value;

      if (!value) return;

      await luckyLoginService.updateChoice(campaign_id, id, idx, {
        answer: value,
      });

      await this.fetchChoices();

      this.notifySuccess(`Answer`);
    },

    async removeChoice(idx) {
      if (!idx) return;

      const { campaign_id, id } = this.task;

      await luckyLoginService.removeChoice(campaign_id, id, idx);
      await this.fetchChoices();
    },

    close() {
      this.$emit("close");
    },

    notifySuccess(field) {
      this.$notify(
        `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
          /_/gi,
          " "
        ) + " updated."
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  &[disabled] {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

.panel-body {
  overflow-y: scroll;
  max-height: calc(100vh - 250px);
}
</style>

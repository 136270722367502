<template>
  <div class="panel panel-bordered">
    <div class="panel-heading">
      <div class="panel-title">Samurai Settings - {{ environment.name }}</div>
      <div class="panel-actions panel-actions-keep"></div>
    </div>
    <div class="panel-body">
      <div class="table-responsive config-list-table">
        <table class="table">
          <tr>
            <td class="align-middle">เวลาสำหรับการเล่นแต่ละครั้ง</td>
            <td width="100" align="right">
              <input
                type="number"
                class="form-control"
                v-model="form.play_sec"
                min="0"
                max="100"
              />
            </td>
            <td class="align-middle" width="80" align="left">วินาที</td>
          </tr>
          <tr>
            <td class="align-middle">คะแนนสำหรับ SKU1</td>
            <td width="100" align="right">
              <input
                type="number"
                class="form-control"
                v-model="form.point_0"
                min="0"
                max="100"
              />
            </td>
            <td class="align-middle" width="80" align="left">คะแนน</td>
          </tr>
          <tr>
            <td class="align-middle">คะแนนสำหรับ SKU2</td>
            <td width="100" align="right">
              <input
                type="number"
                class="form-control"
                v-model="form.point_1"
                min="0"
                max="100"
              />
            </td>
            <td class="align-middle" width="80" align="left">คะแนน</td>
          </tr>
          <tr>
            <td class="align-middle">คะแนนสำหรับ SKU3</td>
            <td width="100" align="right">
              <input
                type="number"
                class="form-control"
                v-model="form.point_2"
                min="0"
                max="100"
              />
            </td>
            <td class="align-middle" width="80" align="left">คะแนน</td>
          </tr>
          <tr>
            <td>คะแนนสำหรับ VIP</td>
            <td class="align-middle" width="100" align="right">
              <input
                type="number"
                class="form-control"
                v-model="form.point_vip"
                min="0"
                max="100"
              />
            </td>
            <td class="align-middle" width="80" align="left">คะแนน</td>
          </tr>
          <tr>
            <td class="align-middle">คะแนนสำหรับระเบิด</td>
            <td width="100" align="right">
              <input
                type="number"
                class="form-control"
                v-model="form.point_bomb"
                min="-100"
                max="0"
              />
            </td>
            <td class="align-middle" width="80" align="left">คะแนน</td>
          </tr>
        </table>
      </div>

      <hr />

      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">Theme</h4>

        <select class="form-control w-250" v-model="form.theme_id">
          <option v-for="theme in themes" :key="theme.id" :value="theme.id">
            {{ ` ${theme.id} - ${theme.name}` }}
          </option>
        </select>
      </div>

      <hr />

      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">No. play per day</h5>

        <div class="table-days-point-wrapper">
          <table>
            <tr>
              <td v-for="(day, index) in days" :key="index">
                <p>{{ day.text }}</p>
                <input
                  min="0"
                  max="999"
                  type="number"
                  class="form-control"
                  v-model="form[day.key]"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="close"
        >
          <i class="icon md-close" aria-hidden="true"></i>
          Cancel
        </button>
        <button type="button" @click="submit" class="btn btn-round btn-primary">
          <i class="icon md-check" aria-hidden="true"></i> Commit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";

export default {
  name: "ConfigurationModal",

  props: ["name", "themes", "environment"],

  data() {
    return {
      form: {
        play_sec: 0,
        point_0: 0,
        point_1: 0,
        point_2: 0,
        point_bomb: 0,
        point_vip: 0,

        theme_id: 0,

        play_day_fri: 0,
        play_day_mon: 0,
        play_day_sat: 0,
        play_day_sun: 0,
        play_day_thu: 0,
        play_day_tue: 0,
        play_day_wed: 0,
      },
      days: [
        { text: "Monday", key: "play_day_mon" },
        { text: "Tuesday", key: "play_day_tue" },
        { text: "Wednesday", key: "play_day_wed" },
        { text: "Thursday", key: "play_day_thu" },
        { text: "Friday", key: "play_day_fri" },
        { text: "Saturday", key: "play_day_sat" },
        { text: "Sunday", key: "play_day_sun" },
      ],
    };
  },

  methods: {
    async submit() {
      const { name, code } = this.environment;
      const options = { cancelLabel: "No", okLabel: "Yes", size: "sm" };

      const { ok } = await this.$dialogs.confirm(
        `Publish these settings to Samurai - ${name} immediately ?`,
        options
      );

      if (!ok) return;

      try {
        await gameSamuraiService.updateConfig(code, this.form);
        this.$notify("Update Settings success.");
        this.$emit("updated");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    async fetchEnvironmentConfig() {
      try {
        const { code } = this.environment;
        const config = await gameSamuraiService.getByCode(code);

        for (const [key, value] of Object.entries(this.form)) {
          this.form[key] = config[key];
        }
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    close() {
      this.$modal.hide(this.name);
    },
  },

  mounted() {
    this.fetchEnvironmentConfig();
  },
};
</script>

<style lang="scss" scoped>
[data-modal="samurai-configuration-modal"] {
  .v--modal .panel {
    margin-bottom: 0px;
  }

  .v--modal .panel .panel-body {
    padding-top: 1rem;
  }
}

.config-list-table {
  table tr:first-child td {
    border-top: none;
  }
}

.table-days-point-wrapper {
  table tr td {
    min-width: 90px;
    padding-left: 1rem;
  }
}
</style>

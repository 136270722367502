<template>
  <div class="col-md-10" v-if="status != null">
    <div
      class="wrapper-replace-form"
      v-if="action == 'ready' && status.active == 0"
    >
      <label class="mx-5">
        <h4>Game Instances</h4>
      </label>

      <button
        type="button"
        class="btn btn-primary btn-round"
        :disabled="disabled"
        @click="setAction('editing')"
      >
        Edit
      </button>
    </div>
    <div
      class="wrapper-replace-form"
      v-else-if="action == 'editing' && status.active == 0"
    >
      <label class="mx-5">
        <h4>Game Instances</h4>
      </label>
      <div>
        <button
          type="button"
          class="btn btn-round btn-primary mx-10"
          @click="handleVerify"
          :disabled="disabled"
        >
          Verify
        </button>
        <button
          type="button"
          class="btn btn-round btn-default"
          @click="handleCancel"
          :disabled="disabled"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="wrapper-replace-form" v-else-if="status.active == 1">
      <label class="mx-5">
        <h4>Game Instances</h4>
      </label>

      <label class="label-blue" v-if="status.state == 'verify'">
        Verifying change
      </label>

      <label class="label-blue" v-else-if="status.state == 'done-verify'">
        Click to commit change
      </label>

      <div v-if="status.state == 'verify'">
        <button type="button" class="btn btn-round btn-primary mx-10" disabled>
          Commit
        </button>
        <button type="button" class="btn btn-round btn-default" disabled>
          Cancel
        </button>
      </div>
      <div v-else-if="status.state == 'done-verify'">
        <button
          type="button"
          class="btn btn-round btn-primary mx-10"
          @click="getResult"
          :disabled="disabled"
        >
          Commit
        </button>
        <button
          type="button"
          class="btn btn-round btn-default"
          @click="handleCancel"
          :disabled="disabled"
        >
          Cancel
        </button>
      </div>
    </div>

    <Selectize
      multiple
      :disabled="!canEdit"
      v-model="instanceSelected"
      :settings="selectizeSetting"
    >
      <option
        v-for="(instance, index) in instances"
        :key="index"
        :value="instance.id"
      >
        {{ instance.name + " - " + instance.game + "-" + instance.id }}
      </option>
    </Selectize>

    <modal name="try-replace-game-instances-modal" :width="400" height="auto">
      <TryReplaceConfirmModal
        :campaign="campaign"
        :result="result"
        @confirm="confirmReplace"
        name="try-replace-game-instances-modal"
      />
    </modal>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { leaderBoardService } from "@/api/client";
import TryReplaceConfirmModal from "./TryUploadConfirmModal.vue";

export default {
  props: ["campaign"],

  components: {
    Selectize,
    TryReplaceConfirmModal,
  },

  data() {
    return {
      instances: [],
      status: null,
      result: null,
      instanceSelected: [],
      action: "ready",
      pullingId: null,
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        // create: this.add,
      },
    };
  },

  computed: {
    disabled() {
      return ["archived", "end", "aborted"].includes(this.campaign.status);
    },
    canEdit() {
      return (
        this.action == "editing" &&
        this.status.active == 0 &&
        !["archived", "end", "aborted"].includes(this.campaign.status)
      );
    },
    campaignInstance() {
      return this.campaign.instance.map((o) => +o);
    },
  },

  watch: {
    status(n) {
      if (
        n.active === 1 &&
        n.type == "instance" &&
        n.state == "verify" &&
        this.pullingId === null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "instance" &&
        n.state == "execute" &&
        this.pullingId === null
      ) {
        this.startPullingStatus();
        return;
      }

      if (n.active === 1 && n.type == "instance" && n.state == "done-execute") {
        this.setAction("ready");
        this.getReplaceStatus();
        return;
      }

      if (n.active === 0) {
        this.setAction("ready");
        this.stopPullingStatus();
        return;
      }
    },

    campaignInstance(n) {
      this.instanceSelected = n;
    },
  },

  methods: {
    setAction(action) {
      this.action = action;
    },

    async handleCancel() {
      if (this.status.active === 1) {
        await leaderBoardService.tryReplaceCancelInstance(this.campaign.id);
        this.$notify("Cancel success");
        this.getReplaceStatus();
      }

      this.$emit("updated");
      this.setAction("ready");
    },

    async handleVerify() {
      if (
        this.campaign.instance.length === 0 &&
        this.instanceSelected.length <= 0
      ) {
        return;
      }

      this.campaign.status == "draft"
        ? await this.update()
        : await this.tryUpdate();

      await this.getReplaceStatus();
      this.campaign.instance = this.instanceSelected;
    },

    async update() {
      try {
        const form = {
          instance: this.instanceSelected.map((o) => +o),
        };

        await leaderBoardService.replaceInstance(this.campaign.id, form);

        this.$notify(`Game Instances Updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Game Instances update error.`);
      }
    },

    async tryUpdate() {
      try {
        const form = { instance: this.instanceSelected };

        const result = await leaderBoardService.tryReplaceInstance(
          this.campaign.id,
          form
        );

        if (result.error) {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        this.getReplaceStatus();
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Game Instances update error.`);
      }
    },

    async getResult() {
      try {
        this.result = await leaderBoardService.tryReplaceResultInstance(
          this.campaign.id
        );

        this.$modal.show(`try-replace-game-instances-modal`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Game Instances update error.`);
      }
    },

    async confirmReplace() {
      try {
        await leaderBoardService.tryReplaceConfirmInstance(this.campaign.id);

        this.$notify("Confirm success");
        this.getReplaceStatus();
        this.$emit("updated");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try replace error");
      }
    },

    async getInstanceList() {
      this.instances = await leaderBoardService.getInstanceList();
    },

    async getReplaceStatus() {
      this.status = await leaderBoardService.getUploadStatus(this.campaign.id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getReplaceStatus, 5000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },

    add(instance) {
      return {
        value: instance,
        text: instance,
      };
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  mounted() {
    this.instanceSelected = this.campaign.instance;
  },

  async created() {
    await this.getInstanceList();
    await this.getReplaceStatus();
  },
};
</script>

<style lang="scss" scoped>
.wrapper-replace-form {
  display: flex;
  margin: 2em auto;
  align-items: center;
  justify-content: space-between;

  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }
}
</style>

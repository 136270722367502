<template>
  <ckeditor
    v-model="localValue"
    :config="mergedConfig"
    :read-only="readOnly"
    @blur="handleBlur"
    @input="handleInput"
  />
</template>

<script>
import CKEditor from "ckeditor4-vue";

export default {
  name: "CkeditorWrapper",
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localValue: this.value, // Initialize localValue with the prop value
    };
  },
  computed: {
    mergedConfig() {
      return {
        ...this.config,
        versionCheck: false, // กำหนด versionCheck: false
      };
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue; // Watch for changes in the prop value and update localValue
    },
    localValue(newValue) {
      this.$emit("input", newValue); // Emit input event to update parent component
    },
  },
  methods: {
    handleBlur(event) {
      this.$emit("blur", event); // Emit blur event to parent component
    },
    handleInput(event) {
      this.$emit("input", event); // Emit input event to parent component
    },
  },
};
</script>

<template>
  <div class="page-header">
    <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
      <h1 class="page-title">Quota</h1>
      <breadcrumb
        name="POINT_QUOTA_LIMIT_EDIT"
        :withLast.sync="quota.summary"
      ></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="goBack"
        >
          <i class="icon md-chevron-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: ["quota"],
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>

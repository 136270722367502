<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create New Campaign</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.campaign.name.$error }"
            >
              <label class="col-md-0 form-control-label form__label"
                ><h5>Campaign Name</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="campaign.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">Copy From</h5>
              <div class="col-md-12">
                <select class="form-control col-md-12" v-model="copyFromId">
                  <option value="" disabled>Select Copy From</option>
                  <option
                    v-for="campaign in list"
                    :key="campaign.id"
                    :value="campaign.id"
                  >
                    {{ ` ${campaign.id} - ${campaign.name}` }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="addCampaign"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { dailyloginService } from "@/api/client";
import { responseErrorMapping } from "./model/error";
import nameValidation from "./validation/name";
import { FormCreateValidateRule as validateRules } from "./validation";
export default {
  name: "Create",
  data() {
    return {
      showErrorNameValidation: false,
      campaign: {
        status: "ARCHIVED",
      },
      copyFromId: null,
      campaigns: [],
      list: [],
    };
  },
  validations: { campaign: validateRules },
  components: {
    nameValidation,
  },
  watch: {
    "campaign.type"(newValue) {
      this.filter(newValue);
    },
  },
  async mounted() {
    this.campaigns = await dailyloginService.getDailylogin();
    this.filter(this.campaign.status);
  },
  methods: {
    async isFormValid() {
      if (this.$v.campaign.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Campaign Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      return true;
    },
    filter(status) {
      this.list = this.campaigns.filter(
        (l) => l.status.toUpperCase() !== status
      );
    },
    close() {
      this.$modal.hide("create-modal");
    },
    async getFormData() {
      // เลือก copy from
      if (this.copyFromId) {
        const copyFromcampaign = await dailyloginService.getById(
          this.copyFromId
        );

        const duration = this.getDuration(
          copyFromcampaign.start_date,
          copyFromcampaign.end_date
        );

        const body = {
          name: this.campaign.name,
          description: copyFromcampaign.description,
          start_date: moment().add(15, "days").format(`YYYY-MM-DD`),
          end_date: moment()
            .add(duration + 15, "days")
            .format(`YYYY-MM-DD`),
          group_id_list: copyFromcampaign.group_id_list,
          special_date_list: copyFromcampaign.special_date_list,
          enable_coin_exchange: copyFromcampaign.enable_coin_exchange,
        };

        if (copyFromcampaign.enable_coin_exchange === 1) {
          body.coin_exchange_list = copyFromcampaign.coin_exchange_list;
        }

        return body;
      }

      // ไม่ได้เลือก copy from
      else {
        const start = moment().add(15, "days");
        const end = moment().add(45, "days");
        return {
          name: this.campaign.name,
          start_date: start.format(`YYYY-MM-DD`),
          end_date: end.format(`YYYY-MM-DD`),
        };
      }
    },
    async addCampaign() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await dailyloginService.createDailylogin(body);
        await this.$dialogs.alert("Create campaign Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
    getDuration(start, end) {
      return moment(end).diff(start, "days");
    },
    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

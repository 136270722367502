import Advocacy from "@/views/advocacy/Index.vue";
import AdvocacyList from "@/views/advocacy/List.vue";

export default {
  path: "/advocacy",
  name: "Advocacy",
  component: Advocacy,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/",
      name: "AdvocacyList",
      component: AdvocacyList,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

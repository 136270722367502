import { numberWithCommas } from "@/filters";

export const tableColumns = [
  {
    label: "Budget ID",
    field: "id",
    type: "number",
  },
  {
    label: "Budget Name",
    field: "name",
  },
  {
    label: "Limit (bht)",
    field: "budget_limit",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "No. Campaigns",
    field: "no_campaign",
    type: "number",
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Start Time",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End Time",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

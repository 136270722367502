import HttpRequest from "./request";

export class UploadProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_UPLOAD_SERVER_URL}/`);
  }

  async upload(url, form) {
    return await this.request(
      "POST",
      `${process.env.VUE_APP_UPLOAD_SERVER_URL}${url}`,
      form,
      {
        headers: {
          Authorization: `Basic ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.weekly && _vm.status != null
    ? _c("div", { staticClass: "col-md-10" }, [
        _c("h5", { staticClass: "mb-5" }, [
          _vm._v("Period " + _vm._s(_vm.index + 1)),
        ]),
        _c(
          "div",
          { staticClass: "sku-box-wrapper" },
          [
            _vm._m(0),
            _c("div", { staticClass: "sku-box-action" }, [
              _c("p", [_vm._v("Product Codes")]),
              _vm.action == "ready" && _vm.status.active == 0
                ? _c("div", { staticClass: "wrapper-upload-form" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-round",
                        attrs: { type: "button", disabled: _vm.disabled },
                        on: { click: _vm.handleClickEdit },
                      },
                      [_vm._v(" Edit ")]
                    ),
                  ])
                : _vm.currentId == _vm.period.id &&
                  _vm.action == "editing" &&
                  _vm.status.active == 0
                ? _c("div", { staticClass: "wrapper-upload-form" }, [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-primary mx-10",
                          attrs: { type: "button" },
                          on: { click: _vm.toggleSelectAll },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.skuSelected.length < _vm.skus.length
                                  ? "Select All"
                                  : "Deselect All"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-primary mx-10",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: { click: _vm.handleVerify },
                        },
                        [_vm._v(" Verify ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-default",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: { click: _vm.handleCancel },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ]),
                  ])
                : _vm.currentId == _vm.period.id && _vm.status.active == 1
                ? _c("div", { staticClass: "wrapper-upload-form" }, [
                    _vm.status.state == "verify"
                      ? _c("label", { staticClass: "label-blue" }, [
                          _vm._v(" Verifying change "),
                        ])
                      : _vm.status.state == "done-verify"
                      ? _c("label", { staticClass: "label-blue" }, [
                          _vm._v(" Click to commit change "),
                        ])
                      : _vm._e(),
                    _vm.status.state == "verify"
                      ? _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-round btn-primary mx-10",
                              attrs: { type: "button", disabled: "" },
                            },
                            [_vm._v(" Commit ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-round btn-default",
                              attrs: { type: "button", disabled: "" },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ])
                      : _vm.status.state == "done-verify"
                      ? _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-round btn-primary mx-10",
                              attrs: { type: "button", disabled: _vm.disabled },
                              on: { click: _vm.getResult },
                            },
                            [_vm._v(" Commit ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-round btn-default",
                              attrs: { type: "button", disabled: _vm.disabled },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ])
                      : _vm._e(),
                    _vm.status.state == "execute" &&
                    _vm.status.status == "pending"
                      ? _c("label", { staticClass: "label-blue" }, [
                          _vm._v(" Processing... "),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c(
              "Selectize",
              {
                attrs: {
                  multiple: "",
                  disabled: !_vm.canEdit,
                  settings: _vm.selectizeSetting,
                },
                model: {
                  value: _vm.skuSelected,
                  callback: function ($$v) {
                    _vm.skuSelected = $$v
                  },
                  expression: "skuSelected",
                },
              },
              _vm._l(_vm.skus, function (sku, index) {
                return _c(
                  "option",
                  {
                    key: index,
                    domProps: {
                      value:
                        _vm.weekly.product_data_source == "ws"
                          ? sku
                          : sku.PRO_CODE,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.weekly.product_data_source == "ws"
                            ? sku
                            : sku.PRO_NAME_EN + " - " + sku.PRO_CODE
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h5", [_vm._v("Target SKUs (counted toward progress)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublish
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  methods: {
    validate(status) {
      const { name, stamp, end_date, start_date } = this.campaign;

      const { text1, no_stamp, reward } = stamp;

      const { banner_picture } = this.campaign.stamp;

      switch (status) {
        case "scheduled":
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign name.");
            return false;
          }

          if (!banner_picture) {
            this.$dialogs.alert("กรุณาระบุ Banner picture.");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องเป็นอนาคต.");
            return false;
          }

          if (dayjs(end_date).isBefore(start_date)) {
            this.$dialogs.alert("End date ต้องมากกว่าหรือเท่ากับ Start date.");
            return false;
          }

          if (!text1) {
            this.$dialogs.alert("กรุณาระบุ Text 1.");
            return false;
          }

          if (text1.length < 1 || text1.length > 255) {
            this.$dialogs.alert("กรุณาระบุ Text 1. ระหว่าง 1 - 255 ตัวอักษร");
            return false;
          }

          if (no_stamp < 5 || no_stamp > 100) {
            this.$dialogs.alert("กรุณาระบุ No. Stamp ระหว่าง 5 - 100");
            return false;
          }

          const lastStampReward = reward.find((o) => o.stamp_no == no_stamp);

          if (!lastStampReward) {
            this.$dialogs.alert(
              `กรุณาระบุรางวัลสำหรับ No. Stamp ตัวสุดท้าย #${no_stamp}`
            );
            return false;
          }

          break;
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = { status };

        await progressBarService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

export const vsmManagementTableColumns = [
  {
    label: "Employee Username",
    field: "username",
  },
  {
    label: "Role",
    field: "employee_role",
  },
  {
    label: "2nd Layer Role",
    field: "asm_code",
  },
  {
    label: "3rd Layer Role",
    field: "rsm_code",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Province",
    field: "province",
  },
  {
    label: "อำเภอ",
    field: "district",
  },
  {
    label: "ตำบล",
    field: "subdistrict",
  },
];

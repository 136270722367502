<template>
  <div id="pool-picker">
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-bordered mb-0">
          <div class="panel-heading">
            <h3 class="panel-title">
              Select Reward
              <p class="light">Aavailable {{ pools.length }} item(s)</p>
            </h3>
          </div>
          <div class="panel-body">
            <div v-for="(item, index) in pools" :key="index">
              <PoolItem
                :item="item"
                :selected="isSelected(item)"
                @click.native="handleSelect(item)"
              />
            </div>
            <div v-if="pools.length <= 0" class="empty">
              <i class="mdi mdi-gift-open-outline"></i>
            </div>
          </div>
          <div class="panel-footer">
            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-round btn-default"
                @click="close"
              >
                <i class="icon md-close" aria-hidden="true"></i>
                Cancel
              </button>
              <button
                type="button"
                @click="confirm"
                :disabled="!readyToSubmit"
                class="btn btn-round btn-primary"
              >
                <i class="icon md-check" aria-hidden="true"></i> Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PoolItem from "./PoolItem.vue";

export default {
  props: ["name", "pools"],

  components: { PoolItem },

  data() {
    return {
      selected: null,
    };
  },

  computed: {
    readyToSubmit() {
      return this.selected != null;
    },
  },

  methods: {
    close() {
      this.$modal.hide(this.name);
    },

    confirm() {
      this.$emit("confirm", this.selected);
      this.$modal.hide(this.name);
    },

    handleSelect(item) {
      if (this.selected == null) {
        this.selected = item;
        return;
      }

      if (item.id === this.selected.id) {
        this.selected = null;
      } else {
        this.selected = item;
      }
    },

    isSelected(item) {
      if (this.selected === null) return false;
      return item.id === this.selected.id;
    },
  },
};
</script>

<style lang="scss" scoped>
#pool-picker {
  .panel-title {
    p {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  .panel-body {
    padding: 1rem;
    max-height: 280px;
    overflow-y: auto;
  }

  .panel-footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .empty {
    text-align: center;

    i {
      color: #e4eaec;
      font-size: 72px;
    }
  }
}
</style>

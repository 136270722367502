<template>
  <div class="page" v-if="lot">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Prize Lot</h1>
          <breadcrumb
            name="PRIZE_SUMMARY_LOT_EDIT"
            :withLast.sync="lot.lot_name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Prize Lot Information - {{ lot.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="lot">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <LotName :lot="lot" @updated="fetchLot" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown :lot="lot" @updated="fetchLot" />
                    </div>
                  </div>
                  <hr />
                  <PrizeLotDate :lot="lot" @updated="fetchLot" />
                  <hr />
                  <PrizeSource :lot="lot" @updated="fetchLot" />
                  <hr />
                  <div
                    class="prize-detail-section"
                    v-if="lot.prize_source == 'manual'"
                  >
                    <h4>Prize Details</h4>
                    <PrizeName :lot="lot" @updated="fetchLot" />
                    <br />
                    <PrizeDescription :lot="lot" @updated="fetchLot" />
                    <br />
                    <PrizePicture :lot="lot" @updated="fetchLot" />
                    <br />
                    <RequireAddress :lot="lot" @updated="fetchLot" />
                    <hr />
                    <LineNotification :lot="lot" @updated="fetchLot" />
                    <hr />
                  </div>
                  <Recipients :lot="lot" @uploaded="fetchLot" />
                  <hr />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prizeService } from "@/api/client";

import LotName from "@/views/prize/lot/components/LotName.vue";
import PrizeName from "@/views/prize/lot/components/PrizeName.vue";
import Recipients from "@/views/prize/lot/components/Recipients.vue";
import PrizeSource from "@/views/prize/lot/components/PrizeSource.vue";
import PrizeLotDate from "@/views/prize/lot/components/PrizeLotDate.vue";
import PrizePicture from "@/views/prize/lot/components/PrizePicture.vue";
import RequireAddress from "@/views/prize/lot/components/RequireAddress.vue";
import StatusDropdown from "@/views/prize/lot/components/StatusDropdown.vue";
import PrizeDescription from "@/views/prize/lot/components/PrizeDescription.vue";
import LineNotification from "@/views/prize/lot/components/LineNotification.vue";

export default {
  name: "PrizeLotEdit",

  data() {
    return {
      lot: null,
    };
  },

  components: {
    LotName,
    PrizeName,
    Recipients,
    PrizeSource,
    PrizeLotDate,
    PrizePicture,
    RequireAddress,
    StatusDropdown,
    PrizeDescription,
    LineNotification,
  },

  computed: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchLot() {
      const id = this.$route.params.id;
      this.lot = await prizeService.getLotById(id);
    },
  },

  created() {
    this.fetchLot();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}
</style>

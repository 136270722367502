<template>
  <div class="panel panel-bordered mb-0">
    <div class="panel-heading">
      <div class="panel-title px-4">
        <i class="icon mdi mdi-controller mdi-24px mr-2" aria-hidden="true"></i>
        Preview with...
      </div>
      <div class="panel-actions panel-actions-keep"></div>
    </div>
    <div class="panel-body" style="padding: 1rem">
      <div class="qrcode-wrapper">
        <qr-code v-if="url" :text="url" />
        <div v-else class="qr-placeholder"></div>
      </div>
      <hr />
      <div class="list-group">
        <a
          href="#"
          class="list-group-item list-group-item-action"
          @click="$emit('selected', 'iframe')"
        >
          <i class="icon mdi mdi-application mdi-18px" aria-hidden="true"></i>
          Open as IFrame
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action"
          @click="$emit('selected', 'tab')"
        >
          <i class="icon mdi mdi-tab mdi-18px" aria-hidden="true"></i>
          Open in new tab
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action"
          @click="$emit('selected', 'window')"
        >
          <i class="icon mdi mdi-dock-window mdi-18px" aria-hidden="true"></i>
          Open in new window
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewChoiceModal",
  props: ["url"],
};
</script>

<style lang="scss" scoped>
.qrcode-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.qr-placeholder {
  width: 256px;
  height: 256px;
  border-radius: 6px;
  background-color: #f5f5f5;
}
</style>

<template>
  <div class="col-md-12">
    <div class="d-flex justify-content-between mx-10 mt-10">
      <h4>Period Duration</h4>
      <h5>No.Period {{ period.length }}</h5>
    </div>
    <div v-for="(o, index) in period" :key="o.id">
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td>
              <h5>Period {{ index + 1 }}</h5>
            </td>
            <td>
              <table>
                <tr>
                  <td>Start</td>
                  <td>
                    <DatePicker
                      type="date"
                      width-200px
                      @change="update(o.id)"
                      placeholder="n/a"
                      ref="start_date"
                      format="YYYY-MM-DD"
                      value-type="YYYY-MM-DD"
                      :disabled="disabled"
                      v-model="o.start_date"
                    />
                  </td>
                  <td>End</td>
                  <td>
                    <DatePicker
                      type="date"
                      width-200px
                      @change="update(o.id)"
                      placeholder="n/a"
                      ref="end_date"
                      format="YYYY-MM-DD"
                      value-type="YYYY-MM-DD"
                      :disabled="disabled"
                      v-model="o.end_date"
                    />
                  </td>
                </tr>
                <tr></tr>
              </table>
            </td>
            <td>
              <button
                class="btn btn-default"
                :disabled="period.length <= 1 || disabled"
                type="button"
                @click="removePeriod(o.id)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>

    <div class="action">
      <button
        class="btn btn-default"
        :disabled="period.length >= 52 || disabled"
        @click="addPeriod"
        type="button"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { progressBarService } from "@/api/client";

const httpService = progressBarService.axiosInstance;

export default {
  name: "PeriodDuration",

  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      period: [],
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  watch: {
    "campaign.leaderboard.period"() {
      this.period = this.campaign.leaderboard.period;
    },
  },

  methods: {
    async update(id) {
      const { start_date, end_date } = this.period.find((o) => o.id === id);

      if (start_date && end_date && new Date(start_date) > new Date(end_date)) {
        this.$dialogs.alert(`กรุณาระบุวันทีให้ถูกต้อง`);

        const oldValue = this.campaign.leaderboard.period.find(
          (o) => o.id === id
        );

        const matchedIndex = this.period.findIndex((o) => o.id === id);
        this.period[matchedIndex] = oldValue;

        return;
      }

      try {
        await httpService.put(`/${this.campaign.id}/leaderboard/period/${id}`, {
          start_date,
          end_date,
        });

        this.$emit("updated");
        this.$notify(`Period date updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update period error.`);
      }
    },

    async addPeriod() {
      try {
        const { data } = await httpService.post(
          `/${this.campaign.id}/leaderboard/period`
        );

        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Add period error.`);
      }
    },

    async removePeriod(id) {
      try {
        //   https://gwapi-sandbox.vivi-th.com/ams/api/progressbar/<campaign id>/leaderboard/period/<period_id>
        const { data } = await httpService.delete(
          `/${this.campaign.id}/leaderboard/period/${id}`
        );

        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Remove period error.`);
      }
    },
  },

  mounted() {
    this.period = this.campaign.leaderboard.period;
  },
};
</script>

<style lang="scss" scoped>
.image-preview {
  max-width: 240px;
}
</style>

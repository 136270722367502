<template>
  <div id="import-progress" v-if="campaign && canImportProgress">
    <div class="d-flex" v-if="status != null">
      <a
        href="https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/progressbar/monthlyshort-progress-upload.xlsx"
        class="btn btn-link"
      >
        Click to download example
      </a>

      <div class="wrapper-upload-form" v-if="status.active === 0">
        <label
          class="btn btn-round btn-upload btn-primary"
          for="user-target-reward-file-upload"
          :disabled="disabled"
        >
          Import Progress
        </label>

        <input
          type="file"
          :disabled="disabled"
          @change="onFileSelected"
          class="input-select-file"
          id="user-target-reward-file-upload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>

      <div
        class="wrapper-upload-form"
        v-else-if="
          status.active === 1 &&
          status.type == 'progress' &&
          status.state != 'execute'
        "
      >
        <label class="label-blue" v-if="status.state == 'verify'">
          Verifying progress
        </label>

        <label class="label-blue" v-else-if="status.state == 'done-verify'">
          Progress verified
        </label>

        <div v-if="status.state == 'verify'">
          <button
            type="button"
            class="btn btn-round btn-primary mx-10"
            disabled
          >
            Commit
          </button>
          <button type="button" class="btn btn-round btn-default" disabled>
            Cancel
          </button>
        </div>
        <div v-else-if="status.state == 'done-verify'">
          <button
            type="button"
            class="btn btn-round btn-primary mx-10"
            @click="getResult"
            :disabled="disabled"
          >
            Commit
          </button>
          <button
            type="button"
            class="btn btn-round btn-default"
            @click="cancelProcess"
            :disabled="disabled"
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        class="wrapper-upload-form"
        v-else-if="
          status.active === 1 &&
          status.type == 'progress' &&
          status.state == 'execute'
        "
      >
        <label>
          {{ filename }}
        </label>

        <label class="label-blue">
          Processing progress.
        </label>

        <button type="button" class="btn btn-round btn-primary" disabled>
          Edit
        </button>
      </div>
    </div>
    <modal name="try-upload-progress-modal" :width="900" height="auto">
      <TryUploadModal
        :campaign="campaign"
        :result="result"
        @confirm="confirmUpload"
        name="try-upload-modal"
      />
    </modal>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import TryUploadModal from "../TryUploadModal.vue";

export default {
  props: ["campaign", "columnConfig"],

  components: { TryUploadModal },

  data() {
    return {
      file: null,
      result: null,
      status: null,
      pullingId: null,
    };
  },

  computed: {
    disabled() {
      return !["scheduled", "ongoing"].includes(this.campaign.status);
    },
    canImportProgress() {
      return this.columnConfig.progress_upload === 1;
    },
  },

  watch: {
    status(n) {
      if (
        n.active === 1 &&
        n.type == "progress" &&
        n.state == "verify" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "progress" &&
        n.state == "verify" &&
        n.state == "pending" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "progress" &&
        n.state == "execute" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 0 ||
        (n.type == "progress" && n.state == "done-verify")
      ) {
        this.stopPullingStatus();
        return;
      }
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      event.target.value = null;

      this.columnConfig.progress_upload_try === 1
        ? this.tryUpload()
        : this.upload();
    },

    async upload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        await progressBarService.uploadProgressUpdate(this.campaign.id, form);

        this.$emit("updated");
        this.$root.$emit("IMPORT_PROGRESS_UPDATED");
        this.$notify("Upload Progress success");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Progress error");
      }
    },

    async tryUpload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        await progressBarService.tryUploadProgress(this.campaign.id, form);

        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getResult() {
      try {
        const result = await progressBarService.tryUploadProgressResult(
          this.campaign.id
        );

        if (!result.error) {
          this.result = result;
          this.$modal.show(`try-upload-progress-modal`);
        } else {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async confirmUpload() {
      try {
        await progressBarService.tryConfirmUploadProgress(this.campaign.id);

        this.$notify("Confirm success");
        this.getUploadStatus();
        this.$emit("updated");
        this.$root.$emit("IMPORT_PROGRESS_UPDATED");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async cancelProcess() {
      try {
        await progressBarService.tryCancelUploadProgress(this.campaign.id);

        this.$notify("Cancel success");
        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getUploadStatus() {
      this.status = await progressBarService.getUploadStatus(this.campaign.id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 3000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  mounted() {},

  async created() {
    this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
#import-progress {
  display: flex;
  min-width: 500px;
  justify-content: flex-end;
}

.input-select-file {
  width: 0px;
  visibility: hidden;
}

.wrapper-upload-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > label {
    margin-bottom: 0px;
  }

  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }

  .btn-upload {
    // padding: 0.5em 2em;

    &[disabled="disabled"] {
      color: #c1c1c1;
      cursor: not-allowed;
      border-color: #e5eaec90;
      background-color: #e5eaec90;
    }
  }
}
</style>

<template>
  <div class="col-12">
    <table class="table">
      <tbody>
        <tr>
          <td width-150px>Winner Notification</td>
          <td>
            <div class="col-md-8">
              <label class="switch">
                <input
                  type="checkbox"
                  :true-value="1"
                  :false-value="0"
                  @change="update"
                  :disabled="!canSwitchOnOff"
                  v-model="lot.winner_notification"
                />
                <span class="slider round"></span>
              </label>
              <p class="mx-10 d-inline">
                {{ +lot.winner_notification === 1 ? "ON" : "OFF" }}
              </p>
            </div>
          </td>
          <td>
            <label for="lot_winner_image" class="mx-5">
              Rich Message
              <span>(Recommended size 670x434 pixels / less than 1 MB)</span>
            </label>

            <div class="form-group">
              <div class="d-block">
                <img
                  class="img img-fluid"
                  v-if="lot.winner_image"
                  :src="lot.winner_image"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                class="form-control"
                @change="upload"
                :disabled="+lot.winner_notification !== 1"
              />
            </div>

            <div class="form-group">
              <button
                type="button"
                @click="clearImage"
                class="btn btn-link"
                :disabled="!lot.winner_image"
              >
                <i aria-hidden="true" class="icon md-close"></i>
                Clear image
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { lotService } from "@/api/client";

export default {
  name: "WinnerNotificationSettings",

  props: ["lot"],

  computed: {
    canSwitchOnOff() {
      return ["draft", "scheduled"].includes(this.lot.status);
    },
  },

  methods: {
    async update() {
      try {
        const { id, winner_notification } = this.lot;

        const form = { winner_notification: +winner_notification };

        await lotService.editLot(id, form);

        this.$emit("updated");
        this.$notify(
          `Winner notification ${+winner_notification == 1 ? "ON" : "OFF"}`
        );
      } catch (error) {
        this.$dialogs.alert(`Winner notification update error.`);
      }
    },

    async clearImage() {
      try {
        const { id } = this.lot;

        const form = { winner_image: null };

        await lotService.editLot(id, form);
        this.$emit("updated");
        this.$notify("Update Winner notification image success.");
      } catch (error) {
        console.error(error);
      }
    },

    async upload() {
      try {
        const { id } = this.lot;
        const image = event.target.files[0];

        const form = new FormData();
        form.append("winner_image", image);

        await lotService.uploadImage(id, form);

        this.$emit("updated");
        this.$notify("Upload Winner notification image success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img.img-fluid {
  max-width: 400px;
  max-height: 400px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "my-30" }, [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _c("div", { staticClass: "d-flex justify-content-end m-10" }, [
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v(" Collect Quota "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.collect_quota,
                        expression: "collect_quota",
                      },
                    ],
                    ref: "collect_quota_input",
                    staticClass: "form-control",
                    attrs: {
                      min: "0",
                      type: "number",
                      "width-150px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.collect_quota },
                    on: {
                      change: function ($event) {
                        return _vm.update("collect_quota")
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.collect_quota = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _vm._v(" Remaining "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("numberWithCommas")(_vm.campaign.remaining_quota)
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _c("div", { staticClass: "d-flex justify-content-end m-10" }, [
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v(" Max individual Collect "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.max_collect,
                        expression: "max_collect",
                      },
                    ],
                    ref: "max_collect_input",
                    staticClass: "form-control",
                    attrs: {
                      min: "0",
                      max: "100000000",
                      type: "number",
                      "width-150px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.max_collect },
                    on: {
                      change: function ($event) {
                        return _vm.update("max_collect")
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.max_collect = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("td"),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
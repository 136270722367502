import Winner from "@/views/winner/Index.vue";
import WinnerList from "@/views/winner/List.vue";

export default {
  path: "/winner",
  name: "Winner",
  component: Winner,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/",
      name: "WinnerList",
      component: WinnerList,
    },
  ],
};

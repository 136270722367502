const messages = {
  ER_DUP_ENTRY_auto_number:
    "กรุณาใส่ค่า Auto Number ที่ไม่ซ้ำกับค่าที่มีอยู่แล้ว",
  ER_DUP_ENTRY_group_code:
    "กรุณาใส่ค่า Group Code ที่ไม่ซ้ำกับค่าที่มีอยู่แล้ว",
  ER_DEACTIVETED_status:
    "ไม่สามารถเปลี่ยน Status ได้เนื่องจากมีการใช้งานอยู่ใน Lot ที่ยังไม่สิ้นสุด",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("auto_number") > -1:
      message = messages.ER_DUP_ENTRY_auto_number;
      break;
    case error.search("group_code") > -1:
      message = messages.ER_DUP_ENTRY_group_code;
      break;
    case error.search("deactivated") > -1:
      message = messages.ER_DEACTIVETED_status;
      break;
  }
  return message;
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h1", { staticClass: "page-title" }, [_vm._v("Prize Theme")]),
          _c("breadcrumb", { attrs: { name: "PRIZE_SUMMARY_THEME_LIST" } }),
          _c("div", { staticClass: "page-header-actions" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-icon btn-dark btn-outline btn-round mx-10",
                attrs: { type: "button" },
                on: { click: _vm.reloadTable },
              },
              [
                _c("i", {
                  staticClass: "icon md-refresh-alt",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Reload table "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-dark btn-outline mx-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.hideArchive = !_vm.hideArchive
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.hideArchive ? "Show all" : "Hide Inactive") +
                    " "
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.openCreateModal },
              },
              [
                _c("i", {
                  staticClass: "icon md-plus",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "page-content page-content-table" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "panel" }, [
            _c(
              "div",
              { staticClass: "panel-body p-0" },
              [
                _c("div", { staticClass: "vgt-global-search vgt-clearfix" }, [
                  _c(
                    "div",
                    { staticClass: "vgt-global-search__input vgt-pull-left" },
                    [
                      _vm._m(0),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTerm,
                            expression: "searchTerm",
                          },
                        ],
                        staticClass: "vgt-input vgt-pull-left",
                        attrs: { type: "text", placeholder: "Search Table" },
                        domProps: { value: _vm.searchTerm },
                        on: {
                          keyup: _vm.handleSearchChange,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchTerm = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", {
                    staticClass: "vgt-global-search__actions vgt-pull-right",
                  }),
                ]),
                _c("vue-good-table", {
                  ref: "table",
                  attrs: {
                    rows: _vm.themes,
                    columns: _vm.columns,
                    "sort-options": _vm.sortOption,
                    "pagination-options": _vm.pagination,
                    "search-options": {
                      enabled: true,
                      externalQuery: _vm.searchTerm,
                    },
                  },
                  on: {
                    "on-row-click": _vm.onRowClick,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPageChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "create-prize-theme-modal",
            width: 450,
            height: "auto",
          },
        },
        [
          _c("CreateModal", {
            on: {
              submit: function ($event) {
                return _vm.fetchTheme(1)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input__icon" }, [
      _c("div", { staticClass: "magnifying-glass" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
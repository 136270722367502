var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("h5", { staticClass: "col-md-12" }, [_vm._v("Campaign Name")]),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.campaign.name,
                        expression: "campaign.name",
                      },
                    ],
                    ref: "name",
                    staticClass: "form-control form__input",
                    attrs: { type: "text", autocomplete: "off" },
                    domProps: { value: _vm.campaign.name },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.campaign, "name", $event.target.value)
                        },
                        function ($event) {
                          return _vm.showValid("name")
                        },
                      ],
                    },
                  }),
                  _vm.showErrorNameValidation
                    ? _c("nameValidation", { attrs: { $v: _vm.$v.campaign } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("h5", { staticClass: "col-md-12" }, [_vm._v("Campaign Type")]),
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.campaign.type,
                        expression: "campaign.type",
                      },
                    ],
                    ref: "type",
                    staticClass: "form-control col-md-12",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.campaign,
                            "type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.handleCampaignTypeChange,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "", disabled: "" } }, [
                      _vm._v("Please select campaign type"),
                    ]),
                    _c("option", { attrs: { value: "barcode" } }, [
                      _vm._v("Barcode"),
                    ]),
                    _c("option", { attrs: { value: "team" } }, [
                      _vm._v("Team Barcode"),
                    ]),
                    _c("option", { attrs: { value: "monthly" } }, [
                      _vm._v("Monthly"),
                    ]),
                    _c("option", { attrs: { value: "task" } }, [
                      _vm._v("Task"),
                    ]),
                    _c("option", { attrs: { value: "leaderboard" } }, [
                      _vm._v("Leaderboard"),
                    ]),
                    _c("option", { attrs: { value: "monthlyshort" } }, [
                      _vm._v("MonthlyShort"),
                    ]),
                    _c("option", { attrs: { value: "weekly" } }, [
                      _vm._v("Weekly"),
                    ]),
                    _c("option", { attrs: { value: "stamp" } }, [
                      _vm._v("Stamp"),
                    ]),
                  ]
                ),
                _vm.hasSelectedCampaignType && !_vm.isSupportNonPanelType
                  ? _c("div", { staticClass: "my-2" }, [_vm._m(1)])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                {
                  staticClass: "col-md-12 d-flex align-items-center gap-5 my-3",
                },
                [
                  _c("h5", { staticClass: "mb-0" }, [_vm._v("User Type")]),
                  _c("div", { staticClass: "radio-custom radio-primary" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.campaign.user_type,
                          expression: "campaign.user_type",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "contact_id",
                        id: "user_type_contact_id",
                      },
                      domProps: {
                        checked: _vm._q(_vm.campaign.user_type, "contact_id"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.campaign,
                            "user_type",
                            "contact_id"
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "user_type_contact_id" } }, [
                      _vm._v("Panel (Contact ID)"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "radio-custom radio-primary mx-10" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.user_type,
                            expression: "campaign.user_type",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          value: "isms_code",
                          id: "user_type_isms_code",
                          disabled: !_vm.isSupportNonPanelType,
                        },
                        domProps: {
                          checked: _vm._q(_vm.campaign.user_type, "isms_code"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.campaign,
                              "user_type",
                              "isms_code"
                            )
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "user_type_isms_code" } }, [
                        _vm._v("Non-Panel (ISMS)"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("h5", { staticClass: "col-md-12" }, [_vm._v("User Group")]),
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectUserGroup,
                        expression: "selectUserGroup",
                      },
                    ],
                    staticClass: "form-control col-md-12",
                    attrs: {
                      disabled: _vm.campaign.user_type !== "contact_id",
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectUserGroup = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.usergroups, function (group) {
                    return _c(
                      "option",
                      { key: group.id, domProps: { value: group.id } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(" " + group.id + " - " + group.group_code) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-close",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Cancel "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.createCampaign },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("Create Progress Bar Campaign"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-warning" }, [
      _c("i", {
        staticClass: "mdi mdi-alert-outline",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(
        " Progress Bar ประเภทนี้ยังไม่รองรับ User ประเภท Non-panel (ISMS) "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
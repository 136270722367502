<template>
  <div v-if="campaign">
    <div class="row">
      <label class="form-control-label">
        <h4>LINE Notification</h4>
      </label>
    </div>

    <div class="row">
      <label class="d-inline mx-10 form-control-label">
        Announcement picture (recommended size 1000x560/less than 1 MB)
      </label>
    </div>
    <div class="form-group">
      <div class="d-block">
        <img
          class="img img-fluid image-preview"
          v-if="picture"
          :src="picture"
        />
      </div>
      <input
        type="file"
        accept="image/*"
        class="form-control"
        @change="uploadImage"
        :disabled="disabled"
      />
    </div>

    <div class="form-group">
      <div class="form-group col-md-6">
        <label> <h5>Custom headline text (announcement)</h5></label>
        <input
          type="text"
          class="form-control"
          ref="custom_headline_text"
          v-model="campaign.custom_headline_text"
          :disabled="disabled"
          @change="update('custom_headline_text')"
        />
      </div>
      <div class="form-group col-md-12">
        <label> <h5>Expiration Reminders</h5></label>
        <div class="form-group">
          <div class="form-group col-md-8">
            <label class="d-inline mx-10">#1 Date Time</label>
            <div class="d-inline mx-10">
              <DatePicker
                type="datetime"
                placeholder="n/a"
                :disabled="!canEditExpiration"
                :disabled-date="disabledBetweenStartEnd"
                v-model="campaign.expiration_reminders1"
                format="YYYY-MM-DD HH:mm:ss"
                value-type="format"
                :show-time-panel="showTimePanelexpiration_reminders1"
                @confirm="handleOpenChange('expiration_reminders1')"
                @clear="handleOpenChange('expiration_reminders1')"
                :confirm="true"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="
                      showTimePanelexpiration_reminders1 = !showTimePanelexpiration_reminders1
                    "
                  >
                    {{
                      showTimePanelexpiration_reminders1
                        ? "select date"
                        : "select time"
                    }}
                  </button>
                </template></DatePicker
              >
            </div>
          </div>
          <div class="form-group col-md-8">
            <label class="d-inline mx-10">#2 Date Time</label>
            <div class="d-inline mx-10">
              <DatePicker
                type="datetime"
                placeholder="n/a"
                :disabled="!canEditExpiration"
                v-model="campaign.expiration_reminders2"
                :disabled-date="disabledBetweenStartEnd"
                format="YYYY-MM-DD HH:mm:ss"
                value-type="format"
                :show-time-panel="showTimePanelexpiration_reminders2"
                @confirm="handleOpenChange('expiration_reminders2')"
                @clear="handleOpenChange('expiration_reminders2')"
                :confirm="true"
                ><template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="
                      showTimePanelexpiration_reminders2 = !showTimePanelexpiration_reminders2
                    "
                  >
                    {{
                      showTimePanelexpiration_reminders2
                        ? "select date"
                        : "select time"
                    }}
                  </button>
                </template></DatePicker
              >
            </div>
          </div>
          <div class="form-group col-md-8">
            <label class="d-inline mx-10">#3 Date Time</label>
            <div class="d-inline mx-10">
              <DatePicker
                type="datetime"
                placeholder="n/a"
                :disabled="!canEditExpiration"
                v-model="campaign.expiration_reminders3"
                :disabled-date="disabledBetweenStartEnd"
                format="YYYY-MM-DD HH:mm:ss"
                value-type="format"
                :show-time-panel="showTimePanelexpiration_reminders3"
                @confirm="handleOpenChange('expiration_reminders3')"
                @clear="handleOpenChange('expiration_reminders3')"
                :confirm="true"
                ><template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="
                      showTimePanelexpiration_reminders3 = !showTimePanelexpiration_reminders3
                    "
                  >
                    {{
                      showTimePanelexpiration_reminders3
                        ? "select date"
                        : "select time"
                    }}
                  </button>
                </template></DatePicker
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { responseErrorMapping } from "../model/error";
export default {
  props: ["campaign"],
  components: {
    DatePicker,
  },
  data() {
    return {
      file: null,
      fileImage: null,
      id: null,
      oldValue: {},
      today: new Date(),
      showTimePanelexpiration_reminders1: false,
      showTimePanelexpiration_reminders2: false,
      showTimePanelexpiration_reminders3: false,
      bodyDateTime: {},
    };
  },
  mounted() {
    this.oldValue = Object.assign({}, this.campaign);
    this.id = this.campaign.id;
  },
  computed: {
    picture() {
      return this.campaign.image;
    },

    disabled() {
      return this.campaign.status != "draft";
    },
    canEditExpiration() {
      return ["draft"].includes(this.campaign.status);
    },
  },
  methods: {
    handleOpenChange(field) {
      this.showTimePanelexpiration_reminders1 = false;
      this.showTimePanelexpiration_reminders2 = false;
      this.showTimePanelexpiration_reminders3 = false;
      if (
        this.campaign[field] == "0000-00-00 00:00:00" ||
        this.campaign[field] == "null"
      ) {
        this.campaign[field] = null;
      }
      this.updateDateTime(field);
    },
    disabledBetweenStartEnd(date) {
      return (
        moment(date) < moment(this.campaign.start_date).subtract(1, "days") ||
        moment(date) > moment(this.campaign.end_date)
      );
    },
    async uploadImage(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        e.target.files[0].value = "";
        return;
      }
      try {
        if (!file) return;
        const form = new FormData();
        form.append("id", this.id);
        form.append("image", file);
        await ecouponService.uploadImageCampaign(form);

        this.$emit("updated");
        this.$notify(`Picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload picture error.`);
      }
    },
    async isFormValid(field) {
      switch (field) {
        case "custom_headline_text":
          if (!this.campaign.custom_headline_text) {
            await this.$dialogs.alert(
              "กรุณากรอก Custom headline text ให้ถูกต้อง"
            );
            this.$refs.custom_headline_text.focus();
            this.campaign.custom_headline_text = this.oldValue.custom_headline_text;
            return false;
          } else if (this.campaign.custom_headline_text.length > 40) {
            await this.$dialogs.alert(
              "กรุณากรอก Custom headline text สูงสุดไม่เกิน 40 ตัวอักษร"
            );
            this.$refs.custom_headline_text.focus();
            this.campaign.custom_headline_text = this.oldValue.custom_headline_text;
            return false;
          }
          break;

        case "expiration_reminders1":
          if (this.isBetween(this.campaign.expiration_reminders1)) {
            await this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );

            this.campaign.expiration_reminders1 = this.oldValue.expiration_reminders1;
            return false;
          }
          break;

        case "expiration_reminders2":
          if (this.isBetween(this.campaign.expiration_reminders2)) {
            await this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            console.log(this.campaign.expiration_reminders2);
            this.campaign.expiration_reminders2 = this.oldValue.expiration_reminders2;
            return false;
          }
          break;

        case "expiration_reminders3":
          if (this.isBetween(this.campaign.expiration_reminders3)) {
            await this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            this.campaign.expiration_reminders3 = this.oldValue.expiration_reminders3;
            return false;
          }
          break;

        default:
          break;
      }

      return true;
    },

    async isFormValidAfterGoing(field) {
      // expiration_reminders1 not today if status ongoing,scheduled

      switch (field) {
        case "expiration_reminders1":
          if (
            moment(this.campaign.expiration_reminders1) <= moment(this.today)
          ) {
            await this.$dialogs.alert(
              "สามารถใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.campaign.expiration_reminders1 = this.oldValue.expiration_reminders1;
            return false;
          }
          break;

        case "expiration_reminders2":
          if (
            moment(this.campaign.expiration_reminders2) <= moment(this.today)
          ) {
            await this.$dialogs.alert(
              "สามารถใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.campaign.expiration_reminders2 = this.oldValue.expiration_reminders2;
            return false;
          }
          break;

        case "expiration_reminders3":
          if (
            moment(this.campaign.expiration_reminders3) <= moment(this.today)
          ) {
            await this.$dialogs.alert(
              "สามารถใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.campaign.expiration_reminders3 = this.oldValue.expiration_reminders3;
            return false;
          }
          break;

        default:
          break;
      }

      return true;
    },
    isBetween(date) {
      return (
        moment(date) < moment(this.campaign.start_date) ||
        moment(date) > moment(this.campaign.end_date)
      );
    },
    setBodyDateTime(field) {
      switch (field) {
        case "expiration_reminders1":
          this.bodyDateTime = {
            expiration_reminders1: this.campaign.expiration_reminders1,
          };
          break;
        case "expiration_reminders2":
          this.bodyDateTime = {
            expiration_reminders2: this.campaign.expiration_reminders2,
          };
          break;
        case "expiration_reminders3":
          this.bodyDateTime = {
            expiration_reminders3: this.campaign.expiration_reminders3,
          };
          break;

        default:
          return;
      }
    },
    async updateDateTime(field) {
      const formValid = await this.isFormValid(field);
      if (!formValid) return;
      if (this.campaign.status == "ongoing") {
        const formValidAfterGoing = await this.isFormValidAfterGoing(field);
        if (!formValidAfterGoing) return;
      }
      this.setBodyDateTime(field);

      try {
        await ecouponService.updateCampaign(
          this.campaign.id,
          this.bodyDateTime
        );
        this.$notify(`Line notification update.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
    async update(field) {
      try {
        const formValid = await this.isFormValid(field);
        if (!formValid) return;
        if (this.campaign.status == "ongoing") {
          const formValidAfterGoing = await this.isFormValidAfterGoing(field);
          if (!formValidAfterGoing) return;
        }
        const form = new FormData();
        form.set(field, this.campaign[field]);
        const body = Object.fromEntries(form);
        await ecouponService.updateCampaign(this.campaign.id, body);
        this.$notify(`Line notification update.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="col-12">
    <table class="table">
      <tbody>
        <tr>
          <td width-150px>Outbid Notification</td>
          <td>
            <div class="col-md-8">
              <label class="switch">
                <input
                  type="checkbox"
                  :true-value="1"
                  :false-value="0"
                  @change="update"
                  :disabled="!canSwitchOnOff"
                  v-model="lot.outbid_notification"
                />
                <span class="slider round"></span>
              </label>
              <p class="mx-10 d-inline">
                {{ +lot.outbid_notification === 1 ? "ON" : "OFF" }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { lotService } from "@/api/client";

export default {
  name: "OutBidNotificationSettings",

  props: ["lot"],

  computed: {
    canSwitchOnOff() {
      return ["draft", "scheduled"].includes(this.lot.status);
    },
  },

  methods: {
    async update() {
      try {
        const { id, outbid_notification } = this.lot;

        const form = { outbid_notification: +outbid_notification };

        await lotService.editLot(id, form);

        this.$emit("updated");
        this.$notify(
          `Outbid notification ${+outbid_notification == 1 ? "ON" : "OFF"}`
        );
      } catch (error) {
        this.$dialogs.alert(`Outbid notification update error.`);
      }
    },
  },
};
</script>

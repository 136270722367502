import Campaign from "@/views/campaign/Index.vue";
import CampaignList from "@/views/campaign/List.vue";
import CampaignEdit from "@/views/campaign/Edit.vue";

export default {
  path: "/campaign",
  name: "Campaign",
  component: Campaign,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/",
      name: "CampaignList",
      component: CampaignList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "edit/:id",
      name: "CampaignEdit",
      component: CampaignEdit,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

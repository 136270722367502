import qs from "qs";
import HttpRequest from "./request";

export class ReportProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/dailylogin/report`);
  }

  async getReport(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`${queryString}`);
    return data;
  }
  async getDetailbyId(campains_id, user_id) {
    const { data } = await this.get(`/${campains_id}/${user_id}`);
    return data;
  }
  async getDownloadFile(id, count, seacrh, oderby, desc) {
    if (id == undefined) return;
    var messageapi = `/${id}?start=0&count=${count}`;
    if (seacrh != null) {
      messageapi += `&search=${seacrh}`;
    }
    if (oderby != null) {
      messageapi += `&order_by=${oderby}&desc=${desc}`;
    }

    const { data } = await this.get(messageapi);
    return data;
  }

  async getReportDownload(id) {
    return await this.axiosInstance.get(
      `${process.env.VUE_APP_SERVICE_URL}/ams/api/dailylogin/report/download/${id}`,
      {
        responseType: "blob",
      }
    );
  }
  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }
  async getReport_month_year(month = "ALL", year = "ALL") {
    const { data } = await this.get(`?month=${month}&year=${year}`);
    return data;
  }

  async createReport(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editReport(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }
}

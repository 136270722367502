import { numberWithCommas } from "@/filters";

export const tableColumns = [
  {
    label: "Pool ID",
    field: "id",
    type: "number",
  },
  {
    label: "Prize Name",
    field: "name",
  },
  {
    label: "Pool Type",
    field: "type",
  },
  {
    label: "Vendor",
    field: "vendor",
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Total",
    field: "count_total",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Used",
    field: "count_used",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Unusable",
    field: "count_unusable",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Available",
    field: "count_available",
    type: "number",
    thClass: 'table-header-green',
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Reserved",
    field: "count_reserved",
    type: "number",
    formatFn: (value) =>
      typeof value == "number" ? numberWithCommas(value) : value,
  },
  {
    label: "Margin",
    field: "count_margin",
    type: "number",
    formatFn: function (value) {
      return typeof value == "number" && value > 0
        ? `<span class="red">${numberWithCommas(value)}</span>`
        : value;
    },
  },
];

export const detailTableColumns = [
  {
    label: "Item ID",
    field: "id",
    type: "number",
  },
  {
    label: "Unique Code",
    field: "unique_code",
  },
  {
    label: "Face Value",
    field: "face_value",
    type: "number",
  },
  {
    label: "Expiration Date",
    field: "expiration_date",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Theme ID",
    field: "theme_id",
    type: "number",
  },
  {
    label: "Request ID",
    field: "request_id",
    type: "number",
  },
  {
    label: "Owner ISMS",
    field: "owner_isms",
  },
  {
    label: "Owner ContactID",
    field: "owner_contact_id",
  },
  {
    label: "App Name ",
    field: "app_id",
  },
  {
    label: "App CP ID",
    field: "app_campaign_id",
    type: "number",
  },
  {
    label: "App Ref",
    field: "app_ref",
    type: "number",
  },
  {
    label: "Uploaded TS",
    field: "upload_timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
  {
    label: "Claimed TS",
    field: "claim_timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
];

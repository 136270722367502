var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Game Leaderboard")]),
        _c("breadcrumb", {
          attrs: { name: "GAME_LEADER_BOARD_CAMPAIGN_STATUS" },
        }),
        _c("div", { staticClass: "page-header-left" }, [
          _c("div", { staticClass: "campaign-select" }, [
            _c("h4", [_vm._v("Campaign Name")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected",
                  },
                ],
                ref: "select",
                staticClass: "selectpicker",
                attrs: {
                  "data-width": "300px",
                  "data-live-search": "true",
                  "data-none-selected-text": "กรุณาเลือก campaign",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.campaigns, function (ref) {
                var id = ref.id
                var name = ref.name
                return _c("option", { key: id, domProps: { value: id } }, [
                  _vm._v(" " + _vm._s(id) + " - " + _vm._s(name) + " "),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "page-header-actions" }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _vm.campaignInfo
          ? _c("div", { staticClass: "panel" }, [
              _c("div", { staticClass: "panel-body" }, [
                _c("table", { staticClass: "table table-borderless" }, [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.campaignInfo.id))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateTimeMedium")(
                              _vm.campaignInfo.start_date
                            )
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateTimeMedium")(_vm.campaignInfo.end_date)
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toUpperCase")(_vm.campaignInfo.status))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.campaignInfo.point_granted
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    rows: _vm.list,
                    columns: _vm.columns,
                    totalRows: _vm.totalRecords,
                    "sort-options": _vm.sortOption,
                    "search-options": _vm.searchOption,
                    "pagination-options": _vm.pagination,
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPerPageChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function (props) {
                        return [
                          props.column.field == "status"
                            ? _c("span", [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toUpperCase")(props.row.status)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c("div", { staticClass: "Excel" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: { click: _vm.exportToExcel },
                          },
                          [
                            _c("i", { staticClass: "fa fa-file-excel-o" }),
                            _vm._v(" Export to Excel "),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Campaign Id")]),
        _c("th", [_vm._v("Start")]),
        _c("th", [_vm._v("End")]),
        _c("th", [_vm._v("Status")]),
        _c("th", [_vm._v("Total SF Point Granted")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <ol class="breadcrumb">
    <li v-for="(item, idx) in breadcrumbs" :key="idx" :class="getClass(idx)">
      <router-link
        v-if="!isLastItem(idx) && typeof item.link !== 'function'"
        :to="item.link"
      >
        {{ item.text }}
      </router-link>
      <a
        v-else-if="!isLastItem(idx) && typeof item.link === 'function'"
        href="javascript:void(null);"
        @click="item.link($router)"
      >
        {{ item.text }}
      </a>
      <span v-else>
        {{ item.text }}
      </span>
    </li>
  </ol>
</template>

<script>
import * as b from "@/config/breadcrumb";

export default {
  props: {
    name: String,
    withLast: String,
  },
  data() {
    return {
      breadcrumbs: [],
      template_breadcrumbs: [],
    };
  },
  mounted() {
    this.render();
  },
  watch: {
    withLast(v, _) {
      this.render();
    },
  },
  methods: {
    render() {
      this.template_breadcrumbs = b[this.name];
      this.breadcrumbs = [...this.template_breadcrumbs];

      if (this.withLast) {
        this.breadcrumbs.push({
          text: this.withLast,
          link: "/",
        });
      }
    },
    isLastItem(id) {
      return id + 1 == this.breadcrumbs.length;
    },
    getClass(id) {
      return id + 1 == this.breadcrumbs.length
        ? "breadcrumb-item active"
        : "breadcrumb-item";
    },
  },
};
</script>

<style lang="scss" scoped>
ol.breadcrumb {
  transform: translate(2px, 8px);
}
</style>

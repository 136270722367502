var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "label",
      { staticClass: "my-10 d-none", attrs: { for: "display" } },
      [
        _c("toggle-button", {
          attrs: {
            id: "display",
            value: false,
            color: "#3e8ef7",
            sync: true,
            labels: true,
          },
          model: {
            value: _vm.display,
            callback: function ($$v) {
              _vm.display = $$v
            },
            expression: "display",
          },
        }),
        _c("span", { staticClass: "mx-10" }, [
          _vm._v(_vm._s(_vm.display ? "ซ่อน" : "แสดง") + " validate"),
        ]),
      ],
      1
    ),
    _vm.display
      ? _c("ul", { staticClass: "list-icons mb-0" }, [
          _c(
            "li",
            { class: { "text-success": _vm.$v.campaign.name.required } },
            [
              !_vm.$v.campaign.name.required
                ? _c("i", {
                    staticClass: "wb-close",
                    attrs: { "aria-hidden": "true" },
                  })
                : _c("i", {
                    staticClass: "wb-check",
                    attrs: { "aria-hidden": "true" },
                  }),
              _vm._v(" จำเป็นต้องระบุ Point Campaign name "),
            ]
          ),
          _c(
            "li",
            { class: { "text-success": _vm.$v.campaign.name.maxLength } },
            [
              !_vm.$v.campaign.name.maxLength
                ? _c("i", {
                    staticClass: "wb-close",
                    attrs: { "aria-hidden": "true" },
                  })
                : _c("i", {
                    staticClass: "wb-check",
                    attrs: { "aria-hidden": "true" },
                  }),
              _vm._v(
                " ต้องระบุไม่เกิน " +
                  _vm._s(_vm.$v.campaign.name.$params.maxLength.max) +
                  " ตัวอักษร "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
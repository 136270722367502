export const tableColumns = [
  {
    label: "Lot ID",
    field: "id",
    type: "number",
  },
  {
    label: "Lot Name ",
    field: "lot_name",
  },
  {
    label: "Issue Date",
    field: "issue_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Expire Date",
    field: "expire_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "No. Prizes",
    field: "num_prize",
    type: "number",
  },
  {
    label: "No. Claims",
    field: "num_claim",
    type: "number",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

<template>
  <div class="col-md-12" v-if="stamp">
    <div class="form-group-row">
      <label class="mb-0">No. Stamp</label>

      <select
        type="text"
        name="no_stamp"
        @change="update"
        :disabled="disabled"
        class="form-control"
        v-model.number="stamp.no_stamp"
      >
        <option
          v-for="(value, index) in items"
          :value="value"
          :key="index"
          :disabled="disabledOption(value)"
        >
          {{ value }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],
  data() {
    return {
      message: {
        error: "Update No. Stamp error.",
        success: "Update No. Stamp success.",
      },
    };
  },

  computed: {
    items() {
      const list = Array(20)
        .fill(5)
        .map((_, i) => (i + 1) * 5);

      list.splice(1, 0, 6);

      return list;
    },
  },

  methods: {
    disabledOption(option) {
      return option < this.stamp.reward.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
}

.form-group-row {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-weight: 500;
    margin-bottom: 0px;
  }

  select {
    max-width: 150px;
  }
}
</style>

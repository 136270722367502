<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">
            Round {{ round.round_id }}: Question {{ index + 1 }}
          </h3>
        </div>
        <div class="panel-body">
          <div class="container" v-if="question">
            <div class="form-group row">
              <label class="form-control-label col-md-2">
                Description<br />(Internal Only)
              </label>
              <input
                type="text"
                name="description"
                maxlength="255"
                class="form-control col-md-10"
                v-model="question.description"
                @change="editQuestion"
                :disabled="!isValidStatus"
              />
            </div>
            <div class="form-group row">
              <label class="form-control-label col-md-2">Question Body</label>
              <textarea
                rows="3"
                name="question"
                class="form-control col-md-10"
                v-model="question.question"
                @change="editQuestion"
                :disabled="!isValidStatus"
              ></textarea>
            </div>
            <div class="form-group row">
              <label class="form-control-label col-md-12">Choice</label>
              <div class="col-md-12">
                <div
                  class="row mb-3"
                  v-for="(choice, index) in question.choice"
                  :key="choice.choice_id"
                >
                  <div class="col-md-2 d-flex align-items-center">
                    {{ index + 1 }}.
                  </div>
                  <input
                    type="text"
                    class="form-control col-md-9"
                    v-model="choice.choice"
                    :choiceId="choice.choice_id"
                    @change="updateChoice"
                    :disabled="!isValidStatus"
                  />
                  <div class="action col-md-1">
                    <button
                      type="button"
                      class="btn btn-default"
                      :disabled="!canRemoveChoice"
                      @click="
                        removeChoice(
                          round.round_id,
                          question.question_id,
                          choice.choice_id
                        )
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="action col-md-12">
                <button
                  type="button"
                  class="btn btn-default"
                  :disabled="!canAddChoice"
                  @click="addChoice(round.round_id, question.question_id)"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="form-group row">
              <label class="form-control-label col-md-2">Correct Answer</label>
              <select
                name="correct_answer"
                class="form-control col-md-2"
                :disabled="!isValidStatus"
                v-model.number="question.correct_answer"
                @change="editQuestion($event, question.correct_answer)"
              >
                <option v-for="n in question.choice.length" :key="n" :value="n">
                  {{ n }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="close"
            >
              <i class="icon md-check" aria-hidden="true"></i>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { staffEngagementService } from "@/api/client";

export default {
  name: "RoundQuestionModal",

  props: ["id", "index", "campaign", "round", "onChange"],

  data() {
    return {
      question: null,
      hasChange: false,
    };
  },

  computed: {
    isValidStatus() {
      return !["archived", "end"].includes(this.campaign.status);
    },
    canAddChoice() {
      return this.isValidStatus && this.question.choice.length < 4;
    },
    canRemoveChoice() {
      return this.isValidStatus && this.question.choice.length > 2;
    },
  },

  methods: {
    async getQuestionInfo() {
      this.question = await staffEngagementService.getQuestionById(
        this.campaign.id,
        this.round.round_id,
        this.id
      );
    },

    async editQuestion(event, model) {
      const name = event.target.name;
      const value = model != undefined ? model : event.target.value;

      const form = {};
      form[name] = value;

      try {
        await staffEngagementService.editQuestion(
          this.campaign.id,
          this.round.round_id,
          this.question.question_id,
          form
        );

        this.hasChange = true;

        this.$notify(`Update ${name.replace(/_/gi, " ")} success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Update question failed. Please try again.");
      }
    },

    async addChoice(roundId, questionId) {
      try {
        await staffEngagementService.addChoice(
          this.campaign.id,
          roundId,
          questionId
        );

        this.hasChange = true;

        this.getQuestionInfo();
        this.$notify("Add choice success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Add choice failed. Please try again.");
      }
    },

    async updateChoice(event) {
      const value = event.target.value;
      const choiceId = event.target.attributes.choiceId.value;

      const form = { choice: value };

      try {
        await staffEngagementService.editChoice(
          this.campaign.id,
          this.round.round_id,
          this.question.question_id,
          choiceId,
          form
        );

        this.hasChange = true;

        this.$notify("Update choice success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Update choice failed. Please try again.");
      }
    },

    async removeChoice(roundId, questionId, choiceId) {
      try {
        await staffEngagementService.removeChoice(
          this.campaign.id,
          roundId,
          questionId,
          choiceId
        );

        this.hasChange = true;

        await this.updateAnswer(choiceId);

        this.getQuestionInfo();
        this.$notify("Remove choice success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Remove choice failed. Please try again.");
      }
    },

    async updateAnswer(choiceId) {
      const form = { correct_answer: 0 };

      const index = this.question.choice.findIndex(
        (choice) => choice.choice_id === choiceId
      );

      // If the correct answer is equal to the choice that is removed
      if (index + 1 === this.question.correct_answer) {
        form.correct_answer = 1;
      }

      // If the correct answer is greater than the choice that is removed
      if (index + 1 < this.question.correct_answer) {
        form.correct_answer = this.question.correct_answer - 1;
      }

      // If the correct answer is less than the choice that is removed
      if (index + 1 > this.question.correct_answer) {
        return;
      }

      try {
        await staffEngagementService.editQuestion(
          this.campaign.id,
          this.round.round_id,
          this.question.question_id,
          form
        );
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Update answer failed. Please try again.");
      }
    },

    close() {
      if (this.question.correct_answer === null) {
        alert("Please select correct answer.");
        return;
      }

      if (this.hasChange) {
        this.onChange();
      }

      this.$modal.hide("round-question-modal");
    },
  },

  mounted() {
    this.getQuestionInfo();
  },
};
</script>

export const ccb2bLogColumns = [
  {
    label: "Task ID",
    field: "task_id",
    type: "number",
  },
  {
    label: "Start Time",
    field: "start_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Last Update",
    field: "lastupdate",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "No. Success",
    field: "no_success",
    type: "number",
  },
  {
    label: "No. Total",
    field: "no_total",
    type: "number",
  },
  {
    label: "No. Value Success",
    field: "total_value_success",
    type: "number",
  },
];

export const ccb2bLogDetailColumns = [
  {
    width: "180px",
    label: "Request TS",
    field: "request_ts",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "ISMS",
    field: "isms_code",
    type: "number",
  },
  {
    label: "Value",
    field: "total_value",
    type: "number",
  },
  {
    label: "Status",
    field: "job_status",
  },
  {
    width: "180px",
    label: "Upload time",
    field: "upload_ts",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Coupon ID",
    field: "coupon_id",
    type: "number",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Upload message",
    field: "upload_message",
  },
  {
    label: "Cancelled",
    field: "cancelled",
    type: "number",
  },
];

<template>
  <div class="col-md-8" v-if="stamp">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Background image
            <span class="small light">
              Recommended size 375 x 840 pixels / less than 1 MB
            </span>
          </h4>
        </label>

        <div class="form-group">
          <div class="image-form-wrapper">
            <div class="image-preview">
              <img class="img img-fluid" v-if="picture" :src="picture" />
              <div v-else class="image-placeholder"></div>
            </div>
            <input
              type="file"
              accept="image/*"
              class="form-control"
              @change="uploadImage"
              ref="background_picture"
              name="background_picture"
              :disabled="disabled"
            />
            <div class="action" v-if="picture">
              <button
                type="button"
                :disabled="disabled"
                class="btn btn-default reset-img"
                @click="showConfirmDelete"
              >
                <i class="icon md-delete" aria-hidden="true"></i>
                Remove image
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Background picture error.",
        success: "Upload Background picture success.",
      },
    };
  },

  computed: {
    picture() {
      return this.campaign.stamp.background_picture;
    },
  },

  methods: {
    showConfirmDelete() {
      const r = confirm("Do you really want to delete background image?");
      if (r == true) {
        this.removeImage({ background_picture: null });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/upload.form.scss";

.image-form-wrapper {
  width: 375px;

  .action {
    width: 100%;
    height: 100%;
    position: relative;

    .reset-img {
      right: 0.5rem;
      bottom: 0.5rem;
      position: absolute;
    }
  }
}
</style>

const messages = {
  ER_EMPRT_name: "กรุณาใส่ค่าใน Game instance name",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("name") > -1:
      message = messages.ER_EMPRT_name;
      break;

    default:
      message = error
        .replace(/Error:/g, "")
        .replace(/_/g, " ")
        .trim();
      break;
  }
  return message;
};

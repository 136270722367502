var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm._m(0),
    _c("div", { staticClass: "col-md-10" }, [
      _c(
        "div",
        { staticClass: "round-question" },
        [
          _vm._l(_vm.round.question, function (question, index) {
            return _c(
              "div",
              { key: question.question_id, staticClass: "question" },
              [
                _c("p", { staticClass: "no" }, [
                  _vm._v(_vm._s(index + 1) + "."),
                ]),
                _c(
                  "p",
                  { staticClass: "description badge badge-pill badge-primary" },
                  [_vm._v(" " + _vm._s(question.description || "-") + " ")]
                ),
                _c("p", { staticClass: "question" }, [
                  _vm._v(_vm._s(question.question || "-")),
                ]),
                _c("p", { staticClass: "choice-count" }, [
                  _vm._v(" No. choice: "),
                  _c("span", [_vm._v(_vm._s(question.choice.length))]),
                ]),
                _c("div", { staticClass: "space" }),
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function ($event) {
                          return _vm.editQuestion(
                            question.question_id,
                            index,
                            _vm.round
                          )
                        },
                      },
                    },
                    [
                      _vm.isValidStatus
                        ? _c("i", { staticClass: "fa fa-pencil" })
                        : _c("i", { staticClass: "fa fa-eye" }),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { disabled: !_vm.canRemoveQuestion },
                      on: {
                        click: function ($event) {
                          return _vm.removeQuestion(question.question_id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  ),
                ]),
              ]
            )
          }),
          _c("div", { staticClass: "actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { disabled: !_vm.canAddQuestion },
                on: { click: _vm.addQuestion },
              },
              [_c("i", { staticClass: "fa fa-plus" })]
            ),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-10" }, [
      _c("label", [_c("h5", [_vm._v("Questions")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config
    ? _c("div", [
        _c("label", { staticClass: "mx-5" }, [
          _vm._v(" " + _vm._s(_vm.config.name) + " "),
          _c("span", { staticClass: "light" }, [
            _vm._v(" " + _vm._s(_vm.config.label)),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "img-upload-wrapper" }, [
            _vm.picture
              ? _c("img", {
                  staticClass: "img img-fluid image-preview",
                  attrs: { src: _vm.picture },
                })
              : _c("div", { staticClass: "img-fluid image-preview" }),
          ]),
          _c("input", {
            ref: _vm.config.ref,
            staticClass: "form-control",
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.uploadImage },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c(
        "div",
        { staticClass: "page" },
        [
          _c("div", { staticClass: "page-main" }, [
            _c("div", { staticClass: "page-header" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                },
                [
                  _c("h1", { staticClass: "page-title" }, [_vm._v("Campaign")]),
                  _c("breadcrumb", {
                    attrs: {
                      name: "LUCKY_LOGIN_CAMPAIGN_EDIT",
                      withLast: _vm.campaign.name,
                    },
                    on: {
                      "update:withLast": function ($event) {
                        return _vm.$set(_vm.campaign, "name", $event)
                      },
                      "update:with-last": function ($event) {
                        return _vm.$set(_vm.campaign, "name", $event)
                      },
                    },
                  }),
                  _c("div", { staticClass: "page-header-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-round btn-default mx-10",
                        attrs: { type: "button" },
                        on: { click: _vm.goBack },
                      },
                      [
                        _c("i", {
                          staticClass: "icon md-chevron-left",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" Back "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "page-content container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                  },
                  [
                    _c("div", { staticClass: "panel panel-bordered" }, [
                      _c("div", { staticClass: "panel-heading" }, [
                        _c("h3", { staticClass: "panel-title" }, [
                          _vm._v(
                            " Campaign Information - " +
                              _vm._s(_vm.campaign.id) +
                              " "
                          ),
                        ]),
                        _c("div", {
                          staticClass: "panel-actions panel-actions-keep",
                        }),
                      ]),
                      _c("div", { staticClass: "panel-body" }, [
                        _c(
                          "form",
                          { staticClass: "form-horizontal" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c("CampaignName", {
                                    attrs: { campaign: _vm.campaign },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-3" },
                                [
                                  _c("StatusDropdown", {
                                    attrs: { campaign: _vm.campaign },
                                    on: { updated: _vm.fetchCampaign },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm._m(0),
                                _c("CampaignSummary", {
                                  attrs: { campaign: _vm.campaign },
                                }),
                                _c("CampaignMedia", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _vm._m(1),
                            _c("CampaignDuration", {
                              attrs: { campaign: _vm.campaign },
                              on: { updated: _vm.fetchCampaign },
                            }),
                            _c("hr"),
                            _c("UserGroup", {
                              attrs: { campaign: _vm.campaign },
                              on: { updated: _vm.fetchCampaign },
                            }),
                            _c("hr"),
                            _c("h5", [_vm._v("Play Logic")]),
                            _c("TriesPerPeriod", {
                              attrs: { campaign: _vm.campaign },
                              on: { updated: _vm.fetchCampaign },
                            }),
                            _c("RedirectUrl", {
                              attrs: { campaign: _vm.campaign },
                              on: { updated: _vm.fetchCampaign },
                            }),
                            _c("hr"),
                            _c("BulkUploadLuckyNumber", {
                              attrs: {
                                campaign: _vm.campaign,
                                campaignId: _vm.campaign.id,
                              },
                              on: { uploaded: _vm.fetchCampaign },
                            }),
                            _vm._l(
                              _vm.campaign.lucky_number_list,
                              function (luckyNumber, index) {
                                return _c("LuckyNumberRow", {
                                  key: index,
                                  attrs: {
                                    index: index,
                                    campaign: _vm.campaign,
                                    luckyNumber: luckyNumber,
                                  },
                                  on: { updated: _vm.fetchCampaign },
                                })
                              }
                            ),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-defalut",
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.canAddLuckyNumber,
                                    },
                                    on: { click: _vm.addLuckyNumber },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon md-plus",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("h5", [_vm._v("Tasks")]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("label", { staticClass: "switch" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.campaign.enable_tasks,
                                          expression: "campaign.enable_tasks",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        "true-value": 1,
                                        "false-value": 0,
                                        disabled: !_vm.canSwitchTaskOnOff,
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.campaign.enable_tasks
                                        )
                                          ? _vm._i(
                                              _vm.campaign.enable_tasks,
                                              null
                                            ) > -1
                                          : _vm._q(
                                              _vm.campaign.enable_tasks,
                                              1
                                            ),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a = _vm.campaign.enable_tasks,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "enable_tasks",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "enable_tasks",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.campaign,
                                                "enable_tasks",
                                                $$c
                                              )
                                            }
                                          },
                                          _vm.setEnableTask,
                                        ],
                                      },
                                    }),
                                    _c("span", { staticClass: "slider round" }),
                                  ]),
                                  _c("p", { staticClass: "mx-10 d-inline" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          +_vm.campaign.enable_tasks === 1
                                            ? "ON"
                                            : "OFF"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _vm.campaign.enable_tasks
                                  ? _c(
                                      "div",
                                      { staticClass: "task-list" },
                                      [
                                        _vm._l(
                                          _vm.campaign.task_list,
                                          function (task, index) {
                                            return _c("TaskRow", {
                                              key: index,
                                              attrs: {
                                                task: task,
                                                index: index,
                                                campaign: _vm.campaign,
                                                taskAmount:
                                                  _vm.campaign.task_list.length,
                                              },
                                              on: {
                                                remove: _vm.removeTask,
                                                edit: _vm.showTaskDetail,
                                                updated: _vm.fetchCampaign,
                                              },
                                            })
                                          }
                                        ),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-defalut",
                                                attrs: {
                                                  type: "button",
                                                  disabled: !_vm.canAddTask,
                                                },
                                                on: { click: _vm.addTask },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "icon md-plus",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("hr"),
                            _c("ConsolidatePrize", {
                              attrs: { campaign: _vm.campaign },
                              on: { updated: _vm.fetchCampaign },
                            }),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("h5", [_vm._v("Require Winner Address")]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("label", { staticClass: "switch" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.campaign.require_winner_address,
                                          expression:
                                            "campaign.require_winner_address",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        "true-value": 1,
                                        "false-value": 0,
                                        disabled: !_vm.canSwitchRequireAddress,
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.campaign.require_winner_address
                                        )
                                          ? _vm._i(
                                              _vm.campaign
                                                .require_winner_address,
                                              null
                                            ) > -1
                                          : _vm._q(
                                              _vm.campaign
                                                .require_winner_address,
                                              1
                                            ),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a =
                                                _vm.campaign
                                                  .require_winner_address,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "require_winner_address",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "require_winner_address",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.campaign,
                                                "require_winner_address",
                                                $$c
                                              )
                                            }
                                          },
                                          _vm.setRequireWinnerAddress,
                                        ],
                                      },
                                    }),
                                    _c("span", { staticClass: "slider round" }),
                                  ]),
                                  _c("p", { staticClass: "mx-10 d-inline" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          +_vm.campaign
                                            .require_winner_address === 1
                                            ? "ON"
                                            : "OFF"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _vm._m(2),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c(
            "modal",
            {
              attrs: { width: 900, height: "auto", name: "edit-task-modal" },
              on: { closed: _vm.onTaskModalClose },
            },
            [
              _c("EditTaskModal", {
                attrs: { task: _vm.selectedTask },
                on: { close: _vm.closeTaskModal },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Campaign Info")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label px-0" }, [
      _c("h4", [_vm._v("Campaign Duration")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span :class="['material-icons', `md-${size}`]">
    {{ icon }}
  </span>
</template>

<script>
// http://google.github.io/material-design-icons/
export default {
  name: "BaseIcon",
  props: {
    size: {
      type: String,
      default: "24"
    },
    icon: {
      type: String,
      default: "user",
      required: true
    }
  }
};
</script>

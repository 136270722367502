<template>
  <div v-if="campaign" class="my-30">
    <div class="row">
      <label class="d-inline mx-10 form-control-label">
        Coupon collect expiration
      </label>
      <div class="d-inline mx-10">
        <DatePicker
          type="datetime"
          width-200px
          @confirm="update"
          :confirm="true"
          :clearable="false"
          placeholder="n/a"
          ref="coupon_collection_expiration"
          format="YYYY-MM-DD HH:mm:ss"
          value-type="format"
          :disabled="disabled"
          v-model="coupon_collection_expiration"
        >
        </DatePicker>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { ecouponService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
      coupon_collection_expiration: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    async isFormValid() {
      const { start_date, end_date } = this.campaign;

      const coupon_collection_expiration = this.coupon_collection_expiration;

      if (
        start_date &&
        coupon_collection_expiration &&
        new Date(coupon_collection_expiration) < new Date(start_date)
      ) {
        await this.$dialogs.alert(
          "Coupon collect expiration ต้องไม่น้อยกว่า campaign start date"
        );

        this.$refs.coupon_collection_expiration.focus();
        this.coupon_collection_expiration = this.oldValue.coupon_collection_expiration;
        return false;
      }

      if (
        end_date &&
        coupon_collection_expiration &&
        new Date(coupon_collection_expiration) > new Date(end_date)
      ) {
        await this.$dialogs.alert(
          "Coupon collect expiration ต้องไม่เกิน campaign end date"
        );

        this.$refs.coupon_collection_expiration.focus();
        this.coupon_collection_expiration = this.oldValue.coupon_collection_expiration;
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const coupon_collection_expiration = this.coupon_collection_expiration;
        const body = {
          coupon_collection_expiration,
        };

        await ecouponService.updateCampaign(this.campaign.id, body);

        this.oldValue.coupon_collection_expiration = this.coupon_collection_expiration;

        this.$emit("updated");
        this.$notify(`Coupon Expiration updated.`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Coupon Expiration update error.`);
      }
    },
  },

  mounted() {
    this.oldValue = Object.assign({}, this.campaign);
    this.coupon_collection_expiration = this.campaign.coupon_collection_expiration;
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page-main" }, [
        _c("div", { staticClass: "page-header" }, [
          _c(
            "div",
            {
              staticClass:
                "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
            },
            [
              _c("h1", { staticClass: "page-title" }, [_vm._v("Product")]),
              _c("breadcrumb", {
                attrs: { name: "PRODUCT_EDIT", withLast: _vm.product.name },
                on: {
                  "update:withLast": function ($event) {
                    return _vm.$set(_vm.product, "name", $event)
                  },
                  "update:with-last": function ($event) {
                    return _vm.$set(_vm.product, "name", $event)
                  },
                },
              }),
              _c("div", { staticClass: "page-header-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-round btn-default mx-10",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-chevron-left",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Back "),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "page-content container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
              },
              [
                _c("div", { staticClass: "panel panel-bordered" }, [
                  _c("div", { staticClass: "panel-heading" }, [
                    _c("h3", { staticClass: "panel-title" }, [
                      _vm._v(
                        " Product Information - " + _vm._s(_vm.product.id) + " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "panel-actions panel-actions-keep" },
                      [
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary btn-round dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toUpperCase")(_vm.product.status)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { role: "menu" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class:
                                    _vm.product.status == "draft" ||
                                    _vm.product.status == "published"
                                      ? "disabled"
                                      : "",
                                  attrs: {
                                    href: "javascript:void(0)",
                                    role: "menuitem",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setStatus("draft")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon md-border-color",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v("Draft "),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class:
                                    _vm.product.status != "draft"
                                      ? "disabled"
                                      : "",
                                  attrs: {
                                    href: "javascript:void(0)",
                                    role: "menuitem",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setStatus("published")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon md-long-arrow-up",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v("Publish "),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class:
                                    _vm.product.status == "draft" ||
                                    _vm.product.status == "archived" ||
                                    _vm.product.status == "unpublished"
                                      ? "disabled"
                                      : "",
                                  attrs: {
                                    href: "javascript:void(0)",
                                    role: "menuitem",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setStatus("draft")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon md-long-arrow-down",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v("Unpublish "),
                                ]
                              ),
                              _c("div", { staticClass: "dropdown-divider" }),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class:
                                    _vm.product.status == "archived" ||
                                    _vm.product.status == "published"
                                      ? "disabled"
                                      : "",
                                  attrs: {
                                    href: "javascript:void(0)",
                                    role: "menuitem",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setStatus("archived")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon md-archive",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v("Archive "),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "panel-body" },
                    [
                      _c("div", { staticClass: "form-horizontal" }, [
                        _c("div", { staticClass: "form-group row mb-50" }, [
                          _c(
                            "label",
                            { staticClass: "col-md-3 form-control-label" },
                            [_vm._v("Product Name")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-9" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product.name,
                                    expression: "product.name",
                                  },
                                ],
                                ref: "name",
                                staticClass: "form-control",
                                attrs: { type: "text", autocomplete: "off" },
                                domProps: { value: _vm.product.name },
                                on: {
                                  change: function ($event) {
                                    return _vm.submit("name")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.product,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("NameValidation", { attrs: { $v: _vm.$v } }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-md-3 form-control-label" },
                            [_vm._v("Product Description")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-9" },
                            [
                              _c("ckeditor", {
                                attrs: { config: _vm.editorConfig },
                                on: {
                                  input: function ($event) {
                                    return _vm.submit("description")
                                  },
                                },
                                model: {
                                  value: _vm.product.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.product, "description", $$v)
                                  },
                                  expression: "product.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3 form-control-label" },
                            [_vm._v("Product Image")]
                          ),
                          _c("div", { staticClass: "col-md-9" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.images, function (n) {
                                return _c(
                                  "div",
                                  { key: n, staticClass: "col-6" },
                                  [
                                    _vm.product["image" + n]
                                      ? _c("div", [
                                          _vm.product["image" + n]
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-control-label",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Image " + _vm._s(n)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "figure",
                                            {
                                              staticClass:
                                                "overlay overlay-hover img-rounded img-bordered",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "overlay-figure product-image",
                                                attrs: {
                                                  src: _vm.product["image" + n],
                                                },
                                              }),
                                              _c(
                                                "figcaption",
                                                {
                                                  staticClass:
                                                    "overlay-panel overlay-background overlay-fade overlay-icon",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-outline btn-inverse vertical-align-middle",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeImage(
                                                            "image" + n
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Change Image ")]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-outline btn-inverse vertical-align-middle mx-10",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.confirmRemove(
                                                            "image" + n
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Remove Image ")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("hr"),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _vm.product.image4 == null
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c("label", {
                                staticClass: "col-md-3 form-control-label",
                              }),
                              _c("div", { staticClass: "col-md-9" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-round btn-default",
                                    attrs: { type: "button" },
                                    on: { click: _vm.openUploadModal },
                                  },
                                  [_vm._v(" Add image ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("hr", { staticClass: "mb-30" }),
                      _c("ProductType", { attrs: { product: _vm.product } }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "modal",
        { attrs: { name: "upload-product-modal", width: 500, height: "auto" } },
        [
          _c("BaseCropUpload", {
            on: { onCrop: _vm.onCrop, close: _vm.closeUploadModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
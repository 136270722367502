var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          {
            staticClass:
              "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
          },
          [
            _c("h1", { staticClass: "page-title" }, [_vm._v("Point Campaign")]),
            _c("breadcrumb", {
              attrs: {
                name: "POINT_CAMPAIGN_EDIT",
                withLast: _vm.campaign.name,
              },
              on: {
                "update:withLast": function ($event) {
                  return _vm.$set(_vm.campaign, "name", $event)
                },
                "update:with-last": function ($event) {
                  return _vm.$set(_vm.campaign, "name", $event)
                },
              },
            }),
            _c("div", { staticClass: "page-header-actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-round btn-default mx-10",
                  attrs: { type: "button" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("i", {
                    staticClass: "icon md-chevron-left",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Back "),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "page-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
            },
            [
              _c("div", { staticClass: "panel panel-bordered" }, [
                _c("div", { staticClass: "panel-heading" }, [
                  _c("h3", { staticClass: "panel-title" }, [
                    _vm._v(
                      " Point Campaign Information - " +
                        _vm._s(_vm.campaign.id) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "panel-actions panel-actions-keep" },
                    [
                      _vm._v(
                        " User Group: " + _vm._s(_vm.campaign.group_code) + " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "panel-body" }, [
                  _c("div", { staticClass: "form-horizontal" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _vm._m(0),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.name,
                                expression: "campaign.name",
                              },
                            ],
                            ref: "campaignName",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              maxlength: "255",
                              disabled: !_vm.isDraft,
                            },
                            domProps: { value: _vm.campaign.name },
                            on: {
                              change: function ($event) {
                                return _vm.submit("name")
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.campaign,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("nameValidation", { attrs: { $v: _vm.$v } }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "px-15" },
                          [
                            _c("StatusButton", {
                              attrs: { status: _vm.campaign.status },
                              on: {
                                change: _vm.setStatus,
                                "update:status": function ($event) {
                                  return _vm.$set(
                                    _vm.campaign,
                                    "status",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-10" }, [
                        _c("div", { staticClass: "widget-url-input" }, [
                          _c("span", [_vm._v("Widget URL")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.widget_url,
                                expression: "campaign.widget_url",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", maxlength: "255" },
                            domProps: { value: _vm.campaign.widget_url },
                            on: {
                              change: function ($event) {
                                return _vm.submit("widget_url")
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.campaign,
                                  "widget_url",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("hr"),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("h5", { staticClass: "col-md-12" }, [
                          _vm._v("Point Campaign Duration"),
                        ]),
                        _c(
                          "label",
                          { staticClass: "col-md-1 form-control-label" },
                          [_vm._v("Start")]
                        ),
                        _c("DatePicker", {
                          ref: "startDate",
                          staticClass: "col-md-3 px-0",
                          attrs: {
                            type: "datetime",
                            confirm: true,
                            format: "DD/MM/YYYY HH:mm",
                            "value-type": "YYYY-MM-DD HH:mm",
                            disabled: !_vm.isDraft,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.submit("start_date")
                            },
                          },
                          model: {
                            value: _vm.campaign.start_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.campaign, "start_date", $$v)
                            },
                            expression: "campaign.start_date",
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "col-md-1 form-control-label" },
                          [_vm._v("End")]
                        ),
                        _c("DatePicker", {
                          ref: "endDate",
                          staticClass: "col-md-3 px-0",
                          attrs: {
                            type: "datetime",
                            confirm: true,
                            format: "DD/MM/YYYY HH:mm",
                            "value-type": "YYYY-MM-DD HH:mm",
                            placeholder: "n/a",
                            disabled: !(_vm.isDraft || _vm.isPaused),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.submit("end_date")
                            },
                          },
                          model: {
                            value: _vm.campaign.end_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.campaign, "end_date", $$v)
                            },
                            expression: "campaign.end_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "row" }, [
                      _c("h5", { staticClass: "col-md-12" }, [
                        _vm._v("Add Coins"),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "point-to-coin-inputs" }, [
                          _vm._v(" Every "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.conv_point,
                                expression: "campaign.conv_point",
                              },
                            ],
                            ref: "convPoint",
                            staticClass: "form-control",
                            attrs: {
                              min: "1",
                              max: "999",
                              type: "number",
                              maxlength: "3",
                              disabled: !(
                                _vm.isDraft ||
                                _vm.isPaused ||
                                _vm.isScheduled
                              ),
                            },
                            domProps: { value: _vm.campaign.conv_point },
                            on: {
                              change: function ($event) {
                                return _vm.submit("conv_point")
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.campaign,
                                  "conv_point",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" SF points convert to "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.conv_coin,
                                expression: "campaign.conv_coin",
                              },
                            ],
                            ref: "convCoin",
                            staticClass: "form-control",
                            attrs: {
                              min: "1",
                              max: "999",
                              type: "number",
                              maxlength: "3",
                              disabled: !(
                                _vm.isDraft ||
                                _vm.isPaused ||
                                _vm.isScheduled
                              ),
                            },
                            domProps: { value: _vm.campaign.conv_coin },
                            on: {
                              change: function ($event) {
                                return _vm.submit("conv_coin")
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.campaign,
                                  "conv_coin",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" coins. "),
                        ]),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "row" }, [
                      _c("h5", { staticClass: "col-md-12" }, [
                        _vm._v("Discount"),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c("table", [
                          _c("tr", [
                            _c("td", [_vm._v(" Scan QR ")]),
                            _c("td", [
                              _c("label", { staticClass: "switch" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.campaign.can_scanqr,
                                      expression: "campaign.can_scanqr",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    disabled: !(
                                      _vm.isDraft ||
                                      _vm.isPaused ||
                                      _vm.isScheduled
                                    ),
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.campaign.can_scanqr
                                    )
                                      ? _vm._i(_vm.campaign.can_scanqr, null) >
                                        -1
                                      : _vm.campaign.can_scanqr,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.campaign.can_scanqr,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.campaign,
                                                "can_scanqr",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.campaign,
                                                "can_scanqr",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.campaign,
                                            "can_scanqr",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.submit("can_scanqr")
                                      },
                                    ],
                                  },
                                }),
                                _c("span", { staticClass: "slider round" }),
                              ]),
                              _c("p", { staticClass: "mx-10 mr-20 d-inline" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.campaign.can_scanqr ? "ON" : "OFF"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("td", [
                              _vm._v(" Markup percentage "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.markerup_percent,
                                    expression: "campaign.markerup_percent",
                                  },
                                ],
                                ref: "markerupPercent",
                                staticClass: "form-control markup-percentage",
                                attrs: {
                                  min: "0.0",
                                  max: "1000",
                                  step: "any",
                                  type: "number",
                                  disabled:
                                    !_vm.canScanQR ||
                                    !(
                                      _vm.isDraft ||
                                      _vm.isPaused ||
                                      _vm.isScheduled
                                    ),
                                },
                                domProps: {
                                  value: _vm.campaign.markerup_percent,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.submit("markerup_percent")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.campaign,
                                      "markerup_percent",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm._v(" Every "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.discount_coin,
                                    expression: "campaign.discount_coin",
                                  },
                                ],
                                ref: "discountCoin",
                                staticClass: "form-control",
                                attrs: {
                                  min: "1",
                                  max: "999",
                                  type: "number",
                                  maxlength: "3",
                                  disabled: !(
                                    _vm.isDraft ||
                                    _vm.isPaused ||
                                    _vm.isScheduled
                                  ),
                                },
                                domProps: { value: _vm.campaign.discount_coin },
                                on: {
                                  change: function ($event) {
                                    return _vm.submit("discount_coin")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.campaign,
                                      "discount_coin",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" coins get discount "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.discount_baht,
                                    expression: "campaign.discount_baht",
                                  },
                                ],
                                ref: "discountBaht",
                                staticClass: "form-control",
                                attrs: {
                                  min: "1",
                                  max: "999",
                                  type: "number",
                                  maxlength: "3",
                                  disabled: !(
                                    _vm.isDraft ||
                                    _vm.isPaused ||
                                    _vm.isScheduled
                                  ),
                                },
                                domProps: { value: _vm.campaign.discount_baht },
                                on: {
                                  change: function ($event) {
                                    return _vm.submit("discount_baht")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.campaign,
                                      "discount_baht",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" baht. "),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "get-discount-employee-discount-table",
                                },
                                [
                                  _c("tr", [
                                    _c("td", [_vm._v(" Get discount ")]),
                                    _c("td", [
                                      _c("label", { staticClass: "switch" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.campaign.get_discount,
                                              expression:
                                                "campaign.get_discount",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            disabled: !(
                                              _vm.isDraft ||
                                              _vm.isPaused ||
                                              _vm.isScheduled
                                            ),
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.campaign.get_discount
                                            )
                                              ? _vm._i(
                                                  _vm.campaign.get_discount,
                                                  null
                                                ) > -1
                                              : _vm.campaign.get_discount,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.campaign.get_discount,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "get_discount",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "get_discount",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "get_discount",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.submit(
                                                  "get_discount"
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "slider round",
                                        }),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "mx-10 mr-20 d-inline" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.campaign.get_discount
                                                  ? "ON"
                                                  : "OFF"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [_vm._v(" Employee Discount ")]),
                                    _c("td", [
                                      _c("label", { staticClass: "switch" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.campaign.can_salesdiscount,
                                              expression:
                                                "campaign.can_salesdiscount",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            "true-value": 1,
                                            "false-value": 0,
                                            disabled: !(
                                              _vm.isDraft ||
                                              _vm.isPaused ||
                                              _vm.isScheduled
                                            ),
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.campaign.can_salesdiscount
                                            )
                                              ? _vm._i(
                                                  _vm.campaign
                                                    .can_salesdiscount,
                                                  null
                                                ) > -1
                                              : _vm._q(
                                                  _vm.campaign
                                                    .can_salesdiscount,
                                                  1
                                                ),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.campaign
                                                      .can_salesdiscount,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? 1 : 0
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "can_salesdiscount",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "can_salesdiscount",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "can_salesdiscount",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.submit(
                                                  "can_salesdiscount"
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "slider round",
                                        }),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "mx-10 mr-20 d-inline" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.campaign.can_salesdiscount
                                                  ? "ON"
                                                  : "OFF"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm.canGetDiscount || _vm.canEmployeeDiscount
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "3" } },
                                  [
                                    _c("SKUDiscountSettings", {
                                      attrs: { item: _vm.campaign },
                                      on: { updated: _vm.fetchCampaign },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "3" } },
                              [
                                _c("p", { staticClass: "mt-15 mb-10" }, [
                                  _vm._v("Customized text area"),
                                ]),
                                _c("ckeditor", {
                                  attrs: { config: _vm.defaultEditorConfig },
                                  on: {
                                    input: function ($event) {
                                      return _vm.submit("description")
                                    },
                                  },
                                  model: {
                                    value: _vm.campaign.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.campaign, "description", $$v)
                                    },
                                    expression: "campaign.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._m(2),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Point Campaign Name")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [_vm._v("Status")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
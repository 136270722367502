var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-6" }, [
    _c("div", { staticClass: "form-group", attrs: { "my-15px": "" } }, [
      _vm._m(0),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "image-preview" }, [
          _vm.picture
            ? _c("img", {
                staticClass: "img img-fluid",
                attrs: { src: _vm.picture },
              })
            : _c("div", { staticClass: "image-placeholder" }),
        ]),
        _c("input", {
          ref: "icon_earned",
          staticClass: "form-control",
          attrs: {
            type: "file",
            accept: "image/*",
            name: "icon_earned",
            disabled: _vm.disabled,
          },
          on: { change: _vm.uploadImage },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [
        _vm._v(" Icon Earned "),
        _c("span", { staticClass: "small light" }, [
          _vm._v(" (Recommended size 108 x 108 pixels) "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
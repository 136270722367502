<template>
  <form class="form-horizontal" v-if="campaign">
    <div class="row">
      <div class="form-group col-md-6">
        <CampaignName :campaign="campaign" />
      </div>
      <div class="form-group col-md-3">
        <StatusDropdown :campaign="campaign" @updated="$emit('updated')" />
      </div>
    </div>
    <hr />

    <div class="row">
      <CampaignDuration :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <BackgroundImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <TargetAndRewardValues :campaign="campaign" @updated="$emit('updated')" />
    </div>
  </form>
</template>

<script>
import StatusDropdown from "./components/StatusDropdown.vue";
import BackgroundImage from "./components/BackgroundImage.vue";
import CampaignDuration from "./components/CampaignDuration.vue";
import CampaignName from "@/views/progressbar/components/CampaignName.vue";
import TargetAndRewardValues from "./components/TargetAndRewardValues.vue";

export default {
  name: "TaskForm",

  props: ["campaign"],

  components: {
    CampaignName,
    StatusDropdown,
    BackgroundImage,
    CampaignDuration,
    TargetAndRewardValues,
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reward-record panel panel-bordered" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "panel-title" }, [
        _vm._v(" Reward "),
        _c("span", { staticClass: "light" }, [
          _vm._v(" " + _vm._s(_vm.reward.reward_name) + " "),
        ]),
      ]),
      _c("div", { staticClass: "panel-actions panel-actions-keep" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-link",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.show = !_vm.show
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.show ? "hide [ - ]" : "show [ + ]") + " ")]
        ),
      ]),
    ]),
    _vm.show
      ? _c(
          "div",
          {
            staticClass: "panel-body",
            staticStyle: { "padding-bottom": "0px" },
          },
          [
            _c("div", { staticClass: "reward-content" }, [
              _c("div", { staticClass: "no" }, [
                _vm._v("No." + _vm._s(_vm.reward.id)),
              ]),
              _c("div", { staticClass: "detail container" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-6 col-md-12" },
                    [
                      _c("RewardNo", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            stamp: _vm.campaign.stamp,
                            status: _vm.campaign.status,
                          },
                        },
                      }),
                      _c("RewardName", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                          },
                        },
                      }),
                      _c("RewardDetail", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-6 col-md-12" },
                    [
                      _c("RewardPrizePool", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          prizepool: _vm.prizepool,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                            user_type: _vm.campaign.user_type,
                          },
                        },
                      }),
                      _c("RewardPoint", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                            user_type: _vm.campaign.user_type,
                          },
                        },
                      }),
                      _c("RewardECoupon", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                            user_type: _vm.campaign.user_type,
                          },
                        },
                      }),
                      _c("RewardECouponCustomText", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                            user_type: _vm.campaign.user_type,
                          },
                        },
                      }),
                      _c("RewardECouponMinimumPurchase", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                            user_type: _vm.campaign.user_type,
                          },
                        },
                      }),
                      _c("RewardExpiration", {
                        attrs: {
                          index: _vm.index,
                          reward: _vm.reward,
                          campaign: {
                            id: _vm.campaign.id,
                            status: _vm.campaign.status,
                            user_type: _vm.campaign.user_type,
                          },
                        },
                        on: {
                          updated: function ($event) {
                            return _vm.$emit("updated")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("RewardIconNotEarned", {
                      attrs: {
                        index: _vm.index,
                        reward: _vm.reward,
                        campaign: {
                          id: _vm.campaign.id,
                          status: _vm.campaign.status,
                        },
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.$emit("updated")
                        },
                      },
                    }),
                    _c("RewardIconEarned", {
                      attrs: {
                        index: _vm.index,
                        reward: _vm.reward,
                        campaign: {
                          id: _vm.campaign.id,
                          status: _vm.campaign.status,
                        },
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.$emit("updated")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("RewardPicture", {
                      attrs: {
                        index: _vm.index,
                        reward: _vm.reward,
                        campaign: {
                          id: _vm.campaign.id,
                          status: _vm.campaign.status,
                        },
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.$emit("updated")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "action" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.disabled },
                    on: { click: _vm.showConfirmDelete },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-delete",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
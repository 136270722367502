<template>
  <div class="page">
    <div class="page-main">
      <div class="page-header">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <h1 class="page-title">History</h1>
          <breadcrumb
            name="HISTORY_VIEW"
            :withLast.sync="history.lot_name"
          ></breadcrumb>
          <div class="page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">Summary</h3>
              </div>
              <div class="panel-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label>
                      <h5>Lot id</h5>
                    </label>
                    <br />
                    <label>
                      <h5>{{ history.lot_id }}</h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5>Lot Name</h5>
                    </label>
                    <br />
                    <label>
                      <h5>{{ history.lot_name }}</h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5>Start</h5>
                    </label>
                    <br />
                    <label>
                      <h5>{{ history.start_date }}</h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5>No. Winners</h5>
                    </label>
                    <br />
                    <label>
                      <h5>{{ history.num_winner }}</h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5>Lot Type</h5>
                    </label>
                    <br />
                    <label>
                      <h5 v-if="history.type == 'auction'">
                        {{ history.type | toUpperCase }}
                      </h5>
                      <h5 v-else-if="history.type == 'luckydraw'">
                        {{ history.type | capitalize }}
                      </h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5>Product Name</h5>
                    </label>
                    <br />
                    <label>
                      <h5 class="limit-line-2">{{ history.product_name }}</h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5>End</h5>
                    </label>
                    <br />
                    <label>
                      <h5>{{ history.end_date }}</h5>
                    </label>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <h5 v-if="history.type == 'auction'">
                        Starting/Winning bid
                      </h5>
                      <h5 v-else-if="history.type == 'luckydraw'">
                        Points per entry
                      </h5>
                    </label>
                    <br />
                    <label>
                      <h5 v-if="history.type == 'auction'">
                        {{ history.minimum_points }}/{{ history.winning_bid }}
                      </h5>
                      <h5 v-else-if="history.type == 'luckydraw'">
                        {{ history.minimum_points }}/{{ history.winning_bid }}
                      </h5>
                    </label>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <h3 class="panel-title">Activity Log</h3>
                <div class="Excel">
                  <button class="btn btn-default" @click="exportToExcel">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
                <div class="page-content page-content-table">
                  <div class="container-fluid">
                    <div class="panel">
                      <div class="panel-body p-0">
                        <vue-good-table
                          :columns="columns"
                          :rows="history.activity_log || []"
                          :pagination-options="{
                            enabled: true,
                            mode: 'records',
                          }"
                        ></vue-good-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lotService, historyService } from "@/api/client";

import {
  activity_log_auction_TableColumns,
  activity_log_luckydraw_TableColumns,
} from "@/views/history/model";

export default {
  name: "HistoryView",

  data() {
    return {
      lot: [],
      status: "",
      lotService,
      history: [],
      historyService,
    };
  },
  computed: {
    columns() {
      return this.history.type == "auction"
        ? activity_log_auction_TableColumns
        : activity_log_luckydraw_TableColumns;
    },
  },

  async mounted() {
    await this.fetchHistory();
  },

  methods: {
    async fetchHistory() {
      const id = this.$route.params.id;
      this.history = await historyService.getById(id);
    },

    async exportToExcel() {
      try {
        const id = this.$route.params.id;
        const response = await historyService.download(id);

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "Export table.xlsx";
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.Excel {
  margin-bottom: 35px;
  margin-left: 20px;
}

/* https://stackoverflow.com/a/13924997 */
.limit-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>

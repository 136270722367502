import qs from "qs";
import HttpRequest from "./request";

export class ECouponProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_WALLET_API_URL}/ecp/api`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/campaign${queryString}`);
    return data;
  }

  async createCampaign(body) {
    const { data } = await this.create(`/campaign`, body);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/campaign/${id}`);
    return data;
  }

  async updateCampaign(campaignId, body) {
    const { data } = await this.update(`/campaign/${campaignId}`, body);
    return data;
  }

  async getTransactionLog(campaignId) {
    const { data } = await this.get(`/campaign/transaction-log/${campaignId}`);
    return data;
  }

  async uploadCampaign(body) {
    const { data } = await this.upload(`/campaign/upload`, body);
    return data;
  }

  async confrimUploadCampaign(task_id) {
    const { data } = await this.get(`/campaign/execute/${task_id}`);
    return data;
  }

  async uploadImageCampaign(form) {
    const { data } = await this.upload(`/campaign/upload_img`, form);
    return data;
  }

  async getGroupType() {
    const { data } = await this.get(`/campaign/getGroupType`);
    return data;
  }

  async getGroupActive() {
    const { data } = await this.get(`/campaign/getActiveGroup`);
    return data;
  }

  async addGroupGT(group_id) {
    const { data } = await this.create(`/campaign/gtGroup/${group_id}`);
    return data;
  }

  async deleteGroupGT(group_id) {
    const { data } = await this.delete(`/campaign/gtGroup/${group_id}`);
    return data;
  }

  async addGroupWS(group_id) {
    const { data } = await this.create(`/campaign/wsGroup/${group_id}`);
    return data;
  }

  async deleteGroupWS(group_id) {
    const { data } = await this.delete(`/campaign/wsGroup/${group_id}`);
    return data;
  }

  async getWidget() {
    const { data } = await this.get(`/campaign/widget`);
    return data;
  }
  async updateWidget(body) {
    const { data } = await this.update(`/campaign/updatewidget`, body);
    return data;
  }
  //VSM
  async getVSM(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/campaign/vsm/management${queryString}`);
    return data;
  }
  async getVSMUsername(keyWord) {
    if (!keyWord) {
      keyWord = "All";
    }
    const { data } = await this.get(
      `/campaign/vsm_user/autocomplete/${keyWord}`
    );
    return data;
  }
  async getVSMISMSode(keyWord) {
    if (!keyWord) {
      keyWord = "All";
    }
    const { data } = await this.get(
      `/campaign/ecp_user/autocomplete/${keyWord}`
    );
    return data;
  }
  async uploadBulkVSM(form) {
    const { data } = await this.upload(`/campaign/heirarchy/upload`, form);
    return data;
  }
  async uploadStatus() {
    const { data } = await this.get(`/campaign/heirarchy/status`);
    return data;
  }
  async uploadBulkConfrim(bool) {
    const { data } = await this.get(
      `/campaign/heirarchy/execute?confirm=${bool}`
    );
    return data;
  }
  async downloadVSM(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/campaign/vsm/management/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async getCCB2BLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/ccb2b/ccb2b_taskreport${queryString}`);
    return data;
  }

  async getCCB2BLogDetail(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(
      `/ccb2b/ccb2b_jobreport/${id}${queryString}`
    );
    return data;
  }

  async ccb2bLogRetry(id) {
    const { data } = await this.get(`/ccb2b/ccb2b_retry/${id}`);
    return data;
  }

  async ccb2bLogCancel(id) {
    const { data } = await this.get(`/ccb2b/ccb2b_cancel/${id}`);
    return data;
  }

  async ccb2bLogRefresh(id) {
    const { data } = await this.get(`/ccb2b/ccb2b_request/${id}`);
    return data;
  }

  async ccb2bLogExport(id) {
    return await this.axiosInstance.get(
      `/ccb2b/ccb2b_jobreport/download/${id}`,
      {
        responseType: "blob",
      }
    );
  }

  // Peddler Management
  async searchPeddler(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/peddler/management${queryString}`);
    return data;
  }

  async getPeddlerInfo(id) {
    const { data } = await this.get(`/peddler/detail/${id}`);
    return data;
  }

  async updatePeddlerInfo(id, form) {
    const { data } = await this.update(`/peddler/update/${id}`, form);
    return data;
  }

  async generatePeddlerPassword() {
    const { data } = await this.get(`/peddler/genpassword`);
    return data;
  }

  async downloadPeddler(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/peddler/management/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async bulkAddPeddler(form) {
    const { data } = await this.upload(`/peddler/upload`, form);
    return data;
  }

  async checkPeddlerBulkAddStatus() {
    const { data } = await this.get(`/peddler/upload_status`);
    return data;
  }

  async executePeddlerBulkAdd(confirm) {
    const { data } = await this.get(
      `/peddler/execute?confirm=${confirm === true ? "y" : "n"}`
    );
    return data;
  }

  async fetchPeddlerActivity(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/peddler/activitiy${queryString}`);
    return data;
  }

  async downloadPeddlerActivity(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/peddler/activitiy/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async getDWZipFolderList() {
    return await this.get(`/ecpcampaignreport/DWzipfolderList`);
  }

  downloadDWgetzipfile(filename) {
    const token = localStorage.getItem("accessToken");
    
    window.open(
      `${process.env.VUE_APP_WALLET_API_URL}/ecp/api/ecpcampaignreport/DWgetzipfile/${filename}?token=${token}`
    );
  }
}

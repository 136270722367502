<template>
  <div class="form-group">
    <label class="mx-5">
      <h5>Name</h5>
    </label>
    <input
      type="text"
      maxlength="60"
      @change="update"
      name="prize_name"
      class="form-control"
      :disabled="disabled"
      v-model="pool.prize_name"
    />
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: `Update Prize name error.`,
        success: `Update Prize name success.`,
      },
    };
  },
};
</script>

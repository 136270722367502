import { render, staticRenderFns } from "./LobbyBanner.vue?vue&type=template&id=147f5e02&scoped=true&"
import script from "./LobbyBanner.vue?vue&type=script&lang=js&"
export * from "./LobbyBanner.vue?vue&type=script&lang=js&"
import style0 from "./LobbyBanner.vue?vue&type=style&index=0&id=147f5e02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147f5e02",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('147f5e02')) {
      api.createRecord('147f5e02', component.options)
    } else {
      api.reload('147f5e02', component.options)
    }
    module.hot.accept("./LobbyBanner.vue?vue&type=template&id=147f5e02&scoped=true&", function () {
      api.rerender('147f5e02', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff-engagement/components/LobbyBanner.vue"
export default component.exports
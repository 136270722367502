var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "btn-group" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-round dropdown-toggle",
        staticStyle: { "text-transform": "uppercase" },
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_vm._v(" " + _vm._s(_vm.status) + " ")]
    ),
    _c("div", { staticClass: "dropdown-menu", attrs: { role: "menu" } }, [
      _vm.status == "draft"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "scheduled")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-long-arrow-up",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Publish "),
            ]
          )
        : _vm._e(),
      _vm.status == "draft"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "archived")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-archive",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Archive "),
            ]
          )
        : _vm._e(),
      _vm.status == "archived"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "draft")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-long-arrow-up",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Unarchive "),
            ]
          )
        : _vm._e(),
      _vm.status == "scheduled"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "draft")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-long-arrow-down",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Unpublish "),
            ]
          )
        : _vm._e(),
      _vm.status == "ongoing"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "paused")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-block",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Pause "),
            ]
          )
        : _vm._e(),
      _vm.status == "ongoing"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "end")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-archive",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Stop "),
            ]
          )
        : _vm._e(),
      _vm.status == "paused"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "ongoing")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-refresh",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Restart "),
            ]
          )
        : _vm._e(),
      _vm.status == "paused"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", "end")
                },
              },
            },
            [
              _c("i", {
                staticClass: "icon md-archive",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Stop "),
            ]
          )
        : _vm._e(),
      _vm.status == "aborted"
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:void(0)", role: "menuitem" },
            },
            [
              _c("i", {
                staticClass: "icon md-archive",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Nothing "),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
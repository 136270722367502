import qs from "qs";
import HttpRequest from "./request";

export class ProgressBarProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/progressbar`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async updateCampaignInfo(campaignId, body) {
    const { data } = await this.update(`/${campaignId}`, body);
    return data;
  }

  async createNewCampaign(body) {
    const { data } = await this.create("", body);
    return data;
  }

  // Start SKU Api
  async getSKUList() {
    const { data } = await this.get(`/sku`);
    return data;
  }

  async getSKUGroupList() {
    const { data } = await this.get(`/sku_group`);
    return data;
  }

  async updateSKU(campaignId, type, form) {
    const { data } = await this.update(`/${campaignId}/${type}/sku`, form);
    return data;
  }

  async tryUpdateSKU(campaignId, type, form) {
    const { data } = await this.update(`/${campaignId}/${type}/try_sku`, form);
    return data;
  }

  async tryUpdateSKUResult(campaignId, type) {
    const { data } = await this.update(`/${campaignId}/${type}/try_sku_result`);
    return data;
  }

  async tryConfirmUpdateSKU(campaignId, type) {
    const { data } = await this.update(
      `/${campaignId}/${type}/try_sku_confirm`
    );
    return data;
  }

  async tryCancelUpdateSKU(campaignId, type) {
    const { data } = await this.update(`/${campaignId}/${type}/try_sku_cancel`);
    return data;
  }
  // End SKU Api

  async fileUpload(campaignId, type, form) {
    const { data } = await this.upload(`/${campaignId}/${type}/upload`, form);
    return data;
  }

  // Barcode - Target and Rewards
  async getTargetRewardList(campaignId, type) {
    const { data } = await this.get(`/${campaignId}/${type}/target`);
    return data;
  }

  async addTargetReward(campaignId, type) {
    const { data } = await this.create(`/${campaignId}/${type}/target`);
    return data;
  }

  async uploadTargetImage(campaignId, type, targetId, form) {
    const { data } = await this.upload(
      `/${campaignId}/${type}/target/${targetId}/upload`,
      form
    );
    return data;
  }

  async deleteTargetReward(campaignId, type, targetId) {
    const { data } = await this.delete(
      `/${campaignId}/${type}/target/${targetId}`
    );
    return data;
  }

  async uploadUserTargetReward(campaignId, type, form) {
    const { data } = await this.upload(
      `/${campaignId}/${type}/user_target_reward/upload`,
      form
    );
    return data;
  }

  async tryUploadUserTargetReward(campaignId, type, form) {
    const { data } = await this.upload(
      `/${campaignId}/${type}/user_target_reward/try_upload`,
      form
    );
    return data;
  }

  async getProgressUpdateDetail(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/progress/${id}/table${queryString}`);
    return data;
  }

  async uploadRankAndReward(campaignId, form) {
    const { data } = await this.upload(
      `/${campaignId}/leaderboard/rank_reward/upload`,
      form
    );
    return data;
  }

  async getColumnConfig(campaignId) {
    const { data } = await this.get(`/progress/${campaignId}/column`);
    return data;
  }

  async tryUploadProgress(campaignId, form) {
    const { data } = await this.upload(
      `/progress/${campaignId}/try_upload`,
      form
    );
    return data;
  }

  async tryUploadProgressResult(campaignId, form) {
    const { data } = await this.create(
      `/progress/${campaignId}/try_upload_result`
    );
    return data;
  }

  async tryConfirmUploadProgress(campaignId, form) {
    const { data } = await this.create(
      `/progress/${campaignId}/try_upload_confirm`
    );
    return data;
  }

  async tryCancelUploadProgress(campaignId, form) {
    const { data } = await this.create(
      `/progress/${campaignId}/try_upload_cancel`
    );
    return data;
  }

  async downloadProgressUpdate(campaignId) {
    return await this.axiosInstance.get(`/progress/${campaignId}/download`, {
      responseType: "blob",
    });
  }

  async uploadProgressUpdate(campaignId, form) {
    const { data } = await this.upload(`/progress/${campaignId}/upload`, form);
    return data;
  }

  async getProgressUpdateInfo(campaignId) {
    const { data } = await this.get(`/progress/${campaignId}/info`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/transaction_log/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/transaction_log${queryString}`);
    return data;
  }

  async getUploadStatus(campaignId) {
    const { data } = await this.get(`/${campaignId}/upload_status`);
    return data;
  }

  async tryUploadResultUserTargetReward(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/user_target_reward/try_upload_result`
    );
    return data;
  }

  async tryUploadCancelUserTargetReward(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/user_target_reward/try_upload_cancel`
    );
    return data;
  }

  async tryUploadConfirmUserTargetReward(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/user_target_reward/try_upload_confirm`
    );
    return data;
  }

  // Team progressbar api
  async uploadTeamTargetPicture(campaignId, form) {
    const { data } = await this.upload(`/${campaignId}/team/upload`, form);
    return data;
  }

  // MonthlyShort
  async getWSSKURYOList() {
    const { data } = await this.get(`/ws_sku/ryo`);
    return data;
  }

  async getWSSKUNonRYOList() {
    const { data } = await this.get(`/ws_sku/non_ryo`);
    return data;
  }

  async updateWSSKU(campaignId, type, form, skuType) {
    const { data } = await this.update(
      `/${campaignId}/${type}/sku/${skuType}`,
      form
    );
    return data;
  }

  async tryUpdateWSSKU(campaignId, type, form, skuType) {
    const { data } = await this.update(
      `/${campaignId}/${type}/try_sku/${skuType}`,
      form
    );
    return data;
  }

  async tryUpdateWSSKUResult(campaignId, type, skuType) {
    const { data } = await this.update(
      `/${campaignId}/${type}/try_sku_result/${skuType}`
    );
    return data;
  }

  async tryConfirmUpdateWSSKU(campaignId, type, skuType) {
    const { data } = await this.update(
      `/${campaignId}/${type}/try_sku_confirm/${skuType}`
    );
    return data;
  }

  async tryCancelUpdateWSSKU(campaignId, type, skuType) {
    const { data } = await this.update(
      `/${campaignId}/${type}/try_sku_cancel/${skuType}`
    );
    return data;
  }

  async uploadAudience(campaignId, type, form) {
    const { data } = await this.upload(
      `/${campaignId}/${type}/audience/upload`,
      form
    );
    return data;
  }

  async tryUploadAudience(campaignId, type, form) {
    const { data } = await this.upload(
      `/${campaignId}/${type}/audience/try_upload`,
      form
    );
    return data;
  }

  async tryUploadResultAudience(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/audience/try_upload_result`
    );
    return data;
  }

  async tryUploadCancelAudience(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/audience/try_upload_cancel`
    );
    return data;
  }

  async tryUploadConfirmAudience(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/audience/try_upload_confirm`
    );
    return data;
  }

  async createReward(campaignId, type) {
    const { data } = await this.axiosInstance.post(
      `/${campaignId}/${type}/reward/`
    );

    return data;
  }

  async updateReward(campaignId, type, rewardId, body) {
    const { data } = await this.axiosInstance.put(
      `/${campaignId}/${type}/reward/${rewardId}`,
      body
    );

    return data;
  }

  async deleteReward(campaignId, type, rewardId) {
    const { data } = await this.axiosInstance.delete(
      `/${campaignId}/${type}/reward/${rewardId}`
    );

    return data;
  }

  async fileUploadReward(campaignId, type, rewardId, form) {
    const { data } = await this.upload(
      `/${campaignId}/${type}/reward/${rewardId}/upload`,
      form
    );

    return data;
  }

  async getPrizePoolList() {
    const { data } = await this.get(`/prizepool`);

    return data;
  }
}

<template>
  <div class="upload-modal">
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-bordered mb-0">
          <div class="panel-heading">
            <h3 class="panel-title">Upload</h3>
          </div>
          <div class="panel-body p-0">
            <cropper
              class="cropper"
              :src="image"
              :stencil-props="{
                aspectRatio: 1 / 1,
              }"
              @change="change"
            ></cropper>

            <div class="action-modal mx-10 my-15">
              <label for="file-upload" class="btn btn-link btn-block">
                <input
                  id="file-upload"
                  type="file"
                  ref="file"
                  class="btn btn-round btn-block btn-default"
                  @change="uploadImage($event)"
                  accept="image/gif, image/jpeg, image/png"
                />
                <span>Browse file</span>
              </label>
              <p v-if="errorFileUpload" class="text-danger text-center">
                {{ errorFileUpload }}
              </p>
            </div>
          </div>
          <div class="panel-footer">
            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-round btn-default mx-10"
                @click="close"
              >
                Cancel
              </button>
              <button
                type="button"
                @click="submit"
                class="btn btn-round btn-primary"
                :disabled="!croped"
              >
                {{ submitButtonText || "Upload" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
export default {
  props: ["defaultImage", "submitButtonText"],
  data() {
    return {
      croped: null,
      imageType: "image/jpeg",
      errorFileUpload: "",
      image: this.defaultImage || "",
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
    change({ coordinates, canvas }) {
      this.croped = canvas.toDataURL(this.imageType, 1);
    },
    submit() {
      var blobBin = atob(this.croped.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var file = new Blob([new Uint8Array(array)], { type: this.imageType });

      return this.$emit("onCrop", file);
    },
    uploadImage(event) {
      // Reference to the DOM input element
      var input = event.target;

      this.errorFileUpload = "";

      if (input.files[0].size > 1000000) {
        this.errorFileUpload = "File is too big";
        input.files[0].value = "";
        return;
      }

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
          this.imageType = input.files[0].type;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
  },

  components: {
    Cropper,
  },
};
</script>

<style lang="scss" scoped>
.upload-modal {
  .cropper {
    height: 350px;
    background: #ddd;
  }
  input[type="file"] {
    display: none;
  }
}
</style>

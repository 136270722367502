<template>
  <div class="form-group" v-if="pool">
    <div my-15px>
      <label class="mx-5">
        <h5>
          Picture
          <span class="small light">
            Recommended size 300 x 300 pixels / less than 1 MB
          </span>
        </h5>
      </label>

      <div class="form-group">
        <div class="image-form-wrapper">
          <div class="image-preview">
            <img class="img img-fluid" v-if="picture" :src="picture" />
            <div v-else class="image-placeholder"></div>
          </div>
          <input
            type="file"
            ref="picture"
            name="picture"
            accept="image/*"
            class="form-control"
            :disabled="disabled"
            @change="uploadImage"
          />
          <div class="action" v-if="picture">
            <button
              type="button"
              :disabled="disabled"
              class="btn btn-default reset-img"
              @click="showConfirmDelete"
            >
              <i class="icon md-delete" aria-hidden="true"></i>
              Remove image
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Prize picture error.",
        success: "Upload Prize picture success.",
      },
    };
  },

  computed: {
    picture() {
      return this.pool.picture;
    },
  },

  methods: {
    showConfirmDelete() {
      const r = confirm("Do you really want to delete this image?");
      if (r == true) {
        this.removeImage({ picture: null });
      }
    },
    validateFileUpload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        event.target.files[0].value = "";
        throw new Error("File shall not exceed 1 MB");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/upload.form.scss";

.image-form-wrapper {
  width: 375px;

  .action {
    width: 100%;
    height: 100%;
    position: relative;

    .reset-img {
      right: 0.5rem;
      bottom: 0.5rem;
      position: absolute;
    }
  }
}
</style>

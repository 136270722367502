var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group col-12" },
    [
      _vm._m(0),
      _c("ckeditor", {
        attrs: { "read-only": _vm.disabled },
        on: { blur: _vm.handleDescriptionChange },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Award Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
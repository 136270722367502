var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-8" }, [
      _c("h5", { staticClass: "px-5 mb-15" }, [_vm._v("Picture")]),
      _c("div", { staticClass: "px-15 mb-30" }, [
        _vm._m(0),
        _c("div", { staticClass: "d-block" }, [
          _vm.placeholderImage
            ? _c("img", {
                staticClass: "img img-fluid image-preview",
                attrs: { src: _vm.placeholderImage },
              })
            : _c("div", { staticClass: "img-fluid image-preview" }),
        ]),
        _c("input", {
          ref: "placeholder_url",
          staticClass: "form-control",
          attrs: {
            type: "file",
            accept: "image/*",
            name: "placeholder_url",
            disabled: _vm.disabled,
          },
          on: { change: _vm.upload },
        }),
      ]),
      _c("div", { staticClass: "px-15" }, [
        _vm._m(1),
        _c("div", { staticClass: "d-block" }, [
          _vm.jigsawImage
            ? _c("img", {
                staticClass: "img img-fluid image-preview",
                attrs: { src: _vm.jigsawImage },
              })
            : _c("div", { staticClass: "img-fluid image-preview" }),
        ]),
        _c("input", {
          ref: "jigsaw_url",
          staticClass: "form-control",
          attrs: {
            type: "file",
            accept: "image/*",
            name: "jigsaw_url",
            disabled: _vm.disabled,
          },
          on: { change: _vm.upload },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "light" }, [
      _vm._v(" Placeholder "),
      _c("span", { staticClass: "small" }, [
        _vm._v(" (recommended size 628 x 328)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "light" }, [
      _vm._v(" Jigsaw "),
      _c("span", { staticClass: "small" }, [
        _vm._v(" (recommended size 628 x 328)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
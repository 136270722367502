var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "form-group-row" }, [
      _vm._m(0),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pool.available_condition,
            expression: "pool.available_condition",
          },
        ],
        staticClass: "form-control",
        attrs: {
          min: "0",
          max: "10000",
          type: "number",
          disabled: _vm.disabled,
          name: "available_condition",
        },
        domProps: { value: _vm.pool.available_condition },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.pool, "available_condition", $event.target.value)
          },
        },
      }),
      _c("span", [_vm._v("days before prize expiration")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("h4", { staticClass: "mx-5" }, [_vm._v("Available condition")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
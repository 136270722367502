<template>
  <div class="row">
    <div class="col-4">
      <p class="light text-right" ml-5>Minimum Purchase</p>
    </div>
    <div class="col-8">
      <input
        min="0"
        width-120px
        type="number"
        @change="update"
        class="form-control"
        name="ecoupon_minimum_purchase"
        v-model="reward.ecoupon_minimum_purchase"
        :disabled="disabled || reward.reward_type !== 'ecoupon'"
      />
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

const message = `Update Custom text on coupon Reward`;

export default {
  mixins: [rewardMixins],

  data() {
    return {
      message: {
        error: `${message} #${this.reward.id} error.`,
        success: `${message} #${this.reward.id} success.`,
      },
    };
  },
};
</script>

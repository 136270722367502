<template>
  <div class="col-md-12">
    <label class="mx-5">
      <h4>Rewards</h4>
    </label>

    <div
      class="reward-records"
      v-for="(reward, index) in campaign.stamp.reward"
      :key="reward.id"
    >
      <div class="row">
        <div class="col-12">
          <RewardRow
            :index="index"
            :reward="reward"
            :campaign="campaign"
            :prizepool="prizepool"
            @updated="$emit('updated')"
            @delete="deleteReward"
          />
        </div>
      </div>
    </div>

    <div class="action">
      <button
        type="button"
        class="btn btn-round btn-primary"
        @click="addNewReward"
        :disabled="disabled || !canAddMoreReward"
      >
        <i aria-hidden="true" class="icon md-plus"></i> Add Reward
      </button>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

import RewardRow from "./RewardRow.vue";

export default {
  props: ["campaign"],
  components: { RewardRow },
  data() {
    return {
      prizepool: [],
      message: {
        error: "Update error.",
        success: "Update success.",
      },
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
    canAddMoreReward() {
      const { reward, no_stamp } = this.campaign.stamp;
      return reward.length < no_stamp;
    },
  },
  methods: {
    async fetchPrizePool() {
      this.prizepool = await progressBarService.getPrizePoolList();
    },
    async addNewReward() {
      try {
        await progressBarService.createReward(this.campaign.id, "stamp");
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(this.message.error);
      }
    },
    async deleteReward(id) {
      try {
        await progressBarService.deleteReward(this.campaign.id, "stamp", id);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(this.message.error);
      }
    },
    async initial() {
      await this.fetchPrizePool();
    },
  },

  mounted() {
    this.initial();
  },
};
</script>

<style lang="scss" scoped>
.action {
  padding: 2rem 1rem 1rem;
}
</style>

<template>
  <div>
    <div class="row">
      <label class="form-control-label">
        <h4>Objects</h4>
      </label>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table">
          <tr v-for="(o, index) in objectConfig" :key="index">
            <td>No. {{ index + 1 }}</td>
            <td class="center w-400">
              <p>Picture</p>
              <div class="form-group">
                <div class="d-block">
                  <img
                    class="img img-fluid image-preview"
                    v-if="o.picture"
                    :src="o.picture"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="upload"
                  :disabled="disabled"
                  :data-index="index"
                />
              </div>
            </td>
            <td class="v-align-middle">
              <div>
                <div class="d-flex align-items-center mb-10">
                  <p class="w-100 mx-10 mb-0">No. Occurrence</p>
                  <input
                    min="0"
                    type="number"
                    class="form-control w-100"
                    :disabled="disabled"
                    @change="update(index)"
                    v-model="o.no_occurrence"
                  />
                </div>
                <div class="d-flex align-items-center mb-10">
                  <p class="w-100 mx-10 mb-0">Point per object</p>
                  <input
                    min="0"
                    max="999"
                    type="number"
                    class="form-control w-100"
                    :disabled="disabled"
                    @change="update(index)"
                    v-model="o.point_per_object"
                  />
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return { objectConfig: [] };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },

  methods: {
    async update(index) {
      try {
        const valid = await this.validate(index);
        if (!valid) return;

        const { id } = this.gameConfig;
        const { no_occurrence, point_per_object } = this.objectConfig[index];

        await httpService.put(`/game/${id}/sunset_drive/object/${index + 1}`, {
          no_occurrence,
          point_per_object,
        });

        this.$emit("updated");
        this.$notify(`Update Object config success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Object config error.`);
      }
    },

    async upload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("picture", file);

        const { id } = this.gameConfig;
        const index = event.target.getAttribute("data-index");

        await httpService.post(
          `/game/${id}/sunset_drive/object/${+index + 1}/upload`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$emit("updated");
        this.$notify(`Object picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Object picture error.`);
      }
    },

    async validate(index) {
      const { game_duration } = this.gameConfig.sunset_drive;
      const { no_occurrence, point_per_object } = this.objectConfig[index];

      if (
        no_occurrence < 0 ||
        no_occurrence > game_duration * 10 ||
        isNaN(+no_occurrence)
      ) {
        await this.$dialogs.alert(
          "กรุณาระบุ No. Occurrence: ใส่เป็นตัวเลขตั้งแต่ 0 ถึง Game duration x 10"
        );
        return false;
      }

      if (
        point_per_object < 0 ||
        point_per_object > 999 ||
        isNaN(+point_per_object)
      ) {
        await this.$dialogs.alert(
          "กรุณาระบุ Point per object: ใส่เป็นตัวเลขตั้งแต่ 0 ถึง 999"
        );
        return false;
      }

      return true;
    },
  },

  mounted() {
    this.objectConfig = this.gameConfig.sunset_drive.object;
  },
};
</script>

<style lang="scss" scoped>
table.table {
  margin-left: 2em;
}
td.center {
  text-align: center;
}
td.v-align-middle {
  vertical-align: middle;
}

.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 100px;
  background-color: #eaeaea;
}

.image-preview {
  max-width: 150px;
}

.form-group {
  > .d-block,
  > input[type="file"] {
    width: 320px;
    background-color: #f9f9f9;
  }
}
</style>

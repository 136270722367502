var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Engagement")]),
        _c("breadcrumb", { attrs: { name: "GAME_ENGAGEMENT" } }),
        _c(
          "div",
          { staticClass: "page-header-right" },
          [
            _vm._v(" Selected Period "),
            _c("date-picker", {
              attrs: {
                type: "date",
                "value-type": "format",
                format: "YYYY-MM-DD",
                range: "",
                placeholder: "Select date range",
              },
              model: {
                value: _vm.fromTo,
                callback: function ($$v) {
                  _vm.fromTo = $$v
                },
                expression: "fromTo",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "panel" }, [
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("h4", [_vm._v("Games")]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "checkbox-custom checkbox-primary checkbox-inline",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isCheckAll,
                              expression: "isCheckAll",
                            },
                          ],
                          attrs: { type: "checkbox", id: "all_game_checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.isCheckAll)
                              ? _vm._i(_vm.isCheckAll, null) > -1
                              : _vm.isCheckAll,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.checkAll()
                            },
                            change: function ($event) {
                              var $$a = _vm.isCheckAll,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isCheckAll = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isCheckAll = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isCheckAll = $$c
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "all_game_checkbox" } }, [
                          _vm._v("All"),
                        ]),
                      ]
                    ),
                    _vm._l(_vm.gamesSelect, function (game) {
                      return _c(
                        "div",
                        {
                          key: game,
                          staticClass:
                            "checkbox-custom checkbox-primary checkbox-inline",
                          class: game,
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selected,
                                expression: "selected",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: game + "_game_checkbox",
                            },
                            domProps: {
                              value: game,
                              checked: Array.isArray(_vm.selected)
                                ? _vm._i(_vm.selected, game) > -1
                                : _vm.selected,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.selected,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = game,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selected = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selected = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selected = $$c
                                  }
                                },
                                function ($event) {
                                  return _vm.updateCheckall()
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: game + "_game_checkbox" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm._f("capitalize")(game)) + " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "Excel" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: { click: _vm.exportToExcel },
              },
              [
                _c("i", { staticClass: "fa fa-file-excel-o" }),
                _vm._v(" Export to Excel "),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c("vue-good-table", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.engagement.table,
                  "pagination-options": {
                    enabled: true,
                    mode: "records",
                    perPage: 10,
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel" }, [
      _c("div", { staticClass: "panel-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-7" }, [
            _c("div", { attrs: { id: "chart-line" } }),
          ]),
          _c("div", { staticClass: "col-lg-5" }, [
            _c("div", { attrs: { id: "chart-pie" } }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
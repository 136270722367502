<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-body">
          <h3 style="text-align: center;">Create New Question</h3>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="Create"
            >
              <i class="icon md-check" aria-hidden="true"></i>
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { questionService } from "@/api/client";
export default {
  name: "Create",
  data() {
    return {};
  },
  methods: {
    close() {
      this.$modal.hide("create-modal");
    },
    async Create() {
      await questionService.createQuestion({});
      this.$modal.hide("create-modal");
      this.$emit("submit");
    },
  },
};
</script>

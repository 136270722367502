module.exports = {
  custom: function () {
    return [
      {
        text: "Home",
        link: "/",
      },
    ];
  },
  ADMIN_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Admin User",
    },
  ],
  ADMIN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Admin User",
      link: "/admin",
    },
  ],
  ADMIN_CHANGE_PASSWORD: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Admin",
      link: "/admin",
    },
    {
      text: "Reset Password",
      link: "/admin/reset-password",
    },
  ],
  USERGROUP_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "UserGroup",
      link: "/usergroup",
    },
  ],
  USERGROUP_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "UserGroup List",
      link: "/usergroup/list",
    },
  ],
  PRODUCT_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Product List",
      link: "/product",
    },
  ],
  PRODUCT_CREATE: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Product List",
      link: "/product",
    },
    {
      text: "Create Product",
    },
  ],
  PRODUCT_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Product List",
      link: "/product",
    },
  ],
  LOT_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lot List",
      link: "/lot",
    },
  ],
  LOT_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lot List",
      link: "/lot/list",
    },
  ],
  WINNER_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Auction Lucky Draw",
      link: "/lot",
    },
    {
      text: "Winners",
      link: "",
    },
  ],
  HISTORY_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Auction Lucky Draw",
      link: "/lot",
    },
    {
      text: "History",
      link: "/history/list",
    },
  ],
  HISTORY_VIEW: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Auction Lucky Draw",
      link: "/lot",
    },
    {
      text: "History",
      link: (r) => {
        r.go(-1);
      },
    },
  ],
  REFUND_LOG_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Auction Lucky Draw",
      link: "/lot",
    },
    {
      text: "Refund Log",
      link: "",
    },
  ],
  TRANSACTION_LOG_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Auction Lucky Draw",
      link: "/lot",
    },
    {
      text: "Transaction Log",
      link: "",
    },
  ],
  LOG_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Auction Lucky Draw",
      link: "/lot",
    },
    {
      text: "Log",
      link: "",
    },
  ],
  CAMPAIGN_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Dailly Login",
      link: "/campaign",
    },
    {
      text: "Campaign",
      link: "/campaign",
    },
  ],
  CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Dailly Login",
      link: "/campaign",
    },
    {
      text: "Campaign",
      link: "/campaign",
    },
  ],
  RESULT_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Dailly Login",
      link: "/campaign",
    },
    {
      text: "Result",
      link: "/result",
    },
  ],
  GAME_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "World Game",
      link: "/gamesinstance",
    },
    {
      text: "Game Instance",
      link: "/gamesinstance",
    },
  ],
  GAME_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "World Game",
      link: "/gamesinstance",
    },
    {
      text: "Game Instance",
      link: (r) => {
        r.go(-1);
      },
    },
  ],
  ADVOCACY_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "World Game",
      link: "/gamesinstance",
    },
    {
      text: "Advocacy Setting",
      link: "/advocacy",
    },
  ],
  POINT_CAMPAIGN_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: (r) => {
        r.go(-1);
      },
    },
    {
      text: "Point Campaign",
    },
  ],
  POINT_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: (r) => {
        r.go(-1);
      },
    },
    {
      text: "Point Campaign",
      link: (r) => {
        r.go(-1);
      },
    },
  ],
  POINT_USER_REPORT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: "/point-program/point-campaign/list",
    },
    {
      text: "User Report",
    },
  ],
  POINT_TRANSACTION_LOGS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: "/point-program/point-campaign/list",
    },
    {
      text: "Transaction Log",
    },
  ],
  POINT_BULK_OPERATION: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: "/point-program/point-campaign/list",
    },
    {
      text: "Bulk Operation",
    },
  ],
  POINT_QUOTA_LIMIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: "/point-program/point-campaign/list",
    },
    {
      text: "Point Quota Limit",
    },
  ],
  POINT_QUOTA_LIMIT_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: "/point-program/point-campaign/list",
    },
    {
      text: "Point Quota Limit",
      link: "/point-program/point-quota-limit",
    },
  ],
  POINT_DISCOUNT_REPORT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Point Program",
      link: "/point-program/point-campaign/list",
    },
    {
      text: "Discount Report",
    },
  ],
  GAME_ENGAGEMENT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "World Game",
      link: "/gamesinstance",
    },
    {
      text: "Engagement",
      link: "/gamesinstance/engagement",
    },
  ],
  GAME_TRANSACTION_LOGS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "World Game",
      link: "/gamesinstance",
    },
    {
      text: "Transaction Log",
      link: "/gamesinstance/transaction-log",
    },
  ],
  LUCKY_LOGIN_CAMPAIGN_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Login",
      link: "/lucky-login/campaigns",
    },
    {
      text: "Campaign List",
      link: "",
    },
  ],
  LUCKY_LOGIN_WINNER_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Login",
      link: "/lucky-login/campaigns",
    },
    {
      text: "Winner",
      link: "",
    },
  ],
  LUCKY_LOGIN_REVIEW: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Login",
      link: "/lucky-login/campaigns",
    },
    {
      text: "Review",
      link: "",
    },
  ],
  LUCKY_LOGIN_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Login",
      link: "/lucky-login/campaigns",
    },
    {
      text: "Campaign List",
      link: "/lucky-login/campaigns",
    },
  ],
  PROGRESS_BAR_CAMPAIGN_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Progress Bar",
      link: "/progress-bar/campaigns",
    },
    {
      text: "Campaign",
      link: "",
    },
  ],
  PROGRESS_BAR_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Progress Bar",
      link: "/progress-bar/campaigns",
    },
    {
      text: "Campaign",
      link: "/progress-bar/campaigns",
    },
  ],
  PROGRESS_BAR_UPDATE_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Progress Bar",
      link: "/progress-bar/campaigns",
    },
    {
      text: "Progress Update",
      link: "",
    },
  ],
  PROGRESS_BAR_UPDATE_DETAIL: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Progress Bar",
      link: "/progress-bar/campaigns",
    },
    {
      text: "Progress Update",
      link: "/progress-bar/update",
    },
  ],
  PROGRESS_BAR_TRANSACTION_LOGS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Progress Bar",
      link: "/progress-bar/campaigns",
    },
    {
      text: "Transaction Log",
      link: "",
    },
  ],
  E_COUPON_CAMPAIGN: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Coupon Campaign",
    },
  ],
  E_COUPON_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Coupon Campaign",
      link: "/e-coupon/campaign",
    },
  ],
  E_COUPON_TRANSACTION_LOG: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Transaction Log",
    },
  ],
  E_COUPON_ACCESS_SETTING: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Access Setting",
    },
  ],
  E_COUPON_CCB2B_LOG: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "CCB2B Log",
    },
  ],
  E_COUPON_CCB2B_LOG_DETAIL: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "CCB2B",
      link: "/e-coupon/ccb2b-log",
    },
  ],
  E_COUPON_PEDDLER_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Peddler Management",
    },
  ],
  E_COUPON_PEDDLER_DETAIL: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Peddler Management",
      link: "/e-coupon/peddler-management",
    },
  ],
  E_COUPON_PEDDLER_ACTIVITY: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Peddler Activity",
    },
  ],
  VSM_MANAGEMENT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "VSM Management",
    },
  ],
  VSM_USER_MANAGEMENT_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "VSM Users",
    },
  ],
  VSM_USER_MANAGEMENT_DETAIL: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "VSM Users",
      link: "/e-coupon/vsm-user-management",
    },
  ],
  E_COUPON_STATUS_REPORT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "E-Coupon",
      link: "/e-coupon/campaign",
    },
    {
      text: "Status Report",
    },
  ],
  GAME_LEADER_BOARD_CAMPAIGN: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Game Leaderboard",
      link: "/game-leaderboard/campaign",
    },
    {
      text: "Campaign",
    },
  ],
  GAME_LEADER_BOARD_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Game Leaderboard",
      link: "/game-leaderboard/campaign",
    },
    {
      text: "Campaign",
      link: "/game-leaderboard/campaign",
    },
  ],
  GAME_LEADER_BOARD_CAMPAIGN_STATUS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Game Leaderboard",
      link: "/game-leaderboard/campaign",
    },
    {
      text: "Game Leaderboard",
      link: "/game-leaderboard/campaign",
    },
    {
      text: "Status",
    },
  ],
  PRIZE_SUMMARY_POOL_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/pool",
    },
    {
      text: "Prize Pool",
      link: "",
    },
  ],
  PRIZE_SUMMARY_POOL_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/pool",
    },
    {
      text: "Prize Pool",
      link: "/prize/pool",
    },
  ],
  PRIZE_SUMMARY_THEME_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/pool",
    },
    {
      text: "Prize Theme",
    },
  ],
  PRIZE_SUMMARY_THEME_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/pool",
    },
    {
      text: "Prize Theme",
      link: "/prize/theme",
    },
  ],
  PRIZE_SUMMARY_POOL_STATUS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/pool",
    },
    {
      text: "Pool Status",
      link: "/prize/pool/status",
    },
  ],
  PRIZE_SUMMARY_REQUEST_TRANSACTION: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/pool",
    },
    {
      text: "Request Transaction",
    },
  ],
  PRIZE_SUMMARY_LOT_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/lot",
    },
    {
      text: "Prize Lot",
      link: "",
    },
  ],
  PRIZE_SUMMARY_LOT_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/lot",
    },
    {
      text: "Prize Lot",
      link: "/prize/lot",
    },
  ],
  PRIZE_SUMMARY_STATUS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Prize",
      link: "/prize/lot",
    },
    {
      text: "Prize Status",
      link: "/prize/status",
    },
  ],
  LUCKY_DRAW_CAMPAIGN: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Draw",
      link: "/lucky-draw/campaigns",
    },
    {
      text: "Campaign",
    },
  ],
  LUCKY_DRAW_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Draw",
      link: "/lucky-draw/campaigns",
    },
    {
      text: "Campaign",
      link: "/lucky-draw/campaigns",
    },
  ],
  LUCKY_DRAW_TRANSACTION_LOG: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Lucky Draw",
      link: "/lucky-draw/campaigns",
    },
    {
      text: "Transaction Log",
    },
  ],
  ILLICIT_BARCODE_CAMPAIGN: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Illicit Barcode",
      link: "/illicit-barcode/campaign",
    },
    {
      text: "Campaign",
    },
  ],
  ILLICIT_BARCODE_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Illicit Barcode",
      link: "/illicit-barcode/campaign",
    },
    {
      text: "Campaign",
      link: "/illicit-barcode/campaign",
    },
  ],
  ILLICIT_BARCODE_EXPIRATION_CAMPAIGN: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Illicit Barcode",
      link: "/illicit-barcode/campaign",
    },
    {
      text: "Expiration Campaign",
    },
  ],
  ILLICIT_BARCODE_EXPIRATION_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Illicit Barcode",
      link: "/illicit-barcode/campaign",
    },
    {
      text: "Expiration Campaign",
      link: "/illicit-barcode/expiration-campaign",
    },
  ],
  ILLICIT_BARCODE_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Illicit Barcode",
      link: "/illicit-barcode/campaign",
    },
    {
      text: "Illicit List",
    },
  ],
  ILLICIT_BARCODE_TRANSACTION_LOG: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Illicit Barcode",
      link: "/illicit-barcode/campaign",
    },
    {
      text: "Transaction Log",
    },
  ],
  WEEKLY_LOGIN_CAMPAIGN_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Weekly Login",
      link: "/weekly-login/campaign",
    },
    {
      text: "Campaign",
    },
  ],
  WEEKLY_LOGIN_CAMPAIGN_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Weekly Login",
      link: "/weekly-login/campaign",
    },
    {
      text: "Campaign",
      link: "/weekly-login/campaign",
    },
  ],
  WEEKLY_LOGIN_TRANSACTION_LOG: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Weekly Login",
      link: "/weekly-login/campaign",
    },
    {
      text: "Transaction Log",
    },
  ],
  LIFF_ACCESS_SETTINGS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "LIFF Access",
    },
  ],
  GAME_SAMURAI_SETTINGS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Samurai",
      link: "/game-samurai/settings",
    },
    {
      text: "Settings",
    },
  ],
  GAME_SAMURAI_THEMES: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Samurai",
      link: "/game-samurai/themes",
    },
    {
      text: "Themes",
    },
  ],
  GAME_SAMURAI_THEMES_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Samurai",
      link: "/game-samurai/themes",
    },
    {
      text: "Themes",
      link: "/game-samurai/themes",
    },
  ],
  BUDGET_CONTROL_INSTANCE_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Budget Control",
      link: "/budget-control/instance",
    },
    {
      text: "Budget Instance",
    },
  ],
  BUDGET_CONTROL_INSTANCE_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Budget Control",
      link: "/budget-control/instance",
    },
    {
      text: "Budget Instance",
      link: "/budget-control/instance",
    },
  ],
  BUDGET_CONTROL_STATUS_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Budget Control",
      link: "/budget-control/status",
    },
    {
      text: "Budget Status",
    },
  ],
  BUDGET_CONTROL_STATUS_DETAIL: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Budget Control",
      link: "/budget-control/status",
    },
    {
      text: "Budget Status",
      link: "/budget-control/status",
    },
  ],
  STAFF_ENGAGEMENT_LIST: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Staff Engagement",
      link: "/staff-engagement/campaign",
    },
    {
      text: "Campaign",
      link: "/staff-engagement/campaign",
    },
  ],
  STAFF_ENGAGEMENT_EDIT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Staff Engagement",
      link: "/staff-engagement/campaign",
    },
    {
      text: "Campaign",
      link: "/staff-engagement/campaign",
    },
  ],
  STAFF_ENGAGEMENT_TRANSACTION_LOGS: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Staff Engagement",
      link: "/staff-engagement/campaign",
    },
    {
      text: "Transaction Log",
      link: "",
    },
  ],
  STAFF_ENGAGEMENT_PARTICIPATION_REPORT: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Staff Engagement",
      link: "/staff-engagement/campaign",
    },
    {
      text: "Participation Report",
      link: "",
    },
  ],
};

<template>
  <div class="reward-record panel panel-bordered">
    <div class="panel-heading">
      <div class="panel-title">
        Reward
        <span class="light">
          {{ reward.reward_name }}
        </span>
      </div>
      <div class="panel-actions panel-actions-keep">
        <button type="button" class="btn btn-link" @click="show = !show">
          {{ show ? "hide [ - ]" : "show [ + ]" }}
        </button>
      </div>
    </div>

    <div class="panel-body" style="padding-bottom: 0px" v-if="show">
      <div class="reward-content">
        <div class="no">No.{{ reward.id }}</div>
        <div class="detail container">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <RewardNo
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  stamp: campaign.stamp,
                  status: campaign.status,
                }"
              />

              <RewardName
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                }"
              />

              <RewardDetail
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                }"
              />
            </div>
            <div class="col-lg-6 col-md-12">
              <RewardPrizePool
                :index="index"
                :reward="reward"
                :prizepool="prizepool"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                  user_type: campaign.user_type,
                }"
              />

              <RewardPoint
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                  user_type: campaign.user_type,
                }"
              />

              <RewardECoupon
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                  user_type: campaign.user_type,
                }"
              />

              <RewardECouponCustomText
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                  user_type: campaign.user_type,
                }"
              />

              <RewardECouponMinimumPurchase
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                  user_type: campaign.user_type,
                }"
              />

              <RewardExpiration
                :index="index"
                :reward="reward"
                :campaign="{
                  id: campaign.id,
                  status: campaign.status,
                  user_type: campaign.user_type,
                }"
                @updated="$emit('updated')"
              />
            </div>
          </div>
          <div class="row">
            <RewardIconNotEarned
              :index="index"
              :reward="reward"
              :campaign="{
                id: campaign.id,
                status: campaign.status,
              }"
              @updated="$emit('updated')"
            />

            <RewardIconEarned
              :index="index"
              :reward="reward"
              :campaign="{
                id: campaign.id,
                status: campaign.status,
              }"
              @updated="$emit('updated')"
            />
          </div>
          <div class="row">
            <RewardPicture
              :index="index"
              :reward="reward"
              :campaign="{
                id: campaign.id,
                status: campaign.status,
              }"
              @updated="$emit('updated')"
            />
          </div>
        </div>
        <div class="action">
          <button
            type="button"
            :disabled="disabled"
            class="btn btn-default"
            @click="showConfirmDelete"
          >
            <i class="icon md-delete" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RewardNo from "./RewardNo.vue";
import RewardName from "./RewardName.vue";
import RewardPoint from "./RewardPoint.vue";
import RewardDetail from "./RewardDetail.vue";
import RewardPicture from "./RewardPicture.vue";
import RewardECoupon from "./RewardECoupon.vue";
import RewardPrizePool from "./RewardPrizePool.vue";
import RewardIconEarned from "./RewardIconEarned.vue";
import RewardExpiration from "./RewardExpiration.vue";
import RewardIconNotEarned from "./RewardIconNotEarned.vue";
import RewardECouponCustomText from "./RewardECouponCustomText.vue";
import RewardECouponMinimumPurchase from "./RewardECouponMinimumPurchase.vue";

export default {
  props: ["index", "campaign", "reward", "prizepool"],

  components: {
    RewardNo,
    RewardName,
    RewardPoint,
    RewardDetail,
    RewardPicture,
    RewardECoupon,
    RewardPrizePool,
    RewardIconEarned,
    RewardExpiration,
    RewardIconNotEarned,
    RewardECouponCustomText,
    RewardECouponMinimumPurchase,
  },

  data() {
    return {
      show: this.index == 0,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    showConfirmDelete() {
      const r = confirm(
        `Do you really want to delete reward id #${this.reward.id}`
      );

      if (r === true) {
        this.$emit("delete", this.reward.id);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/reward.scss";
@import "../styles/upload.form.scss";
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-block card-inverse bg-primary text-center" },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass:
            "card-footer card-footer-transparent card-footer-bordered text-muted",
        },
        [
          _c(
            "button",
            {
              staticClass:
                "btn btn-block btn-round btn-outline btn-default grey-200",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            },
            [_vm._v(" Go to login page. ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block" }, [
      _c("i", {
        staticClass: "icon md-alert-circle-o",
        attrs: { "aria-hidden": "true" },
      }),
      _c("h3", { staticClass: "grey-200" }, [
        _vm._v(" Session token was expired. "),
      ]),
      _c("p", { staticClass: "font-size-16" }, [
        _vm._v(" please sign in again. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-menubar" }, [
    _c("div", { staticClass: "site-menubar-body" }, [
      _c(
        "ul",
        { staticClass: "site-menu", attrs: { "data-plugin": "menu" } },
        _vm._l(_vm.menus, function (menu, idx) {
          return _c(
            "li",
            {
              key: idx,
              class: _vm.getMenuClass(menu),
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick.apply(null, arguments)
                },
              },
            },
            [
              menu.category
                ? _c("span", [_vm._v(_vm._s(menu.text))])
                : _vm._e(),
              !menu.hasSub && !menu.category
                ? _c(
                    "router-link",
                    {
                      staticClass: "animsition-link",
                      attrs: { to: menu.link },
                    },
                    [
                      _c("i", {
                        staticClass: "site-menu-icon",
                        class: menu.icon,
                        attrs: { "aria-hidden": "true" },
                      }),
                      _c("span", { staticClass: "site-menu-title" }, [
                        _vm._v(_vm._s(menu.text)),
                      ]),
                    ]
                  )
                : _vm._e(),
              menu.hasSub
                ? _c("a", { attrs: { href: "javascript:void(0)" } }, [
                    _c("i", {
                      staticClass: "site-menu-icon",
                      class: menu.icon,
                      attrs: { "aria-hidden": "true" },
                    }),
                    _c("span", { staticClass: "site-menu-title" }, [
                      _vm._v(_vm._s(menu.text)),
                    ]),
                    _c("span", { staticClass: "site-menu-arrow" }),
                  ])
                : _vm._e(),
              menu.hasSub
                ? _c(
                    "ul",
                    { staticClass: "site-menu-sub" },
                    _vm._l(menu.subs, function (sub, sidx) {
                      return _c(
                        "li",
                        { key: sidx, staticClass: "site-menu-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "animsition-link sub-link",
                              attrs: { to: sub.link },
                            },
                            [
                              _c("span", { staticClass: "site-menu-title" }, [
                                _vm._v(_vm._s(sub.text)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page vertical-align text-center",
      attrs: {
        "data-animsition-in": "fade-in",
        "data-animsition-out": "fade-out",
      },
    },
    [
      _c("div", { staticClass: "page-content vertical-align-middle" }, [
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "panel panel-transparent" }, [
          _c("div", { staticClass: "panel-body" }, [
            _vm._m(2),
            _c(
              "form",
              {
                staticClass: "login w-350 mx-auto mt-30",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group text-left" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.username,
                        expression: "username",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "username",
                      name: "username",
                      placeholder: "Name",
                    },
                    domProps: { value: _vm.username },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.username = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group text-left" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      id: "password",
                      name: "password",
                      placeholder: "Password",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group clearfix" }),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-block btn-lg mt-30",
                    attrs: { type: "submit" },
                  },
                  [_vm._v(" Sign in ")]
                ),
                _c("p", { staticClass: "mt-30 text-white" }, [
                  _vm._v("Sign into your pages account"),
                ]),
              ]
            ),
          ]),
        ]),
        _c("footer", { staticClass: "page-copyright" }, [
          !_vm.isProduction
            ? _c(
                "a",
                {
                  staticClass: "btn btn-link text-white",
                  attrs: {
                    href:
                      "https://bitbucket.org/keenagilist/vue_ams_vivi/commits/" +
                      _vm.gitHash,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.gitBranch) +
                      " / " +
                      _vm._s(_vm.gitHash) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _c("small", { staticClass: "text-white" }, [
        _vm._v("PHILIP MORRIS "),
        _c("br"),
        _vm._v("INTERNATIONAL"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "brand" }, [
      _c("img", {
        staticClass: "brand-img w-120",
        attrs: { src: require("@/assets/logo.png"), alt: "PMI" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _c("small", { staticClass: "text-white" }, [
        _vm._v("AUCTION MANAGEMENT SYSTEM"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
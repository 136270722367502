<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">User Activity</h3>
        </div>
        <div class="panel-body">
          <div class="form-row">
            <div class="form-group col-md-3">
              <label class="form-control-label">Campaign</label> <br />
              <label class="form-control-label">{{ campagin_id.name }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">User ID</label> <br />
              <label class="form-control-label">{{
                reportDetail.sfdc_user_id
              }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">Contact ID</label> <br />
              <label class="form-control-label">{{
                reportDetail.contact_id
              }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">First Name</label> <br />
              <label class="form-control-label">{{
                reportDetail.firstname
              }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">Last Name</label> <br />
              <label>{{ reportDetail.lastname }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">No. Coins</label> <br />
              <label class="form-control-label">{{
                reportDetail.total_coins
              }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">No. Special</label> <br />
              <label class="form-control-label">{{
                reportDetail.no_locker
              }}</label>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">No. Exchange</label> <br />
              <label class="form-control-label">
                {{ reportDetail.no_exchange }}
              </label>
            </div>
          </div>
          <hr />
          <vue-good-table
            :columns="columns"
            :rows="history"
            :fixed-header="true"
            max-height="300px"
          >
            <template slot="table-row" slot-scope="props">
              <span
                v-if="
                  props.column.field == 'coin_plus' && props.row.type == 'coin'
                "
              >
                <span>{{ props.row.coins }}</span>
              </span>
              <span
                v-else-if="
                  props.column.field == 'coin_use' &&
                  props.row.type == 'exchange'
                "
              >
                <span>{{ props.row.coins | absolute }}</span>
              </span>
              <span
                v-else-if="
                  props.column.field == 'point' &&
                  (props.row.type == 'lock' || props.row.type == 'exchange')
                "
              >
                <span>{{ props.row.points }}</span>
              </span>
              <span
                v-else-if="
                  props.column.field == 'acc_coin' && props.row.type != 'lock'
                "
              >
                <span>{{ list_coins[props.row.timestamp] }}</span>
              </span>
              <span
                v-else-if="
                  props.column.field == 'acc_point' &&
                  (props.row.type == 'lock' || props.row.type == 'exchange')
                "
              >
                <span>{{ list_points[props.row.timestamp] }}</span>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useractivityTableColumns } from "@/views/result/model";
import { reportService } from "@/api/client";
export default {
  props: ["idDetail", "campagin_id"],
  data() {
    return {
      campagin: null,
      display: true,
      columns: useractivityTableColumns,
      reportDetail: [],
      history: [],
      list_coins: {},
      list_points: {},
    };
  },
  async created() {
    this.reportDetail = await reportService.getDetailbyId(
      this.campagin_id.id,
      this.idDetail
    );
    this.history = await this.reportDetail.history;
    this.reducecoins();
    this.reducepoints();
  },
  methods: {
    reducecoins() {
      const list = {};
      list[this.history[0].timestamp] = this.history[0].coins;
      for (var i = 1; i < this.history.length; i++) {
        list[this.history[i].timestamp] =
          this.history[i].coins + list[this.history[i - 1].timestamp];
      }
      this.list_coins = list;
    },
    reducepoints() {
      const list = {};
      list[this.history[0].timestamp] = this.history[0].points;
      for (var i = 1; i < this.history.length; i++) {
        list[this.history[i].timestamp] =
          this.history[i].points + list[this.history[i - 1].timestamp];
      }
      this.list_points = list;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div v-if="lot" class="mx-10 col-6">
    <label class="form-label">
      <h5 class="mb-0">Require Address</h5>
    </label>
    <br />
    <label class="switch">
      <input
        type="checkbox"
        :true-value="1"
        :false-value="0"
        @change="update"
        :disabled="!canEdit"
        v-model="require_address"
      />
      <span class="slider round"></span>
    </label>
    <p class="mx-10 d-inline">
      {{ +require_address === 1 ? "ON" : "OFF" }}
    </p>
  </div>
</template>

<script>
import { prizeService } from "@/api/client";

export default {
  props: ["lot"],

  data() {
    return {
      id: null,
      require_address: 0,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.lot.status);
    },
  },

  mounted() {
    this.id = this.lot.id;
    this.require_address = this.lot.require_address;
  },

  methods: {
    async update() {
      try {
        await prizeService.updateLot(this.lot.id, {
          require_address: this.require_address,
        });
        this.$notify(`Require address updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Require address  error.`);
      }
    },
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c("BaseIcon", { staticClass: "anim", attrs: { icon: _vm.icon } }),
      _c("small", { staticClass: "mx-10" }, [_vm._v("กำลังทำงาน...")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
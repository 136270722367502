var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.breadcrumbs, function (item, idx) {
      return _c(
        "li",
        { key: idx, class: _vm.getClass(idx) },
        [
          !_vm.isLastItem(idx) && typeof item.link !== "function"
            ? _c("router-link", { attrs: { to: item.link } }, [
                _vm._v(" " + _vm._s(item.text) + " "),
              ])
            : !_vm.isLastItem(idx) && typeof item.link === "function"
            ? _c(
                "a",
                {
                  attrs: { href: "javascript:void(null);" },
                  on: {
                    click: function ($event) {
                      return item.link(_vm.$router)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              )
            : _c("span", [_vm._v(" " + _vm._s(item.text) + " ")]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "radio-input-row radio-custom radio-primary" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.reward.reward_type,
              expression: "reward.reward_type",
            },
          ],
          attrs: {
            type: "radio",
            value: "prizepool",
            disabled: _vm.disabled,
            name: "reward_type_" + _vm.index,
            id: "reward_type_prizepool_" + _vm.index,
          },
          domProps: { checked: _vm._q(_vm.reward.reward_type, "prizepool") },
          on: {
            change: [
              function ($event) {
                return _vm.$set(_vm.reward, "reward_type", "prizepool")
              },
              function ($event) {
                return _vm.update($event, "reward_type")
              },
            ],
          },
        }),
        _c("label", { attrs: { for: "reward_type_prizepool_" + _vm.index } }, [
          _vm._v("Prize Pool"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "form-group-row" }, [
        _c("label", { attrs: { for: "prize_theme_id_" + _vm.reward.id } }, [
          _vm._v("Prize Theme ID"),
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reward.prize_theme_id,
                expression: "reward.prize_theme_id",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: {
              name: "prize_theme_id",
              id: "prize_theme_id_" + _vm.reward.id,
              disabled: _vm.disabled || _vm.reward.reward_type !== "prizepool",
            },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return _vm._n(val)
                    })
                  _vm.$set(
                    _vm.reward,
                    "prize_theme_id",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.update,
              ],
            },
          },
          [
            _c(
              "option",
              { attrs: { disabled: "" }, domProps: { value: null } },
              [_vm._v("Not Select")]
            ),
            _vm._l(_vm.prizepool, function (prize) {
              return _c(
                "option",
                { key: prize.id, domProps: { value: prize.id } },
                [
                  _vm._v(
                    " " + _vm._s(prize.id) + " - " + _vm._s(prize.name) + " "
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
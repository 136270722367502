var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group col-12" }, [
    _c("div", { staticClass: "form-group-row" }, [
      _c("h4", [_vm._v("Auto claim")]),
      _c("div", { staticClass: "switch-wrapper" }, [
        _c("label", { staticClass: "switch" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.theme.auto_claim,
                expression: "theme.auto_claim",
                modifiers: { number: true },
              },
            ],
            attrs: {
              type: "checkbox",
              "true-value": 1,
              "false-value": 0,
              disabled: _vm.disabled,
              name: "auto_claim",
            },
            domProps: {
              checked: Array.isArray(_vm.theme.auto_claim)
                ? _vm._i(_vm.theme.auto_claim, null) > -1
                : _vm._q(_vm.theme.auto_claim, 1),
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.theme.auto_claim,
                    $$el = $event.target,
                    $$c = $$el.checked ? 1 : 0
                  if (Array.isArray($$a)) {
                    var $$v = _vm._n(null),
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.theme, "auto_claim", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.theme,
                          "auto_claim",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.theme, "auto_claim", $$c)
                  }
                },
                function ($event) {
                  return _vm.update($event, "checkbox", _vm.theme.auto_claim)
                },
              ],
            },
          }),
          _c("span", { staticClass: "slider round" }),
        ]),
        _c("label", { attrs: { for: "switch" } }, [
          _vm._v(" " + _vm._s(_vm.theme.auto_claim === 1 ? "ON" : "OFF") + " "),
        ]),
      ]),
      _c(
        "b",
        {
          class: {
            red: _vm.theme.auto_claim === 0 && _vm.theme.pool_list.length > 1,
          },
        },
        [_vm._v(" There must be only 1 pool for auto claim ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
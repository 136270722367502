var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "panel panel-bordered mb-0" }, [
            _c("div", { staticClass: "panel-heading" }, [
              _c("h3", { staticClass: "panel-title" }, [
                _vm._v(
                  "Task " + _vm._s(_vm.item.id) + " - " + _vm._s(_vm.item.name)
                ),
              ]),
            ]),
            _c("div", { staticClass: "panel-body" }, [
              _c("div", { staticClass: "form-horizontal" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.instruction,
                              expression: "item.instruction",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", autocomplete: "off" },
                          domProps: { value: _vm.item.instruction },
                          on: {
                            change: _vm.updateInstruction,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "instruction",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.description,
                              expression: "item.description",
                            },
                          ],
                          ref: "question",
                          staticClass: "form-control",
                          attrs: { rows: "3", autocomplete: "off" },
                          domProps: { value: _vm.item.description },
                          on: {
                            change: _vm.updateDescription,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "table",
                          { staticClass: "table" },
                          [
                            _vm._l(_vm.choices, function (choice, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(index + 1) + ".")]),
                                _c("td", { attrs: { "width-250px": "" } }, [
                                  _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      "data-choice-id": choice.id,
                                    },
                                    domProps: { value: choice.answer },
                                    on: { change: _vm.updateAnswer },
                                  }),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-default",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeChoice(choice.id)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "icon md-delete" })]
                                  ),
                                ]),
                              ])
                            }),
                            _c("tr", [
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-round w-80",
                                    attrs: {
                                      disabled:
                                        _vm.choices.length === 4 ||
                                        (_vm.choices.length === 3 &&
                                          _vm.item.correct_answer == "fill"),
                                    },
                                    on: { click: _vm.addChoice },
                                  },
                                  [_vm._v(" + ")]
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("tr", [
                          _vm._m(3),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.correct_answer,
                                    expression: "item.correct_answer",
                                  },
                                ],
                                staticClass: "form-control correct-choice",
                                attrs: { "width-100px": "" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.item,
                                        "correct_answer",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.updateCorrectAnswer,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "none" } }, [
                                  _vm._v(" None "),
                                ]),
                                _vm.choices.length >= 1
                                  ? _c("option", { attrs: { value: "1" } }, [
                                      _vm._v(" 1 "),
                                    ])
                                  : _vm._e(),
                                _vm.choices.length >= 2
                                  ? _c("option", { attrs: { value: "2" } }, [
                                      _vm._v(" 2 "),
                                    ])
                                  : _vm._e(),
                                _vm.choices.length >= 3
                                  ? _c("option", { attrs: { value: "3" } }, [
                                      _vm._v(" 3 "),
                                    ])
                                  : _vm._e(),
                                _vm.choices.length >= 4
                                  ? _c("option", { attrs: { value: "4" } }, [
                                      _vm._v(" 4 "),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "fill",
                                      disabled: _vm.choices.length === 4,
                                    },
                                  },
                                  [_vm._v(" Fill ")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(4),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.submitButtonDelay,
                                  expression: "submitButtonDelay",
                                },
                              ],
                              ref: "submitButtonDelay",
                              staticClass: "form-control d-inline",
                              attrs: { "width-100px": "", type: "number" },
                              domProps: { value: _vm.submitButtonDelay },
                              on: {
                                change: _vm.updateSubmitButtonDelay,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.submitButtonDelay = $event.target.value
                                },
                              },
                            }),
                            _c("span", { staticClass: "mx-10" }, [
                              _vm._v("seconds"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        { staticClass: "row", attrs: { "my-15px": "" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.image_or_video,
                                expression: "item.image_or_video",
                              },
                            ],
                            staticClass: "mr-10",
                            attrs: {
                              type: "radio",
                              value: "none",
                              name: "image_or_video",
                              id: "image_or_video_none",
                            },
                            domProps: {
                              checked: _vm._q(_vm.item.image_or_video, "none"),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.item,
                                    "image_or_video",
                                    "none"
                                  )
                                },
                                _vm.updateImageVideoType,
                              ],
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "image_or_video_none" } },
                            [_vm._v("None")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "row", attrs: { "my-15px": "" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.image_or_video,
                                expression: "item.image_or_video",
                              },
                            ],
                            staticClass: "mr-10",
                            attrs: {
                              type: "radio",
                              value: "image",
                              name: "image_or_video",
                              id: "image_or_video_image",
                            },
                            domProps: {
                              checked: _vm._q(_vm.item.image_or_video, "image"),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.item,
                                    "image_or_video",
                                    "image"
                                  )
                                },
                                _vm.updateImageVideoType,
                              ],
                            },
                          }),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "image_or_video_image" } },
                              [_vm._v(" Image Only ")]
                            ),
                            _c("div", { staticClass: "d-block" }, [
                              _vm.item.image_url
                                ? _c("img", {
                                    staticClass: "img img-fluid",
                                    attrs: {
                                      src: _vm.item.image_url,
                                      disabled:
                                        _vm.item.image_or_video != "image",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("input", {
                              ref: "picture_url_upload_input",
                              staticClass: "form-control",
                              attrs: {
                                type: "file",
                                accept: "image/*",
                                disabled: _vm.item.image_or_video != "image",
                              },
                              on: { change: _vm.uploadImage },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "row", attrs: { "my-15px": "" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.image_or_video,
                                expression: "item.image_or_video",
                              },
                            ],
                            staticClass: "mr-10",
                            attrs: {
                              type: "radio",
                              value: "video",
                              name: "image_or_video",
                              id: "image_or_video_video",
                            },
                            domProps: {
                              checked: _vm._q(_vm.item.image_or_video, "video"),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.item,
                                    "image_or_video",
                                    "video"
                                  )
                                },
                                _vm.updateImageVideoType,
                              ],
                            },
                          }),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "image_or_video_video" } },
                              [_vm._v("VDO URL Only")]
                            ),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.video_url,
                                    expression: "item.video_url",
                                  },
                                ],
                                ref: "video_url",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  "width-300px": "",
                                  maxlength: "255",
                                  disabled: _vm.item.image_or_video != "video",
                                },
                                domProps: { value: _vm.item.video_url },
                                on: {
                                  change: _vm.updateVideoURL,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "video_url",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "panel-footer" }, [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-round btn-primary",
                    on: { click: _vm.close },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-check",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Close "),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [_vm._v("Instruction")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [_vm._v("Task Description")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [_vm._v("Choice")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h5", [_vm._v("Correct Answer")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h5", [_vm._v("Submit Button Delay")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
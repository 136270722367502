var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("Campaign"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(_vm._s(_vm.campagin_id.name)),
                ]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("User ID"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(_vm._s(_vm.reportDetail.sfdc_user_id)),
                ]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("Contact ID"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(_vm._s(_vm.reportDetail.contact_id)),
                ]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("First Name"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(_vm._s(_vm.reportDetail.firstname)),
                ]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("Last Name"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", [_vm._v(_vm._s(_vm.reportDetail.lastname))]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("No. Coins"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(_vm._s(_vm.reportDetail.total_coins)),
                ]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("No. Special"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(_vm._s(_vm.reportDetail.no_locker)),
                ]),
              ]),
              _c("div", { staticClass: "form-group col-md-3" }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("No. Exchange"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v(" " + _vm._s(_vm.reportDetail.no_exchange) + " "),
                ]),
              ]),
            ]),
            _c("hr"),
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.history,
                "fixed-header": true,
                "max-height": "300px",
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function (props) {
                    return [
                      props.column.field == "coin_plus" &&
                      props.row.type == "coin"
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(props.row.coins))]),
                          ])
                        : props.column.field == "coin_use" &&
                          props.row.type == "exchange"
                        ? _c("span", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("absolute")(props.row.coins))
                              ),
                            ]),
                          ])
                        : props.column.field == "point" &&
                          (props.row.type == "lock" ||
                            props.row.type == "exchange")
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(props.row.points))]),
                          ])
                        : props.column.field == "acc_coin" &&
                          props.row.type != "lock"
                        ? _c("span", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.list_coins[props.row.timestamp])
                              ),
                            ]),
                          ])
                        : props.column.field == "acc_point" &&
                          (props.row.type == "lock" ||
                            props.row.type == "exchange")
                        ? _c("span", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.list_points[props.row.timestamp])
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._m(1),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("User Activity")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
export const gameTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Instance Name",
    field: "name",
  },
  {
    label: "Game",
    field: "game",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Start",
    field: "start_date",
  },
  {
    label: "End",
    field: "end_date",
  },

  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "Modifield By",
    field: "modified_by_username",
  },
];

export const engagementTableColumns = [
  {
    label: "Date",
    field: "date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Game Instance ID",
    field: "instance_id",
    type: "number",
  },
  {
    label: "Game Instance Name",
    field: "name",
  },
  {
    label: "Game Type",
    field: "type",
  },
  {
    label: "No.Users",
    field: "no_users",
    type: "number",
  },
  {
    label: "No.Plays",
    field: "no_plays",
    type: "number",
  },
  {
    label: "No.Points",
    field: "no_points",
    type: "number",
  },
];

export const transactionLogTableColumns = [
  {
    label: "Event TS",
    field: "event_time",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "E-mail",
    field: "email",
  },
  {
    label: "First Name",
    field: "firstname",
  },
  {
    label: "Game Type",
    field: "type",
  },
  {
    label: "Game Instance ID",
    field: "instance_id",
  },
  {
    label: "No. Point",
    field: "point",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Transaction TS",
    field: "transaction_time",
  },
];

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Admin User</h1>
      <breadcrumb name="ADMIN_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>
        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideInactive = !hideInactive"
        >
          <i
            v-if="hideInactive"
            class="icon mdi mdi-eye"
            aria-hidden="true"
          ></i>
          <i v-else class="icon mdi mdi-eye-off" aria-hidden="true"></i>
          {{ hideInactive ? "Show all" : "Hide Inactive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :columns="columns"
              :rows="list"
              @on-row-dblclick="onRowDoubleClick"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-admin-modal" :width="650" height="auto">
      <CreateModal @submit="fetchAdmin" />
    </modal>
  </div>
</template>

<script>
import { adminService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import CreateModal from "@/views/admin/CreateModal.vue";
import { adminTableColumns } from "@/views/admin/model";

export default {
  name: "AdminList",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      admin: [],
      hideInactive: false,
      columns: adminTableColumns,
    };
  },

  components: { CreateModal },

  created() {
    this.fetchAdmin();
  },

  watch: {
    hideInactive() {
      this.filter();
    },

    admin(v) {
      this.list = v;
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    onRowDoubleClick(event) {
      this.$router
        .push({ name: "AdminEdit", params: { id: event.row.id } })
        .catch((err) => {});
    },

    async fetchAdmin() {
      const data = await adminService.getAdmins();

      this.admin = data.map((o) => {
        return { ...o, active: +o.active === 1 ? "Active" : "Inactive" };
      });
    },

    filter() {
      this.hideInactive
        ? (this.list = this.admin.filter(
            (o) => o.active.toLowerCase() !== "inactive"
          ))
        : (this.list = this.admin);
    },

    async reloadTable() {
      await this.fetchAdmin();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-admin-modal`);
    },
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Update confirmation</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-12">
              <p>
                {{ result.add_count | numberWithCommas }} items will be added.
              </p>
              <p>
                {{ result.update_count | numberWithCommas }} items will be
                updated.
              </p>
              <p>
                {{ result.remove_count | numberWithCommas }} items will be
                removed.
              </p>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="confirm"
              :disabled="result.error == 1"
            >
              <i class="icon md-check" aria-hidden="true"></i> Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["result", "name"],

  methods: {
    close() {
      this.$modal.hide(this.name);
    },

    confirm() {
      this.$emit("confirm");
      this.$modal.hide(this.name);
    },
  },
};
</script>

import qs from "qs";
import HttpRequest from "./request";

export class PrizeProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/prize`);
  }

  async getLotList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/${queryString}`);
    return data;
  }

  async getLotById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createNewLot(body) {
    const { data } = await this.create(`/`, body);
    return data;
  }

  async updateLot(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async uploadPicture(id, form) {
    const { data } = await this.upload(`/${id}/upload`, form);
    return data;
  }

  async bulkUpload(id, form) {
    const { data } = await this.upload(`/${id}/recipient/upload`, form);
    return data;
  }

  async fetchPrizeStatus(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/status${queryString}`);

    return data;
  }

  async prizeStatusDownload(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return await this.axiosInstance.get(`/status/download${queryString}`, {
      responseType: "blob",
    });
  }

  async getPrizeDetail(id) {
    const { data } = await this.get(`/status/prize/${id}`);
    return data;
  }

  async deliver(id) {
    const { data } = await this.axiosInstance.post(
      `/status/prize/${id}/deliver`
    );
    return data;
  }

  async fetchRequestTransaction(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/request_transaction${queryString}`);

    return data;
  }

  async exportRequestTransaction(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return await this.axiosInstance.get(
      `/request_transaction/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }
}

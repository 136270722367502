var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "input-group mb-2 mr-sm-2" }, [
      _c("div", { staticClass: "input-group-prepend" }, [
        _c("div", { staticClass: "input-group-text" }, [
          _c("span", { staticClass: "material-icons" }, [
            _vm._v(" " + _vm._s(_vm.icon) + " "),
          ]),
        ]),
      ]),
      _c(
        "select",
        {
          staticClass: "form-control",
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        [
          _vm._t("default"),
          _vm._l(_vm.options, function (year) {
            return _c("option", { key: year, domProps: { value: year } }, [
              _vm._v(" " + _vm._s(year) + " "),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import qs from "qs";
import HttpRequest from "./request";

export class WalletProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_WALLET_API_URL}/wallet/api`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/campaign/${queryString}`);
    return data;
  }

  async getCampaignById(id) {
    const { data } = await this.get(`/campaign/${id}`);
    return data;
  }

  async createNewCampaign(body) {
    const { data } = await this.create(`/campaign/`, body);
    return data;
  }

  async updateCampaign(id, body) {
    const { data } = await this.update(`/campaign/${id}`, body);
    return data;
  }

  async getUserReport(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/user-report${queryString}`);
    return data;
  }

  async downloadUserReport(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(`/user-report/download${queryString}`, {
      responseType: "blob",
    });
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/transaction_log${queryString}`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/transaction_log/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async addNewTask(body) {
    const { data } = await this.create(`/bulk-operation`, body);
    return data;
  }

  async bulkOperationUpload(form) {
    const { data } = await this.upload(`/bulk-operation/upload`, form);
    return data;
  }

  async bulkOperationExecute(id) {
    const { data } = await this.get(`/bulk-operation/execute/${id}`);
    return data;
  }

  async getAllBulkOperationTask(form) {
    const { data } = await this.get(`/bulk-operation`);
    return data;
  }

  async downloadTask(id) {
    return await this.axiosInstance.get(`/bulk-operation/download/${id}`, {
      responseType: "blob",
    });
  }

  async deleteTask(id) {
    const { data } = await this.delete(`/bulk-operation/${id}`);
    return data;
  }

  async updateTask(id, body) {
    const { data } = await this.update(`/bulk-operation/${id}`, body);
    return data;
  }

  async getQuotaList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/quota/${queryString}`);
    return data;
  }

  async createNewQuota(body) {
    const { data } = await this.create(`/quota`, body);
    return data;
  }

  async getAllSKU() {
    const { data } = await this.get(`/campaign/sku/getall`);
    return data;
  }

  async findMasterSku() {
    const { data } = await this.get(`/campaign/findMasterSku`);
    return data;
  }

  async createSku(body) {
    const { data } = await this.create(`/campaign/sku/campaign_sku`, body);
    return data;
  }

  async updateSkuDiscount(currentId, body) {
    const { data } = await this.update(
      `/campaign/sku/campaign_sku/${currentId}`,
      body
    );
    return data;
  }

  async deleteSkuFromDiscount(skuId, campaignId) {
    const { data } = await this.delete(`/campaign/sku/campaign_sku`, null, {
      sku_id: skuId,
      cp_id: campaignId,
    });
    return data;
  }

  async getQuotaLimitById(id) {
    const { data } = await this.get(`/quota/${id}`);
    return data;
  }

  async updateQuotaInfo(id, body) {
    const { data } = await this.update(`/quota/${id}`, body);
    return data;
  }

  async fetchAvailableCampaign(id) {
    const { data } = await this.get(`/campaign/findAvailableCampaign/${id}`);
    return data;
  }

  async getDiscountReport(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/discountreport${queryString}`);
    return data;
  }

  async downloadDiscountReport(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/discountreport/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }
}

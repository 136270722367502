<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Transaction Log</h1>
      <breadcrumb name="TRANSACTION_LOG_LIST"></breadcrumb>
      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="fromTo"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span>
                    {{ props.row.status | toUpperCase }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <div slot="table-actions">
                <div class="d-flex">
                  <button
                    @click="exportToExcel"
                    class="btn btn-default export-excel-btn"
                  >
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>

                  <button
                    class="btn btn-round mx-3"
                    :class="{
                      'btn-primary': isLuckyDraw,
                      'btn-default': !isLuckyDraw,
                    }"
                    @click="switchType('luckydraw')"
                  >
                    Lucky Draw
                  </button>

                  <button
                    class="btn btn-round mx-3"
                    :class="{
                      'btn-primary': isAuction,
                      'btn-default': !isAuction,
                    }"
                    @click="switchType('auction')"
                  >
                    Auction
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import vueGoodTableMixins from "@/mixins/vue-good-table";

import { transactionService } from "@/api/client";
import { transactionLogTableColumns } from "@/views/transaction/model";

export default {
  name: "TransactionLog",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      fromTo: [],
      transactions: [],
    };
  },

  computed: {
    columns() {
      const columns = transactionLogTableColumns;
      return this.isLuckyDraw
        ? columns.filter((o) => o.field != "is_winner")
        : columns;
    },
    isLuckyDraw() {
      return this.$route.query.type == "luckydraw";
    },
    isAuction() {
      return this.$route.query.type != "luckydraw";
    },
    logType() {
      return this.$route.query.type == "luckydraw" ? "luckydraw" : "auction";
    },
  },

  watch: {
    fromTo() {
      this.onPeriodChange();
    },

    transactions(v) {
      this.list = v;
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    switchType(type) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
            type: type || "auction",
          }),
        })
        .catch((err) => {});
    },

    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    isValidPeriod() {
      const { from, to } = this.$route.query;

      if (!from || !moment(from).isValid()) {
        return false;
      }

      if (!to || !moment(to).isValid()) {
        return false;
      }

      return true;
    },

    async fetch() {
      if (!this.isValidPeriod()) {
        return;
      }

      const params = this.getParams();

      const { data, count } = await transactionService.getTransactionLog(
        this.logType,
        params
      );

      this.transactions = data;
      this.totalRecords = count;
    },

    async exportToExcel() {
      try {
        const response = await transactionService.downloadTransactionLog(
          this.logType,
          {
            from: this.fromTo[0],
            to: this.fromTo[1],
          }
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `auctionluckydraw_txlog_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
          }),
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.export-excel-btn {
  margin-right: 180px;
}
</style>

<template>
  <div class="px-15" v-if="theme">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ theme.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('active')"
          :class="{ disabled: theme.status === 'active' }"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Active
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('inactive')"
          :class="{ disabled: theme.status === 'inactive' }"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"></i>Inactive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";

import warningChangeMixins from "../mixins/warning";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["theme"],

  mixins: [warningChangeMixins],

  computed: {
    isInuse() {
      const { environment_list } = this.theme;
      return Array.isArray(environment_list) && environment_list.length > 0;
    },
  },

  methods: {
    validate(status) {
      if (
        this.theme.status === "active" &&
        status === "inactive" &&
        this.isInuse
      ) {
        const { environment_list } = this.theme;

        const usedBy = Array.isArray(environment_list)
          ? environment_list.toString().replace(/\,/g, ", ").toUpperCase()
          : environment_list;

        throw new Error(
          `Cannot inactive this theme. The following environments are using it. ${usedBy}`
        );
      }
    },

    async update(status) {
      try {
        if (status == this.theme.status) return;

        this.validate(status);

        if (this.shouldWarning) {
          const ok = await this.warning();

          if (!ok) {
            this.name = this.theme.name;
            return;
          }
        }

        const { id } = this.theme;

        const form = { status };

        await gameSamuraiService.updateThemeConfig(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error.message || error);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="page" v-if="campaign">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="LUCKY_LOGIN_CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Campaign Information - {{ campaign.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <CampaignName :campaign="campaign" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-12">
                      <label class="form-control-label">
                        <h4>Campaign Info</h4>
                      </label>
                    </div>

                    <CampaignSummary :campaign="campaign" />
                    <CampaignMedia
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                  </div>
                  <hr />
                  <label class="form-control-label px-0">
                    <h4>Campaign Duration</h4>
                  </label>

                  <CampaignDuration
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                  <hr />
                  <UserGroup :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <h5>Play Logic</h5>
                  <TriesPerPeriod
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                  <RedirectUrl :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <BulkUploadLuckyNumber
                    :campaign="campaign"
                    :campaignId="campaign.id"
                    @uploaded="fetchCampaign"
                  />

                  <LuckyNumberRow
                    v-for="(luckyNumber, index) in campaign.lucky_number_list"
                    :key="index"
                    :index="index"
                    :campaign="campaign"
                    @updated="fetchCampaign"
                    :luckyNumber="luckyNumber"
                  />

                  <div class="row">
                    <div class="col-12">
                      <button
                        class="btn btn-defalut"
                        type="button"
                        @click="addLuckyNumber"
                        :disabled="!canAddLuckyNumber"
                      >
                        <i class="icon md-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-12">
                      <h5>Tasks</h5>
                      <div class="col-md-8">
                        <label class="switch">
                          <input
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            @change="setEnableTask"
                            :disabled="!canSwitchTaskOnOff"
                            v-model="campaign.enable_tasks"
                          />
                          <span class="slider round"></span>
                        </label>
                        <p class="mx-10 d-inline">
                          {{ +campaign.enable_tasks === 1 ? "ON" : "OFF" }}
                        </p>
                      </div>

                      <div class="task-list" v-if="campaign.enable_tasks">
                        <TaskRow
                          v-for="(task, index) in campaign.task_list"
                          :key="index"
                          :task="task"
                          :index="index"
                          :campaign="campaign"
                          @remove="removeTask"
                          @edit="showTaskDetail"
                          @updated="fetchCampaign"
                          :taskAmount="campaign.task_list.length"
                        />

                        <div class="row">
                          <div class="col-12">
                            <button
                              class="btn btn-defalut"
                              type="button"
                              @click="addTask"
                              :disabled="!canAddTask"
                            >
                              <i class="icon md-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <ConsolidatePrize
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />

                  <hr />
                  
                  <div class="row">
                    <div class="col-12">
                      <h5>Require Winner Address</h5>
                      <div class="col-md-8">
                        <label class="switch">
                          <input
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            @change="setRequireWinnerAddress"
                            :disabled="!canSwitchRequireAddress"
                            v-model="campaign.require_winner_address"
                          />
                          <span class="slider round"></span>
                        </label>
                        <p class="mx-10 d-inline">
                          {{
                            +campaign.require_winner_address === 1
                              ? "ON"
                              : "OFF"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :width="900"
      height="auto"
      name="edit-task-modal"
      @closed="onTaskModalClose"
    >
      <EditTaskModal :task="selectedTask" @close="closeTaskModal" />
    </modal>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

import TaskRow from "@/views/luckylogin/components/TaskRow.vue";
import EditTaskModal from "@/views/luckylogin/EditTaskModal.vue";
import UserGroup from "@/views/luckylogin/components/UserGroup.vue";
import RedirectUrl from "@/views/luckylogin/components/RedirectUrl.vue";
import CampaignName from "@/views/luckylogin/components/CampaignName.vue";
import CampaignMedia from "@/views/luckylogin/components/CampaignMedia.vue";
import TriesPerPeriod from "@/views/luckylogin/components/TriesPerPeriod.vue";
import LuckyNumberRow from "@/views/luckylogin/components/LuckyNumberRow.vue";
import StatusDropdown from "@/views/luckylogin/components/StatusDropdown.vue";
import CampaignSummary from "@/views/luckylogin/components/CampaignSummary.vue";
import CampaignDuration from "@/views/luckylogin/components/CampaignDuration.vue";
import ConsolidatePrize from "@/views/luckylogin/components/ConsolidatePrize.vue";
import BulkUploadLuckyNumber from "@/views/luckylogin/components/BulkUploadLuckyNumber.vue";

export default {
  name: "LuckyLoginEdit",

  data() {
    return {
      campaign: null,
      selectedTask: null,
    };
  },

  components: {
    TaskRow,
    UserGroup,
    RedirectUrl,
    CampaignName,
    CampaignMedia,
    EditTaskModal,
    TriesPerPeriod,
    LuckyNumberRow,
    StatusDropdown,
    CampaignSummary,
    CampaignDuration,
    ConsolidatePrize,
    BulkUploadLuckyNumber,
  },

  computed: {
    canSwitchRequireAddress() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },

    canSwitchTaskOnOff() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },

    canAddTask() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },

    canAddLuckyNumber() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async onTaskModalClose() {
      await this.fetchCampaign();
    },

    getLuckyNoObject(id) {
      const index = this.campaign.lucky_number_list.findIndex(
        (i) => i.id === id
      );
      return this.campaign.lucky_number_list[index];
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await luckyLoginService.getById(id);
      return;
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },

    async addLuckyNumber() {
      await luckyLoginService.addLuckyNumber(this.campaign.id);
      this.$notify("Lucky Number added.");
      await this.fetchCampaign();
    },

    async addTask() {
      await luckyLoginService.addTask(this.campaign.id);
      this.$notify("Task added.");
      await this.fetchCampaign();
    },

    removeTask(item) {
      const r = confirm(`Delete Task name ${item.name} \nPlease confirm!`);

      if (r == true) {
        const index = this.campaign.task_list.findIndex((i) => i.id == item.id);

        this.campaign.task_list.splice(index, 1);
      }
    },

    async setEnableTask() {
      await luckyLoginService.updateCampaignInfo(this.campaign.id, {
        enable_tasks: this.campaign.enable_tasks,
      });
      this.$notify("Updated.");
    },

    async setRequireWinnerAddress() {
      await luckyLoginService.updateCampaignInfo(this.campaign.id, {
        require_winner_address: this.campaign.require_winner_address,
      });
      this.$notify("Updated.");
    },

    showTaskDetail(item) {
      this.selectedTask = item;
      this.$modal.show(`edit-task-modal`);
    },

    closeTaskModal() {
      this.selectedTask = null;
      this.$modal.hide("edit-task-modal");
    },
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.img {
  &[disabled] {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}
</style>

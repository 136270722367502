import qs from "qs";
import HttpRequest from "./request";

export class ExpirationProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/expiration`);
  }

  // campaign management api
  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/campaign${queryString}`);
    return data;
  }

  async getCampaignChoice(params) {
    const { data } = await this.get(`/campaign/choice_copy_from`);
    return data;
  }

  async createCampaign(body) {
    const { data } = await this.create(`/campaign`, body);
    return data;
  }

  async getCampaignById(id) {
    const { data } = await this.get(`/campaign/${id}`);
    return data;
  }

  async updateCampaign(id, body) {
    const { data } = await this.update(`/campaign/${id}`, body);
    return data;
  }

  // expiration campaign management api
  async getExpirationCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/campaign${queryString}`);
    return data;
  }

  // Start SKU Api
  async getSKUList() {
    const { data } = await this.get(`/campaign/sku`);
    return data;
  }

  async getCampaignSKUs(id) {
    const { data } = await this.get(`/campaign/${id}/sku`);
    return data;
  }

  async updateSKU(id, form) {
    const { data } = await this.update(`/campaign/${id}/sku`, form);
    return data;
  }

  async tryUpdateSKU(id, form) {
    const { data } = await this.update(`/campaign/${id}/try_sku`, form);
    return data;
  }

  async tryUpdateSKUResult(id) {
    const { data } = await this.update(`/campaign/${id}/try_sku_result`);
    return data;
  }

  async tryConfirmUpdateSKU(id) {
    const { data } = await this.update(`/campaign/${id}/try_sku_confirm`);
    return data;
  }

  async tryCancelUpdateSKU(id) {
    const { data } = await this.update(`/campaign/${id}/try_sku_cancel`);
    return data;
  }

  async tryCancelUpdateSKU(id) {
    const { data } = await this.update(`/campaign/${id}/try_sku_cancel`);
    return data;
  }

  async getUploadStatus(id) {
    const { data } = await this.get(`/campaign/${id}/upload_status`);
    return data;
  }
}

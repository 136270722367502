export function toggleBodyClass(addRemoveClass, classNames) {
  const el = document.body;

  if (addRemoveClass === "addClass") {
    classNames.split(" ").map(function (c, i) {
      el.classList.add(c);
    });
  } else {
    classNames.split(" ").map(function (c, i) {
      el.classList.remove(c);
    });
  }
}

import { budgetService } from "@/api/client";

export default {
  props: ["budget"],

  data() {
    return {
      message: {
        error: "Update error.",
        success: "Update success.",
      },
    };
  },
  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.budget.status);
    },
  },
  methods: {
    // event: original event
    // type: input type
    // model: input v-model
    async update(event, type, model) {
      try {
        const { id } = this.budget;

        const name = event.target.name;
        const value = model != undefined ? model : event.target.value;

        const form = {};
        form[name] = value;

        await budgetService.updateBudget(id, form);

        this.$emit("updated");
        this.$notify(this.message.success);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || this.message.error);

        this.$emit("error");
      }
    },

    initial() {
      return;
    },
  },

  mounted() {
    this.initial();
  },
};

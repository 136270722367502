var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("input", {
      directives: [
        {
          name: "money",
          rawName: "v-money",
          value: _vm.money,
          expression: "money",
        },
        {
          name: "model",
          rawName: "v-model.lazy",
          value: _vm.amt_max,
          expression: "amt_max",
          modifiers: { lazy: true },
        },
      ],
      ref: "amt_max",
      staticClass: "form-control text-right",
      attrs: { min: "0", "width-300px": "", disabled: !_vm.canEdit },
      domProps: { value: _vm.amt_max },
      on: {
        blur: _vm.update,
        change: function ($event) {
          _vm.amt_max = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Quota Amount (coins)")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page vld-parent" v-if="theme">
    <loading :active="isLoading" :is-full-page="false" loader="bars" />
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Theme</h1>
          <breadcrumb
            name="GAME_SAMURAI_THEMES_EDIT"
            :withLast.sync="theme.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="generatePreviewUrl"
            >
              <i
                class="icon mdi mdi-controller mdi-18px mx-2"
                aria-hidden="true"
              ></i>
              Preview
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="goBack"
            >
              <i
                class="icon mdi mdi-chevron-left mdi-18px"
                aria-hidden="true"
              ></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">Theme Information - {{ theme.id }}</h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <div class="form-horizontal" v-if="theme">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <ThemeName :theme="theme" @updated="fetchTheme" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown :theme="theme" @updated="fetchTheme" />
                    </div>
                  </div>
                  <hr />

                  <h5>Background & Elements</h5>
                  <PictureControl
                    :theme="theme"
                    @updated="fetchTheme"
                    :config="fields.background_picture"
                  />
                  <br />

                  <PictureControl
                    :theme="theme"
                    @updated="fetchTheme"
                    :config="fields.title"
                  />
                  <br />

                  <PictureControl
                    :theme="theme"
                    @updated="fetchTheme"
                    :config="fields.summary_picture"
                  />
                  <br />

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.button_leaderboard_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.button_play_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.button_play_disabled_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.button_x_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.clock_picture"
                      />
                    </div>
                  </div>

                  <h5>Bomb Animation</h5>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.bomb1_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.bomb2_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.bomb3_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.explode_picture"
                      />
                    </div>
                  </div>

                  <h5>VIP Animation</h5>
                  <PictureControl
                    :theme="theme"
                    @updated="fetchTheme"
                    :config="fields.flash_picture"
                  />
                  <br />

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_full_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_break_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_top_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_bottom_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_left_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_right_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_top_right_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_bottom_left_picture"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_top_left_picture"
                      />
                    </div>
                    <div class="col-md-6">
                      <PictureControl
                        :theme="theme"
                        @updated="fetchTheme"
                        :config="fields.vip_bottom_right_picture"
                      />
                    </div>
                  </div>

                  <div v-for="n in 3" :key="n">
                    <h5>SKU{{ n }} Animation</h5>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_full_picture`]"
                        />
                      </div>
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_break_picture`]"
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_top_picture`]"
                        />
                      </div>
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_bottom_picture`]"
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_left_picture`]"
                        />
                      </div>
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_right_picture`]"
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_top_right_picture`]"
                        />
                      </div>
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_bottom_left_picture`]"
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_top_left_picture`]"
                        />
                      </div>
                      <div class="col-md-6">
                        <PictureControl
                          :theme="theme"
                          @updated="fetchTheme"
                          :config="fields[`sku${n}_bottom_right_picture`]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal width="430px" height="932px" name="preview-modal">
      <iframe
        :src="previewUrl"
        title="Samurai Demo"
        width="100%"
        height="100%"
        style="border: none"
      ></iframe>
    </modal>

    <modal width="320px" height="auto" name="preview-choice-modal">
      <PreviewChoiceModal :url="previewUrl" @selected="preview" />
    </modal>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ThemeName from "./components/ThemeName.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import PictureControl from "./components/PictureControl.vue";
import PreviewChoiceModal from "./components/PreviewChoiceModal.vue";

import fields from "./model/fields";

export default {
  name: "SamuraiThemeEdit",

  data() {
    return {
      theme: null,
      fields: fields,
      previewUrl: "",
      isLoading: false,
    };
  },

  components: {
    Loading,
    ThemeName,
    StatusDropdown,
    PictureControl,
    PreviewChoiceModal,
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchTheme() {
      const { id } = this.$route.params;
      this.theme = await gameSamuraiService.getThemeConfig(id);
    },

    async generatePreviewUrl() {
      this.isLoading = true;

      try {
        const { id } = this.$route.params;
        const { url } = await gameSamuraiService.getPreviewUrl(id);
        this.previewUrl = url;

        this.showPreviewChoice();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || error);
      } finally {
        this.isLoading = false;
      }
    },

    async preview(viewer) {
      if (viewer === "iframe") {
        this.$modal.show(`preview-modal`);
        this.$modal.hide(`preview-choice-modal`);
      } else if (viewer === "tab") {
        window.open(this.previewUrl, "_blank_tab");
      } else if (viewer === "window") {
        window.open(this.previewUrl, "_blank_window", "width=430,height=932");
      }
    },

    showPreviewChoice() {
      this.$modal.show(`preview-choice-modal`);
    },
  },

  async created() {
    await this.fetchTheme();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.page-header-actions {
  right: 0px;

  gap: 1rem;
  display: flex;

  button {
    gap: 0.5rem;
    display: flex;
    align-items: center;

    i {
      margin-bottom: 0px;
    }
  }
}

.vld-overlay.is-active {
  height: 100vh;
  position: fixed;
}
</style>

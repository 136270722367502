var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _c("h3", { staticClass: "panel-title" }, [
            _vm._v("Quiz Step-" + _vm._s(_vm.quizData.step)),
          ]),
        ]),
        _c("div", { staticClass: "panel-body" }, [
          _c("form", { staticClass: "form-horizontal row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "form-group row" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question,
                        expression: "question",
                      },
                    ],
                    ref: "question",
                    staticClass: "form-control form__input",
                    attrs: { rows: "4", autocomplete: "off" },
                    domProps: { value: _vm.question },
                    on: {
                      change: _vm.updateQuestion,
                      blur: _vm.onQuestionInputBlur,
                      focus: _vm.onQuestionInputFocus,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.question = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "table",
                    { staticClass: "table table-responsive table-borderless" },
                    [
                      _vm._m(1),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.choices, function (choice, idx) {
                            return _c(
                              "tr",
                              { key: idx, staticClass: "choice" },
                              [
                                _c("td", [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "form-control-label form__label",
                                    },
                                    [_c("h5", [_vm._v(_vm._s(idx + 1))])]
                                  ),
                                ]),
                                _c("td", [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "input-group mb-3" },
                                      [
                                        _c("input", {
                                          ref: "choice" + (idx + 1),
                                          refInFor: true,
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            "data-choice-id": idx + 1,
                                          },
                                          domProps: { value: choice },
                                          on: { blur: _vm.updateChoice },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "input-group-append" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn",
                                                attrs: {
                                                  type: "button",
                                                  disabled:
                                                    _vm.choices.length <= 1 ||
                                                    +_vm.correct_choice ===
                                                      idx + 1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeChoice(idx)
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "icon md-delete",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          _c("tr", [
                            _c("td"),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round w-80",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.choices.length === 4,
                                  },
                                  on: { click: _vm.addNewChoice },
                                },
                                [_vm._v(" + ")]
                              ),
                            ]),
                          ]),
                          _vm._m(2),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._m(3),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.correct_choice,
                                      expression: "correct_choice",
                                    },
                                  ],
                                  staticClass: "form-control correct-choice",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.correct_choice = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.onCorrectChoiceChange,
                                    ],
                                  },
                                },
                                _vm._l(_vm.choices, function (choice, idx) {
                                  return _c(
                                    "option",
                                    { key: idx, domProps: { value: idx + 1 } },
                                    [_vm._v(" " + _vm._s(idx + 1) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "radio-custom radio-primary" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.image_video,
                        expression: "image_video",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      value: "none",
                      id: "question_media_none",
                    },
                    domProps: { checked: _vm._q(_vm.image_video, "none") },
                    on: {
                      change: [
                        function ($event) {
                          _vm.image_video = "none"
                        },
                        _vm.updateImageVideoType,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "question_media_none" } }, [
                    _vm._v("None"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "radio-custom radio-primary pb-15" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.image_video,
                        expression: "image_video",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      value: "image",
                      id: "question_media_image",
                    },
                    domProps: { checked: _vm._q(_vm.image_video, "image") },
                    on: {
                      change: [
                        function ($event) {
                          _vm.image_video = "image"
                        },
                        _vm.updateImageVideoType,
                      ],
                    },
                  }),
                  _vm._m(4),
                ]),
                _c("div", { staticClass: "pl-30" }, [
                  _c("div", { staticClass: "img-url-wrapper" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.image_url },
                    }),
                  ]),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "file",
                      accept: "image/*",
                      disabled: _vm.image_video != "image",
                    },
                    on: { change: _vm.uploadImage },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "radio-custom radio-primary pb-15" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.image_video,
                        expression: "image_video",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      value: "video",
                      id: "question_media_video",
                    },
                    domProps: { checked: _vm._q(_vm.image_video, "video") },
                    on: {
                      change: [
                        function ($event) {
                          _vm.image_video = "video"
                        },
                        _vm.updateImageVideoType,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "question_media_video" } }, [
                    _vm._v("Video"),
                  ]),
                ]),
                _c("div", { staticClass: "pl-30" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.video_url,
                        expression: "video_url",
                      },
                    ],
                    ref: "video_url",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.image_video != "video",
                    },
                    domProps: { value: _vm.video_url },
                    on: {
                      change: _vm.updateVideoURL,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.video_url = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-end" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [_vm._v(" CLOSE ")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Question")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [_c("tr", [_c("th", [_vm._v("Choice")]), _c("th")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "2" } }, [_c("hr")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label form__label" }, [
      _c("h5", [_vm._v("Correct Answer")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "question_media_image" } }, [
      _vm._v(" Image - "),
      _c("span", [_vm._v(" Recommended size 335x189 px / less than 1 MB ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
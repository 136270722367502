<template>
  <div class="row mb-15" v-if="campaign">
    <div class="col-6">
      <p d-inline-block width-150px>No.Tries per period</p>
      <input
        width-150px
        type="number"
        min="0"
        max="200"
        @change="update"
        :disabled="!canEdit"
        ref="tries_per_period"
        v-model.number="campaign.tries_per_period"
        class="form-control mx-10 d-inline"
      />
      times
    </div>
    <div class="col-6">
      Period duration
      <input
        min="1"
        width-150px
        type="number"
        @change="update"
        :disabled="!canEdit"
        ref="period_duration"
        v-model.number="campaign.period_duration"
        class="form-control mx-10 d-inline"
      />
      days
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  mounted() {
    const { tries_per_period, period_duration } = this.campaign;
    this.oldValue = { tries_per_period, period_duration };
  },

  methods: {
    async validate() {
      if (isNaN(parseInt(this.campaign.tries_per_period))) {
        await this.$dialogs.alert("Tries per period ต้องเป็นตัวเลขเท่านั้น");
        this.campaign.tries_per_period = this.oldValue.tries_per_period;
        this.$refs.tries_per_period.focus();
        return false;
      }

      if (+this.campaign.tries_per_period < 1) {
        await this.$dialogs.alert("Tries per period ต้องมากกว่า 0");
        this.campaign.tries_per_period = this.oldValue.tries_per_period;
        this.$refs.tries_per_period.focus();
        return false;
      }

      if (+this.campaign.tries_per_period > 200) {
        await this.$dialogs.alert("Tries per period ต้องไม่เกิน 200");
        this.campaign.tries_per_period = this.oldValue.tries_per_period;
        this.$refs.tries_per_period.focus();
        return false;
      }

      if (isNaN(parseInt(this.campaign.period_duration))) {
        await this.$dialogs.alert("Period duration ต้องเป็นตัวเลขเท่านั้น");
        this.campaign.period_duration = this.oldValue.period_duration;
        this.$refs.period_duration.focus();
        return false;
      }

      if (+this.campaign.period_duration < 0) {
        await this.$dialogs.alert("Period duration ต้องมากกว่า 0");
        this.campaign.period_duration = this.oldValue.period_duration;
        this.$refs.period_duration.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.validate();

        if (!formValid) return;

        const { id, tries_per_period, period_duration } = this.campaign;

        const form = {
          period_duration: +period_duration,
          tries_per_period: +tries_per_period,
        };

        await luckyLoginService.updateCampaignInfo(id, form);

        this.oldValue = { period_duration, tries_per_period };

        this.$emit("updated");
        this.$notify(`No.Tries per period Updated.`);
      } catch (error) {
        this.$dialogs.alert(`No.Tries per period update error.`);
      }
    },
  },
};
</script>

<style></style>

export const transactionLogTableColumns = [
  {
    label: "Timestamp",
    field: "ts",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Campaign ID",
    field: "cp_id",
    type: "number",
  },
  {
    label: "Number",
    field: "lucky_num",
    type: "number",
  },
  {
    label: "Prize Name",
    field: "prize_name",
  },
  {
    label: "App Ref ID",
    field: "app_ref_id",
    type: "number",
  },
  {
    label: "Reward Request ID",
    field: "reward_request_id",
  },
  {
    label: "Reward Request Status",
    field: "reward_request_status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Prize Theme ID",
    field: "prize_theme_id",
  },
  {
    label: "Remaining Prize",
    field: "remaining_prize",
  },
  {
    label: "SF Points Granted",
    field: "sf_point_grant",
  },
  {
    label: "SF Grant status",
    field: "sf_grant_status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Coupon Value",
    field: "coupon_value",
  },
  {
    label: "Coupon App Ref",
    field: "coupon_app_ref",
  },
];

<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { leaderBoardService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["campaign"],

  methods: {
    validate(status) {
      const {
        name,
        instance,
        end_date,
        start_date,
        post_date,
        delist_date,
        banner_picture,
        num_rank_reward,
        last_reward_upload,
      } = this.campaign;

      switch (status) {
        case "scheduled":
          // Campaign Name ต้องมี
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign name.");
            return false;
          }

          // มีรูป Banner picture
          if (!banner_picture) {
            this.$dialogs.alert("กรุณาระบุ Banner picture.");
            return false;
          }

          // มีการเลือก Game Instances อย่างน้อย 1 game
          if (instance.length <= 0) {
            this.$dialogs.alert("กรุณาระบุ Game Instances อย่างน้อย 1 game");
            return false;
          }

          // มีการ upload Ranks & Rewards
          if (!last_reward_upload || !num_rank_reward) {
            this.$dialogs.alert("กรุณา upload Ranks & Rewards");
            return false;
          }

          // Start Date
          // ถ้าระบุวันที่ start date ไม่ใช่วันที่ในอนาคต
          // https://stackoverflow.com/a/5646753
          if (
            moment(
              new Date(start_date.replace(/-/g, "/")).setSeconds(0, 0)
            ).diff(new Date().setSeconds(0, 0), "minutes") < 1
          ) {
            this.$dialogs.alert(
              "Start date ต้องเป็นวันที่ในอนาคตเท่านั้น"
            );

            return false;
          }

          // ถ้าระบุวันที่ start date มากกว่าวันที่ end date
          if (
            moment(start_date).diff(
              new Date(end_date.replace(/-/g, "/")),
              "minutes"
            ) >= 0 &&
            end_date != null
          ) {
            this.$dialogs.alert("วันเริ่มต้นต้องน้อยกว่าวันสิ้นสุด campaign");

            return false;
          }

          // ถ้าไม่ระบุวันที่ start date
          if (!start_date) {
            this.$dialogs.alert("กรุณาระบุค่า Start date");

            return false;
          }

          // ถ้าไม่ระบุวันที่ end date
          if (!end_date) {
            this.$dialogs.alert("กรุณาระบุค่า End date");

            return false;
          }

          // ถ้าไม่ระบุวันที่ post_date
          if (!post_date) {
            this.$dialogs.alert("กรุณาระบุค่า Post date");

            return false;
          }

          // ถ้าไม่ระบุวันที่ delist_date
          if (!delist_date) {
            this.$dialogs.alert("กรุณาระบุค่า Delist date");

            return false;
          }
          break;
      }

      return true;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await leaderBoardService.updateCampaign(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

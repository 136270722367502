<template>
  <div id="import-progress" v-if="campaign && canImportProgress">
    <a :href="exampleLink" class="btn btn-link"> Click to download example </a>

    <label
      :disabled="!canUpload"
      class="btn btn-primary btn-upload"
      for="user-target-reward-file-upload"
    >
      Import Progress
    </label>

    <input
      type="file"
      :disabled="!canUpload"
      @change="onFileSelected"
      class="input-select-file"
      id="user-target-reward-file-upload"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
    <modal name="try-upload-progress-modal" :width="900" height="auto">
      <TryUploadModal :campaign="campaign" :result="result" @confirm="upload" />
    </modal>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import TryUploadModal from "../TryUploadModal.vue";

export default {
  props: ["campaign", "columnConfig"],

  components: { TryUploadModal },

  data() {
    return {
      result: null,
    };
  },

  computed: {
    canUpload() {
      return ["scheduled", "ongoing"].includes(this.campaign.status);
    },

    canImportProgress() {
      return this.columnConfig.progress_upload === 1;
    },
    exampleLink() {
      const userType = this.campaign.user_type;
      
      const url =
        "https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/progressbar";
      switch (userType) {
        case "isms_code":
          return `${url}/leaderboard-test-progress-keen-nondte-10apr2024.xlsx?dt=${new Date().getTime()}`;
        case "contact_id":
          return `${url}/leaderboard-test-progress-keen-dte-10apr2024.xlsx?dt=${new Date().getTime()}`;
      }
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      event.target.value = null;

      this.columnConfig.progress_upload_try === 1
        ? this.tryUpload()
        : this.upload();
    },

    async tryUpload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        this.result = await progressBarService.tryUploadProgress(
          this.campaign.id,
          form
        );

        this.$modal.show(`try-upload-progress-modal`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async upload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        await progressBarService.uploadProgressUpdate(this.campaign.id, form);

        this.$emit("updated");
        this.$root.$emit("IMPORT_PROGRESS_UPDATED");
        this.$notify("Upload Progress success");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Progress error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#import-progress {
  display: inline-block;
}

.input-select-file {
  width: 0px;
  visibility: hidden;
}

.btn-upload {
  margin-bottom: 0px;

  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
  }
}
</style>

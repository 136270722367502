var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _vm.luckyNumber
        ? _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", { staticStyle: { "vertical-align": "middle" } }, [
                  _vm._v("No. " + _vm._s(_vm.index + 1)),
                ]),
                _c("td", { attrs: { align: "left" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start gap-1rem",
                    },
                    [
                      _c("label", { staticClass: "mb-0" }, [
                        _vm._v("Lucky No."),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.luckyNumber.lucky_no,
                            expression: "luckyNumber.lucky_no",
                          },
                        ],
                        ref: "lucky_no",
                        staticClass: "form-control",
                        attrs: {
                          min: "1",
                          "width-100px": "",
                          type: "number",
                          disabled: !_vm.canEdit,
                        },
                        domProps: { value: _vm.luckyNumber.lucky_no },
                        on: {
                          change: function ($event) {
                            return _vm.update("lucky_no")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.luckyNumber,
                              "lucky_no",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _c("td", { attrs: { colspan: "2", align: "right" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-defautl",
                      attrs: { type: "button", disabled: !_vm.canEdit },
                      on: { click: _vm.remove },
                    },
                    [
                      _c("i", {
                        staticClass: "icon md-delete",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td"),
                _c("td", [
                  _vm._m(0),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "lucky-number-picture-form-upload" },
                      [
                        _c("div", { staticClass: "image-form-wrapper" }, [
                          _c("div", { staticClass: "image-preview" }, [
                            _vm.luckyNumber.picture
                              ? _c("img", {
                                  attrs: { src: _vm.luckyNumber.picture },
                                })
                              : _c("div", { staticClass: "image-placeholder" }),
                          ]),
                          _c("input", {
                            ref: "lucky-number-picure-" + _vm.luckyNumber.id,
                            staticClass: "form-control",
                            attrs: {
                              type: "file",
                              accept: "image/*",
                              disabled:
                                !_vm.canEdit ||
                                _vm.luckyNumber.reward_type == "sfdc",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.upload(_vm.luckyNumber.id)
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c("div", { staticClass: "reward-choice-list" }, [
                    _c("div", { staticClass: "reward-choice" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center gap-1rem" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.luckyNumber.reward_type,
                                expression: "luckyNumber.reward_type",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "radio",
                              value: "prizepool",
                              disabled: !_vm.canEdit,
                              id:
                                "reward-choice-prizepool-" +
                                _vm.luckyNumber.lucky_no,
                            },
                            domProps: {
                              checked:
                                _vm.luckyNumber.reward_type == "prizepool",
                              checked: _vm._q(
                                _vm.luckyNumber.reward_type,
                                _vm._n("prizepool")
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.$set(
                                    _vm.luckyNumber,
                                    "reward_type",
                                    _vm._n("prizepool")
                                  )
                                },
                                function ($event) {
                                  return _vm.update(
                                    "reward_type",
                                    _vm.luckyNumber
                                  )
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "choice-label",
                              attrs: {
                                for:
                                  "reward-choice-prizepool-" +
                                  _vm.luckyNumber.lucky_no,
                              },
                            },
                            [_vm._v(" Prize Pool ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: {
                              for:
                                "reward-choice-theme_id_" +
                                _vm.luckyNumber.lucky_no,
                            },
                          },
                          [_vm._v(" Prize Theme ID ")]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.luckyNumber.theme_id,
                                expression: "luckyNumber.theme_id",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "theme_id",
                              disabled:
                                !_vm.canEdit ||
                                _vm.luckyNumber.reward_type != "prizepool",
                              id:
                                "reward-choice-theme_id_" +
                                _vm.luckyNumber.lucky_no,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.luckyNumber,
                                    "theme_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.update("theme_id", _vm.luckyNumber)
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { disabled: "" },
                                domProps: { value: null || 0 || undefined },
                              },
                              [_vm._v(" Not Select ")]
                            ),
                            _vm._l(_vm.prizepool, function (prize) {
                              return _c(
                                "option",
                                {
                                  key: prize.id,
                                  domProps: { value: prize.id },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(prize.id) +
                                      " - " +
                                      _vm._s(prize.name) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "reward-choice" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center gap-1rem" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.luckyNumber.reward_type,
                                expression: "luckyNumber.reward_type",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "radio",
                              value: "sfdc",
                              disabled: !_vm.canEdit,
                              id:
                                "reward-choice-sfdc-" +
                                _vm.luckyNumber.lucky_no,
                            },
                            domProps: {
                              checked: _vm.luckyNumber.reward_type === "sfdc",
                              checked: _vm._q(
                                _vm.luckyNumber.reward_type,
                                _vm._n("sfdc")
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.$set(
                                    _vm.luckyNumber,
                                    "reward_type",
                                    _vm._n("sfdc")
                                  )
                                },
                                function ($event) {
                                  return _vm.update(
                                    "reward_type",
                                    _vm.luckyNumber
                                  )
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "choice-label",
                              attrs: {
                                for:
                                  "reward-choice-sfdc-" +
                                  _vm.luckyNumber.lucky_no,
                              },
                            },
                            [_vm._v(" SF Point ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "d-flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.luckyNumber.sfdc_point,
                              expression: "luckyNumber.sfdc_point",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            min: "0",
                            max: "10000",
                            type: "number",
                            name: "sfdc_point",
                            disabled:
                              !_vm.canEdit ||
                              _vm.luckyNumber.reward_type != "sfdc",
                          },
                          domProps: { value: _vm.luckyNumber.sfdc_point },
                          on: {
                            change: function ($event) {
                              return _vm.update("sfdc_point")
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.luckyNumber,
                                "sfdc_point",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "reward-choice" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center gap-1rem" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.luckyNumber.reward_type,
                                expression: "luckyNumber.reward_type",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "radio",
                              value: "other",
                              disabled: !_vm.canEdit,
                              id:
                                "reward-choice-other-" +
                                _vm.luckyNumber.lucky_no,
                            },
                            domProps: {
                              checked: _vm.luckyNumber.reward_type === "other",
                              checked: _vm._q(
                                _vm.luckyNumber.reward_type,
                                _vm._n("other")
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.$set(
                                    _vm.luckyNumber,
                                    "reward_type",
                                    _vm._n("other")
                                  )
                                },
                                function ($event) {
                                  return _vm.update(
                                    "reward_type",
                                    _vm.luckyNumber
                                  )
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "choice-label",
                              attrs: {
                                for:
                                  "reward-choice-other-" +
                                  _vm.luckyNumber.lucky_no,
                              },
                            },
                            [_vm._v(" Prize ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column gap-1rem" },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("label", { staticClass: "input-label" }, [
                              _vm._v("Reward Name"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.luckyNumber.reward_name,
                                  expression: "luckyNumber.reward_name",
                                },
                              ],
                              ref: "reward_name",
                              staticClass: "form-control",
                              attrs: {
                                rows: "2",
                                disabled:
                                  !_vm.canEdit ||
                                  _vm.luckyNumber.reward_type != "other",
                              },
                              domProps: { value: _vm.luckyNumber.reward_name },
                              on: {
                                change: function ($event) {
                                  return _vm.update("reward_name")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.luckyNumber,
                                    "reward_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.luckyNumber.is_e_reward,
                                      expression: "luckyNumber.is_e_reward",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "lucky_number_" + _vm.luckyNumber.id,
                                    disabled:
                                      !_vm.canEdit ||
                                      _vm.luckyNumber.reward_type != "other",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.luckyNumber.is_e_reward
                                    )
                                      ? _vm._i(
                                          _vm.luckyNumber.is_e_reward,
                                          null
                                        ) > -1
                                      : _vm.luckyNumber.is_e_reward,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.luckyNumber.is_e_reward,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.luckyNumber,
                                                "is_e_reward",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.luckyNumber,
                                                "is_e_reward",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.luckyNumber,
                                            "is_e_reward",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.update("is_e_reward")
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      for: "lucky_number_" + _vm.luckyNumber.id,
                                    },
                                  },
                                  [_vm._v(" E-Reward Code ")]
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.luckyNumber.e_reward_code,
                                    expression: "luckyNumber.e_reward_code",
                                  },
                                ],
                                ref: "e_reward_code",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  disabled:
                                    !_vm.isEReward ||
                                    !_vm.canEdit ||
                                    _vm.luckyNumber.reward_type != "other",
                                },
                                domProps: {
                                  value: _vm.luckyNumber.e_reward_code,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.update("e_reward_code")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.luckyNumber,
                                      "e_reward_code",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _vm._v(" Picture "),
      _c("span", [_vm._v("Recommended size 300x300 pixel")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
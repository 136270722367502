import moment from "moment";

export default {
  methods: {
    validate(field) {
      switch (field) {
        case "name":
          if (this.campaign.name == "") {
            this.$dialogs.alert("ค่า name เป็นค่าว่างไม่ได้");
            this.campaign.name = this.previousValue.name;
            this.$refs.campaignName.focus();
            return false;
          }
          break;

        case "description":
          return true;
          break;

        case "start_date":
          const startDate = moment(this.campaign.start_date);
          if (this.isPastDate(startDate)) {
            this.$dialogs.alert("Start Date ต้องเป็นเวลาในอนาคต");
            this.campaign.start_date = this.previousValue.start_date || null;
            this.$refs.startDate.focus();
            return false;
          }
          if (this.isPastDate(moment(this.campaign.end_date), startDate)) {
            this.$dialogs.alert("Start Date ต้องไม่มากกว่า End Date");
            this.campaign.start_date = this.previousValue.start_date || null;
            this.$refs.startDate.focus();
            return false;
          }
          break;

        case "end_date":
          const endDate = moment(this.campaign.end_date);
          if (this.isPastDate(endDate, this.campaign.start_date)) {
            this.$dialogs.alert(
              "End Date ต้องเป็นเวลาหลังจาก Start Date แล้วเท่านั้น"
            );
            this.campaign.end_date = this.previousValue.end_date || null;
            this.$refs.endDate.focus();
            return false;
          }
          break;

        case "conv_point":
          if (
            +this.campaign.conv_point < 1 ||
            +this.campaign.conv_point > 999
          ) {
            this.$dialogs.alert("ค่า SF Point ต้องอยู่ระหว่าง 1 - 999");
            this.campaign.conv_point = +this.previousValue.conv_point;
            this.$refs.convPoint.focus();
            return false;
          }
          break;

        case "conv_coin":
          if (+this.campaign.conv_coin < 1 || +this.campaign.conv_coin > 999) {
            this.$dialogs.alert("ค่า Coin ต้องอยู่ระหว่าง 1 - 999");
            this.campaign.conv_coin = +this.previousValue.conv_coin;
            this.$refs.convCoin.focus();
            return false;
          }
          break;

        case "discount_coin":
          if (
            +this.campaign.discount_coin < 1 ||
            +this.campaign.discount_coin > 999
          ) {
            this.$dialogs.alert("ค่า Discount Coin ต้องอยู่ระหว่าง 1 - 999");
            this.campaign.discount_coin = +this.previousValue.discount_coin;
            this.$refs.discountCoin.focus();
            return false;
          }
          break;

        case "discount_baht":
          if (
            +this.campaign.discount_baht < 1 ||
            +this.campaign.discount_baht > 999
          ) {
            this.$dialogs.alert("ค่า Discount Baht ต้องอยู่ระหว่าง 1 - 999");
            this.campaign.discount_baht = +this.previousValue.discount_baht;
            this.$refs.discountBaht.focus();
            return false;
          }
          break;

        case "markerup_percent":
          if (
            +this.campaign.markerup_percent < 0 ||
            +this.campaign.markerup_percent > 1000
          ) {
            this.$dialogs.alert(
              "ค่า Markup percentage ต้องอยู่ระหว่าง 0 - 1000"
            );
            this.campaign.markerup_percent = +this.previousValue
              .markerup_percent;
            this.$refs.markerupPercent.focus();
            return false;
          }
          break;

        case "can_scanqr":
          return true;
          break;

        case "get_discount":
          return true;
          break;

        case "can_salesdiscount":
          return true;
          break;

        default:
          if (!this.campaign[field]) return false;
          break;
      }

      return true;
    },
  },
};

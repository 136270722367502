var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page vld-parent" },
    [
      _c("loading", {
        attrs: { active: _vm.isLoading, "is-full-page": false },
      }),
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h1", { staticClass: "page-title" }, [_vm._v("CCB2B Log")]),
          _c("breadcrumb", {
            attrs: { name: "E_COUPON_CCB2B_LOG_DETAIL", withLast: _vm.logId },
            on: {
              "update:withLast": function ($event) {
                _vm.logId = $event
              },
              "update:with-last": function ($event) {
                _vm.logId = $event
              },
            },
          }),
          _c("div", { staticClass: "page-header-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.refreshStatus },
              },
              [
                _c("i", {
                  staticClass: "icon md-refresh",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Refresh status "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.goBack },
              },
              [
                _c("i", {
                  staticClass: "icon md-chevron-left",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Back "),
              ]
            ),
          ]),
          _c("div", { staticClass: "page-header-actions" }),
        ],
        1
      ),
      _c("div", { staticClass: "page-content page-content-table" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "panel" }, [
            _c(
              "div",
              { staticClass: "panel-body p-0" },
              [
                _c(
                  "vue-good-table",
                  {
                    ref: "table",
                    attrs: {
                      mode: "remote",
                      columns: _vm.columns,
                      rows: _vm.list,
                      totalRows: _vm.totalRecords,
                      "sort-options": _vm.sortOption,
                      "pagination-options": _vm.pagination,
                      "search-options": _vm.searchOption,
                    },
                    on: {
                      "on-sort-change": _vm.onSortChange,
                      "on-page-change": _vm.onPageChange,
                      "on-search": _vm.onSearch,
                      "on-row-click": _vm.onRowClick,
                      "on-per-page-change": _vm.onPerPageChange,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        attrs: { slot: "table-actions" },
                        slot: "table-actions",
                      },
                      [
                        _c("div", { staticClass: "Excel" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: { click: _vm.exportToExcel },
                            },
                            [
                              _c("i", { staticClass: "fa fa-file-excel-o" }),
                              _vm._v(" Export to Excel "),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: { name: "ccb2b-log-detail-modal", width: 500, height: "auto" },
        },
        [
          _c("LogDetailModal", {
            attrs: { detail: _vm.detail },
            on: { cancelled: _vm.fetch, retried: false },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-10" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _vm._v(" Date "),
          ]),
          _c(
            "div",
            { staticClass: "d-inline" },
            [
              _c("date-picker", {
                staticStyle: { "max-width": "150px" },
                attrs: {
                  type: "date",
                  clearable: false,
                  format: "DD/MM/YYYY",
                  disabled: !_vm.canEditDateTime,
                  "value-type": "YYYY-MM-DD HH:mm",
                  "disabled-date": _vm.disabledStartDate,
                  "disabled-time": _vm.disabledStartTime,
                },
                on: { change: _vm.update },
                model: {
                  value: _vm.round.start_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.round, "start_date", $$v)
                  },
                  expression: "round.start_date",
                },
              }),
            ],
            1
          ),
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _vm._v(" Start Time "),
          ]),
          _c("date-picker", {
            staticStyle: { "max-width": "150px" },
            attrs: {
              type: "time",
              format: "HH:mm",
              clearable: false,
              disabled: !_vm.canEditTime,
              "value-type": "YYYY-MM-DD HH:mm",
            },
            on: { change: _vm.update },
            model: {
              value: _vm.round.start_date,
              callback: function ($$v) {
                _vm.$set(_vm.round, "start_date", $$v)
              },
              expression: "round.start_date",
            },
          }),
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _vm._v(" End Time "),
          ]),
          _c("date-picker", {
            staticStyle: { "max-width": "150px" },
            attrs: {
              type: "time",
              format: "HH:mm",
              clearable: false,
              disabled: !_vm.canEditTime,
              "value-type": "YYYY-MM-DD HH:mm",
              "disabled-time": _vm.disabledEndTime,
            },
            on: { change: _vm.update },
            model: {
              value: _vm.round.end_date,
              callback: function ($$v) {
                _vm.$set(_vm.round, "end_date", $$v)
              },
              expression: "round.end_date",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Round Duration")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
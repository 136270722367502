import Result from "@/views/result/Index.vue";
import ResultList from "@/views/result/List.vue";

export default {
  path: "/result",
  name: "Result",
  component: Result,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/",
      name: "ResultList",
      component: ResultList,
    },
  ],
};

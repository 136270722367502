import HttpRequest from "./request";

export class LIFFAccessProvinder extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/liff_access/config`);
  }

  async getConfig() {
    const { data } = await this.get(``);
    return data;
  }

  async updateConfig(body) {
    const { data } = await this.update(``, body);
    return data;
  }
}

<template>
  <div class="panel panel-bordered mb-0">
    <div class="panel-heading">
      <h3 class="panel-title">Create Samurai Theme</h3>
    </div>
    <div class="panel-body">
      <form class="form-horizontal">
        <div
          class="form-group row"
          :class="{ '.is-invalid': $v.theme.name.$error }"
        >
          <label class="col-md-0 form-control-label form__label"
            ><h5>Theme Name</h5>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              ref="name"
              class="form-control form__input"
              v-model="theme.name"
              autocomplete="off"
              @input="showValid('name')"
            />
            <nameValidation :$v="$v.theme" v-if="showErrorNameValidation" />
          </div>
        </div>
        <div class="form-group row">
          <h5 class="col-md-12">Copied From</h5>
          <div class="col-md-12">
            <select class="form-control col-md-12" v-model="copyFromId">
              <option value="" disabled>Select Copy From</option>
              <option v-for="theme in themes" :key="theme.id" :value="theme.id">
                {{ ` ${theme.id} - ${theme.name}` }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="panel-footer">
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="close"
        >
          <i class="icon md-close" aria-hidden="true"></i>
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="addTheme"
          :disabled="$v.$invalid"
        >
          <i class="icon md-check" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";

import nameValidation from "@/views/samurai/Themes/validation/name";
import { responseErrorMapping } from "@/views/samurai/Themes/model/error";
import { FormCreateValidateRule as validateRules } from "@/views/samurai/Themes/validation";

export default {
  name: "CreateModal",

  props: ["name"],

  components: {
    nameValidation,
  },

  validations: { theme: validateRules },

  data() {
    return {
      theme: {
        name: "",
      },
      themes: [],
      copyFromId: null,
      showErrorNameValidation: false,
    };
  },

  methods: {
    close() {
      this.$modal.hide(this.name);
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },

    async getFormData() {
      // เลือก copy from
      if (this.copyFromId) {
        return { copy_from_id: this.copyFromId, name: this.theme.name };
      }

      // ไม่ได้เลือก copy from
      else {
        return {
          name: this.theme.name,
        };
      }
    },

    validate() {
      if (this.$v.theme.name.$invalid) {
        this.$refs.name.focus();
        throw new Error("กรุณากรอก Theme Name ให้ถูกต้อง");
      }
    },

    async addTheme() {
      try {
        this.validate();

        const body = await this.getFormData();

        await gameSamuraiService.createTheme(body);
        await this.$dialogs.alert("Create Theme Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error.message || error);
      }
    },
  },

  async mounted() {
    this.themes = await gameSamuraiService.getThemeChoiceCopyFrom();
  },
};
</script>

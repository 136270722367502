var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.weekly
    ? _c(
        "div",
        { staticClass: "col-md-10" },
        [
          _vm._m(0),
          _c("div", { staticClass: "progress-upload-switcher-wrapper" }, [
            _c("p", { staticClass: "my-0 d-inline" }, [_vm._v("Auto")]),
            _c("label", { staticClass: "switch m0" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.weekly.progress_upload_manual,
                    expression: "weekly.progress_upload_manual",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "true-value": 1,
                  "false-value": 0,
                  disabled: _vm.disabled,
                },
                domProps: {
                  checked: Array.isArray(_vm.weekly.progress_upload_manual)
                    ? _vm._i(_vm.weekly.progress_upload_manual, null) > -1
                    : _vm._q(_vm.weekly.progress_upload_manual, 1),
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.weekly.progress_upload_manual,
                        $$el = $event.target,
                        $$c = $$el.checked ? 1 : 0
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.weekly,
                              "progress_upload_manual",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.weekly,
                              "progress_upload_manual",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.weekly, "progress_upload_manual", $$c)
                      }
                    },
                    _vm.update,
                  ],
                },
              }),
              _c("span", { staticClass: "slider round" }),
            ]),
            _c("p", { staticClass: "mx-10 my-0 ml-20 d-inline" }, [
              _vm._v("Manual"),
            ]),
          ]),
          +_vm.weekly.progress_upload_manual === 0
            ? _c("div", { staticClass: "data-source-selector" }, [
                _c("h5", { staticClass: "title" }, [_vm._v("Data Source")]),
                _c("div", { staticClass: "data-source-gtws-wrapper" }, [
                  _c("p", { staticClass: "my-0 d-inline" }, [_vm._v("GT")]),
                  _c("label", { staticClass: "switch m0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.product_data_source,
                          expression: "weekly.product_data_source",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        "true-value": "ws",
                        "false-value": "gt",
                        disabled: !_vm.canChangeProductCodes,
                      },
                      domProps: {
                        checked: Array.isArray(_vm.weekly.product_data_source)
                          ? _vm._i(_vm.weekly.product_data_source, null) > -1
                          : _vm._q(_vm.weekly.product_data_source, "ws"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.weekly.product_data_source,
                              $$el = $event.target,
                              $$c = $$el.checked ? "ws" : "gt"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.weekly,
                                    "product_data_source",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.weekly,
                                    "product_data_source",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.weekly, "product_data_source", $$c)
                            }
                          },
                          _vm.updateProductDatasource,
                        ],
                      },
                    }),
                    _c("span", { staticClass: "slider round" }),
                  ]),
                  _c("p", { staticClass: "mx-10 my-0 ml-20 d-inline" }, [
                    _vm._v("WS"),
                  ]),
                ]),
                _c("div", { staticClass: "data-source-ryo-switcher" }, [
                  _c("p", { staticClass: "my-0 d-inline" }, [_vm._v("RYO")]),
                  _c("label", { staticClass: "switch m0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.product_code_type,
                          expression: "weekly.product_code_type",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        "true-value": "non_ryo",
                        "false-value": "ryo",
                        disabled:
                          !_vm.canChangeProductCodes ||
                          _vm.weekly.product_data_source == "gt",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.weekly.product_code_type)
                          ? _vm._i(_vm.weekly.product_code_type, null) > -1
                          : _vm._q(_vm.weekly.product_code_type, "non_ryo"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.weekly.product_code_type,
                              $$el = $event.target,
                              $$c = $$el.checked ? "non_ryo" : "ryo"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.weekly,
                                    "product_code_type",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.weekly,
                                    "product_code_type",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.weekly, "product_code_type", $$c)
                            }
                          },
                          _vm.updateProductCodeTypes,
                        ],
                      },
                    }),
                    _c("span", { staticClass: "slider round" }),
                  ]),
                  _c("p", { staticClass: "mx-10 my-0 d-inline" }, [
                    _vm._v("Non-RYO"),
                  ]),
                ]),
              ])
            : _vm._e(),
          +_vm.weekly.progress_upload_manual === 0
            ? _c(
                "div",
                { staticClass: "target-sku-wrapper" },
                _vm._l(_vm.period, function (o, index) {
                  return _c(
                    "div",
                    { key: o.id, staticClass: "mb-30" },
                    [
                      _c("TargetSKU", {
                        attrs: {
                          campaign: _vm.campaign,
                          skus: _vm.skus,
                          index: index,
                          status: _vm.status,
                          action: _vm.action,
                          pullingId: _vm.pullingId,
                          currentEditId: _vm.currentEditId,
                        },
                        on: {
                          setAction: _vm.setAction,
                          onCommit: _vm.handleCommit,
                          updated: _vm.handleSKUUpdated,
                          getUploadStatus: _vm.getUploadStatus,
                          stopPullingStatus: _vm.stopPullingStatus,
                          startPullingStatus: _vm.startPullingStatus,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "modal",
            { attrs: { name: _vm.targetModal, width: 900, height: "auto" } },
            [
              _c("TryUploadUserTargetModal", {
                attrs: {
                  campaign: _vm.campaign,
                  result: _vm.result,
                  targetName: _vm.targetModal,
                },
                on: { confirm: _vm.confirmUpdate },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Progress Upload")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Progress Bar</h1>
      <breadcrumb name="PROGRESS_BAR_UPDATE_LIST"></breadcrumb>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              @on-row-click="goToEdit"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span>
                    {{ props.row.status | toUpperCase }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { campaignTableColumns } from "@/views/progressbar/model";

export default {
  name: "CampaignList",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      campaigns: [],
      columns: campaignTableColumns,
    };
  },

  created() {
    this.fetch();
  },

  watch: {
    hideArchive() {
      this.filter();
    },

    campaigns(v) {
      this.list = v;
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    goToEdit(event) {
      this.$router
        .push({ name: "ProgressUpdateDetail", params: { id: event.row.id } })
        .catch((err) => {});
    },

    async fetch() {
      this.campaigns = await progressBarService.getCampaignList();
    },
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>

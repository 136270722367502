var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quota
    ? _c("div", { staticClass: "form-group row" }, [
        _c("h5", { staticClass: "col-md-12" }, [_vm._v("Campaign IDs")]),
        _c(
          "div",
          { staticClass: "col-md-8 col-sm-12" },
          [
            _c(
              "Selectize",
              {
                ref: "select",
                attrs: {
                  multiple: "",
                  disabled: !_vm.canEdit,
                  settings: _vm.selectizeSetting,
                },
                model: {
                  value: _vm.quota.campaign_id_list,
                  callback: function ($$v) {
                    _vm.$set(_vm.quota, "campaign_id_list", $$v)
                  },
                  expression: "quota.campaign_id_list",
                },
              },
              _vm._l(_vm.campaigns, function (ref) {
                var id = ref.id
                var name = ref.name
                return _c("option", { key: id, domProps: { value: id } }, [
                  _vm._v(" " + _vm._s(" " + id + " - " + name) + " "),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
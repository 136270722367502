var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-8" }, [
    _vm._m(0),
    _c("div", { staticClass: "input-wrapper" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.game_duration,
            expression: "game_duration",
          },
        ],
        ref: "game_duration",
        staticClass: "form-control w-150",
        attrs: { min: "0", max: "600", type: "number", disabled: _vm.disabled },
        domProps: { value: _vm.game_duration },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.game_duration = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Game Duration (seconds)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
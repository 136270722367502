<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <div
          class="d-block"
          v-for="(sideObject, index) in gameConfig.sunset_drive.side_object
            .left"
          :key="index"
        >
          <div class="form-group">
            <div my-15px>
              <label class="form-control-label">
                <h5>
                  Left side object #{{ index + 1 }}
                  <span class="small light"> (recommended size 571x1000)</span>
                </h5>
              </label>

              <div class="form-group">
                <div class="d-block">
                  <img
                    class="img img-fluid image-preview"
                    v-if="picture"
                    :src="picture.side_object.left[index].picture"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="upload"
                  data-side="left"
                  :data-number="index + 1"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div
          class="d-block"
          v-for="(sideObject, index) in gameConfig.sunset_drive.side_object
            .right"
          :key="index"
        >
          <div class="form-group">
            <div my-15px>
              <label class="form-control-label">
                <h5>
                  Right side object #{{ index + 1 }}
                  <span class="small light"> (recommended size 571x1000)</span>
                </h5>
              </label>

              <div class="form-group">
                <div class="d-block">
                  <img
                    class="img img-fluid image-preview"
                    v-if="picture"
                    :src="picture.side_object.right[index].picture"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="upload"
                  data-side="right"
                  :data-number="index + 1"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return {
      file: null,
    };
  },
  computed: {
    picture() {
      return this.gameConfig.sunset_drive;
    },

    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },
  methods: {
    async upload(event) {
      const file = event.target.files[0];

      const side = event.target.getAttribute("data-side");
      const number = event.target.getAttribute("data-number");

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append(`picture`, file);

        const { id } = this.gameConfig;

        await httpService.post(
          `/game/${id}/sunset_drive/side_object/${side}/${number}/upload`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$emit("updated");
        this.$notify(`Upload ${side} side object #${number} success`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload side object error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  height: auto;
  max-width: 400px;
  max-height: 400px;
}

.form-control-label {
  padding: 0px;

  h4 {
    margin-bottom: 0.5em;
  }
}
</style>

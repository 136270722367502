import qs from "qs";
import HttpRequest from "./request";

export class GameProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api`);
  }

  async getGame(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/game${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/game/${id}`);
    return data;
  }

  async createGame(body) {
    const { data } = await this.create("/game", body);
    return data;
  }

  async editGame(id, body) {
    const { data } = await this.update(`/game/${id}`, body);
    return data;
  }

  async editGameLabel(id, label, body) {
    const { data } = await this.update(
      `/game/${id}` + `/fortune/label` + `/${label}`,
      body
    );
    return data;
  }

  async editGameSetting(id, body) {
    const { data } = await this.update(`/game/${id}`, body);
    return data;
  }

  async editGameSettingChoice(id, body) {
    const { data } = await this.update(`/game/${id}/advocacy/question`, body);
    return data;
  }

  async engagement(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/engagement${queryString}`);
    return data;
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/transaction_log${queryString}`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/transaction_log/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }
}

import Point from "@/views/point/Index.vue";
import PointList from "@/views/point/List.vue";
import PointEdit from "@/views/point/Edit.vue";
import UserReport from "@/views/point/UserReport.vue";
import BulkOperation from "@/views/point/BulkOperation.vue";
import TransactionLog from "@/views/point/TransactionLog.vue";
import DiscountReport from "@/views/point/DiscountReport/Index.vue";
import PointQuotaLimit from "@/views/point/PointQuotaLimit/List.vue";
import PointQuotaLimitEdit from "@/views/point/PointQuotaLimit/Edit.vue";

export default {
  path: "/point-program",
  name: "Point",
  component: Point,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "point-campaign/list",
      name: "PointCampaignList",
      component: PointList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "point-campaign/edit/:id",
      name: "PointCampaignEdit",
      component: PointEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "user-report",
      name: "PointCampaignUserReport",
      component: UserReport,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "bulk-operation",
      name: "PointCampaignBulkOperation",
      component: BulkOperation,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "transaction-log",
      name: "PointCampaignTransactionLog",
      component: TransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "point-quota-limit",
      name: "PointCampaignPointQuotaLimit",
      component: PointQuotaLimit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "point-quota-limit/edit/:id",
      name: "PointCampaignPointQuotaLimitEdit",
      component: PointQuotaLimitEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "discount-report",
      name: "PointCampaignDiscountReport",
      component: DiscountReport,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

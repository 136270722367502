<template>
  <div class="wrap">
    <!-- toggle show / hide -->
    <label for="display" class="my-10 d-none">
      <toggle-button
        id="display"
        :value="false"
        color="#3e8ef7"
        :sync="true"
        :labels="true"
        v-model="display"
      />
      <span class="mx-10">{{ display ? "ซ่อน" : "แสดง" }} validate</span>
    </label>

    <ul class="list-icons mb-0" v-if="display">
      <!-- minLength -->
      <li :class="{ 'text-success': $v.name.required }">
        <i class="wb-close" aria-hidden="true" v-if="!$v.name.required"></i>
        <i class="wb-check" aria-hidden="true" v-else></i>
        จำเป็นต้องระบุ Campaign name
      </li>

      <!-- maxLength -->
      <li :class="{ 'text-success': $v.name.maxLength }">
        <i class="wb-close" aria-hidden="true" v-if="!$v.name.maxLength"></i>
        <i class="wb-check" aria-hidden="true" v-else></i>
        ต้องระบุไม่เกิน
        {{ $v.name.$params.maxLength.max }} ตัวอักษร
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["$v"],
  data() {
    return {
      display: true,
    };
  },
};
</script>
<style lang="scss" scoped>
div.wrap {
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #f3f3f3;
}
</style>

<template>
  <div class="page vld-parent">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="page-header">
      <h1 class="page-title">Status Report</h1>
      <breadcrumb name="E_COUPON_STATUS_REPORT"></breadcrumb>
    </div>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-bordered">
            <div class="panel-heading">
              <div class="panel-title">
                <div class="filter-container">
                  <label class="form-control-label">
                    Select Campaigns and Apps
                  </label>
                  <div class="filter-wrapper">
                    <div class="d-flex g-3 mb-10">
                      <div class="checkbox-custom checkbox-primary">
                        <input
                          type="checkbox"
                          id="all_campaign_checkbox"
                          v-model="allCampaignSelected"
                          @change="handleAppCampaignChange"
                        />
                        <label for="all_campaign_checkbox">All Campaigns</label>
                      </div>
                      <div class="checkbox-custom checkbox-primary">
                        <input
                          type="checkbox"
                          id="all_app_checkbox"
                          v-model="allAppSelected"
                          @change="handleAppCampaignChange"
                        />
                        <label for="all_app_checkbox">All Apps</label>
                      </div>
                    </div>

                    <multiselect
                      label="name"
                      :limit="limit"
                      track-by="name"
                      :multiple="true"
                      :options="options"
                      group-values="data"
                      group-label="source"
                      :showLabels="false"
                      :group-select="true"
                      :close-on-select="false"
                      :custom-label="customLabel"
                      placeholder="Type to search"
                      v-model="selectedAppCampaign"
                      class="app-campaign-multiselector"
                      @open="handleAppCampaignSelectorOpen"
                      @close="handleAppCampaignSelectorClose"
                      @input="handleAppCampaignSelectorChange"
                      :disabled="allCampaignSelected || allAppSelected"
                    >
                      <span slot="noResult">
                        Oops! No elements found. Consider changing the search
                        query.
                      </span>
                    </multiselect>
                  </div>
                  <button
                    class="btn btn-default export-button"
                    @click="downloadChart"
                  >
                    <i class="mdi mdi-microsoft-excel"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-12 mt-10">
                  <div class="float-right">
                    <div class="show-percentage-switch">
                      <p class="d-inline">
                        Show Percentage
                      </p>
                      <label class="switch">
                        <input
                          type="checkbox"
                          @change="reRenderChart"
                          v-model="showAsPercentage"
                        />
                        <span class="slider round"></span>
                      </label>
                      <p class="d-inline">
                        {{ showAsPercentage ? "ON" : "OFF" }}
                      </p>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="chart-wrapper">
                  <button
                    type="button"
                    @click="drillup"
                    :disabled="this.level === 0"
                    class="btn btn-icon btn-lg"
                  >
                    <i class="icon md-arrow-missed" aria-hidden="true"></i>
                  </button>
                  <div class="highcharts-wrapper">
                    <figure class="highcharts-figure">
                      <div id="container"></div>
                      <p class="highcharts-description"></p>
                    </figure>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-10">
                  <div class="float-right">
                    <button
                      class="btn btn-default export-button"
                      @click="downloadTable"
                    >
                      <i class="mdi mdi-microsoft-excel"></i>
                      Export to Excel
                    </button>
                  </div>
                </div>

                <div class="col-12 mb-10"></div>

                <div class="col-12">
                  <vue-good-table
                    ref="table"
                    mode="remote"
                    :rows="list"
                    :columns="columns"
                    :totalRows="totalRecords"
                    :sort-options="sortOption"
                    :search-options="{ enabled: false }"
                    :pagination-options="pagination"
                    @on-sort-change="onSortChange"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                  >
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="d-flex justify-content-between"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import Multiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { ecouponService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { columns } from "@/views/ecoupon/status-report/model";

const httpService = ecouponService.axiosInstance;

export default {
  name: "ECouponStatusReport",

  mixins: [vueGoodTableMixins],

  components: {
    Loading,
    Multiselect,
  },

  data() {
    return {
      options: [
        {
          source: "Campaigns",
          data: [],
        },
        {
          source: "Apps",
          data: [],
        },
      ],

      dropdownOpened: false,
      dropdownOpenedValue: "",

      allAppSelected: false,
      allCampaignSelected: false,

      selectedAppCampaign: [],

      list: [],
      level: 0,
      isLoading: false,
      defaultColors: [
        "#4472c4",
        "#ed7d31",
        "#a5a5a5",
        "#ffbf00",
        "#5b9bd5",
        "#71ad47",
        "#264478",
        "#9e480e",
        "#f44336",
        "#9c27b0",
        "#009688",
      ],
      chart: null,
      params: null,
      previousCode: [],
      columns: columns,
      responseData: null,
      showAsPercentage: false,
      filterStatus: null,
      levelValue: [null, "RSM", "ASM", "TSE"],
    };
  },

  watch: {
    async "$route.query"() {
      const code = this.previousCode[this.level];
      await this.fetchReport(code);
      this.initialTableState();
    },
    responseData({ table }) {
      this.list = table;
    },
  },

  computed: {
    limit() {
      if (this.selectedAppCampaign.length <= 0) {
        return 9999;
      }

      const boxWidth = document
        .querySelector(".app-campaign-multiselector")
        .getBoundingClientRect().width;

      const limitTextLength = 100;
      var selectedWidth = 0;

      for (let index = 0; index < this.selectedAppCampaign.length; index++) {
        const element = this.selectedAppCampaign[index];

        selectedWidth += element.name.toString().length * 14;
        selectedWidth += 30 + 26;

        if (selectedWidth + limitTextLength > boxWidth * 2)
          return index + 1 + 2;
      }

      return 9999;
    },
  },

  methods: {
    reRenderChart() {
      this.chart.update(
        {
          plotOptions: {
            series: {
              stacking: this.showAsPercentage ? "percent" : "normal",
            },
          },
        },
        true,
        true
      );
    },

    drillup() {
      if (this.level > 0) {
        this.level -= 1;
      }

      this.previousCode.pop();

      const code = this.previousCode[this.previousCode.length - 1];
      this.fetchReport(code);
    },

    updateChart() {
      const { series, categories } = this.transformDataToChart();

      this.chart.update(
        {
          series,
          xAxis: { categories },
        },
        true,
        true
      );

      this.handleClickLabelGroup();
    },

    transformDataToChart() {
      // total
      if (this.level === 0) {
        return {
          categories: ["Total"],
          series: this.responseData.all_status.map((o) => {
            return { ...o, data: [this.responseData.chart[0][o.id] || 0] };
          }),
        };
      }

      // // RSM, ASM, TSE
      else if (this.level > 0) {
        const data = this.responseData.chart.map((o) => {
          return {
            ...o,
            // RSM | ASM | TSE ขึ้นอยู่กับ level นั้นๆ
            category: o.category || `ไม่มี ${this.levelValue[this.level]}`,
          };
        });

        return {
          categories: data.map((o) => o.category),
          series: this.responseData.all_status.map((s) => {
            return {
              ...s,
              data: data.map((d) => d[s.id] || 0),
            };
          }),
        };
      }
    },

    getParams(code) {
      let appSelected = "";
      let campaignSelected = "";

      if (this.allAppSelected || this.allCampaignSelected) {
        appSelected = this.allAppSelected ? "All" : "";
        campaignSelected = this.allCampaignSelected ? "All" : "";
      } else {
        appSelected = this.selectedAppCampaign
          .filter((o) => o.type == "APP")
          .map((o) => o.id)
          .toString();

        campaignSelected = this.selectedAppCampaign
          .filter((o) => o.type == "CAMPAIGN")
          .map((o) => o.id)
          .toString();

        appSelected = appSelected || "";
        campaignSelected = campaignSelected || "";
      }

      let params = {
        app_id: appSelected,
        cp_id: campaignSelected,
        drill: this.level > 0 ? 1 : 0,
      };

      if (this.filterStatus !== null) {
        params = Object.assign(params, { coupon_status: this.filterStatus });
      }

      if (code && this.level > 0) {
        params = Object.assign(params, code);
      }

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },

    async fetchReport(code) {
      try {
        const params = this.getParams(code);

        this.params = params;

        const queryString = qs.stringify(params, {
          addQueryPrefix: true,
        });

        this.isLoading = true;

        const { data } = await httpService.get(
          `/ecpadvancestatusreport/appchart${queryString}`
        );

        const allStatus = data.data.all_status;

        data.data.table = data.data.table.map((o) => {
          const status = allStatus.find((s) => s.id == o.ecp_status);

          return {
            ...o,
            status: status.name,
          };
        });

        this.responseData = data.data;
        this.totalRecords = data.data.total_records;

        this.updateChart();

        this.previousCode[this.level] = code;

        await this.wait(100);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchAppsCampaign() {
      const { data } = await httpService.get(
        "/ecpadvancestatusreport/SelectAppCampaign"
      );

      const { AppList, CampaignList } = data;

      this.options = [
        {
          source: "Campaigns",
          data: CampaignList.filter((o) =>
            ["ongoing", "end"].includes(o.status)
          )
            .map((o) => {
              return { ...o, type: "CAMPAIGN" };
            })
            .sort((a, b) => b.id - a.id),
        },
        {
          source: "Apps",
          data: AppList.map((o) => {
            return {
              id: o.app_id,
              name: o.app_name,
              status: o.app_status,
              type: "APP",
            };
          }).sort((a, b) => a.id - b.id),
        },
      ];
    },

    customLabel({ id, name }) {
      return `${id} – ${name}`;
    },

    handleAppCampaignSelectorChange() {
      if (this.dropdownOpened) return;
      this.handleAppCampaignChange();
    },

    handleAppCampaignSelectorOpen() {
      this.dropdownOpened = true;
      if (this.selectedAppCampaign.length > 0) {
        this.dropdownOpenedValue = this.selectedAppCampaign
          .map((o) => o.id)
          .sort((a, b) => a - b)
          .toString()
          .replace(/,/g, "");
      } else {
        this.dropdownOpenedValue = "";
      }
    },

    handleAppCampaignSelectorClose() {
      this.dropdownOpened = false;

      const onCloseStringValue = this.selectedAppCampaign
        .map((o) => o.id)
        .sort((a, b) => a - b)
        .toString()
        .replace(/,/g, "");
      if (onCloseStringValue === this.dropdownOpenedValue) {
        return;
      }

      this.handleAppCampaignChange();
    },

    async handleAppCampaignChange() {
      this.level = 0;
      this.previousCode = [];
      await this.fetchReport();
    },

    handleClickLabelGroup() {
      const _self = this;
      const labels = this.chart.xAxis[0].labelGroup;

      labels &&
        labels.element.childNodes.forEach(function (label) {
          if (_self.level >= 3) return;

          if (label.textContent.search(`ไม่มี`) >= 0) {
            label.style.cursor = "not-allowed";
            label.style.fill = "rgb(165 165 165)";
            return;
          }

          label.style.cursor = "pointer";
          label.style.fontWeight = "bold";
          label.onclick = async function () {
            if (_self.level < 3) {
              _self.level += 1;
            }

            switch (_self.level) {
              case 0:
                await _self.fetchReport();
                break;
              case 1:
                await _self.fetchReport();
                break;
              case 2:
                await _self.fetchReport({ rsm_code: this.textContent });
                break;
              case 3:
                await _self.fetchReport({ asm_code: this.textContent });
                break;
              case 4:
                await _self.fetchReport({ tse_code: this.textContent });
                break;
            }
          };
        });
    },

    createChart({ series, categories }) {
      this.chart = Highcharts.chart("container", {
        chart: {
          type: "bar",
        },
        colors: this.responseData
          ? this.responseData.all_status.map((o) => o.color)
          : this.defaultColors,
        exporting: {
          enabled: false,
        },
        title: false,
        xAxis: {
          categories,
        },
        yAxis: {
          min: 0,
          title: {},
          allowDecimals: false,
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color:
                // theme
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                "gray",
            },
          },
        },
        legend: {
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 0,
          itemMarginBottom: 12,
        },
        tooltip: {
          formatter: function () {
            return `${this.series.name} ${
              this.series.chart.options.plotOptions.series.stacking ===
              "percent"
                ? `${this.percentage.toFixed(2).replace(/[.,]00$/, "")}% (${
                    this.y
                  })`
                : this.y
            }`;
          },
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
          bar: {
            events: {
              legendItemClick: async (e) => {
                await this.wait(100);

                const status = this.chart.legend.allItems.map((o) => {
                  return { id: o.userOptions.id, visible: o.visible };
                });

                const activeStatus = status.filter((o) => o.visible);

                this.filterStatus =
                  activeStatus.length === status.length
                    ? null
                    : (this.filterStatus = activeStatus
                        .map((o) => o.id)
                        .toString());

                const code = this.previousCode[this.level];
                await this.fetchReport(code);
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        series,
      });

      this.handleClickLabelGroup();
    },

    async downloadChart() {
      try {
        const queryString = qs.stringify(this.params, {
          addQueryPrefix: true,
        });

        const response = await httpService.get(
          `/ecpadvancestatusreport/appchart/download${queryString}`,
          {
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);

        const { rsm_code, asm_code, tse_code } = this.params;
        const code = rsm_code || asm_code || tse_code || "All";

        a.download = `ecoupon_chart_${code}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    async downloadTable() {
      try {
        const queryString = qs.stringify(this.params, {
          addQueryPrefix: true,
        });

        const response = await httpService.get(
          `/ecpadvancestatusreport/apptable/download${queryString}`,
          {
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);

        const { rsm_code, asm_code, tse_code } = this.params;
        const code = rsm_code || asm_code || tse_code || "All";

        a.download = `ecoupon_data_${code}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.fetchAppsCampaign();

    // only create chart
    if (this.options.length === 0) {
      this.createChart({ series: [], categories: ["Total"] });
    }

    // create and update chart
    else {
      this.createChart({ series: [], categories: ["Total"] });

      this.level = 0;
      await this.fetchReport();
    }
  },

  created() {},
};
</script>

<style lang="scss" scoped>
@import "./styles/status-report.scss";
</style>

<!-- vue-multiselect custom style -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "./styles/multiselect.custom.scss";
</style>

import { AuthProvider } from "./auth";
import { AdminProvider } from "./admin";
import { DealerProvider } from "./dealer";
import { ProductProvider } from "./product";
import { UserGroupProvider } from "./usergroup";
import { LotProvider } from "./lot";
import { CustomerProvider } from "./customer";
import { UploadProvider } from "./upload";
import { HistoryProvider } from "./history";
import { WinnerProvider } from "./winner";
import { DailyloginProvider } from "./campaign";
import { RefundLogProvider } from "./refund_log";
import { TransactionLogProvider } from "./transaction";
import { ReportProvider } from "./result";
import { GameProvider } from "./game";
import { WalletProvider } from "./wallet";
import { SkuProvider } from "./sku";
import { QuestionProvider } from "./question";
import { UploadGameProvider } from "./uploadgame";
import { CharacterProvider } from "./character";
import { LuckyLoginProvider } from "./luckylogin";
import { ProgressBarProvider } from "./progressbar";
import { ECouponProvider } from "./ecoupon";
import { LeaderBoardProvider } from "./leaderboard";
import { PrizeProvider } from "./prize";
import { PrizePoolProvider } from "./prizepool";
import { EcpECouponProvider } from "./ecpEcoupon";
import { LuckyDrawProvider } from "./luckydraw";
import { ExpirationProvider } from "./expiration";
import { IllicitBarcodeProvider } from "./illicit_barcode";
import { WeeklyLoginProvider } from "./weeklylogin";
import { VSMUserProvider } from "./vsm_user_management";
import { LIFFAccessProvinder } from "./liff_access";
import { GameSamuraiProvider } from "./game-samurai";
import { BudgetProvider } from "./budget";
import { StaffEngagementProvider } from "./staffEngagement";

export const authService = new AuthProvider();
export const adminService = new AdminProvider();
export const dealerService = new DealerProvider();
export const productService = new ProductProvider();
export const userGroupService = new UserGroupProvider();
export const lotService = new LotProvider();
export const customerService = new CustomerProvider();
export const uploadService = new UploadProvider();
export const historyService = new HistoryProvider();
export const winnerService = new WinnerProvider();
export const transactionService = new TransactionLogProvider();
export const dailyloginService = new DailyloginProvider();
export const logService = new RefundLogProvider();
export const reportService = new ReportProvider();
export const gameService = new GameProvider();
export const walletService = new WalletProvider();
export const skuService = new SkuProvider();
export const uploadgameService = new UploadGameProvider();
export const questionService = new QuestionProvider();
export const characterService = new CharacterProvider();
export const luckyLoginService = new LuckyLoginProvider();
export const progressBarService = new ProgressBarProvider();
export const ecouponService = new ECouponProvider();
export const leaderBoardService = new LeaderBoardProvider();
export const prizeService = new PrizeProvider();
export const prizepoolService = new PrizePoolProvider();
export const ecpEcouponService = new EcpECouponProvider();
export const luckyDrawService = new LuckyDrawProvider();
export const weeklyLoginService = new WeeklyLoginProvider();
export const expirationService = new ExpirationProvider();
export const illicitBarcodeService = new IllicitBarcodeProvider();
export const VSMUserService = new VSMUserProvider();
export const liffAccessService = new LIFFAccessProvinder();
export const gameSamuraiService = new GameSamuraiProvider();
export const budgetService = new BudgetProvider();
export const staffEngagementService = new StaffEngagementProvider();

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create Prize Theme</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.name.$error }"
            >
              <label class="col-md-0 form-control-label form__label">
                <h5>Theme Name</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="createPrizeTheme"
              :disabled="$v.$invalid"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";
import { responseErrorMapping } from "./model/error";
import nameValidation from "./validation/name";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "CreateModal",

  data() {
    return {
      showErrorNameValidation: false,
      name: "",
    };
  },

  components: {
    nameValidation,
  },

  validations: { name: validateRules.name },

  methods: {
    async isFormValid() {
      if (this.$v.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Theme Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    close() {
      this.$modal.hide("create-prize-theme-modal");
    },

    async getFormData() {
      return {
        name: this.name,
      };
    },

    async createPrizeTheme() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const body = await this.getFormData();

        await prizepoolService.createNewPrizeTheme(body);
        await this.$dialogs.alert("Create prize theme Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Game Instance")]),
        _c("breadcrumb", {
          attrs: { name: "GAME_EDIT", withLast: _vm.game.name },
          on: {
            "update:withLast": function ($event) {
              return _vm.$set(_vm.game, "name", $event)
            },
            "update:with-last": function ($event) {
              return _vm.$set(_vm.game, "name", $event)
            },
          },
        }),
        _c("div", { staticClass: "page-header-actions" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-default mx-10",
              attrs: { type: "button" },
              on: { click: _vm.goBack },
            },
            [
              _c("i", {
                staticClass: "icon md-chevron-left",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Back "),
            ]
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "page-content container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
          },
          [
            _c("div", { staticClass: "panel panel-bordered" }, [
              _c("div", { staticClass: "panel-heading" }, [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v(
                    " Game Instance Information - " + _vm._s(_vm.game.id) + " "
                  ),
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("h5", { staticClass: "col-md-5" }, [
                    _c("div", { staticClass: "text-left" }, [
                      _vm._v(
                        " Cigarette " +
                          _vm._s(_vm._f("capitalize")(_vm.game.game)) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("h5", { staticClass: "col-md-5" }, [
                    _vm._v(
                      " User Groups: " + _vm._s(_vm.usergroups.group_code) + " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "panel-body" }, [
                _c("form", { staticClass: "form-horizontal" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _vm._m(0),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.game.name,
                              expression: "game.name",
                            },
                          ],
                          ref: "gameName",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            maxlength: "255",
                            disabled: _vm.isDisable.name,
                          },
                          domProps: { value: _vm.game.name },
                          on: {
                            change: function ($event) {
                              return _vm.submit("name")
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.game, "name", $event.target.value)
                            },
                          },
                        }),
                        _c("nameValidation", { attrs: { $v: _vm.$v } }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _c("div", { staticClass: "px-15" }, [
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary btn-round dropdown-toggle",
                              staticStyle: { "text-transform": "uppercase" },
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.game.status) + " ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { role: "menu" },
                            },
                            [
                              _vm.game.status == "draft"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("scheduled")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-long-arrow-up",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Publish "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "draft"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("archived")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-archive",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Archive "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "scheduled"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("draft")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-long-arrow-down",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Unpublished "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "archived"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("draft")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-archive",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Unarchive "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "ongoing"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("paused")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-archive",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Pause "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "ongoing"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("end")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-archive",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Stop "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "paused"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("ongoing")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-archive",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Restart "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.game.status == "paused"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "javascript:void(0)",
                                        role: "menuitem",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setStatus("end")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon md-archive",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v("Stop "),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("hr"),
                  _c("h5", { staticClass: "col-md-12" }, [
                    _vm._v("Game Instance Duration"),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-md-1 form-control-label" },
                      [_vm._v("Start")]
                    ),
                    _c(
                      "section",
                      [
                        _c("date-picker", {
                          ref: "game_start_date",
                          attrs: {
                            type: "datetime",
                            placeholder: "Select datetime",
                            "value-type": "format",
                            format: "YYYY-MM-DD HH:mm:ss",
                            "show-time-panel": _vm.showTimePanel,
                            disabled: _vm.isDisable.duration,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.clearDate(true)
                            },
                            close: function ($event) {
                              return _vm.handleOpenChange(true)
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "footer",
                              fn: function () {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "mx-btn mx-btn-text",
                                      on: { click: _vm.toggleTimePanel },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.showTimePanel
                                              ? "select date"
                                              : "select time"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.game.start_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.game, "start_date", $$v)
                            },
                            expression: "game.start_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-md-1 form-control-label" },
                      [_vm._v("End")]
                    ),
                    _c(
                      "section",
                      [
                        _c("date-picker", {
                          ref: "game_start_date",
                          attrs: {
                            type: "datetime",
                            placeholder: "n/a",
                            "value-type": "format",
                            format: "YYYY-MM-DD HH:mm:ss",
                            "show-time-panel": _vm.showTimePanel,
                            "disabled-date": _vm.AfterStartDate,
                            disabled: _vm.isDisable.durationEnd,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.clearDate(false)
                            },
                            close: function ($event) {
                              return _vm.handleOpenChange(false)
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "footer",
                              fn: function () {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "mx-btn mx-btn-text",
                                      on: { click: _vm.toggleTimePanel },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.showTimePanel
                                              ? "select date"
                                              : "select time"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.game.end_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.game, "end_date", $$v)
                            },
                            expression: "game.end_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("hr"),
                  _c("h5", { staticClass: "col-md-12" }, [
                    _vm._v("Game Configuration"),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Monday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_mon,
                            expression: "game.no_play_mon",
                          },
                        ],
                        ref: "no_play_mon",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9]/g, '')",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_mon },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_mon")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_mon",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Tuesday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_tue,
                            expression: "game.no_play_tue",
                          },
                        ],
                        ref: "no_play_tue",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9]/g, '')",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_tue },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_tue")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_tue",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Wednesday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_wed,
                            expression: "game.no_play_wed",
                          },
                        ],
                        ref: "no_play_wed",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9]/g, '')",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_wed },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_wed")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_wed",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Thursday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_thu,
                            expression: "game.no_play_thu",
                          },
                        ],
                        ref: "no_play_thu",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_thu },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_thu")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_thu",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Friday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_fri,
                            expression: "game.no_play_fri",
                          },
                        ],
                        ref: "no_play_fri",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9]/g, '')",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_fri },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_fri")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_fri",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Saturday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_sat,
                            expression: "game.no_play_sat",
                          },
                        ],
                        ref: "no_play_sat",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9]/g, '')",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_sat },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_sat")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_sat",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "w80" }, [
                      _c("label", [_vm._v("Sunday")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.game.no_play_sun,
                            expression: "game.no_play_sun",
                          },
                        ],
                        ref: "no_play_sun",
                        staticClass: "center form-control",
                        attrs: {
                          type: "number",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9]/g, '')",
                          disabled: _vm.isDisable.configuration,
                        },
                        domProps: { value: _vm.game.no_play_sun },
                        on: {
                          change: function ($event) {
                            return _vm.submit("no_play_sun")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.game,
                              "no_play_sun",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("hr"),
                  _c("h5", { staticClass: "col-md-12" }, [
                    _vm._v(
                      " Game Specific Setting - Cigarette " +
                        _vm._s(_vm._f("capitalize")(_vm.game.game)) +
                        " "
                    ),
                  ]),
                  _vm.typeGame == "fortune"
                    ? _c("div", [
                        _c("table", { staticClass: "table" }, [
                          _vm._m(2),
                          _c(
                            "tbody",
                            _vm._l(_vm.label_set, function (set, index) {
                              return _c("tr", { key: index }, [
                                _c(
                                  "th",
                                  {
                                    staticClass: "center",
                                    attrs: { scope: "row" },
                                  },
                                  [
                                    _c("div", { staticClass: "space-top" }, [
                                      _vm._v(" " + _vm._s(set) + " "),
                                    ]),
                                  ]
                                ),
                                _c("td", { staticClass: "input-table" }, [
                                  _c("div", { staticClass: "center" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.setting[index].point,
                                          expression: "setting[index].point",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        max: "999",
                                        min: "0",
                                        disabled:
                                          _vm.isDisable.specific_setting,
                                      },
                                      domProps: {
                                        value: _vm.setting[index].point,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submitSetting(
                                            "point",
                                            set,
                                            index
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.setting[index],
                                            "point",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("td", { staticClass: "input-table" }, [
                                  _c("div", { staticClass: "center" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.setting[index].chance_weight,
                                          expression:
                                            "setting[index].chance_weight",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        max: "1000000",
                                        min: "0",
                                        disabled:
                                          _vm.isDisable.specific_setting,
                                      },
                                      domProps: {
                                        value: _vm.setting[index].chance_weight,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submitSetting(
                                            "chance_weight",
                                            set,
                                            index
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.setting[index],
                                            "chance_weight",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("td", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.setting[index].meaning1,
                                        expression: "setting[index].meaning1",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      rows: "2",
                                      maxlength: "255",
                                      disabled: _vm.isDisable.specific_setting,
                                    },
                                    domProps: {
                                      value: _vm.setting[index].meaning1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.submitSetting(
                                          "meaning1",
                                          set,
                                          index
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.setting[index],
                                          "meaning1",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "space-top" }, [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.setting[index].meaning2,
                                          expression: "setting[index].meaning2",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        rows: "2",
                                        maxlength: "255",
                                        disabled:
                                          _vm.isDisable.specific_setting,
                                      },
                                      domProps: {
                                        value: _vm.setting[index].meaning2,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submitSetting(
                                            "meaning2",
                                            set,
                                            index
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.setting[index],
                                            "meaning2",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm.typeGame == "sustainable"
                    ? _c("div", { staticClass: "text-left" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Game duration (second)")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.game_duration,
                                  expression: "setting.game_duration",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "0",
                                max: "1000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.game_duration },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("game_duration")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "game_duration",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Average SF point")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.average_sf_point,
                                  expression: "setting.average_sf_point",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.average_sf_point },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("average_sf_point")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "average_sf_point",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("No. Cigarette per point")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.cigarettes_per_point,
                                  expression: "setting.cigarettes_per_point",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: {
                                value: _vm.setting.cigarettes_per_point,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting(
                                    "cigarettes_per_point"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "cigarettes_per_point",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("No. Cigarette per obstacle")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.cigarette_obstacle_ratio,
                                  expression:
                                    "setting.cigarette_obstacle_ratio",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: {
                                value: _vm.setting.cigarette_obstacle_ratio,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting(
                                    "cigarette_obstacle_ratio"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "cigarette_obstacle_ratio",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Penalty shell")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.penalty_shell,
                                  expression: "setting.penalty_shell",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "-1000",
                                max: "0",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.penalty_shell },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("penalty_shell")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "penalty_shell",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Penalty crab")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.penalty_crab,
                                  expression: "setting.penalty_crab",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "-1000",
                                max: "0",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.penalty_crab },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("penalty_crab")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "penalty_crab",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm.typeGame == "space"
                    ? _c("div", { staticClass: "text-left" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Base duration (second)")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.base_duration,
                                  expression: "setting.base_duration",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "0",
                                max: "300",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.base_duration },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("base_duration")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "base_duration",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Average SF point")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.base_sf_points,
                                  expression: "setting.base_sf_points",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.base_sf_points },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("base_sf_points")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "base_sf_points",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("No. VIP objects")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.no_vip_objects,
                                  expression: "setting.no_vip_objects",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "100",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.no_vip_objects },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("no_vip_objects")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "no_vip_objects",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("VIP object time (second) ")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.vip_object_time,
                                  expression: "setting.vip_object_time",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "100",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.vip_object_time },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("vip_object_time")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "vip_object_time",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Avg. obstacles per minute")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.avg_obstacles_per_min,
                                  expression: "setting.avg_obstacles_per_min",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: {
                                value: _vm.setting.avg_obstacles_per_min,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting(
                                    "avg_obstacles_per_min"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "avg_obstacles_per_min",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm.typeGame == "run"
                    ? _c("div", { staticClass: "text-left" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Base duration (second)")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.base_duration,
                                  expression: "setting.base_duration",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "0",
                                max: "300",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.base_duration },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("base_duration")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "base_duration",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Base SF point")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.base_sf_points,
                                  expression: "setting.base_sf_points",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.base_sf_points },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("base_sf_points")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "base_sf_points",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("No. VIP objects")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.no_vip_objects,
                                  expression: "setting.no_vip_objects",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "7",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.no_vip_objects },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("no_vip_objects")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "no_vip_objects",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("VIP object time (second) ")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.vip_object_time,
                                  expression: "setting.vip_object_time",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: { value: _vm.setting.vip_object_time },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("vip_object_time")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "vip_object_time",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 col-form-label" },
                            [_vm._v("Avg. obstacles per minute")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.avg_obstacles_per_min,
                                  expression: "setting.avg_obstacles_per_min",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "30",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: {
                                value: _vm.setting.avg_obstacles_per_min,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting(
                                    "avg_obstacles_per_min"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "avg_obstacles_per_min",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm.typeGame == "advocacy"
                    ? _c("div", { staticClass: "text-left" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 col-form-label" },
                            [_vm._v("Time per questions (second)")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.time_per_questions,
                                  expression: "setting.time_per_questions",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "0",
                                max: "300",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: {
                                value: _vm.setting.time_per_questions,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting("time_per_questions")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "time_per_questions",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-sm-3 col-form-label" },
                            [_vm._v("No. questions per game")]
                          ),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.setting.no_questions_per_game,
                                  expression: "setting.no_questions_per_game",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: "1",
                                max: "1000",
                                disabled: _vm.isDisable.specific_setting,
                              },
                              domProps: {
                                value: _vm.setting.no_questions_per_game,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.submitSetting(
                                    "no_questions_per_game"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.setting,
                                    "no_questions_per_game",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._m(3),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "text-align": "center" },
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c("h3", [_vm._v("Available list")]),
                              _c(
                                "div",
                                { staticClass: "scrollList" },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group",
                                      attrs: {
                                        list: _vm.list1,
                                        "ghost-class": "ghost",
                                        group: "people",
                                        disabled:
                                          _vm.isDisable.specific_setting,
                                      },
                                    },
                                    _vm._l(_vm.list1, function (question) {
                                      return _c(
                                        "div",
                                        {
                                          key: question.id,
                                          staticClass: "list-group-item",
                                          style:
                                            _vm.idChose.id == question.id
                                              ? "background-color: rgba(93, 93, 231, 0.616);"
                                              : null,
                                          on: {
                                            click: function ($event) {
                                              return _vm.OnClickChosen(
                                                question,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(question.name) +
                                              " - " +
                                              _vm._s(
                                                _vm.NameChar(question.character)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-2" }, [
                              _c("div", { staticClass: "row-center" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-round btn-default",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          _vm.onList != false ||
                                          _vm.isDisable.specific_setting,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.MoveListChosen(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-arrow-left",
                                        staticStyle: { "font-size": "48px" },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-round btn-default",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          _vm.onList != true ||
                                          _vm.isDisable.specific_setting,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.MoveListChosen(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-arrow-right",
                                        staticStyle: { "font-size": "48px" },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c("h3", [_vm._v("Chosen list")]),
                              _c(
                                "div",
                                { staticClass: "scrollList" },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group",
                                      attrs: {
                                        list: _vm.list2,
                                        "ghost-class": "ghost",
                                        group: "people",
                                        disabled:
                                          _vm.isDisable.specific_setting,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.chosenList()
                                        },
                                      },
                                    },
                                    _vm._l(_vm.list2, function (question) {
                                      return _c(
                                        "div",
                                        {
                                          key: question.id,
                                          staticClass: "list-group-item",
                                          style:
                                            _vm.idChose.id == question.id
                                              ? "background-color: rgba(93, 93, 231, 0.616);"
                                              : null,
                                          on: {
                                            click: function ($event) {
                                              return _vm.OnClickChosen(
                                                question,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(question.name) +
                                              " - " +
                                              _vm._s(
                                                _vm.NameChar(question.character)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("hr"),
                  _vm._m(4),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-6 mb-1" }, [
                      _c("div", { staticClass: "text-left" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.game.redirect_url,
                              expression: "game.redirect_url",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            maxlength: "255",
                            disabled: _vm.isDisable.url,
                          },
                          domProps: { value: _vm.game.redirect_url },
                          on: {
                            change: function ($event) {
                              return _vm.submit("redirect_url")
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.game,
                                "redirect_url",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(5),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [_vm._v("Game Instance Name")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-con" }, [
      _c("label", [_vm._v("No. play per day")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
          _vm._v("Label"),
        ]),
        _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
          _vm._v("Point"),
        ]),
        _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
          _vm._v(" Chance Weight "),
        ]),
        _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
          _vm._v("Meaning"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-sm-2 col-form-label" }, [
        _vm._v("Questions List"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Redirect URL")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
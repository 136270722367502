import { progressBarService } from "@/api/client";

export default {
  props: ["index", "campaign", "reward"],

  data() {
    return {
      message: {
        error: "Update error.",
        success: "Update success.",
      },
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
    isNonPanelUser() {
      return this.campaign.user_type !== "contact_id";
    },
  },
  methods: {
    // event: original event
    // type: input type
    // modelValue: input v-model
    async update(event, inputName, modelValue) {
      try {
        const { id } = this.campaign;

        const name = inputName || event.target.name;
        const value = modelValue != undefined ? modelValue : event.target.value;

        const form = {};
        form[name] = value;

        await progressBarService.updateReward(
          id,
          "stamp",
          this.reward.id,
          form
        );

        this.$emit("updated");
        this.$notify(this.message.success);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || this.message.error);
      }
    },

    async uploadImage(event) {
      const file = event.target.files[0];

      try {
        if (!file) return;

        this.validateFileUpload(event);

        const { id } = this.campaign;

        const name = event.target.name;

        const form = new FormData();
        form.append(name, file);

        await progressBarService.fileUploadReward(
          id,
          "stamp",
          this.reward.id,
          form
        );

        this.$emit("updated");
        this.$notify(this.message.success);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || this.message.error);
      }
    },

    selected(n) {
      const selected = this.campaign.stamp.reward.map((o) => o.stamp_no);

      const index = selected
        .filter((o) => o != this.reward.stamp_no)
        .findIndex((o) => o === n);

      return index >= 0;
    },

    validateFileUpload() {
      return;
    },

    initial() {
      return;
    },
  },

  mounted() {
    this.initial();
  },
};

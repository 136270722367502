<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Transaction Log</h1>
      <breadcrumb name="WEEKLY_LOGIN_TRANSACTION_LOG"></breadcrumb>
      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="fromTo"
          type="date"
          @change="onPeriodChange"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :columns="columns"
              :rows="list"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-search="onSearch"
              :isLoading="loadingTable"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button class="btn btn-default" @click="exportToExcel">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div></vue-good-table
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { weeklyLoginService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { transactionLogTableColumns } from "@/views/weeklylogin/transaction-log/model";

export default {
  mixins: [vueGoodTableMixins],
  data() {
    return {
      list: [],
      fromTo: [],
      type: 1,
      page: 1,
      loadingTable: false,
      columns: transactionLogTableColumns,
    };
  },
  watch: {
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
    fromTo() {
      this.onPeriodChange();
    },
  },
  methods: {
    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
          }),
        })
        .catch((err) => {});
    },
    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }
      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },
    async fetch() {
      try {
        this.loadingTable = true;
        if (this.fromTo.length != 2) return;
        const params = this.getParams();
        const { data, total_records } =
          await weeklyLoginService.getTransactionLog(params);
        this.list = data;
        this.totalRecords = total_records;
      } catch (error) {
        console.log(error);
      } finally {
        await this.wait(300);
        this.loadingTable = false;
      }
    },
    async exportToExcel() {
      if (this.fromTo.length != 2) return;
      const params = this.getParams();
      try {
        const response = await weeklyLoginService.downloadTransactionLog(
          params
        );
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `weeklylogin_txlog_${moment().format(
          "YYYYMMDD_HHmmss"
        )}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
</style>

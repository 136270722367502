<template>
  <div class="page">
    <div class="page-main">
      <div class="page-header">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <h1 class="page-title">Product</h1>
          <breadcrumb
            name="PRODUCT_EDIT"
            :withLast.sync="product.name"
          ></breadcrumb>
          <div class="page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Product Information - {{ product.id }}
                </h3>

                <div class="panel-actions panel-actions-keep">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-primary btn-round dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ product.status | toUpperCase }}
                    </button>
                    <div class="dropdown-menu" role="menu">
                      <a
                        class="dropdown-item"
                        :class="
                          product.status == 'draft' ||
                          product.status == 'published'
                            ? 'disabled'
                            : ''
                        "
                        href="javascript:void(0)"
                        role="menuitem"
                        @click="setStatus('draft')"
                      >
                        <i class="icon md-border-color" aria-hidden="true"></i
                        >Draft
                      </a>
                      <a
                        class="dropdown-item"
                        :class="product.status != 'draft' ? 'disabled' : ''"
                        href="javascript:void(0)"
                        role="menuitem"
                        @click="setStatus('published')"
                      >
                        <i class="icon md-long-arrow-up" aria-hidden="true"></i
                        >Publish
                      </a>
                      <a
                        class="dropdown-item"
                        :class="
                          product.status == 'draft' ||
                          product.status == 'archived' ||
                          product.status == 'unpublished'
                            ? 'disabled'
                            : ''
                        "
                        href="javascript:void(0)"
                        role="menuitem"
                        @click="setStatus('draft')"
                      >
                        <i
                          class="icon md-long-arrow-down"
                          aria-hidden="true"
                        ></i
                        >Unpublish
                      </a>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item"
                        :class="
                          product.status == 'archived' ||
                          product.status == 'published'
                            ? 'disabled'
                            : ''
                        "
                        href="javascript:void(0)"
                        role="menuitem"
                        @click="setStatus('archived')"
                      >
                        <i class="icon md-archive" aria-hidden="true"></i
                        >Archive
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="panel-body">
                <div class="form-horizontal">
                  <div class="form-group row mb-50">
                    <label class="col-md-3 form-control-label"
                      >Product Name</label
                    >
                    <div class="col-md-9">
                      <input
                        ref="name"
                        type="text"
                        class="form-control"
                        v-model="product.name"
                        @change="submit('name')"
                        autocomplete="off"
                      />
                      <NameValidation :$v="$v" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 form-control-label"
                      >Product Description</label
                    >
                    <div class="col-md-9">
                      <ckeditor
                        :config="editorConfig"
                        v-model="product.description"
                        @input="submit('description')"
                      ></ckeditor>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-3 form-control-label">Product Image</div>
                    <div class="col-md-9">
                      <div class="row">
                        <div v-for="n in images" :key="n" class="col-6">
                          <div v-if="product[`image${n}`]">
                            <label
                              v-if="product[`image${n}`]"
                              class="form-control-label"
                            >
                              <span>Image {{ n }}</span>
                            </label>

                            <figure
                              class="overlay overlay-hover img-rounded img-bordered"
                            >
                              <img
                                :src="product[`image${n}`]"
                                class="overlay-figure product-image"
                              />

                              <figcaption
                                class="overlay-panel overlay-background overlay-fade overlay-icon"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline btn-inverse vertical-align-middle"
                                  @click="changeImage(`image${n}`)"
                                >
                                  Change Image
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline btn-inverse vertical-align-middle mx-10"
                                  @click="confirmRemove(`image${n}`)"
                                >
                                  Remove Image
                                </button>
                              </figcaption>
                            </figure>
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="product.image4 == null" class="form-group row">
                    <label class="col-md-3 form-control-label"></label>
                    <div class="col-md-9">
                      <button
                        type="button"
                        @click="openUploadModal"
                        class="btn btn-round btn-default"
                      >
                        Add image
                      </button>
                    </div>
                  </div>
                </div>

                <hr class="mb-30" />
                <ProductType :product="product" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal name="upload-product-modal" :width="500" height="auto">
      <BaseCropUpload @onCrop="onCrop" @close="closeUploadModal" />
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { productService, uploadService } from "@/api/client";
import ProductType from "./components/ProductType.vue";
import NameValidation from "./validation/name.vue";
import {
  errorMessage,
  FormEditValidateRule as validateRules,
} from "./validation";

import { defaultEditorConfig, ProductModel, fileUploadOptions } from "./model";

export default {
  name: "ProductEdit",
  data() {
    return {
      firstTimeChange: true,
      onChangeImageId: null,
      images: _.range(1, 5, 1),

      fileUploadOptions,
      editorConfig: defaultEditorConfig,

      product: new ProductModel(),
    };
  },

  validations: { product: validateRules },

  components: {
    ProductType,
    NameValidation,
  },

  async mounted() {
    this.product = await productService.getById(this.$route.params.id);
    this.title = this.product.name;
  },

  methods: {
    openUploadModal() {
      this.$modal.show(`upload-product-modal`);
    },

    closeUploadModal() {
      this.$modal.hide("upload-product-modal");
    },

    async isFormValid() {
      if (this.$v.product.name.$invalid) {
        await this.$dialogs.alert(errorMessage.name.default);
        this.$refs.name.focus();
        return false;
      }

      return true;
    },

    goBack() {
      this.$router.go(-1);
    },

    async setStatus(status) {
      try {
        const form = new FormData();
        form.set("status", status);

        if (status == this.product.status) return;

        const body = JSON.stringify(Object.fromEntries(form));
        await productService.editProduct(this.product.id, JSON.parse(body));

        this.$notify("Change status success");
        this.product.status = status;
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    async submit(name) {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        if (name == `description` && this.firstTimeChange) {
          this.firstTimeChange = false;
          return;
        }

        const form = new FormData();
        form.set(name, this.product[name]);

        const body = JSON.stringify(Object.fromEntries(form));
        await productService.editProduct(this.product.id, JSON.parse(body));

        this.$notify("Updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    async onCrop(image) {
      this.$modal.hide("upload-product-modal");
      if (!image) return;

      if (this.onChangeImageId) {
        await this.upload(this.onChangeImageId, image);
        this.onChangeImageId = null;
        this.product = await productService.getById(this.$route.params.id);
        return;
      } else {
        const count = document.querySelectorAll(".product-image").length;
        const imageId = `image${count + 1}`;
        await this.upload(imageId, image);
        this.product = await productService.getById(this.$route.params.id);
      }
    },

    async upload(imageId, image) {
      try {
        if (!image) return;

        let form = new FormData();
        form.append(imageId, image);

        const result = await uploadService.upload(
          `/ams/api/product/${this.product.id}/upload`,
          form
        );

        this.$notify("Updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    changeImage(imageId) {
      this.onChangeImageId = imageId;
      this.openUploadModal();
    },

    confirmRemove(imageId) {
      const r = confirm("Do you really want to delete?");
      if (r == true) {
        this.removeImage(imageId);
      }
    },

    onRemoveImage(imageId) {
      let body = {};
      switch (imageId) {
        case "image1":
          body = {
            image1: this.product.image2,
            image2: this.product.image3,
            image3: this.product.image4,
            image4: null,
          };
          break;
        case "image2":
          body = {
            image2: this.product.image3,
            image3: this.product.image4,
            image4: null,
          };
          break;
        case "image3":
          body = {
            image3: this.product.image4,
            image4: null,
          };
          break;
        case "image4":
          body = {
            image4: null,
          };
          break;
      }

      return body;
    },

    async removeImage(imageId) {
      try {
        let body = this.onRemoveImage(imageId);
        await productService.editProduct(this.product.id, body);
        this.product = await productService.getById(this.$route.params.id);
        this.$notify("Updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

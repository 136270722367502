var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme
    ? _c(
        "div",
        { staticClass: "page vld-parent" },
        [
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              "is-full-page": false,
              loader: "bars",
            },
          }),
          _c("div", { staticClass: "page-main" }, [
            _c("div", { staticClass: "page-header" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                },
                [
                  _c("h1", { staticClass: "page-title" }, [_vm._v("Theme")]),
                  _c("breadcrumb", {
                    attrs: {
                      name: "GAME_SAMURAI_THEMES_EDIT",
                      withLast: _vm.theme.name,
                    },
                    on: {
                      "update:withLast": function ($event) {
                        return _vm.$set(_vm.theme, "name", $event)
                      },
                      "update:with-last": function ($event) {
                        return _vm.$set(_vm.theme, "name", $event)
                      },
                    },
                  }),
                  _c("div", { staticClass: "page-title page-header-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-round btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.generatePreviewUrl },
                      },
                      [
                        _c("i", {
                          staticClass: "icon mdi mdi-controller mdi-18px mx-2",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" Preview "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-round btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.goBack },
                      },
                      [
                        _c("i", {
                          staticClass: "icon mdi mdi-chevron-left mdi-18px",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" Back "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "page-content container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                  },
                  [
                    _c("div", { staticClass: "panel panel-bordered" }, [
                      _c("div", { staticClass: "panel-heading" }, [
                        _c("h3", { staticClass: "panel-title" }, [
                          _vm._v("Theme Information - " + _vm._s(_vm.theme.id)),
                        ]),
                        _c("div", {
                          staticClass: "panel-actions panel-actions-keep",
                        }),
                      ]),
                      _c("div", { staticClass: "panel-body" }, [
                        _vm.theme
                          ? _c(
                              "div",
                              { staticClass: "form-horizontal" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-6" },
                                    [
                                      _c("ThemeName", {
                                        attrs: { theme: _vm.theme },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-3" },
                                    [
                                      _c("StatusDropdown", {
                                        attrs: { theme: _vm.theme },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("hr"),
                                _c("h5", [_vm._v("Background & Elements")]),
                                _c("PictureControl", {
                                  attrs: {
                                    theme: _vm.theme,
                                    config: _vm.fields.background_picture,
                                  },
                                  on: { updated: _vm.fetchTheme },
                                }),
                                _c("br"),
                                _c("PictureControl", {
                                  attrs: {
                                    theme: _vm.theme,
                                    config: _vm.fields.title,
                                  },
                                  on: { updated: _vm.fetchTheme },
                                }),
                                _c("br"),
                                _c("PictureControl", {
                                  attrs: {
                                    theme: _vm.theme,
                                    config: _vm.fields.summary_picture,
                                  },
                                  on: { updated: _vm.fetchTheme },
                                }),
                                _c("br"),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields
                                              .button_leaderboard_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields.button_play_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields
                                              .button_play_disabled_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.button_x_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.clock_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("h5", [_vm._v("Bomb Animation")]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.bomb1_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.bomb2_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.bomb3_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.explode_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("h5", [_vm._v("VIP Animation")]),
                                _c("PictureControl", {
                                  attrs: {
                                    theme: _vm.theme,
                                    config: _vm.fields.flash_picture,
                                  },
                                  on: { updated: _vm.fetchTheme },
                                }),
                                _c("br"),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_full_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_break_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_top_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_bottom_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_left_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config: _vm.fields.vip_right_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields.vip_top_right_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields.vip_bottom_left_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields.vip_top_left_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("PictureControl", {
                                        attrs: {
                                          theme: _vm.theme,
                                          config:
                                            _vm.fields.vip_bottom_right_picture,
                                        },
                                        on: { updated: _vm.fetchTheme },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._l(3, function (n) {
                                  return _c("div", { key: n }, [
                                    _c("h5", [
                                      _vm._v("SKU" + _vm._s(n) + " Animation"),
                                    ]),
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" + n + "_full_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" + n + "_break_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" + n + "_top_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" + n + "_bottom_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" + n + "_left_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" + n + "_right_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" +
                                                    n +
                                                    "_top_right_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" +
                                                    n +
                                                    "_bottom_left_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" +
                                                    n +
                                                    "_top_left_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6" },
                                        [
                                          _c("PictureControl", {
                                            attrs: {
                                              theme: _vm.theme,
                                              config:
                                                _vm.fields[
                                                  "sku" +
                                                    n +
                                                    "_bottom_right_picture"
                                                ],
                                            },
                                            on: { updated: _vm.fetchTheme },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ])
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                      _vm._m(0),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c(
            "modal",
            {
              attrs: { width: "430px", height: "932px", name: "preview-modal" },
            },
            [
              _c("iframe", {
                staticStyle: { border: "none" },
                attrs: {
                  src: _vm.previewUrl,
                  title: "Samurai Demo",
                  width: "100%",
                  height: "100%",
                },
              }),
            ]
          ),
          _c(
            "modal",
            {
              attrs: {
                width: "320px",
                height: "auto",
                name: "preview-choice-modal",
              },
            },
            [
              _c("PreviewChoiceModal", {
                attrs: { url: _vm.previewUrl },
                on: { selected: _vm.preview },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="col-md-12" v-if="campaign">
    <div class="">
      <table class="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td class="px-0"><h5>Grand Prize</h5></td>
            <td>Name</td>
            <td>
              <input
                type="text"
                maxlength="40"
                class="form-control"
                :disabled="!canEdit"
                v-model.number="campaign.grandprize_name"
                @change="update('grandprize_name')"
              />
            </td>
            <td rowspan="2">
              <div class="d-flex flex-column gap-5 px-20">
                <div class="reward-choice">
                  <input
                    value="other"
                    type="radio"
                    :disabled="!canEdit"
                    v-model="campaign.grandprize_type"
                    :checked="campaign.grandprize_type === 'other'"
                    @change="update('grandprize_type')"
                    name="grandprize_type"
                    :id="`reward-choice-physical-grandprize_type`"
                  />
                  <label
                    :for="`reward-choice-physical-grandprize_type`"
                    class="input-label"
                  >
                    Physical
                  </label>
                </div>
                <div class="reward-choice">
                  <input
                    value="sfdc"
                    type="radio"
                    :disabled="!canEdit"
                    :checked="campaign.grandprize_type === 'sfdc'"
                    v-model="campaign.grandprize_type"
                    @change="update('grandprize_type')"
                    name="grandprize_type"
                    :id="`reward-choice-sf-point-grandprize_type`"
                  />
                  <div class="d-flex">
                    <label
                      class="input-label"
                      :for="`reward-choice-sf-point-grandprize_type`"
                    >
                      SF Point
                    </label>
                    <input
                      :min="1"
                      max="1000000"
                      type="number"
                      class="form-control"
                      style="width: 120px"
                      v-model.number="campaign.grandprize_points"
                      @change="update('grandprize_points')"
                      :disabled="
                        !canEdit || campaign.grandprize_type !== 'sfdc'
                      "
                    />
                  </div>
                </div>
                <div class="reward-choice flex-align-top">
                  <input
                    value="ecoupon"
                    type="radio"
                    :disabled="!canEdit"
                    :checked="campaign.grandprize_type === 'ecoupon'"
                    v-model="campaign.grandprize_type"
                    @change="update('grandprize_type')"
                    name="grandprize_type"
                    :id="`reward-choice-e-coupon-grandprize_type`"
                  />
                  <div>
                    <div class="d-flex flex-align-top mb-10">
                      <label
                        class="input-label"
                        :for="`reward-choice-e-coupon-grandprize_type`"
                      >
                        E-Coupon
                      </label>
                      <div class="face-value-form">
                        <div class="d-flex">
                          <label
                            class="input-label"
                            :for="`reward-choice-face-value-grandprize_type`"
                          >
                            Face Value
                          </label>
                          <input
                            min="1"
                            max="999999"
                            type="number"
                            class="form-control"
                            v-model.number="campaign.grandprize_face_value"
                            @change="update('grandprize_face_value')"
                            :disabled="
                              !canEdit || campaign.grandprize_type !== 'ecoupon'
                            "
                            :id="`reward-choice-face-value-grandprize_type`"
                          />
                          <span>bht</span>
                        </div>
                        <div class="d-flex">
                          <label
                            class="input-label"
                            :for="`reward-choice-topup-grandprize_type`"
                          >
                            Top Up
                          </label>
                          <input
                            min="0"
                            max="999999"
                            type="number"
                            class="form-control"
                            v-model.number="campaign.grandprize_topup"
                            @change="update('grandprize_topup')"
                            :disabled="
                              !canEdit || campaign.grandprize_type !== 'ecoupon'
                            "
                            :id="`reward-choice-topup-grandprize_type`"
                          />
                          <span>bht</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-align-top">
                      <label
                        class="input-label line-high-1"
                        :for="`reward-choice-custom-text-grandprize_type`"
                      >
                        Custom text<br />on coupon
                      </label>
                      <input
                        type="text"
                        maxlength="40"
                        class="form-control w250"
                        v-model="campaign.grandprize_custom_text"
                        @change="update('grandprize_custom_text')"
                        :disabled="
                          !canEdit || campaign.grandprize_type !== 'ecoupon'
                        "
                        :id="`reward-choice-custom-text-grandprize_type`"
                      />
                    </div>
                  </div>
                </div>
                <div class="reward-choice">
                  <input
                    value="prizepool"
                    type="radio"
                    :disabled="!canEdit"
                    v-model="campaign.grandprize_type"
                    :checked="campaign.grandprize_type === 'prizepool'"
                    @change="update('grandprize_type')"
                    name="grandprize_type"
                    :id="`reward-choice-prizepool-grandprize_type`"
                  />
                  <div class="d-flex">
                    <label
                      for="reward-choice-prizepool-grandprize_type"
                      class="input-label"
                    >
                      Prize Pool
                    </label>
                    <div class="d-flex">
                      <label
                        class="grandprize_theme_id"
                        for="grandprize_theme_id"
                      >
                        Prize Theme ID
                      </label>
                      <select
                        class="form-control"
                        name="grandprize_theme_id"
                        id="grandprize_theme_id"
                        v-model.number="campaign.grandprize_theme_id"
                        @change="update('grandprize_theme_id')"
                        :disabled="
                          !canEdit || campaign.grandprize_type !== 'prizepool'
                        "
                      >
                        <option :value="null" disabled>Not Select</option>
                        <option
                          v-for="prize in prizepool"
                          :value="prize.id"
                          :key="prize.id"
                        >
                          {{ prize.id }} - {{ prize.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Details</td>
            <td>
              <textarea
                rows="5"
                class="form-control"
                :disabled="!canEdit"
                v-model.number="campaign.grandprize_details"
                @change="update('grandprize_details')"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Picture</td>
            <td>
              <GrandPrizePicture
                :campaign="campaign"
                @updated="$emit('updated')"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  </div>
</template>

<script>
import { weeklyLoginService, progressBarService } from "@/api/client";

import GrandPrizePicture from "./GrandPrizePicture.vue";

export default {
  name: "GrandPrize",

  props: ["campaign"],

  components: {
    GrandPrizePicture,
  },

  data() {
    return {
      id: null,
      prizepool: [],
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  methods: {
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    async isFormValid(name) {
      const value = this.campaign[name];

      switch (name) {
        case "grandprize_face_value":
          if (+value < 1 || +value > 999999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Face value เป็นเลขจำนวนเต็ม ที่ 1 - 999,999`
            );
            return false;
          }
          break;

        case "grandprize_topup":
          if (+value < 0 || +value > 999999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Topup เป็นเลขจำนวนเต็ม ที่ 0 - 999,999`
            );
            return false;
          }
          break;

        case "grandprize_custom_text":
          if (value.length > 40) {
            await this.$dialogs.alert(
              `กรุณาระบุ Custom text ความยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;

        case "grandprize_points":
          const minimum = 1;
          if (+value < minimum || +value > 1000000 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ SF Point เป็นเลขจำนวนเต็ม ที่ ${minimum} - 1000000`
            );
            return false;
          }
          break;

        case "grandprize_name":
          if (!value || value.length > 40) {
            await this.$dialogs.alert(
              `กรุณาระบุ Grand Prize Name เป็นข้อความที่ยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;
      }

      return true;
    },

    async update(name) {
      const formValid = await this.isFormValid(name);
      if (!formValid) return;

      try {
        const form = {};
        form[name] = this.campaign[name];

        const { id } = this.campaign;

        await weeklyLoginService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`Grand Prize updated.`);
      } catch (error) {
        this.$dialogs.alert(`Grand Prize update error.`);
      }
    },

    async fetchPrizePool() {
      this.prizepool = await progressBarService.getPrizePoolList();
    },
  },

  async mounted() {
    await this.fetchPrizePool();
    this.id = this.campaign.id;
  },
};
</script>

<style lang="scss" scoped>
.reward-choice {
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .d-flex {
    align-items: center;
    justify-content: flex-start;
  }

  .input-label {
    width: 100px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 0px;
  }

  input[type="number"] {
    max-width: 120px;
    margin-right: 1em;
  }

  label.grandprize_theme_id {
    width: 180px;
    flex: 1 1 auto;
    margin-bottom: 0px;
  }

  .flex-align-top,
  &.flex-align-top {
    align-items: flex-start;
  }

  .face-value-form {
    gap: 0.5em;
    display: flex;
    min-width: 240px;
    flex-direction: column;
  }

  .line-high-1 {
    line-height: 1;
  }

  .w250 {
    width: 250px;
  }
}

td:nth-child(3) {
  min-width: 300px;
}
</style>

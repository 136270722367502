<template>
  <div class="col-md-12" v-if="campaign">
    <label class="form-control-label">
      <h4>Prize</h4>
    </label>
    <div class="ml-20" v-for="prize in campaign.prize" :key="prize.prize_order">
      <table class="table table-responsive table-borderless">
        <tbody>
          <tr v-if="prize.prize_order === 11">
            <td colspan="4"><h5>Consolation Prize (SF Point)</h5></td>
          </tr>
          <tr>
            <td>
              {{ prize.prize_order !== 11 ? `No. ${prize.prize_order}` : `` }}
            </td>
            <td>Lucky Number</td>
            <td>
              <input
                min="0"
                max="999"
                type="number"
                class="form-control"
                :disabled="!canEdit"
                v-model.number="prize.lucky_num"
                @change="update('lucky_num', prize)"
              />
            </td>
            <td>
              <div class="reward-choice">
                <input
                  value="0"
                  type="radio"
                  :disabled="!canEdit"
                  v-model.number="prize.lucky_onoff"
                  :checked="prize.lucky_onoff === 0"
                  @change="update('lucky_onoff', prize)"
                  :name="`reward-choice-${prize.prize_order}`"
                  :id="`reward-choice-physical-${prize.prize_order}`"
                />
                <label
                  :for="`reward-choice-physical-${prize.prize_order}`"
                  class="input-label"
                >
                  Physical
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Name</td>
            <td>
              <input
                type="text"
                maxlength="40"
                class="form-control"
                :disabled="!canEdit"
                v-model.number="prize.prize_name"
                @change="update('prize_name', prize)"
              />
            </td>
            <td>
              <div class="reward-choice">
                <input
                  value="1"
                  type="radio"
                  :disabled="!canEdit"
                  :checked="prize.lucky_onoff === 1"
                  v-model.number="prize.lucky_onoff"
                  @change="update('lucky_onoff', prize)"
                  :name="`reward-choice-${prize.prize_order}`"
                  :id="`reward-choice-sf-point-${prize.prize_order}`"
                />
                <div class="d-flex">
                  <label
                    class="input-label"
                    :for="`reward-choice-sf-point-${prize.prize_order}`"
                  >
                    SF Point
                  </label>
                  <input
                    :min="prize.prize_order < 11 ? 1 : 0"
                    max="1000000"
                    type="number"
                    class="form-control"
                    style="width: 120px"
                    v-model.number="prize.sfdc_point"
                    @change="update('sfdc_point', prize)"
                    :disabled="!canEdit || +prize.lucky_onoff !== 1"
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Details</td>
            <td>
              <textarea
                rows="3"
                class="form-control"
                :disabled="!canEdit"
                v-model.number="prize.prize_detail"
                @change="update('prize_detail', prize)"
              />
            </td>
            <td>
              <div class="reward-choice flex-align-top">
                <input
                  value="2"
                  type="radio"
                  :disabled="!canEdit"
                  :checked="prize.lucky_onoff === 2"
                  v-model.number="prize.lucky_onoff"
                  @change="update('lucky_onoff', prize)"
                  :name="`reward-choice-${prize.prize_order}`"
                  :id="`reward-choice-e-coupon-${prize.prize_order}`"
                />
                <div>
                  <div class="d-flex flex-align-top mb-10">
                    <label
                      class="input-label"
                      :for="`reward-choice-e-coupon-${prize.prize_order}`"
                    >
                      E-Coupon
                    </label>
                    <div class="face-value-form">
                      <div class="d-flex">
                        <label
                          class="input-label"
                          :for="`reward-choice-face-value-${prize.prize_order}`"
                        >
                          Face Value
                        </label>
                        <input
                          min="1"
                          max="999999"
                          type="number"
                          class="form-control"
                          v-model.number="prize.face_value"
                          @change="update('face_value', prize)"
                          :disabled="!canEdit || prize.lucky_onoff !== 2"
                          :id="`reward-choice-face-value-${prize.prize_order}`"
                        />
                        <span>bht</span>
                      </div>
                      <div class="d-flex">
                        <label
                          class="input-label"
                          :for="`reward-choice-topup-${prize.prize_order}`"
                        >
                          Top Up
                        </label>
                        <input
                          min="0"
                          max="999999"
                          type="number"
                          class="form-control"
                          v-model.number="prize.topup"
                          @change="update('topup', prize)"
                          :disabled="!canEdit || prize.lucky_onoff !== 2"
                          :id="`reward-choice-topup-${prize.prize_order}`"
                        />
                        <span>bht</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-align-top">
                    <label
                      class="input-label line-high-1"
                      :for="`reward-choice-custom-text-${prize.prize_order}`"
                    >
                      Custom text<br />on coupon
                    </label>
                    <input
                      type="text"
                      maxlength="40"
                      class="form-control w250"
                      v-model="prize.custom_text"
                      @change="update('custom_text', prize)"
                      :disabled="!canEdit || prize.lucky_onoff !== 2"
                      :id="`reward-choice-custom-text-${prize.prize_order}`"
                    />
                  </div>
                </div>
              </div>

              <div class="reward-choice">
                <input
                  value="4"
                  type="radio"
                  :disabled="!canEdit"
                  v-model.number="prize.lucky_onoff"
                  :checked="prize.lucky_onoff === 4"
                  @change="update('lucky_onoff', prize)"
                  :id="`reward-choice-prizepool-${prize.prize_order}`"
                />
                <label
                  :for="`reward-choice-prizepool-${prize.prize_order}`"
                  class="input-label"
                >
                  Prize Pool
                </label>
                <div class="d-flex gap-1rem">
                  <label
                    class="prize_theme_id"
                    :for="`reward-choice-prize_theme_id_${prize.prize_order}`"
                  >
                    Prize Theme ID
                  </label>
                  <select
                    class="form-control"
                    name="prize_theme_id"
                    v-model.number="prize.prize_theme_id"
                    @change="update('prize_theme_id', prize)"
                    :disabled="!canEdit || prize.lucky_onoff !== 4"
                    :id="`reward-choice-prize_theme_id_${prize.prize_order}`"
                  >
                    <option :value="null" disabled>Not Select</option>
                    <option
                      v-for="prize in prizepool"
                      :value="prize.id"
                      :key="prize.id"
                    >
                      {{ prize.id }} - {{ prize.name }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="prize.prize_order !== 11">
            <td></td>
            <td>No. Items</td>
            <td colspan="2">
              <input
                min="1"
                type="number"
                class="form-control"
                :disabled="!canEdit"
                style="width: 120px"
                v-model.number="prize.num_items"
                @change="update('num_items', prize)"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Chance Weight</td>
            <td colspan="2">
              <input
                type="number"
                min="1"
                max="1000000"
                class="form-control"
                :disabled="!canEdit"
                style="width: 120px"
                v-model.number="prize.chance_weight"
                @change="update('chance_weight', prize)"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td colspan="3">
              <PrizePicture
                :prize="prize"
                :campaign="campaign"
                @updated="$emit('updated')"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  </div>
</template>

<script>
import { luckyDrawService } from "@/api/client";

import PrizePicture from "./PrizePicture.vue";

export default {
  name: "PrizeItem",

  props: ["campaign"],

  components: {
    PrizePicture,
  },

  data() {
    return {
      id: null,
      prizepool: [],
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  methods: {
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    async isFormValid(key, prize) {
      const value = prize[key];

      switch (key) {
        case "lucky_num":
          if (+value < 0 || +value > 999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Lucky Number เป็นเลขจำนวนเต็ม ที่ 0 - 999`
            );
            return false;
          }
          break;

        case "face_value":
          if (+value < 1 || +value > 999999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Face value เป็นเลขจำนวนเต็ม ที่ 1 - 999,999`
            );
            return false;
          }
          break;

        case "topup":
          if (+value < 0 || +value > 999999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Topup เป็นเลขจำนวนเต็ม ที่ 0 - 999,999`
            );
            return false;
          }
          break;

        case "custom_text":
          if (value.length > 40) {
            await this.$dialogs.alert(
              `กรุณาระบุ Custom text ความยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;

        case "sfdc_point":
          const minimum = +prize.prize_order === 11 ? 0 : 1;
          if (+value < minimum || +value > 1000000 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ SF Point เป็นเลขจำนวนเต็ม ที่ ${minimum} - 1000000`
            );
            return false;
          }
          break;

        case "prize_name":
          if (!value || value.length > 40) {
            await this.$dialogs.alert(
              `กรุณาระบุ Prize Name เป็นข้อความที่ยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;

        case "num_items":
          if (+value <= 0 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ No. Items เป็นเลขจำนวนเต็ม ที่มากกว่า 0`
            );
            return false;
          }
          break;

        case "chance_weight":
          if (+value <= 0 || +value > 1000000 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Chance Weight เป็นเลขจำนวนเต็ม ที่ 1 - 1000000`
            );
            return false;
          }
          break;
      }

      return true;
    },

    async update(key, prize) {
      try {
        const formValid = await this.isFormValid(key, prize);
        if (!formValid) return;

        const { id } = this.campaign;

        const form = {
          prize_order: prize.prize_order,
        };

        form[key] = prize[key];

        await luckyDrawService.updatePrizeItem(id, form);

        this.$emit("updated");
        this.$notify(`Prize updated.`);
      } catch (error) {
        this.$dialogs.alert(`Prize update error.`);
      }
    },

    async fetchPrizePool() {
      this.prizepool = await luckyDrawService.getActiveThemeList();
    },
  },

  async mounted() {
    await this.fetchPrizePool();
    this.id = this.campaign.id;
  },
};
</script>

<style lang="scss" scoped>
table tr td {
  &:nth-child(3) {
    width: 40%;
  }
  &:nth-child(4) {
    width: 400px;
    text-align: right;
    vertical-align: middle;

    .form-control {
      display: inline-block;
    }
  }
}

.reward-choice {
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .d-flex {
    align-items: center;
    justify-content: flex-start;
  }

  .gap-1rem {
    gap: 1rem;
  }

  .input-label {
    width: 100px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 0px;
  }

  input[type="number"] {
    max-width: 120px;
    margin-right: 1em;
  }

  label.prize_theme_id {
    width: 180px;
    flex: 1 1 auto;
    margin-bottom: 0px;
  }

  .flex-align-top,
  &.flex-align-top {
    align-items: flex-start;
  }

  .face-value-form {
    gap: 0.5em;
    display: flex;
    min-width: 240px;
    flex-direction: column;
  }

  .line-high-1 {
    line-height: 1;
  }

  .w250 {
    width: 250px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "form-group-row" }, [
          _c("label", { staticClass: "mb-0" }, [_vm._v("No. Stamp")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.stamp.no_stamp,
                  expression: "stamp.no_stamp",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "no_stamp", disabled: _vm.disabled },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.$set(
                      _vm.stamp,
                      "no_stamp",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  _vm.update,
                ],
              },
            },
            _vm._l(_vm.items, function (value, index) {
              return _c(
                "option",
                {
                  key: index,
                  attrs: { disabled: _vm.disabledOption(value) },
                  domProps: { value: value },
                },
                [_vm._v(" " + _vm._s(value) + " ")]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const defaultEditorConfig = {
  toolbarGroups: [
    { name: "clipboard", groups: ["clipboard", "undo"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
    },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],
};

export const campaignTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Campaign Name",
    field: "name",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Start",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

export const userReportTableColumns = [
  {
    label: "ISMS Code",
    field: "isms_code",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "E-mail",
    field: "email",
  },
  {
    label: "First Name",
    field: "firstname",
  },
  {
    label: "Last Name",
    field: "lastname",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Balance",
    field: "balance",
  },
];

export const transactionLogTableColumns = [
  {
    label: "ISMS Code",
    field: "isms_code",
  },
  {
    label: "Timestamp",
    field: "event_time",
  },
  {
    label: "User ID",
    field: "sfdc_user_id",
  },
  {
    label: "First Name",
    field: "firstname",
  },
  {
    label: "Last Name",
    field: "lastname",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "TX type",
    field: "tx_type",
  },
  {
    label: "Details",
    field: "detail",
  },
  {
    label: "Amount",
    field: "amount",
  },
  {
    label: "Balance",
    field: "balance",
  },
];

export const bulkOperationTableColumns = [
  {
    label: "Task ID",
    field: "id",
    type: "number",
  },
  {
    label: "Task Name",
    field: "name",
    tdClass: "task-name",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Last Modified By",
    field: "modified_by_username",
  },
  {
    label: "Action",
    field: "action",
    width: "400px",
    sortable: false,
  },
];

export const pointQuotaLimitTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Summary",
    field: "name",
  },
  {
    label: "Type",
    field: "qt_type",
    formatFn: function (value) {
      if (value == "points") {
        return "Get coin";
      }

      if (value == "coins") {
        return "Discount";
      }
    },
  },
  {
    label: "Quota (coins)",
    field: "amt_max",
    type: "number",
  },
  {
    label: "Remaining",
    field: "amt_remain",
    type: "number",
  },
  {
    label: "Start",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
    formatFn: function (value) {
      return value == null ? "n/a" : value;
    },
  },
  {
    label: "Status",
    field: "status",
    formatFn: function (value) {
      return value.toUpperCase();
    },
  },
  {
    label: "Campaign IDs",
    field: "cp_id",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

export const discountReportTableColumns = [
  {
    label: "Timestamp",
    field: "ts",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Giver/Employee ID",
    field: "giver",
  },
  {
    label: "Recipient ID",
    field: "reciepient",
  },
  {
    label: "Discount ID",
    field: "refid",
  },
  {
    label: "Discount Type",
    field: "type",
  },
  {
    label: "Discount Amount",
    field: "discount_amt",
    type: "number",
  },
  {
    label: "Coin used",
    field: "coin_used",
    type: "number",
  },
  {
    label: "Mark up",
    field: "markup",
  },
  {
    label: "Quota ID",
    field: "qt_id",
    type: "number",
  },
  {
    label: "Campaign ID",
    field: "cp_id",
    type: "number",
  },
  {
    label: "Max Discount",
    field: "max_discount",
    type: "number",
  },
];

import PrizeSummaryIndex from "@/views/prize/Index.vue";

import PoolList from "@/views/prize/pool/List.vue";
import PoolEdit from "@/views/prize/pool/Edit.vue";
import ThemeList from "@/views/prize/theme/List.vue";
import ThemeEdit from "@/views/prize/theme/Edit.vue";
import PoolStatus from "@/views/prize/pool_status/Index.vue";
import PoolStatusDetail from "@/views/prize/pool_status/Detail.vue";

import RequestTransaction from "@/views/prize/request_transaction/Index.vue";

import LotList from "@/views/prize/lot/List.vue";
import LotEdit from "@/views/prize/lot/Edit.vue";
import PrizeStatus from "@/views/prize/status/Index.vue";

export default {
  path: "/prize",
  name: "PrizeSummaryIndex",
  component: PrizeSummaryIndex,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "pool",
      name: "prize-pool-list",
      component: PoolList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "pool/edit/:id",
      name: "prize-pool-edit",
      component: PoolEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "pool/status",
      name: "prize-pool-status",
      component: PoolStatus,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "pool/status/:id",
      name: "prize-pool-status-detail",
      component: PoolStatusDetail,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "theme",
      name: "prize-theme-list",
      component: ThemeList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "theme/edit/:id",
      name: "prize-theme-edit",
      component: ThemeEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "request-transaction",
      name: "prize-request-transaction",
      component: RequestTransaction,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "lot",
      name: "prize-lot-list",
      component: LotList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "lot/edit/:id",
      name: "prize-lot-edit",
      component: LotEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "status",
      name: "prize-status",
      component: PrizeStatus,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-bordered" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "panel-title" }, [
        _vm._v("Samurai Settings - " + _vm._s(_vm.environment.name)),
      ]),
      _c("div", { staticClass: "panel-actions panel-actions-keep" }),
    ]),
    _c("div", { staticClass: "panel-body" }, [
      _c("div", { staticClass: "table-responsive config-list-table" }, [
        _c("table", { staticClass: "table" }, [
          _c("tr", [
            _c("td", { staticClass: "align-middle" }, [
              _vm._v("เวลาสำหรับการเล่นแต่ละครั้ง"),
            ]),
            _c("td", { attrs: { width: "100", align: "right" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.play_sec,
                    expression: "form.play_sec",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "0", max: "100" },
                domProps: { value: _vm.form.play_sec },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "play_sec", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "80", align: "left" },
              },
              [_vm._v("วินาที")]
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "align-middle" }, [
              _vm._v("คะแนนสำหรับ SKU1"),
            ]),
            _c("td", { attrs: { width: "100", align: "right" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.point_0,
                    expression: "form.point_0",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "0", max: "100" },
                domProps: { value: _vm.form.point_0 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "point_0", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "80", align: "left" },
              },
              [_vm._v("คะแนน")]
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "align-middle" }, [
              _vm._v("คะแนนสำหรับ SKU2"),
            ]),
            _c("td", { attrs: { width: "100", align: "right" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.point_1,
                    expression: "form.point_1",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "0", max: "100" },
                domProps: { value: _vm.form.point_1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "point_1", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "80", align: "left" },
              },
              [_vm._v("คะแนน")]
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "align-middle" }, [
              _vm._v("คะแนนสำหรับ SKU3"),
            ]),
            _c("td", { attrs: { width: "100", align: "right" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.point_2,
                    expression: "form.point_2",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "0", max: "100" },
                domProps: { value: _vm.form.point_2 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "point_2", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "80", align: "left" },
              },
              [_vm._v("คะแนน")]
            ),
          ]),
          _c("tr", [
            _c("td", [_vm._v("คะแนนสำหรับ VIP")]),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "100", align: "right" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.point_vip,
                      expression: "form.point_vip",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number", min: "0", max: "100" },
                  domProps: { value: _vm.form.point_vip },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "point_vip", $event.target.value)
                    },
                  },
                }),
              ]
            ),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "80", align: "left" },
              },
              [_vm._v("คะแนน")]
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "align-middle" }, [
              _vm._v("คะแนนสำหรับระเบิด"),
            ]),
            _c("td", { attrs: { width: "100", align: "right" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.point_bomb,
                    expression: "form.point_bomb",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "-100", max: "0" },
                domProps: { value: _vm.form.point_bomb },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "point_bomb", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "td",
              {
                staticClass: "align-middle",
                attrs: { width: "80", align: "left" },
              },
              [_vm._v("คะแนน")]
            ),
          ]),
        ]),
      ]),
      _c("hr"),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h4", { staticClass: "mb-0" }, [_vm._v("Theme")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.theme_id,
                  expression: "form.theme_id",
                },
              ],
              staticClass: "form-control w-250",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "theme_id",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(_vm.themes, function (theme) {
              return _c(
                "option",
                { key: theme.id, domProps: { value: theme.id } },
                [
                  _vm._v(
                    " " + _vm._s(" " + theme.id + " - " + theme.name) + " "
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h5", { staticClass: "mb-0" }, [_vm._v("No. play per day")]),
          _c("div", { staticClass: "table-days-point-wrapper" }, [
            _c("table", [
              _c(
                "tr",
                _vm._l(_vm.days, function (day, index) {
                  return _c("td", { key: index }, [
                    _c("p", [_vm._v(_vm._s(day.text))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form[day.key],
                          expression: "form[day.key]",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { min: "0", max: "999", type: "number" },
                      domProps: { value: _vm.form[day.key] },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, day.key, $event.target.value)
                        },
                      },
                    }),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-default mx-10",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _c("i", {
              staticClass: "icon md-close",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Cancel "),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.submit },
          },
          [
            _c("i", {
              staticClass: "icon md-check",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Commit "),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
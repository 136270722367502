export const fileUploadOptions = {
  accept: ".jpeg, .jpg, .png",
  maxFileSize: "500K",
};

export const questionTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Question",
    field: "text",
  },
  {
    label: "Char Name",
    field: "character",
  },
  {
    label: "Char Picture",
    field: "thumbnail",
  },
  {
    label: "Num choices",
    field: "num_choices",
    type: "number",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "modified By",
    field: "modified_by_username",
  },
];

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Engagement</h1>
      <breadcrumb name="GAME_ENGAGEMENT"></breadcrumb>
      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="fromTo"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-12">
                <h4>Games</h4>
                <div class="form-group">
                  <div class="checkbox-custom checkbox-primary checkbox-inline">
                    <input
                      type="checkbox"
                      id="all_game_checkbox"
                      @click="checkAll()"
                      v-model="isCheckAll"
                    />
                    <label for="all_game_checkbox">All</label>
                  </div>

                  <div
                    v-for="game in gamesSelect"
                    :key="game"
                    :class="game"
                    class="checkbox-custom checkbox-primary checkbox-inline"
                  >
                    <input
                      type="checkbox"
                      v-bind:value="game"
                      v-model="selected"
                      @change="updateCheckall()"
                      :id="game + '_game_checkbox'"
                    />
                    <label :for="game + '_game_checkbox'">
                      {{ game | capitalize }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-lg-7">
                <div id="chart-line"></div>
              </div>
              <div class="col-lg-5">
                <div id="chart-pie"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="Excel">
            <button @click="exportToExcel" class="btn btn-default">
              <i class="fa fa-file-excel-o"></i>
              Export to Excel
            </button>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              :columns="columns"
              :rows="engagement.table"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
              }"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

import moment from "moment";
import { gameService } from "@/api/client";
import { engagementTableColumns } from "@/views/game/model";

export default {
  name: "Engagement",

  data() {
    return {
      download: [],
      fromTo: [],
      engagement: { table: [] },
      columns: engagementTableColumns,
      colorPattern: ["#f44336", "#009688", "#2196f3", "#e91e63", "#4caf50", "#dd9e49"],

      isCheckAll: true,
      selected: ["fortune", "sustainable", "advocacy", "space", "run", "sunset_drive"],
      gamesSelect: ["fortune", "sustainable", "advocacy", "space", "run", "sunset_drive"],
    };
  },

  created() {
    this.fromTo = [
      moment().add(-15, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
  },

  watch: {
    fromTo() {
      this.update();
    },
    selected() {
      this.update();
    },
  },

  methods: {
    // https://makitweb.com/check-uncheck-all-checkboxes-with-vue-js/
    checkAll: function () {
      this.isCheckAll = !this.isCheckAll;
      this.selected = [];
      if (this.isCheckAll) {
        // Check all
        for (var key in this.gamesSelect) {
          this.selected.push(this.gamesSelect[key]);
        }
      }
    },

    updateCheckall: function () {
      if (this.selected.length == this.gamesSelect.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    createChart() {
      this.createPieChart();
      this.createLineChart();
    },

    getColorPattern(games) {
      let colors = [];
      const _this = this;

      games.forEach(function (game, i) {
        let matched = _this.gamesSelect.findIndex(
          (selected) => selected == game
        );

        if (matched > -1) {
          colors.push(_this.colorPattern[matched]);
        }
      });

      return colors;
    },

    createLineChart() {
      const { run, space, fortune, advocacy, sustainable, sunset_drive } =
        this.engagement.daily_participants.data_set;

      const columns = [
        ["x-label"].concat(this.engagement.daily_participants.labels),
      ];

      run && columns.push(["Run"].concat(run));
      space && columns.push(["Space"].concat(space));
      fortune && columns.push(["Fortune"].concat(fortune));
      advocacy && columns.push(["Advocacy"].concat(advocacy));
      sustainable && columns.push(["Sustainable"].concat(sustainable));
      sunset_drive && columns.push(["Sustainable"].concat(sunset_drive));

      const games = columns.map((c) => c[0].toLowerCase()).slice(1);

      c3.generate({
        bindto: "#chart-line",
        legend: {
          show: false,
        },
        color: {
          pattern: this.getColorPattern(games),
        },
        data: {
          x: "x-label",
          columns: columns,
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m-%d",
            },
          },
        },
      });
    },

    createPieChart() {
      let columns = [];

      const _this = this;
      this.engagement.pie_chart.labels.map(function (label, index) {
        return columns.push([label, _this.engagement.pie_chart.data[index]]);
      });

      const games = columns.map((c) => c[0].toLowerCase());

      c3.generate({
        bindto: "#chart-pie",
        legend: {
          show: false,
        },
        color: {
          pattern: this.getColorPattern(games),
        },
        data: {
          columns: columns,
          type: "pie",
        },
        onrendered: function () {
          const _t = _this;
          const sum = columns.reduce((s, c, i) => (s += c[1]), 0);

          this.svg.selectAll("g.c3-target text").each(function (d) {
            const value = d.data.values[0].value;
            const percentage = ((value / sum) * 100).toFixed(1);
            _t.insertLinebreaks(this, [value, `${percentage}%`]);
          });
        },
      });
    },

    insertLinebreaks(t, words) {
      var el = d3.select(t);
      el.text("");

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", 0).attr("dy", "15");
      }
    },

    exportToExcel() {
      this.download = this.engagement.table;

      const jsonData = this.download.map((d) => {
        return {
          Date: d.date,
          "Game Instance ID": d.instance_id,
          "Game Instance Name": d.name,
          "Game Type": d.type,
          "No.Users": d.no_users,
          "No.Plays": d.no_plays,
          "No.Points": d.no_points,
        };
      });

      const dataWorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWorkSheet);
      XLSX.writeFile(wb, `export_game_engagement_${new Date().getTime()}.xlsx`);
    },

    didGameSelected(game) {
      return this.selected.findIndex((o) => o == game) > -1;
    },

    async update() {
      const params = {
        to: this.fromTo[1],
        from: this.fromTo[0],
        run: this.didGameSelected("run") ? 1 : 0,
        space: this.didGameSelected("space") ? 1 : 0,
        fortune: this.didGameSelected("fortune") ? 1 : 0,
        advocacy: this.didGameSelected("advocacy") ? 1 : 0,
        sustainable: this.didGameSelected("sustainable") ? 1 : 0,
      };

      this.engagement = await gameService.engagement(params);
      this.createChart();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-primary {
  &.fortune {
    input[type="checkbox"]:checked + label {
      color: #f44336;

      &::before {
        border-color: #f44336;
        background-color: #f44336;
      }
    }
  }

  &.sustainable {
    input[type="checkbox"]:checked + label {
      color: #009688;

      &::before {
        border-color: #009688;
        background-color: #009688;
      }
    }
  }

  &.advocacy {
    input[type="checkbox"]:checked + label {
      color: #2196f3;

      &::before {
        border-color: #2196f3;
        background-color: #2196f3;
      }
    }
  }

  &.space {
    input[type="checkbox"]:checked + label {
      color: #e91e63;

      &::before {
        border-color: #e91e63;
        background-color: #e91e63;
      }
    }
  }

  &.run {
    input[type="checkbox"]:checked + label {
      color: #4caf50;

      &::before {
        border-color: #4caf50;
        background-color: #4caf50;
      }
    }
  }
  
  &.sunset_drive {
    input[type="checkbox"]:checked + label {
      color: #dd9e49;

      &::before {
        border-color: #dd9e49;
        background-color: #dd9e49;
      }
    }
  }
}
</style>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin-top: 35px;
}
</style>

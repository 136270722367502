<template>
  <div id="progressbar-scanner-image" class="col-md-6" v-if="stamp">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Picture
            <span class="small light">
              (recommended size 335 x 50 pixels )
            </span>
          </h4>
        </label>

        <div class="form-group">
          <div class="image-preview">
            <img class="img img-fluid" v-if="picture" :src="picture" />
            <div v-else class="image-placeholder"></div>
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="uploadImage"
            ref="scanner_picture"
            name="scanner_picture"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Scanner picture error.",
        success: "Upload Scanner picture success.",
      },
    };
  },

  computed: {
    picture() {
      return this.campaign.stamp.scanner_picture;
    },
  },

  methods: {
    validateFileUpload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        event.target.files[0].value = "";
        throw new Error("File shall not exceed 1 MB");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/upload.form.scss";

#progressbar-scanner-image {
  margin-top: 1rem;
  padding-left: 3rem;
}
</style>

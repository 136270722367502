var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("CCB2B Log")]),
        _c("breadcrumb", { attrs: { name: "E_COUPON_CCB2B_LOG" } }),
        _c("div", { staticClass: "page-header-right" }),
        _c("div", { staticClass: "page-header-actions" }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c("vue-good-table", {
                ref: "table",
                attrs: {
                  mode: "remote",
                  columns: _vm.columns,
                  rows: _vm.list,
                  totalRows: _vm.totalRecords,
                  "sort-options": {
                    enabled: true,
                    initialSortBy: { field: "task_id", type: "desc" },
                  },
                  "pagination-options": _vm.pagination,
                  "search-options": _vm.searchOption,
                },
                on: {
                  "on-sort-change": _vm.onSortChange,
                  "on-page-change": _vm.onPageChange,
                  "on-search": _vm.onSearch,
                  "on-row-dblclick": _vm.onRowDoubleClick,
                  "on-per-page-change": _vm.onPerPageChange,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <a @click="$emit('sort')">
    <span>{{ text }}</span>
    <BaseIcon
      icon="arrow_downward"
      v-if="this.$route.query.sortBy == sortBy && this.$route.query.sortDir == 1"
    />
    <BaseIcon
      icon="arrow_upward"
      v-else-if="
        this.$route.query.sortBy == sortBy && this.$route.query.sortDir == -1
      "
    />
  </a>
</template>

<script>
export default {
  props: ["sortBy", "text"],
};
</script>

<style></style>

<template>
  <div class="form-group">
    <label class="mx-5">
      <h5>Require Address</h5>
    </label>

    <div class="flex-fit-content">
      <div class="form-group-row">
        <div class="left-box">
          <label class="switch">
            <input
              type="checkbox"
              :true-value="1"
              :false-value="0"
              :disabled="disabled"
              name="require_address"
              v-model.number="pool.require_address"
              @change="update($event, 'checkbox', pool.require_address)"
            />
            <span class="slider round"></span>
          </label>
          <p class="mx-10 d-inline">
            {{ pool.require_address === 1 ? "ON" : "OFF" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: "Update Require address error.",
        success: "Update Require address success.",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
  margin-bottom: 1rem;
  padding: 0px 1.25rem;
}

.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-bottom: 0px;
  }
}

.switch {
  margin: 1rem 1.25rem;
  margin-top: 0px;
}
</style>

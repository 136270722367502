<template>
  <div class="px-15" v-if="quota">
    <h4>Status</h4>
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ quota.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <DropdownItemStatus
          text="Activate"
          icon="md-check"
          @onSelect="update('scheduled')"
          v-if="quota.status == 'draft'"
        />

        <DropdownItemStatus
          text="Archive"
          icon="md-archive"
          @onSelect="update('archived')"
          v-if="quota.status == 'draft'"
        />

        <DropdownItemStatus
          text="Deactivate"
          icon="md-long-arrow-down"
          @onSelect="update('draft')"
          v-if="quota.status == 'scheduled'"
        />

        <DropdownItemStatus
          text="Unarchive"
          icon="md-long-arrow-down"
          @onSelect="update('draft')"
          v-if="quota.status == 'archived'"
        />

        <DropdownItemStatus
          text="Release"
          icon="md-arrow-right"
          @onSelect="update('released')"
          v-if="quota.status == 'tracking'"
        />

        <DropdownItemStatus
          text="Release"
          icon="md-arrow-right"
          @onSelect="update('released')"
          v-if="quota.status == 'reached'"
        />

        <DropdownItemStatus text="Nothing" v-if="!quota.status" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { walletService } from "@/api/client";
import { responseErrorMapping } from "../../model/error";

import DropdownItemStatus from "./DropdownItemStatus.vue";

export default {
  props: ["quota"],

  components: { DropdownItemStatus },

  data() {
    return { status: "" };
  },

  mounted() {
    this.status = this.quota.status;
  },

  methods: {
    async update(status) {
      try {
        if (!["draft", "archived"].includes(status)) {
          const formValid = await this.isFormValid();
          if (status == "scheduled" && !formValid) {
            this.status = "draft";
            return;
          }
        }

        const { id } = this.quota;
        const form = { status: status };

        await walletService.updateQuotaInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    countDownToNextStatus() {
      if (!["scheduled", "tracking"].includes(this.quota.status)) {
        return;
      }

      setInterval(() => {
        const isPassTime =
          new Date(this.quota.start_date).getTime() < new Date().getTime();

        if (isPassTime) {
          this.$emit("updated");
        }
      }, 5000);
    },

    async isFormValid() {
      const { start_date, end_date } = this.quota;

      if (!start_date) {
        await this.$dialogs.alert("กรุณากรอก Start date ให้ถูกต้อง");
        return false;
      }

      if (moment(end_date).diff(start_date, "minutes") < 1) {
        await this.$dialogs.alert("กรุณาระบุ End date ให้ถูกต้อง");
        return false;
      }

      if (moment(end_date).isSameOrBefore(moment())) {
        await this.$dialogs.alert("กรุณาระบุ End date ให้ถูกต้อง");
        return false;
      }

      return true;
    },
  },

  created() {
    this.countDownToNextStatus();
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8" }, [
    _c("div", { staticClass: "ml-10" }, [
      _c("h4", [_vm._v(" URL ")]),
      _c(
        "table",
        { staticClass: "table table-responsive table-borderless ml-20" },
        [
          _c("tr", [
            _vm._m(0),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.url_link,
                    expression: "campaign.url_link",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", disabled: _vm.disabled },
                domProps: { value: _vm.campaign.url_link },
                on: {
                  change: _vm.update,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.campaign, "url_link", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("tr", [
            _vm._m(1),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaign.url_back,
                    expression: "campaign.url_back",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", disabled: _vm.disabled },
                domProps: { value: _vm.campaign.url_back },
                on: {
                  change: _vm.update,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.campaign, "url_back", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("Widget link "), _c("br"), _vm._v("From LINE")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_vm._v("URL link for "), _c("br"), _vm._v("back button")])
  },
]
render._withStripped = true

export { render, staticRenderFns }
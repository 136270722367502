<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create New Quota</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.campaign.name.$error }"
            >
              <label class="col-md-0 form-control-label form__label">
                <h5>Quota Summary</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="campaign.name"
                  autocomplete="off"
                  placeholder="Any name"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">Quota Type</h5>
              <div class="col-md-12 pl-50">
                <div class="form-group">
                  <div class="row" my-15px>
                    <input
                      type="radio"
                      value="points"
                      class="mr-10"
                      v-model="campaign.type"
                      id="campaign_type_get_coin"
                    />
                    <label for="campaign_type_get_coin">
                      <p class="caption">Get Coin</p>
                      <p class="description">
                        Limit the exchange from VIP points to coins
                      </p>
                    </label>
                  </div>
                  <div class="row" my-15px>
                    <input
                      type="radio"
                      value="coins"
                      class="mr-10"
                      v-model="campaign.type"
                      id="campaign_type_discount"
                    />
                    <label for="campaign_type_discount">
                      <p class="caption">Discount</p>
                      <p class="description">
                        Limit the user of discount in exchange for coins
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="addCampaign"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletService } from "@/api/client";
import { responseErrorMapping } from "@/views/point/model/error";
import nameValidation from "@/views/point/validation/name";
import { FormCreateValidateRule as validateRules } from "@/views/point/validation";

export default {
  name: "Create",

  data() {
    return {
      showErrorNameValidation: false,

      campaign: {
        name: "",
        type: "coins",
      },
    };
  },

  components: {
    nameValidation,
  },

  validations: { campaign: validateRules },

  methods: {
    async isFormValid() {
      if (this.$v.campaign.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Quota Summary ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }

      return true;
    },

    close() {
      this.$modal.hide("create-modal");
    },

    async getFormData() {
      return {
        name: this.campaign.name,
        qt_type: this.campaign.type,
      };
    },

    async addCampaign() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await walletService.createNewQuota(body);
        await this.$dialogs.alert("Create Quota Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  p {
    margin-bottom: 0;
  }

  .caption {
    font-size: 1.2rem;
  }

  .description {
    margin-left: 1.5rem;
  }
}
</style>

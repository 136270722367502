<template>
  <div class="row">
    <div class="col-12">
      <h5>Consolidation Prize</h5>
      <div class="d-flex align-items-center">
        <label class="switch">
          <input
            type="checkbox"
            :true-value="1"
            :false-value="0"
            @change="update"
            :disabled="disabled"
            v-model="campaign.consolidation_prize"
          />
          <span class="slider round"></span>
        </label>
        <p class="mx-10 d-inline">
          {{ +campaign.consolidation_prize === 1 ? "ON" : "OFF" }}
        </p>
        <div class="d-flex align-items-center" style="margin-left: 60px;">
          <label for="consolidation_prize_sf_point" style="width: 100px;">
            SF Point
          </label>
          <input
            min="0"
            max="10000"
            type="number"
            @change="update"
            class="form-control"
            style="max-width: 120px;"
            v-model="campaign.consolidation_sf_point"
            :disabled="disabled || +campaign.consolidation_prize !== 1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      oldValue: {},
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled"].includes(this.campaign.status);
    },
  },

  methods: {
    async update() {
      const { id, consolidation_prize, consolidation_sf_point } = this.campaign;

      if (this.campaign.consolidation_sf_point < 0) {
        this.campaign.consolidation_sf_point = this.oldValue.consolidation_sf_point;
        return;
      }

      try {
        await luckyLoginService.updateCampaignInfo(id, {
          consolidation_prize: consolidation_prize,
          consolidation_sf_point: consolidation_sf_point,
        });
        this.$emit("updated");
        this.$notify("Updated.");

        this.oldValue.consolidation_prize = consolidation_prize;
        this.oldValue.consolidation_sf_point = consolidation_sf_point;
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },

  mounted() {
    this.oldValue = this.campaign;
  },
};
</script>

<style></style>

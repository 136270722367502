<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table" v-if="campaign">
        <tbody>
          <tr>
            <td colspan="5">
              Target Icon on progress bar (recommended size 69 x69 px)
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Unreached</td>
            <td>
              <div class="form-group">
                <div class="d-block">
                  <img
                    class="img img-fluid image-preview"
                    v-if="campaign.team.target_picture_not_reached"
                    :src="campaign.team.target_picture_not_reached"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="upload"
                  name="target_picture_not_reached"
                  :disabled="disabled"
                />
              </div>
            </td>
            <td>Reached</td>
            <td>
              <div class="form-group">
                <div class="d-block">
                  <img
                    class="img img-fluid image-preview"
                    v-if="campaign.team.target_picture_reached"
                    :src="campaign.team.target_picture_reached"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="upload"
                  name="target_picture_reached"
                  :disabled="disabled"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],
  data() {
    return {
      file: null,
      team: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async upload(event) {
      const file = event.target.files[0];

      if (file.size > 2000000) {
        this.$notify("File shall not exceed 2 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append(event.target.name, file);

        const campaignId = this.campaign.id;

        await progressBarService.uploadTeamTargetPicture(campaignId, form);

        this.$emit("updated");
        this.$notify(`Upload Target icon success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Target icon error");
      }
    },
  },

  mounted() {
    this.team = this.campaign.team;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  min-width: 360px;
}

@media (max-width: 1280px) {
  .image-preview,
  input[type="file"] {
    min-width: 280px;
  }
}
</style>

import qs from "qs";
import HttpRequest from "./request";

export class WeeklyLoginProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/weeklylogin`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async createCampaign(form) {
    const { data } = await this.create("/", form);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async updateCampaignInfo(id, form) {
    const { data } = await this.update(`/${id}`, form);
    return data;
  }

  async uploadCampaignImage(id, form) {
    const { data } = await this.upload(`/${id}/upload`, form);
    return data;
  }

  async updatePrizeInfo(id, periodId, form) {
    const { data } = await this.update(`/${id}/period/${periodId}`, form);
    return data;
  }

  async uploadPrizeImage(id, periodId, form) {
    const { data } = await this.upload(
      `/${id}/period/${periodId}/upload`,
      form
    );
    return data;
  }
  
  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/transaction_log${queryString}`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/transaction_log/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }
}

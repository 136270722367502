<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title" style="text-align: center;">Remove SKUs</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <div class="center">Remove {{ image }}</div>
          </div>
          <div class="form-group">
            <div class="center">
              You Will NOT be able undo this operation!
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <div class="d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-round btn-default mx-10"
            @click="close"
          >
            <i class="icon md-close" aria-hidden="true"></i>
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-round btn-primary"
            @click="confrimRemove"
          >
            <i class="icon md-check" aria-hidden="true"></i> Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { skuService } from "@/api/client";
export default {
  name: "Create",
  props: ["idSkus", "image"],
  data() {
    return {};
  },

  methods: {
    close() {
      this.$modal.hide("confrim-modal");
    },

    async confrimRemove() {
      try {
        await skuService.deleteSku(this.idSkus);
        this.$emit("sucessRemove");
        this.$modal.hide("confrim-modal");
        this.$notify("Delete Success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Game Instance Update error");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  text-align: center;
}
</style>

import GamesInstance from "@/views/game/Index.vue";
import GamesInstanceList from "@/views/game/List.vue";
import GamesInstanceListEdit from "@/views/game/Edit.vue";
import GamesInstanceSunsetDrive from "@/views/game/sunset-drive/Edit.vue";

import Engagement from "@/views/game/Engagement.vue";
import TransactionLog from "@/views/game/TransactionLog.vue";

export default {
  path: "/gamesinstance",
  name: "GamesInstance",
  component: GamesInstance,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/",
      name: "GamesInstance",
      component: GamesInstanceList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "edit/:id",
      name: "GamesInstanceListEdit",
      component: GamesInstanceListEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "sunset-drive/:id",
      name: "GamesInstanceSunsetDrive",
      component: GamesInstanceSunsetDrive,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
      props: true,
    },
    {
      path: "engagement",
      name: "WorldGameEngagement",
      component: Engagement,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "transaction-log",
      name: "WorldGameTransactionLog",
      component: TransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

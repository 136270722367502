import RefundLog from "@/views/refund_log/Index.vue";
import RefundLogList from "@/views/refund_log/List.vue";

export default {
  path: "/refund-log",
  name: "RefundLog",
  component: RefundLog,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/",
      name: "RefundLogList",
      component: RefundLogList,
    },
  ],
};

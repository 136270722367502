<template>
  <div v-if="lot" class="mx-10 col-6">
    <label class="form-label">
      <h5 class="mb-0">Prize Name</h5>
    </label>
    <input
      ref="prize_name"
      type="text"
      v-model="prize_name"
      maxlength="255"
      @change="update"
      :disabled="!canEdit"
      class="form-control"
    />
  </div>
</template>

<script>
import { prizeService } from "@/api/client";

export default {
  props: ["lot"],

  data() {
    return {
      id: null,
      prize_name: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.lot.status);
    },
  },

  mounted() {
    this.id = this.lot.id;
    this.prize_name = this.lot.prize_name;
  },

  methods: {
    async isFormValid() {
      if (!this.prize_name) {
        await this.$dialogs.alert("กรุณากรอก Prize Name ให้ถูกต้อง");
        this.$refs.prize_name.focus();
        return false;
      }
      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await prizeService.updateLot(this.lot.id, {
          prize_name: this.prize_name,
        });
        this.$notify(`Prize name updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Prize name error.`);
      }
    },
  },
};
</script>

<style></style>

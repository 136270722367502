<template>
  <div class="page">
    <div class="page-main">
      <div class="page-header">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <h1 class="page-title">User Group</h1>
          <breadcrumb
            name="USERGROUP_EDIT"
            :withLast.sync="usergroup.group_code"
          ></breadcrumb>
        </div>
        <div class="page-header-actions">
          <button
            type="button"
            class="btn btn-round btn-default mx-10"
            @click="goBack"
          >
            <i class="icon md-chevron-left" aria-hidden="true"></i>
            Back
          </button>
        </div>
      </div>

      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">User Group Information</h3>
              </div>
              <div class="panel-body">
                <form class="form-horizontal">
                  <div
                    class="form-group row"
                    :class="{ '.is-invalid': $v.usergroup.auto_number.$error }"
                  >
                    <label class="col-md-0 form-control-label form__label"
                      ><h5>Auto Number</h5>
                    </label>
                    <div class="error col-md-12">
                      This number must match value in Salesforce! Example:
                      a0F25000007ZIgY (15 digits)
                    </div>
                    <div class="col-md-12">
                      <input
                        type="text"
                        ref="auto_number"
                        maxlength="255"
                        class="form-control form__input"
                        :class="{
                          'is-valid': !$v.usergroup.auto_number.$invalid,
                        }"
                        v-model="usergroup.auto_number"
                        @change="submit('auto_number')"
                        autocomplete="off"
                      />
                      <autoNumberValidation
                        :$v="$v"
                        v-if="showErrorAutoNumberValidation"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <h5 class="col-md-12">Group Code (View only)​</h5>
                    <div class="col-md-12">
                      <div class="">
                        <span class="badge badge-lg badge-primary">{{
                          usergroup.group_code
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group row"
                    :class="{
                      '.is-invalid': $v.usergroup.reason_description.$error,
                    }"
                  >
                    <label class="col-md-0 form-control-label form__label"
                      ><h5>Reason Description</h5>
                    </label>
                    <div class="error col-md-12">
                      This is the value to be sent to Salesforce. Example:
                      'ประมูล หรือจับฉลาก ค้าปลีก(GT) RT-00002593' หรือ
                      'ล๊อกอินสะสม Family Mart'
                    </div>
                    <div class="col-md-12">
                      <textarea
                        type="text"
                        ref="reason_description"
                        maxlength="255"
                        class="form-control form__input"
                        :class="{
                          'is-valid': !$v.usergroup.reason_description.$invalid,
                        }"
                        v-model="usergroup.reason_description"
                        @change="submit('reason_description')"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-md-12">
                      <reasonDescriptionValidation
                        :$v="$v"
                        v-if="showErrorReasondDscriptionValidation"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <h5 class="col-md-12">Group Status</h5>
                    <div class="col-md-12">
                      <select
                        class="form-control"
                        v-model="usergroup.status"
                        @change="submit('status')"
                      >
                        <option value="active">ACTIVE</option>
                        <option value="inactive">INACTIVE</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userGroupService } from "@/api/client";
import UserGroupModel from "./model";
import { responseErrorMapping } from "./model/error";
import autoNumberValidation from "./validation/autoNumber";
import reasonDescriptionValidation from "./validation/reasonDescription";
import {
  errorMessage,
  FormCreateValidateRule as validateRules,
} from "./validation";

export default {
  name: "UserGroupEdit",
  data() {
    return {
      firstTimeChange: true,
      usergroup: {},
      usergroupOld: {},
      showErrorAutoNumberValidation: false,
      showErrorGroupCodeValidation: false,
      showErrorReasondDscriptionValidation: false,
    };
  },
  validations: { usergroup: validateRules },
  components: {
    autoNumberValidation,
    reasonDescriptionValidation,
  },

  async mounted() {
    this.usergroup = await userGroupService.getById(this.$route.params.id);
    this.usergroupOld = await userGroupService.getById(this.$route.params.id);
    this.title = this.usergroup.group_code;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async submit(name) {
      if (name == "auto_number") {
        this.showErrorAutoNumberValidation = true;
      }
      if (name == "group_code") {
        this.showErrorGroupCodeValidation = true;
      }
      if (name == "reason_description") {
        this.showErrorReasondDscriptionValidation = true;
      }
      if (this.usergroup.auto_number == "") {
        await this.$dialogs.alert("กรุณาใส่ค่า Auto Number !");
        this.usergroup.auto_number = this.usergroupOld.auto_number;
        this.$refs.auto_number.focus();
      }
      if (this.usergroup.reason_description == "") {
        await this.$dialogs.alert("กรุณาใส่ค่า Reason Description !");
        this.usergroup.reason_description = this.usergroupOld.reason_description;
        this.$refs.reason_description.focus();
      }

      try {
        if (this.usergroup.auto_number == "") {
          await this.$dialogs.alert("กรุณาใส่ค่า Auto Number !");
          this.usergroup.auto_number = this.usergroupOld.auto_number;
          this.$refs.auto_number.focus();
          return;
        }

        if (this.usergroup.reason_description == "") {
          await this.$dialogs.alert("กรุณาใส่ค่า Reason Description !");
          this.usergroup.reason_description = this.usergroupOld.reason_description;
          this.$refs.reason_description.focus();
          return;
        }

        const form = new FormData();
        form.set(name, this.usergroup[name]);

        const body = JSON.stringify(Object.fromEntries(form));
        await userGroupService.editUserGroup(
          this.usergroup.id,
          JSON.parse(body)
        );

        this.$notify("Updated");
      } catch (error) {
        (error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Update error")
        ).then(function () {
          location.reload();
        });
      }
    },
  },
};
</script>

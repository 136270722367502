var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8" }, [
    _vm._m(0),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.description,
          expression: "description",
        },
      ],
      ref: "description",
      staticClass: "form-control",
      attrs: { rows: "4", maxlength: "150", disabled: !_vm.canEdit },
      domProps: { value: _vm.description },
      on: {
        change: _vm.update,
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.description = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [
        _vm._v(" Campaign Description "),
        _c("span", { staticClass: "small light" }, [
          _vm._v("(max 150 charactors)"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12" },
    [
      _vm._m(0),
      _vm._l(_vm.campaign.stamp.reward, function (reward, index) {
        return _c("div", { key: reward.id, staticClass: "reward-records" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("RewardRow", {
                  attrs: {
                    index: index,
                    reward: reward,
                    campaign: _vm.campaign,
                    prizepool: _vm.prizepool,
                  },
                  on: {
                    updated: function ($event) {
                      return _vm.$emit("updated")
                    },
                    delete: _vm.deleteReward,
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      }),
      _c("div", { staticClass: "action" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-primary",
            attrs: {
              type: "button",
              disabled: _vm.disabled || !_vm.canAddMoreReward,
            },
            on: { click: _vm.addNewReward },
          },
          [
            _c("i", {
              staticClass: "icon md-plus",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Add Reward "),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [_c("h4", [_vm._v("Rewards")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
const messages = {
  ER_DUPLICATE_USERNAME: "มี username นี้อยู่แล้ว",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("duplicate username") > -1:
      message = messages.ER_DUPLICATE_USERNAME;
      break;

    default:
      message = error.replace(/Error:/g, "").trim();
      break;
  }
  return message;
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "table",
          { staticClass: "table" },
          _vm._l(_vm.objectConfig, function (o, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v("No. " + _vm._s(index + 1))]),
              _c("td", { staticClass: "center w-400" }, [
                _c("p", [_vm._v("Picture")]),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "d-block" }, [
                    o.picture
                      ? _c("img", {
                          staticClass: "img img-fluid image-preview",
                          attrs: { src: o.picture },
                        })
                      : _vm._e(),
                  ]),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "file",
                      accept: "image/*",
                      disabled: _vm.disabled,
                      "data-index": index,
                    },
                    on: { change: _vm.upload },
                  }),
                ]),
              ]),
              _c("td", { staticClass: "v-align-middle" }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mb-10" },
                    [
                      _c("p", { staticClass: "w-100 mx-10 mb-0" }, [
                        _vm._v("No. Occurrence"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: o.no_occurrence,
                            expression: "o.no_occurrence",
                          },
                        ],
                        staticClass: "form-control w-100",
                        attrs: {
                          min: "0",
                          type: "number",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: o.no_occurrence },
                        on: {
                          change: function ($event) {
                            return _vm.update(index)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(o, "no_occurrence", $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mb-10" },
                    [
                      _c("p", { staticClass: "w-100 mx-10 mb-0" }, [
                        _vm._v("Point per object"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: o.point_per_object,
                            expression: "o.point_per_object",
                          },
                        ],
                        staticClass: "form-control w-100",
                        attrs: {
                          min: "0",
                          max: "999",
                          type: "number",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: o.point_per_object },
                        on: {
                          change: function ($event) {
                            return _vm.update(index)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(o, "point_per_object", $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Objects")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import UserGroup from "@/views/usergroup/Index.vue";
import UserGroupList from "@/views/usergroup/List.vue";
import UserGroupEdit from "@/views/usergroup/Edit.vue";

export default {
  path: "/usergroup",
  name: "UserGroup",
  component: UserGroup,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/",
      name: "UserGroupList",
      component: UserGroupList,
    },
    {
      path: "edit/:id",
      name: "UserGroupEdit",
      component: UserGroupEdit,
    },
  ],
};

<template>
  <div class="col-12 mx-10" v-if="lot">
    <label class="label">LUM Groups</label>
    <div class="form-group">
      <Selectize
        multiple
        :disabled="disabled"
        :settings="groupSelectizeSetting"
        v-model="lot.lum_group_code_list"
      >
        <option
          v-for="group in lumUserGroup"
          :key="group.id"
          :value="group.group_code"
        >
          {{ ` ${group.id} - ${group.group_name}` }}
        </option>
      </Selectize>
    </div>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { lotService, userGroupService } from "@/api/client";

export default {
  name: "LUMGroupSetting",

  props: ["lot"],

  components: {
    Selectize,
  },

  data() {
    return {
      lumUserGroup: [],

      groupSelectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        onChange: this.update,
      },
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled"].includes(this.lot.status);
    },
  },

  async created() {
    await this.fetchLUMGroup();
  },

  methods: {
    async update(groups) {
      try {
        const { id } = this.lot;
        const form = { lum_group_code_list: groups };

        await lotService.editLot(id, form);

        this.$emit("updated");
        this.$notify(`LUM group Updated.`);
      } catch (error) {
        this.$dialogs.alert(`LUM group update error.`);
      }
    },

    async fetchLUMGroup() {
      this.lumUserGroup = await userGroupService.getLUMUserGroup();
    },
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "page" }, [
        _c("div", { staticClass: "page-main" }, [
          _c("div", { staticClass: "page-header" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
              },
              [
                _c("h1", { staticClass: "page-title" }, [_vm._v("Campaign")]),
                _c("breadcrumb", {
                  attrs: {
                    name: "LUCKY_DRAW_CAMPAIGN_EDIT",
                    withLast: _vm.campaign.name,
                  },
                  on: {
                    "update:withLast": function ($event) {
                      return _vm.$set(_vm.campaign, "name", $event)
                    },
                    "update:with-last": function ($event) {
                      return _vm.$set(_vm.campaign, "name", $event)
                    },
                  },
                }),
                _c("div", { staticClass: "page-title page-header-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-round btn-default mx-10",
                      attrs: { type: "button" },
                      on: { click: _vm.goBack },
                    },
                    [
                      _c("i", {
                        staticClass: "icon md-chevron-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" Back "),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "page-content container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                },
                [
                  _c("div", { staticClass: "panel panel-bordered" }, [
                    _c("div", { staticClass: "panel-heading" }, [
                      _c(
                        "div",
                        { staticClass: "panel-title campaign-type-group" },
                        [
                          _c("h4", [
                            _vm._v(
                              "Campaign Information - " +
                                _vm._s(_vm.campaign.id)
                            ),
                          ]),
                          _c("h4", [
                            _vm._v(
                              "User Group: " + _vm._s(_vm.campaign.group_code)
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "panel-body" }, [
                      _vm.campaign
                        ? _c("form", { staticClass: "form-horizontal" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c("CampaignName", {
                                    attrs: { campaign: _vm.campaign },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-3" },
                                [
                                  _c("StatusDropdown", {
                                    attrs: { campaign: _vm.campaign },
                                    on: { updated: _vm.fetchCampaign },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("CampaignDuration", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("PlayLogic", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("TermsAndCondition", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("PrizeItem", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm._m(0),
                                _c("BackgroundImage", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("HeadLineImage", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("ButtonPicture", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("PrizeFramePicture", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("HighlightedPrizeFramePicture", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("PrizeDetailFrame", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("ButtonAction", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm._m(1),
                                _c("LINEPicture", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("LUMGroupSetting", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                                _c("ReminderDate", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("URLForm", {
                                  attrs: { campaign: _vm.campaign },
                                  on: { updated: _vm.fetchCampaign },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._m(2),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-8" }, [
      _c("h4", [_vm._v("Appearance")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-8" }, [
      _c("h4", [_vm._v("LINE Reminder")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
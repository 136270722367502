<template>
  <div class="col-md-10" v-if="weekly">
    <label class="mx-5">
      <h4>Progress Upload</h4>
    </label>

    <div class="progress-upload-switcher-wrapper">
      <p class="my-0 d-inline">Auto</p>
      <label class="switch m0">
        <input
          type="checkbox"
          :true-value="1"
          :false-value="0"
          :disabled="disabled"
          v-model="weekly.progress_upload_manual"
          @change="update"
        />
        <span class="slider round"></span>
      </label>
      <p class="mx-10 my-0 ml-20 d-inline">Manual</p>
    </div>

    <div
      class="data-source-selector"
      v-if="+weekly.progress_upload_manual === 0"
    >
      <h5 class="title">Data Source</h5>
      <div class="data-source-gtws-wrapper">
        <p class="my-0 d-inline">GT</p>
        <label class="switch m0">
          <input
            type="checkbox"
            true-value="ws"
            false-value="gt"
            :disabled="!canChangeProductCodes"
            v-model="weekly.product_data_source"
            @change="updateProductDatasource"
          />
          <span class="slider round"></span>
        </label>
        <p class="mx-10 my-0 ml-20 d-inline">WS</p>
      </div>

      <div class="data-source-ryo-switcher">
        <p class="my-0 d-inline">RYO</p>
        <label class="switch m0">
          <input
            type="checkbox"
            true-value="non_ryo"
            false-value="ryo"
            :disabled="
              !canChangeProductCodes || weekly.product_data_source == 'gt'
            "
            v-model="weekly.product_code_type"
            @change="updateProductCodeTypes"
          />
          <span class="slider round"></span>
        </label>
        <p class="mx-10 my-0 d-inline">Non-RYO</p>
      </div>
    </div>

    <div class="target-sku-wrapper" v-if="+weekly.progress_upload_manual === 0">
      <div v-for="(o, index) in period" :key="o.id" class="mb-30">
        <TargetSKU
          :campaign="campaign"
          :skus="skus"
          :index="index"
          :status="status"
          :action="action"
          :pullingId="pullingId"
          @setAction="setAction"
          @onCommit="handleCommit"
          @updated="handleSKUUpdated"
          :currentEditId="currentEditId"
          @getUploadStatus="getUploadStatus"
          @stopPullingStatus="stopPullingStatus"
          @startPullingStatus="startPullingStatus"
        />
      </div>
    </div>

    <modal :name="targetModal" :width="900" height="auto">
      <TryUploadUserTargetModal
        :campaign="campaign"
        :result="result"
        @confirm="confirmUpdate"
        :targetName="targetModal"
      />
    </modal>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import TargetSKU from "./TargetSKU.vue";
import TryUploadUserTargetModal from "./TryUploadUserTargetModal.vue";

const httpService = progressBarService.axiosInstance;

export default {
  props: ["campaign"],

  components: { TargetSKU, TryUploadUserTargetModal },

  data() {
    return {
      skus: [],
      period: [],
      weekly: null,
      result: null,

      // confirm state data
      status: null,
      action: "ready",
      pullingId: null,
      currentEditId: null,
      targetModal: "try-update-sku-modal",
    };
  },

  watch: {
    "campaign.weekly.period"() {
      this.period = this.campaign.weekly.period;
    },
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
    canChangeProductCodes() {
      return !["scheduled", "ongoing", "archived", "end", "aborted"].includes(
        this.campaign.status
      );
    },
    skuType() {
      const { product_data_source, product_code_type } = this.weekly;
      return product_data_source == "gt" ? "gt" : product_code_type;
    },
  },

  methods: {
    setAction(action, id) {
      this.action = action;

      if (action == "ready") {
        this.currentEditId = null;
      } else {
        this.currentEditId = id;
      }
    },

    async update() {
      try {
        const { id } = this.campaign;

        const progress_upload_manual = this.weekly.progress_upload_manual;

        await progressBarService.updateCampaignInfo(id, {
          weekly: { progress_upload_manual },
        });

        this.$emit("updated");
        this.$notify(`Progress Update updated`);
      } catch (error) {
        this.$dialogs.alert(`Progress Update update error.`);
      }
    },

    async getSKUList() {
      const { product_data_source, product_code_type } = this.weekly;

      if (product_data_source == "gt") {
        const skus = await progressBarService.getSKUList();
        this.skus = skus;
        return;
      }

      if (product_code_type == "ryo") {
        this.skus = await progressBarService.getWSSKURYOList();
      } else if (product_code_type == "non_ryo") {
        this.skus = await progressBarService.getWSSKUNonRYOList();
      }
    },

    async updateProductCodeTypes() {
      try {
        const { id } = this.campaign;

        const { product_code_type } = this.weekly;

        const body = {
          weekly: {
            product_code_type,
          },
        };

        await progressBarService.updateCampaignInfo(id, body);

        await this.getSKUList();

        this.$emit("updated");
        this.$notify(`Progress Update updated`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Progress Update update error.`);
      }
    },

    async updateProductDatasource() {
      try {
        const { id } = this.campaign;

        const { product_data_source } = this.weekly;

        const body = {
          weekly: {
            product_data_source,
          },
        };

        await progressBarService.updateCampaignInfo(id, body);

        await this.getSKUList();

        this.$emit("updated");
        this.$notify(`Progress Update updated`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Progress Update update error.`);
      }
    },

    async getUploadStatus() {
      this.status = await progressBarService.getUploadStatus(this.campaign.id);
    },

    async confirmUpdate() {
      try {
        const type = this.status ? this.status.type : null;
        const periodId = type.split(" ")[2];

        await httpService.put(
          `${this.campaign.id}/weekly/period/${periodId}/try_sku_confirm/${this.skuType}`
        );

        this.$notify("Confirm success");
        this.getUploadStatus();
        this.$emit("updated");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async handleSKUUpdated() {
      await this.getSKUList();
    },

    handleCommit(data) {
      this.result = data;
      this.$modal.show(this.targetModal);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 5000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  async mounted() {
    this.weekly = this.campaign.weekly;
    this.period = this.campaign.weekly.period;

    await this.getSKUList();
  },

  async created() {
    await this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
.progress-upload-switcher-wrapper {
  display: flex;
  margin: 1.5em;
  margin-top: 1em;
}

.data-source-selector,
.target-sku-wrapper {
  margin-left: 2em;
}

.data-source-selector {
  display: flex;
  padding: 1em;
  justify-content: flex-start;
}

.data-source-gtws-wrapper {
  flex: 1;
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-source-ryo-switcher {
  flex: 1;
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12" },
    [
      _c("div", { staticClass: "d-flex justify-content-between mx-10 mt-10" }, [
        _c("h4", [_vm._v("Period Duration")]),
        _c("h5", [_vm._v("No.Period " + _vm._s(_vm.period.length))]),
      ]),
      _vm._l(_vm.period, function (o, index) {
        return _c("div", { key: o.id }, [
          _c("table", { staticClass: "table table-borderless" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("h5", [_vm._v("Period " + _vm._s(index + 1))])]),
                _c("td", [
                  _c("table", [
                    _c("tr", [
                      _c("td", [_vm._v("Start")]),
                      _c(
                        "td",
                        [
                          _c("DatePicker", {
                            ref: "start_date",
                            refInFor: true,
                            attrs: {
                              type: "date",
                              "width-200px": "",
                              placeholder: "n/a",
                              format: "YYYY-MM-DD",
                              "value-type": "YYYY-MM-DD",
                              disabled: _vm.disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.update(o.id)
                              },
                            },
                            model: {
                              value: o.start_date,
                              callback: function ($$v) {
                                _vm.$set(o, "start_date", $$v)
                              },
                              expression: "o.start_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", [_vm._v("End")]),
                      _c(
                        "td",
                        [
                          _c("DatePicker", {
                            ref: "end_date",
                            refInFor: true,
                            attrs: {
                              type: "date",
                              "width-200px": "",
                              placeholder: "n/a",
                              format: "YYYY-MM-DD",
                              "value-type": "YYYY-MM-DD",
                              disabled: _vm.disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.update(o.id)
                              },
                            },
                            model: {
                              value: o.end_date,
                              callback: function ($$v) {
                                _vm.$set(o, "end_date", $$v)
                              },
                              expression: "o.end_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr"),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: {
                        disabled: _vm.period.length <= 1 || _vm.disabled,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removePeriod(o.id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("hr"),
        ])
      }),
      _c("div", { staticClass: "action" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: {
              disabled: _vm.period.length >= 52 || _vm.disabled,
              type: "button",
            },
            on: { click: _vm.addPeriod },
          },
          [_c("i", { staticClass: "fa fa-plus" })]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page" v-if="campaign">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="STAFF_ENGAGEMENT_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading pt-5">
                <div class="panel-title campaign-type-group">
                  <h4 class="my-0">Campaign Information - {{ campaign.id }}</h4>
                </div>
                <div class="panel-actions panel-actions-keep"></div>
                <div class="panel-title campaign-type-group"></div>
              </div>
              <div class="panel-body">
                <div class="form-horizontal" v-if="campaign">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <CampaignName :campaign="campaign" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <CampaignDuration
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <LobbyBanner
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <HeroBanner
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <ArticlePicture
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <QuizBanner
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <TermsAndCondition
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <RoundInformation
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { staffEngagementService } from "@/api/client";

import CampaignName from "@/views/staff-engagement/components/CampaignName.vue";
import StatusDropdown from "@/views/staff-engagement/components/StatusDropdown.vue";
import CampaignDuration from "@/views/staff-engagement/components/CampaignDuration.vue";

import LobbyBanner from "@/views/staff-engagement/components/LobbyBanner.vue";
import HeroBanner from "@/views/staff-engagement/components/HeroBanner.vue";
import ArticlePicture from "@/views/staff-engagement/components/ArticlePicture.vue";
import QuizBanner from "@/views/staff-engagement/components/QuizBanner.vue";
import TermsAndCondition from "@/views/staff-engagement/components/TermsAndCondition.vue";
import RoundInformation from "@/views/staff-engagement/components/RoundInformation.vue";

export default {
  name: "StaffEngagementEdit",

  components: {
    CampaignName,
    StatusDropdown,
    CampaignDuration,

    LobbyBanner,
    HeroBanner,
    ArticlePicture,
    QuizBanner,
    TermsAndCondition,
    RoundInformation,
  },

  data() {
    return { campaign: null };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await staffEngagementService.getById(id);
    },
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading {
  padding-top: 15px;
}

.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}
</style>

import LuckyLogin from "@/views/luckylogin/Index.vue";
import LuckyLoginList from "@/views/luckylogin/List.vue";
import LuckyLoginEdit from "@/views/luckylogin/Edit.vue";
import LuckyLoginReview from "@/views/luckylogin/review/List.vue";
import LuckyLoginWinners from "@/views/luckylogin/winners/List.vue";

export default {
  path: "/lucky-login",
  name: "LuckyLogin",
  component: LuckyLogin,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaigns",
      name: "LuckyLoginList",
      component: LuckyLoginList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "campaigns/edit/:id",
      name: "LuckyLoginEdit",
      component: LuckyLoginEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "winners",
      name: "LuckyLoginWinners",
      component: LuckyLoginWinners,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "review",
      name: "LuckyLoginReview",
      component: LuckyLoginReview,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">UserGroup</h1>
      <breadcrumb name="USERGROUP_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          @click="reloadTable"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>
        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideActive = !hideActive"
        >
          <i v-if="hideActive" class="icon md-eye" aria-hidden="true"></i>
          <i v-else class="icon md-eye-off" aria-hidden="true"></i>
          {{ hideActive ? "Show all" : "Hide Inactive" }}
        </button>
        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="panel">
              <div class="panel-body p-0">
                <vue-good-table
                  :columns="columns"
                  :rows="list"
                  :search-options="{ enabled: true }"
                  :sort-options="sortOption"
                  :pagination-options="pagination"
                  @on-row-click="goToEdit"
                  @on-sort-change="onSortChange"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPageChange"
                >
                  <template slot="table-row" slot-scope="props">
                    <div
                      v-if="props.column.field == 'description'"
                      v-html="
                        props.row.description ? props.row.description : ''
                      "
                    ></div>
                    <div v-else-if="props.column.field == 'status'">
                      {{ props.row.status | toUpperCase }}
                    </div>
                    <div v-else-if="props.column.field == 'group_code'">
                      <span class="badge badge-lg badge-primary">{{
                        props.row.group_code
                      }}</span>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12"></div>
        </div>
      </div>
    </div>

    <modal name="create-usergroup-modal" :width="900" height="auto">
      <CreateModal @submit="fetchUserGroup" />
    </modal>
  </div>
</template>

<script>
import { userGroupService } from "@/api/client";
import { userGroupTableColumns } from "@/views/usergroup/model";
import CreateModal from "@/views/usergroup/CreateModal.vue";
import VTooltip from "v-tooltip";

export default {
  model: "UserGroupList",
  data() {
    const list = [];
    const usergroups = [];
    const title = String;
    const publicPath = process.env.BASE_URL;
    return {
      list,
      usergroups,
      publicPath,
      hideActive: false,
      columns: userGroupTableColumns,
      pagination: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
        initialSortBy: { field: "id", type: "desc" },
      },
    };
  },

  components: { CreateModal },

  created() {
    this.fetchUserGroup();
  },

  beforeMount() {
    this.initialTableState();
  },

  watch: {
    usergroups(newUsergroups, oldUsergroups) {
      this.filter();
    },

    hideActive(hide) {
      this.filter();
    },

    async "$route.query"() {
      this.initialTableState();
    },
  },

  methods: {
    goToEdit(event) {
      this.$router.push({
        name: "UserGroupEdit",
        params: { id: event.row.id },
      });
    },

    filter() {
      this.hideActive
        ? (this.list = this.list.filter((g) => g.status != "inactive"))
        : (this.list = this.usergroups);
    },

    async fetchUserGroup() {
      this.usergroups = await userGroupService.getUserGroups({ show_all: 1 });
    },

    async reloadTable() {
      await this.fetchUserGroup();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-usergroup-modal`);
    },

    onSortChange(params) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          sortBy: params[0].field,
          sortDir: params[0].type,
        }),
      });
    },

    onPageChange(params) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          perPage: params.currentPerPage,
          page: params.currentPage,
        }),
      });
    },

    initialTableState() {
      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = parseInt(this.$route.query.page);
      if (this.$route.query.perPage)
        this.pagination.perPage = parseInt(this.$route.query.perPage);
    },
  },
};
</script>

export default {
  background_picture: {
    name: "Background",
    label: "(recommended size 1080 x 1920 px PNG file)",
    field: "background_picture",
    ref: "input_background_picture",
  },
  title: {
    name: "Title",
    label: "(recommended size 1060 x 1360 px PNG file)",
    field: "title_picture",
    ref: "input_title_picture",
  },
  summary_picture: {
    name: "Summary",
    label: "(recommended size 640 x 960 px PNG file)",
    field: "summary_picture",
    ref: "input_summary_picture",
  },
  vip_picture: {
    name: "VIP",
    label: "(recommended size 63 x 64 px PNG file)",
    field: "vip_picture",
    ref: "input_vip_picture",
  },
  button_leaderboard_picture: {
    name: "Button Leaderbaord",
    label: "(recommended size 397 x 186 PNG file)",
    field: "button_leaderboard_picture",
    ref: "input_button_leaderboard_picture",
  },
  button_play_picture: {
    name: "Button Play",
    label: "(recommended size 397 x 186 px PNG file)",
    field: "button_play_picture",
    ref: "input_button_play_picture",
  },
  button_play_disabled_picture: {
    name: "Button Play Disabled",
    label: "(recommended size 397 x 186 px PNG file)",
    field: "button_play_disabled_picture",
    ref: "input_button_play_disabled_picture",
  },
  button_x_picture: {
    name: "Button X",
    label: "(recommended size 87 x 88 px PNG file)",
    field: "button_x_picture",
    ref: "input_button_x_picture",
  },
  clock_picture: {
    name: "Clock",
    label: "(recommended size 26 x 26 px PNG file)",
    field: "clock_picture",
    ref: "input_clock_picture",
  },
  bomb1_picture: {
    name: "Bomb1",
    label: "(recommended size 200 x 200 px PNG file)",
    field: "bomb1_picture",
    ref: "input_bomb1_picture",
  },
  bomb2_picture: {
    name: "Bomb2",
    label: "(recommended size 200 x 200 px PNG file)",
    field: "bomb2_picture",
    ref: "input_bomb2_picture",
  },
  bomb3_picture: {
    name: "Bomb3",
    label: "(recommended size 200 x 200 px PNG file)",
    field: "bomb3_picture",
    ref: "input_bomb3_picture",
  },
  explode_picture: {
    name: "Explode",
    label: "(recommended size 500 x 500 px PNG file)",
    field: "explode_picture",
    ref: "input_explode_picture",
  },
  explode_picture: {
    name: "Explode",
    label: "(recommended size 500 x 500 px PNG file)",
    field: "explode_picture",
    ref: "input_explode_picture",
  },
  flash_picture: {
    name: "Flash",
    label: "(recommended size 300 x 300 px PNG file)",
    field: "flash_picture",
    ref: "input_flash_picture",
  },
  vip_full_picture: {
    name: "VIP full",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_full_picture",
    ref: "input_vip_full_picture",
  },
  vip_break_picture: {
    name: "VIP break",
    label: "(recommended size 200 x 200 px PNG file)",
    field: "vip_break_picture",
    ref: "input_vip_break_picture",
  },
  vip_top_picture: {
    name: "VIP top",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_top_picture",
    ref: "input_vip_top_picture",
  },
  vip_bottom_picture: {
    name: "VIP bottom",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_bottom_picture",
    ref: "input_vip_bottom_picture",
  },
  vip_left_picture: {
    name: "VIP left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_left_picture",
    ref: "input_vip_left_picture",
  },
  vip_right_picture: {
    name: "VIP right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_right_picture",
    ref: "input_vip_right_picture",
  },
  vip_top_right_picture: {
    name: "VIP top-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_top_right_picture",
    ref: "input_vip_top_right_picture",
  },
  vip_bottom_left_picture: {
    name: "VIP bottom-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_bottom_left_picture",
    ref: "input_vip_bottom_left_picture",
  },
  vip_top_left_picture: {
    name: "VIP top-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_top_left_picture",
    ref: "input_vip_top_left_picture",
  },
  vip_bottom_right_picture: {
    name: "VIP bottom-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "vip_bottom_right_picture",
    ref: "input_vip_bottom_right_picture",
  },
  sku1_full_picture: {
    name: "SKU1 full",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_full_picture",
    ref: "input_sku1_full_picture",
  },
  sku1_break_picture: {
    name: "SKU1 break",
    label: "(recommended size 500 x 500 px PNG file)",
    field: "sku1_break_picture",
    ref: "input_sku1_break_picture",
  },
  sku1_top_picture: {
    name: "SKU1 top",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_top_picture",
    ref: "input_sku1_top_picture",
  },
  sku1_bottom_picture: {
    name: "SKU1 bottom",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_bottom_picture",
    ref: "input_sku1_bottom_picture",
  },
  sku1_left_picture: {
    name: "SKU1 left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_left_picture",
    ref: "input_sku1_left_picture",
  },
  sku1_right_picture: {
    name: "SKU1 right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_right_picture",
    ref: "input_sku1_right_picture",
  },
  sku1_top_right_picture: {
    name: "SKU1 top-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_top_right_picture",
    ref: "input_sku1_top_right_picture",
  },
  sku1_bottom_left_picture: {
    name: "SKU1 bottom-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_bottom_left_picture",
    ref: "input_sku1_bottom_left_picture",
  },
  sku1_top_left_picture: {
    name: "SKU1 top-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_top_left_picture",
    ref: "input_sku1_top_left_picture",
  },
  sku1_bottom_right_picture: {
    name: "SKU1 bottom-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku1_bottom_right_picture",
    ref: "input_sku1_bottom_right_picture",
  },
  sku2_full_picture: {
    name: "SKU2 full",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_full_picture",
    ref: "input_sku2_full_picture",
  },
  sku2_break_picture: {
    name: "SKU2 break",
    label: "(recommended size 500 x 500 px PNG file)",
    field: "sku2_break_picture",
    ref: "input_sku2_break_picture",
  },
  sku2_top_picture: {
    name: "SKU2 top",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_top_picture",
    ref: "input_sku2_top_picture",
  },
  sku2_bottom_picture: {
    name: "SKU2 bottom",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_bottom_picture",
    ref: "input_sku2_bottom_picture",
  },
  sku2_left_picture: {
    name: "SKU2 left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_left_picture",
    ref: "input_sku2_left_picture",
  },
  sku2_right_picture: {
    name: "SKU2 right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_right_picture",
    ref: "input_sku2_right_picture",
  },
  sku2_top_right_picture: {
    name: "SKU2 top-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_top_right_picture",
    ref: "input_sku2_top_right_picture",
  },
  sku2_bottom_left_picture: {
    name: "SKU2 bottom-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_bottom_left_picture",
    ref: "input_sku2_bottom_left_picture",
  },
  sku2_top_left_picture: {
    name: "SKU2 top-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_top_left_picture",
    ref: "input_sku2_top_left_picture",
  },
  sku2_bottom_right_picture: {
    name: "SKU2 bottom-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku2_bottom_right_picture",
    ref: "input_sku2_bottom_right_picture",
  },
  sku3_full_picture: {
    name: "SKU3 full",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_full_picture",
    ref: "input_sku3_full_picture",
  },
  sku3_break_picture: {
    name: "SKU3 break",
    label: "(recommended size 500 x 500 px PNG file)",
    field: "sku3_break_picture",
    ref: "input_sku3_break_picture",
  },
  sku3_top_picture: {
    name: "SKU3 top",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_top_picture",
    ref: "input_sku3_top_picture",
  },
  sku3_bottom_picture: {
    name: "SKU3 bottom",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_bottom_picture",
    ref: "input_sku3_bottom_picture",
  },
  sku3_left_picture: {
    name: "SKU3 left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_left_picture",
    ref: "input_sku3_left_picture",
  },
  sku3_right_picture: {
    name: "SKU3 right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_right_picture",
    ref: "input_sku3_right_picture",
  },
  sku3_top_right_picture: {
    name: "SKU3 top-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_top_right_picture",
    ref: "input_sku3_top_right_picture",
  },
  sku3_bottom_left_picture: {
    name: "SKU3 bottom-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_bottom_left_picture",
    ref: "input_sku3_bottom_left_picture",
  },
  sku3_top_left_picture: {
    name: "SKU3 top-left",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_top_left_picture",
    ref: "input_sku3_top_left_picture",
  },
  sku3_bottom_right_picture: {
    name: "SKU3 bottom-right",
    label: "(recommended size 120 x 120 px PNG file)",
    field: "sku3_bottom_right_picture",
    ref: "input_sku3_bottom_right_picture",
  },
};

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Lot</h1>
      <breadcrumb name="LOT_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          @click="reloadTable"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="panel">
              <div class="panel-body p-0">
                <vue-good-table
                  :columns="columns"
                  :rows="list"
                  :search-options="searchOption"
                  :sort-options="sortOption"
                  :pagination-options="pagination"
                  @on-row-click="goToEdit"
                  @on-sort-change="onSortChange"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPageChange"
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'type'">
                      {{ props.row.type | toUpperCase }}
                    </div>
                    <div v-else-if="props.column.field == 'status'">
                      {{ props.row.status | toUpperCase }}
                    </div>
                    <div v-else-if="props.column.field == 'duration'">
                      {{
                        getDuration(props.row.start_date, props.row.end_date)
                      }}
                    </div>

                    <div v-else-if="props.column.field == 'start_date'">
                      <span
                        v-tooltip="
                          getPostStartTooltip(
                            props.row.post_date,
                            props.row.start_date
                          )
                        "
                        >{{ setTextDate(props.row.start_date) }}
                      </span>
                    </div>

                    <div v-else-if="props.column.field == 'end_date'">
                      <span
                        v-tooltip="
                          getEndStartTooltip(
                            props.row.end_date,
                            props.row.delist_date
                          )
                        "
                        >{{ setTextDate(props.row.end_date) }}
                      </span>
                    </div>

                    <div v-else-if="props.column.field == 'pinned'">
                      <span class="checkbox-custom checkbox-primary">
                        <input
                          v-if="props.row.pinned == 1"
                          type="checkbox"
                          checked
                          @click.stop="uncheckedPinned(props.row.id)"
                        />
                        <input
                          v-if="props.row.pinned == 0"
                          type="checkbox"
                          @click.stop="checkedPinned(props.row.id)"
                        />
                        <label></label>
                      </span>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12"></div>
        </div>
      </div>
    </div>

    <modal name="create-lot-modal" :width="900" height="auto">
      <CreateModal @submit="fetchLots" />
    </modal>
  </div>
</template>

<script>
import { lotService } from "@/api/client";
import { LotTableColumns } from "@/views/lot/model";
import CreateModal from "@/views/lot/CreateModal.vue";
import moment from "moment";
import VTooltip from "v-tooltip";

export default {
  model: "LotList",
  data() {
    const list = [];
    const lots = [];
    const title = String;
    const hideArchive = true;
    const publicPath = process.env.BASE_URL;
    return {
      list,
      lots,
      publicPath,
      hideArchive,
      columns: LotTableColumns,
      pagination: {
        enabled: true,
      },
      searchOption: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
      },
    };
  },

  beforeMount() {
    this.initialTableState();
  },

  components: { CreateModal },

  created() {
    this.fetchLots();
  },

  watch: {
    lots(newLots, oldLots) {
      this.filter();
    },
    hideArchive(newLots, oldLots) {
      this.filter();
    },
    async "$route.query"() {
      this.initialTableState();
    },
  },

  methods: {
    async fetchLots() {
      this.lots = await lotService.getLots({ show_all: 1 });
    },
    goToEdit(event) {
      this.$router.push({ name: "LotEdit", params: { id: event.row.id } });
    },
    checkedPinned(id) {
      let pinnedValue = 1;
      this.changPinned(id, pinnedValue);
    },
    uncheckedPinned(id) {
      let pinnedValue = 0;
      this.changPinned(id, pinnedValue);
    },

    async changPinned(id, pinnedValue) {
      try {
        const form = new FormData();
        form.set("pinned", pinnedValue);
        const body = JSON.stringify(Object.fromEntries(form));
        await lotService.editLot(id, JSON.parse(body));
        await this.$dialogs.alert("Change Pinned success");
        await this.fetchLots();
      } catch (error) {
        this.$dialogs.alert(error);
      }
    },
    filter() {
      this.hideArchive
        ? (this.list = this.lots.filter((g) => g.status != "archived"))
        : (this.list = this.lots);
    },
    async fetchLots() {
      this.lots = await lotService.getLots({ show_all: 1 });
    },
    async reloadTable() {
      await this.fetchLots();
      this.$notify("Updated");
    },
    async openCreateModal() {
      this.$modal.show(`create-lot-modal`);
    },

    getDuration(start, end) {
      start = moment(start, "YYYY-MM-DD ");
      end = moment(end, "YYYY-MM-DD");

      let duration = end.diff(start, "days") + 1;

      if (isNaN(duration)) {
        duration = "-";
        return duration;
      } else {
        duration.toString();
        duration = duration + " วัน";
        return duration;
      }
    },

    setTextDate(date) {
      let textDate = "";
      if (date == null) {
        textDate = "-";
      }
      if (date != null) {
        textDate = moment(date, "YYYY-MM-DD HH:mm:ss").format(
          "DD MMMM YYYY HH:mm"
        );
      }

      return textDate;
    },

    getPostStartTooltip(post, start) {
      post = moment(post).format("DD MMMM YYYY HH:mm");
      start = moment(start).format("DD MMMM YYYY HH:mm");
      let text = "Post " + post + ", " + "Start " + start;
      return text;
    },

    getEndStartTooltip(end, delist) {
      end = moment(end).format("DD MMMM YYYY HH:mm");
      delist = moment(delist).format("DD MMMM YYYY HH:mm");
      let text = "End " + end + ", " + "Delist " + delist;
      return text;
    },

    onSortChange(params) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          sortBy: params[0].field,
          sortDir: params[0].type,
        }),
      });
    },

    onPageChange(params) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          perPage: params.currentPerPage,
          page: params.currentPage,
        }),
      });
    },

    initialTableState() {
      //default
      if (!this.$route.query.sortBy || !this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: "id",
          type: "desc",
        };

      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = parseInt(this.$route.query.page);
      if (this.$route.query.perPage)
        this.pagination.perPage = parseInt(this.$route.query.perPage);
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="page">
    <div class="page-main">
      <div class="page-header">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <h1 class="page-title">Point Campaign</h1>
          <breadcrumb name="POINT_CAMPAIGN_EDIT" :withLast.sync="campaign.name">
          </breadcrumb>
          <div class="page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Point Campaign Information - {{ campaign.id }}
                </h3>
                <div class="panel-actions panel-actions-keep">
                  User Group: {{ campaign.group_code }}
                </div>
              </div>
              <div class="panel-body">
                <div class="form-horizontal">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>
                        <h5>Point Campaign Name</h5>
                      </label>
                      <input
                        type="text"
                        ref="campaignName"
                        maxlength="255"
                        class="form-control"
                        v-model="campaign.name"
                        @change="submit('name')"
                        :disabled="!isDraft"
                      />
                      <nameValidation :$v="$v" />
                    </div>
                    <div class="form-group col-md-3">
                      <label class="form-control-label">
                        <h5>Status</h5>
                      </label>
                      <div class="px-15">
                        <StatusButton
                          @change="setStatus"
                          :status.sync="campaign.status"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-10">
                      <div class="widget-url-input">
                        <span>Widget URL</span>
                        <input
                          type="text"
                          maxlength="255"
                          class="form-control"
                          v-model="campaign.widget_url"
                          @change="submit('widget_url')"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <h5 class="col-md-12">Point Campaign Duration</h5>
                    <label class="col-md-1 form-control-label">Start</label>
                    <DatePicker
                      type="datetime"
                      ref="startDate"
                      :confirm="true"
                      class="col-md-3 px-0"
                      format="DD/MM/YYYY HH:mm"
                      value-type="YYYY-MM-DD HH:mm"
                      @change="submit('start_date')"
                      v-model="campaign.start_date"
                      :disabled="!isDraft"
                    />
                    <label class="col-md-1 form-control-label">End</label>
                    <DatePicker
                      type="datetime"
                      ref="endDate"
                      :confirm="true"
                      class="col-md-3 px-0"
                      format="DD/MM/YYYY HH:mm"
                      value-type="YYYY-MM-DD HH:mm"
                      placeholder="n/a"
                      v-model="campaign.end_date"
                      @change="submit('end_date')"
                      :disabled="!(isDraft || isPaused)"
                    />
                  </div>
                  <hr />
                  <div class="row">
                    <h5 class="col-md-12">Add Coins</h5>
                    <div class="col-12">
                      <div class="point-to-coin-inputs">
                        Every
                        <input
                          min="1"
                          max="999"
                          type="number"
                          maxlength="3"
                          ref="convPoint"
                          class="form-control"
                          v-model="campaign.conv_point"
                          @change="submit('conv_point')"
                          :disabled="!(isDraft || isPaused || isScheduled)"
                        />
                        SF points convert to
                        <input
                          min="1"
                          max="999"
                          type="number"
                          maxlength="3"
                          ref="convCoin"
                          class="form-control"
                          v-model="campaign.conv_coin"
                          @change="submit('conv_coin')"
                          :disabled="!(isDraft || isPaused || isScheduled)"
                        />
                        coins.
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <h5 class="col-md-12">Discount</h5>
                    <div class="col-12">
                      <table>
                        <tr>
                          <td>
                            Scan QR
                          </td>
                          <td>
                            <label class="switch">
                              <input
                                type="checkbox"
                                @change="submit('can_scanqr')"
                                v-model="campaign.can_scanqr"
                                :disabled="
                                  !(isDraft || isPaused || isScheduled)
                                "
                              />
                              <span class="slider round"></span>
                            </label>
                            <p class="mx-10 mr-20 d-inline">
                              {{ campaign.can_scanqr ? "ON" : "OFF" }}
                            </p>
                          </td>
                          <td>
                            Markup percentage
                            <input
                              min="0.0"
                              max="1000"
                              step="any"
                              type="number"
                              ref="markerupPercent"
                              class="form-control markup-percentage"
                              :disabled="
                                !canScanQR ||
                                !(isDraft || isPaused || isScheduled)
                              "
                              v-model="campaign.markerup_percent"
                              @change="submit('markerup_percent')"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            Every
                            <input
                              min="1"
                              max="999"
                              type="number"
                              maxlength="3"
                              ref="discountCoin"
                              class="form-control"
                              :disabled="!(isDraft || isPaused || isScheduled)"
                              v-model="campaign.discount_coin"
                              @change="submit('discount_coin')"
                            />
                            coins get discount
                            <input
                              min="1"
                              max="999"
                              type="number"
                              maxlength="3"
                              ref="discountBaht"
                              class="form-control"
                              :disabled="!(isDraft || isPaused || isScheduled)"
                              v-model="campaign.discount_baht"
                              @change="submit('discount_baht')"
                            />
                            baht.
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <table class="get-discount-employee-discount-table">
                              <tr>
                                <td>
                                  Get discount
                                </td>
                                <td>
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      @change="submit('get_discount')"
                                      v-model="campaign.get_discount"
                                      :disabled="
                                        !(isDraft || isPaused || isScheduled)
                                      "
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                  <p class="mx-10 mr-20 d-inline">
                                    {{ campaign.get_discount ? "ON" : "OFF" }}
                                  </p>
                                </td>
                                <td>
                                  Employee Discount
                                </td>
                                <td>
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      :true-value="1"
                                      :false-value="0"
                                      @change="submit('can_salesdiscount')"
                                      v-model="campaign.can_salesdiscount"
                                      :disabled="
                                        !(isDraft || isPaused || isScheduled)
                                      "
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                  <p class="mx-10 mr-20 d-inline">
                                    {{
                                      campaign.can_salesdiscount ? "ON" : "OFF"
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr v-if="canGetDiscount || canEmployeeDiscount">
                          <td colspan="3">
                            <SKUDiscountSettings
                              :item="campaign"
                              @updated="fetchCampaign"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <p class="mt-15 mb-10">Customized text area</p>
                            <ckeditor
                              :config="defaultEditorConfig"
                              v-model="campaign.description"
                              @input="submit('description')"
                            ></ckeditor>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletService } from "@/api/client";

import moment from "moment";
import DatePicker from "vue2-datepicker";

import { defaultEditorConfig } from "./model";
import nameValidation from "@/views/point/validation/name";
import { FormCreateValidateRule as validateRules } from "@/views/point/validation";
import StatusButton from "@/views/point/StatusButton.vue";
import FormValidateMixin from "@/views/point/mixins/validate";
import SKUDiscountSettings from "./components/SKUDiscountSettings.vue";

export default {
  name: "PointCampaignEdit",

  mixins: [FormValidateMixin],

  components: {
    DatePicker,
    StatusButton,
    nameValidation,
    SKUDiscountSettings,
  },

  validations: { campaign: validateRules },

  data() {
    return {
      interalStatusId: null,
      previousValue: {},
      campaign: { name: "" },
      defaultEditorConfig: defaultEditorConfig,
    };
  },

  computed: {
    canScanQR() {
      return +this.campaign.can_scanqr === 1;
    },
    canGetDiscount() {
      return +this.campaign.get_discount === 1;
    },
    canEmployeeDiscount() {
      return +this.campaign.can_salesdiscount === 1;
    },
    isDraft() {
      return this.campaign.status == "draft";
    },
    isPaused() {
      return this.campaign.status == "paused";
    },
    isScheduled() {
      return this.campaign.status == "scheduled";
    },
  },

  async created() {
    await this.fetchCampaign();
    this.autoChangeStatus();
    this.parseValue();
  },

  watch: {
    "campaign.status"(n, o) {
      if (this.interalStatusId == null) {
        this.autoChangeStatus();
      }

      if (n == "end") {
        clearInterval(this.interalStatusId);
      }
    },
  },

  methods: {
    parseValue() {
      const { markerup_percent } = this.campaign;

      if (
        !isNaN(+markerup_percent) &&
        !(
          Number(markerup_percent) === markerup_percent &&
          markerup_percent % 1 !== 0
        )
      ) {
        this.campaign.markerup_percent = (+this.campaign
          .markerup_percent).toFixed(1);
      }
    },

    autoChangeStatus() {
      const status = this.campaign.status;
      if (status == "end" || status == "draft") {
        clearInterval(this.interalStatusId);
        return;
      }

      const _this = this;

      this.interalStatusId = setInterval(() => {
        const status = _this.campaign.status;

        switch (status) {
          case "scheduled":
            if (
              _this.isPastDate(moment(_this.campaign.start_date), new Date())
            ) {
              _this.campaign.status = "ongoing";
            }

            break;
          case "ongoing":
            if (_this.isPastDate(moment(_this.campaign.end_date), new Date())) {
              _this.campaign.status = "end";
              clearInterval(_this.interalStatusId);
            }
            break;
        }
      }, 1000);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await walletService.getCampaignById(id);
      this.previousValue = { ...this.campaign };
    },

    goBack() {
      this.$router.go(-1);
    },

    isPastDate(date, withDate) {
      return date.diff(withDate || new Date()) < 0;
    },

    formatBooleanToNumber(form, field) {
      if (form[field] === true) {
        return 1;
      } else if (form[field] === false) {
        return 0;
      } else {
        return form[field];
      }
    },

    async submit(field) {
      try {
        if (!this.validate(field)) return;

        const form = {};
        form[field] = this.campaign[field];
        form[field] = this.formatBooleanToNumber(form, field);
        await walletService.updateCampaign(this.campaign.id, form);
        this.notifySuccess(field);
      } catch (error) {
        console.error(error);
        this.notifyError(field);
        await this.fetchCampaign();
      }
    },

    validateScheduleStatus() {
      if (this.campaign.status != "scheduled") {
        return true;
      }

      if (!this.validate("start_date")) {
        return false;
      }

      if (!this.validate("end_date")) {
        return false;
      }

      return true;
    },

    async submitWithDefaultValue() {
      const {
        can_scanqr,
        get_discount,

        conv_coin,
        conv_point,
        discount_coin,
        discount_baht,
        markerup_percent,
        can_salesdiscount,
      } = this.campaign;

      const form = {
        // Default value.
        conv_point: conv_point || 1,
        conv_coin: conv_coin || 1,
        discount_coin: discount_coin || 1,
        discount_baht: discount_baht || 10,
        markerup_percent: markerup_percent || 5.0,

        can_scanqr: can_scanqr === true ? 1 : 0,
        get_discount: get_discount === true ? 1 : 0,
        can_salesdiscount: can_salesdiscount === true ? 1 : 0,
      };

      await walletService.updateCampaign(this.campaign.id, form);
      await this.fetchCampaign();
    },

    async setStatus(status) {
      try {
        if (!this.validateScheduleStatus()) {
          return;
        }

        if (status == "scheduled") {
          await this.submitWithDefaultValue();
        }

        const form = new FormData();
        form.set("status", status);

        if (status == this.campaign.status) return;

        const body = JSON.stringify(Object.fromEntries(form));
        await walletService.updateCampaign(this.campaign.id, JSON.parse(body));

        this.$notify("Change status success");
        this.campaign.status = status;
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    notifySuccess(field) {
      this.$notify(`${this.mapFieldName(field)} updated.`);
    },

    notifyError(field) {
      this.$notify(`${this.mapFieldName(field)} update error.`);
    },

    mapFieldName(field) {
      switch (field) {
        case "name":
          return "Point Campaign Name";
          break;
        case "start_date":
          return "Start Date";
          break;
        case "end_date":
          return "End Date";
          break;
        case "conv_point":
          return "SF Point";
          break;
        case "conv_coin":
          return "Coin";
          break;
        case "markerup_percent":
          return "Markup percentage";
          break;

        default:
          return "";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.point-to-coin-inputs {
  margin-left: 2rem;
}

.widget-url-input {
  span {
    display: inline-block;
  }

  input {
    max-width: 400px;
    margin-left: 12px;
    display: inline-block;
  }
}

table {
  width: 100%;

  tr {
    height: 60px;
    border-bottom: 1px solid #ddd;
    td {
      &:nth-child(1) {
        width: 160px;
      }
      &:nth-child(2) {
        width: 140px;
      }
    }
  }
}

label.switch {
  margin-right: 10px;
}

input[type="number"] {
  width: auto;
  max-width: 80px;
  margin: auto 10px;
  display: inline-block;
}

.markup-percentage {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}

.get-discount-employee-discount-table {
  tr td {
    &:nth-child(1),
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(2),
    &:nth-child(4) {
      width: 30%;
    }
  }

  tr {
    border: 0px;
  }
}
</style>

export const winnerTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
  {
    label: "User ID",
    field: "sfdc_user_id",
  },
  {
    label: "Name",
    field: "firstname",
  },
  {
    label: "Last Name",
    field: "lastname",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "Group Code",
    field: "group_code",
  },
  {
    label: "Campaign ID",
    field: "campaign_id",
    type: "number",
  },
  {
    label: "Campaign Name.",
    field: "campaign_name",
  },
  {
    label: "Login No.",
    field: "login_no",
    type: "number",
  },
  {
    label: "Reward Name",
    field: "reward_name",
  },
  {
    label: "E-Reward Code",
    field: "e_reward_code",
  },
  {
    label: "Prize Theme ID",
    field: "theme_id",
  },
  {
    label: "Reward Request ID",
    field: "request_id",
  },
  {
    label: "Answers",
    field: "answer",
  },
  {
    label: "Submitted",
    field: "submitted",
    type: "number",
    formatFn: function (value) {
      return +value === 1 ? "Yes" : "No";
    },
  },
];

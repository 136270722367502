<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Transaction Log</h1>
      <breadcrumb name="GAME_TRANSACTION_LOGS"></breadcrumb>
      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="fromTo"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span>
                    {{ props.row.status | toUpperCase }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import vueGoodTableMixins from "@/mixins/vue-good-table";

import { gameService } from "@/api/client";
import { transactionLogTableColumns } from "@/views/game/model";

export default {
  name: "TransactionLog",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      fromTo: [],
      transactions: [],
      columns: transactionLogTableColumns,
    };
  },

  watch: {
    fromTo() {
      this.onPeriodChange();
    },

    transactions(v) {
      this.list = v;
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    isValidPeriod() {
      const { from, to } = this.$route.query;

      if (!from || !moment(from).isValid()) {
        return false;
      }

      if (!to || !moment(to).isValid()) {
        return false;
      }

      return true;
    },

    async fetch() {
      if (!this.isValidPeriod()) {
        return;
      }

      const params = this.getParams();

      const { data, total_records } = await gameService.getTransactionLog(
        params
      );

      this.transactions = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      try {
        const response = await gameService.downloadTransactionLog({
          from: this.fromTo[0],
          to: this.fromTo[1],
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Game_Transaction_Log_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
          }),
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
</style>

<template>
  <div class="col-md-12" v-if="stamp">
    <label class="mx-5">
      <h4>Numbers on empty stamp</h4>
    </label>

    <div class="flex-fit-content">
      <div class="form-group-row">
        <div class="left-box">
          <p class="mx-10 d-inline">Hidden</p>
          <label class="switch">
            <input
              type="checkbox"
              :true-value="1"
              :false-value="0"
              :disabled="disabled"
              name="show_numbers_on_empty_stamp"
              v-model.number="stamp.show_numbers_on_empty_stamp"
              @change="
                update($event, 'checkbox', stamp.show_numbers_on_empty_stamp)
              "
            />
            <span class="slider round"></span>
          </label>
          <p class="mx-10 d-inline">Shown</p>
        </div>

        <div class="right-box">
          <label class="mb-0">Text color</label>

          <div class="color-picker">
            <input
              type="text"
              v-model="color"
              name="number_color"
              @change="update($event, 'color')"
              :disabled="disabledColorPicker"
            />
            <input
              type="color"
              v-model="color"
              name="number_color"
              @change="update($event, 'color')"
              :disabled="disabledColorPicker"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      color: null,
    };
  },

  computed: {
    disabledColorPicker() {
      return this.disabled || this.stamp.show_numbers_on_empty_stamp !== 1;
    },
  },

  methods: {
    initial() {
      const { number_color } = this.stamp;

      if (!number_color) return;

      this.color = this.parseColorValue(number_color);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
  margin-bottom: 1rem;
  padding: 0px 1.25rem;
}

.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-bottom: 0px;
  }
}

.left-box,
.right-box {
  min-width: 230px;
}

.right-box {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 5rem;
  border-left: 1px solid #ccc;

  > * {
    flex: 1 0 auto;
  }
}

.switch {
  margin: 1rem 1.25rem;
  margin-top: 0px;
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;

    &:disabled {
      color: #aaa;
      background-color: #efefef;
    }
  }

  input[type="color"] {
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border-left: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

input[type="color"] {
  &:disabled {
    background-color: #efefef;
  }
}
</style>

<template>
  <div class="col-md-8" v-if="monthlyshort">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Progress bar
            <span class="small light"
              >(Dimension MUST BE 632 x 64 pixels with exactly the same
              shape)</span
            >
          </h4>
        </label>

        <div class="form-group">
          <div class="d-block">
            <img
              class="img img-fluid image-preview"
              v-if="picture"
              :src="picture"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="uploadImage"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      file: null,
      monthlyshort: null,
    };
  },
  computed: {
    picture() {
      return this.campaign.monthlyshort.progressbar_picture;
    },

    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 2000000) {
        this.$notify("File shall not exceed 2 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("progressbar_picture", file);

        const { id } = this.campaign;

        await progressBarService.fileUpload(id, "monthlyshort", form);

        this.$emit("updated");
        this.$notify(`Progress bar picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Progress bar picture error.`);
      }
    },
  },

  mounted() {
    this.monthlyshort = this.campaign.monthlyshort;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  max-width: 420px;
}
</style>

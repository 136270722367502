var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group-row row" }, [
    _c("label", [_vm._v("Stamp No.")]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.reward.stamp_no,
            expression: "reward.stamp_no",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { name: "stamp_no", disabled: _vm.disabled },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return _vm._n(val)
                })
              _vm.$set(
                _vm.reward,
                "stamp_no",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            _vm.update,
          ],
        },
      },
      [
        _c("option", { attrs: { disabled: "" }, domProps: { value: null } }, [
          _vm._v("Not Select"),
        ]),
        _vm._l(_vm.campaign.stamp.no_stamp, function (n, i) {
          return _c(
            "option",
            {
              key: i,
              attrs: { disabled: _vm.selected(n) },
              domProps: { value: n },
            },
            [_vm._v(" " + _vm._s(n) + " ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
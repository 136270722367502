var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.weekly
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 1A")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text1a_content,
                          expression: "weekly.text1a_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text1a_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text1a_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text1a_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text1a_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text1a_specify,
                            expression: "weekly.text1a_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text1a_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text1a_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text1a_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 1B")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text1b_content,
                          expression: "weekly.text1b_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text1b_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text1b_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text1b_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text1b_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text1b_specify,
                            expression: "weekly.text1b_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text1b_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text1b_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text1b_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 1C")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text1c_content,
                          expression: "weekly.text1c_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text1c_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text1c_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text1c_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text1c_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text1c_specify,
                            expression: "weekly.text1c_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text1c_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text1c_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text1c_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 1D")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text1d_content,
                          expression: "weekly.text1d_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text1d_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text1d_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text1d_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text1d_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text1d_specify,
                            expression: "weekly.text1d_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text1d_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text1d_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text1d_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 1E")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text1e_content,
                          expression: "weekly.text1e_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text1e_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text1e_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text1e_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text1e_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text1e_specify,
                            expression: "weekly.text1e_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text1e_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text1e_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text1e_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 2A")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text2a_content,
                          expression: "weekly.text2a_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text2a_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text2a_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text2a_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text2a_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text2a_specify,
                            expression: "weekly.text2a_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text2a_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text2a_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text2a_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 2B")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text2b_content,
                          expression: "weekly.text2b_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text2b_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text2b_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text2b_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text2b_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text2b_specify,
                            expression: "weekly.text2b_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text2b_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text2b_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text2b_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 2C")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text2c_content,
                          expression: "weekly.text2c_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text2c_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text2c_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text2c_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text2c_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text2c_specify,
                            expression: "weekly.text2c_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text2c_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text2c_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text2c_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 2D")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text2d_content,
                          expression: "weekly.text2d_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text2d_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text2d_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text2d_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text2d_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text2d_specify,
                            expression: "weekly.text2d_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text2d_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text2d_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text2d_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Text Group 2E")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weekly.text2e_content,
                          expression: "weekly.text2e_content",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "text2e_content", disabled: _vm.disabled },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.weekly,
                              "text2e_content",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "reward_cur" } }, [
                        _vm._v("RewardCur"),
                      ]),
                      _c("option", { attrs: { value: "target_cur" } }, [
                        _vm._v("TargetCur"),
                      ]),
                      _c("option", { attrs: { value: "reward_all" } }, [
                        _vm._v("RewardAll"),
                      ]),
                      _c("option", { attrs: { value: "no_period" } }, [
                        _vm._v("No.Period"),
                      ]),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Custom"),
                      ]),
                    ]
                  ),
                ]),
                _vm.weekly.text2e_content == "custom"
                  ? _c("td", [_vm._v("Specify")])
                  : _vm._e(),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.weekly.text2e_content == "custom"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weekly.text2e_specify,
                            expression: "weekly.text2e_specify",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "text2e_specify",
                          "width-250px": "",
                          maxlength: "100",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.weekly.text2e_specify },
                        on: {
                          change: function ($event) {
                            return _vm.update($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.weekly,
                              "text2e_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v("Text area")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
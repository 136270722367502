<template>
  <div class="px-15" v-if="theme">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ theme.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="theme.status == 'active'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('inactive')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>
          INACTIVE
        </a>
        <a
          v-if="theme.status == 'inactive'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('active')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>
          ACTIVE
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["theme"],

  methods: {
    validate(status) {
      const {
        name,
        award_name,
        picture,
        auto_claim,
        claim_expiration,
        pool_id_list,
      } = this.theme;

      switch (status) {
        case "active":
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Theme name.");
            return false;
          }

          if (!award_name) {
            this.$dialogs.alert("กรุณาระบุ Award name.");
            return false;
          }

          if (!picture) {
            this.$dialogs.alert("กรุณาระบุ Picture.");
            return false;
          }

          if (
            isNaN(+claim_expiration) ||
            claim_expiration < 0 ||
            claim_expiration > 10000
          ) {
            this.$dialogs.alert(
              "กรุณาระบุ Claim expiration ที่มากกว่า 0 และต้องไม่เกิน 10,000"
            );
            return false;
          }

          if (+auto_claim === 1 && pool_id_list.length > 1) {
            this.$dialogs.alert(
              "หาก Auto claim เป็น ON ต้องมี Pool Option แค่ 1 รายการเท่านั้น"
            );
            return false;
          }
      }

      return true;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.theme;
        const form = { status };

        await prizepoolService.updatePrizeTheme(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

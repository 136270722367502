var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "d-block" }, [
        _vm.picture
          ? _c("img", {
              staticClass: "img img-fluid image-preview",
              attrs: { src: _vm.picture },
            })
          : _c("div", { staticClass: "img-fluid image-preview" }),
      ]),
      _c("input", {
        ref: "button_picture",
        staticClass: "form-control",
        attrs: { type: "file", accept: "image/*", disabled: _vm.disabled },
        on: { change: _vm.uploadImage },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("span", { staticClass: "light" }, [
        _vm._v(" recommended size 300 x 300"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
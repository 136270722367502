<template>
  <div class="form-group col-12">
    <div class="form-group-row">
      <h4>Auto claim</h4>
      <div class="switch-wrapper">
        <label class="switch">
          <input
            type="checkbox"
            :true-value="1"
            :false-value="0"
            :disabled="disabled"
            name="auto_claim"
            v-model.number="theme.auto_claim"
            @change="update($event, 'checkbox', theme.auto_claim)"
          />
          <span class="slider round"></span>
        </label>
        <label for="switch">
          {{ theme.auto_claim === 1 ? "ON" : "OFF" }}
        </label>
      </div>
      <b :class="{ red: theme.auto_claim === 0 && theme.pool_list.length > 1 }">
        There must be only 1 pool for auto claim
      </b>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: "Update Auto claim error.",
        success: "Update Auto claim success.",
      },
    };
  },

  computed: {
    disabled() {
      return (
        this.theme.pool_list.length > 1 ||
        !["inactive"].includes(this.theme.status)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h4,
  label {
    margin-bottom: 0px;
  }
}

.switch {
  margin: 1rem 1.25rem;
  margin-top: 0px;
}

.switch:has(input:disabled) {
  cursor: not-allowed;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  [for="switch"] {
    margin-right: 2rem;
  }
}

.red {
  color: #f44336;
}
</style>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Prize Theme</h1>
      <breadcrumb name="PRIZE_SUMMARY_THEME_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Inactive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <div class="vgt-global-search vgt-clearfix">
              <div class="vgt-global-search__input vgt-pull-left">
                <span class="input__icon">
                  <div class="magnifying-glass"></div>
                </span>
                <input
                  type="text"
                  placeholder="Search Table"
                  class="vgt-input vgt-pull-left"
                  v-model="searchTerm"
                  @keyup="handleSearchChange"
                />
              </div>
              <div class="vgt-global-search__actions vgt-pull-right"></div>
            </div>
            <vue-good-table
              ref="table"
              :rows="themes"
              :columns="columns"
              @on-row-click="onRowClick"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-prize-theme-modal" :width="450" height="auto">
      <CreateModal @submit="fetchTheme(1)" />
    </modal>
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import CreateModal from "@/views/prize/theme/CreateModal.vue";
import { tableColumns } from "@/views/prize/theme/model";

export default {
  name: "PrizeThemeTable",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      themes: [],
      searchTerm: "",
      hideArchive: false,
      columns: tableColumns,
    };
  },

  components: { CreateModal },

  created() {
    this.fetchTheme(1);
  },

  watch: {
    hideArchive() {
      this.filter();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
    this.searchTerm = this.$route.query.searchTerm;
  },

  methods: {
    onRowClick(event) {
      this.$router
        .push({
          name: "prize-theme-edit",
          params: { id: event.row.id },
        })
        .catch((err) => {});
    },

    async fetchTheme(show_all) {
      this.themes = await prizepoolService.getPrizeThemeList({
        show_all: show_all,
      });
    },

    filter() {
      this.hideArchive ? this.fetchTheme(0) : this.fetchTheme(1);
    },

    async reloadTable() {
      await this.fetchTheme(this.hideArchive ? 0 : 1);
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-prize-theme-modal`);
    },

    handleSearchChange() {
      this.onSearch({ searchTerm: this.searchTerm });
    },
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>

import Index from "@/views/liff_access/Index.vue";
import Settings from "@/views/liff_access/Settings.vue";

export default {
  path: "/liff-access",
  name: "LIFFAccess",
  component: Index,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "settings",
      name: "liff-access-settings",
      component: Settings,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

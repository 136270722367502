<template>
  <div class="col-md-10" v-if="weekly && status != null">
    <h5 class="mb-5">Period {{ index + 1 }}</h5>
    <div class="sku-box-wrapper">
      <label class="mx-5">
        <h5>Target SKUs (counted toward progress)</h5>
      </label>
      <div class="sku-box-action">
        <p>Product Codes</p>

        <div
          class="wrapper-upload-form"
          v-if="action == 'ready' && status.active == 0"
        >
          <button
            type="button"
            class="btn btn-primary btn-round"
            :disabled="disabled"
            @click="handleClickEdit"
          >
            Edit
          </button>
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="
            currentId == period.id && action == 'editing' && status.active == 0
          "
        >
          <div>
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="toggleSelectAll"
            >
              {{
                skuSelected.length < skus.length ? "Select All" : "Deselect All"
              }}
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="handleVerify"
              :disabled="disabled"
            >
              Verify
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="handleCancel"
              :disabled="disabled"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="currentId == period.id && status.active == 1"
        >
          <label class="label-blue" v-if="status.state == 'verify'">
            Verifying change
          </label>

          <label class="label-blue" v-else-if="status.state == 'done-verify'">
            Click to commit change
          </label>

          <div v-if="status.state == 'verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              disabled
            >
              Commit
            </button>
            <button type="button" class="btn btn-round btn-default" disabled>
              Cancel
            </button>
          </div>
          <div v-else-if="status.state == 'done-verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="getResult"
              :disabled="disabled"
            >
              Commit
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="handleCancel"
              :disabled="disabled"
            >
              Cancel
            </button>
          </div>

          <label
            class="label-blue"
            v-if="status.state == 'execute' && status.status == 'pending'"
          >
            Processing...
          </label>
        </div>
      </div>

      <Selectize
        multiple
        :disabled="!canEdit"
        v-model="skuSelected"
        :settings="selectizeSetting"
      >
        <option
          v-for="(sku, index) in skus"
          :key="index"
          :value="weekly.product_data_source == 'ws' ? sku : sku.PRO_CODE"
        >
          {{
            weekly.product_data_source == "ws"
              ? sku
              : `${sku.PRO_NAME_EN} - ${sku.PRO_CODE}`
          }}
        </option>
      </Selectize>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import Selectize from "vue2-selectize";

const httpService = progressBarService.axiosInstance;

export default {
  props: [
    "campaign",
    "skus",
    "index",
    "status",
    "action",
    "pullingId",
    "currentEditId",
  ],

  components: {
    Selectize,
  },

  data() {
    return {
      result: null,
      weekly: null,
      skuSelected: [],
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
      },
    };
  },

  computed: {
    disabled() {
      return ["archived", "end", "aborted"].includes(this.campaign.status);
    },
    currentId() {
      if (this.campaign.status == "draft") {
        return this.currentEditId;
      }

      if (this.status.active === 1) {
        const id = this.status.type.split(" ")[2];
        return +id;
      } else {
        return this.currentEditId;
      }
    },
    canEdit() {
      return (
        this.currentId == this.period.id &&
        this.action == "editing" &&
        this.status.active === 0 &&
        !["archived", "end", "aborted"].includes(this.campaign.status)
      );
    },
    canChangeProductCodes() {
      return (
        this.currentId == this.period.id &&
        this.action == "editing" &&
        this.status.active === 0 &&
        !["scheduled", "ongoing", "archived", "end", "aborted"].includes(
          this.campaign.status
        )
      );
    },
    skuType() {
      const { product_data_source, product_code_type } = this.weekly;
      return product_data_source == "gt" ? "gt" : product_code_type;
    },
    period() {
      return this.weekly.period[this.index];
    },
  },

  watch: {
    status(n) {
      const { id } = this.period;

      if (
        n.active === 1 &&
        n.type == `sku ${this.skuType} ${id}` &&
        n.state == "verify" &&
        this.pullingId === null
      ) {
        this.$emit("startPullingStatus");
        return;
      }

      if (
        n.active === 1 &&
        n.type == `sku ${this.skuType} ${id}` &&
        n.state == "execute" &&
        this.pullingId === null
      ) {
        this.$emit("startPullingStatus");
        return;
      }

      if (
        n.active === 1 &&
        n.type == `sku ${this.skuType} ${id}` &&
        n.state == "done-execute"
      ) {
        this.$emit("setAction", "ready");
        this.$emit("getUploadStatus");
        return;
      }

      if (n.active === 0) {
        this.$emit("setAction", "ready");
        this.$emit("stopPullingStatus");
        return;
      }
    },
    async "weekly.product_code_type"() {
      this.setSKUSelected();
    },
    async "weekly.product_data_source"() {
      this.setSKUSelected();
    },
  },

  methods: {
    handleClickEdit() {
      this.result = null;
      this.$emit("setAction", "editing", this.period.id);
    },
    async handleCancel() {
      if (this.status.active === 1) {
        await httpService.put(
          `${this.campaign.id}/weekly/period/${this.period.id}/try_sku_cancel/${this.skuType}`
        );
        this.$notify("Cancel success");
        this.$emit("getUploadStatus");
      }

      await this.$emit("updated");
      this.$emit("setAction", "ready");
      this.setSKUSelected();
    },

    async handleVerify() {
      const skus = this.period.sku[this.skuType];

      if (skus.length === 0 && this.skuSelected.length <= 0) {
        return;
      }

      if (this.campaign.status == "draft") {
        await this.update();
        this.$emit("setAction", "ready");
      } else {
        await this.tryUpdate();
        this.$emit("getUploadStatus");
      }

      this.weekly.period[this.index].sku[this.skuType] = this.skuSelected;
    },

    async update() {
      try {
        const form = { sku: this.skuSelected };

        await httpService.put(
          `${this.campaign.id}/weekly/period/${this.period.id}/sku/${this.skuType}`,
          form
        );

        this.$notify(`Target SKUs Updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async tryUpdate() {
      try {
        const form = { sku: this.skuSelected };

        const { data } = await httpService.put(
          `${this.campaign.id}/weekly/period/${this.period.id}/try_sku/${this.skuType}`,
          form
        );

        this.result = data;

        if (this.result.error) {
          this.cancelProcess();
          this.$dialogs.alert(this.result.error);
        }
      } catch (error) {
        console.error(error);
        this.$emit("getUploadStatus");
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async getResult() {
      try {
        const { data } = await httpService.put(
          `${this.campaign.id}/weekly/period/${this.period.id}/try_sku_result/${this.skuType}`
        );

        this.result = data;

        this.$emit("onCommit", this.result);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    add(sku) {
      return {
        value: sku,
        text: sku,
      };
    },

    setSKUSelected() {
      this.skuSelected = this.period.sku[this.skuType];
    },

    toggleSelectAll() {
      if (this.skuSelected.length < this.skus.length) {
        this.skuSelected = this.skus.map((o) =>
          this.weekly.product_data_source == "ws" ? o : o.PRO_CODE
        );
      } else {
        this.skuSelected = [];
      }
    },
  },

  async mounted() {
    this.weekly = this.campaign.weekly;

    this.setSKUSelected();
  },
};
</script>

<style lang="scss" scoped>
.wrapper-upload-form {
  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }
}

.sku-box-wrapper {
  padding: 1em;
  border-radius: 0.25rem;
  border: 1px solid #e4eaec;

  p {
    margin-bottom: 0px;
  }
}

.sku-box-action {
  min-height: 65px;
  margin-bottom: 0.5em;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

export const adminMenu = [
  {
    text: "General",
    category: true,
    class: "site-menu-category",
  },
  {
    text: "Admin User",
    hasSub: false,
    icon: "mdi mdi-account-tie mdi-24px",
    class: "site-menu-item",
    link: "/admin",
  },
  {
    text: "Product",
    hasSub: false,
    icon: "md-parking",
    class: "site-menu-item",
    link: "/product",
  },
  {
    text: "User Group",
    hasSub: false,
    icon: "md-accounts",
    class: "site-menu-item",
    link: "/usergroup",
  },
  {
    text: "Auction Lucky Draw",
    hasSub: true,
    icon: "md-card-giftcard",
    subs: [
      {
        text: "Lot",
        link: "/lot",
      },
      {
        text: "Winner",
        link: "/winner",
      },
      {
        text: "History",
        link: "/history",
      },
      {
        text: "Refund Log",
        link: "/refund-log",
      },
      {
        text: "Transaction Log",
        link: "/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Daily Login",
    hasSub: true,
    icon: "md-calendar-check",
    subs: [
      {
        text: "Campaign",
        link: "/campaign",
      },
      {
        text: "Result",
        link: "/result",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "World Game",
    hasSub: true,
    icon: "fa fa-gamepad",
    subs: [
      {
        text: "Game Instance",
        link: "/gamesinstance",
      },
      {
        text: "Engagement",
        link: "/gamesinstance/engagement",
      },
      {
        text: "Transaction Log",
        link: "/gamesinstance/transaction-log",
      },
      {
        text: "Advocacy Setting",
        link: "/advocacy",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Point Program",
    hasSub: true,
    icon: "md-star-circle",
    subs: [
      {
        text: "Point Campaign",
        link: "/point-program/point-campaign/list",
      },
      {
        text: "User Report",
        link: "/point-program/user-report",
      },
      {
        text: "Transaction Log",
        link: "/point-program/transaction-log",
      },
      {
        text: "Bulk Operation",
        link: "/point-program/bulk-operation",
      },
      {
        text: "Point Quota Limit",
        link: "/point-program/point-quota-limit",
      },
      {
        text: "Discount Report",
        link: "/point-program/discount-report",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "E-Coupon",
    hasSub: true,
    icon: "md-money-box",
    subs: [
      {
        text: "Campaign",
        link: "/e-coupon/campaign",
      },
      {
        text: "Transaction Log",
        link: "/e-coupon/transaction-log",
      },
      {
        text: "Access Setting",
        link: "/e-coupon/access-settings",
      },
      {
        text: "VSM Management",
        link: "/e-coupon/vsm-management",
      },
      {
        text: "VSM Users",
        link: "/e-coupon/vsm-user-management",
      },
      {
        text: "Status Report",
        link: "/e-coupon/status-report",
      },
      {
        text: "CCB2B Log",
        link: "/e-coupon/ccb2b-log",
      },
      {
        text: "Peddler Management",
        link: "/e-coupon/peddler-management",
      },
      {
        text: "Peddler Activity",
        link: "/e-coupon/peddler-activity",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Illicit Barcode",
    hasSub: true,
    icon: "mdi mdi-barcode-scan",
    subs: [
      {
        text: "Illicit Campaign",
        link: "/illicit-barcode/campaign",
      },
      {
        text: "Expiration Campaign",
        link: "/illicit-barcode/expiration-campaign",
      },
      {
        text: "Illicit Log",
        link: "/illicit-barcode/transaction-log",
      },
      {
        text: "Illicit List",
        link: "/illicit-barcode/list",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Lucky Login",
    hasSub: true,
    icon: "wb-star-outline",
    subs: [
      {
        text: "Campaign",
        link: "/lucky-login/campaigns",
      },
      {
        text: "Winners",
        link: "/lucky-login/winners",
      },
      {
        text: "Review",
        link: "/lucky-login/review",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Progress Bar",
    hasSub: true,
    icon: "fa fa-window-minimize",
    subs: [
      {
        text: "Campaign",
        link: "/progress-bar/campaigns",
      },
      {
        text: "Progress Update",
        link: "/progress-bar/update",
      },
      {
        text: "Transaction Log",
        link: "/progress-bar/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Game Leaderboard",
    hasSub: true,
    icon: "mdi mdi-podium-gold",
    subs: [
      {
        text: "Campaign",
        link: "/game-leaderboard/campaign",
      },
      {
        text: "Status",
        link: "/game-leaderboard/status",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Samurai",
    hasSub: true,
    icon: "mdi mdi-sword",
    subs: [
      {
        text: "Themes",
        link: "/game-samurai/themes",
      },
      {
        text: "Settings",
        link: "/game-samurai/settings",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Prize",
    hasSub: true,
    icon: "mdi mdi-trophy-outline",
    subs: [
      {
        text: "Prize Pool",
        link: "/prize/pool",
      },
      {
        text: "Pool Status",
        link: "/prize/pool/status",
      },
      {
        text: "Prize Theme",
        link: "/prize/theme",
      },
      {
        text: "Request Transaction",
        link: "/prize/request-transaction",
      },
      {
        text: "Prize Lot",
        link: "/prize/lot",
      },
      {
        text: "Status",
        link: "/prize/status",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Lucky Draw",
    hasSub: true,
    icon: "mdi mdi-numeric-9-circle-outline",
    subs: [
      {
        text: "Campaign",
        link: "/lucky-draw/campaigns",
      },
      {
        text: "Transaction Log",
        link: "/lucky-draw/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Weekly Login",
    hasSub: true,
    icon: "mdi mdi-calendar-week",
    subs: [
      {
        text: "Campaign",
        link: "/weekly-login/campaign",
      },
      {
        text: "Transaction Log",
        link: "/weekly-login/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "LIFF Access",
    hasSub: false,
    icon: "mdi mdi-door-open",
    class: "site-menu-item",
    link: "/liff-access/settings",
  },
  {
    text: "Budget Control",
    hasSub: true,
    icon: "mdi mdi-cash-lock-open",
    subs: [
      {
        text: "Budget Instance",
        link: "/budget-control/instance",
      },
      {
        text: "Budget Status",
        link: "/budget-control/status",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Staff Engagement",
    hasSub: true,
    icon: "mdi mdi-timer-check",
    subs: [
      {
        text: "Campaign",
        link: "/staff-engagement/campaign",
      },
      {
        text: "Participation",
        link: "/staff-engagement/participation",
      },
      {
        text: "Transaction Log",
        link: "/staff-engagement/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  }
];

export const specialAdminMenu = [
  {
    text: "Report",
    category: true,
    class: "site-menu-category",
  },
  {
    text: "Auction Lucky Draw",
    hasSub: true,
    icon: "md-card-giftcard",
    subs: [
      {
        text: "Winner",
        link: "/winner",
      },
      {
        text: "History",
        link: "/history",
      },
      {
        text: "Refund Log",
        link: "/refund-log",
      },
      {
        text: "Transaction Log",
        link: "/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Daily Login",
    hasSub: true,
    icon: "md-calendar-check",
    subs: [
      {
        text: "Result",
        link: "/result",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "World Game",
    hasSub: true,
    icon: "fa fa-gamepad",
    subs: [
      {
        text: "Transaction Log",
        link: "/gamesinstance/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Point Program",
    hasSub: true,
    icon: "md-star-circle",
    subs: [
      {
        text: "User Report",
        link: "/point-program/user-report",
      },
      {
        text: "Transaction Log",
        link: "/point-program/transaction-log",
      },
      {
        text: "Discount Report",
        link: "/point-program/discount-report",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "E-Coupon",
    hasSub: true,
    icon: "md-money-box",
    subs: [
      {
        text: "Transaction Log",
        link: "/e-coupon/transaction-log",
      },
      {
        text: "Status Report",
        link: "/e-coupon/status-report",
      },
      {
        text: "CCB2B Log",
        link: "/e-coupon/ccb2b-log",
      },
      {
        text: "Peddler Activity",
        link: "/e-coupon/peddler-activity",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Illicit Barcode",
    hasSub: true,
    icon: "mdi mdi-barcode-scan",
    subs: [
      {
        text: "Illicit Log",
        link: "/illicit-barcode/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Lucky Login",
    hasSub: true,
    icon: "wb-star-outline",
    subs: [
      {
        text: "Winners",
        link: "/lucky-login/winners",
      },
      {
        text: "Review",
        link: "/lucky-login/review",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Progress Bar",
    hasSub: true,
    icon: "fa fa-window-minimize",
    subs: [
      {
        text: "Progress Update",
        link: "/progress-bar/update",
      },
      {
        text: "Transaction Log",
        link: "/progress-bar/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Game Leaderboard",
    hasSub: true,
    icon: "mdi mdi-podium-gold",
    subs: [
      {
        text: "Status",
        link: "/game-leaderboard/status",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Prize",
    hasSub: true,
    icon: "mdi mdi-trophy-outline",
    subs: [
      {
        text: "Pool Status",
        link: "/prize/pool/status",
      },
      {
        text: "Request Transaction",
        link: "/prize/request-transaction",
      },
      {
        text: "Status",
        link: "/prize/status",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Lucky Draw",
    hasSub: true,
    icon: "mdi mdi-numeric-9-circle-outline",
    subs: [
      {
        text: "Transaction Log",
        link: "/lucky-draw/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Weekly Login",
    hasSub: true,
    icon: "mdi mdi-calendar-week",
    subs: [
      {
        text: "Transaction Log",
        link: "/weekly-login/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Budget Control",
    hasSub: true,
    icon: "mdi mdi-cash-lock-open",
    subs: [
      {
        text: "Budget Status",
        link: "/budget-control/status",
      },
    ],
    class: "site-menu-item has-sub",
  },
  {
    text: "Staff Engagement",
    hasSub: true,
    icon: "mdi mdi-timer-check",
    subs: [
      {
        text: "Participation",
        link: "/staff-engagement/participation",
      },
      {
        text: "Transaction Log",
        link: "/staff-engagement/transaction-log",
      },
    ],
    class: "site-menu-item has-sub",
  }
];

<template>
  <div class="col-md-10" v-if="campaign">
    <label class="mx-5">
      <h4>Ranks and Rewards</h4>
    </label>
    <div class="form-group">
      <div class="clearfix">
        <p class="float-left">
          <a :href="exampleLink"> Click to download example </a>
        </p>
        <p class="float-right">
          <span class="date mr-10"> Last upload: {{ lastUploadTime }} </span>
          <span class="no-ranks">
            No. Ranks:
            {{ campaign.leaderboard.reward_count | numberWithCommas }}
          </span>
        </p>
      </div>

      <div class="wrapper-upload-form">
        <label
          class="btn btn-round btn-upload mx-3"
          for="rank-reward-file-upload"
          :disabled="!canUpload"
        >
          Click to upload file
        </label>

        <input
          type="file"
          :disabled="!canUpload"
          @change="onFileSelected"
          class="input-select-file"
          id="rank-reward-file-upload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
    </div>
  </div>
</template>

<script>
const ENV = process.env.NODE_ENV;

import moment from "moment";
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    canUpload() {
      return !["archived", "scheduled", "end", "aborted"].includes(
        this.campaign.status
      );
    },
    lastUploadTime() {
      const time = moment(
        this.campaign.leaderboard.reward_time,
        "YYYY-MM-DD HH:mm:ss"
      );
      return time.isValid() ? time.format("DD/MM/YYYY HH:mm") : "-";
    },
    exampleLink() {
      const userType = this.campaign.user_type;

      const url =
        "https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/progressbar";
      switch (userType) {
        case "isms_code":
          return `${url}/leaderboard-reward-upload-new-dte-nondte.xlsx?dt=${new Date().getTime()}`;
        case "contact_id":
          return `${url}/leaderboard-reward-upload-new-dteonly.xlsx?dt=${new Date().getTime()}`;
      }
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      event.target.value = null;
      this.upload();
    },

    async upload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        await progressBarService.uploadRankAndReward(this.campaign.id, form);

        await this.wait(1000);

        this.$emit("updated");
        this.$notify("Upload Rank and Reward success");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Rank and Reward error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select-file {
  width: 0px;
  visibility: hidden;
}

.btn-upload {
  width: 100%;
  height: 100px;
  font-size: 1.5em;
  font-weight: 300;
  border-width: 0px;
  border-radius: 0.5em;
  background-color: #e5eaec;

  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled="disabled"] {
    color: #c1c1c1;
    cursor: not-allowed;
    background-color: #e5eaec90;
  }
}
</style>

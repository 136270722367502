var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group-row row" }, [
    _c("label", [_vm._v("Name")]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.reward.reward_name,
          expression: "reward.reward_name",
        },
      ],
      staticClass: "form-control",
      attrs: { type: "text", name: "reward_name", disabled: _vm.disabled },
      domProps: { value: _vm.reward.reward_name },
      on: {
        change: _vm.update,
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.reward, "reward_name", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import LuckyDraw from "@/views/luckydraw/Index.vue";
import LuckyDrawList from "@/views/luckydraw/List.vue";
import LuckyDrawEdit from "@/views/luckydraw/Edit.vue";
import TransactionLog from "@/views/luckydraw/transaction-log/List.vue";

export default {
  path: "/lucky-draw",
  name: "LuckyDraw",
  component: LuckyDraw,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "campaigns",
      name: "LuckyDrawList",
      component: LuckyDrawList,
      meta: {
        requiresAuth: true,
        roles: ["admin"]
      },
    },
    {
      path: "campaigns/edit/:id",
      name: "LuckyDrawEdit",
      component: LuckyDrawEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"]
      },
    },
    {
      path: "transaction-log",
      name: "LuckyDrawTransactionLog",
      component: TransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

<template>
  <div class="form-group-row row">
    <label>Details</label>
    <div class="flex-grow">
      <div class="preview" v-html="reward.reward_details"></div>
      <button
        @click="edit"
        type="button"
        class="btn btn-link"
        :disabled="disabled"
      >
        <i class="icon md-edit"></i>
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";
import RewardDetailEditor from "./RewardDetailEditor.vue";

export default {
  mixins: [rewardMixins],

  components: { RewardDetailEditor },

  data() {
    return {
      message: {
        error: `Update detail of Reward #${this.reward.id} error.`,
        success: `Update detail of Reward #${this.reward.id} success.`,
      },
    };
  },

  methods: {
    edit() {
      this.$modal.show(
        RewardDetailEditor,
        {
          name: "reward-detail-modal",
          detail: this.reward.reward_details,
          confirm: this.save,
        },
        {
          width: 600,
          height: "auto",
          clickToClose: false,
          name: "reward-detail-modal",
        },
        {
          "before-close": this.save,
        }
      );
    },

    save({ params }) {
      if (params && params.value) {
        this.reward.reward_details = params.value;
        this.update(null, "reward_details", this.reward.reward_details);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  padding: 1rem;
  overflow-y: auto;
  height: 150px;
  max-height: 150px;
  border-radius: 0.215rem;
  border: 1px solid #e4eaec;
}

.flex-grow {
  flex: 1 0 auto;
  max-width: 300px;
}
</style>

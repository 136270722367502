import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import Login from "@/views/auth/Login.vue";
import Logout from "@/views/auth/Logout.vue";
import productRouter from "@/views/product/router.js";
import adminRouter from "@/views/admin/router.js";
import userGroupRouter from "@/views/usergroup/router.js";
import lotRouter from "@/views/lot/router.js";
import historyRouter from "@/views/history/router.js";
import winnerRouter from "@/views/winner/router.js";
import campaignRouter from "@/views/campaign/router.js";
import refundLogRouter from "@/views/refund_log/router.js";
import transactionLogRouter from "@/views/transaction/router.js";
import resultRouter from "@/views/result/router.js";
import gameRouter from "@/views/game/router.js";
import pointRouter from "@/views/point/router.js";
import advocacyRouter from "@/views/advocacy/router.js";
import luckyloginRouter from "@/views/luckylogin/router.js";
import progressbarRouter from "@/views/progressbar/router.js";
import ecouponRouter from "@/views/ecoupon/router.js";
import leaderboardRouter from "@/views/leaderboard/router.js";
import prizeSummaryRouter from "@/views/prize/router.js";
import luckydrawRouter from "@/views/luckydraw/router.js";
import illicitbarcodeRouter from "@/views/illicit-barcode/router.js";
import weeklyloginRouter from "@/views/weeklylogin/router.js";
import liffAccessRouter from "@/views/liff_access/router.js";
import samuraiRouter from "@/views/samurai/router.js";
import budgetControlRouter from "@/views/budget-control/router.js";
import staffEngagementRouter from "@/views/staff-engagement/router.js";

import NotFound from "@/views/error/404.vue";

import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      beforeEnter: (to, from, next) => {
        if (!store.state.auth.role) {
          return next("/login");
        }

        const basePath = store.getters[`auth/basePath`];
        next(basePath);
      },
    },
    adminRouter,
    productRouter,
    refundLogRouter,
    transactionLogRouter,
    userGroupRouter,
    campaignRouter,
    resultRouter,
    lotRouter,
    historyRouter,
    winnerRouter,
    gameRouter,
    pointRouter,
    advocacyRouter,
    luckyloginRouter,
    progressbarRouter,
    ecouponRouter,
    leaderboardRouter,
    prizeSummaryRouter,
    luckydrawRouter,
    illicitbarcodeRouter,
    weeklyloginRouter,
    liffAccessRouter,
    samuraiRouter,
    budgetControlRouter,
    staffEngagementRouter,
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },
    { path: "*", component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = store.getters["auth/isLoggedIn"];
    if (isLoggedIn) {
      // Check if user has the required role
      if (to.matched.some((record) => record.meta.roles)) {
        const role = store.state.auth.role;
        const roles = to.matched.map((record) => record.meta.roles).flat();

        if (!roles.includes(role)) {
          next("/404");
          return;
        }
      }

      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  // setTimeout(NProgress.done, 800);
  NProgress.done();
});

export default router;

import qs from "qs";
import HttpRequest from "./request";

export class BudgetProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api`);
  }

  async getBudgetList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/budget${queryString}`);
    return data;
  }

  async createNewBudget(body) {
    const { data } = await this.create(`/budget`, body);
    return data;
  }

  async getBudgetById(id) {
    const { data } = await this.get(`/budget/${id}`);
    return data;
  }

  async updateBudget(id, body) {
    const { data } = await this.update(`/budget/${id}`, body);
    return data;
  }

  async getBudgetApplication() {
    const { data } = await this.get(`/budget/application`);
    return data;
  }

  async getTargetCampaign(id) {
    const { data } = await this.get(`/budget/${id}/campaign`);
    return data;
  }

  async getTargetCampaignById(id, targetId) {
    const { data } = await this.get(`/budget/${id}/campaign/${targetId}`);
    return data;
  }

  async createTargetCampaign(id) {
    const { data } = await this.create(`/budget/${id}/campaign`);
    return data;
  }

  async updateTargetCampaign(id, targetId, body) {
    const { data } = await this.update(
      `/budget/${id}/campaign/${targetId}`,
      body
    );
    return data;
  }

  async deleteTargetCampaign(id, targetId) {
    const { data } = await this.delete(`/budget/${id}/campaign/${targetId}`);
    return data;
  }

  async getBudgetStatus(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/budget/status${queryString}`);
    return data;
  }

  async getBudgetStatusTable(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/budget/status/${id}/table${queryString}`);
    return data;
  }

  async getBudgetStatusInfo(id) {
    const { data } = await this.get(`/budget/status/${id}/info`);
    return data;
  }

  async downloadBudgetStatusTable(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return await this.axiosInstance.get(
      `/budget/status/${id}/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }
}

import {
  required,
  minValue,
  maxValue,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export const FormCreateValidateRule = {
  name: {
    required,
    minLength: minLength(0),
    maxLength: maxLength(255),
  },
  start_date: {
    required,
  },
  end_date: {
    required,
  },
  conv_point: {
    required,
    minValue: minValue(1),
    maxValue: maxValue(999),
  },
  conv_coin: {
    required,
    minValue: minValue(1),
    maxValue: maxValue(999),
  },
  discount_coin: {
    required,
    minValue: minValue(1),
    maxValue: maxValue(999),
  },
  discount_baht: {
    required,
    minValue: minValue(1),
    maxValue: maxValue(999),
  },
  markerup_percent: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
};

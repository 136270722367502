import ECouponIndex from "@/views/ecoupon/Index.vue";

import CampaignList from "@/views/ecoupon/campaign/List.vue";
import CampaignEdit from "@/views/ecoupon/campaign/Edit.vue";

import CCB2BLog from "@/views/ecoupon/ccb2b-log/List.vue";
import CCB2BLogDetail from "@/views/ecoupon/ccb2b-log/Detail.vue";
import TransactionLog from "@/views/ecoupon/transaction-log/List.vue";
import AccessSetting from "@/views/ecoupon/access-settings/Index.vue";
import VSMManagement from "@/views/ecoupon/vsm-management/Index.vue";
import VSMUserManagement from "@/views/ecoupon/vsm-user-management/Index.vue";
import VSMUserManagementInfo from "@/views/ecoupon/vsm-user-management/Detail.vue";
import StatusReport from "@/views/ecoupon/status-report/Index.vue";
import PeddlerManagement from "@/views/ecoupon/peddler-management/Index.vue";
import PeddlerInformation from "@/views/ecoupon/peddler-management/Detail.vue";
import PeddlerActivity from "@/views/ecoupon/peddler-activity/List.vue";

export default {
  path: "/e-coupon",
  name: "ECouponIndex",
  component: ECouponIndex,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaign",
      name: "e-coupon-campaigns",
      component: CampaignList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "campaign/edit/:id",
      name: "e-coupon-campaign-edit",
      component: CampaignEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "transaction-log",
      name: "e-coupon-transaction-log",
      component: TransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "access-settings",
      name: "e-coupon-access-settings",
      component: AccessSetting,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "vsm-management",
      name: "e-coupon-vsm-management",
      component: VSMManagement,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "vsm-user-management",
      name: "e-coupon-vsm-user-management",
      component: VSMUserManagement,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "vsm-user-management/:id",
      name: "e-coupon-vsm-user-management-info",
      component: VSMUserManagementInfo,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "status-report",
      name: "e-coupon-status-report",
      component: StatusReport,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "ccb2b-log",
      name: "e-coupon-ccb2b-log",
      component: CCB2BLog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "ccb2b-log-detail/:id",
      name: "e-coupon-ccb2b-log-detail",
      component: CCB2BLogDetail,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "peddler-management",
      name: "e-coupon-peddler-management",
      component: PeddlerManagement,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "peddler-management/:id",
      name: "e-coupon-peddler-info",
      component: PeddlerInformation,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "peddler-activity",
      name: "e-coupon-peddler-activity",
      component: PeddlerActivity,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Game Leaderboard</h1>
      <breadcrumb name="GAME_LEADER_BOARD_CAMPAIGN_STATUS"></breadcrumb>
      <div class="page-header-left">
        <div class="campaign-select">
          <h4>Campaign Name</h4>
          <select
            ref="select"
            v-model="selected"
            data-width="300px"
            class="selectpicker"
            data-live-search="true"
            data-none-selected-text="กรุณาเลือก campaign"
          >
            <option v-for="{ id, name } in campaigns" :key="id" :value="id">
              {{ id }} - {{ name }}
            </option>
          </select>
        </div>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel" v-if="campaignInfo">
          <div class="panel-body">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th>Campaign Id</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Status</th>
                  <th>Total SF Point Granted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ campaignInfo.id }}</td>
                  <td>{{ campaignInfo.start_date | dateTimeMedium }}</td>
                  <td>{{ campaignInfo.end_date | dateTimeMedium }}</td>
                  <td>{{ campaignInfo.status | toUpperCase }}</td>
                  <td>{{ campaignInfo.point_granted | numberWithCommas }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span>
                    {{ props.row.status | toUpperCase }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTableMixins from "@/mixins/vue-good-table";

import { leaderBoardService } from "@/api/client";
import { tableColumns } from "@/views/leaderboard/status/model";

export default {
  name: "LeaderboardStatus",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      columns: tableColumns,

      campaigns: [],
      selected: null,
      campaignInfo: null,
    };
  },

  watch: {
    selected() {
      this.onSelectedCampaign();
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.getAllCampaign();
    this.initialTableSearchState();
  },

  updated() {
    $(this.$refs.select).selectpicker("refresh");
  },

  methods: {
    async getAllCampaign() {
      this.campaigns = await leaderBoardService.fetchGameLeaderboardStatus();
    },

    async getCampaignInfo() {
      this.campaignInfo = await leaderBoardService.fetchGameLeaderboardInfo(
        this.selected
      );
    },

    getParams() {
      let params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    async fetch() {
      if (!this.selected) return;

      const id = this.selected;
      const params = this.getParams();
      const {
        data,
        total_records,
      } = await leaderBoardService.fetchGameLeaderboardTable(id, params);

      this.list = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      if (!this.selected) return;

      try {
        const id = this.selected;
        const response = await leaderBoardService.downloadGameLeaderboard(id);

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `game_leaderboard_status_${id}_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    resetPage() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: 1,
        }),
      });
    },

    async onSelectedCampaign() {
      if (!this.selected) return;
      this.getCampaignInfo();
      this.resetPage();
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}

.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}

.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}

.page-content {
  padding-left: 30px;
  padding-right: 30px;

  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.table {
  tr {
    th,
    td {
      font-size: 1.25em;
    }
  }

  thead {
    tr th {
      font-weight: 600;
      padding-top: 0px;
      padding-bottom: 0px;

      &:first-child {
        width: 220px;
        text-align: right;
        padding-left: 0px;
        padding-right: 50px;
      }
    }
  }

  tbody {
    tr td {
      font-weight: 400;
      padding-top: 0px;
      padding-bottom: 0px;

      &:first-child {
        width: 220px;
        text-align: right;
        padding-left: 0px;
        padding-right: 50px;
      }
    }

    tr {
      &:hover {
        cursor: default;
        background-color: #fff;

        td {
          background-color: #fff;
        }
      }

      &:last-child td {
        border-bottom: none;
      }
    }
  }
}
</style>

<style lang="scss">
.campaign-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 2em;

  h4 {
    margin: 0px;
    margin-right: 1em;
  }

  .dropdown {
    border-radius: 6px;
    border: 1px solid #ccc;

    > button {
      border-radius: 6px;
    }
  }
}
</style>

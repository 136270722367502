import qs from "qs";
import HttpRequest from "./request";

export class RefundLogProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/refund_log`);
  }
  async getLogDate(from = this.lastmonth(), to = this.today()) {
    const { data } = await this.get(`?from=${from}&to=${to}`);
    return data;
  }

  async download(from, to) {
    return await this.axiosInstance.get(
      `${process.env.VUE_APP_SERVICE_URL}/ams/api/refund_log/download?from=${from}&to=${to}`,
      {
        responseType: "blob",
      }
    );
  }

  lastmonth() {
    var today = new Date();
    var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 7)
      .toJSON()
      .slice(0, 10);
    return lastmonth;
  }

  today() {
    var today = new Date().toJSON().slice(0, 10);
    return today;
  }

  async getLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createLog(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editLog(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }
}

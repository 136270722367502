<template>
  <footer class="site-footer">
    <div class="site-footer-legal">
      © 2020
      <a href="https://www.pmi.com/markets/thailand/th">PMI (TH)</a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.site-footer {
  background-color: rgb(37, 37, 37);
}
</style>

import qs from "qs";
import HttpRequest from "./request";

export class UserGroupProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/group`);
  }

  async getUserGroups(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createUserGroup(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editUserGroup(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async getLUMUserGroup() {
    const { data } = await this.get(`/lum`);
    return data;
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c(
        "div",
        [
          _vm._m(0),
          _c("div", { staticClass: "ml-2 form-row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _vm.uploadStatus == "initial"
                ? _c("div", { staticClass: "wrapper-upload-form" }, [_vm._m(1)])
                : !_vm.uploadStatus ||
                  _vm.uploadStatus == "done" ||
                  _vm.uploadStatus == "ready"
                ? _c("div", { staticClass: "wrapper-upload-form d-flex" }, [
                    _vm.canBulkUpload
                      ? _c("div", { staticClass: "upload-button-label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-upload mx-3",
                              attrs: {
                                disabled: !_vm.canBulkUpload,
                                for: _vm.canBulkUpload ? "Bulk-upload" : null,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-upload",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Bulk upload "),
                            ]
                          ),
                          _c("input", {
                            staticClass: "input-select-file",
                            attrs: {
                              type: "file",
                              disabled: !_vm.canBulkUpload,
                              id: "Bulk-upload",
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            },
                            on: { change: _vm.FileBulkUpload },
                          }),
                        ])
                      : _vm.canBulkAdd
                      ? _c("div", { staticClass: "upload-button-label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-upload mx-3",
                              attrs: {
                                disabled: !_vm.canBulkAdd,
                                for: _vm.canBulkAdd ? "Bulk-add" : null,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Bulk Add "),
                            ]
                          ),
                          _c("input", {
                            staticClass: "input-select-file",
                            attrs: {
                              type: "file",
                              disabled: !_vm.canBulkAdd,
                              id: "Bulk-add",
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            },
                            on: { change: _vm.FileBulkAdd },
                          }),
                        ])
                      : _vm._e(),
                    _vm.uploadStatus == "ready"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mx-5",
                            attrs: { type: "button" },
                            on: { click: _vm.showConfirmPopup },
                          },
                          [_vm._v(" Confirm ")]
                        )
                      : _vm._e(),
                  ])
                : _vm.uploadStatus == "loading"
                ? _c("div", { staticClass: "wrapper-upload-form" }, [
                    _c("div", { staticClass: "upload-button-label" }, [
                      !_vm.canBulkAdd
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-upload mx-3",
                              attrs: { type: "button", disabled: "disabled" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-upload",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Bulk upload "),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-upload mx-3",
                              attrs: { type: "button", disabled: "disabled" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Bulk Add "),
                            ]
                          ),
                      _c("label", { staticClass: "text-link" }, [
                        _vm._v("Verifying..."),
                      ]),
                    ]),
                  ])
                : _vm.uploadStatus == "running"
                ? _c("div", { staticClass: "wrapper-upload-form" }, [
                    _c("div", { staticClass: "upload-button-label" }, [
                      !_vm.canBulkAdd
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-upload mx-3",
                              attrs: { type: "button", disabled: "disabled" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-upload",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Bulk upload "),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-upload mx-3",
                              attrs: { type: "button", disabled: "disabled" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Bulk Add "),
                            ]
                          ),
                      _c("label", { staticClass: "text-link" }, [
                        _vm._v("Executing... "),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.downloadTemplate },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-excel-o",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Click to download template "),
                ]
              ),
            ]),
            _c("div", { staticClass: "form-group col-md-4" }),
          ]),
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _c("strong", [
              _vm._v("Last update: " + _vm._s(_vm.campaign.modified_time)),
            ]),
          ]),
          _c("div", { staticClass: "form-row ml-4 mt-2" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _vm._v(
                " No. Coupons: " +
                  _vm._s(
                    _vm._f("numberWithCommas")(_vm.campaign.total_coupon)
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _vm._v(
                " Total Face Value: " +
                  _vm._s(
                    _vm._f("numberWithCommas")(_vm.campaign.total_face_value)
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _vm._v(
                " Total Top Up Value:" +
                  _vm._s(
                    _vm._f("numberWithCommas")(_vm.campaign.total_topup_value)
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "modal",
            {
              attrs: {
                width: 600,
                height: "auto",
                clickToClose: false,
                name: "upload-confrim-modal",
                draggable: ".panel-heading",
              },
            },
            [
              _c("UploadConfirmModal", {
                attrs: {
                  message: _vm.confirmMessage,
                  name: "upload-confrim-modal",
                  title: "ยืนยันการอัพโหลดไฟล์หรือไม่?",
                },
                on: { confirm: _vm.confirmUpload },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Coupon Information")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-button-label" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-upload mx-3",
          attrs: { type: "button", disabled: "disabled" },
        },
        [
          _c("i", {
            staticClass: "fa fa-upload",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" Bulk upload "),
        ]
      ),
      _c("label", { staticClass: "text-grey mx-5" }, [_vm._v("Loading...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="wrap">
    <!-- toggle show / hide -->
    <label for="display" class="my-10 d-none">
      <toggle-button
        id="display"
        :value="false"
        color="#3e8ef7"
        :sync="true"
        :labels="true"
        v-model="display"
      />
      <span class="mx-10">{{ display ? "ซ่อน" : "แสดง" }} validate</span>
    </label>

    <ul class="list-icons mb-0" v-if="display">
      <!-- maxLength -->
      <li>
        <i
          class="wb-close"
          aria-hidden="true"
          v-if="match"
        ></i>
        <i class="wb-check" aria-hidden="true" v-else></i>
        User Groups ที่มี duration overlap : {{ group }}
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  props: ["group"],
  data() {
    return {
      match: true,
      display: true,
    };
  },
};
</script>
<style lang="scss" scoped>
div.wrap {
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #f3f3f3;
}
</style>

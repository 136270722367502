var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "dropdown-item",
      attrs: { role: "menuitem", href: "javascript:void(0)" },
      on: {
        click: function ($event) {
          return _vm.$emit("onSelect")
        },
      },
    },
    [
      _c("i", {
        staticClass: "icon",
        class: _vm.icon,
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(" " + _vm._s(_vm.text) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "d-flex align-items-center gap-3 mb-10" }, [
          _vm._m(0),
          _c("label", { staticClass: "switch" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.period_prize,
                  expression: "period_prize",
                },
              ],
              attrs: {
                type: "checkbox",
                "true-value": 1,
                "false-value": 0,
                disabled: !_vm.canEdit,
              },
              domProps: {
                checked: Array.isArray(_vm.period_prize)
                  ? _vm._i(_vm.period_prize, null) > -1
                  : _vm._q(_vm.period_prize, 1),
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.period_prize,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.period_prize = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.period_prize = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.period_prize = $$c
                    }
                  },
                  _vm.togglePeriodPrize,
                ],
              },
            }),
            _c("span", { staticClass: "slider round" }),
          ]),
          _c("p", { staticClass: "mx-10 mb-5 d-inline" }, [
            _vm._v(" " + _vm._s(+_vm.period_prize === 1 ? "ON" : "OFF") + " "),
          ]),
          _c("div", { staticClass: "d-flex align-items-center" }),
        ]),
        +_vm.period_prize === 1
          ? _c(
              "div",
              { staticClass: "period-prize-items" },
              _vm._l(_vm.campaign.period_list, function (prize, index) {
                return _c("div", { key: prize.id, staticClass: "ml-20" }, [
                  _c(
                    "table",
                    { staticClass: "table table-responsive table-borderless" },
                    [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("No. " + _vm._s(index + 1))]),
                          _c("td", [_vm._v("Name")]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: prize.prize_name,
                                  expression: "prize.prize_name",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                maxlength: "40",
                                disabled: !_vm.canEdit,
                              },
                              domProps: { value: prize.prize_name },
                              on: {
                                change: function ($event) {
                                  return _vm.update("prize_name", prize)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    prize,
                                    "prize_name",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column gap-5 px-20" },
                              [
                                _c("div", { staticClass: "reward-choice" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: prize.prize_type,
                                        expression: "prize.prize_type",
                                      },
                                    ],
                                    attrs: {
                                      value: "other",
                                      type: "radio",
                                      disabled: !_vm.canEdit,
                                      name: "reward-choice-" + prize.id,
                                      id: "reward-choice-physical-" + prize.id,
                                    },
                                    domProps: {
                                      checked: prize.prize_type === "other",
                                      checked: _vm._q(
                                        prize.prize_type,
                                        "other"
                                      ),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          return _vm.$set(
                                            prize,
                                            "prize_type",
                                            "other"
                                          )
                                        },
                                        function ($event) {
                                          return _vm.update("prize_type", prize)
                                        },
                                      ],
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label",
                                      attrs: {
                                        for:
                                          "reward-choice-physical-" + prize.id,
                                      },
                                    },
                                    [_vm._v(" Physical ")]
                                  ),
                                ]),
                                _c("div", { staticClass: "reward-choice" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: prize.prize_type,
                                        expression: "prize.prize_type",
                                      },
                                    ],
                                    attrs: {
                                      value: "sfdc",
                                      type: "radio",
                                      disabled: !_vm.canEdit,
                                      name: "reward-choice-" + prize.id,
                                      id: "reward-choice-sf-point-" + prize.id,
                                    },
                                    domProps: {
                                      checked: prize.prize_type === "sfdc",
                                      checked: _vm._q(prize.prize_type, "sfdc"),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          return _vm.$set(
                                            prize,
                                            "prize_type",
                                            "sfdc"
                                          )
                                        },
                                        function ($event) {
                                          return _vm.update("prize_type", prize)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-label",
                                        attrs: {
                                          for:
                                            "reward-choice-sf-point-" +
                                            prize.id,
                                        },
                                      },
                                      [_vm._v(" SF Point ")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: prize.prize_points,
                                          expression: "prize.prize_points",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        min: 1,
                                        max: "1000000",
                                        type: "number",
                                        disabled:
                                          !_vm.canEdit ||
                                          prize.prize_type !== "sfdc",
                                      },
                                      domProps: { value: prize.prize_points },
                                      on: {
                                        change: function ($event) {
                                          return _vm.update(
                                            "prize_points",
                                            prize
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            prize,
                                            "prize_points",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "reward-choice flex-align-top",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: prize.prize_type,
                                          expression: "prize.prize_type",
                                        },
                                      ],
                                      attrs: {
                                        value: "ecoupon",
                                        type: "radio",
                                        disabled: !_vm.canEdit,
                                        name: "reward-choice-" + prize.id,
                                        id:
                                          "reward-choice-e-coupon-" + prize.id,
                                      },
                                      domProps: {
                                        checked: prize.prize_type === "ecoupon",
                                        checked: _vm._q(
                                          prize.prize_type,
                                          "ecoupon"
                                        ),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            return _vm.$set(
                                              prize,
                                              "prize_type",
                                              "ecoupon"
                                            )
                                          },
                                          function ($event) {
                                            return _vm.update(
                                              "prize_type",
                                              prize
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-align-top mb-10",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "input-label",
                                              attrs: {
                                                for:
                                                  "reward-choice-e-coupon-" +
                                                  prize.id,
                                              },
                                            },
                                            [_vm._v(" E-Coupon ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "face-value-form" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-label",
                                                      attrs: {
                                                        for:
                                                          "reward-choice-face-value-" +
                                                          prize.id,
                                                      },
                                                    },
                                                    [_vm._v(" Face Value ")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName:
                                                          "v-model.number",
                                                        value:
                                                          prize.prize_face_value,
                                                        expression:
                                                          "prize.prize_face_value",
                                                        modifiers: {
                                                          number: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      min: "1",
                                                      max: "999999",
                                                      type: "number",
                                                      disabled:
                                                        !_vm.canEdit ||
                                                        prize.prize_type !==
                                                          "ecoupon",
                                                      id:
                                                        "reward-choice-face-value-" +
                                                        prize.id,
                                                    },
                                                    domProps: {
                                                      value:
                                                        prize.prize_face_value,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.update(
                                                          "prize_face_value",
                                                          prize
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          prize,
                                                          "prize_face_value",
                                                          _vm._n(
                                                            $event.target.value
                                                          )
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("bht")]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-label",
                                                      attrs: {
                                                        for:
                                                          "reward-choice-topup-" +
                                                          prize.id,
                                                      },
                                                    },
                                                    [_vm._v(" Top Up ")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName:
                                                          "v-model.number",
                                                        value:
                                                          prize.prize_topup,
                                                        expression:
                                                          "prize.prize_topup",
                                                        modifiers: {
                                                          number: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      min: "0",
                                                      max: "999999",
                                                      type: "number",
                                                      disabled:
                                                        !_vm.canEdit ||
                                                        prize.prize_type !==
                                                          "ecoupon",
                                                      id:
                                                        "reward-choice-topup-" +
                                                        prize.id,
                                                    },
                                                    domProps: {
                                                      value: prize.prize_topup,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.update(
                                                          "prize_topup",
                                                          prize
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          prize,
                                                          "prize_topup",
                                                          _vm._n(
                                                            $event.target.value
                                                          )
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("bht")]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flex-align-top",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "input-label line-high-1",
                                              attrs: {
                                                for:
                                                  "reward-choice-custom-text-" +
                                                  prize.id,
                                              },
                                            },
                                            [
                                              _vm._v(" Custom text"),
                                              _c("br"),
                                              _vm._v("on coupon "),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: prize.prize_custom_text,
                                                expression:
                                                  "prize.prize_custom_text",
                                              },
                                            ],
                                            staticClass: "form-control w250",
                                            attrs: {
                                              type: "text",
                                              maxlength: "40",
                                              disabled:
                                                !_vm.canEdit ||
                                                prize.prize_type !== "ecoupon",
                                              id:
                                                "reward-choice-custom-text-" +
                                                prize.id,
                                            },
                                            domProps: {
                                              value: prize.prize_custom_text,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.update(
                                                  "prize_custom_text",
                                                  prize
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  prize,
                                                  "prize_custom_text",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "reward-choice" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: prize.prize_type,
                                        expression: "prize.prize_type",
                                      },
                                    ],
                                    attrs: {
                                      value: "prizepool",
                                      type: "radio",
                                      disabled: !_vm.canEdit,
                                      name: "reward-choice-" + prize.id,
                                      id: "reward-choice-prizepool-" + prize.id,
                                    },
                                    domProps: {
                                      checked: prize.prize_type === "prizepool",
                                      checked: _vm._q(
                                        prize.prize_type,
                                        "prizepool"
                                      ),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          return _vm.$set(
                                            prize,
                                            "prize_type",
                                            "prizepool"
                                          )
                                        },
                                        function ($event) {
                                          return _vm.update("prize_type", prize)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-label",
                                        attrs: {
                                          for:
                                            "reward-choice-prizepool-" +
                                            prize.id,
                                        },
                                      },
                                      [_vm._v(" Prize Pool ")]
                                    ),
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "prize_theme_id",
                                          attrs: {
                                            for: "prize_theme_id_" + prize.id,
                                          },
                                        },
                                        [_vm._v(" Prize Theme ID ")]
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: prize.prize_theme_id,
                                              expression:
                                                "prize.prize_theme_id",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "prize_theme_id",
                                            id: "prize_theme_id_" + prize.id,
                                            disabled:
                                              !_vm.canEdit ||
                                              prize.prize_type !== "prizepool",
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return _vm._n(val)
                                                    })
                                                _vm.$set(
                                                  prize,
                                                  "prize_theme_id",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.update(
                                                  "prize_theme_id",
                                                  prize
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: { disabled: "" },
                                              domProps: { value: null },
                                            },
                                            [_vm._v(" Not Select ")]
                                          ),
                                          _vm._l(
                                            _vm.prizepool,
                                            function (prize) {
                                              return _c(
                                                "option",
                                                {
                                                  key: prize.id,
                                                  domProps: { value: prize.id },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(prize.id) +
                                                      " - " +
                                                      _vm._s(prize.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td"),
                          _c("td", [_vm._v("Details")]),
                          _c("td", [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: prize.prize_details,
                                  expression: "prize.prize_details",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { rows: "5", disabled: !_vm.canEdit },
                              domProps: { value: prize.prize_details },
                              on: {
                                change: function ($event) {
                                  return _vm.update("prize_details", prize)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    prize,
                                    "prize_details",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td"),
                          _c("td", [_vm._v("Picture")]),
                          _c(
                            "td",
                            [
                              _c("PrizePicture", {
                                attrs: { prize: prize, campaign: _vm.campaign },
                                on: {
                                  updated: function ($event) {
                                    return _vm.$emit("updated")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("hr"),
                ])
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label px-0" }, [
      _c("h5", [_vm._v("Period Prize")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        { staticClass: "page vld-parent" },
        [
          _c("loading", {
            attrs: { active: _vm.isLoading, "is-full-page": false },
          }),
          _c("div", { staticClass: "page-main" }, [
            _c("div", { staticClass: "page-header" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                },
                [
                  _c("h1", { staticClass: "page-title" }, [
                    _vm._v("VSM Users"),
                  ]),
                  _c("breadcrumb", {
                    attrs: {
                      name: "VSM_USER_MANAGEMENT_DETAIL",
                      withLast: _vm.user.username,
                    },
                    on: {
                      "update:withLast": function ($event) {
                        return _vm.$set(_vm.user, "username", $event)
                      },
                      "update:with-last": function ($event) {
                        return _vm.$set(_vm.user, "username", $event)
                      },
                    },
                  }),
                  _c("div", { staticClass: "page-title page-header-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-round btn-default mx-10",
                        attrs: { type: "button" },
                        on: { click: _vm.goBack },
                      },
                      [
                        _c("i", {
                          staticClass: "icon md-chevron-left",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" Back "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "page-content container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                  },
                  [
                    _c("div", { staticClass: "panel panel-bordered" }, [
                      _c("div", { staticClass: "panel-heading" }, [
                        _c("h3", { staticClass: "panel-title" }, [
                          _vm._v("VSM Users - " + _vm._s(_vm.user.vsm_id)),
                        ]),
                        _c("div", {
                          staticClass: "panel-actions panel-actions-keep",
                        }),
                      ]),
                      _c("div", { staticClass: "panel-body" }, [
                        _vm.user
                          ? _c("div", { staticClass: "user-form-horizontal" }, [
                              _c("div", { staticClass: "row" }, [
                                _vm._m(0),
                                _c("div", { staticClass: "col-5" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.user.username,
                                        expression: "user.username",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "username",
                                      autocomplete: "false",
                                      id: "paddler-login-input",
                                    },
                                    domProps: { value: _vm.user.username },
                                    on: {
                                      change: _vm.update,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.user,
                                          "username",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("p", { staticClass: "my-5 px-3 hint" }, [
                                    _vm._v(
                                      " 1-255 characters, a-z, A-Z, 0-9, dot, dash, underscore. "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "d-flex justify-content-end",
                                    },
                                    [
                                      _vm._v(
                                        " Last Login " +
                                          _vm._s(_vm.user.last_login || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _vm._m(1),
                                _c("div", { staticClass: "col-5" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.user.firstname,
                                        expression: "user.firstname",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "firstname",
                                      id: "user-firstname-input",
                                      autocomplete: "false",
                                    },
                                    domProps: { value: _vm.user.firstname },
                                    on: {
                                      change: _vm.update,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.user,
                                          "firstname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _vm._m(2),
                                _c("div", { staticClass: "col-5" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.user.lastname,
                                        expression: "user.lastname",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "lastname",
                                      id: "user-lastname-input",
                                      autocomplete: "false",
                                    },
                                    domProps: { value: _vm.user.lastname },
                                    on: {
                                      change: _vm.update,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.user,
                                          "lastname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _vm._m(3),
                                _c("div", { staticClass: "col-5" }, [
                                  _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "employee_role",
                                      id: "user-role-input",
                                      autocomplete: "false",
                                    },
                                    domProps: {
                                      value:
                                        _vm.user.employee_role.toUpperCase(),
                                    },
                                    on: {
                                      change: _vm.update,
                                      input: function ($event) {
                                        _vm.user.employee_role =
                                          $event.target.value.toUpperCase()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _vm._m(4),
                                _c("div", { staticClass: "col-5" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.user.status,
                                          expression: "user.status",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        name: "status",
                                        id: "paddler-status-input",
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return _vm._n(val)
                                                })
                                            _vm.$set(
                                              _vm.user,
                                              "status",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.update,
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "1" } }, [
                                        _vm._v("ACTIVE"),
                                      ]),
                                      _c("option", { attrs: { value: "0" } }, [
                                        _vm._v("INACTIVE"),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-3" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "paddler-password-input" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.enable_edit_password
                                              ? "New password"
                                              : "Password"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm.enable_edit_password
                                  ? _c("div", { staticClass: "col-5" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-update-password" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.password,
                                                expression: "user.password",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "password",
                                              id: "paddler-password-input",
                                              autocomplete: "false",
                                            },
                                            domProps: {
                                              value: _vm.user.password,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "password",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-round",
                                              attrs: { type: "button" },
                                              on: { click: _vm.updatePassword },
                                            },
                                            [_vm._v(" Commit ")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-defalut btn-round",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.enable_edit_password = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "auto-generate-password-link",
                                          on: { click: _vm.generatePassword },
                                        },
                                        [_vm._v(" Auto Generate Password ")]
                                      ),
                                    ])
                                  : _c("div", { staticClass: "col-5" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-update-password" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.user.password)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-round",
                                              on: {
                                                click: function ($event) {
                                                  _vm.enable_edit_password = true
                                                },
                                              },
                                            },
                                            [_vm._v(" Change ")]
                                          ),
                                        ]
                                      ),
                                    ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._m(5),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("label", { attrs: { for: "paddler-login-input" } }, [
        _vm._v("Username"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("label", { attrs: { for: "user-firstname-input" } }, [
        _vm._v("First Name"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("label", { attrs: { for: "user-lastname-input" } }, [
        _vm._v("Last Name"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("label", { attrs: { for: "user-role-input" } }, [_vm._v("Role")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("label", { attrs: { for: "paddler-status-input" } }, [
        _vm._v("Status"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
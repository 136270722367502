<template>
  <div class="form-group" v-if="campaign">
    <div my-15px>
      <label class="mx-5">
        <h5>
          Quiz & Leaderboard Banner (non-clickable)
          <span class="small light">Recommended size 510 x 287 pixels</span>
        </h5>
      </label>

      <div class="form-group">
        <div class="image-form-wrapper">
          <div class="image-preview">
            <img class="img img-fluid" v-if="picture" :src="picture" />
            <div v-else class="image-placeholder"></div>
          </div>
          <input
            type="file"
            ref="quiz_banner"
            name="quiz_banner"
            accept="image/*"
            class="form-control"
            :disabled="disabled"
            @change="uploadImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixins from "../mixins/form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Quiz & Leaderboard banner error.",
        success: "Upload Quiz & Leaderboard banner success.",
      },
    };
  },

  computed: {
    picture() {
      return this.campaign.quiz_banner;
    },
  },

  methods: {
    validateFileUpload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        event.target.files[0].value = "";
        throw new Error("File shall not exceed 1 MB");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/upload.form.scss";

.image-form-wrapper {
  width: 375px;

  .action {
    width: 100%;
    height: 100%;
    position: relative;

    .reset-img {
      right: 0.5rem;
      bottom: 0.5rem;
      position: absolute;
    }
  }
}
</style>

<template>
  <div class="page vld-parent" v-if="peddler">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Peddler Management</h1>
          <breadcrumb
            name="E_COUPON_PEDDLER_DETAIL"
            :withLast.sync="peddler.username"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Peddler Info - {{ peddler.peddler_id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <div class="peddler-form-horizontal" v-if="peddler">
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-login-input">Peddler Log In</label>
                    </div>
                    <div class="col-5">
                      <input
                        name="username"
                        class="form-control"
                        id="paddler-login-input"
                        autocomplete="false"
                        v-model="peddler.username"
                        @change="update"
                      />
                      <p class="my-5 px-3 hint">
                        6-10 characters, a-z, A-Z, 0-9, dot, dash, underscore.
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-name-input">Peddler Name</label>
                    </div>
                    <div class="col-5">
                      <input
                        name="peddler_name"
                        class="form-control"
                        id="paddler-name-input"
                        autocomplete="false"
                        v-model="peddler.peddler_name"
                        @change="update"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-isms-code-input">ISMS</label>
                    </div>
                    <div class="col-5" v-if="enable_edit_isms_code">
                      <div class="form-update-isms-code">
                        <input
                          name="isms_code"
                          class="form-control"
                          id="paddler-isms-code-input"
                          autocomplete="false"
                          v-model="peddler.isms_code"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-round"
                          @click="updateISMSCode"
                        >
                          Commit
                        </button>
                        <button
                          type="button"
                          class="btn btn-defalut btn-round"
                          @click="enable_edit_isms_code = false"
                        >
                          Cancel
                        </button>
                      </div>
                      <p>{{ shopAddress }}</p>
                    </div>
                    <div class="col-5" v-else>
                      <div class="form-update-isms-code">
                        <span>{{ peddler.isms_code }}</span>

                        <button
                          class="btn btn-primary btn-round"
                          @click="enable_edit_isms_code = true"
                        >
                          Change
                        </button>
                      </div>
                      <p>{{ shopAddress }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-status-input">Status</label>
                    </div>
                    <div class="col-5">
                      <select
                        name="status"
                        class="form-control"
                        id="paddler-status-input"
                        @change="update"
                        v-model.number="peddler.status"
                      >
                        <option value="1">ACTIVE</option>
                        <option value="0">INACTIVE</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-password-input">Password</label>
                    </div>
                    <div class="col-5" v-if="enable_edit_password">
                      <div class="form-update-password">
                        <input
                          name="password"
                          class="form-control"
                          id="paddler-password-input"
                          autocomplete="false"
                          v-model="peddler.password"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-round"
                          @click="updatePassword"
                        >
                          Commit
                        </button>
                        <button
                          type="button"
                          class="btn btn-defalut btn-round"
                          @click="enable_edit_password = false"
                        >
                          Cancel
                        </button>
                      </div>

                      <p
                        @click="generatePassword"
                        class="auto-generate-password-link"
                      >
                        Auto Generate Password
                      </p>
                    </div>
                    <div class="col-5" v-else>
                      <div class="form-update-password">
                        <span>{{ peddler.password }}</span>

                        <button
                          class="btn btn-primary btn-round"
                          @click="enable_edit_password = true"
                        >
                          Change
                        </button>
                      </div>

                      <vue-qr
                        :size="260"
                        :text="peddler.password"
                        :value="peddler.password"
                        colorDark="#000"
                        class="qr-render"
                        style="display: none"
                      />

                      <p @click="downloadQR" class="click-to-download-qr">
                        Click to download QR
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";

import VueQr from "vue-qr";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "PeddlerInformation",

  data() {
    return {
      peddler: null,
      isLoading: false,
      enable_edit_password: false,
      enable_edit_isms_code: false,

      current_password: "",
      current_isms_code: "",
    };
  },

  components: {
    VueQr,
    Loading,
  },

  watch: {
    enable_edit_isms_code(n) {
      if (n === true) {
        this.current_isms_code = this.peddler.isms_code;
        return;
      }
      if (n === false) {
        this.peddler.isms_code = this.current_isms_code;
        this.current_isms_code = "";
        return;
      }
    },
    enable_edit_password(n) {
      if (n === true) {
        this.current_password = this.peddler.password;
        return;
      }
      if (n === false) {
        this.peddler.password = this.current_password;
        this.current_password = "";
        return;
      }
    },
  },

  computed: {
    shopAddress() {
      const { shop_name, subdistrict, district, province } = this.peddler;
      return `ร้าน ${shop_name || "-"}  ${subdistrict || ""} ${district || ""} ${
        province || ""
      }`;
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async update({ target }) {
      if (!target) return;

      if (target.name == "username") {
        const matched = /^[A-Za-z\d\.\-\_]{6,10}$/gm.test(target.value);
        if (!matched) {
          this.$dialogs.alert(
            `Peddler Login "${target.value}" not match pattern \n "a-z, A-Z, 0-9, dot, dash, underscore"`
          );
          return;
        }
      }

      try {
        const form = {};
        form[target.name] = target.value;

        if (target.name == "status") {
          form[target.name] = +target.value;
        }

        const { id } = this.$route.params;

        await ecouponService.updatePeddlerInfo(id, form);

        const labelElem = document.querySelector(`label[for=\"${target.id}\"]`);
        this.$notify(`Update ${labelElem.textContent} success.`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Update peddler information error");

        this.getPeddlerInfo();
      }
    },

    async updateISMSCode() {
      this.isLoading = true;
      await this.wait(500);

      try {
        await this.update({
          target: {
            name: "isms_code",
            value: this.peddler.isms_code,
            id: "paddler-isms-code-input",
          },
        });

        this.current_isms_code = this.peddler.isms_code;
        this.enable_edit_isms_code = false;

        this.getPeddlerInfo();
      } catch (error) {
        this.$notify("Error! Something went wrong.");
      } finally {
        this.isLoading = false;
      }
    },

    async updatePassword() {
      this.isLoading = true;
      await this.wait(500);

      try {
        await this.update({
          target: {
            name: "password",
            value: this.peddler.password,
            id: "paddler-password-input",
          },
        });

        this.current_password = this.peddler.password;
        this.enable_edit_password = false;
      } catch (error) {
        this.$notify("Error! Something went wrong.");
      } finally {
        this.isLoading = false;
      }
    },

    async downloadQR() {
      this.isLoading = true;
      await this.wait(500);

      try {
        const { peddler_id, username } = this.peddler;

        const ImageBase64 = document
          .querySelector(".qr-render")
          .getAttribute("src");

        const a = document.createElement("a");
        a.href = ImageBase64;
        a.download = `${peddler_id}_${username}.png`;
        a.click();
      } catch (error) {
        this.$notify("Error! Something went wrong.");
      } finally {
        this.isLoading = false;
      }
    },

    async generatePassword() {
      this.isLoading = true;
      await this.wait(500);
      try {
        this.peddler.password = await ecouponService.generatePeddlerPassword();
      } catch (error) {
        this.$notify("Error! Something went wrong.");
      } finally {
        this.isLoading = false;
      }
    },

    async getPeddlerInfo() {
      const id = this.$route.params.id;
      this.peddler = await ecouponService.getPeddlerInfo(id);
    },
  },

  async created() {
    await this.getPeddlerInfo();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.peddler-form-horizontal {
  .form-control {
    width: 250px;
  }

  .hint {
    color: #bbc1c6;
  }

  .row {
    margin-bottom: 2em;
  }

  .row .col-3 {
    font-weight: 400;
    text-align: right;
    align-items: center;
    justify-content: flex-end;

    > label {
      margin-top: 0.5em;
    }
  }

  .qr-render {
    border-radius: 6px;
    border: 4px solid #000;
  }

  .click-to-download-qr,
  .auto-generate-password-link {
    color: #3e8ef7;
    cursor: pointer;
    padding: 0.5em 0px;
    display: inline-block;
    text-decoration: underline;
  }

  .form-update-password,
  .form-update-isms-code {
    gap: 0.5em;
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    > span {
      margin-right: 2em;
    }
  }
}
</style>

import qs from "qs";
import HttpRequest from "./request";

export class WinnerProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/winner`);
  }

  async getWinnerDate(from = this.lastmonth(), to = this.today()) {
    const { data } = await this.get(`?from=${from}&to=${to}`);
    return data;
  }

  lastmonth() {
    var today = new Date();
    var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 30 * 3)
      .toJSON()
      .slice(0, 10);
    return lastmonth;
  }

  today() {
    var today = new Date().toJSON().slice(0, 10);
    return today;
  }

  async getWinner(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    console.log(this.queryString);
    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createWinner(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editWinner(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async download(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return await this.axiosInstance.get(`/download${queryString}`, {
      responseType: "blob",
    });
  }
}

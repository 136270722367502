export class ProductModel {
  name = "";
  description = "";
  status = "draft";
}

export const ProductTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
    sortable: true,
  },
  {
    label: "Product Name",
    field: "name",
  },
  {
    label: "Product Description",
    field: "description",
    sortable: false,
  },
  {
    label: "Product Image",
    field: "image",
    sortable: false,
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Created date",
    field: "created_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm",
  },
  {
    label: "Modified by",
    field: "modified_by_username",
  },
];

export const fileUploadOptions = {
  accept: ".jpeg, .jpg, .png",
  maxFileSize: "500K",
};

export const defaultEditorConfig = {
  extraPlugins: "image2",
  filebrowserUploadUrl: `${
    process.env.VUE_APP_SERVICE_URL
  }/ams/api/editor/upload?token=${localStorage.getItem("accessToken")}`,
};

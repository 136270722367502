<template>
  <div class="col-md-12">
    <div
      class="reward-picture d-flex flex-column align-items-center justify-content-center"
      my-15px
    >
      <label>
        <h4>
          Picture
          <span class="small light">
            (Recommended size 300 x 300 pixels / less than 1 MB)
          </span>
        </h4>
      </label>
      <div class="image-preview">
        <img class="img img-fluid" v-if="picture" :src="picture" />
        <div v-else class="image-placeholder"></div>
      </div>
      <input
        type="file"
        accept="image/*"
        class="form-control"
        @change="uploadImage"
        ref="prize_picture"
        name="prize_picture"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  data() {
    return {
      file: null,
      message: {
        error: "Upload Reward picture error.",
        success: "Upload Reward picture success.",
      },
    };
  },

  computed: {
    picture() {
      return this.reward.prize_picture;
    },
  },

  methods: {
    validateFileUpload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        event.target.files[0].value = "";
        throw new Error("File shall not exceed 1 MB");
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-body">
          <div class="container">
            <h4>Prize</h4>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Prize Name</th>
                  <th scope="col">Detail</th>
                  <th scope="col">Prize Lot ID</th>
                  <th scope="col">Type</th>
                  <th scope="col">ID</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ detail.id }}</td>
                  <td>{{ detail.prize_name }}</td>
                  <td>{{ "-" }}</td>
                  <td>{{ detail.prize_lot_id }}</td>
                  <td>{{ detail.campaign_type }}</td>
                  <td>{{ detail.campaign_id }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Issue Date</th>
                  <th scope="col">Deliver Date</th>
                  <th scope="col">Use Date</th>
                  <th scope="col">Expire Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ detail.status | capitalize }}</td>
                  <td>{{ detail.barcode_id }}</td>
                  <td>{{ detail.issue_date | dateTime }}</td>
                  <td>{{ detail.deliver_date | dateTime }}</td>
                  <td>{{ detail.use_date | dateTime }}</td>
                  <td>{{ detail.expire_date | dateTime }}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h4>User</h4>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Contact ID</th>
                  <th scope="col">Contact Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ detail.contact_id }}</td>
                  <td>{{ detail.name }}</td>
                </tr>
              </tbody>
            </table>
            <hr />

            <div class="d-flex justify-content-between align-items-center">
              <h4>Contact Info</h4>
              <button
                @click="deliver"
                id="btn-deliver"
                class="btn btn-success btn-round"
                :disabled="
                  detail.status != 'claimed' || detail.barcode_id != null
                "
              >
                <i
                  class="icon mdi mdi-truck-fast mdi-24px"
                  aria-hidden="true"
                ></i>
                Deliver
              </button>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col" td-width-30>Name</th>
                  <th scope="col" td-width-30>Tel. No.</th>
                  <th scope="col">Address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ `${detail.firstname || ""} ${detail.lastname || ""}` }}
                  </td>
                  <td>{{ detail.telephone }}</td>
                  <td>{{ address }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" td-width-60>Citizen ID</th>
                  <th scope="col">Tax Handling</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ detail.id_card_no }}</td>
                  <td v-html="taxHandling"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prizeService } from "@/api/client";

export default {
  props: ["detail"],

  computed: {
    address() {
      const {
        house_no,
        road,
        tambon,
        amphoe,
        province,
        zip_code,
      } = this.detail;

      const address = `${house_no || ""} ${road || ""} ${tambon || ""} ${
        amphoe || ""
      } ${province || ""} ${zip_code || ""}`;

      return address.replace(/ /gi, "").length > 0 ? address : "-";
    },

    taxHandling() {
      const { email, tax_document } = this.detail;
      if (tax_document == "email") {
        return `Email: <br/> ${email || "-"}`;
      } else {
        return tax_document;
      }
    },
  },

  methods: {
    async deliver() {
      try {
        await prizeService.deliver(this.detail.id);

        this.$emit("delivered", this.detail.id);
        this.$notify("Set prize to delivered success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Set prize to delivered error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[td-width-30] {
  width: 30%;
}

[td-width-60] {
  width: 60%;
}

#btn-deliver {
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 0.25em 1.25em;

  .icon {
    width: auto;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "site-navbar navbar navbar-default navbar-fixed-top navbar-mega",
      attrs: { role: "navigation" },
    },
    [
      _c("div", { staticClass: "navbar-header" }, [
        _vm._m(0),
        _vm._m(1),
        _c(
          "div",
          {
            staticClass:
              "navbar-brand navbar-brand-center site-gridmenu-toggle",
            attrs: { "data-toggle": "gridmenu" },
          },
          [
            _c("img", {
              staticClass: "navbar-brand-logo",
              attrs: { src: require("@/assets/logo.png"), title: _vm.appName },
            }),
            _c("span", { staticClass: "navbar-brand-text hidden-xs-down" }, [
              _vm._v(" PMI"),
            ]),
          ]
        ),
        _vm._m(2),
      ]),
      _c("div", { staticClass: "navbar-container container-fluid" }, [
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse navbar-collapse-toolbar",
            attrs: { id: "site-navbar-collapse" },
          },
          [
            _vm._m(3),
            _c(
              "ul",
              {
                staticClass:
                  "nav navbar-toolbar navbar-right navbar-toolbar-right",
              },
              [
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link navbar-avatar",
                      attrs: {
                        "data-toggle": "dropdown",
                        "aria-expanded": "false",
                        "data-animation": "scale-up",
                        role: "button",
                      },
                    },
                    [
                      _c("span", { staticClass: "avatar avatar-online" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.themePath +
                              "/theme/remark/global/portraits/8.jpg",
                          },
                        }),
                        _c("i"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                    [
                      _vm._m(4),
                      _vm._m(5),
                      _c("div", {
                        staticClass: "dropdown-divider",
                        attrs: { role: "presentation" },
                      }),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/logout", role: "menuitem" },
                        },
                        [
                          _c("i", {
                            staticClass: "icon wb-power",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" Logout"),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "navbar-toggler hamburger hamburger-close navbar-toggler-left hided",
        attrs: { type: "button", "data-toggle": "menubar" },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _c("span", { staticClass: "hamburger-bar" }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler collapsed",
        attrs: {
          type: "button",
          "data-target": "#site-navbar-collapse",
          "data-toggle": "collapse",
        },
      },
      [
        _c("i", {
          staticClass: "icon wb-more-horizontal",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler collapsed",
        attrs: {
          type: "button",
          "data-target": "#site-navbar-search",
          "data-toggle": "collapse",
        },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle Search")]),
        _c("i", {
          staticClass: "icon wb-search",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav navbar-toolbar" }, [
      _c(
        "li",
        {
          staticClass: "nav-item hidden-sm-down",
          attrs: { id: "toggleFullscreen" },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link icon icon-fullscreen",
              attrs: { "data-toggle": "fullscreen", role: "button" },
            },
            [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v("Toggle fullscreen"),
              ]),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "javascript:void(0)", role: "menuitem" },
      },
      [
        _c("i", {
          staticClass: "icon wb-user",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" Profile"),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "javascript:void(0)", role: "menuitem" },
      },
      [
        _c("i", {
          staticClass: "icon wb-settings",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" Settings"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.team
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 1: Free Text ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.team.text1_content,
                        expression: "team.text1_content",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text1_content",
                      "width-300px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.team.text1_content },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.team, "text1_content", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_color,
                          expression: "team.text1_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text1_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text1_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_color,
                          expression: "team.text1_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text1_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text1_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_stroke_color,
                          expression: "team.text1_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text1_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text1_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_stroke_color,
                          expression: "team.text1_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text1_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text1_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_shadow_color,
                          expression: "team.text1_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text1_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text1_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_shadow_color,
                          expression: "team.text1_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text1_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text1_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_size,
                          expression: "team.text1_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text1_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text1_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_stroke_width,
                          expression: "team.text1_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text1_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text1_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text1_shadow_width,
                          expression: "team.text1_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text1_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text1_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text1_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 2: SFPoint XXX คะแนน, Reward ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_color,
                          expression: "team.text2_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text2_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text2_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_color,
                          expression: "team.text2_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text2_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text2_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_stroke_color,
                          expression: "team.text2_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text2_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text2_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_stroke_color,
                          expression: "team.text2_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text2_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text2_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_shadow_color,
                          expression: "team.text2_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text2_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text2_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_shadow_color,
                          expression: "team.text2_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text2_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text2_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_size,
                          expression: "team.text2_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text2_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text2_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_stroke_width,
                          expression: "team.text2_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text2_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text2_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text2_shadow_width,
                          expression: "team.text2_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text2_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text2_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text2_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 3: Free text ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.team.text3_content,
                        expression: "team.text3_content",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text3_content",
                      "width-300px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.team.text3_content },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.team, "text3_content", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_color,
                          expression: "team.text3_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text3_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text3_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_color,
                          expression: "team.text3_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text3_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text3_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_stroke_color,
                          expression: "team.text3_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text3_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text3_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_stroke_color,
                          expression: "team.text3_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text3_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text3_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_shadow_color,
                          expression: "team.text3_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text3_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text3_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_shadow_color,
                          expression: "team.text3_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text3_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text3_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_size,
                          expression: "team.text3_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text3_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text3_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_stroke_width,
                          expression: "team.text3_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text3_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text3_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text3_shadow_width,
                          expression: "team.text3_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text3_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text3_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text3_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 4: New line & free text ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.team.text4_content,
                        expression: "team.text4_content",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_content",
                      "width-300px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.team.text4_content },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.team, "text4_content", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_color,
                          expression: "team.text4_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text4_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text4_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_color,
                          expression: "team.text4_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text4_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text4_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_stroke_color,
                          expression: "team.text4_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text4_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text4_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_stroke_color,
                          expression: "team.text4_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text4_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text4_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_shadow_color,
                          expression: "team.text4_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text4_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text4_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_shadow_color,
                          expression: "team.text4_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text4_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text4_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_size,
                          expression: "team.text4_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text4_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text4_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_stroke_width,
                          expression: "team.text4_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text4_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text4_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text4_shadow_width,
                          expression: "team.text4_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text4_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text4_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text4_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 5: Target ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_color,
                          expression: "team.text5_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text5_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text5_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_color,
                          expression: "team.text5_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text5_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text5_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_stroke_color,
                          expression: "team.text5_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text5_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text5_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_stroke_color,
                          expression: "team.text5_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text5_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text5_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_shadow_color,
                          expression: "team.text5_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text5_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text5_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_shadow_color,
                          expression: "team.text5_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text5_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text5_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_size,
                          expression: "team.text5_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text5_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text5_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_stroke_width,
                          expression: "team.text5_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text5_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text5_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text5_shadow_width,
                          expression: "team.text5_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text5_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text5_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text5_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 6: แถว ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_color,
                          expression: "team.text6_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text6_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text6_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_color,
                          expression: "team.text6_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text6_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text6_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_stroke_color,
                          expression: "team.text6_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text6_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text6_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_stroke_color,
                          expression: "team.text6_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text6_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text6_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_shadow_color,
                          expression: "team.text6_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text6_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text6_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_shadow_color,
                          expression: "team.text6_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text6_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text6_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_size,
                          expression: "team.text6_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text6_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text6_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_stroke_width,
                          expression: "team.text6_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text6_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text6_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text6_shadow_width,
                          expression: "team.text6_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text6_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text6_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text6_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 7: New line & free text ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content")]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.team.text7_content,
                        expression: "team.text7_content",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text7_content",
                      "width-300px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.team.text7_content },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.team, "text7_content", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_color,
                          expression: "team.text7_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text7_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text7_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_color,
                          expression: "team.text7_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text7_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text7_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_stroke_color,
                          expression: "team.text7_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text7_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text7_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_stroke_color,
                          expression: "team.text7_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text7_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text7_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_shadow_color,
                          expression: "team.text7_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text7_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text7_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_shadow_color,
                          expression: "team.text7_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text7_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text7_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_size,
                          expression: "team.text7_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text7_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text7_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_stroke_width,
                          expression: "team.text7_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text7_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text7_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text7_shadow_width,
                          expression: "team.text7_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text7_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text7_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text7_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [
            _vm._v(" Text Group 8: Progress Result (ตรวจแล้ว... รอการตรวจ) "),
          ]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_color,
                          expression: "team.text8_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text8_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text8_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_color,
                          expression: "team.text8_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text8_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text8_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_stroke_color,
                          expression: "team.text8_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text8_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text8_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_stroke_color,
                          expression: "team.text8_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text8_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text8_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_shadow_color,
                          expression: "team.text8_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text8_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text8_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_shadow_color,
                          expression: "team.text8_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text8_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text8_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_size,
                          expression: "team.text8_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text8_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text8_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_stroke_width,
                          expression: "team.text8_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text8_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text8_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text8_shadow_width,
                          expression: "team.text8_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text8_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text8_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text8_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v(" Text Group 9: Progress Value (XXXXX) ")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text color")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_color,
                          expression: "team.text9_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text9_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text9_color", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_color,
                          expression: "team.text9_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text9_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text9_color", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text stroke")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_stroke_color,
                          expression: "team.text9_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text9_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text9_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_stroke_color,
                          expression: "team.text9_stroke_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text9_stroke_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_stroke_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text9_stroke_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [_vm._v("Text shadow")]),
                _c("td", [
                  _c("div", { staticClass: "color-picker" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_shadow_color,
                          expression: "team.text9_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "text9_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text9_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_shadow_color,
                          expression: "team.text9_shadow_color",
                        },
                      ],
                      attrs: {
                        type: "color",
                        name: "text9_shadow_color",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_shadow_color },
                      on: {
                        change: function ($event) {
                          return _vm.update($event, "color")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text9_shadow_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text size")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_size,
                          expression: "team.text9_size",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text9_size",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_size },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.team, "text9_size", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" rem "),
                  ]),
                ]),
                _c("td", [_vm._v("Stroke width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_stroke_width,
                          expression: "team.text9_stroke_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text9_stroke_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_stroke_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text9_stroke_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
                _c("td", [_vm._v("Shadow width")]),
                _c("td", [
                  _c("div", { staticClass: "css-input-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.team.text9_shadow_width,
                          expression: "team.text9_shadow_width",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "text9_shadow_width",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.team.text9_shadow_width },
                      on: {
                        change: function ($event) {
                          return _vm.update($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.team,
                            "text9_shadow_width",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v(" Text area ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
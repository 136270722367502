<template>
  <div id="prize-theme-pool-options" class="col-md-12" v-if="theme">
    <label>
      <h4>Pool Option</h4>
    </label>

    <div
      class="option"
      v-for="(pool, index) in selected"
      :key="index"
      @click="handleOptionClick(pool)"
    >
      <p class="no">{{ index + 1 }}</p>

      <div
        class="selected-pool"
        data-toggle="tooltip"
        data-placement="top"
        title="Click to edit"
      >
        {{ pool.id }} - {{ pool.name }}
      </div>

      <button
        type="button"
        class="btn btn-default"
        @click.stop="removeOption(pool.id)"
        :disabled="disabled || !canRemoveOption"
      >
        <i class="icon md-delete" aria-hidden="true"></i>
      </button>
    </div>

    <div class="action">
      <button
        type="button"
        @click="addMoreOption"
        class="btn btn-round btn-primary"
        :disabled="disabled || !canAddMoreOption"
      >
        <i aria-hidden="true" class="icon md-plus"></i>
        Add Pool option
      </button>
    </div>

    <modal name="pool-picker-modal" :width="400" height="auto">
      <PoolPicker
        :pools="availablePools()"
        @confirm="confirmSelect"
        name="pool-picker-modal"
      />
    </modal>
  </div>
</template>

<script>
import formMixins from "../form.mixins";
import PoolPicker from "./PoolPicker.vue";
import { prizepoolService } from "@/api/client";

export default {
  name: "PoolOption",

  mixins: [formMixins],

  components: {
    PoolPicker,
  },

  data() {
    return {
      mode: "ADD", // ADD || EDIT
      editId: null,
      pools: [],
      selected: [],
      message: {
        error: "Update Pool option error.",
        success: "Update Pool option success.",
      },
    };
  },

  computed: {
    disabled() {
      return !["inactive"].includes(this.theme.status);
    },
    canAddMoreOption() {
      const maxOption = +this.theme.auto_claim === 1 ? 1 : 4;
      return this.selected.length < maxOption;
    },
    canRemoveOption() {
      return this.selected.length > 1;
    },
  },

  methods: {
    availablePools() {
      return this.pools.filter((o) => {
        const index = this.selected.findIndex((s) => s.id == o.id);
        return index < 0;
      });
    },

    confirmSelect(item) {
      if (this.mode == "ADD") {
        this.selected.push(item);
      }

      if (this.mode == "EDIT") {
        const index = this.selected.findIndex((o) => o.id === this.editId);
        this.selected[index] = item;
      }

      this.handlePoolOptionChange();
    },

    addMoreOption() {
      this.mode = "ADD";
      this.$modal.show(`pool-picker-modal`);
    },

    removeOption(id) {
      const index = this.selected.findIndex((o) => o.id === id);
      this.selected.splice(index, 1);
      this.handlePoolOptionChange();
    },

    handleOptionClick(pool) {
      this.mode = "EDIT";
      this.editId = pool.id;
      this.$modal.show(`pool-picker-modal`);
    },

    handlePoolOptionChange() {
      const event = {
        target: {
          name: "pool_id_list",
          value: this.selected.map((o) => o.id),
        },
      };

      this.update(event);
    },

    async fetchPoolList() {
      const pools = await prizepoolService.getPrizePoolList({
        brief: 1,
        show_all: 0,
      });

      this.pools = pools;
    },

    async initial() {
      await this.fetchPoolList();

      for (let index = 0; index < this.theme.pool_id_list.length; index++) {
        const id = this.theme.pool_id_list[index];

        const pool = this.pools.find((o) => o.id == id);

        if (pool) {
          this.selected.push(pool);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#prize-theme-pool-options {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .option {
    width: 450px;
    max-width: 450px;

    gap: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: flex-start;

    cursor: pointer;
    padding: 0.25rem 1rem;
    padding-right: 0.25rem;

    &:hover {
      border-radius: 0.5rem;
      background-color: #f5f5f5;
    }

    .no {
      padding-right: 1rem;
      margin-bottom: 0px;
      border-right: 1px solid #ccc;
    }

    .selected-pool {
      flex-grow: 1;
    }
  }

  .action {
    margin-top: 1rem;
    margin-left: 1rem;
  }
}
</style>

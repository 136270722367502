var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.cars, function (ref, index) {
        var name = ref.name
        var text = ref.text
        return _c("div", { key: index, staticClass: "col-md-4" }, [
          index !== 4
            ? _c("div", { staticClass: "form-group" }, [
                _c("div", { attrs: { "my-15px": "" } }, [
                  _c("label", { staticClass: "form-control-label" }, [
                    _c("h5", [
                      _vm._v(" " + _vm._s(text) + " "),
                      _c("span", { staticClass: "small light" }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "d-block" }, [
                      _vm.picture
                        ? _c("img", {
                            staticClass: "img img-fluid image-preview",
                            attrs: { src: _vm.getProductImageUrl(index) },
                          })
                        : _vm._e(),
                    ]),
                    _c("input", {
                      ref: "" + name,
                      refInFor: true,
                      staticClass: "form-control",
                      attrs: {
                        type: "file",
                        accept: "image/*",
                        name: "" + name,
                        disabled: _vm.disabled,
                      },
                      on: { change: _vm.upload },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("label", { staticClass: "form-control-label" }, [
          _c("h4", [
            _vm._v(" Car Skin "),
            _c("span", { staticClass: "small light" }, [
              _vm._v(" (recommended size 539 x 285 )"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
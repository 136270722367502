var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "container ml-20" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("p", { attrs: { "d-inline-block": "", "width-150px": "" } }, [
              _vm._v("No.Tries per period"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.campaign.no_play,
                  expression: "campaign.no_play",
                  modifiers: { number: true },
                },
              ],
              ref: "no_play",
              staticClass: "form-control mx-10 d-inline",
              attrs: {
                "width-150px": "",
                type: "number",
                min: "1",
                max: "999",
                disabled: !_vm.editable,
              },
              domProps: { value: _vm.campaign.no_play },
              on: {
                change: _vm.update,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.campaign, "no_play", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" times "),
          ]),
          _c("div", { staticClass: "col-6" }, [
            _vm._v(" Period duration "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.campaign.period_duration,
                  expression: "campaign.period_duration",
                  modifiers: { number: true },
                },
              ],
              ref: "period_duration",
              staticClass: "form-control mx-10 d-inline",
              attrs: {
                min: "1",
                "width-150px": "",
                type: "number",
                disabled: !_vm.editable,
              },
              domProps: { value: _vm.campaign.period_duration },
              on: {
                change: _vm.update,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "period_duration",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" days "),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="panel" v-if="campaign && campaign.round">
    <div class="panel-heading">
      <h3 class="panel-title">Round Information</h3>
      <div class="panel-actions panel-actions-keep">
        <h4>No. Round {{ rounds.length }}</h4>
      </div>
    </div>
    <div class="panel-body">
      <RoundItem
        v-for="(round, index) in rounds"
        :key="round.round_id"
        :index="index"
        :round="round"
        :campaign="campaignData"
        @remove="removeRound"
      />
    </div>
    <div class="panel-footer">
      <button
        class="btn btn-default"
        @click="addRound"
        :disabled="!canAddRound"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { staffEngagementService } from "@/api/client";

import RoundItem from "./RoundItem.vue";
import RoundQuestionModal from "./RoundQuestionModal.vue";

export default {
  props: ["campaign"],

  components: {
    RoundItem,
    RoundQuestionModal,
  },

  computed: {
    rounds() {
      return this.campaign.round.sort((a, b) => a.round_id - b.round_id);
    },

    campaignData() {
      const { id, status } = this.campaign;
      return { id, status };
    },

    canAddRound() {
      if (this.campaign.status !== "draft") {
        return false;
      }

      return this.campaign.round.length < 50;
    },
  },

  methods: {
    async addRound() {
      try {
        await staffEngagementService.addRound(this.campaign.id);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Add round failed. Please try again.");
      }
    },

    async removeRound(round) {
      const name = round.name ? ` "${round.name}"` : "";

      const r = confirm(`Do you want want to delete Round${name}?`);

      if (r) {
        try {
          await staffEngagementService.removeRound(
            this.campaign.id,
            round.round_id
          );

          this.$emit("updated");
        } catch (error) {
          error.response
            ? this.$dialogs.alert(error.response.data)
            : this.$dialogs.alert("Remove round failed. Please try again.");
        }
      }
    },

    async addQuestion(roundId) {
      try {
        const { id } = await staffEngagementService.addQuestion(
          this.campaign.id,
          roundId
        );

        const round = this.rounds.find((round) => round.round_id === roundId);
        const index = round.question.length;

        this.openQuestionModal({ id, index, round });
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert("Add question failed. Please try again.");
      }
    },

    async removeQuestion(roundId, questionId) {
      const r = confirm("Do you want want to delete this question?");

      if (r) {
        try {
          await staffEngagementService.removeQuestion(
            this.campaign.id,
            roundId,
            questionId
          );

          this.$emit("updated");
        } catch (error) {
          error.response
            ? this.$dialogs.alert(error.response.data)
            : this.$dialogs.alert("Remove question failed. Please try again.");
        }
      }
    },

    openQuestionModal(params) {
      const { id, status } = this.campaign;
      const campaign = { id, status };

      this.$modal.show(
        RoundQuestionModal,
        { ...params, campaign, onChange: () => this.$emit("updated") },
        {
          name: "round-question-modal",
          width: 800,
          height: "auto",
        }
      );
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$root.$on("STAFF_ENGAGEMENT_REFETCH", () => {
        this.$emit("updated");
      });

      this.$root.$on("STAFF_ENGAGEMENT_ADD_QUESTION", (roundId) => {
        this.addQuestion(roundId);
      });

      this.$root.$on(
        "STAFF_ENGAGEMENT_EDIT_QUESTION",
        ({ id, index, round }) => {
          this.openQuestionModal({ id, index, round });
        }
      );

      this.$root.$on(
        "STAFF_ENGAGEMENT_REMOVE_QUESTION",
        (roundId, questionId) => {
          this.removeQuestion(roundId, questionId);
        }
      );

      this.$root.$on("hook:beforeDestroy", () => {
        this.$root.$off("STAFF_ENGAGEMENT_REFETCH");
      });

      this.$root.$on("hook:beforeDestroy", () => {
        this.$root.$off("STAFF_ENGAGEMENT_EDIT_QUESTION");
      });

      this.$root.$on("hook:beforeDestroy", () => {
        this.$root.$off("STAFF_ENGAGEMENT_ADD_QUESTION");
      });

      this.$root.$on("hook:beforeDestroy", () => {
        this.$root.$off("STAFF_ENGAGEMENT_REMOVE_QUESTION");
      });
    });
  },
};
</script>

<style></style>

import { required, minLength, maxLength } from "vuelidate/lib/validators";

const exceptCommaAndSpace = (v) => {
  return /^[^,\s]+$/.test(v);
};

export const FormCreateValidateRule = {
  name: {
    required,
    maxLength: maxLength(255),
  },
};

export const FormEditValidateRule = {
  name: {
    required,
    maxLength: maxLength(255),
  },
};

export const errorMessage = {
  name: {
    default: `กรุณาระบุค่า Product Name ให้ถูกต้อง`,
  },
};

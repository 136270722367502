export const reportTableColumns = [
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User ID",
    field: "sfdc_user_id",
  },
  {
    label: "First Name",
    field: "firstname",
  },
  {
    label: "Last Name",
    field: "lastname",
  },
  {
    label: "No. Coins",
    field: "total_coins",
    type: "number",
  },
  {
    label: "No. Special ",
    field: "no_locker",
    type: "number",
  },
  {
    label: "Special Points",
    field: "locker_points",
    type: "number",
  },
  {
    label: "No. Exchange",
    field: "no_exchange",
    type: "number",
  },
  {
    label: "Exchanged Points",
    field: "exchange_points",
    type: "number",
  },
];
export const useractivityTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
  },
  {
    label: "Coin Earned",
    field: "coin_plus",
  },
  {
    label: "Coin Exchanged",
    field: "coin_use",
  },
  {
    label: "Total Coins",
    field: "acc_coin",
  },
  {
    label: "Point Earned",
    field: "point",
  },
  {
    label: "Total Points",
    field: "acc_point",
  },
  {
    label: "Status",
    field: "status",
  },
];

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lot
    ? _c(
        "div",
        [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            ref: "name",
            staticClass: "form-control",
            attrs: { type: "text", maxlength: "255", disabled: !_vm.canEdit },
            domProps: { value: _vm.name },
            on: {
              change: _vm.update,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              },
            },
          }),
          _c("nameValidation", { attrs: { $v: _vm.$v } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Lot Name")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
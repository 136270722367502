<template>
  <div v-if="campaign">
    <div class="row">
      <label class="form-control-label">
        <h4>Coupon Information</h4>
      </label>
    </div>
    <div class="ml-2 form-row">
      <div class="form-group col-md-4">
        <div class="wrapper-upload-form" v-if="uploadStatus == 'initial'">
          <div class="upload-button-label">
            <button
              class="btn btn-upload mx-3"
              type="button"
              disabled="disabled"
            >
              <i class="fa fa-upload" aria-hidden="true"></i>
              Bulk upload
            </button>
            <label class="text-grey mx-5">Loading...</label>
          </div>
        </div>
        <div
          class="wrapper-upload-form d-flex"
          v-else-if="
            !uploadStatus || uploadStatus == 'done' || uploadStatus == 'ready'
          "
        >
          <div class="upload-button-label" v-if="canBulkUpload">
            <label
              class="btn btn-upload mx-3"
              :disabled="!canBulkUpload"
              :for="canBulkUpload ? 'Bulk-upload' : null"
            >
              <i class="fa fa-upload" aria-hidden="true"></i>
              Bulk upload
            </label>

            <input
              type="file"
              :disabled="!canBulkUpload"
              @change="FileBulkUpload"
              class="input-select-file"
              id="Bulk-upload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>

          <div class="upload-button-label" v-else-if="canBulkAdd">
            <label
              class="btn btn-upload mx-3"
              :disabled="!canBulkAdd"
              :for="canBulkAdd ? 'Bulk-add' : null"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Bulk Add
            </label>

            <input
              type="file"
              :disabled="!canBulkAdd"
              @change="FileBulkAdd"
              class="input-select-file"
              id="Bulk-add"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>

          <button
            class="btn btn-primary mx-5"
            type="button"
            @click="showConfirmPopup"
            v-if="uploadStatus == 'ready'"
          >
            Confirm
          </button>
        </div>
        <div class="wrapper-upload-form" v-else-if="uploadStatus == 'loading'">
          <div class="upload-button-label">
            <button
              v-if="!canBulkAdd"
              class="btn btn-upload mx-3"
              type="button"
              disabled="disabled"
            >
              <i class="fa fa-upload" aria-hidden="true"></i>
              Bulk upload
            </button>
            <button
              v-else
              class="btn btn-upload mx-3"
              type="button"
              disabled="disabled"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Bulk Add
            </button>
            <label class="text-link">Verifying...</label>
          </div>
        </div>
        <div class="wrapper-upload-form" v-else-if="uploadStatus == 'running'">
          <div class="upload-button-label">
            <button
              v-if="!canBulkAdd"
              class="btn btn-upload mx-3"
              type="button"
              disabled="disabled"
            >
              <i class="fa fa-upload" aria-hidden="true"></i>
              Bulk upload
            </button>
            <button
              v-else
              class="btn btn-upload mx-3"
              type="button"
              disabled="disabled"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Bulk Add
            </button>
            <label class="text-link">Executing... </label>
          </div>
        </div>
      </div>
      <div class="form-group col-md-4">
        <a @click="downloadTemplate" href="javascript:;">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
          Click to download template
        </a>
      </div>
      <div class="form-group col-md-4"></div>
    </div>
    <label class="d-inline mx-10 form-control-label">
      <strong>Last update: {{ campaign.modified_time }}</strong>
    </label>
    <div class="form-row ml-4 mt-2">
      <div class="form-group col-md-4">
        No. Coupons: {{ campaign.total_coupon | numberWithCommas }}
      </div>
      <div class="form-group col-md-4">
        Total Face Value: {{ campaign.total_face_value | numberWithCommas }}
      </div>
      <div class="form-group col-md-4">
        Total Top Up Value:{{ campaign.total_topup_value | numberWithCommas }}
      </div>
    </div>
    <modal
      :width="600"
      height="auto"
      :clickToClose="false"
      name="upload-confrim-modal"
      draggable=".panel-heading"
    >
      <UploadConfirmModal
        @confirm="confirmUpload"
        :message="confirmMessage"
        name="upload-confrim-modal"
        title="ยืนยันการอัพโหลดไฟล์หรือไม่?"
      />
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { ecouponService } from "@/api/client";
import UploadConfirmModal from "@/components/shared/BaseConfirmModal.vue";

export default {
  props: ["campaign"],

  data() {
    return {
      id: null,
      file: null,
      task_id: null,
      pullingId: null,
      uploadStatus: null,
      total_coupons: 0,
      total_users: 0,
      confirmMessage: "",
    };
  },

  components: { UploadConfirmModal },

  computed: {
    canBulkUpload() {
      return ["draft"].includes(this.campaign.status);
    },
    canBulkAdd() {
      return ["ongoing", "scheduled"].includes(this.campaign.status);
    },
  },

  watch: {
    uploadStatus(n) {
      if ((n == "loading" || n == "running") && this.pullingId == null) {
        this.startPullingStatus(1);
        return;
      }

      if (n == "ready") {
        this.stopPullingStatus();
        return;
      }

      if (n == "done" && this.pullingId != null) {
        this.stopPullingStatus();
        this.$emit("updated");
        this.$notify("Upload Rank and Reward success");
        return;
      }
    },
  },

  mounted() {
    this.id = this.campaign.id;
    this.uploadStatus = "initial";
    this.getUploadStatus();
  },

  methods: {
    downloadTemplate() {
      const url = `https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/ecoupon/ecp_upload.xlsx?ts=${new Date().getTime()}`;
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", "Example Template.xlsx");
          link.download = "Example template.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    checkFileUpload(file) {
      const { type } = file;
      if (
        type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.$dialogs.alert("อัพโหลดได้เฉพาะไฟล์ Excel เท่านั้น");
        return false;
      }
      return true;
    },

    FileBulkUpload(event) {
      this.file = event.target.files[0];
      const validateFileType = this.checkFileUpload(this.file);
      if (!validateFileType) return;
      event.target.value = null;
      this.upload("upload");
    },

    FileBulkAdd(event) {
      this.file = event.target.files[0];
      event.target.value = null;
      this.upload("add");
    },

    async upload(typeUpload) {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("id", this.id);
        form.append("type", typeUpload);
        form.append("file", this.file);

        this.uploadStatus = "loading";

        const { task_id, total_coupons, total_users } =
          await ecouponService.uploadCampaign(form);

        this.task_id = task_id;
        this.total_users = total_users;
        this.total_coupons = total_coupons;
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Rank and Reward error");
      }
    },

    showConfirmPopup() {
      this.confirmMessage = `มี coupon ทั้งหมด ${this.total_coupons} ใบ issued ให้กับ user ${this.total_users} คน`;
      this.$modal.show("upload-confrim-modal");
    },

    async confirmUpload() {
      try {
        this.uploadStatus = "running";
        this.$modal.hide("upload-confrim-modal");
        await ecouponService.confrimUploadCampaign(this.task_id);

        this.startPullingStatus(3);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Rank and Reward error");
      } finally {
        this.task_id = null;
        this.total_users = 0;
        this.total_coupons = 0;
        this.confirmMessage = "";
      }
    },

    async getUploadStatus() {
      if (this.uploadStatus == `ready`) {
        this.stopPullingStatus();
        return;
      }

      const httpService = ecouponService.axiosInstance;

      try {
        const { data } = await httpService.get(
          `/campaign/upload_cpstatus/${this.id}`
        );

        this.task_id = data.task_id;
        this.uploadStatus = data.status;

        if (data.status == `ready`) {
          this.stopPullingStatus();
          this.total_users = data.total_users;
          this.total_coupons = data.total_coupons;
        }
      } catch (error) {
        console.log(error);
      }
    },

    startPullingStatus(n) {
      // console.log(n);
      this.stopPullingStatus();
      this.pullingId = setInterval(this.getUploadStatus, 3000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },
  beforeDestroy() {
    this.stopPullingStatus();
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  color: #3e8ef7;
  margin-left: 1em;
}

.input-select-file {
  width: 0px;
  display: none;
  visibility: hidden;
}

.upload-button-label {
  display: flex;
  margin-left: 1em;
  gap: 5px;
  align-items: center;
}

.btn-upload {
  font-weight: 300;
  margin-bottom: 0px;
  background-color: #e5eaec;

  &[disabled="disabled"] {
    color: #c1c1c1;
    cursor: not-allowed;
    background-color: #e5eaec90;
  }
}
</style>

<template>
  <div class="form-group">
    <div class="form-group-row">
      <label>
        <h4 class="mx-5">Available condition</h4>
      </label>
      <input
        min="0"
        max="10000"
        type="number"
        @change="update"
        class="form-control"
        :disabled="disabled"
        name="available_condition"
        v-model="pool.available_condition"
      />
      <span>days before prize expiration</span>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: `Update Available condition error.`,
        success: `Update Available condition success.`,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 2rem;

  h4,
  label {
    margin-bottom: 0px;
  }
}

[type="number"] {
  max-width: 100px;
}
</style>

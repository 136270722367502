<template>
  <div class="col-md-12" v-if="leaderboard">
    <label class="mx-5">
      <h4 class="mb-0">Table area</h4>
    </label>

    <div class="ml-10">
      <table
        id="matric-content-table"
        class="table table-responsive table-borderless"
      >
        <tr>
          <td width="120">Metric content</td>
          <td>
            <input
              type="text"
              width-300px
              class="form-control"
              name="metric_content"
              @change="update($event)"
              v-model="leaderboard.metric_content"
              :disabled="disabled"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      leaderboard: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update(event, type) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        const form = {};
        form[name] = this.leaderboard[name];

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await progressBarService.updateCampaignInfo(id, { leaderboard: form });

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
  },

  mounted() {
    this.leaderboard = this.campaign.leaderboard;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

table tr td {
  &:nth-child(1) {
    width: 10%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 12%;
  }
  &:nth-child(6) {
    width: 20%;
  }
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

.css-input-text {
  input[type="number"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem;
    margin-right: 6px;
  }
}

input[type="text"],
input[type="color"],
input[type="number"] {
  &:disabled {
    background-color: #efefef;
  }
}

input[type="text"] {
  max-width: 120px;
}

#matric-content-table {
  tr td {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4) {
      width: 40%;
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group-row row" }, [
    _c("label", [_vm._v("Details")]),
    _c("div", { staticClass: "flex-grow" }, [
      _c("div", {
        staticClass: "preview",
        domProps: { innerHTML: _vm._s(_vm.reward.reward_details) },
      }),
      _c(
        "button",
        {
          staticClass: "btn btn-link",
          attrs: { type: "button", disabled: _vm.disabled },
          on: { click: _vm.edit },
        },
        [_c("i", { staticClass: "icon md-edit" }), _vm._v(" Edit ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import qs from "qs";
import HttpRequest from "./request";

export class EcpECouponProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_WALLET_API_URL}/ecp/api/ecpcampaignreport`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`${queryString}`);
    return data;
  }
  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    // const response = await this.get(`/download${queryString}`);
    // return response;
    return await this.axiosInstance.get(`/download${queryString}`, {
      responseType: "blob",
    });
  }

  async getTaskStatus() {
    const { data } = await this.get(`status_shell`);
    return data;
  }

  async createTask(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`check_shell${queryString}`);
    return data;
  }

  async runTask(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`run_shell${queryString}`);
    return data;
  }

  async cancelTask() {
    const { data } = await this.get(`cancel_shell`);
    return data;
  }

  async downloadLog() {
    const token = localStorage.getItem("accessToken");

    window.open(
      `${process.env.VUE_APP_WALLET_API_URL}/ecp/api/ecpcampaignreport/file_shell?token=${token}`
    );
  }
}

<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <label class="form-control-label">
          <h4>Game Configuration</h4>
        </label>
      </div>

      <div class="row">
        <div class="text-con">
          <label>No. play per day</label>
        </div>

        <div
          v-for="({ name, text }, index) in weekday"
          :key="index"
          class="w80"
        >
          <label>{{ text }}</label>
          <input
            type="number"
            maxlength="3"
            :ref="`${name}`"
            :disabled="disabled"
            v-model="gameConfig[name]"
            class="center form-control"
            @change="update(`${name}`)"
          />
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

const weekday = [
  {
    name: "no_play_mon",
    text: "Monday",
  },
  {
    name: "no_play_tue",
    text: "Tuesday",
  },
  {
    name: "no_play_wed",
    text: "Wednesday",
  },
  {
    name: "no_play_thu",
    text: "Thursday",
  },
  {
    name: "no_play_fri",
    text: "Friday",
  },
  {
    name: "no_play_sat",
    text: "Saturday",
  },
  {
    name: "no_play_sun",
    text: "Sunday",
  },
];

export default {
  props: ["gameConfig"],

  data() {
    return { weekday: weekday, oldConfig: null };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },

  methods: {
    async update(field) {
      try {
        const valid = await this.validate(field);
        if (!valid) return;

        const form = {};
        form[field] = this.gameConfig[field];

        await httpService.put(`/game/${this.gameConfig.id}`, form);

        this.oldConfig[field] = +this.gameConfig[field];

        const day = this.weekday.find((o) => o.name == field);

        this.$emit("updated");
        this.$notify(`No. play on ${day.text} updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Game Instance configuration update error");
      }
    },

    async validate(field) {
      const value = +this.gameConfig[field];

      if (value < 0 || value > 999 || isNaN(value)) {
        await this.$dialogs.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
        this.gameConfig[field] = +this.oldConfig[field];
        return false;
      }

      if (!(Number(value) === value && value % 1 === 0)) {
        await this.$dialogs.alert(
          "กรุณาระบุ​ No. play per day เป็นเลขจำนวนเต็ม"
        );
        this.gameConfig[field] = +this.oldConfig[field];
        return false;
      }

      return true;
    },
  },

  mounted() {
    this.oldConfig = Object.assign({}, this.gameConfig);
  },
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.text-con {
  margin-left: 40px;
  margin-top: 30px;
  margin-right: 40px;
}
.form-control {
  width: 100%;
}
.w80 {
  width: 80px;
  margin-right: 12px;
}
</style>

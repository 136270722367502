<template>
  <div class="container ml-20" v-if="campaign">
    <div class="row">
      <div class="col-6">
        <p d-inline-block width-150px>No.Tries per period</p>
        <input
          width-150px
          type="number"
          min="1"
          max="999"
          @change="update"
          :disabled="!editable"
          ref="no_play"
          v-model.number="campaign.no_play"
          class="form-control mx-10 d-inline"
        />
        times
      </div>
      <div class="col-6">
        Period duration
        <input
          min="1"
          width-150px
          type="number"
          @change="update"
          :disabled="!editable"
          ref="period_duration"
          v-model.number="campaign.period_duration"
          class="form-control mx-10 d-inline"
        />
        days
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { luckyDrawService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    editable() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  mounted() {
    const { no_play, period_duration } = this.campaign;
    this.oldValue = { no_play, period_duration };
  },

  methods: {
    async validate() {
      if (isNaN(+this.campaign.no_play)) {
        await this.$dialogs.alert("Tries per period ต้องเป็นตัวเลขเท่านั้น");
        this.campaign.no_play = this.oldValue.no_play;
        this.$refs.no_play.focus();
        return false;
      }

      if (+this.campaign.no_play < 1) {
        await this.$dialogs.alert("Tries per period ต้องมากกว่า 0");
        this.campaign.no_play = this.oldValue.no_play;
        this.$refs.no_play.focus();
        return false;
      }

      if (+this.campaign.no_play > 999) {
        await this.$dialogs.alert("Tries per period ต้องไม่เกิน 999");
        this.campaign.no_play = this.oldValue.no_play;
        this.$refs.no_play.focus();
        return false;
      }

      if (isNaN(+this.campaign.period_duration)) {
        await this.$dialogs.alert("Period duration ต้องเป็นตัวเลขเท่านั้น");
        this.campaign.period_duration = this.oldValue.period_duration;
        this.$refs.period_duration.focus();
        return false;
      }

      if (+this.campaign.period_duration < 0) {
        await this.$dialogs.alert("Period duration ต้องมากกว่า 0");
        this.campaign.period_duration = this.oldValue.period_duration;
        this.$refs.period_duration.focus();
        return false;
      }

      const duration = dayjs(this.campaign.end_date).diff(
        this.campaign.start_date,
        "days"
      );
      if (+this.campaign.period_duration > duration) {
        await this.$dialogs.alert(
          `Period duration ต้องไม่เกินช่วงเวลา Campaign Duration (${duration} วัน)`
        );
        this.campaign.period_duration = this.oldValue.period_duration;
        this.$refs.period_duration.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.validate();

        if (!formValid) return;

        const { id, no_play, period_duration } = this.campaign;

        const form = {
          period_duration: +period_duration,
          no_play: +no_play,
        };

        await luckyDrawService.updateCampaignInfo(id, form);

        this.oldValue = { period_duration, no_play };

        this.$emit("updated");
        this.$notify(`No.Tries per period Updated.`);
      } catch (error) {
        this.$dialogs.alert(`No.Tries per period update error.`);
      }
    },
  },
};
</script>

<style></style>

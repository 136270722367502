<template>
  <div class="col-md-10" v-if="monthly && status != null">
    <label class="mx-5">
      <h4>Target SKUs (counted toward progress)</h4>
    </label>

    <div class="sku-box-wrapper">
      <div class="sku-box-action">
        <p>Product Codes</p>

        <div class="ryo-switcher">
          <p class="my-0 d-inline">
            RYO
          </p>
          <label class="switch m0">
            <input
              type="checkbox"
              true-value="non_ryo"
              false-value="ryo"
              :disabled="!canChangeProductCodes"
              v-model="monthly.product_code_type"
              @change="updateProductCodeTypes"
            />
            <span class="slider round"></span>
          </label>
          <p class="mx-10 my-0 d-inline">
            Non-RYO
          </p>
        </div>

        <div
          class="wrapper-upload-form"
          v-if="action == 'ready' && status.active == 0"
        >
          <button
            type="button"
            class="btn btn-primary btn-round"
            :disabled="disabled"
            @click="setAction('editing')"
          >
            Edit
          </button>
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="action == 'editing' && status.active == 0"
        >
          <div>
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="handleVerify"
              :disabled="disabled"
            >
              Verify
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="handleCancel"
              :disabled="disabled"
            >
              Cancel
            </button>
          </div>
        </div>

        <div class="wrapper-upload-form" v-else-if="status.active == 1">
          <label class="label-blue" v-if="status.state == 'verify'">
            Verifying change
          </label>

          <label class="label-blue" v-else-if="status.state == 'done-verify'">
            Click to commit change
          </label>

          <div v-if="status.state == 'verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              disabled
            >
              Commit
            </button>
            <button type="button" class="btn btn-round btn-default" disabled>
              Cancel
            </button>
          </div>
          <div v-else-if="status.state == 'done-verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="getResult"
              :disabled="disabled"
            >
              Commit
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="handleCancel"
              :disabled="disabled"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <Selectize
        multiple
        :disabled="!canEdit"
        v-model="skuSelected"
        :settings="selectizeSetting"
      >
        <option v-for="(sku, index) in skus" :key="index" :value="sku">
          {{ sku }}
        </option>
      </Selectize>
    </div>

    <modal name="try-update-sku-modal" :width="900" height="auto">
      <TryUploadUserTargetModal
        :campaign="campaign"
        :result="result"
        @confirm="confirmUpdate"
        name="try-update-sku-modal"
      />
    </modal>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { progressBarService } from "@/api/client";
import TryUploadUserTargetModal from "./TryUploadUserTargetModal.vue";

export default {
  props: ["campaign"],

  components: {
    Selectize,
    TryUploadUserTargetModal,
  },

  data() {
    return {
      skus: [],
      status: null,
      result: null,
      monthly: null,
      skuSelected: [],
      action: "ready",
      pullingId: null,
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        // create: this.add,
      },
    };
  },

  computed: {
    disabled() {
      return ["archived", "end", "aborted"].includes(this.campaign.status);
    },
    canEdit() {
      return (
        this.action == "editing" &&
        !["archived", "end", "aborted"].includes(this.campaign.status)
      );
    },
    canChangeProductCodes() {
      return (
        this.action == "editing" &&
        this.status.active === 0 &&
        !["scheduled", "ongoing", "archived", "end", "aborted"].includes(
          this.campaign.status
        )
      );
    },
  },

  watch: {
    status(n) {
      const { product_code_type } = this.monthly;

      if (
        n.active === 1 &&
        n.type == `sku ${product_code_type}` &&
        n.state == "verify" &&
        this.pullingId === null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == `sku ${product_code_type}` &&
        n.state == "execute" &&
        this.pullingId === null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == `sku ${product_code_type}` &&
        n.state == "done-execute"
      ) {
        this.setAction("ready");
        this.getUploadStatus();
        return;
      }

      if (n.active === 0) {
        this.setAction("ready");
        this.stopPullingStatus();
        return;
      }
    },
  },

  methods: {
    setAction(action) {
      this.action = action;
    },

    async handleCancel() {
      const { product_code_type } = this.monthly;

      if (this.status.active === 1) {
        await progressBarService.tryCancelUpdateWSSKU(
          this.campaign.id,
          "monthly",
          product_code_type
        );
        this.$notify("Cancel success");
        this.getUploadStatus();
      }

      this.setAction("ready");
      this.setSKUSelected();
    },

    async handleVerify() {
      if (
        this.monthly.sku[this.monthly.product_code_type].length ===
          0 &&
        this.skuSelected.length <= 0
      ) {
        return;
      }

      this.campaign.status == "draft"
        ? await this.update()
        : await this.tryUpdate();

      await this.getUploadStatus();
      this.monthly.sku[
        this.monthly.product_code_type
      ] = this.skuSelected;
    },

    async update() {
      try {
        const form = { sku: this.skuSelected };
        const { product_code_type } = this.monthly;

        await progressBarService.updateWSSKU(
          this.campaign.id,
          "monthly",
          form,
          product_code_type
        );

        this.$notify(`Target SKUs Updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async updateProductCodeTypes() {
      try {
        const { id } = this.campaign;

        const { product_code_type } = this.monthly;

        const body = {
          monthly: {
            product_code_type,
          },
        };

        await progressBarService.updateCampaignInfo(id, body);

        this.setSKUSelected();
        await this.getSKUList();

        this.$emit("updated");
        this.$notify(`Progress Update updated`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Progress Update update error.`);
      }
    },

    async tryUpdate() {
      try {
        const form = { sku: this.skuSelected };
        const { product_code_type } = this.monthly;

        const result = await progressBarService.tryUpdateWSSKU(
          this.campaign.id,
          "monthly",
          form,
          product_code_type
        );

        if (result.error) {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        this.getUploadStatus();
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async getResult() {
      try {
        const { product_code_type } = this.monthly;

        this.result = await progressBarService.tryUpdateWSSKUResult(
          this.campaign.id,
          "monthly",
          product_code_type
        );

        this.$modal.show(`try-update-sku-modal`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async confirmUpdate() {
      try {
        const { product_code_type } = this.monthly;

        await progressBarService.tryConfirmUpdateWSSKU(
          this.campaign.id,
          "monthly",
          product_code_type
        );

        this.$notify("Confirm success");
        this.getUploadStatus();
        this.$emit("updated");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getSKUList() {
      if (this.monthly.product_code_type == "ryo") {
        this.skus = await progressBarService.getWSSKURYOList();
      } else if (this.monthly.product_code_type == "non_ryo") {
        this.skus = await progressBarService.getWSSKUNonRYOList();
      }
    },

    async getUploadStatus() {
      this.status = await progressBarService.getUploadStatus(this.campaign.id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 5000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },

    add(sku) {
      return {
        value: sku,
        text: sku,
      };
    },

    setSKUSelected() {
      const { product_code_type } = this.monthly;
      this.skuSelected = this.monthly.sku[product_code_type];
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  async mounted() {
    this.monthly = this.campaign.monthly;

    this.setSKUSelected();
    await this.getSKUList();
  },

  async created() {
    await this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
.wrapper-upload-form {
  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }
}

.sku-box-wrapper {
  padding: 1em;
  border-radius: 0.25rem;
  border: 1px solid #e4eaec;

  p {
    margin-bottom: 0px;
  }
}

.ryo-switcher {
  flex: 1;
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sku-box-action {
  min-height: 65px;
  margin-bottom: 0.5em;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<template>
  <div class="page">
    <div class="page-main">
      <div class="page-header">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <h1 class="page-title">Lot</h1>
          <breadcrumb name="LOT_EDIT" :withLast.sync="lot.name"></breadcrumb>
          <div class="page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">Lot Information - {{ lot.id }}</h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label class="form-control-label"><h5>Name</h5></label>
                      <input
                        :disabled="isCanChangeDisabled"
                        type="text"
                        maxlength="40"
                        ref="lotName"
                        class="form-control"
                        v-model="lot.name"
                        @change="submit('name')"
                        autocomplete="off"
                      />
                      <NameValidation :$v="$v" />
                    </div>
                    <div class="form-group col-md-3">
                      <label class="form-control-label"
                        ><h5>Lot Type</h5></label
                      >
                      <div class="px-15">{{ lot.type | toUpperCase }}</div>
                    </div>
                    <div class="form-group col-md-3">
                      <div class="px-15">
                        <div class="btn-group">
                          <button
                            :disabled="isButtonDisabled"
                            type="button"
                            class="btn btn-primary btn-round dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ lot.status | toUpperCase }}
                          </button>
                          <div class="dropdown-menu" role="menu">
                            <a
                              v-if="lot.status == 'draft'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('published')"
                              ><i
                                class="icon md-long-arrow-up"
                                aria-hidden="true"
                              ></i
                              >Publish</a
                            >

                            <a
                              v-if="
                                lot.status == 'scheduled' ||
                                lot.status == 'posted'
                              "
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('draft')"
                            >
                              <i
                                class="icon md-long-arrow-down"
                                aria-hidden="true"
                              ></i
                              >Unpublished
                            </a>

                            <a
                              v-if="lot.status == 'ongoing'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('aborted')"
                              ><i
                                class="icon md-border-color"
                                aria-hidden="true"
                              ></i
                              >Abort</a
                            >
                            <a
                              v-if="lot.status == 'draft'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('archived')"
                              ><i class="icon md-archive" aria-hidden="true"></i
                              >Archive</a
                            >
                            <a
                              v-if="lot.status == 'archived'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('draft')"
                            >
                              <i
                                class="icon md-long-arrow-down"
                                aria-hidden="true"
                              ></i
                              >Unarchive
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">Description (Option)</h5>
                    <div class="col-md-12">
                      <ckeditor
                        :disabled="isEditorDisabled"
                        v-model="lot.description"
                        @blur="submit('description')"
                      ></ckeditor>
                    </div>
                  </div>
                  <hr />

                  <div class="form-group row">
                    <h5 class="col-md-12">
                      Lot Picture (Option) recommended size 600 x 600 pixel /
                      less than 1 MB
                    </h5>
                    <figure
                      class="overlay overlay-hover img-rounded img-bordered"
                      style="min-height: 250px;"
                    >
                      <img
                        :src="lot.image"
                        class="rounded mx-auto d-block"
                        style="max-height: 250px;"
                      />

                      <figcaption
                        class="overlay-panel overlay-background overlay-fade overlay-icon"
                      >
                        <button
                          :disabled="isPicDisabled"
                          type="button"
                          class="btn btn-outline btn-inverse vertical-align-middle"
                          @click="changeImage(`image`)"
                        >
                          Add / Change Image
                        </button>
                        <button
                          :disabled="isPicDisabled"
                          type="button"
                          class="btn btn-outline btn-inverse vertical-align-middle mx-10"
                          @click="confirmRemove('image', $event)"
                        >
                          Remove Image
                        </button>
                      </figcaption>
                    </figure>
                    <hr />
                  </div>

                  <hr />
                  <div class="row">
                    <div class="form-group col-md-9">
                      <label class="form-control-label"
                        ><h5>Lot Product Item</h5></label
                      >
                      <select
                        :disabled="isCanChangeDisabled"
                        class="form-control col-md-9"
                        ref="productSelected"
                        v-model="lot.product_id"
                        @change="submit('product_id')"
                      >
                        <option value="">Please select one</option>
                        <option
                          v-for="product in products"
                          :key="product.id"
                          :value="product.id"
                        >
                          {{ ` ${product.id} - ${product.name}` }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-3">
                      <label class="form-control-label"
                        ><h5>No. Prize</h5></label
                      >
                      <input
                        :disabled="disabledNoPrize"
                        type="number"
                        min="0"
                        max="1000000"
                        step="1"
                        class="form-control"
                        v-model="lot.no_prize"
                        ref="lotNoPrize"
                        @blur="submit('no_prize')"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="form-group row">
                    <h5 class="col-md-12">Terms & Condition</h5>
                    <div class="col-md-12">
                      <ckeditor
                        :disabled="isEditorDisabled"
                        v-model="lot.term_condition"
                        @blur="submit('term_condition')"
                      ></ckeditor>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">User Groups</h5>
                    <div class="col-md-12">
                      <Selectize
                        :disabled="isCanChangeDisabled"
                        multiple
                        ref="groupCode"
                        v-model="lot.group_id_list"
                        :settings="groupSelectizeSetting"
                        @change="chagevalue = true"
                      >
                        <option
                          v-for="group in groups"
                          :key="group.id"
                          :value="group.id"
                        >
                          {{ ` ${group.id} - ${group.group_code}` }}
                        </option>
                      </Selectize>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">Required Points</h5>
                    <label class="col-md-2 form-control-label">Starting </label>
                    <input
                      :disabled="isNumberDisabled"
                      type="text"
                      placeholder="n/a"
                      class="col-md-2 form-control"
                      autocomplete="off"
                      ref="minimumPoints"
                      v-model="lot.minimum_points"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      @change="submit('minimum_points')"
                    />
                    <label class="col-md-0 form-control-label"> points </label>

                    <label class="col-md-2 form-control-label">Maximum</label>

                    <input
                      :disabled="isNumberDisabled"
                      v-if="
                      (lot.type == 'auction')"
                      type="text"
                      placeholder="n/a"
                      class="form-control col-md-2"
                      autocomplete="off"
                      ref="maximumPoints"
                      v-model="lot.maximum_points"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      @change="submit('maximum_points')"
                    />
                    <input
                      :disabled="isNumberDisabled"
                      v-if="lot.type == 'luckydraw'"
                      type="number"
                      placeholder="n/a"
                      class="form-control col-md-2"
                      autocomplete="off"
                      readonly
                    />

                    <label class="col-md-0 form-control-label"> points </label>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 form-control-label"
                      >Min. increment
                    </label>
                    <input
                      v-if="
                      (lot.type == 'auction')"
                      :disabled="isNumberDisabled"
                      ref="minIncrementPoints"
                      type="text"
                      placeholder="n/a"
                      class="col-md-2 form-control"
                      autocomplete="off"
                      v-model="lot.minimum_increment"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      @change="submit('minimum_increment')"
                    />
                    <input
                      v-if="
                        (lot.maximum_points == null && lot.type == 'luckydraw')
                      "
                      :disabled="isNumberDisabled"
                      type="number"
                      placeholder="n/a"
                      class="form-control col-md-2"
                      autocomplete="off"
                      readonly
                    />

                    <label class="col-md-0 form-control-label"> points </label>

                    <label class="col-md-2 form-control-label"
                      >Max no.entries</label
                    >

                    <input
                      v-if="lot.type == 'auction'"
                      :disabled="isNumberDisabled"
                      type="text"
                      placeholder="n/a"
                      class="form-control col-md-2"
                      value=""
                      autocomplete="off"
                      readonly
                    />
                    <input
                      v-if="
                      (lot.type == 'luckydraw')"
                      :disabled="isNumberDisabled"
                      ref="maxNoEntries"
                      type="text"
                      placeholder="n/a"
                      class="col-md-2 form-control"
                      autocomplete="off"
                      v-model="lot.max_no_entries"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      @change="submit('max_no_entries')"
                    />
                    <label class="col-md-0 form-control-label"> times </label>
                  </div>
                  <hr />

                  <div class="form-group row">
                    <h5 class="col-md-12">Start-End Date</h5>
                    <label class="col-md-2 form-control-label">From</label>
                    <DatePicker
                      :disabled="isDateDisabled"
                      type="datetime"
                      ref="startDate"
                      class="col-md-3 px-0"
                      v-model="lot.start_date"
                      format="DD/MM/YYYY HH:mm"
                      value-type="YYYY-MM-DD HH:mm"
                      :confirm="true"
                      @change="updatePostDate"
                    />
                    <label class="col-md-1 form-control-label">To</label>
                    <DatePicker
                      :disabled="isDateDisabled"
                      type="datetime"
                      ref="endDate"
                      class="col-md-3 px-0"
                      v-model="lot.end_date"
                      format="DD/MM/YYYY HH:mm"
                      value-type="YYYY-MM-DD HH:mm"
                      :confirm="true"
                      @change="updateDelistDate"
                    />
                  </div>
                  <hr />

                  <div class="form-group row">
                    <h5 class="col-md-12">Post Date</h5>
                    <label class="col-md-2 form-control-label"
                      >Before start date</label
                    >
                    <input
                      :disabled="isDateDisabled"
                      min="0"
                      max="365"
                      step="1"
                      type="number"
                      ref="dayPostDate"
                      class="form-control col-md-1"
                      v-model.number="postDate.days"
                      @change="updatePostDate"
                      autocomplete="off"
                    />
                    <label class="col-md-0 form-control-label">days</label>
                    <select
                      :disabled="isDateDisabled"
                      class="form-control col-md-1"
                      v-model="postDate.hours"
                      @change="updatePostDate"
                    >
                      <option v-for="n in hourNums" :key="n" :value="n">{{
                        getTimeValue(n)
                      }}</option>
                    </select>
                    <label class="col-md-0 form-control-label">hours</label>
                    <select
                      :disabled="isDateDisabled"
                      class="form-control col-md-1"
                      v-model="postDate.minutes"
                      @change="updatePostDate"
                    >
                      <option v-for="n in minuteNums" :key="n" :value="n">{{
                        getTimeValue(n)
                      }}</option>
                    </select>
                    <label class="col-md-0 form-control-label">minutes</label>
                    <label class="col-md-3 form-control-label">{{
                      postDateCal
                    }}</label>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">
                      Delist Date
                    </h5>
                    <label class="col-md-2 form-control-label"
                      >After end date</label
                    >
                    <input
                      :disabled="isDateDisabled"
                      min="0"
                      max="365"
                      step="1"
                      type="number"
                      class="form-control col-md-1"
                      v-model.number="delistDate.days"
                      @change="updateDelistDate"
                      autocomplete="off"
                    />
                    <label class="col-md-0 form-control-label">days</label>
                    <select
                      :disabled="isDateDisabled"
                      class="form-control col-md-1"
                      v-model="delistDate.hours"
                      @change="updateDelistDate"
                    >
                      <option v-for="n in hourNums" :key="n" :value="n">{{
                        getTimeValue(n)
                      }}</option>
                    </select>
                    <label class="col-md-0 form-control-label">hours</label>
                    <select
                      :disabled="isDateDisabled"
                      class="form-control col-md-1"
                      v-model="delistDate.minutes"
                      @change="updateDelistDate"
                    >
                      <option v-for="n in minuteNums" :key="n" :value="n">{{
                        getTimeValue(n)
                      }}</option>
                    </select>
                    <label class="col-md-0 form-control-label">minutes</label>
                    <label class="col-md-3 form-control-label">
                      {{ delistDateCal }}
                    </label>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-12">
                      <h5 class="label">LINE Notification</h5>
                    </div>
                    <WinnerNotificationSettings
                      :lot="lot"
                      @updated="fetchLot"
                    />
                    <EndCampaignNotificationSettings
                      :lot="lot"
                      @updated="fetchLot"
                    />
                    <OutBidNotificationSettings
                      :lot="lot"
                      @updated="fetchLot"
                    />
                    <div class="col-12">
                      <hr />
                    </div>
                    <LUMGroupSetting :lot="lot" @updated="fetchLot" />
                  </div>
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal name="upload-image-modal" :width="500" :height="545">
      <BaseCropUpload @onCrop="onCrop" @close="closeUploadModal" />
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { Money } from "v-money";
import Selectize from "vue2-selectize";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";
import "selectize/dist/css/selectize.bootstrap3.css";

import {
  lotService,
  uploadService,
  productService,
  userGroupService,
} from "@/api/client";

import NameValidation from "./validation/name";
import { responseErrorMapping } from "./model/error";
import { FormCreateValidateRule as validateRules } from "./validation";

import LUMGroupSetting from "@/views/lot/components/LUMGroupSetting.vue";
import WinnerNotificationSettings from "@/views/lot/components/WinnerNotificationSettings.vue";
import OutBidNotificationSettings from "@/views/lot/components/OutBidNotificationSettings.vue";
import EndCampaignNotificationSettings from "@/views/lot/components/EndCampaignNotificationSettings.vue";

export default {
  name: "LotEdit",

  validations: { lot: validateRules },

  components: {
    Money,
    Selectize,
    DatePicker,
    NameValidation,
    LUMGroupSetting,
    WinnerNotificationSettings,
    OutBidNotificationSettings,
    EndCampaignNotificationSettings,
  },

  data() {
    return {
      firstTimeTermsChange: true,
      firstTimeDescriptionChange: true,
      id: "",
      status: "",
      lot: {
        start_date: "",
        end_date: "",
      },
      lotOld: {},
      groups: [],
      product: {},
      products: [],
      groupSelectizeSetting: {
        selected: [],
        onChange: this.setGroupIdList,
      },
      postDate: { days: null, hours: null, minutes: null },
      delistDate: { days: null, hours: null, minutes: null },

      postDateCal: null,
      delistDateCal: null,

      //Datepiker
      isDateDisabled: false,
      //description &&Terms
      isEditorDisabled: false,
      //inputCanChangeOngoingpScheduledPosted
      isCanChangeDisabled: false,
      //PointAll
      isNumberDisabled: false,
      //picture
      isPicDisabled: false,
      isButtonDisabled: false,

      disabledNoPrize: false,

      settingUsergroup: {},
      hourNums: _.range(0, 24, 1),
      minuteNums: _.range(0, 60, 1),
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      onChangeImageId: null,
      editorConfig: {
        isReadOnly: true,
      },
    };
  },

  watch: {
    lot(newLot) {
      this.groupSelectizeSetting.selected = newLot.group_id_list;
    },
  },

  async mounted() {
    this.id = this.$route.params.id;
    await this.fetchLot();

    this.product = await productService.getById(this.lot.product_id);
    this.groups = await userGroupService.getUserGroups();
    this.lotOld = await lotService.getById(this.id);

    this.products = await productService.getProducts();
    this.products = this.products.filter((p) => p.status == "published");

    this.initPostDate();
    this.initDelistDate();
  },

  methods: {
    async fetchLot() {
      const lot = await lotService.getById(this.id);
      this.lot = this.setInitialValue(lot);
      this.lotOld = await lotService.getById(this.id);

      const d1 = moment(this.lot.post_date);
      this.postDateCal = d1.format("DD/MM/YYYY, HH:mm");
      const d2 = moment(this.lot.delist_date);
      this.delistDateCal = d2.format("DD/MM/YYYY, HH:mm");

      //แก้ไขได้ทั้งหมด
      if (this.lot.status == "draft") {
        this.isDateDisabled = false;
        this.isEditorDisabled = false;
        this.isCanChangeDisabled = false;
        this.isNumberDisabled = false;
        this.isPicDisabled = false;
        this.isButtonDisabled = false;
        this.disabledNoPrize = false;
      }

      //แก้ไขได้บ้างส่วน
      if (this.lot.status == "scheduled" || this.lot.status == "posted") {
        this.isDateDisabled = true;
        this.isEditorDisabled = false;
        this.isCanChangeDisabled = false;
        this.isNumberDisabled = true;
        this.isPicDisabled = false;
        this.isButtonDisabled = false;
        this.disabledNoPrize = false;
      }
      if (this.lot.status == "ongoing") {
        this.isDateDisabled = true;
        this.isEditorDisabled = false;
        this.isCanChangeDisabled = false;
        this.isNumberDisabled = true;
        this.isPicDisabled = false;
        this.isButtonDisabled = false;
        this.disabledNoPrize = true;
      }

      //ห้ามแก้ไขอะไรเลย
      if (
        this.lot.status == "aborted" ||
        this.lot.status == "end" ||
        this.lot.status == "delisted"
      ) {
        this.isDateDisabled = true;
        this.isEditorDisabled = true;
        this.isCanChangeDisabled = true;
        this.isNumberDisabled = true;
        this.isPicDisabled = true;
        this.isButtonDisabled = true;
        this.disabledNoPrize = true;
      }
    },

    setInitialValue(lot) {
      lot.description = lot.description || "";
      lot.term_condition = lot.term_condition || "";

      return lot;
    },

    updatePostDate() {
      const d = moment(this.lot.start_date);
      d.subtract(this.postDate.days, "days");
      d.subtract(this.postDate.hours, "hours");
      d.subtract(this.postDate.minutes, "minutes");
      this.lot.post_date = d.format("YYYY-MM-DD HH:mm:00");
      this.postDateCal = d.format("DD/MM/YYYY, HH:mm");
      this.submit("start_date");
      this.submit("post_date");
    },

    updateDelistDate() {
      const d = moment(this.lot.end_date);
      d.add(this.delistDate.days, "days");
      d.add(this.delistDate.hours, "hours");
      d.add(this.delistDate.minutes, "minutes");
      this.lot.delist_date = d.format("YYYY-MM-DD HH:mm:00");
      this.delistDateCal = d.format("DD/MM/YYYY, HH:mm");
      this.submit("end_date");
      this.submit("delist_date");
    },

    initDelistDate() {
      const diffDays = moment(this.lot.delist_date).diff(
        this.lot.end_date,
        "days"
      );
      const diffHours = moment(this.lot.delist_date).diff(
        this.lot.end_date,
        "hours"
      );
      const diffMinutes = moment(this.lot.delist_date).diff(
        this.lot.end_date,
        "minutes"
      );

      this.delistDate.days = diffDays;
      this.delistDate.hours = diffHours % 24 || 0;
      this.delistDate.minutes = diffMinutes % diffHours || 0;
    },
    initPostDate() {
      const diffDays = moment(this.lot.start_date).diff(
        this.lot.post_date,
        "days"
      );
      const diffHours = moment(this.lot.start_date).diff(
        this.lot.post_date,
        "hours"
      );
      const diffMinutes = moment(this.lot.start_date).diff(
        this.lot.post_date,
        "minutes"
      );

      this.postDate.days = diffDays;
      this.postDate.hours = diffHours % 24 || 0;
      this.postDate.minutes = diffMinutes % diffHours || 0;
    },
    getTimeValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    goBack() {
      this.$router.go(-1);
    },

    isValid(field) {
      switch (field) {
        case "name":
          if (this.lot.name == "") {
            this.$dialogs.alert("ค่า Lot name เป็นค่าว่างไม่ได้");
            this.lot.name = this.lotOld.name;
            this.$refs.lotName.focus();
            return false;
          }
          break;

        case "no_prize":
          if (this.lot.type == "auction") {
            if (this.lot.no_prize < 1) {
              this.lot.no_prize = this.lotOld.no_prize || 1;
              this.$dialogs.alert(
                "Lot Type : " +
                  this.lot.type.toUpperCase() +
                  " ค่า No Prize ต้องเท่ากับ 1 ขึ้นไป"
              );
              this.$refs.lotNoPrize.focus();
              return false;
            }
          }

          if (this.lot.type == "luckydraw") {
            if (this.lot.no_prize < 1 || this.lot.no_prize > 1000000) {
              this.$dialogs.alert(
                "Lot Type : " +
                  this.lot.type.toUpperCase() +
                  " ค่า No Prize เป็น 1 - 1,000,000 ได้เท่านั้น"
              );
              this.lot.no_prize = this.lotOld.no_prize;
              this.$refs.lotNoPrize.focus();
              return false;
            }
          }
          break;

        case "description":
          if (this.firstTimeDescriptionChange) {
            this.firstTimeDescriptionChange = false;
            return false;
          }
          break;

        case "term_condition":
          if (this.firstTimeTermsChange) {
            this.firstTimeTermsChange = false;
            return false;
          }
          break;

        case "product_id":
          if (this.lot.product_id == "" && this.lot.status == "scheduled") {
            this.$dialogs.alert("Lot product item เป็นค่าว่างไม่ได้");
            this.lot.product_id = this.lotOld.product_id;
            this.$refs.productSelected.focus();
            return false;
          } else {
            return true;
          }
          break;

        case "minimum_increment":
          if (this.lot.type == "auction") {
            if (this.lot.maximum_points == null) {
              if (
                this.lot.minimum_increment < 1 ||
                this.lot.minimum_increment > 1000000
              ) {
                this.$dialogs.alert(
                  " ค่า Minimum Increment เป็น 1 - 1,000,000 ได้เท่านั้น"
                );
                this.lot.minimum_increment = this.lotOld.minimum_increment || 1;
                this.$refs.minIncrementPoints.focus();
                return false;
              }
            } else {
              let rangePoints =
                this.lot.maximum_points - this.lot.minimum_points;
              if (
                this.lot.minimum_increment < 1 ||
                this.lot.minimum_increment > rangePoints
              ) {
                this.$dialogs.alert(
                  " ค่า Minimum Increment เป็น 1 - " +
                    rangePoints +
                    " ได้เท่านั้น"
                );
                this.lot.minimum_increment = this.lotOld.minimum_increment || 1;
                this.$refs.minIncrementPoints.focus();
                return false;
              }
            }
          }

          if (this.lot.type == "luckydraw") {
            if (
              this.lot.minimum_increment < 0 ||
              this.lot.minimum_increment > 0
            ) {
              this.$dialogs.alert(
                "Lot Type : " +
                  this.lot.type.toUpperCase() +
                  " ไม่สามารถกำหนดค่า Minimum Increment ได้ "
              );
              this.lot.minimum_increment = null;
              this.$refs.minIncrementPoints.focus();
              return false;
            }
          }

          break;

        case "minimum_points":
          if (
            this.lot.minimum_points < 1 ||
            this.lot.minimum_points > 1000000
          ) {
            this.$dialogs.alert(
              " ค่า Minimum points เป็น 1 - 1,000,000 ได้เท่านั้น"
            );
            this.lot.minimum_points = this.lotOld.minimum_points || 1;
            this.$refs.minimumPoints.focus();
            return false;
          }

          if (this.lot.type == "auction") {
            if (this.lot.maximum_points == null) {
              return true;
            } else {
              if (this.lot.minimum_points >= this.lot.maximum_points) {
                this.$dialogs.alert(
                  "Lot Type : " +
                    this.lot.type.toUpperCase() +
                    " Minimum Points จะมากกว่าหรือเท่ากับ Maximum Points ไม่ได้ "
                );
                this.lot.minimum_points = this.lotOld.minimum_points;
                this.$refs.minimumPoints.focus();
                return false;
              }
            }
          }

          break;

        case "maximum_points":
          if (this.lot.type == "auction") {
            if (
              this.lot.maximum_points == "" ||
              this.lot.maximum_points > 0 ||
              this.lot.maximum_points == null
            ) {
              if (this.lot.maximum_points == "") {
                this.$dialogs.alert(
                  "การไม่กำหนดค่าใน Maximum Points จะทำให้มีค่าเป็น Infinity "
                );
                this.lot.maximum_points = null;
                return true;
              }

              if (this.lot.maximum_points == null) {
                return true;
              }

              if (
                this.lot.maximum_points < 1 ||
                this.lot.maximum_points > 1000000
              ) {
                this.$dialogs.alert(
                  " ค่า Maximum points เป็น 1 - 1,000,000 ได้เท่านั้น"
                );
                this.lot.maximum_points = this.lotOld.maximum_points || 1;
                this.$refs.maximumPoints.focus();
                return false;
              }

              if (this.lot.maximum_points <= this.lot.minimum_points) {
                this.$dialogs.alert(
                  "Lot Type : " +
                    this.lot.type.toUpperCase() +
                    " Maximum Points จะน้อยกว่าหรือเท่ากับ Minimum Points ไม่ได้ "
                );
                this.lot.maximum_points = this.lotOld.maximum_points;
                this.$refs.maximumPoints.focus();
                return false;
              }
            } else {
              this.$dialogs.alert(
                "ค่า Maximum points เป็น 1 - 1,000,000 หรือค่าว่าง ได้เท่านั้น"
              );
              this.lot.maximum_points = this.lotOld.maximum_points;
              this.$refs.maximumPoints.focus();
              return false;
            }
          }

          if (this.lot.type == "luckydraw") {
            if (this.lot.maximum_points < 0 || this.lot.maximum_points > 0) {
              this.$dialogs.alert(
                "Lot Type : " +
                  this.lot.type.toUpperCase() +
                  " ไม่สามารถกำหนดค่า Maximum Points ได้ "
              );
              this.lot.maximum_points = null;
              this.$refs.maximumPoints.focus();
              return false;
            }
          }
          break;

        case "max_no_entries":
          if (this.lot.type == "auction") {
            this.$dialogs.alert(
              "Lot Type : " +
                this.lot.type.toUpperCase() +
                " จะไม่สามารถใส่ค่า Max no entries ได้"
            );
            this.lot.max_no_entries = null;
            this.$refs.maxNoEntries.focus();
            return false;
          }

          if (this.lot.type == "luckydraw") {
            if (this.lot.max_no_entries == "" || this.lot.max_no_entries == 0) {
              this.lot.max_no_entries = null;
              return true;
            } else {
              if (
                this.lot.max_no_entries < 1 ||
                this.lot.max_no_entries > 1000000
              ) {
                this.$dialogs.alert(
                  " ค่า Max no entries เป็น 1 - 1,000,000 ได้เท่านั้น"
                );

                if (this.lotOld.max_no_entries == null) {
                  this.lot.max_no_entries = null;
                  this.$refs.maxNoEntries.focus();
                  return false;
                } else {
                  this.lot.max_no_entries = this.lotOld.max_no_entries;
                  this.$refs.maxNoEntries.focus();
                  return false;
                }
              }
            }
          }
          break;

        case "start_date":
          const startDate = moment(this.lot.start_date);
          const startIsPast = startDate.diff(new Date()) < 0;
          if (startIsPast) {
            this.$dialogs.alert("Start Date ต้องเป็นเวลาในอนาคต");
            this.lot.start_date = this.lotOld.start_date || null;
            this.$refs.startDate.focus();
            return false;
          }
          break;

        case "end_date":
          const endDate = moment(this.lot.end_date);
          const endIsPast = endDate.diff(this.lot.start_date) < 0;
          if (endIsPast) {
            this.$dialogs.alert(
              "End Date ต้องเป็นเวลาหลักจาก Start Date แล้วเท่านั้น"
            );
            this.lot.end_date = this.lotOld.end_date || null;
            this.$refs.endDate.focus();
            return false;
          }
          break;

        default:
          if (!this.lot[field]) return false;
          break;
      }

      return true;
    },

    isScheduled(field) {
      switch (field) {
        case "product_id":
          this.$dialogs.alert(
            "ไม่สามารถแก้ไขได้เนื่องจาก Lot มีสถานะเป็น SCHEDULED"
          );
          this.lot.product_id = this.lotOld.product_id;
          this.$refs.productSelected.focus();
          return false;
          break;

        case "start_date":
          this.$dialogs.alert(
            "ไม่สามารถแก้ไขได้เนื่องจาก Lot มีสถานะเป็น SCHEDULED"
          );
          this.lot.start_date = this.lotOld.start_date;
          this.$refs.startDate.focus();
          return false;
          break;

        case "end_date":
          this.$dialogs.alert(
            "ไม่สามารถแก้ไขได้เนื่องจาก Lot มีสถานะเป็น SCHEDULED"
          );
          this.lot.end_date = this.lotOld.end_date;
          this.$refs.endDate.focus();
          return false;
          break;

        default:
          if (!this.lot[field]) return false;
          break;
      }

      return true;
    },

    async submit(field) {
      try {
        if (this.lot.status == "scheduled") {
          if (!this.isScheduled(field)) return;
        }

        if (!this.isValid(field)) return;

        const form = {};
        form[field] = this.lot[field];
        await lotService.editLot(this.lot.id, form);

        this.$notify(
          `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
            /_/gi,
            " "
          ) + " updated."
        );
        await this.fetchLot();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify(
              `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
                /_/gi,
                " "
              ) + " update error"
            );
        await this.fetchLot();
      }
    },

    async setGroupIdList(ids) {
      try {
        await lotService.editLot(this.lot.id, { group_id_list: ids });
        this.$notify("Updated");
      } catch (error) {
        this.$notify("Update error");
      }
    },

    confirmRemove(imageId) {
      const r = confirm("Do you really want to delete?");
      if (r == true) {
        this.removeImage(imageId);
      }
    },

    async removeImage(imageId) {
      try {
        let body = {};
        body[imageId] = null;
        await lotService.editLot(this.lot.id, body);
        await this.fetchLot();
        this.$notify("Updated");
      } catch (error) {
        this.$notify("delete image error");
      }
    },

    displayGroupCodeError(messages) {
      this.$modal.show("dialog", {
        title: "คำเตือน!",
        text: messages,
        buttons: [
          {
            title: "Close",
          },
        ],
      });
    },

    async isFormValidScheduled() {
      if (this.lot.name == "") {
        await this.$dialogs.alert("กรุณาใส่ค่าใน Lot Name");
        this.lot.name = this.lotOld.name;
        this.$refs.lotName.focus();
        return false;
      }
      if (this.lot.product_id == "") {
        this.$dialogs.alert("Lot product item เป็นค่าว่างไม่ได้");
        this.lot.product_id = this.lotOld.product_id;
        this.$refs.productSelected.focus();
        return false;
      }

      if (this.product.status != "published") {
        await this.$dialogs.alert(
          `Product Item : ${this.product.name} ไม่ได้อยู่ในสถานะ PUBLISHED`
        );
        this.$refs.productSelected.focus();
        return false;
      }

      const countInactiveGroup = this.lot.group_list.filter(
        (g) => g.status == "inactive"
      );
      if (countInactiveGroup.length > 0) {
        const messages = this.lot.group_list.reduce((message, group) => {
          return (
            group.status == "inactive" &&
            (message += ` User Group ${group.group_code} ไม่ได้อยู่ในสถานะ ACTIVE<br/>`)
          );
        }, ``);

        this.displayGroupCodeError(messages);
        return false;
      }

      const startDate = moment(this.lot.start_date);
      const isPast = startDate.diff(new Date().setHours("HH", "mm", 0, 0)) < 0;
      if (isPast) {
        await this.$dialogs.alert("Start Date ต้องเป็นเวลาในอนาคต");
        this.$refs.startDate.focus();
        return false;
      }

      return true;
    },

    async setStatus(status) {
      try {
        let changePage = "";

        if (status == "published") {
          this.status = "scheduled";
          changePage = false;
          const formValid = await this.isFormValidScheduled();
          if (!formValid) {
            return;
          }
        } else {
          this.status = status;
          changePage = true;
        }

        const form = new FormData();
        form.set("status", this.status);

        const body = JSON.stringify(Object.fromEntries(form));
        await lotService.editLot(this.lot.id, JSON.parse(body));

        this.$dialogs.alert("Change status success");
        if (changePage == true) {
          this.goBack();
        } else {
          await this.fetchLot();
        }
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify(error);
      }
    },

    openUploadModal() {
      this.$modal.show(`upload-image-modal`);
    },

    closeUploadModal() {
      this.$modal.hide("upload-image-modal");
    },

    async onCrop(image) {
      this.$modal.hide("upload-image-modal");
      if (!image) return;

      if (this.onChangeImageId) {
        await this.upload(this.onChangeImageId, image);
        this.onChangeImageId = null;
        this.lot = await lotService.getById(this.$route.params.id);
        return;
      } else {
        const imageId = `image`;
        await this.upload(imageId, image);
        this.lot = await lotService.getById(this.$route.params.id);
      }
    },

    async upload(imageId, image) {
      try {
        if (!image) return;

        let form = new FormData();
        form.append(imageId, image);

        const result = await uploadService.upload(
          `/ams/api/lot/${this.lot.id}/upload`,
          form
        );

        this.$notify("Updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    changeImage(imageId) {
      if (this.lot.status == "end" || this.lot.status == "delisted") {
        this.$dialogs.alert("ไม่สามารถแก้ไขข้อมูลได้");
      } else {
        this.onChangeImageId = imageId;
        this.openUploadModal();
      }
    },
  },
};
</script>

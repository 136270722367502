import qs from "qs";
import HttpRequest from "./request";

export class LotProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/lot`);
  }

  async getLots(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createLot(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editLot(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async uploadImage(id, body) {
    const { data } = await this.upload(`/${id}/upload`, body);
    return data;
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-10 mb-15" }, [
    _c("p", { staticClass: "mt-5 mb-15" }, [_vm._v("SKU Discount")]),
    _vm.item.sku_id_list
      ? _c(
          "div",
          [
            _vm._l(_vm.list, function (ref, index) {
              var sku_id = ref.sku_id
              var discount = ref.discount
              var sku_cp_id = ref.sku_cp_id
              return _c("div", { key: sku_id }, [
                _c("table", { staticClass: "table" }, [
                  _c("tr", [
                    _c("td", [
                      _c("span", { staticClass: "d-flex align-items-center" }, [
                        _vm._v(" No. " + _vm._s(index + 1) + " "),
                      ]),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("span", { staticClass: "px-10" }, [_vm._v("name")]),
                        _c(
                          "select",
                          {
                            staticClass: "form-control",
                            on: {
                              input: function ($event) {
                                return _vm.updateSkuId(sku_cp_id, discount)
                              },
                            },
                          },
                          _vm._l(_vm.SKUMaster, function (master) {
                            return _c(
                              "option",
                              {
                                key: master.sku_id,
                                attrs: { disabled: _vm.isUsed(master.sku_id) },
                                domProps: {
                                  value: master.sku_id,
                                  selected: master.sku_id == sku_id,
                                },
                              },
                              [_vm._v(" " + _vm._s(master.sku_name) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("span", { staticClass: "px-10" }, [
                          _vm._v("Max discount per unit"),
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            min: "1",
                            "width-100px": "",
                            type: "number",
                          },
                          domProps: { value: discount },
                          on: {
                            input: function ($event) {
                              return _vm.updateDiscount(sku_cp_id, sku_id)
                            },
                          },
                        }),
                        _c("span", { staticClass: "px-10" }, [_vm._v("Bht.")]),
                      ]),
                    ]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.removeSkuDiscount(sku_id)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon md-delete",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            _c("div", { staticClass: "action" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.item.sku_id_list.length >= _vm.SKUMaster.length,
                  },
                  on: { click: _vm.addSKU },
                },
                [
                  _c("i", {
                    staticClass: "icon md-plus",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
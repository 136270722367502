var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "col-md-10" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _c("TargetAndRewardRow", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mx-5" }, [
      _c("div", { staticClass: "d-flex" }, [
        _c("h4", [
          _vm._v(
            " Targets and Rewards (targets are cumulative, rewards are incremental) "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="pool-status-info" class="container-fluid" v-if="info">
    <div class="row">
      <div class="col-12">
        <div class="top-row">
          <h4>Pool Status - {{ info.id }}</h4>
          <div class="status-type">
            <h4>Status - {{ info.status | capitalize }}</h4>
            <h4>Type - {{ info.type | capitalize }}</h4>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="info-row">
          <label>Vendor</label>
          <div>{{ info.vendor }}</div>
        </div>
        <div class="info-row">
          <label>Prize Name</label>
          <div>{{ info.prize_name }}</div>
        </div>
        <div class="info-row">
          <label>Prize Details</label>
          <div prize-detail v-html="info.description"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="counter-info">
          <div class="info-column">
            <label>Total</label>
            <div>{{ info.count_total | numberWithCommas }}</div>
          </div>
          <div class="info-column">
            <label>Used</label>
            <div>{{ info.count_used | numberWithCommas }}</div>
          </div>
          <div class="info-column">
            <label>Unusable</label>
            <div>{{ info.count_unusable | numberWithCommas }}</div>
          </div>
          <div class="info-column">
            <label class="green">Available</label>
            <div class="green">
              {{ info.count_available | numberWithCommas }}
            </div>
          </div>
          <div class="info-column">
            <label>Reserved</label>
            <div>{{ info.count_reserved | numberWithCommas }}</div>
          </div>
          <div class="info-column">
            <label>Margin</label>
            <div>{{ info.count_margin | numberWithCommas }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info"],
};
</script>

<style lang="scss" scoped>
#pool-status-info {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;

  .top-row {
    display: flex;
    justify-content: space-between;
  }

  .info-row {
    gap: 1rem;
    display: flex;
    margin-left: 1rem;

    label {
      color: #444;
      width: 100px;
      font-weight: bold;
    }

    [prize-detail] {
      width: 560px;
      height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .counter-info {
    gap: 3rem;
    display: flex;
    margin-top: 1.5rem;
  }

  .info-column {
    gap: 0.5rem;
    display: flex;
    margin-left: 1rem;

    label {
      color: #444;
      font-weight: bold;
    }

    .green {
      color: #4caf50;
    }
  }

  .status-type {
    max-width: 200;

    gap: 2rem;
    display: flex;
    justify-content: flex-start;
  }
}
</style>

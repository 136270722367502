var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm._m(0),
          _vm._l(_vm.campaign.prize, function (prize) {
            return _c("div", { key: prize.prize_order, staticClass: "ml-20" }, [
              _c(
                "table",
                { staticClass: "table table-responsive table-borderless" },
                [
                  _c("tbody", [
                    prize.prize_order === 11
                      ? _c("tr", [_vm._m(1, true)])
                      : _vm._e(),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              prize.prize_order !== 11
                                ? "No. " + prize.prize_order
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [_vm._v("Lucky Number")]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: prize.lucky_num,
                              expression: "prize.lucky_num",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            min: "0",
                            max: "999",
                            type: "number",
                            disabled: !_vm.canEdit,
                          },
                          domProps: { value: prize.lucky_num },
                          on: {
                            change: function ($event) {
                              return _vm.update("lucky_num", prize)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                prize,
                                "lucky_num",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("div", { staticClass: "reward-choice" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: prize.lucky_onoff,
                                expression: "prize.lucky_onoff",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              value: "0",
                              type: "radio",
                              disabled: !_vm.canEdit,
                              name: "reward-choice-" + prize.prize_order,
                              id: "reward-choice-physical-" + prize.prize_order,
                            },
                            domProps: {
                              checked: prize.lucky_onoff === 0,
                              checked: _vm._q(prize.lucky_onoff, _vm._n("0")),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.$set(prize, "lucky_onoff", _vm._n("0"))
                                },
                                function ($event) {
                                  return _vm.update("lucky_onoff", prize)
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: {
                                for:
                                  "reward-choice-physical-" + prize.prize_order,
                              },
                            },
                            [_vm._v(" Physical ")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td", [_vm._v("Name")]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: prize.prize_name,
                              expression: "prize.prize_name",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            maxlength: "40",
                            disabled: !_vm.canEdit,
                          },
                          domProps: { value: prize.prize_name },
                          on: {
                            change: function ($event) {
                              return _vm.update("prize_name", prize)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                prize,
                                "prize_name",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("div", { staticClass: "reward-choice" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: prize.lucky_onoff,
                                expression: "prize.lucky_onoff",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              value: "1",
                              type: "radio",
                              disabled: !_vm.canEdit,
                              name: "reward-choice-" + prize.prize_order,
                              id: "reward-choice-sf-point-" + prize.prize_order,
                            },
                            domProps: {
                              checked: prize.lucky_onoff === 1,
                              checked: _vm._q(prize.lucky_onoff, _vm._n("1")),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.$set(prize, "lucky_onoff", _vm._n("1"))
                                },
                                function ($event) {
                                  return _vm.update("lucky_onoff", prize)
                                },
                              ],
                            },
                          }),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: {
                                  for:
                                    "reward-choice-sf-point-" +
                                    prize.prize_order,
                                },
                              },
                              [_vm._v(" SF Point ")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: prize.sfdc_point,
                                  expression: "prize.sfdc_point",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { width: "120px" },
                              attrs: {
                                min: prize.prize_order < 11 ? 1 : 0,
                                max: "1000000",
                                type: "number",
                                disabled:
                                  !_vm.canEdit || +prize.lucky_onoff !== 1,
                              },
                              domProps: { value: prize.sfdc_point },
                              on: {
                                change: function ($event) {
                                  return _vm.update("sfdc_point", prize)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    prize,
                                    "sfdc_point",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td", [_vm._v("Details")]),
                      _c("td", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: prize.prize_detail,
                              expression: "prize.prize_detail",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "3", disabled: !_vm.canEdit },
                          domProps: { value: prize.prize_detail },
                          on: {
                            change: function ($event) {
                              return _vm.update("prize_detail", prize)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                prize,
                                "prize_detail",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "reward-choice flex-align-top" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: prize.lucky_onoff,
                                  expression: "prize.lucky_onoff",
                                  modifiers: { number: true },
                                },
                              ],
                              attrs: {
                                value: "2",
                                type: "radio",
                                disabled: !_vm.canEdit,
                                name: "reward-choice-" + prize.prize_order,
                                id:
                                  "reward-choice-e-coupon-" + prize.prize_order,
                              },
                              domProps: {
                                checked: prize.lucky_onoff === 2,
                                checked: _vm._q(prize.lucky_onoff, _vm._n("2")),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    _vm.$set(prize, "lucky_onoff", _vm._n("2"))
                                  },
                                  function ($event) {
                                    return _vm.update("lucky_onoff", prize)
                                  },
                                ],
                              },
                            }),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-align-top mb-10" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label",
                                      attrs: {
                                        for:
                                          "reward-choice-e-coupon-" +
                                          prize.prize_order,
                                      },
                                    },
                                    [_vm._v(" E-Coupon ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "face-value-form" },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "input-label",
                                            attrs: {
                                              for:
                                                "reward-choice-face-value-" +
                                                prize.prize_order,
                                            },
                                          },
                                          [_vm._v(" Face Value ")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: prize.face_value,
                                              expression: "prize.face_value",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            min: "1",
                                            max: "999999",
                                            type: "number",
                                            disabled:
                                              !_vm.canEdit ||
                                              prize.lucky_onoff !== 2,
                                            id:
                                              "reward-choice-face-value-" +
                                              prize.prize_order,
                                          },
                                          domProps: { value: prize.face_value },
                                          on: {
                                            change: function ($event) {
                                              return _vm.update(
                                                "face_value",
                                                prize
                                              )
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                prize,
                                                "face_value",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v("bht")]),
                                      ]),
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "input-label",
                                            attrs: {
                                              for:
                                                "reward-choice-topup-" +
                                                prize.prize_order,
                                            },
                                          },
                                          [_vm._v(" Top Up ")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: prize.topup,
                                              expression: "prize.topup",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            min: "0",
                                            max: "999999",
                                            type: "number",
                                            disabled:
                                              !_vm.canEdit ||
                                              prize.lucky_onoff !== 2,
                                            id:
                                              "reward-choice-topup-" +
                                              prize.prize_order,
                                          },
                                          domProps: { value: prize.topup },
                                          on: {
                                            change: function ($event) {
                                              return _vm.update("topup", prize)
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                prize,
                                                "topup",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v("bht")]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex flex-align-top" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label line-high-1",
                                      attrs: {
                                        for:
                                          "reward-choice-custom-text-" +
                                          prize.prize_order,
                                      },
                                    },
                                    [
                                      _vm._v(" Custom text"),
                                      _c("br"),
                                      _vm._v("on coupon "),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: prize.custom_text,
                                        expression: "prize.custom_text",
                                      },
                                    ],
                                    staticClass: "form-control w250",
                                    attrs: {
                                      type: "text",
                                      maxlength: "40",
                                      disabled:
                                        !_vm.canEdit || prize.lucky_onoff !== 2,
                                      id:
                                        "reward-choice-custom-text-" +
                                        prize.prize_order,
                                    },
                                    domProps: { value: prize.custom_text },
                                    on: {
                                      change: function ($event) {
                                        return _vm.update("custom_text", prize)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          prize,
                                          "custom_text",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "reward-choice" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: prize.lucky_onoff,
                                expression: "prize.lucky_onoff",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              value: "4",
                              type: "radio",
                              disabled: !_vm.canEdit,
                              id:
                                "reward-choice-prizepool-" + prize.prize_order,
                            },
                            domProps: {
                              checked: prize.lucky_onoff === 4,
                              checked: _vm._q(prize.lucky_onoff, _vm._n("4")),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.$set(prize, "lucky_onoff", _vm._n("4"))
                                },
                                function ($event) {
                                  return _vm.update("lucky_onoff", prize)
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: {
                                for:
                                  "reward-choice-prizepool-" +
                                  prize.prize_order,
                              },
                            },
                            [_vm._v(" Prize Pool ")]
                          ),
                          _c("div", { staticClass: "d-flex gap-1rem" }, [
                            _c(
                              "label",
                              {
                                staticClass: "prize_theme_id",
                                attrs: {
                                  for:
                                    "reward-choice-prize_theme_id_" +
                                    prize.prize_order,
                                },
                              },
                              [_vm._v(" Prize Theme ID ")]
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: prize.prize_theme_id,
                                    expression: "prize.prize_theme_id",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "prize_theme_id",
                                  disabled:
                                    !_vm.canEdit || prize.lucky_onoff !== 4,
                                  id:
                                    "reward-choice-prize_theme_id_" +
                                    prize.prize_order,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return _vm._n(val)
                                        })
                                      _vm.$set(
                                        prize,
                                        "prize_theme_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.update("prize_theme_id", prize)
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { disabled: "" },
                                    domProps: { value: null },
                                  },
                                  [_vm._v("Not Select")]
                                ),
                                _vm._l(_vm.prizepool, function (prize) {
                                  return _c(
                                    "option",
                                    {
                                      key: prize.id,
                                      domProps: { value: prize.id },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(prize.id) +
                                          " - " +
                                          _vm._s(prize.name) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    prize.prize_order !== 11
                      ? _c("tr", [
                          _c("td"),
                          _c("td", [_vm._v("No. Items")]),
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: prize.num_items,
                                  expression: "prize.num_items",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { width: "120px" },
                              attrs: {
                                min: "1",
                                type: "number",
                                disabled: !_vm.canEdit,
                              },
                              domProps: { value: prize.num_items },
                              on: {
                                change: function ($event) {
                                  return _vm.update("num_items", prize)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    prize,
                                    "num_items",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("td"),
                      _c("td", [_vm._v("Chance Weight")]),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: prize.chance_weight,
                              expression: "prize.chance_weight",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "120px" },
                          attrs: {
                            type: "number",
                            min: "1",
                            max: "1000000",
                            disabled: !_vm.canEdit,
                          },
                          domProps: { value: prize.chance_weight },
                          on: {
                            change: function ($event) {
                              return _vm.update("chance_weight", prize)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                prize,
                                "chance_weight",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td"),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        [
                          _c("PrizePicture", {
                            attrs: { prize: prize, campaign: _vm.campaign },
                            on: {
                              updated: function ($event) {
                                return _vm.$emit("updated")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("hr"),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Prize")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "4" } }, [
      _c("h5", [_vm._v("Consolation Prize (SF Point)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="app">
    <router-view></router-view>
    <v-dialog />
    <notifications position="bottom right" :duration="3000" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UnauthorizedModal from "@/components/shared/UnauthorizedModal.vue";
export default {
  name: "app",
  components: {
    UnauthorizedModal,
  },
  mounted() {
    // Remark theme start init here.
    setTimeout(window.Site.run, 0);
    this.handleAuthStatus();
  },
  computed: {
    ...mapGetters({ authStatus: "auth/authStatus" }),
  },
  watch: {
    authStatus(newStatus, oldStatus) {
      this.handleAuthStatus();
    },
  },
  methods: {
    handleAuthStatus() {
      if (this.authStatus === "unauthorized" && this.$route.meta.requiresAuth) {
        this.$router.push({ name: "Logout" });
      }
    },
  },
};
</script>
<style lang="scss">
@import "node_modules/v-slim-dialog/dist/v-slim-dialog";
@import "@/styles/app.scss";
@import "@/styles/app.custom.scss";
</style>

<template>
  <div class="row">
    <div class="col-md-10">
      <label>
        <h5>Round Duration</h5>
      </label>

      <div class="row">
        <label class="d-inline mx-10 form-control-label"> Date </label>
        <div class="d-inline">
          <date-picker
            type="date"
            @change="update"
            :clearable="false"
            format="DD/MM/YYYY"
            :disabled="!canEditDateTime"
            v-model="round.start_date"
            value-type="YYYY-MM-DD HH:mm"
            :disabled-date="disabledStartDate"
            :disabled-time="disabledStartTime"
            style="max-width: 150px"
          ></date-picker>
        </div>
        <label class="d-inline mx-10 form-control-label"> Start Time </label>
        <date-picker
          type="time"
          format="HH:mm"
          @change="update"
          :clearable="false"
          :disabled="!canEditTime"
          v-model="round.start_date"
          value-type="YYYY-MM-DD HH:mm"
          style="max-width: 150px"
        ></date-picker>
        <label class="d-inline mx-10 form-control-label"> End Time </label>
        <date-picker
          type="time"
          format="HH:mm"
          @change="update"
          :clearable="false"
          :disabled="!canEditTime"
          v-model="round.end_date"
          value-type="YYYY-MM-DD HH:mm"
          :disabled-time="disabledEndTime"
          style="max-width: 150px"
        ></date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import { staffEngagementService } from "@/api/client";

export default {
  props: ["round"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    campaign() {
      return this.$parent.campaign;
    },
    canEditDateTime() {
      return ["draft"].includes(this.campaign.status);
    },
    canEditTime() {
      const isValidStartDate = dayjs(this.round.start_date).isValid();
      return isValidStartDate && this.canEditDateTime;
    },
  },

  methods: {
    disabledStartDate(date) {
      const today = dayjs().toDate();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    disabledStartTime(date) {
      return date < Date.now();
    },

    disabledEndTime(date) {
      return date < new Date(this.round.start_date);
    },

    async update() {
      try {
        const { id } = this.campaign;

        const { round_id, start_date, end_date } = this.round;

        const isValidEndDate = dayjs(end_date).isValid();

        let endDate = dayjs(start_date).format("YYYY-MM-DD HH:mm");

        if (isValidEndDate) {
          const endDateDate = dayjs(start_date).format("YYYY-MM-DD");
          const endDateTime = dayjs(end_date).format("HH:mm");

          endDate = endDateDate + " " + endDateTime;
        }

        const form = {
          start_date,
          end_date: endDate,
        };

        await staffEngagementService.editRound(id, round_id, form);

        this.oldValue = {
          start_date: form.start_date,
          end_date: form.end_date,
        };

        this.$notify(`Round Period updated.`);
        this.$root.$emit("STAFF_ENGAGEMENT_REFETCH");
      } catch (error) {
        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        this.$dialogs.alert(`Round Period update error.`);
      }
    },

    setDefaultValues(start, end) {
      this.round.end_date = end;
      this.round.start_date = start;

      this.duration = dayjs(new Date(end)).diff(new Date(start), "days") + 1;
    },
  },

  mounted() {
    const { start_date, end_date } = this.round;
    this.oldValue = { start_date, end_date };
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-header-actions" }, [
        _vm.status.status == "done" || !_vm.status.status
          ? _c("div", [
              _c("div", { staticClass: "wrapper-upload-form" }, [
                _c(
                  "label",
                  {
                    staticClass: "btn btn-round btn-upload mx-3",
                    attrs: { for: "Bulk-upload" },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-upload",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Bulk update "),
                  ]
                ),
                _c("input", {
                  staticClass: "input-select-file",
                  attrs: { type: "file", id: "Bulk-upload" },
                  on: { change: _vm.FileBulkUpload },
                }),
              ]),
            ])
          : _vm.status.status == "ready" || _vm.status.status == "loading"
          ? _c("div", [
              _c(
                "label",
                {
                  staticClass: "label-blue",
                  attrs: { hidden: _vm.status.status == "ready" },
                },
                [_vm._v(" Verifying... ")]
              ),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-round btn-primary mx-10",
                    attrs: {
                      type: "button",
                      disabled: _vm.status.status == "loading",
                    },
                    on: { click: _vm.getResult },
                  },
                  [_vm._v(" Commit ")]
                ),
              ]),
            ])
          : _vm.status.status == "running"
          ? _c("div", [
              _c("label", { staticClass: "label-blue" }, [
                _vm._v(" Updating... "),
              ]),
              _vm._m(0),
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group" }, [
          _c("a", { attrs: { href: _vm.downloadExampleLink } }, [
            _c("i", {
              staticClass: "fa fa-file-excel-o",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Download template"),
          ]),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "try-upload-team-target-modal",
            width: 600,
            height: "auto",
            clickToClose: false,
          },
        },
        [
          _c("TryUploadUserTargetModal", {
            attrs: { result: _vm.result, name: "try-upload-team-target-modal" },
            on: { confirm: _vm.confirmUpload },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-round btn-primary mx-10",
          attrs: { type: "button", disabled: "" },
        },
        [_vm._v(" Commit ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h1", { staticClass: "page-title" }, [_vm._v("Samurai")]),
          _c("breadcrumb", { attrs: { name: "GAME_SAMURAI_SETTINGS" } }),
          _c("div", { staticClass: "page-header-actions" }),
        ],
        1
      ),
      _c("div", { staticClass: "page-content page-content-table" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-12 col-lg-12" }, [
              _c("div", { staticClass: "panel" }, [
                _c(
                  "div",
                  { staticClass: "panel-body p-0" },
                  [
                    _c("vue-good-table", {
                      attrs: { rows: _vm.rows, columns: _vm.columns },
                      on: { "on-row-click": _vm.openConfigModal },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: { name: _vm.modalName, width: 860, height: "auto" },
          on: { closed: _vm.clearSelected },
        },
        [
          _c("ConfigurationModal", {
            attrs: {
              name: _vm.modalName,
              themes: _vm.choices,
              environment: _vm.selected.environment,
            },
            on: { updated: _vm.onConfigUpdated },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
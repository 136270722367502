import { required, minLength, maxLength } from "vuelidate/lib/validators";

const alphanumericExceptCommaAndSpace = (v) => {
  if (!v) {
    return false;
  }
  return /^[a-zA-Z0-9_-]*$/.test(v) && /^[^, \s]+$/.test(v);
};

export const FormCreateValidateRule = {
  auto_number: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
    alphanumericExceptCommaAndSpace,
  },
  group_code: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
    alphanumericExceptCommaAndSpace,
  },
  reason_description: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
  },
};

export const errorMessage = {
  auto_number: {
    default: `กรุณาใส่ค่า Auto Number`,
  },
  group_code: {
    default: `กรุณาใส่ค่า Group Code`,
  },
  reason_description: {
    default: `กรุณาใส่ค่า Reason Description`,
  },
};

<template>
  <div class="flex-fit-content" v-if="stamp">
    <div class="form-group-row">
      <label class="mb-0">Text box 2</label>

      <div
        :key="id"
        v-for="{ id, value, text } in choices"
        class="radio-custom radio-primary mx-10"
      >
        <input
          type="radio"
          name="text2_choice"
          :value="value"
          @change="update"
          :disabled="disabled"
          :id="`text2_choice_${id}`"
          v-model.number="stamp.text2_choice"
        />
        <label :for="`text2_choice_${id}`">{{ text }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

const items = [
  { id: 0, text: "Overall Progress", value: 0 },
  { id: 1, text: "Step to next reward", value: 1 },
  { id: 2, text: "Do not show", value: 2 },
];

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      choices: items,
      message: {
        error: "Update Text box 2 error.",
        success: "Update Text box 2 success.",
      },
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "ongoing"].includes(this.campaign.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
}

.form-group-row {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-weight: 500;
    margin-bottom: 0px;
  }

  input {
    max-width: 250px;
  }
}

.form-horizontal .radio-custom {
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-weight: 400;
  }
}
</style>

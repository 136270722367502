export const VSMUsersColumns = [
  {
    label: "ID",
    field: "vsm_id",
    type: "number",
  },
  {
    label: "Username",
    field: "username",
  },
  {
    label: "First Name",
    field: "firstname",
  },
  {
    label: "Last Name",
    field: "lastname",
  },
  {
    label: "Role",
    field: "employee_role",
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => {
      return +value === 0
        ? "INACTIVE"
        : +value === 1
        ? "ACTIVE"
        : value.toUpperCase();
    },
  },
  {
    label: "Last Login",
    field: "last_login",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Modified by",
    field: "modified_by_username",
  },
];

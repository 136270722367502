export const tableColumns = [
  {
    label: "Rank",
    field: "rank",
    type: "number",
  },
  {
    label: "ContactID ",
    field: "contact_id",
  },
  {
    label: "Touchpoint ID ",
    field: "touchpoint_id",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Score",
    field: "game_point",
    type: "number",
  },
  {
    label: "Last Active",
    field: "timestamp",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Rewards",
    field: "reward_name",
  },
  {
    label: "Prize Theme ID",
    field: "theme_id",
  },
  {
    label: "Reward Request ID",
    field: "request_id",
  },
  {
    label: "Auto Granted",
    field: "auto_grant",
  },
  {
    label: "Granted ?",
    field: "granted",
  },
];

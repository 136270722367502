<template>
  <div class="col-md-8">
    <label class="form-control-label">
      <h5>
        Campaign Description
        <span class="small light">(max 150 charactors)</span>
      </h5>
    </label>
    <textarea
      rows="4"
      @change="update"
      maxlength="150"
      ref="description"
      :disabled="!canEdit"
      class="form-control"
      v-model="description"
    />
  </div>
</template>

<script>
import { weeklyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return { description: "" };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  methods: {
    async isFormValid() {
      if (this.description.length > 150) {
        await this.$dialogs.alert("กรุณากรอก Campaign Description ให้ถูกต้อง");
        this.description = this.campaign.description;
        this.$refs.description.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const { id } = this.campaign;

        await weeklyLoginService.updateCampaignInfo(id, {
          description: this.description,
        });

        this.$notify(`Campaign Description updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Campaign Description error.`);
      }
    },
  },

  mounted() {
    this.description = this.campaign.description;
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding-left: 0px;
  h4 {
    margin-bottom: 0.5em;
  }
}

.light {
  color: gray;
  font-weight: 300;
}
</style>

<template>
  <div class="col-md-10" v-if="campaign">
    <div class="mx-5">
      <div class="d-flex">
        <h4>
          Target Icon on progress bar (image size 263x263 px / button size 120 x
          120 px)
        </h4>
      </div>
    </div>

    <div class="d-block">
      <TargetAndRewardRow :campaign="campaign" @updated="$emit('updated')" />
    </div>
  </div>
</template>

<script>
import TargetAndRewardRow from "./TargetAndRewardRow.vue";

export default {
  props: ["campaign"],
  components: { TargetAndRewardRow },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}
</style>

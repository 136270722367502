<template>
  <div class="col-md-8 my-10" v-if="stamp">
    <div class="d-flex align-items-center gap-5">
      <label class="mb-0">
        Background color (If background image is not present)
      </label>

      <div class="color-picker">
        <input
          type="text"
          v-model="color"
          :disabled="disabled"
          name="background_color"
          @change="update($event, 'color')"
        />
        <input
          type="color"
          v-model="color"
          :disabled="disabled"
          name="background_color"
          @change="update($event, 'color')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      color: null,
      message: {
        error: "Update Background color error.",
        success: "Update Background color success.",
      },
    };
  },
  methods: {
    initial() {
      const { background_color } = this.stamp;

      if (!background_color) return;

      this.color = this.parseColorValue(background_color);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;

    &:disabled {
      color: #aaa;
      background-color: #efefef;
    }
  }

  input[type="color"] {
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border-left: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

input[type="color"] {
  &:disabled {
    background-color: #efefef;
  }
}
</style>

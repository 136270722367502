var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-block" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Budget Limit ")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.budget.budget_limit,
            expression: "budget.budget_limit",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "number",
          min: "0",
          max: "10000000",
          name: "budget_limit",
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.budget.budget_limit },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.budget, "budget_limit", $event.target.value)
          },
        },
      }),
      _c("p", [_vm._v("Baht")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
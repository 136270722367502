import { required, minLength, maxLength } from "vuelidate/lib/validators";

const alphanumericExceptCommaAndSpace = (v) => {
  return /^[a-zA-Z0-9_-]*$/.test(v) && /^[^,\s]+$/.test(v);
};

const numericInteger = (v) => {
  return /[^0-9.]/g.test(v);
};

export const FormCreateValidateRule = {
  name: {
    required,
    minLength: minLength(0),
    maxLength: maxLength(40),
  },
  // minimum_points: {
  //   required,
  //   minLength: minLength(0),
  //   maxLength: maxLength(40),
  // },
};

<template>
  <div class="page" v-if="campaign">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="LUCKY_DRAW_CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <div class="panel-title campaign-type-group">
                  <h4>Campaign Information - {{ campaign.id }}</h4>
                  <h4>User Group: {{ campaign.group_code }}</h4>
                </div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="campaign">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <CampaignName :campaign="campaign" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <CampaignDuration
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                  </div>
                  <hr />

                  <div class="row">
                    <PlayLogic :campaign="campaign" @updated="fetchCampaign" />
                  </div>
                  <hr />

                  <div class="row">
                    <TermsAndCondition
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                  </div>
                  <hr />

                  <div class="row">
                    <PrizeItem :campaign="campaign" @updated="fetchCampaign" />
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-8">
                      <h4>Appearance</h4>
                    </div>

                    <BackgroundImage
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                    <HeadLineImage
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                    <ButtonPicture
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                    <PrizeFramePicture
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                    <HighlightedPrizeFramePicture
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                    <PrizeDetailFrame
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                  </div>
                  <hr />

                  <div class="row">
                    <ButtonAction
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-8">
                      <h4>LINE Reminder</h4>
                    </div>

                    <LINEPicture
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />

                    <LUMGroupSetting
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />

                    <ReminderDate
                      :campaign="campaign"
                      @updated="fetchCampaign"
                    />
                  </div>
                  <hr />

                  <div class="row">
                    <URLForm :campaign="campaign" @updated="fetchCampaign" />
                  </div>
                  <hr />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyDrawService } from "@/api/client";

import URLForm from "./components/URLForm.vue";
import PlayLogic from "./components/PlayLogic.vue";
import PrizeItem from "./components/PrizeItem.vue";
import LINEPicture from "./components/LINEPicture.vue";
import ButtonAction from "./components/ButtonAction.vue";
import ReminderDate from "./components/ReminderDate.vue";
import CampaignName from "./components/CampaignName.vue";
import HeadLineImage from "./components/HeadLineImage.vue";
import ButtonPicture from "./components/ButtonPicture.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import BackgroundImage from "./components/BackgroundImage.vue";
import LUMGroupSetting from "./components/LUMGroupSetting.vue";
import CampaignDuration from "./components/CampaignDuration.vue";
import PrizeDetailFrame from "./components/PrizeDetailFrame.vue";
import PrizeFramePicture from "./components/PrizeFramePicture.vue";
import TermsAndCondition from "./components/TermsAndCondition.vue";
import HighlightedPrizeFramePicture from "./components/HighlightedPrizeFramePicture.vue";

export default {
  name: "LuckyDrawEdit",

  data() {
    return {
      campaign: null,
    };
  },

  components: {
    URLForm,
    PlayLogic,
    PrizeItem,
    LINEPicture,
    ButtonAction,
    ReminderDate,
    CampaignName,
    HeadLineImage,
    ButtonPicture,
    StatusDropdown,
    BackgroundImage,
    LUMGroupSetting,
    CampaignDuration,
    PrizeDetailFrame,
    PrizeFramePicture,
    TermsAndCondition,
    HighlightedPrizeFramePicture,
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await luckyDrawService.getById(id);
    },
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.campaign-type-group {
  display: flex;
  padding-top: 5px;
  padding-bottom: 0px;
  align-items: center;
  justify-content: space-between;

  h p {
    font-size: 18px;
    color: #37474f;
    margin-bottom: 0px;
  }
}
</style>

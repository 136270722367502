<template>
  <div
    class="page vertical-align text-center"
    data-animsition-in="fade-in"
    data-animsition-out="fade-out"
  >
    <div class="page-content vertical-align-middle">
      <i
        class="icon wb-settings icon-spin page-maintenance-icon"
        aria-hidden="true"
      ></i>
      <h2>กำลังออกจากระบบ...</h2>
      <p>กรุณารอสักครู่ ระบบกำลังทำงาน</p>

      <footer class="page-copyright">
        <p>PMI Thailand</p>
        <p>© 2018. All RIGHT RESERVED.</p>
        <div class="social">
          <a class="btn btn-icon btn-pure" href="javascript:void(0)">
            <i class="icon bd-twitter" aria-hidden="true"></i>
          </a>
          <a class="btn btn-icon btn-pure" href="javascript:void(0)">
            <i class="icon bd-facebook" aria-hidden="true"></i>
          </a>
          <a class="btn btn-icon btn-pure" href="javascript:void(0)">
            <i class="icon bd-dribbble" aria-hidden="true"></i>
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import { toggleBodyClass } from "@/utils/helpers";
export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    setTimeout(() => {
      this.logout();
    }, 2000);
  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  mounted() {
    toggleBodyClass("addClass", "page-maintenance layout-full");
  },
  destroyed() {
    toggleBodyClass("removeClass", "page-maintenance layout-full");
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "table",
          { staticClass: "table" },
          _vm._l(_vm.gameConfig.sunset_drive.billboard, function (o, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "w-100" }, [
                _vm._v("No. " + _vm._s(index + 1)),
              ]),
              _c("td", { staticClass: "center w-400" }, [
                _c("p", [_vm._v("Picture")]),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "d-block" }, [
                    o.picture
                      ? _c("img", {
                          staticClass: "img img-fluid image-preview",
                          attrs: { src: o.picture },
                        })
                      : _vm._e(),
                  ]),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "file",
                      accept: "image/*",
                      disabled: _vm.disabled,
                      "data-index": index,
                    },
                    on: { change: _vm.upload },
                  }),
                ]),
              ]),
              _c("td", { staticClass: "v-align-middle w-200" }, [
                _c("div", [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("p", { staticClass: "mx-10 mb-0" }, [
                      _vm._v("Appear on"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: o.appear_on,
                          expression: "o.appear_on",
                        },
                      ],
                      staticClass: "form-control w-100",
                      attrs: {
                        min: "0",
                        type: "number",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: o.appear_on },
                      on: {
                        change: function ($event) {
                          return _vm.update(index)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(o, "appear_on", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("td", { staticClass: "v-align-middle px-50" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.remove(index)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-trash" })]
                ),
              ]),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "action" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: {
                disabled:
                  _vm.gameConfig.sunset_drive.billboard.length >= 6 ||
                  _vm.disabled,
                type: "button",
              },
              on: { click: _vm.add },
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Billboard Pictures")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
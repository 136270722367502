var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign && _vm.campaign.round
    ? _c("div", { staticClass: "panel" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _c("h3", { staticClass: "panel-title" }, [
            _vm._v("Round Information"),
          ]),
          _c("div", { staticClass: "panel-actions panel-actions-keep" }, [
            _c("h4", [_vm._v("No. Round " + _vm._s(_vm.rounds.length))]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "panel-body" },
          _vm._l(_vm.rounds, function (round, index) {
            return _c("RoundItem", {
              key: round.round_id,
              attrs: { index: index, round: round, campaign: _vm.campaignData },
              on: { remove: _vm.removeRound },
            })
          }),
          1
        ),
        _c("div", { staticClass: "panel-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { disabled: !_vm.canAddRound },
              on: { click: _vm.addRound },
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "file",
    staticClass: "dropify",
    attrs: {
      type: "file",
      "data-errors-position": "outside",
      accept: _vm.options.accept || "image/*",
      "data-default-file": _vm.defaultFile || "",
      "data-max-file-size": _vm.options.maxFileSize || "2M",
      "data-allowed-file-extensions":
        _vm.options.accept.replace(/\.|,/g, "") || "image/*",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Samurai</h1>
      <breadcrumb name="GAME_SAMURAI_THEMES"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideInactive = !hideInactive"
        >
          {{ hideInactive ? "Show all" : "Hide Inactive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="rows"
              :columns="columns"
              @on-row-click="onRowClick"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-samurai-theme-modal" :width="500" height="auto">
      <CreateModal @submit="fetchThemes" name="create-samurai-theme-modal" />
    </modal>
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";

import vueGoodTableMixins from "@/mixins/vue-good-table";
import CreateModal from "@/views/samurai/Themes/CreateModal.vue";
import { themesTableColumns as columns } from "@/views/samurai/Themes/model";

export default {
  name: "GameSamuraiThemes",

  mixins: [vueGoodTableMixins],

  components: { CreateModal },

  data() {
    return {
      themes: [],
      hideInactive: false,
      columns: columns,
    };
  },

  computed: {
    rows() {
      if (this.hideInactive) {
        return this.themes.filter((o) => o.status !== "inactive");
      }

      return this.themes;
    },
  },

  methods: {
    onRowClick(event) {
      this.$router
        .push({ name: "GameSamuraiThemeEdit", params: { id: event.row.id } })
        .catch((err) => {});
    },

    async fetchThemes() {
      const items = await gameSamuraiService.getAllTheme();
      this.themes = items.map((o) => {
        const published_to = this.getPublishedTo(o.environment_list);

        return {
          ...o,
          published_to,
        };
      });
    },

    getPublishedTo(environment_list) {
      return Array.isArray(environment_list)
        ? environment_list.toString().replace(/\,/g, ", ")
        : "";
    },

    async reloadTable() {
      await this.fetchThemes();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-samurai-theme-modal`);
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  created() {
    this.fetchThemes();
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>

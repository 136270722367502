<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Pool Status</h1>

      <breadcrumb name="PRIZE_SUMMARY_POOL_STATUS"></breadcrumb>

      <div class="page-header-right"></div>
      <div class="page-header-actions"></div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-row-click="onRowClick"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'count_available'">
                  <span :class="{ green: +props.row.count_available > 0 }">
                    {{ props.row.count_available | numberWithCommas }}
                  </span>
                </div>
                <div v-else-if="props.column.field == 'count_margin'">
                  <span :class="{ red: +props.row.count_margin < 0 }">
                    {{ props.row.count_margin | numberWithCommas }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { tableColumns } from "@/views/prize/pool_status/model";

export default {
  name: "PrizePoolStatus",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      columns: tableColumns,
    };
  },

  watch: {
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    async fetch() {
      const { data, total_records } =
        await prizepoolService.getPrizePoolStatus();

      this.list = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      try {
        const response = await prizepoolService.downloadPrizePoolStatus();

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `export_prize_pool_status_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onRowClick(event) {
      this.$router
        .push({
          name: "prize-pool-status-detail",
          params: { id: event.row.id },
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.red {
  color: var(--red);
}

.green {
  color: var(--green);
}

.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
</style>

<style>
.vgt-table thead th.table-header-green {
  color: var(--green);
}
</style>

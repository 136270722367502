var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _vm.task
        ? _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v(" " + _vm._s(_vm.index + 1) + " ")]),
                _c("td", [_vm._v(" Name ")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.task.name,
                        expression: "task.name",
                      },
                    ],
                    staticClass: "form-control mx-10 d-inline",
                    attrs: {
                      "width-150px": "",
                      type: "text",
                      disabled: !_vm.canEdit,
                    },
                    domProps: { value: _vm.task.name },
                    on: {
                      change: function ($event) {
                        return _vm.update("name")
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.task, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Date")]),
                _c(
                  "td",
                  [
                    _c("DatePicker", {
                      attrs: {
                        type: "date",
                        "width-150px": "",
                        placeholder: "n/a",
                        format: "YYYY-MM-DD",
                        disabled: !_vm.canEdit,
                        "value-type": "YYYY-MM-DD",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.update("task_date")
                        },
                      },
                      model: {
                        value: _vm.task.task_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.task, "task_date", $$v)
                        },
                        expression: "task.task_date",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", [_vm._v("Order")]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.task.task_order,
                          expression: "task.task_order",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: !_vm.canEdit },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.task,
                              "task_order",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.update("task_order")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("n/a"),
                      ]),
                      _vm._l(_vm.taskAmount, function (n) {
                        return _c(
                          "option",
                          {
                            key: n,
                            attrs: { disabled: _vm.isUsed(n) },
                            domProps: { value: n },
                          },
                          [_vm._v(" " + _vm._s(n) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [_vm._v("Weight")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.task.task_weight,
                        expression: "task.task_weight",
                      },
                    ],
                    ref: "task_weight",
                    staticClass: "form-control mx-10 d-inline",
                    attrs: {
                      "width-100px": "",
                      type: "number",
                      disabled: !_vm.canEdit,
                    },
                    domProps: { value: _vm.task.task_weight },
                    on: {
                      change: function ($event) {
                        return _vm.update("task_weight")
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.task, "task_weight", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-defautl mx-10",
                      attrs: { type: "button", disabled: !_vm.canEdit },
                      on: { click: _vm.showTaskDetail },
                    },
                    [
                      _c("i", {
                        staticClass: "icon md-edit",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-defautl",
                      attrs: { type: "button", disabled: !_vm.canRemove },
                      on: { click: _vm.remove },
                    },
                    [
                      _c("i", {
                        staticClass: "icon md-delete",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
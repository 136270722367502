var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign && _vm.canImportProgress
    ? _c(
        "div",
        { attrs: { id: "import-progress" } },
        [
          _c(
            "a",
            { staticClass: "btn btn-link", attrs: { href: _vm.exampleLink } },
            [_vm._v(" Click to download example ")]
          ),
          _c(
            "label",
            {
              staticClass: "btn btn-primary btn-upload",
              attrs: {
                disabled: !_vm.canUpload,
                for: "user-target-reward-file-upload",
              },
            },
            [_vm._v(" Import Progress ")]
          ),
          _c("input", {
            staticClass: "input-select-file",
            attrs: {
              type: "file",
              disabled: !_vm.canUpload,
              id: "user-target-reward-file-upload",
              accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            on: { change: _vm.onFileSelected },
          }),
          _c(
            "modal",
            {
              attrs: {
                name: "try-upload-progress-modal",
                width: 900,
                height: "auto",
              },
            },
            [
              _c("TryUploadModal", {
                attrs: { campaign: _vm.campaign, result: _vm.result },
                on: { confirm: _vm.upload },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "row" }, [
        _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
          _vm._v(" Start "),
        ]),
        _c(
          "div",
          { staticClass: "d-inline mx-10" },
          [
            _c("DatePicker", {
              ref: "start_date",
              attrs: {
                type: "date",
                "width-150px": "",
                placeholder: "n/a",
                format: "YYYY-MM-DD",
                "value-type": "YYYY-MM-DD",
                disabled: _vm.disabled,
              },
              on: { change: _vm.update },
              model: {
                value: _vm.campaign.start_date,
                callback: function ($$v) {
                  _vm.$set(_vm.campaign, "start_date", $$v)
                },
                expression: "campaign.start_date",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "d-inline mx-10" }, [_vm._v("No. days")]),
        _c("div", { staticClass: "d-inline mx-10" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.duration,
                expression: "duration",
              },
            ],
            ref: "duration",
            staticClass: "form-control",
            attrs: {
              "width-100px": "",
              type: "number",
              min: "1",
              max: "999",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.duration },
            on: {
              change: _vm.update,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.duration = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "d-inline mx-10" }, [
          _c("p", [
            _vm._v(
              "End Date: " + _vm._s(_vm._f("DATE_HUGE")(_vm.campaign.end_date))
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
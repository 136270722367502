var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.leaderboard
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "ml-10" }, [
          _c(
            "table",
            {
              staticClass: "table table-responsive table-borderless",
              attrs: { id: "matric-content-table" },
            },
            [
              _c("tr", [
                _c("td", { attrs: { width: "120" } }, [
                  _vm._v("Metric content"),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.metric_content,
                        expression: "leaderboard.metric_content",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      "width-300px": "",
                      name: "metric_content",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.metric_content },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "metric_content",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td"),
                _c("td"),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v("Table area")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create User Group Form</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <label class="col-md-3"> </label>
              <label class="col-md-9">
                This number must match value in Salesforce! Example:
                a0F25000007ZIgY (15 digits)</label
              >
              <label class="col-md-3 form-control-label form__label"
                >Auto Number</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  ref="auto_number"
                  maxlength="255"
                  autocomplete="off"
                  v-model="usergroup.auto_number"
                  class="form-control form__input"
                  :class="{ 'is-valid': !$v.usergroup.auto_number.$invalid }"
                />
                <autoNumberValidation
                  :$v="$v"
                  v-if="showErrorAutoNumberValidation"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3"> </label>
              <label class="col-md-9"> Once created, cannot be changed !</label>
              <label class="col-md-3 form-control-label form__label">
                Group Code
              </label>
              <div class="col-md-9">
                <input-tag
                  :limit="limit"
                  maxlength="255"
                  ref="group_code"
                  v-model="groupCode"
                  :add-tag-on-blur="true"
                  class="form-control form__input"
                  :class="{ 'is-valid': !$v.usergroup.group_code.$invalid }"
                ></input-tag>
                <groupCodeValidation
                  :$v="$v"
                  v-if="showErrorGroupCodeValidation"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3"> </label>
              <label class="col-md-9">
                This is the value to be sent to Salesforce. Example: 'ประมูล
                หรือจับฉลาก ค้าปลีก(GT) RT-00002593' หรือ 'ล๊อกอินสะสม Family
                Mart'
              </label>
              <label class="col-md-3 form-control-label form__label">
                Reason Description
              </label>
              <div class="col-md-9">
                <textarea
                  type="text"
                  maxlength="255"
                  autocomplete="off"
                  ref="reason_description"
                  class="form-control form__input"
                  v-model="usergroup.reason_description"
                  :class="{
                    'is-valid': !$v.usergroup.reason_description.$invalid,
                  }"
                />
                <reasonDescriptionValidation
                  :$v="$v"
                  v-if="showErrorReasondDscriptionValidation"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              @click="addUserGroup"
              :disabled="$v.$invalid"
              class="btn btn-round btn-primary"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserGroupModel } from "./model";
import { userGroupService } from "@/api/client";
import { responseErrorMapping } from "./model/error";
import autoNumberValidation from "./validation/autoNumber";
import groupCodeValidation from "./validation/groupCode";
import reasonDescriptionValidation from "./validation/reasonDescription";

import {
  errorMessage,
  FormCreateValidateRule as validateRules,
} from "./validation";

export default {
  name: "CreateModal",

  data() {
    return {
      limit: 1,
      groupCode: [],
      usergroup: new UserGroupModel(),
      showErrorGroupCodeValidation: true,
      showErrorAutoNumberValidation: true,
      showErrorReasondDscriptionValidation: true,
    };
  },

  validations: { usergroup: validateRules },

  components: {
    groupCodeValidation,
    autoNumberValidation,
    reasonDescriptionValidation,
  },

  watch: {
    groupCode(n, o) {
      this.usergroup.group_code = n[0];
    },
  },

  methods: {
    async isFormValid() {
      if (this.$v.usergroup.auto_number.$invalid) {
        await this.$dialogs.alert(errorMessage.auto_number.default);
        this.$refs.auto_number.focus();
        return false;
      }

      if (this.$v.usergroup.group_code.$invalid) {
        await this.$dialogs.alert(errorMessage.group_code.default);
        this.$refs.group_code.focus();
        return false;
      }

      if (this.$v.usergroup.reason_description.$invalid) {
        await this.$dialogs.alert(errorMessage.reason_description.default);
        this.$refs.reason_description.focus();
        return false;
      }
      return true;
    },

    async addUserGroup() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const response = await userGroupService.createUserGroup(this.usergroup);
        await this.$dialogs.alert("Create Group Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify(error);
      }
    },

    close() {
      this.$modal.hide("create-usergroup-modal");
    },

    showValid(name) {
      if (name == "auto_number") {
        this.showErrorAutoNumberValidation = true;
      }
      if (name == "group_code") {
        this.showErrorGroupCodeValidation = true;
      }
      if (name == "reason_description") {
        this.showErrorReasondDscriptionValidation = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-body {
  height: auto;
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

.vue-input-tag-wrapper .input-tag {
  margin-bottom: 0px;
}
.vue-input-tag-wrapper {
  padding: 0px 10px;

  .new-tag {
    border-radius: 15px;
  }
  .input-tag {
    margin-bottom: 0px;
  }
}
</style>

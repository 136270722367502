var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "col-md-10" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "clearfix" }, [
            _c("p", { staticClass: "float-left" }, [
              _c("a", { attrs: { href: _vm.exampleLink } }, [
                _vm._v(" Click to download example "),
              ]),
            ]),
            _c("p", { staticClass: "float-right" }, [
              _c("span", { staticClass: "date mr-10" }, [
                _vm._v(" Last upload: " + _vm._s(_vm.lastUploadTime) + " "),
              ]),
              _c("span", { staticClass: "no-ranks" }, [
                _vm._v(
                  " No. Ranks: " +
                    _vm._s(
                      _vm._f("numberWithCommas")(
                        _vm.campaign.leaderboard.reward_count
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "wrapper-upload-form" }, [
            _c(
              "label",
              {
                staticClass: "btn btn-round btn-upload mx-3",
                attrs: {
                  for: "rank-reward-file-upload",
                  disabled: !_vm.canUpload,
                },
              },
              [_vm._v(" Click to upload file ")]
            ),
            _c("input", {
              staticClass: "input-select-file",
              attrs: {
                type: "file",
                disabled: !_vm.canUpload,
                id: "rank-reward-file-upload",
                accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              on: { change: _vm.onFileSelected },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Ranks and Rewards")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
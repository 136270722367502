<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Advocacy Setting</h1>
      <breadcrumb name="ADVOCACY_LIST"></breadcrumb>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <nav class="nav nav-pills nav-fill">
            <a
              :class="
                !barSelect ? 'nav-item nav-link active' : 'nav-item nav-link'
              "
              href="javascript:void(0)"
              @click="barSelect = false"
              >SKUs</a
            >
            <a
              :class="
                barSelect ? 'nav-item nav-link active' : 'nav-item nav-link'
              "
              href="javascript:void(0)"
              @click="barSelect = true"
              >Question Pool</a
            >
          </nav>
          <div class="panel-body p-0">
            <div v-if="barSelect">
              <div class="page-content container-fluid">
                <div class="row">
                  <div class="col-lg-12 col-xl-12">
                    <div v-if="OnEdit == true">
                      <div class="panel panel-bordered">
                        <div class="panel-heading">
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <h3>
                                Question - {{ getquestion.id }}
                                {{ getquestion.name }}
                              </h3>
                            </div>
                            <div class="form-group col-md-6">
                              <div class="px-15">
                                <div class="btn-group">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-round dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    style="text-transform: uppercase;"
                                  >
                                    {{ getquestion.status }}
                                  </button>
                                  <div class="dropdown-menu" role="menu">
                                    <a
                                      v-if="getquestion.status == 'active'"
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      role="menuitem"
                                      @click="submitStatus('inactive')"
                                    >
                                      Inactive
                                    </a>
                                    <a
                                      v-if="getquestion.status == 'inactive'"
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      role="menuitem"
                                      @click="submitStatus('active')"
                                    >
                                      Active
                                    </a>
                                  </div>
                                  <div class="back-right">
                                    <button
                                      type="button"
                                      class="btn btn-round btn-default mx-15"
                                      @click="OnEdit = false"
                                    >
                                      Back to List
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form class="form-horizontal">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-control-label"
                            >Question Name</label
                          >
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              maxlength="50"
                              v-model="getquestion.name"
                              @change="submitQuestion('name')"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-control-label"
                            >Question Text</label
                          >
                          <div class="col-sm-6">
                            <input
                              class="form-control"
                              type="text"
                              maxlength="64"
                              v-model="getquestion.text"
                              @change="submitQuestion('text')"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-control-label"
                            >Character</label
                          >
                          <div class="col-sm-6">
                            <select
                              class="form-control"
                              v-model="getquestion.character"
                              @change="submitQuestion('character')"
                            >
                              <option value="A">White Collar</option>
                              <option value="B">Day Hire</option>
                              <option value="C">Self Hire</option>
                              <option value="D">LA29 </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            class="col-sm-2 col-form-control-label"
                          ></label>
                          <div class="col-sm-6">
                            <img
                              :src="thumbnailChar(getquestion.character)"
                              alt=""
                              class="preview"
                            />
                          </div>
                        </div>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" class="t1-e">Choices</th>
                              <th scope="col" class="t2-e"></th>
                              <th scope="col" class="t3-e"></th>
                              <th scope="col" class="t4-e"></th>
                              <th scope="col" class="t5-e">Correct Answer</th>
                              <th scope="col" class="t6-e"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="( item , index) in (getquestion.choices)"
                              :key="index"
                            >
                              <th class="t1">No.{{ index }}</th>
                              <th class="t2">
                                <div class="form-group row">
                                  <label class="col-sm-2 col-form-control-label"
                                    >SKU:
                                  </label>
                                  <div class="col-sm-8">
                                    <select
                                      class="form-control"
                                      v-model="item.sku_id"
                                      @change="submitChoices(index)"
                                    >
                                      <option value="null" disabled
                                        >Please choose....</option
                                      >
                                      <option
                                        v-for="sku in skus"
                                        :key="sku.id"
                                        :value="sku.id"
                                        :disabled="sku.status == 'inactive'"
                                        :hidden="sku.status == 'inactive'"
                                      >
                                        {{ ` ${sku.label} ` }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </th>
                              <th>
                                {{
                                  ShowSku(item.sku_id, "status") | toUpperCase
                                }}
                              </th>
                              <th>
                                <img
                                  :src="ShowSku(item.sku_id, 'thumbnail')"
                                  alt=""
                                />
                              </th>
                              <th>
                                <div class="center">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="item.correct_answer"
                                    @change="submitChoices(index)"
                                  />
                                </div>
                              </th>
                              <th>
                                <button
                                  type="button"
                                  class="btn btn-round btn-default mx-10"
                                  @click="DeleteChoice(index)"
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    style="font-size: 18px;"
                                  ></i>
                                </button>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </div>
                    <div v-else>
                      <vue-good-table
                        :columns="columns"
                        :rows="questions"
                        :search-options="{
                          enabled: true,
                        }"
                        :pagination-options="{
                          enabled: true,
                          mode: 'records',
                        }"
                        @on-row-dblclick="onRowDoubleClick"
                      >
                        <div slot="table-actions">
                          <button
                            type="button"
                            class="btn btn-round btn-primary"
                            @click="CreateQuestion"
                          >
                            <i class="icon md-plus" aria-hidden="true"></i>
                            Create
                          </button>
                        </div>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'character'">
                            <span>{{ CharName(props.row.character) }}</span>
                          </span>
                          <span v-else-if="props.column.field == 'thumbnail'">
                            <img
                              class="imageTable"
                              :src="thumbnailChar(props.row.character)"
                              alt=""
                            />
                          </span>
                          <span v-else-if="props.column.field == 'status'">
                            {{ props.row.status | toUpperCase }}
                          </span>
                          <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                          </span>
                        </template>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="page-content container-fluid">
                <div class="row">
                  <div class="col-lg-12 col-xl-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" class="t1"></th>
                          <th scope="col" class="t2">Status</th>
                          <th scope="col" class="t3">Label:</th>
                          <th scope="col" class="t4">
                            Description
                          </th>
                          <th scope="col" class="t5"></th>
                          <th scope="col" class="t6"></th>
                          <th scope="col" class="t7"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(sku, index) in skus" :key="index">
                          <th class="t1">No.{{ index + 1 }}</th>
                          <th class="t2">
                            <select
                              v-model="skus[index].status"
                              class="form-control"
                              @change="submit('status', skus[index].id, index)"
                            >
                              <option
                                value="active"
                                :disabled="skus[index].status == 'active'"
                                :hidden="skus[index].status == 'active'"
                                >ACTIVE</option
                              >
                              <option
                                value="inactive"
                                :disabled="skus[index].status == 'inactive'"
                                :hidden="skus[index].status == 'inactive'"
                                >INACTIVE</option
                              >
                            </select>
                          </th>
                          <th class="t3">
                            <input
                              type="text"
                              class="form-control"
                              v-model="skus[index].label"
                              maxlength="50"
                              @change="submit('label', skus[index].id, index)"
                            />
                          </th>
                          <th class="t4">
                            <input
                              type="text"
                              class="form-control"
                              maxlength="255"
                              v-model="skus[index].description"
                              @change="
                                submit('description', skus[index].id, index)
                              "
                            />
                          </th>
                          <th class="t5">
                            <img :src="skus[index].thumbnail" alt="" />
                          </th>
                          <th class="t6">
                            <input
                              type="file"
                              :ref="'fileUpload' + skus[index].id"
                              style="display: none;"
                              @change="onFileSelected"
                              accept="image/*"
                            />
                            <button
                              type="button"
                              @click="
                                displayRef(
                                  'fileUpload' + skus[index].id,
                                  skus[index].id
                                )
                              "
                              class="btn btn-round btn-default mx-10"
                            >
                              <i
                                class="fa fa-file-text-o"
                                style="font-size: 18px;"
                              ></i>
                            </button>
                          </th>
                          <th class="t7">
                            <button
                              type="button"
                              class="btn btn-round btn-default mx-10"
                              @click="remove(skus[index].id, skus[index].image)"
                            >
                              <i
                                class="fa fa-trash-o"
                                style="font-size: 18px;"
                              ></i>
                            </button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-round btn-default mx-10"
                      @click="add()"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="400" height="auto">
      <CreateModal @submit="fetchQuestion" />
    </modal>
    <modal name="confrim-modal" :width="600" height="auto">
      <ConfrimModal
        @sucessRemove="fetch"
        :idSkus="RemoveID"
        :image="RemoveImage"
      />
    </modal>
  </div>
</template>

<script>
import CreateModal from "@/views/advocacy/CreateModal.vue";
import {
  skuService,
  uploadgameService,
  questionService,
  characterService,
} from "@/api/client";
import { fileUploadOptions, questionTableColumns } from "./model";
import ConfrimModal from "@/views/advocacy/confrim.vue";
export default {
  name: "AdvocacyList",
  data() {
    return {
      barSelect: true,
      skus: [],
      characters: [],
      questions: [],
      getquestion: [],
      selectedfile: null,
      fileUploadOptions,
      columns: questionTableColumns,
      RemoveID: null,
      RemoveImage: null,
      NowID: null,
      OnEdit: false,
      objectEdit: null,
    };
  },
  components: { ConfrimModal, CreateModal },
  watch: {
    OnEdit() {
      this.fetchQuestion();
    },
  },
  created() {
    this.fetch();
    this.fetchQuestion();
  },
  methods: {
    async fetchQuestion() {
      this.questions = await questionService.getQuestion({ show_all: 1 });
      this.characters = await characterService.getCharacter();
    },
    async fetch() {
      this.skus = await skuService.getSku({ show_all: 1 });
    },
    async add() {
      await skuService.createSku({});
      this.fetch();
    },
    remove(id, image) {
      this.RemoveImage = null;
      this.RemoveID = id;
      if (image) {
        var pieces = image.split(/[\s/]+/);
        this.RemoveImage = pieces[pieces.length - 1];
      }
      this.$modal.show("confrim-modal");
    },

    check(field, index) {
      switch (field) {
        case "status":
          if (this.skus[index].image == null) {
            this.skus[index].status = "inactive";
            this.alert("กรุณา Upload Image ก่อน");
            return false;
          }
          return true;

        default:
          return true;
      }
    },
    async alert(Text) {
      await this.$dialogs.alert(Text);
    },
    async submit(field, id, index) {
      try {
        const Check = this.check(field, index);
        if (!Check) return;
        const form = {};
        form[field] = this.skus[index][field];
        await skuService.editSku(id, form);
        this.fetch();
        this.$notify(
          `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
            /_/gi,
            " "
          ) + " updated."
        );
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("SKUs Update error");
      }
    },
    displayRef(ref, id) {
      this.$refs[ref][0].click();
      this.NowID = id;
    },
    onFileSelected(event) {
      this.selectedfile = event.target.files[0];
      this.OnUpload(this.selectedfile);
    },
    async OnUpload(image) {
      try {
        if (!image) return;
        if (!this.checkImageType(image)) return;
        let form = new FormData();
        form.append("image", image, image.name);
        const result = await uploadgameService.upload(
          `/ams/api/sku/${this.NowID}/upload`,
          form
        );
        this.NowID = null;
        this.$notify("Updated");
        this.fetch();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
    checkImageType(image) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(image["type"])) {
        this.alert("เกิดข้อผิดพลาด File image ต้องเป็น jpg และ png");
        return false;
      }
      return true;
    },
    CreateQuestion() {
      this.$modal.show(`create-modal`);
    },
    CharName(text) {
      switch (text) {
        case "A":
          return "White Collar";
        case "B":
          return "Day Hire";
        case "C":
          return "Self Hire";
        case "D":
          return "LA29";
        default:
          return "";
      }
    },
    thumbnailChar(Text) {
      var Character = "";
      if (Text == null) return;
      Character = this.characters.filter((g) => g.character == Text);
      if (Character.length > 0) {
        return Character[0].image_url;
      }
    },
    onRowDoubleClick(params) {
      this.objectEdit = params.row;
      this.OnEdit = true;
      this.fetchGetQuestion();
    },
    async fetchGetQuestion() {
      this.getquestion = await questionService.getById(this.objectEdit.id);
    },
    ShowSku(id, field) {
      if (id == null) {
        return "n/a";
      }
      var form = [];
      form = this.skus.filter((g) => g.id == id);
      switch (field) {
        case "status":
          return form[0].status;
        case "thumbnail":
          return form[0].thumbnail;

        default:
          return;
      }
    },
    async submitChoices(NoChoice) {
      try {
        const form = {};
        form["sku_id"] = this.getquestion.choices[NoChoice]["sku_id"];
        form["correct_answer"] =
          this.getquestion.choices[NoChoice]["correct_answer"] == true ? 1 : 0;
        await questionService.editQuestionChoice(
          this.objectEdit.id,
          NoChoice,
          form
        );
        this.fetchGetQuestion();
        this.$notify("Choices updated.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Question Choices Update error");
      }
    },
    async DeleteChoice(NoChoice) {
      try {
        const form = {};
        form["sku_id"] = null;
        form["correct_answer"] = 0;
        await questionService.editQuestionChoice(
          this.objectEdit.id,
          NoChoice,
          form
        );
        this.fetchGetQuestion();
        this.$notify("Delete succes.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Question Choices Delete error");
      }
    },
    async submitQuestion(field) {
      try {
        const form = {};
        form[field] = this.getquestion[field];
        await questionService.editQuestion(this.objectEdit.id, form);
        this.fetchGetQuestion();
        this.$notify(
          `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
            /_/gi,
            " "
          ) + " updated."
        );
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Question Update error");
      }
    },
    async submitStatus(status) {
      try {
        const form = new FormData();
        form.set("status", status);
        const body = JSON.stringify(Object.fromEntries(form));
        await questionService.editQuestion(
          this.objectEdit.id,
          JSON.parse(body)
        );
        this.$notify("Change Status success");
        this.fetchGetQuestion();
      } catch (error) {
        console.log(error);
        if (error.response) {
          this.$dialogs.alert(responseErrorMapping(error.response.data));
          this.repeatedlygroup(error.response.data);
        } else {
          this.$notify(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.half-width {
  width: 50%;
}
.center {
  text-align: center;
}
.t1 {
  width: 8%;
  text-align: center;
}
.t2 {
  width: 15%;
  text-align: center;
}
.t3 {
  width: 20%;
  text-align: center;
}
.t4 {
  width: 25%;
  text-align: center;
}
.image-upload > input {
  display: none;
}
.centerT {
  margin: auto;
  width: 60%;
  padding: 10px;
}
.imageTable {
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.page-header-right {
  position: absolute;
  top: 15px;
  bottom: 0;
  right: 40px;
}
.preview {
  width: 25%;
  height: auto;
}
.t1-e {
  width: 10%;
  text-align: center;
}
.t2-e {
  width: 30%;
  text-align: center;
}
.t3-e {
  width: 10%;
  text-align: center;
}
.t4-e {
  width: 10%;
  text-align: center;
}
.t5-e {
  width: 10%;
  text-align: center;
}
.t5-e {
  width: 10%;
  text-align: center;
}
.back-right {
  margin-left: 45px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-header" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Quota")]),
        _c("breadcrumb", {
          attrs: {
            name: "POINT_QUOTA_LIMIT_EDIT",
            withLast: _vm.quota.summary,
          },
          on: {
            "update:withLast": function ($event) {
              return _vm.$set(_vm.quota, "summary", $event)
            },
            "update:with-last": function ($event) {
              return _vm.$set(_vm.quota, "summary", $event)
            },
          },
        }),
        _c("div", { staticClass: "page-header-actions" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-default mx-10",
              attrs: { type: "button" },
              on: { click: _vm.goBack },
            },
            [
              _c("i", {
                staticClass: "icon md-chevron-left",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Back "),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page" v-if="theme">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Prize Theme</h1>
          <breadcrumb
            name="PRIZE_SUMMARY_THEME_EDIT"
            :withLast.sync="theme.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Prize Theme Information - {{ theme.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="theme">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <ThemeName :theme="theme" @updated="fetchTheme" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown :theme="theme" @updated="fetchTheme" />
                    </div>
                  </div>
                  <hr />
                  <AwardName :theme="theme" @updated="fetchTheme" />
                  <AwardDetails :theme="theme" @updated="fetchTheme" />
                  <AwardPicture :theme="theme" @updated="fetchTheme" />
                  <hr />
                  <AutoClaim :theme="theme" @updated="fetchTheme" />
                  <hr />
                  <PoolOption :theme="theme" @updated="fetchTheme" />
                  <hr />
                  <ClaimExpiration :theme="theme" @updated="fetchTheme" />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";

import AutoClaim from "@/views/prize/theme/components/AutoClaim.vue";
import ThemeName from "@/views/prize/theme/components/ThemeName.vue";
import AwardName from "@/views/prize/theme/components/AwardName.vue";
import PoolOption from "@/views/prize/theme/components/PoolOption.vue";
import AwardDetails from "@/views/prize/theme/components/AwardDetails.vue";
import AwardPicture from "@/views/prize/theme/components/AwardPicture.vue";
import StatusDropdown from "@/views/prize/theme/components/StatusDropdown.vue";
import ClaimExpiration from "@/views/prize/theme/components/ClaimExpiration.vue";

export default {
  name: "PrizeThemeEdit",

  data() {
    return {
      theme: null,
    };
  },

  components: {
    AutoClaim,
    ThemeName,
    AwardName,
    PoolOption,
    AwardDetails,
    AwardPicture,
    StatusDropdown,
    ClaimExpiration,
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchTheme() {
      const id = this.$route.params.id;
      this.theme = await prizepoolService.getPrizeThemeById(id);
    },
  },

  created() {
    this.fetchTheme();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
</style>

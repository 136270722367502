import HttpRequest from "./request";

export class GameSamuraiProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/dte_game`);
  }

  async getAllGroup() {
    const { data } = await this.get(`/samurai/group`);
    return data;
  }

  async getByCode(code) {
    const { data } = await this.get(`/samurai/group/${code}`);
    return data;
  }

  async updateConfig(code, body) {
    const { data } = await this.update(`/samurai/group/${code}`, body);
    return data;
  }

  async getThemeChoice(id, body) {
    const { data } = await this.get(`/samurai/theme/choice_active`, body);
    return data;
  }

  async getAllTheme() {
    const { data } = await this.get(`/samurai/theme`);
    return data;
  }

  async createTheme(body) {
    const { data } = await this.create(`/samurai/theme`, body);
    return data;
  }

  async getThemeChoiceCopyFrom() {
    const { data } = await this.get(`/samurai/theme/choice_copy_from`);
    return data;
  }

  async getThemeConfig(id) {
    const { data } = await this.get(`/samurai/theme/${id}`);
    return data;
  }

  async updateThemeConfig(id, body) {
    const { data } = await this.update(`/samurai/theme/${id}`, body);
    return data;
  }

  async uploadThemeImage(id, form) {
    const { data } = await this.upload(`/samurai/theme/${id}/upload`, form);
    return data;
  }

  async getPreviewUrl(id) {
    const { data } = await this.create(`/samurai/theme/${id}/gen_preview_url`);
    return data;
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("h5", { staticClass: "col-md-12" }, [_vm._v("Lucky Login Numbers")]),
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        {
          staticClass: "btn btn-default mx-10",
          class: { disabled: !_vm.canEdit },
          attrs: { for: "bulk_upload_lucky_number" },
        },
        [
          _c("i", {
            staticClass: "icon md-upload",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" Bulk upload "),
        ]
      ),
      _vm.canEdit
        ? _c("input", {
            staticClass: "invisible",
            attrs: {
              type: "file",
              id: "bulk_upload_lucky_number",
              accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            on: { change: _vm.onFileSelected },
          })
        : _vm._e(),
    ]),
    _c(
      "a",
      {
        staticClass: "btn btn-link btn-round",
        attrs: { target: "_blank", href: _vm.sampleTaskFileUploadUri },
      },
      [
        _c("i", { staticClass: "fa fa-file-excel-o" }),
        _vm._v(" Click to download template "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="col-md-10" v-if="monthlyshort">
    <label class="mx-5">
      <h4>Progress Upload</h4>
    </label>

    <div class="progress-upload-switcher-wrapper">
      <p class="my-0 d-inline">
        Auto
      </p>
      <label class="switch m0">
        <input
          type="checkbox"
          :true-value="1"
          :false-value="0"
          :disabled="disabled"
          v-model="monthlyshort.progress_upload_manual"
          @change="update"
        />
        <span class="slider round"></span>
      </label>
      <p class="mx-10 my-0 ml-20 d-inline">
        Manual
      </p>
    </div>

    <div
      class="target-sku-wrapper"
      v-if="+monthlyshort.progress_upload_manual === 0"
    >
      <TargetSKU :campaign="campaign" />
    </div>
  </div>
</template>

<script>
import TargetSKU from "./TargetSKU.vue";

import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  components: { TargetSKU },

  data() {
    return {
      monthlyshort: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    async update() {
      try {
        const { id } = this.campaign;

        const progress_upload_manual = this.monthlyshort.progress_upload_manual;

        await progressBarService.updateCampaignInfo(id, {
          monthlyshort: { progress_upload_manual },
        });

        this.$emit("updated");
        this.$notify(`Progress Update updated`);
      } catch (error) {
        this.$dialogs.alert(`Progress Update update error.`);
      }
    },
  },

  mounted() {
    this.monthlyshort = this.campaign.monthlyshort;
  },
};
</script>

<style lang="scss" scoped>
.progress-upload-switcher-wrapper {
  display: flex;
  margin: 1.5em;
  margin-top: 1em;
}
</style>

<template>
  <div class="form-group row" v-if="quota">
    <h5 class="col-md-12">Campaign IDs</h5>
    <div class="col-md-8 col-sm-12">
      <Selectize
        ref="select"
        multiple
        :disabled="!canEdit"
        :settings="selectizeSetting"
        v-model="quota.campaign_id_list"
      >
        <option v-for="{ id, name } in campaigns" :key="id" :value="id">
          {{ ` ${id} - ${name}` }}
        </option>
      </Selectize>
    </div>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { walletService } from "@/api/client";

export default {
  props: ["quota"],

  components: {
    Selectize,
  },

  data() {
    return {
      campaigns: [],
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        onChange: this.update,
      },
    };
  },

  computed: {
    canEdit() {
      return ["draft", "scheduled", "tracking"].includes(this.quota.status);
    },
  },

  async created() {
    await this.fetchCampaign();
  },

  methods: {
    async update(ids) {
      try {
        const { id } = this.quota;
        const form = { campaign_id_list: ids.map((id) => +id) };

        await walletService.updateQuotaInfo(id, form);

        this.$emit("updated");
        this.$notify(`Campaign Updated.`);
      } catch (error) {
        this.$refs.select.setValue(this.quota.campaign_id_list);

        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    async fetchCampaign() {
      const { id } = this.quota;
      this.campaigns = await walletService.fetchAvailableCampaign(id);
    },
  },
};
</script>

<style lang="scss">
.selectize-control .selectize-input.disabled {
  background-color: #f3f7f9;
}
</style>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Refund Log</h1>
      <breadcrumb name="REFUND_LOG_LIST"></breadcrumb>
      <div class="Excel">
        <button class="btn btn-default" @click="exportToExcel">
          <i class="fa fa-file-excel-o"></i>
          Export to Excel
        </button>
      </div>
      <div class="page-header-right">
        <span class="mx-10">Selected Period</span>
        <date-picker
          v-model="from_to"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0"></div>
          <vue-good-table
            :rows="log"
            :columns="columns"
            :sort-options="sortOption"
            :search-options="searchOption"
            :pagination-options="paginationOptions"
          ></vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logService } from "@/api/client";
import { logTableColumns } from "@/views/refund_log/model";

export default {
  name: "LogList",

  data() {
    return {
      log: [],
      from_to: [],
      columns: logTableColumns,
      url: "https://78.media.tumblr.com/tumblr_m39nv7PcCU1r326q7o1_500.png",
      searchOption: {
        enabled: true,
        placeholder: "Search this",
      },
      sortOption: {
        enabled: true,
        initialSortBy: {
          type: "desc",
          field: "timestamp",
        },
      },
      paginationOptions: {
        enabled: true,
        mode: "records",
      },
    };
  },

  async created() {
    this.from_to = await [this.lastmonth(), await this.today()];
  },

  watch: {
    from_to() {
      this.update();
    },
  },

  methods: {
    async exportToExcel() {
      try {
        const response = await logService.download(
          this.from_to[0],
          this.from_to[1]
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "Export table.xlsx";
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    async update() {
      if (this.from_to[1] == null || this.from_to[0] == null) {
        this.log = await logService.getLogDate();
      } else {
        this.log = await logService.getLogDate(
          this.from_to[0],
          this.from_to[1]
        );
      }
    },

    lastmonth() {
      var today = new Date();
      var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 7)
        .toJSON()
        .slice(0, 10);
      return lastmonth;
    },

    today() {
      var today = new Date().toJSON().slice(0, 10);
      return today;
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin-top: 35px;
}
</style>

import { render, staticRenderFns } from "./NumbersOnEmptyStamp.vue?vue&type=template&id=40c2e696&scoped=true&"
import script from "./NumbersOnEmptyStamp.vue?vue&type=script&lang=js&"
export * from "./NumbersOnEmptyStamp.vue?vue&type=script&lang=js&"
import style0 from "./NumbersOnEmptyStamp.vue?vue&type=style&index=0&id=40c2e696&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c2e696",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40c2e696')) {
      api.createRecord('40c2e696', component.options)
    } else {
      api.reload('40c2e696', component.options)
    }
    module.hot.accept("./NumbersOnEmptyStamp.vue?vue&type=template&id=40c2e696&scoped=true&", function () {
      api.rerender('40c2e696', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/progressbar/Stamp/components/NumbersOnEmptyStamp.vue"
export default component.exports
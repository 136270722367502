var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quota
    ? _c("div", { staticClass: "form-group row" }, [
        _c("h5", { staticClass: "col-md-12" }, [_vm._v("Quota Limit Period")]),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v("Start"),
            ]),
            _c("DatePicker", {
              ref: "startDate",
              staticClass: "px-0",
              attrs: {
                type: "datetime",
                confirm: true,
                format: "DD/MM/YYYY HH:mm",
                "value-type": "YYYY-MM-DD HH:mm:00",
                disabled: !_vm.canUpdateStartDate,
              },
              on: { change: _vm.update },
              model: {
                value: _vm.start_date,
                callback: function ($$v) {
                  _vm.start_date = $$v
                },
                expression: "start_date",
              },
            }),
            _c("label", { staticClass: "form-control-label" }, [_vm._v("End")]),
            _c("DatePicker", {
              ref: "endDate",
              staticClass: "px-0",
              attrs: {
                type: "datetime",
                confirm: true,
                placeholder: "n/a",
                format: "DD/MM/YYYY HH:mm",
                "value-type": "YYYY-MM-DD HH:mm:00",
                disabled: !_vm.canUpdateEndDate,
              },
              on: { change: _vm.update },
              model: {
                value: _vm.end_date,
                callback: function ($$v) {
                  _vm.end_date = $$v
                },
                expression: "end_date",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
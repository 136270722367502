<template>
  <div v-if="pool">
    <label class="form-control-label">
      <h4>Pool Name</h4>
    </label>
    <input
      ref="name"
      type="text"
      v-model="name"
      maxlength="255"
      @change="update"
      :disabled="!canEdit"
      class="form-control"
    />
    <nameValidation :$v="$v" />
  </div>
</template>

<script>
import { prizepoolService } from "@/api/client";
import nameValidation from "@/views/prize/pool/validation/name.vue";
import { FormEditValidateRule as validateRules } from "@/views/prize/pool/validation";

export default {
  props: ["pool"],

  components: {
    nameValidation,
  },

  validations: { name: validateRules.name },

  data() {
    return {
      id: null,
      name: null,
    };
  },

  computed: {
    canEdit() {
      return ["active", "inactive"].includes(this.pool.status);
    },
  },

  mounted() {
    this.id = this.pool.id;
    this.name = this.pool.name;
  },

  methods: {
    async isFormValid() {
      if (this.$v.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Pool Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await prizepoolService.updatePrizePool(this.pool.id, {
          name: this.name,
        });
        this.$notify(`Pool name updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Pool name error.`);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="page vld-parent">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="page-header">
      <h1 class="page-title">CCB2B Log</h1>
      <breadcrumb
        name="E_COUPON_CCB2B_LOG_DETAIL"
        :withLast.sync="logId"
      ></breadcrumb>
      <div class="page-header-right">
        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="refreshStatus"
        >
          <i class="icon md-refresh" aria-hidden="true"></i> Refresh status
        </button>

        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="goBack"
        >
          <i class="icon md-chevron-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :columns="columns"
              :rows="list"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              :search-options="searchOption"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-search="onSearch"
              @on-row-click="onRowClick"
              @on-per-page-change="onPerPageChange"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button class="btn btn-default" @click="exportToExcel">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="ccb2b-log-detail-modal" :width="500" height="auto">
      <LogDetailModal :detail="detail" @cancelled="fetch" @retried="false" />
    </modal>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import LogDetailModal from "@/views/ecoupon/ccb2b-log/Popup.vue";
import { ccb2bLogDetailColumns } from "@/views/ecoupon/ccb2b-log/model";

export default {
  mixins: [vueGoodTableMixins],

  components: {
    Loading,
    LogDetailModal,
  },

  data() {
    return {
      page: 1,
      list: [],
      detail: null,
      isLoading: false,
      columns: ccb2bLogDetailColumns,
    };
  },

  watch: {
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  computed: {
    logId() {
      return this.$route.params.id.toString();
    },
  },

  methods: {
    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
          }),
        })
        .catch((err) => {});
    },

    getParams() {
      let params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }
      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },

    async refreshStatus() {
      try {
        this.isLoading = true;
        const { id } = this.$route.params;
        await ecouponService.ccb2bLogRefresh(id);
        await this.fetch();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetch() {
      const { id } = this.$route.params;
      const params = this.getParams();

      const { data, total_records } = await ecouponService.getCCB2BLogDetail(
        id,
        params
      );

      this.list = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      const { id } = this.$route.params;

      try {
        const response = await ecouponService.ccb2bLogExport(id);
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `ecoupon_ccb2blog_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    goBack() {
      this.$router.replace({ name: `e-coupon-ccb2b-log` }).catch((err) => {});
    },

    onRowClick({ row }) {
      this.detail = row;
      if (+row.cancelled === 1 || row.job_status == "UPLOADED") return;
      this.$modal.show(`ccb2b-log-detail-modal`);
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.Excel {
  margin: auto 1rem;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.monthlyshort
    ? _c(
        "div",
        { staticClass: "col-md-10" },
        [
          _vm._m(0),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "clearfix" }, [
              _vm._m(1),
              _c("p", { staticClass: "float-right" }, [
                _c("span", { staticClass: "date mr-10" }, [
                  _vm._v(" Last upload: " + _vm._s(_vm.lastUploadTime) + " "),
                ]),
                _c("span", { staticClass: "no-records" }, [
                  _vm._v(
                    " No. records: " +
                      _vm._s(
                        _vm._f("numberWithCommas")(
                          _vm.campaign.monthlyshort.target_reward_count
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _vm.status != null
              ? _c("div", [
                  _vm.status.active === 0
                    ? _c("div", { staticClass: "wrapper-upload-form" }, [
                        _c("label", { staticClass: "label-blue" }, [
                          _vm._v(" Click to choose new file to upload "),
                        ]),
                        _c(
                          "label",
                          {
                            staticClass: "btn btn-round btn-upload btn-primary",
                            attrs: {
                              for: "user-target-reward-file-upload",
                              disabled: _vm.disabled,
                            },
                          },
                          [_vm._v(" Edit ")]
                        ),
                        _c("input", {
                          staticClass: "input-select-file",
                          attrs: {
                            type: "file",
                            disabled: _vm.disabled,
                            id: "user-target-reward-file-upload",
                            accept:
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          },
                          on: { change: _vm.onFileSelected },
                        }),
                      ])
                    : _vm.status.active === 1 &&
                      _vm.status.type == "target" &&
                      _vm.status.state != "execute"
                    ? _c("div", { staticClass: "wrapper-upload-form" }, [
                        _c("label", [_vm._v(" " + _vm._s(_vm.filename) + " ")]),
                        _vm.status.state == "verify"
                          ? _c("label", { staticClass: "label-blue" }, [
                              _vm._v(" Verifying change "),
                            ])
                          : _vm.status.state == "done-verify"
                          ? _c("label", { staticClass: "label-blue" }, [
                              _vm._v(" Click to commit change "),
                            ])
                          : _vm._e(),
                        _vm.status.state == "verify"
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-round btn-primary mx-10",
                                  attrs: { type: "button", disabled: "" },
                                },
                                [_vm._v(" Commit ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-default",
                                  attrs: { type: "button", disabled: "" },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ])
                          : _vm.status.state == "done-verify"
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-round btn-primary mx-10",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.disabled,
                                  },
                                  on: { click: _vm.getResult },
                                },
                                [_vm._v(" Commit ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-round btn-default",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.disabled,
                                  },
                                  on: { click: _vm.cancelProcess },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm.status.active === 1 &&
                      _vm.status.type == "target" &&
                      _vm.status.state == "execute"
                    ? _c("div", { staticClass: "wrapper-upload-form" }, [
                        _c("label", [_vm._v(" " + _vm._s(_vm.filename) + " ")]),
                        _c("label", { staticClass: "label-blue" }, [
                          _vm._v(" Processing change. "),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-round btn-primary",
                            attrs: { type: "button", disabled: "" },
                          },
                          [_vm._v(" Edit ")]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "modal",
            { attrs: { name: "try-upload-modal", width: 900, height: "auto" } },
            [
              _c("TryUploadUserTargetModal", {
                attrs: {
                  campaign: _vm.campaign,
                  result: _vm.result,
                  name: "try-upload-modal",
                },
                on: { confirm: _vm.confirmUpload },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Target & Rewards")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "float-left" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/progressbar/monthlyshort-target-upload.xlsx",
          },
        },
        [_vm._v(" Click to download example ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
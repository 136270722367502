<template>
  <div class="row" v-if="campaign">
    <div class="col-12">
      <p d-inline-block width-150px>Redirect URL</p>
      <input
        type="text"
        width-400px
        @change="update"
        :disabled="!canEdit"
        v-model="campaign.redirect_url"
        class="form-control mx-10 d-inline"
      />
      (from result page)
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  computed: {
    canEdit() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },
  },

  methods: {
    async update() {
      try {
        const { id, redirect_url } = this.campaign;
        const form = {
          redirect_url: redirect_url,
        };

        await luckyLoginService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`Redirect URL Updated.`);
      } catch (error) {
        this.$dialogs.alert(`Redirect URL update error.`);
      }
    },
  },
};
</script>

<style></style>

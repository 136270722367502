<template>
  <div class="col-md-8">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Banner Picture
            <span class="small light"
              >Recommended size 640 x 300 px / less than 1 MB</span
            >
          </h4>
        </label>

        <div class="form-group">
          <div class="d-block wrapper-image-preview">
            <img
              class="img img-fluid image-preview"
              v-if="picture"
              :src="picture"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="uploadImage"
            ref="banner_picture"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { leaderBoardService } from "@/api/client";

export default {
  name: "BannerPicture",
  
  props: ["campaign"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    picture() {
      return this.campaign.banner_picture;
    },

    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    async uploadImage(event) {
      const file = event.target.files[0];

      if (!file) return;

      if (file.size > 1000000) {
        await this.$dialogs.alert("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        const form = new FormData();

        form.append("banner_picture", file);

        const { id } = this.campaign;

        await leaderBoardService.uploadBanner(id, form);

        this.$emit("updated");
        this.$notify(`Banner picture updated.`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload banner picture error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.wrapper-image-preview {
  text-align: center;
  min-height: 300px;
  background-color: #efefef;
}

.image-preview {
  padding: 12px;
  min-height: 300px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  min-width: 420px;
}
</style>

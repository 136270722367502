var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Pool Status")]),
        _c("breadcrumb", { attrs: { name: "PRIZE_SUMMARY_POOL_STATUS" } }),
        _c("div", { staticClass: "page-header-right" }),
        _c("div", { staticClass: "page-header-actions" }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    rows: _vm.list,
                    columns: _vm.columns,
                    "sort-options": _vm.sortOption,
                    "search-options": _vm.searchOption,
                    "pagination-options": _vm.pagination,
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPerPageChange,
                    "on-row-click": _vm.onRowClick,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function (props) {
                        return [
                          props.column.field == "count_available"
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      green: +props.row.count_available > 0,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("numberWithCommas")(
                                            props.row.count_available
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : props.column.field == "count_margin"
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    class: { red: +props.row.count_margin < 0 },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("numberWithCommas")(
                                            props.row.count_margin
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c("div", { staticClass: "Excel" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: { click: _vm.exportToExcel },
                          },
                          [
                            _c("i", { staticClass: "fa fa-file-excel-o" }),
                            _vm._v(" Export to Excel "),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import StaffEngagementIndex from "@/views/staff-engagement/Index.vue";
import StaffEngagementList from "@/views/staff-engagement/List.vue";
import StaffEngagementEdit from "@/views/staff-engagement/Edit.vue";
import Participation from "@/views/staff-engagement/Participation.vue";
import TransactionLog from "@/views/staff-engagement/TransactionLog.vue";

export default {
  path: "/staff-engagement",
  name: "StaffEngagement",
  component: StaffEngagementIndex,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaign",
      name: "StaffEngagementList",
      component: StaffEngagementList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "campaign/edit/:id",
      name: "StaffEngagementEdit",
      component: StaffEngagementEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "participation",
      name: "StaffEngagementParticipation",
      component: Participation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "transaction-log",
      name: "StaffEngagementTransactionLog",
      component: TransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

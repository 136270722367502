var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _c("h3", { staticClass: "panel-title" }, [
            _vm._v(
              " Round " +
                _vm._s(_vm.round.round_id) +
                ": Question " +
                _vm._s(_vm.index + 1) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "panel-body" }, [
          _vm.question
            ? _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _vm._m(0),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.description,
                        expression: "question.description",
                      },
                    ],
                    staticClass: "form-control col-md-10",
                    attrs: {
                      type: "text",
                      name: "description",
                      maxlength: "255",
                      disabled: !_vm.isValidStatus,
                    },
                    domProps: { value: _vm.question.description },
                    on: {
                      change: _vm.editQuestion,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.question,
                          "description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "form-control-label col-md-2" }, [
                    _vm._v("Question Body"),
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.question,
                        expression: "question.question",
                      },
                    ],
                    staticClass: "form-control col-md-10",
                    attrs: {
                      rows: "3",
                      name: "question",
                      disabled: !_vm.isValidStatus,
                    },
                    domProps: { value: _vm.question.question },
                    on: {
                      change: _vm.editQuestion,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "question", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "form-control-label col-md-12" }, [
                    _vm._v("Choice"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    _vm._l(_vm.question.choice, function (choice, index) {
                      return _c(
                        "div",
                        { key: choice.choice_id, staticClass: "row mb-3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-md-2 d-flex align-items-center",
                            },
                            [_vm._v(" " + _vm._s(index + 1) + ". ")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: choice.choice,
                                expression: "choice.choice",
                              },
                            ],
                            staticClass: "form-control col-md-9",
                            attrs: {
                              type: "text",
                              choiceId: choice.choice_id,
                              disabled: !_vm.isValidStatus,
                            },
                            domProps: { value: choice.choice },
                            on: {
                              change: _vm.updateChoice,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(choice, "choice", $event.target.value)
                              },
                            },
                          }),
                          _c("div", { staticClass: "action col-md-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  disabled: !_vm.canRemoveChoice,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeChoice(
                                      _vm.round.round_id,
                                      _vm.question.question_id,
                                      choice.choice_id
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "action col-md-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button", disabled: !_vm.canAddChoice },
                        on: {
                          click: function ($event) {
                            return _vm.addChoice(
                              _vm.round.round_id,
                              _vm.question.question_id
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-plus" })]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "form-control-label col-md-2" }, [
                    _vm._v("Correct Answer"),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.question.correct_answer,
                          expression: "question.correct_answer",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control col-md-2",
                      attrs: {
                        name: "correct_answer",
                        disabled: !_vm.isValidStatus,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.question,
                              "correct_answer",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.editQuestion(
                              $event,
                              _vm.question.correct_answer
                            )
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.question.choice.length, function (n) {
                      return _c("option", { key: n, domProps: { value: n } }, [
                        _vm._v(" " + _vm._s(n) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Close "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label col-md-2" }, [
      _vm._v(" Description"),
      _c("br"),
      _vm._v("(Internal Only) "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
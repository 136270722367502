import Product from "@/views/product/Index.vue";
import ProductList from "@/views/product/List.vue";
import ProductEdit from "@/views/product/Edit.vue";

export default {
  path: "/product",
  name: "Product",
  component: Product,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/",
      name: "ProductList",
      component: ProductList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "edit/:id",
      name: "ProductEdit",
      component: ProductEdit,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

<template>
  <div class="page" v-if="budget">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Budget Control</h1>
          <breadcrumb
            name="BUDGET_CONTROL_INSTANCE_EDIT"
            :withLast.sync="budget.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">Budget Instance - {{ budget.id }}</h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="budget">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <InstanceName :budget="budget" @updated="fetchBudget" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :budget="budget"
                        @error="fetchBudget"
                        @updated="fetchBudget"
                      />
                    </div>
                  </div>
                  <hr />

                  <BudgetDuration :budget="budget" @updated="fetchBudget" />
                  <hr />

                  <div class="row">
                    <div class="form-group col-md-12">
                      <label class="form-control-label">
                        <h4>Budget Details</h4>
                      </label>

                      <BudgetLimit
                        :budget="budget"
                        @error="fetchBudget"
                        @updated="fetchBudget"
                      />

                      <SFPointConversion
                        :budget="budget"
                        @error="fetchBudget"
                        @updated="fetchBudget"
                      />
                    </div>
                  </div>
                  <hr />

                  <TargetCampaign :budget="budget" @updated="fetchBudget" />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetService } from "@/api/client";

import BudgetLimit from "@/views/budget-control/instance/components/BudgetLimit.vue";
import InstanceName from "@/views/budget-control/instance/components/InstanceName.vue";
import BudgetDuration from "@/views/budget-control/instance/components/BudgetDuration.vue";
import StatusDropdown from "@/views/budget-control/instance/components/StatusDropdown.vue";
import TargetCampaign from "@/views/budget-control/instance/components/TargetCampaign.vue";
import SFPointConversion from "@/views/budget-control/instance/components/SFPointConversion.vue";

export default {
  name: "BudgetInstanceEdit",

  data() {
    return {
      budget: null,
    };
  },

  components: {
    BudgetLimit,
    InstanceName,
    BudgetDuration,
    StatusDropdown,
    TargetCampaign,
    SFPointConversion,
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchBudget() {
      const { id } = this.$route.params;
      this.budget = await budgetService.getBudgetById(id);
    },
  },

  created() {
    this.fetchBudget();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
</style>

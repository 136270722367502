<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Update confirmation</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <h4 class="col-md-12">{{ campaign.name }}</h4>
              <div class="col-md-12">
                <p>Rewards will be forfeited from the following users</p>
                <p class="red">
                  Note: this operation may fail. Please check transaction log
                  afterward.
                </p>

                <div v-if="revokedList.length > 0" class="mx-10">
                  <ul>
                    <li v-for="id in revokedList" :key="id">
                      {{ id }}
                    </li>
                  </ul>
                </div>

                <div v-else class="mx-10">
                  <p>-</p>
                </div>

                <p>
                  A total of
                  <span class="blue-600">
                    {{ result.granted_reward | numberWithCommas }}
                  </span>
                  rewards will be granted to users after this operation.
                </p>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              {{ revokedList.length > 0 ? "Cancel" : "Close" }}
            </button>
            <button
              type="button"
              @click="confirm"
              class="btn btn-round btn-primary"
            >
              <i class="icon md-check" aria-hidden="true"></i> Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["campaign", "result", "targetName"],

  computed: {
    revokedList() {
      return this.result.revoked_list;
    },
  },

  methods: {
    close() {
      this.$modal.hide(this.targetName);
    },

    confirm() {
      this.$emit("confirm");
      this.$modal.hide(this.targetName);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}
p.red {
  color: #af0202;
  font-weight: 400;

  margin-bottom: revert;
}
</style>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create New Lot</h3>
        </div>
        <div class="panel-body mb-0">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.lot.name.$error }"
            >
              <label class="col-md-0 form-control-label form__label"
                ><h5>Lot Name</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="lot.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>

            <div class="form-group row">
              <h5 class="col-md-12">Lot Type</h5>
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <input
                  name="lotType"
                  type="radio"
                  id="one"
                  value="auction"
                  checked="checked"
                  v-model="lot.type"
                />
                <label for="one" class="mx-10">Auction</label>
                <br />
                <input
                  name="lotType"
                  type="radio"
                  id="two"
                  value="luckydraw"
                  v-model="lot.type"
                />
                <label for="two" class="mx-10">Lucky Draw</label>
              </div>
            </div>

            <div class="form-group row">
              <h5 class="col-md-12">Copy From</h5>
              <div class="col-md-12">
                <select class="form-control col-md-12" v-model="copyFromId">
                  <option v-for="lot in list" :key="lot.id" :value="lot.id">
                    {{ ` ${lot.id} - ${lot.name}` }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              @click="addLot"
              class="btn btn-round btn-primary"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lotService } from "@/api/client";
import { lotModel as lots, lot } from "@/views/lot/model";
import { responseErrorMapping } from "./model/error";
import nameValidation from "./validation/name";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "LotCreate",
  data() {
    return {
      lot: {
        type: "auction",
      },
      copyFromId: null,
      lots: [],
      list: [],
      showErrorNameValidation: false,
    };
  },

  validations: { lot: validateRules },
  components: {
    nameValidation,
  },

  watch: {
    "lot.type"(newValue, oldValue) {
      this.filter(newValue);
    },
  },
  async mounted() {
    this.lots = await lotService.getLots();
    this.filter(this.lot.type);
  },
  methods: {
    filter(type) {
      this.list = this.lots.filter((l) => l.type == type);
      // this.copyFromId = this.list[0].id;
    },

    async isFormValid() {
      if (this.$v.lot.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Lot Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    async addLot() {
      try {
        const copyFromLot = await lotService.getById(this.copyFromId);
        const body = {
          name: this.lot.name,
          type: this.lot.type,
          description: copyFromLot.description,
          image: copyFromLot.image,
          no_prize: copyFromLot.no_prize,
          product_id: copyFromLot.product_id,
          term_condition: copyFromLot.term_condition,
          group_id_list: copyFromLot.group_id_list,
          minimum_points: copyFromLot.minimum_points,
          maximum_points: copyFromLot.maximum_points,
          max_no_entries: copyFromLot.max_no_entries,
          minimum_increment: copyFromLot.minimum_increment,

          winner_image: copyFromLot.winner_image,
          winner_notification: copyFromLot.winner_notification,
          end_image: copyFromLot.end_image,
          end_notification: copyFromLot.end_notification,
          outbid_notification: copyFromLot.outbid_notification,
          lum_group_code_list: copyFromLot.lum_group_code_list,
        };

        const formValid = await this.isFormValid();
        if (!formValid) return;

        await lotService.createLot(body);
        await this.$dialogs.alert("Create lot Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : // : this.$notify(error);
            this.$dialogs.alert(error);
      }
    },

    close() {
      this.$modal.hide("create-lot-modal");
    },
    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

export const tableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Action",
    field: "action",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Req ID",
    field: "request_id",
    type: "number",
  },
  {
    label: "Type",
    field: "reward_type",
  },
  {
    label: "App Name ",
    field: "app_id",
  },
  {
    label: "App CP ID",
    field: "app_campaign_id",
    type: "number",
  },
  {
    label: "App Ref",
    field: "app_ref",
    type: "number",
  },
  {
    label: "Theme ID",
    field: "theme_id",
    type: "number",
  },
  {
    label: "Pool ID",
    field: "pool_id",
    type: "number",
  },
  {
    label: "Item ID",
    field: "item_id",
    type: "number",
  },
];

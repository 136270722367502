var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              {
                staticClass: "form-group row",
                class: { "form-group--error": _vm.$v.product.name.$error },
              },
              [
                _c(
                  "label",
                  { staticClass: "col-md-3 form-control-label form__label" },
                  [_vm._v("Product Name")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.name,
                          expression: "product.name",
                        },
                      ],
                      ref: "name",
                      staticClass: "form-control form__input",
                      class: { "is-valid": !_vm.$v.product.name.$invalid },
                      attrs: { type: "text", autocomplete: "off" },
                      domProps: { value: _vm.product.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.product, "name", $event.target.value)
                        },
                      },
                    }),
                    _c("NameValidation", { attrs: { $v: _vm.$v } }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-md-3 form-control-label" }, [
                _vm._v("Product Description"),
              ]),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("ckeditor", {
                    attrs: { config: _vm.editorConfig },
                    model: {
                      value: _vm.product.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.product, "description", $$v)
                      },
                      expression: "product.description",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-close",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Cancel "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.addProduct },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Create New Product")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
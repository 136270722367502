import HttpRequest from "./request";

export class AuthProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_AUTH_SERVER_URL}/ams/auth`);
  }

  async login(username, password) {
    return await this.request(
      "POST",
      `${process.env.VUE_APP_AUTH_SERVER_URL}/ams/auth/login`,
      {},
      {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          "x-application-secret-key": process.env.VUE_APP_APP_SECRET_KEY,
        },
      }
    );
  }

  async logout() {
    return await this.request(
      "POST",
      `${process.env.VUE_APP_AUTH_SERVER_URL}/ams/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "x-application-secret-key": process.env.VUE_APP_APP_SECRET_KEY,
        },
      }
    );
  }
}

<template>
  <div class="col-8">
    <div class="input-wrapper">
      <label class="form-control-label">
        <h4>Story</h4>
        <p>Max 300 characters</p>
      </label>

      <textarea
        rows="3"
        ref="story"
        v-model="story"
        maxlength="300"
        @change="update"
        :disabled="disabled"
        class="form-control"
      />
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return {
      story: null,
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },

  mounted() {
    this.story = this.gameConfig.sunset_drive.story;
  },

  methods: {
    async isFormValid() {
      if (this.story && this.story.length > 300) {
        await this.$dialogs.alert("กรุณาระบุ Story ไม่เกิน 300 characters");
        this.$refs.story.focus();
        return false;
      }
      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await httpService.put(`/game/${this.gameConfig.id}`, {
          sunset_drive: { story: this.story },
        });

        this.$notify(`Game story updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Game story error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  display: flex;
  justify-content: space-between;

  padding: 0px;

  h4,
  p {
    margin-bottom: 0.5em;
  }
}
</style>

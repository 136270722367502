<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create New Point Campaign</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.campaign.name.$error }"
            >
              <label class="col-md-0 form-control-label form__label">
                <h5>Point Campaign Name</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="campaign.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">User Group</h5>
              <div class="col-md-12">
                <select
                  class="form-control col-md-12"
                  v-model="selectUserGroup"
                >
                  <option
                    v-for="group in usergroups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ ` ${group.id} - ${group.group_code}` }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="addCampaign"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletService, userGroupService } from "@/api/client";
import { responseErrorMapping } from "@/views/point/model/error";
import nameValidation from "@/views/point/validation/name";
import { FormCreateValidateRule as validateRules } from "@/views/point/validation";

export default {
  name: "Create",

  data() {
    return {
      usergroups: [],
      selectUserGroup: null,
      showErrorNameValidation: false,

      campaign: {
        name: "",
      },
    };
  },

  components: {
    nameValidation,
  },

  validations: { campaign: validateRules },

  async created() {
    await this.fetchUserGroup();
  },

  methods: {
    async fetchUserGroup() {
      this.usergroups = await userGroupService.getUserGroups();
    },

    async isFormValid() {
      if (this.$v.campaign.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Point Campaign Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      if (this.selectUserGroup == null) {
        await this.$dialogs.alert("กรุณากรอก User Group ให้ถูกต้อง");
        return false;
      }
      return true;
    },

    close() {
      this.$modal.hide("create-modal");
    },

    async getFormData() {
      return {
        name: this.campaign.name,
        group_id: this.selectUserGroup,
        
        // Default value.
        conv_point: 1,
        conv_coin: 1,
        discount_coin: 1,
        discount_baht: 10,
        markerup_percent: 5.0,

        can_scanqr: 0,
        get_discount: 0,
      };
    },

    async addCampaign() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const response = await walletService.createNewCampaign(body);
        await this.$dialogs.alert("Create point campaign Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Result</h1>
      <breadcrumb name="RESULT_LIST"></breadcrumb>
      <div class="form-inline" style="margin-top: 10px;">
        <label class="form-control-label">Year:</label>
        <date-picker
          v-model="year"
          type="year"
          format="YYYY"
          placeholder="All"
          valueType="format"
        ></date-picker>
        <Label class="form-control-label">Month:</Label>
        <select class="form-control" v-model="month">
          <option value="null">All</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <Label class="form-control-label">Campaigns</Label>
        <select class="form-control" v-model="campagin_id">
          <option value="" disabled>Please select campagin</option>
          <option
            v-for="campagin in campagins"
            :key="campagin.id"
            :value="campagin"
          >
            {{ campagin.id }} - {{ campagin.name }}
          </option>
        </select>
      </div>
      <hr />
      <div class="Excel">
        <button @click="exportToExcel" class="btn btn-default">
          <i class="fa fa-file-excel-o"></i>
          Export to Excel
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              :columns="columns"
              :rows="report"
              @on-row-click="goToDetail"
              @on-select-all="onSelectAll"
              @on-sort-change="onSortChange"
              @on-search="onSearch"
              :search-options="{
                enabled: true,
                placeholder: 'Search this',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: selected,
              }"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="detail-modal" :width="900" height="auto">
      <DetailModal :idDetail="idDetail" :campagin_id="campagin_id" />
    </modal>
  </div>
</template>

<script>
import { reportTableColumns } from "@/views/result/model";
import { reportService } from "@/api/client";
import DetailModal from "@/views/result/Detail.vue";
export default {
  name: "ResultList",
  data() {
    return {
      campagins: [],
      campagin_id: [],
      month: null,
      dowload: [],
      searchfilter: null,
      checksearchbox: 0,
      filter: null,
      selected: 10,
      columns: reportTableColumns,
      idDetail: null,
      year: null,
      orderby: null,
      desc: null,
      report: [],
    };
  },
  async created() {
    this.campagins = await reportService.getReport();
  },
  components: { DetailModal },
  watch: {
    year() {
      this.update();
    },
    month() {
      this.update();
    },
    campagin_id() {
      this.update_campagin_id();
    },
    report() {
      this.selected = 10;
    },
  },
  methods: {
    onSortChange(params) {
      this.orderby = params.lastItem.field;
      this.desc = params.lastItem.type == "asc" ? 0 : 1;
    },
    onSelectAll() {
      this.checksearchbox += 1;
    },
    onSearch(params) {
      this.checksearchbox = 0;
      this.searchfilter = params.searchTerm;
      this.checksearchbox += 1;
    },
    async update() {
      this.campagins = await reportService.getReport_month_year(
        this.month,
        this.year
      );
    },
    async update_campagin_id() {
      this.report = await reportService.getById(this.campagin_id.id);
    },
    async exportToExcel() {
      try {
        const response = await reportService.getReportDownload(
          this.campagin_id.id
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `report_campaign_id_${this.campagin_id.id}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },
    async goToDetail(event) {
      this.idDetail = await event.row.user_id;
      this.$modal.show(`detail-modal`);
    },
  },
};
</script>

<style scoped></style>

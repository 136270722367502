<template>
  <div class="form-group row" v-if="quota">
    <h5 class="col-md-12">Quota Limit Period</h5>
    <div class="d-flex">
      <label class="form-control-label">Start</label>
      <DatePicker
        ref="startDate"
        type="datetime"
        :confirm="true"
        @change="update"
        class="px-0"
        v-model="start_date"
        format="DD/MM/YYYY HH:mm"
        value-type="YYYY-MM-DD HH:mm:00"
        :disabled="!canUpdateStartDate"
      />
      <label class="form-control-label">End</label>
      <DatePicker
        ref="endDate"
        type="datetime"
        :confirm="true"
        @change="update"
        placeholder="n/a"
        class="px-0"
        v-model="end_date"
        format="DD/MM/YYYY HH:mm"
        value-type="YYYY-MM-DD HH:mm:00"
        :disabled="!canUpdateEndDate"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { walletService } from "@/api/client";
import { responseErrorMapping } from "../../model/error";

export default {
  name: "QuotaLimitPeriod",

  props: ["quota"],

  components: {
    DatePicker,
  },

  data() {
    return {
      end_date: "",
      start_date: "",

      oldValue: null,
    };
  },

  computed: {
    canUpdateEndDate() {
      return ["draft", "scheduled", "tracking"].includes(this.quota.status);
    },

    canUpdateStartDate() {
      return ["draft"].includes(this.quota.status);
    },
  },

  mounted() {
    const { start_date, end_date } = this.quota;
    this.oldValue = { start_date, end_date };

    this.end_date = end_date;
    this.start_date = start_date;
  },

  methods: {
    async isFormValid() {
      const { start_date, end_date } = this.$data;

      if (!start_date) {
        this.start_date = this.oldValue.start_date;

        await this.$dialogs.alert("กรุณากรอก Start date ให้ถูกต้อง");
        this.$refs.startDate.focus();
        return false;
      }

      if (
        moment(start_date).isSameOrBefore(moment()) &&
        ["draft"].includes(this.quota.status)
      ) {
        this.start_date = this.oldValue.start_date;
        await this.$dialogs.alert("เวลาเริ่มต้นต้องเป็นเวลาในอนาคตเท่านั้น");
        this.$refs.startDate.focus();
        return false;
      }

      if (moment(end_date).diff(start_date, "minutes") < 1) {
        this.end_date = this.oldValue.end_date;

        await this.$dialogs.alert("เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น");
        this.$refs.endDate.focus();
        return false;
      }

      if (
        moment(end_date).isSameOrBefore(moment()) &&
        ["scheduled", "tracking"].includes(this.quota.status)
      ) {
        this.end_date = this.oldValue.end_date;
        await this.$dialogs.alert("ระบุเวลาในอนาคตเท่านั้น");
        this.$refs.endDate.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        
      const formValid = await this.isFormValid();
      if (!formValid) return;

      const { id } = this.quota;
      const { start_date, end_date } = this.$data;

      await walletService.updateQuotaInfo(id, { end_date, start_date });

      this.oldValue = { start_date, end_date };
      this.$notify("Change Quota limit period success");
      this.$emit("updated");
      } catch (error) {
        this.end_date = this.oldValue.end_date;
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

export default {
  data() {
    return {
      totalRecords: 0,
      pagination: {
        perPage: 10,
        enabled: true,
        dropdownAllowAll: false,
        perPageDropdown: [5, 10, 15, 20, 30],
      },
      searchOption: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
      },
    };
  },

  methods: {
    onSearch(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            searchTerm: params.searchTerm,
          }),
        })
        .catch((err) => {});
    },

    onSortChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            sortBy: params[0].field,
            sortDir: params[0].type,
          }),
        })
        .catch((err) => {});
    },

    onPageChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            perPage: params.currentPerPage,
            page: params.currentPage,
          }),
        })
        .catch((err) => {});
    },

    onPerPageChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            perPage: params.currentPerPage,
            page: 1,
          }),
        })
        .catch((err) => {});
    },

    initialTableState(option) {
      //with option
      if (option) {
        this.sortOption = option;
        return;
      }

      //default
      if (!this.$route.query.sortBy || !this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.columns[0].field,
          type: "desc",
        };

      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = +this.$route.query.page;
      if (this.$route.query.perPage)
        this.pagination.perPage = +this.$route.query.perPage;
    },

    initialTableSearchState() {
      // Searching
      if (this.$route.query.searchTerm) {
        this.$refs["table"].globalSearchTerm = this.$route.query.searchTerm;
      }
    },
  },
};

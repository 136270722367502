<template>
  <div id="prize-status-bulk-upload" v-if="isAdminOrSupervisor">
    <div class="inner">
      <div v-if="status != null">
        <div class="wrapper-upload-form" v-if="status.active === 0">
          <label
            :disabled="loading"
            for="bulk-upload-file-input"
            class="btn btn-round btn-upload"
          >
            <i class="fa fa-upload" aria-hidden="true"></i>
            Bulk update
          </label>

          <input
            type="file"
            @change="onFileSelected"
            class="input-select-file"
            id="bulk-upload-file-input"
          />
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="
            status.active === 1 &&
            status.type == 'pool' &&
            status.state != 'execute'
          "
        >
          <label class="label-orange" v-if="status.state == 'verify'">
            Verifying change
          </label>

          <label
            class="label-blue"
            v-else-if="status.state == 'done-verify' && loading"
          >
            Loading...
          </label>

          <label
            class="label-blue"
            v-else-if="status.state == 'done-verify' && !loading"
          >
            Click to commit change
          </label>

          <div v-if="status.state == 'verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              disabled
            >
              Commit
            </button>
            <button type="button" class="btn btn-round btn-default" disabled>
              Cancel
            </button>
          </div>
          <div v-else-if="status.state == 'done-verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="getResult"
              :disabled="loading"
            >
              Commit
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="cancelProcess"
              :disabled="loading"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="
            status.active === 1 &&
            status.type == 'pool' &&
            status.state == 'execute'
          "
        >
          <label class="label-orange"> Processing change. </label>
        </div>
      </div>

      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="mdi mdi-file-excel-outline" aria-hidden="true"></i>
          Download template
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" :href="downloadExampleLink('add')">
            <b>Add</b> action template
          </a>
          <a class="dropdown-item" :href="downloadExampleLink('update')">
            <b>Update</b> action template
          </a>
          <a class="dropdown-item" :href="downloadExampleLink('remove')">
            <b>Remove</b> action template
          </a>
        </div>
      </div>

      <p class="mb-0">
        Last updated: {{ detail.last_pool_upload }} by
        {{ detail.last_pool_upload_username || "-" }}
      </p>
    </div>

    <modal :width="600" height="auto" :clickToClose="false" :name="modalName">
      <TryUploadModal
        :result="result"
        :name="modalName"
        @confirm="confirmUpload"
      />
    </modal>
  </div>
</template>
<script>
import { prizepoolService } from "@/api/client";
import TryUploadModal from "./TryUploadModal.vue";

const modalName = "prize-status-bulk-upload-confirm-modal";

export default {
  name: "PrizePoolStatusBulkUpload",

  props: ["detail"],

  components: { TryUploadModal },

  data() {
    return {
      file: null,
      status: null,
      result: null,
      loading: false,
      pullingId: null,
      modalName: modalName,
    };
  },

  computed: {
    isAdminOrSupervisor() {
      const role = this.$store.state.auth.role;
      return ["admin", "supervisor"].includes(role);
    },
  },

  watch: {
    status(n, o) {
      if (o) {
        if (n.active === 0 && o.active === 1) {
          this.$emit("updated");
        }
      }

      if (
        n.active === 1 &&
        n.type == "pool" &&
        n.state == "verify" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "pool" &&
        n.state == "execute" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "pool" &&
        n.state == "verify" &&
        n.status == "pending" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (n.active === 0 || (n.type == "pool" && n.state == "done-verify")) {
        this.stopPullingStatus();
        return;
      }
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      event.target.value = null;

      this.tryUpload();
    },

    async confirmUpload() {
      try {
        this.loading = true;
        const { id } = this.$route.params;

        await prizepoolService.tryUploadConfirmStatus(id);

        this.getUploadStatus();
        this.$notify("Bulk update success");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Bulk update error");
      } finally {
        this.loading = false;
      }
    },

    async getResult() {
      try {
        const { id } = this.$route.params;
        const result = await prizepoolService.tryUploadResultStatus(id);

        if (!result.error) {
          this.result = result;
          this.$modal.show(this.modalName);
        } else {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async cancelProcess() {
      try {
        this.loading = true;
        const { id } = this.$route.params;
        await prizepoolService.tryUploadCancelStatus(id);

        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      } finally {
        this.loading = false;
      }
    },

    async tryUpload(event) {
      if (!this.file) return;
      try {
        this.loading = true;

        const { id } = this.$route.params;

        const form = new FormData();
        form.append("excel", this.file);

        await prizepoolService.tryUploadStatus(id, form);

        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try Bulk update error");
      } finally {
        this.loading = false;
      }
    },

    async getUploadStatus() {
      const { id } = this.$route.params;
      this.status = await prizepoolService.getUploadStatus(id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 3000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },

    downloadExampleLink(action) {
      return `${
        process.env.VUE_APP_GAME_WORLD_BUCKET_URI
      }/ams/prize_summary/prizepool-upload-${action}.xlsx?ts=${new Date().getTime()}`;
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  created() {
    this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
#prize-status-bulk-upload {
  display: flex;
  position: relative;
  justify-content: flex-end;

  .inner {
    z-index: 1;
    right: 0px;
    bottom: -38px;
    position: absolute;
    background-color: #f1f4f5;

    padding: 0px 1rem;

    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .input-select-file {
      width: 0px;
      visibility: hidden;
    }

    .btn-upload {
      margin-bottom: 0px;
      font-weight: 300;
      border-radius: 0.5em;
      background-color: #e5eaec;
      &[disabled="disabled"] {
        color: #c1c1c1;
        cursor: not-allowed;
        background-color: #e5eaec90;
      }
    }

    [type="file"] {
      height: 0px;
      padding: 0px;
    }
  }

  .label-orange {
    color: #dc6d35;
    font-weight: 500;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lot
    ? _c(
        "div",
        { staticClass: "mx-10 col-12" },
        [
          _vm._m(0),
          _c("ckeditor", {
            attrs: { "read-only": !_vm.canEdit },
            on: { blur: _vm.update },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-label" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Description")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
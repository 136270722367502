import Vue from "vue";

Vue.mixin({
  methods: {
    async wait(ms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, ms || 1000);
      });
    },
  },
});

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quota
    ? _c("div", { staticClass: "page" }, [
        _c(
          "div",
          { staticClass: "page-main" },
          [
            _c("PageHeader", { attrs: { quota: _vm.quota } }),
            _c("div", { staticClass: "page-content container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                  },
                  [
                    _c("div", { staticClass: "panel panel-bordered" }, [
                      _c("div", { staticClass: "panel-heading" }, [
                        _c("h3", { staticClass: "panel-title" }, [
                          _vm._v("Quota Information - " + _vm._s(_vm.quota.id)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "panel-actions panel-actions-keep" },
                          [
                            _vm._v(" Quota Type : "),
                            _c("b", [_vm._v(_vm._s(_vm.discountType))]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "panel-body" }, [
                        _c("form", { staticClass: "form-horizontal" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-md-6" },
                              [
                                _c("QuotaName", {
                                  attrs: { quota: _vm.quota },
                                  on: { updated: _vm.fetchQuota },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group col-md-3" },
                              [
                                _c("StatusDropdown", {
                                  attrs: { quota: _vm.quota },
                                  on: { updated: _vm.fetchQuota },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("CampaignList", {
                                  attrs: { quota: _vm.quota },
                                  on: { updated: _vm.fetchQuota },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("QuotaAmount", {
                                  attrs: { quota: _vm.quota },
                                  on: { updated: _vm.fetchQuota },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-8" },
                              [
                                _c("QuotaLimitPeriod", {
                                  attrs: { quota: _vm.quota },
                                  on: { updated: _vm.fetchQuota },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._m(0),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
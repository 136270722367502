var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          {
            staticClass:
              "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
          },
          [
            _c("h1", { staticClass: "page-title" }, [_vm._v("History")]),
            _c("breadcrumb", {
              attrs: { name: "HISTORY_VIEW", withLast: _vm.history.lot_name },
              on: {
                "update:withLast": function ($event) {
                  return _vm.$set(_vm.history, "lot_name", $event)
                },
                "update:with-last": function ($event) {
                  return _vm.$set(_vm.history, "lot_name", $event)
                },
              },
            }),
            _c("div", { staticClass: "page-header-actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-round btn-default mx-10",
                  attrs: { type: "button" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("i", {
                    staticClass: "icon md-chevron-left",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Back "),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "page-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
            },
            [
              _c("div", { staticClass: "panel panel-bordered" }, [
                _vm._m(0),
                _c("div", { staticClass: "panel-body" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(1),
                      _c("br"),
                      _c("label", [
                        _c("h5", [_vm._v(_vm._s(_vm.history.lot_id))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(2),
                      _c("br"),
                      _c("label", [
                        _c("h5", [_vm._v(_vm._s(_vm.history.lot_name))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(3),
                      _c("br"),
                      _c("label", [
                        _c("h5", [_vm._v(_vm._s(_vm.history.start_date))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(4),
                      _c("br"),
                      _c("label", [
                        _c("h5", [_vm._v(_vm._s(_vm.history.num_winner))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(5),
                      _c("br"),
                      _c("label", [
                        _vm.history.type == "auction"
                          ? _c("h5", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toUpperCase")(_vm.history.type)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm.history.type == "luckydraw"
                          ? _c("h5", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("capitalize")(_vm.history.type)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(6),
                      _c("br"),
                      _c("label", [
                        _c("h5", { staticClass: "limit-line-2" }, [
                          _vm._v(_vm._s(_vm.history.product_name)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _vm._m(7),
                      _c("br"),
                      _c("label", [
                        _c("h5", [_vm._v(_vm._s(_vm.history.end_date))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _c("label", [
                        _vm.history.type == "auction"
                          ? _c("h5", [_vm._v(" Starting/Winning bid ")])
                          : _vm.history.type == "luckydraw"
                          ? _c("h5", [_vm._v(" Points per entry ")])
                          : _vm._e(),
                      ]),
                      _c("br"),
                      _c("label", [
                        _vm.history.type == "auction"
                          ? _c("h5", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.history.minimum_points) +
                                  "/" +
                                  _vm._s(_vm.history.winning_bid) +
                                  " "
                              ),
                            ])
                          : _vm.history.type == "luckydraw"
                          ? _c("h5", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.history.minimum_points) +
                                  "/" +
                                  _vm._s(_vm.history.winning_bid) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "panel-footer" }, [
                  _c("h3", { staticClass: "panel-title" }, [
                    _vm._v("Activity Log"),
                  ]),
                  _c("div", { staticClass: "Excel" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: { click: _vm.exportToExcel },
                      },
                      [
                        _c("i", { staticClass: "fa fa-file-excel-o" }),
                        _vm._v(" Export to Excel "),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "page-content page-content-table" },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "panel" }, [
                          _c(
                            "div",
                            { staticClass: "panel-body p-0" },
                            [
                              _c("vue-good-table", {
                                attrs: {
                                  columns: _vm.columns,
                                  rows: _vm.history.activity_log || [],
                                  "pagination-options": {
                                    enabled: true,
                                    mode: "records",
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Summary")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Lot id")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Lot Name")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Start")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("No. Winners")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Lot Type")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("Product Name")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h5", [_vm._v("End")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=16256f34&scoped=true&"
import script from "./Info.vue?vue&type=script&lang=js&"
export * from "./Info.vue?vue&type=script&lang=js&"
import style0 from "./Info.vue?vue&type=style&index=0&id=16256f34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16256f34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16256f34')) {
      api.createRecord('16256f34', component.options)
    } else {
      api.reload('16256f34', component.options)
    }
    module.hot.accept("./Info.vue?vue&type=template&id=16256f34&scoped=true&", function () {
      api.rerender('16256f34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/prize/pool_status/components/Info.vue"
export default component.exports
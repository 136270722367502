<template>
  <div class="col-md-8">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Terms & Condition
          </h4>
        </label>

        <div class="form-group">
          <ckeditor
            @blur="submit"
            :read-only="disabled"
            v-model="term_condition"
            :config="{
              extraPlugins: 'colorbutton',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyDrawService } from "@/api/client";

export default {
  name: "TermsAndCondition",

  props: ["campaign"],

  data() {
    return {
      term_condition: "",
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },

    formData() {
      return {
        term_condition: this.term_condition,
      };
    },
  },

  methods: {
    async submit() {
      if (!this.campaign.term_condition && !this.term_condition) return;

      if (
        this.isString(this.campaign.term_condition) &&
        this.isString(this.term_condition) &&
        this.campaign.term_condition.substr(0, this.campaign.term_condition.length) ==
          this.term_condition.substr(0, this.term_condition.length)
      ) {
        return;
      }

      try {
        const { id } = this.campaign;
        const form = this.formData;

        await luckyDrawService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`Terms & Condition updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Terms & Condition error.`);
      }
    },

    isString(myVar) {
      return typeof myVar === "string" || myVar instanceof String;
    },
  },

  mounted() {
    this.term_condition = this.campaign.term_condition;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.wrapper-image-preview {
  text-align: center;
  min-height: 300px;
  background-color: #efefef;
}

.image-preview {
  padding: 12px;
  min-height: 300px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  min-width: 420px;
}
</style>

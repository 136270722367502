import axios from "axios";
import store from "../../store";

class HttpRequest {
  constructor(url = `${process.env.VUE_APP_SERVICE_URL}/ams/api`) {
    // this.axios = axios
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 120000,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    this.axiosInstance.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        return new Promise(function (resolve, reject) {
          if (
            err.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            window.location = "/logout";
          }
          throw err;
        });
      }
    );

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      function (response) {
        // Do something with response data
        response.status;
        return response;
      },
      function (error) {
        // Do something with response error
        if (error.response.status === 401) {
          store.dispatch("auth/unauthorized");
        }
        return Promise.reject(error);
      }
    );
  }

  setHeader(header) {
    // this.axiosInstance.defaults.headers.common[header.key] = header.value
    this.axiosInstance.defaults.headers.common = header;
    this.axiosInstance.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
  }

  get(url, data) {
    return this.axiosInstance.get(url, {
      params: data,
    });
  }

  create(url, data) {
    return this.axiosInstance.post(url, data);
  }

  update(url, data) {
    return this.axiosInstance.put(url, data);
  }

  delete(url, param, data) {
    return this.axiosInstance.delete(url, {
      params: param,
      data: data,
    });
  }

  upload(url, data) {
    return this.axiosInstance.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  request(type, url, data, config) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, { params: data });
        break;
      case "POST":
        promise = axios.post(url, data, config);
        break;
      case "PUT":
        promise = axios.put(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default HttpRequest;

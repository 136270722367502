<template>
  <div class="row">
    <div class="col-4">
      <div class="radio-input-row radio-custom radio-primary">
        <input
          type="radio"
          value="prizepool"
          :disabled="disabled"
          v-model="reward.reward_type"
          :name="`reward_type_${index}`"
          :id="`reward_type_prizepool_${index}`"
          @change="update($event, 'reward_type')"
        />
        <label :for="`reward_type_prizepool_${index}`">Prize Pool</label>
      </div>
    </div>
    <div class="col-8">
      <div class="form-group-row">
        <label :for="`prize_theme_id_${reward.id}`">Prize Theme ID</label>
        <select
          @change="update"
          class="form-control"
          name="prize_theme_id"
          :id="`prize_theme_id_${reward.id}`"
          v-model.number="reward.prize_theme_id"
          :disabled="disabled || reward.reward_type !== 'prizepool'"
        >
          <option :value="null" disabled>Not Select</option>
          <option v-for="prize in prizepool" :value="prize.id" :key="prize.id">
            {{ prize.id }} - {{ prize.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  props: ["prizepool"],

  mixins: [rewardMixins],

  data() {
    return {
      message: {
        error: `Update Prize pool of Reward #${this.reward.id} error.`,
        success: `Update Prize pool of Reward #${this.reward.id} success.`,
      },
    };
  },
};
</script>

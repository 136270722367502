var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.leaderboard && _vm.status != null
    ? _c(
        "div",
        { staticClass: "col-md-10" },
        [
          _vm.action == "ready" && _vm.status.active == 0
            ? _c("div", { staticClass: "wrapper-upload-form" }, [
                _vm._m(0),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-round",
                    attrs: { type: "button", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.setAction("editing")
                      },
                    },
                  },
                  [_vm._v(" Edit ")]
                ),
              ])
            : _vm.action == "editing" && _vm.status.active == 0
            ? _c("div", { staticClass: "wrapper-upload-form" }, [
                _vm._m(1),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-round btn-primary mx-10",
                      attrs: { type: "button", disabled: _vm.disabled },
                      on: { click: _vm.handleVerify },
                    },
                    [_vm._v(" Verify ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-round btn-default",
                      attrs: { type: "button", disabled: _vm.disabled },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ]),
              ])
            : _vm.status.active == 1
            ? _c("div", { staticClass: "wrapper-upload-form" }, [
                _vm._m(2),
                _vm.status.state == "verify"
                  ? _c("label", { staticClass: "label-blue" }, [
                      _vm._v(" Verifying change "),
                    ])
                  : _vm.status.state == "execute" &&
                    _vm.status.status == "pending"
                  ? _c("label", { staticClass: "label-orange" }, [
                      _vm._v(" Executing... "),
                    ])
                  : _vm.status.state == "done-verify" &&
                    _vm.status.status !== "failure"
                  ? _c("label", { staticClass: "label-blue" }, [
                      _vm._v(" Click to commit change "),
                    ])
                  : _vm.status.state == "done-verify" &&
                    _vm.status.status === "failure"
                  ? _c("label", { staticClass: "label-red" }, [
                      _vm._v(" Verifying change failure "),
                    ])
                  : _vm._e(),
                _vm.status.state == "verify" && _vm.campaign.status != "draft"
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-primary mx-10",
                          attrs: { type: "button", disabled: "" },
                        },
                        [_vm._v(" Commit ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-default",
                          attrs: { type: "button", disabled: "" },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.status.state == "execute" && _vm.status.status == "pending"
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-primary mx-10",
                          attrs: { type: "button", disabled: "" },
                        },
                        [_vm._v(" Commit ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-default",
                          attrs: { type: "button", disabled: "" },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ])
                  : _vm.status.state == "done-verify"
                  ? _c("div", [
                      _vm.status.status === "success"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-round btn-primary mx-10",
                              attrs: { type: "button", disabled: _vm.disabled },
                              on: { click: _vm.getResult },
                            },
                            [_vm._v(" Commit ")]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-default",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: { click: _vm.handleCancel },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "Selectize",
            {
              attrs: {
                multiple: "",
                disabled: !_vm.canEdit,
                settings: _vm.selectizeSetting,
              },
              model: {
                value: _vm.skuSelected,
                callback: function ($$v) {
                  _vm.skuSelected = $$v
                },
                expression: "skuSelected",
              },
            },
            _vm._l(_vm.skus, function (sku, index) {
              return _c(
                "option",
                { key: index, domProps: { value: sku.PRO_CODE } },
                [
                  _vm._v(
                    " " + _vm._s(sku.PRO_NAME_EN + " - " + sku.PRO_CODE) + " "
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "try-update-sku-modal",
                width: 600,
                height: "auto",
              },
            },
            [
              _c("TryUploadModal", {
                attrs: {
                  campaign: _vm.campaign,
                  result: _vm.result,
                  name: "try-update-sku-modal",
                },
                on: { confirm: _vm.confirmUpdate },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Target SKUs (counted toward progress)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Target SKUs (counted toward progress)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Target SKUs (counted toward progress)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
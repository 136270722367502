<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Game Instance</h1>
      <breadcrumb name="GAME_EDIT" :withLast.sync="game.name"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="goBack"
        >
          <i class="icon md-chevron-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <div class="panel panel-bordered">
            <div class="panel-heading">
              <h3 class="panel-title">
                Game Instance Information - {{ game.id }}
              </h3>
              <div class="form-row">
                <h5 class="col-md-5">
                  <div class="text-left">
                    Cigarette {{ game.game | capitalize }}
                  </div>
                </h5>
                <h5 class="col-md-5">
                  User Groups: {{ usergroups.group_code }}
                </h5>
              </div>
            </div>
            <div class="panel-body">
              <form class="form-horizontal">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-control-label">
                      <h5>Game Instance Name</h5>
                    </label>
                    <input
                      type="text"
                      ref="gameName"
                      maxlength="255"
                      class="form-control"
                      v-model="game.name"
                      :disabled="isDisable.name"
                      @change="submit('name')"
                    />
                    <nameValidation :$v="$v" />
                  </div>
                  <div class="form-group col-md-3">
                    <div class="px-15">
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-primary btn-round dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          style="text-transform: uppercase;"
                        >
                          {{ game.status }}
                        </button>
                        <div class="dropdown-menu" role="menu">
                          <a
                            v-if="game.status == 'draft'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('scheduled')"
                          >
                            <i
                              class="icon md-long-arrow-up"
                              aria-hidden="true"
                            ></i
                            >Publish
                          </a>
                          <a
                            v-if="game.status == 'draft'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('archived')"
                          >
                            <i class="icon md-archive" aria-hidden="true"></i
                            >Archive
                          </a>
                          <a
                            v-if="game.status == 'scheduled'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('draft')"
                          >
                            <i
                              class="icon md-long-arrow-down"
                              aria-hidden="true"
                            ></i
                            >Unpublished
                          </a>
                          <a
                            v-if="game.status == 'archived'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('draft')"
                          >
                            <i class="icon md-archive" aria-hidden="true"></i
                            >Unarchive
                          </a>
                          <a
                            v-if="game.status == 'ongoing'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('paused')"
                          >
                            <i class="icon md-archive" aria-hidden="true"></i
                            >Pause
                          </a>
                          <a
                            v-if="game.status == 'ongoing'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('end')"
                          >
                            <i class="icon md-archive" aria-hidden="true"></i
                            >Stop
                          </a>
                          <a
                            v-if="game.status == 'paused'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('ongoing')"
                          >
                            <i class="icon md-archive" aria-hidden="true"></i
                            >Restart
                          </a>
                          <a
                            v-if="game.status == 'paused'"
                            class="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            @click="setStatus('end')"
                          >
                            <i class="icon md-archive" aria-hidden="true"></i
                            >Stop
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5 class="col-md-12">Game Instance Duration</h5>
                <div class="form-group row">
                  <label class="col-md-1 form-control-label">Start</label>
                  <section>
                    <date-picker
                      v-model="game.start_date"
                      type="datetime"
                      placeholder="Select datetime"
                      value-type="format"
                      format="YYYY-MM-DD HH:mm:ss"
                      :show-time-panel="showTimePanel"
                      :disabled="isDisable.duration"
                      ref="game_start_date"
                      @change="clearDate(true)"
                      @close="handleOpenChange(true)"
                    >
                      <template v-slot:footer>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimePanel"
                        >
                          {{ showTimePanel ? "select date" : "select time" }}
                        </button>
                      </template>
                    </date-picker>
                  </section>
                  <label class="col-md-1 form-control-label">End</label>
                  <section>
                    <date-picker
                      v-model="game.end_date"
                      type="datetime"
                      placeholder="n/a"
                      value-type="format"
                      format="YYYY-MM-DD HH:mm:ss"
                      :show-time-panel="showTimePanel"
                      :disabled-date="AfterStartDate"
                      :disabled="isDisable.durationEnd"
                      ref="game_start_date"
                      @change="clearDate(false)"
                      @close="handleOpenChange(false)"
                    >
                      <template v-slot:footer>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimePanel"
                        >
                          {{ showTimePanel ? "select date" : "select time" }}
                        </button>
                      </template>
                    </date-picker>
                  </section>
                </div>
                <hr />
                <h5 class="col-md-12">Game Configuration</h5>

                <div class="row">
                  <div class="text-con">
                    <label>No. play per day</label>
                  </div>
                  <div class="w80">
                    <label>Monday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_mon"
                      class="center form-control"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      @change="submit('no_play_mon')"
                      ref="no_play_mon"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                  <div class="w80">
                    <label>Tuesday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_tue"
                      class="center form-control"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      @change="submit('no_play_tue')"
                      ref="no_play_tue"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                  <div class="w80">
                    <label>Wednesday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_wed"
                      class="center form-control"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      @change="submit('no_play_wed')"
                      ref="no_play_wed"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                  <div class="w80">
                    <label>Thursday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_thu"
                      class="center form-control"
                      @change="submit('no_play_thu')"
                      ref="no_play_thu"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                  <div class="w80">
                    <label>Friday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_fri"
                      class="center form-control"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      @change="submit('no_play_fri')"
                      ref="no_play_fri"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                  <div class="w80">
                    <label>Saturday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_sat"
                      class="center form-control"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      @change="submit('no_play_sat')"
                      ref="no_play_sat"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                  <div class="w80">
                    <label>Sunday</label>
                    <input
                      type="number"
                      maxlength="3"
                      v-model="game.no_play_sun"
                      class="center form-control"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      @change="submit('no_play_sun')"
                      ref="no_play_sun"
                      :disabled="isDisable.configuration"
                    />
                  </div>
                </div>
                <hr />
                <h5 class="col-md-12">
                  Game Specific Setting - Cigarette {{ game.game | capitalize }}
                </h5>
                <div v-if="typeGame == 'fortune'">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" class="center">Label</th>
                        <th scope="col" class="center">Point</th>
                        <th scope="col" class="center">
                          Chance Weight
                        </th>
                        <th scope="col" class="center">Meaning</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(set, index) in label_set" :key="index">
                        <th scope="row" class="center">
                          <div class="space-top">
                            {{ set }}
                          </div>
                        </th>
                        <td class="input-table">
                          <div class="center">
                            <input
                              type="number"
                              max="999"
                              min="0"
                              class="form-control"
                              v-model="setting[index].point"
                              @change="submitSetting('point', set, index)"
                              :disabled="isDisable.specific_setting"
                            />
                          </div>
                        </td>
                        <td class="input-table">
                          <div class="center">
                            <input
                              type="number"
                              max="1000000"
                              min="0"
                              class="form-control"
                              v-model="setting[index].chance_weight"
                              @change="
                                submitSetting('chance_weight', set, index)
                              "
                              :disabled="isDisable.specific_setting"
                            />
                          </div>
                        </td>
                        <td>
                          <textarea
                            class="form-control"
                            rows="2"
                            maxlength="255"
                            v-model="setting[index].meaning1"
                            @change="submitSetting('meaning1', set, index)"
                            :disabled="isDisable.specific_setting"
                          ></textarea>
                          <div class="space-top">
                            <textarea
                              class="form-control"
                              rows="2"
                              maxlength="255"
                              v-model="setting[index].meaning2"
                              @change="submitSetting('meaning2', set, index)"
                              :disabled="isDisable.specific_setting"
                            ></textarea>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else-if="typeGame == 'sustainable'" class="text-left">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Game duration (second)</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="0"
                        max="1000"
                        v-model="setting.game_duration"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('game_duration')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label"
                      >Average SF point</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000"
                        v-model="setting.average_sf_point"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('average_sf_point')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >No. Cigarette per point</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000000"
                        v-model="setting.cigarettes_per_point"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('cigarettes_per_point')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label"
                      >No. Cigarette per obstacle</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000000"
                        v-model="setting.cigarette_obstacle_ratio"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('cigarette_obstacle_ratio')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Penalty shell</label>
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="-1000"
                        max="0"
                        v-model="setting.penalty_shell"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('penalty_shell')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">Penalty crab</label>
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="-1000"
                        max="0"
                        v-model="setting.penalty_crab"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('penalty_crab')"
                      />
                    </div>
                  </div>
                </div>
                <div v-else-if="typeGame == 'space'" class="text-left">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Base duration (second)</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="0"
                        max="300"
                        v-model="setting.base_duration"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('base_duration')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label"
                      >Average SF point</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000"
                        v-model="setting.base_sf_points"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('base_sf_points')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >No. VIP objects</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="100"
                        v-model="setting.no_vip_objects"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('no_vip_objects')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label"
                      >VIP object time (second)
                    </label>
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="100"
                        v-model="setting.vip_object_time"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('vip_object_time')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Avg. obstacles per minute</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000000"
                        v-model="setting.avg_obstacles_per_min"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('avg_obstacles_per_min')"
                      />
                    </div>
                  </div>
                </div>
                <div v-else-if="typeGame == 'run'" class="text-left">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Base duration (second)</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="0"
                        max="300"
                        v-model="setting.base_duration"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('base_duration')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">Base SF point</label>
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000"
                        v-model="setting.base_sf_points"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('base_sf_points')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >No. VIP objects</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="7"
                        v-model="setting.no_vip_objects"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('no_vip_objects')"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label"
                      >VIP object time (second)
                    </label>
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000"
                        v-model="setting.vip_object_time"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('vip_object_time')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Avg. obstacles per minute</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="30"
                        v-model="setting.avg_obstacles_per_min"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('avg_obstacles_per_min')"
                      />
                    </div>
                  </div>
                </div>
                <div v-else-if="typeGame == 'advocacy'" class="text-left">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label"
                      >Time per questions (second)</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="0"
                        max="300"
                        v-model="setting.time_per_questions"
                        :disabled="isDisable.specific_setting"
                        @change="submitSetting('time_per_questions')"
                      />
                    </div>
                    <label class="col-sm-3 col-form-label"
                      >No. questions per game</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="1000"
                        :disabled="isDisable.specific_setting"
                        v-model="setting.no_questions_per_game"
                        @change="submitSetting('no_questions_per_game')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Questions List</label
                    >
                  </div>
                  <div class="row" style="text-align: center;">
                    <div class="col-4">
                      <h3>Available list</h3>
                      <div class="scrollList">
                        <draggable
                          class="list-group"
                          :list="list1"
                          ghost-class="ghost"
                          group="people"
                          :disabled="isDisable.specific_setting"
                        >
                          <div
                            class="list-group-item"
                            v-for="question in list1"
                            :key="question.id"
                            @click="OnClickChosen(question, true)"
                            :style="
                              idChose.id == question.id
                                ? 'background-color: rgba(93, 93, 231, 0.616);'
                                : null
                            "
                          >
                            {{ question.name }} -
                            {{ NameChar(question.character) }}
                          </div>
                        </draggable>
                      </div>
                    </div>

                    <div class="col-2">
                      <div class="row-center">
                        <div class="col">
                          <button
                            type="button"
                            class="btn btn-round btn-default"
                            :disabled="
                              onList != false || isDisable.specific_setting
                            "
                            @click="MoveListChosen(true)"
                          >
                            <i
                              class="fas fa-arrow-left"
                              style="font-size: 48px;"
                            ></i>
                          </button>
                        </div>
                        <div class="col">
                          <button
                            type="button"
                            class="btn btn-round btn-default"
                            @click="MoveListChosen(false)"
                            :disabled="
                              onList != true || isDisable.specific_setting
                            "
                          >
                            <i
                              class="fas fa-arrow-right"
                              style="font-size: 48px;"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <h3>Chosen list</h3>
                      <div class="scrollList">
                        <draggable
                          class="list-group"
                          :list="list2"
                          ghost-class="ghost"
                          group="people"
                          @change="chosenList()"
                          :disabled="isDisable.specific_setting"
                        >
                          <div
                            class="list-group-item"
                            v-for="question in list2"
                            :key="question.id"
                            @click="OnClickChosen(question, false)"
                            :style="
                              idChose.id == question.id
                                ? 'background-color: rgba(93, 93, 231, 0.616);'
                                : null
                            "
                          >
                            {{ question.name }} -
                            {{ NameChar(question.character) }}
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5 class="col-md-12">
                  <label>Redirect URL</label>
                </h5>

                <div class="form-row">
                  <div class="col-md-6 mb-1">
                    <div class="text-left">
                      <input
                        type="text"
                        class="form-control"
                        v-model="game.redirect_url"
                        maxlength="255"
                        @change="submit('redirect_url')"
                        :disabled="isDisable.url"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="panel-footer">
              <div class="d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { questionService } from "@/api/client";
import { responseErrorMapping } from "./model/error";
import { userGroupService } from "@/api/client";
import { FormCreateValidateRule as validateRules } from "./validation";
import moment from "moment";
import nameValidation from "./validation/name";
import { gameService } from "@/api/client";
export default {
  name: "GamesInstanceListEdit",
  data() {
    return {
      questions: [],
      oldgame: [],
      list1: [],
      list2: [],
      idChose: [],
      dragging: false,
      oldsetting: [],
      onList: null,
      game: [],
      showTimePanel: false,
      showTimeRangePanel: false,
      isDisable: {
        name: true,
        duration: true,
        durationEnd: true,
        configuration: true,
        specific_setting: true,
        url: true,
      },
      usergroups: [],
      setting: [
        { point: null },
        { point: null },
        { point: null },
        { point: null },
        { point: null },
        { point: null },
        { point: null },
        { point: null },
        { point: null },
      ],
      label_set: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      id: null,
      typeGame: null,
    };
  },
  async created() {
    this.id = await this.$route.params.id;
    this.fetchGame();
  },
  validations: { game: validateRules },
  components: {
    nameValidation,
    draggable,
  },
  methods: {
    isCheck(field) {
      const check = field;
      switch (check) {
        /// Name
        case "name":
          if (
            this.game[check] == null ||
            this.game[check] == "" ||
            this.game[check].length > 255
          ) {
            this.game[check] = this.oldgame["name"];
            return false;
          }

        //// Day Week
        case "no_play_mon":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        case "no_play_tue":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        case "no_play_wed":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        case "no_play_thu":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        case "no_play_fri":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        case "no_play_sat":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        case "no_play_sun":
          if (
            this.game[check] < 0 ||
            this.game[check] > 999 ||
            this.game[check] == ""
          ) {
            this.alert("No. play per day สามารถใส่ได้ตั้ง 0-999");
            this.game[check] = this.oldgame[check];
            return false;
          }
          return true;

        //// Start Date
        case "start_date":
          if (moment(this.game[check]) >= moment(this.game["end_date"])) {
            this.alert(
              "ไม่สามารถใส่ค่าที่ Start date/time หลัง End date/time ได้"
            );
            this.game["start_date"] = this.oldgame["start_date"];
            return false;
          }
          return true;

        case "end_date":
          if (moment(this.game["start_date"]) >= moment(this.game[check])) {
            this.alert("ไม่สามารถใส่ค่าที่เกิดก่อน Start date/time ได้");
            this.game["end_date"] = this.oldgame["end_date"];
            return false;
          }
          return true;
        case "redirect_url":
          if (this.game[check].length > 255) {
            return false;
          }

          return true;
      }
    },
    isCheckSetting(field, label) {
      const check = field;
      switch (check) {
        /// Name
        case "point":
          if (
            this.setting[label][field] > 999 ||
            this.setting[label][field] < 0
          ) {
            this.alert("point สามารถใส่ได้ตั้ง 0-999");
            this.setting[label][field] = this.oldsetting[label][field];
            return false;
          } else if (
            this.setting[label][field] == "" ||
            this.setting[label][field] == null
          ) {
            this.setting[label][field] = 0;
          }
          return true;
        case "chance_weight":
          if (
            this.setting[label][field] > 1000000 ||
            this.setting[label][field] < 0
          ) {
            this.alert("Chance Weight สามารถใส่ได้ตั้ง 0-1,000,000");
            this.setting[label][field] = this.oldsetting[label][field];
            return false;
          } else if (
            this.setting[label][field] == "" ||
            this.setting[label][field] == null
          ) {
            this.setting[label][field] = 0;
          }
          return true;
        case "meaning1":
          if (this.setting[label][field].length > 255) {
            return false;
          }
          return true;
        case "meaning2":
          if (this.setting[label][field].length > 255) {
            return false;
          }
          return true;
        //sustainable
        case "game_duration":
          if (
            this.setting.game_duration < 0 ||
            this.setting.game_duration > 300
          ) {
            this.alert("Game duration สามารถใส่ได้ตั้งแต่ 0-300");
            this.setting.game_duration = this.oldsetting.game_duration;
            return false;
          } else if (
            this.setting.game_duration == "" ||
            this.setting.game_duration == null
          ) {
            this.setting.game_duration = 0;
          }
          return true;
        case "average_sf_point":
          if (
            this.setting.average_sf_point < 1 ||
            this.setting.average_sf_point > 1000 ||
            this.setting.average_sf_point == null
          ) {
            this.alert("Average SF point สามารถใส่ได้ตั้งแต่ 1-1,000");
            this.setting.average_sf_point = this.oldsetting.average_sf_point;
            return false;
          }
          return true;
        case "cigarettes_per_point":
          if (
            this.setting.cigarettes_per_point < 1 ||
            this.setting.cigarettes_per_point > 1000000 ||
            this.setting.cigarettes_per_point == null
          ) {
            this.alert(
              "No. Cigarette per point สามารถใส่ได้ตั้งแต่ 1-1,000,000"
            );
            this.setting.cigarettes_per_point = this.oldsetting.cigarettes_per_point;
            return false;
          }
          return true;
        case "cigarette_obstacle_ratio":
          if (
            this.setting.cigarette_obstacle_ratio < 1 ||
            this.setting.cigarette_obstacle_ratio > 1000000 ||
            this.setting.cigarette_obstacle_ratio == null
          ) {
            this.alert(
              "No. Cigarette per obstacle สามารถใส่ได้ตั้งแต่ 1-1,000,000"
            );
            this.setting.cigarette_obstacle_ratio = this.oldsetting.cigarette_obstacle_ratio;
            return false;
          }
          return true;
        case "penalty_shell":
          if (
            this.setting.penalty_shell < -1000 ||
            this.setting.penalty_shell > 0
          ) {
            this.alert("Penalty shell สามารถใส่ได้ตั้งแต่ -1,000-0");
            this.setting.penalty_shell = this.oldsetting.penalty_shell;
            return false;
          } else if (
            this.setting.penalty_shell == "" ||
            this.setting.penalty_shell == null
          ) {
            this.setting.penalty_shell = 0;
          }
          return true;
        case "penalty_crab":
          if (
            this.setting.penalty_crab < -1000 ||
            this.setting.penalty_crab > 0
          ) {
            this.alert("Penalty crab สามารถใส่ได้ตั้งแต่ -1,000-0");
            this.setting.penalty_crab = this.oldsetting.penalty_crab;
            return false;
          } else if (
            this.setting.penalty_crab == "" ||
            this.setting.penalty_crab == null
          ) {
            this.setting.penalty_crab = 0;
          }
          return true;
        case "summary_text":
          if (this.setting.summary_text.length > 50) {
            this.alert("Summary Text สามารถกรอกข้อมูลสูงสุด 50");
            return false;
          }
          return true;
        //Space
        case "base_duration":
          if (
            this.setting.base_duration < 0 ||
            this.setting.base_duration > 300
          ) {
            this.alert("Base duration สามารถใส่ได้ตั้งแต่ 0-300");
            this.setting.base_duration = this.oldsetting.base_duration;
            return false;
          } else if (
            this.setting.base_duration == "" ||
            this.setting.base_duration == null
          ) {
            this.setting.base_duration = 0;
          }
          return true;
        case "base_sf_points":
          if (
            this.setting.base_sf_points < 1 ||
            this.setting.base_sf_points > 1000 ||
            this.setting.base_sf_points == "" ||
            this.setting.base_sf_points == null
          ) {
            this.typeGame == "run"
              ? this.alert("Base SF point สามารถใส่ได้ตั้งแต่ 1-1,000")
              : this.alert("Average SF point สามารถใส่ได้ตั้งแต่ 1-1,000");
            this.setting.base_sf_points = this.oldsetting.base_sf_points;
            return false;
          }
          return true;
        case "no_vip_objects":
          if (this.typeGame == "run") {
            if (
              this.setting.no_vip_objects < 1 ||
              this.setting.no_vip_objects > 7 ||
              this.setting.no_vip_objects == "" ||
              this.setting.no_vip_objects == null
            ) {
              this.alert("No. VIP objects สามสรถใส่ได้ตั้งแต่ 1-7");
              this.setting.no_vip_objects = this.oldsetting.no_vip_objects;
              return false;
            }
          } else {
            if (
              this.setting.no_vip_objects < 1 ||
              this.setting.no_vip_objects > 100 ||
              this.setting.no_vip_objects == "" ||
              this.setting.no_vip_objects == null
            ) {
              this.alert("No. VIP objects สามารถใส่ได้ตั้งแต่ 1-100");
              this.setting.no_vip_objects = this.oldsetting.no_vip_objects;
              return false;
            }
          }
          return true;
        case "vip_object_time":
          if (this.typeGame == "run") {
            if (
              this.setting.vip_object_time < 1 ||
              this.setting.vip_object_time > 1000 ||
              this.setting.vip_object_time == "" ||
              this.setting.vip_object_time == null
            ) {
              this.alert("VIP object time สามารถใส่ได้ตั้งแต่ 1-1,000");
              this.setting.vip_object_time = this.oldsetting.vip_object_time;
              return false;
            }
            return true;
          } else {
            if (
              this.setting.vip_object_time < 1 ||
              this.setting.vip_object_time > 100 ||
              this.setting.vip_object_time == "" ||
              this.setting.vip_object_time == null
            ) {
              this.alert("VIP object time สามารถใส่ได้ตั้งแต่ 1-100");
              this.setting.vip_object_time = this.oldsetting.vip_object_time;
              return false;
            }
            return true;
          }

        case "avg_obstacles_per_min":
          if (this.typeGame == "run") {
            if (
              this.setting.avg_obstacles_per_min < 1 ||
              this.setting.avg_obstacles_per_min > 30 ||
              this.setting.avg_obstacles_per_min == "" ||
              this.setting.avg_obstacles_per_min == null
            ) {
              this.alert("VIP object time สามารถใส่ได้ตั้งแต่ 1-30");
              this.setting.avg_obstacles_per_min = this.oldsetting.avg_obstacles_per_min;
              return false;
            }
          } else {
            if (
              this.setting.avg_obstacles_per_min < 1 ||
              this.setting.avg_obstacles_per_min > 1000000 ||
              this.setting.avg_obstacles_per_min == "" ||
              this.setting.avg_obstacles_per_min == null
            ) {
              this.alert("VIP object time สามารถใส่ได้ตั้งแต่ 1-1,000,000");
              this.setting.avg_obstacles_per_min = this.oldsetting.avg_obstacles_per_min;
              return false;
            }
          }
          return true;
        case "time_per_questions":
          if (
            this.setting.time_per_questions < 0 ||
            this.setting.time_per_questions > 300
          ) {
            this.alert("Time per questions สามารถใส่ได้ตั้งแต่ 0-300");
            this.setting.time_per_questions = this.oldsetting.time_per_questions;
            return false;
          } else if (
            this.setting.time_per_questions == "" ||
            this.setting.time_per_questions == null
          ) {
            this.setting.time_per_questions = 0;
          }
          return true;
        case "no_questions_per_game":
          if (
            this.setting.no_questions_per_game < 1 ||
            this.setting.no_questions_per_game > 1000 ||
            this.setting.no_questions_per_game == "" ||
            this.setting.no_questions_per_game == null
          ) {
            this.alert("No. questions per game สามารถใส่ได้ตั้งแต่ 1-1,000");
            this.setting.no_questions_per_game = this.oldsetting.no_questions_per_game;
            return false;
          }
          return true;
      }
    },
    async fetchGame() {
      this.game = await gameService.getById(this.id);
      this.typeGame = this.game.game;
      this.setting = this.getSettingGame(this.game.game, this.game);
      this.usergroups = await userGroupService.getById(this.game.group_id);
      this.oldgame = await gameService.getById(this.id);
      this.oldsetting = this.getSettingGame(this.oldgame.game, this.oldgame);
      this.checkStatus();
      if (this.game.game == "advocacy") {
        this.fetchQuestion(this.game.advocacy.questions);
      }
    },
    getSettingGame(type, list) {
      if (type == "fortune") {
        return list.fortune.levels;
      } else if (type == "advocacy") {
        return list.advocacy;
      } else if (type == "sustainable") {
        return list.sustainable;
      } else if (type == "flappy") {
        return list.flappy;
      } else if (type == "run") {
        return list.run;
      } else if (type == "space") {
        return list.space;
      }
    },
    async fetchQuestion(choice) {
      const listquestion = null;
      this.list1 = [];
      this.list2 = [];
      this.questions = await questionService.getQuestion();
      console.log(this.questions);
      const Get = this.questions.filter((g) => g.status == "active");
      this.list1 = Get.filter((g) => choice.indexOf(g.id) <= -1);
      this.list2 = Get.filter((g) => choice.indexOf(g.id) > -1);
    },
    checkStatus() {
      const status = this.game.status;
      if (status == "draft") {
        this.isDisable = {
          name: false,
          duration: false,
          durationEnd: false,
          configuration: false,
          specific_setting: false,
          url: false,
        };
      } else if (status == "scheduled") {
        this.isDisable = {
          name: true,
          duration: true,
          durationEnd: true,
          configuration: false,
          specific_setting: false,
          url: false,
        };
      } else if (status == "paused") {
        this.isDisable = {
          name: true,
          duration: true,
          durationEnd: false,
          configuration: false,
          specific_setting: false,
          url: false,
        };
      } else {
        this.isDisable = {
          name: true,
          duration: true,
          durationEnd: true,
          configuration: true,
          specific_setting: true,
          url: true,
        };
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async submitSetting(field, label, index) {
      try {
        const checksetting = this.isCheckSetting(field, index);
        if (!checksetting) return;
        const form = {};
        switch (this.typeGame) {
          case "fortune":
            form[field] = this.setting[index][field];
            await gameService.editGameLabel(this.id, label, form);
            break;
          case "sustainable":
            form[field] = this.setting[field];
            var body = {
              sustainable: form,
            };
            await gameService.editGameSetting(this.id, body);
            break;
          case "space":
            form[field] = this.setting[field];
            var body = {
              space: form,
            };
            await gameService.editGameSetting(this.id, body);
            break;
          case "run":
            form[field] = this.setting[field];
            var body = {
              run: form,
            };
            await gameService.editGameSetting(this.id, body);
            break;
          case "advocacy":
            form[field] = this.setting[field];
            var body = {
              advocacy: form,
            };
            await gameService.editGameSetting(this.id, body);
            break;
        }
        this.fetchGame();
        this.$notify(
          `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
            /_/gi,
            " "
          ) + " updated."
        );
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Game Instance Update error");
      }
    },
    async submit(field) {
      try {
        const check = this.isCheck(field);
        if (!check) return;
        const form = {};
        form[field] = this.game[field];
        await gameService.editGame(this.id, form);
        this.fetchGame();
        this.$notify(
          `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
            /_/gi,
            " "
          ) + " updated."
        );
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Game Instance Update error");
      }
    },
    async alert(Text) {
      await this.$dialogs.alert(Text);
    },
    AfterStartDate(date) {
      if (this.game.status == "paused") {
        const today = new Date();
        return (
          moment(date) < moment(today).subtract(1, "days") ||
          moment(date) < moment(this.game.start_date).subtract(1, "days")
        );
      } else {
        return moment(date) < moment(this.game.start_date).subtract(1, "days");
      }
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange(event) {
      this.showTimePanel = false;
      if (this.game.start_date == null && event) return;
      if (this.game.end_date == null && !event) return;
      event ? this.submit("start_date") : this.submit("end_date");
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    clearDate(event) {
      if (this.game.start_date == null && event) {
        this.submit("start_date");
      } else if (this.game.end_date == null && !event) {
        this.submit("end_date");
      }
    },
    isRequired(status) {
      switch (status) {
        case "scheduled":
          if (this.game.start_date == null) {
            this.alert("กรุณาใส่วันที่เริ่ม Start");
            return false;
          }
          if (moment(this.game.start_date) <= moment(new Date())) {
            this.alert("ไม่สามารถ set ให้ค่า Start เป็นค่าในอดีตได้");
            return false;
          }
          if (
            this.game.no_play_mon == null ||
            this.game.no_play_tue == null ||
            this.game.no_play_wed == null ||
            this.game.no_play_thu == null ||
            this.game.no_play_fri == null ||
            this.game.no_play_sat == null ||
            this.game.no_play_sun == null
          ) {
            this.alert("กรุณาใส่ No. play per day ให้ครบในแต่ละวัน");
            return false;
          }
          if (this.game.game == "advocacy") {
            if (this.list2.length == 0) {
              this.alert("กรุณาเลือกจำนวนคำถามอย่างน้อย 1 คำถาม");
              return false;
            }
          }

          return true;
        case "ongoing":
          if (moment(this.game.end_date) <= moment(new Date())) {
            this.setStatus("end");
            return false;
          }
          if (this.game.game == "advocacy") {
            if (this.list2.length == 0) {
              this.alert("กรุณาเลือกจำนวนคำถามอย่างน้อย 1 คำถาม");
              return false;
            }
          }
          return true;
        default:
          return true;
      }
    },
    async alert(Text) {
      await this.$dialogs.alert(Text);
    },
    async setStatus(status) {
      try {
        const check = this.isRequired(status);
        if (!check) return;
        const form = new FormData();
        form.set("status", status);
        const body = JSON.stringify(Object.fromEntries(form));
        await gameService.editGame(this.game.id, JSON.parse(body));
        this.$notify("Change Status success");
        this.fetchGame();
      } catch (error) {
        console.log(error);
        if (error.response) {
          this.$dialogs.alert(responseErrorMapping(error.response.data));
          this.repeatedlygroup(error.response.data);
        } else {
          this.$notify(error);
        }
      }
    },
    chosenList() {
      this.idChose = [];
      this.onList = null;
      var num = this.list2.length;
      var questionID = [];
      if (num == 0) {
        this.submitQuestion(questionID);
        return;
      }
      for (var i = 0; i < num; i++) {
        questionID.push(this.list2[i].id);
      }
      this.submitQuestion(questionID);
    },
    async submitQuestion(ChoiceID) {
      try {
        await gameService.editGameSettingChoice(this.id, ChoiceID);
        this.fetchGame();
        this.$notify("Choice List updated.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Choice List Update error");
      }
    },
    OnClickChosen(id, chosen) {
      this.idChose = id;
      this.onList = chosen;
    },
    MoveListChosen(bool) {
      if (this.idChose.length == 0) return;
      if (!bool && this.onList == true) {
        this.list2.push(this.idChose);
      } else {
        this.list2 = this.list2.filter((g) => g.id != this.idChose.id);
      }
      this.chosenList();
    },
    NameChar(char) {
      switch (char) {
        case "A":
          return "White Collar";
        case "B":
          return "Day Hire";
        case "C":
          return "Self Hire";
        case "D":
          return "LA29";
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.input-table {
  width: 150px;
}
.text-left {
  margin-left: 40px;
}
.space-top {
  margin-top: 10px;
}
.text-con {
  margin-left: 40px;
  margin-top: 30px;
  margin-right: 40px;
}
.big-text {
  text-transform: uppercase;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
div.scrollList {
  height: 350px;
  width: auto;
  overflow-y: auto;
  border: 1px solid black;
}
.row-center {
  margin-top: 100px;
}
.form-control {
  width: 100%;
}
.w80 {
  width: 80px;
  margin-right: 12px;
}
.mx-input:disabled,
.mx-input.disabled {
  color: #888;
}
</style>

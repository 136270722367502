var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("section", { staticClass: "pool-item", class: _vm.cardStatusClass }, [
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "info" }, [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.item.id) + " - " + _vm._s(_vm.item.name)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "footer" }, [_vm._v(_vm._s(_vm.footerText))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
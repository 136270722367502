<template>
  <div class="form-group col-12">
    <label class="mx-5">
      <h4>Terms & Condition</h4>
    </label>
    <ckeditor
      v-model="value"
      :read-only="disabled"
      @blur="handleDescriptionChange"
    />
  </div>
</template>

<script>
import formMixins from "../mixins/form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      value: "",
      message: {
        error: `Update Terms & Condition error.`,
        success: `Update Terms & Condition success.`,
      },
    };
  },

  methods: {
    initial() {
      this.value = this.campaign.term_condition;
    },

    handleDescriptionChange() {
      if (this.value == this.campaign.term_condition) return;

      const event = {
        target: {
          value: this.value,
          name: "term_condition",
        },
      };

      this.update(event);
    },
  },
};
</script>

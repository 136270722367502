import TransactionLog from "@/views/transaction/Index.vue";
import TransactionLogList from "@/views/transaction/List.vue";

export default {
  path: "/transaction-log",
  name: "TransactionLog",
  component: TransactionLog,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/",
      name: "TransactionLogList",
      component: TransactionLogList,
    },
  ],
};

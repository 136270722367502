var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "radio-input-row radio-custom radio-primary" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.reward.reward_type,
              expression: "reward.reward_type",
            },
          ],
          attrs: {
            type: "radio",
            value: "ecoupon",
            name: "reward_type_" + _vm.index,
            id: "reward_type_ecoupon_" + _vm.index,
            disabled: _vm.disabled,
          },
          domProps: { checked: _vm._q(_vm.reward.reward_type, "ecoupon") },
          on: {
            change: [
              function ($event) {
                return _vm.$set(_vm.reward, "reward_type", "ecoupon")
              },
              function ($event) {
                return _vm.update($event, "reward_type")
              },
            ],
          },
        }),
        _c("label", { attrs: { for: "reward_type_ecoupon_" + _vm.index } }, [
          _vm._v("E-Coupon"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "form-group-row mb-5" }, [
        _c("label", [_vm._v("Face Value")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.reward.ecoupon_face_value,
              expression: "reward.ecoupon_face_value",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: {
            min: "1",
            max: "999999",
            "width-100px": "",
            type: "number",
            name: "ecoupon_face_value",
            disabled: _vm.disabled || _vm.reward.reward_type !== "ecoupon",
          },
          domProps: { value: _vm.reward.ecoupon_face_value },
          on: {
            change: _vm.update,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.reward,
                "ecoupon_face_value",
                _vm._n($event.target.value)
              )
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("div", { staticClass: "unit" }, [_vm._v("bht")]),
      ]),
      _c("div", { staticClass: "form-group-row" }, [
        _c("label", [_vm._v("Top Up")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.reward.ecoupon_topup,
              expression: "reward.ecoupon_topup",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: {
            min: "0",
            max: "999999",
            "width-100px": "",
            type: "number",
            name: "ecoupon_topup",
            disabled: _vm.disabled || _vm.reward.reward_type !== "ecoupon",
          },
          domProps: { value: _vm.reward.ecoupon_topup },
          on: {
            change: _vm.update,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.reward, "ecoupon_topup", _vm._n($event.target.value))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("div", { staticClass: "unit" }, [_vm._v("bht")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "product-type" } }, [
    _c("h4", { staticClass: "mb-20 ml-20" }, [_vm._v("Product Type")]),
    _c("div", { staticClass: "container ml-30" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("label", { staticClass: "switch" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.product.product_type,
                  expression: "product.product_type",
                },
              ],
              attrs: {
                type: "checkbox",
                "true-value": "ecoupon",
                "false-value": "default",
                name: "product_type",
              },
              domProps: {
                checked: Array.isArray(_vm.product.product_type)
                  ? _vm._i(_vm.product.product_type, null) > -1
                  : _vm._q(_vm.product.product_type, "ecoupon"),
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.product.product_type,
                      $$el = $event.target,
                      $$c = $$el.checked ? "ecoupon" : "default"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.product,
                            "product_type",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.product,
                            "product_type",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.product, "product_type", $$c)
                    }
                  },
                  _vm.update,
                ],
              },
            }),
            _c("span", { staticClass: "slider round" }),
          ]),
          _c("p", { staticClass: "mx-10 d-inline" }, [_vm._v("E-Coupon")]),
        ]),
        _vm.product.product_type === "ecoupon"
          ? _c("div", { staticClass: "col-12" }, [
              _c("table", [
                _c("tr", [
                  _vm._m(0),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.ecoupon_face_value,
                          expression: "product.ecoupon_face_value",
                        },
                      ],
                      staticClass: "form-control w-150",
                      attrs: {
                        min: "1",
                        max: "999999",
                        type: "number",
                        name: "ecoupon_face_value",
                      },
                      domProps: { value: _vm.product.ecoupon_face_value },
                      on: {
                        change: _vm.update,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.product,
                            "ecoupon_face_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._m(1),
                  _vm._m(2),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.ecoupon_topup,
                          expression: "product.ecoupon_topup",
                        },
                      ],
                      staticClass: "form-control w-150",
                      attrs: {
                        min: "0",
                        max: "999999",
                        type: "number",
                        name: "ecoupon_topup",
                      },
                      domProps: { value: _vm.product.ecoupon_topup },
                      on: {
                        change: _vm.update,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.product,
                            "ecoupon_topup",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._m(3),
                ]),
                _c("tr", [
                  _vm._m(4),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.ecoupon_expire_in,
                          expression: "product.ecoupon_expire_in",
                        },
                      ],
                      staticClass: "form-control w-150",
                      attrs: {
                        min: "1",
                        max: "365",
                        type: "number",
                        name: "ecoupon_expire_in",
                      },
                      domProps: { value: _vm.product.ecoupon_expire_in },
                      on: {
                        change: _vm.update,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.product,
                            "ecoupon_expire_in",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._m(5),
                ]),
                _c("tr", [
                  _vm._m(6),
                  _c("td", { attrs: { colspan: "5" } }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.ecoupon_custom_text,
                            expression: "product.ecoupon_custom_text",
                          },
                        ],
                        staticClass: "form-control w-350",
                        attrs: {
                          type: "text",
                          maxlength: "40",
                          autocomplete: "off",
                          name: "ecoupon_custom_text",
                        },
                        domProps: { value: _vm.product.ecoupon_custom_text },
                        on: {
                          change: _vm.update,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.product,
                              "ecoupon_custom_text",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("span", { staticClass: "px-10" }, [
                        _vm._v("Max 40 char"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Face Value")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "px-10" }, [_vm._v("Baht")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "w-100 h-align-end" }, [
      _c("span", [_vm._v("Topup")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "px-10" }, [_vm._v("Baht")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Expire in")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "4" } }, [
      _c("span", { staticClass: "px-10" }, [_vm._v("Days after issuing date")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Custom text on coupon")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-md-10">
      <label>
        <h5>Round Name</h5>
      </label>
      <input
        type="text"
        name="name"
        maxlength="50"
        @change="update"
        class="form-control"
        v-model="round.name"
        :disabled="disabled"
        placeholder="Round Name"
      />
    </div>
  </div>
</template>

<script>
import roundMixins from "../mixins/round.mixins";

export default {
  mixins: [roundMixins],

  data() {
    return {
      message: {
        error: "Upload Round name error.",
        success: "Upload Round name success.",
      },
    };
  },
};
</script>

<style></style>

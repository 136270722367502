var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lot
    ? _c("div", { staticClass: "mx-10 col-6" }, [
        _vm._m(0),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.prize_name,
              expression: "prize_name",
            },
          ],
          ref: "prize_name",
          staticClass: "form-control",
          attrs: { type: "text", maxlength: "255", disabled: !_vm.canEdit },
          domProps: { value: _vm.prize_name },
          on: {
            change: _vm.update,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.prize_name = $event.target.value
            },
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-label" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Prize Name")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
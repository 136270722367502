<template>
  <div v-if="budget">
    <div class="row">
      <label class="form-control-label">
        <h4>Budget Duration</h4>
      </label>
    </div>
    <div class="row">
      <label class="d-inline mx-10 form-control-label"> Start </label>
      <div class="d-inline">
        <DatePicker
          width-200px
          type="datetime"
          :confirm="true"
          placeholder="n/a"
          ref="start_date"
          format="DD/MM/YYYY HH:mm"
          value-type="YYYY-MM-DD HH:mm"
          :disabled="canChangeStartDate"
          v-model="budget.start_date"
          @change="update('start_date')"
          :disabled-date="disabledStartDate"
          :disabled-time="disabledStartTime"
        />
      </div>
      <label class="d-inline mx-10 form-control-label"> End </label>
      <div class="d-inline">
        <DatePicker
          width-200px
          type="datetime"
          :confirm="true"
          placeholder="n/a"
          ref="end_date"
          format="DD/MM/YYYY HH:mm"
          value-type="YYYY-MM-DD HH:mm"
          :disabled="canChangeEndDate"
          v-model="budget.end_date"
          @change="update('end_date')"
          :disabled-date="disabledBeforeStart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import { budgetService } from "@/api/client";

export default {
  props: ["budget"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    canChangeStartDate() {
      return !["draft"].includes(this.budget.status);
    },
    canChangeEndDate() {
      return !["draft", "scheduled", "paused"].includes(this.budget.status);
    },
  },

  mounted() {
    const { start_date, end_date } = this.budget;
    this.oldValue = { start_date, end_date };
  },

  methods: {
    disabledStartDate(date) {
      const today = dayjs().toDate();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    disabledStartTime(date) {
      return date < Date.now();
    },

    disabledBeforeStart(date) {
      const startDate = new Date(this.budget.start_date);
      startDate.setHours(0, 0, 0, 0);
      return date < startDate;
    },

    async isFormValid(key) {
      if (
        key == "start_date" &&
        dayjs(this.budget.start_date).diff(new Date(), "second") < 1
      ) {
        await this.$dialogs.alert(
          "Start date ใส่ค่าได้ตั้งแต่วันที่ในอนาคตเท่านั้น"
        );
        this.$refs.start_date.focus();

        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        return false;
      }

      if (
        key == "end_date" &&
        dayjs(this.budget.end_date).diff(new Date(), "second") < 1
      ) {
        await this.$dialogs.alert("End date ใส่ค่าเป็นเวลาในอนาคตเท่านั้น");
        this.$refs.end_date.focus();

        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        return false;
      }

      if (dayjs(this.budget.start_date) > dayjs(this.budget.end_date)) {
        await this.$dialogs.alert(
          "กรุณาระบุวันเริ่มที่น้อยกว่าวันสิ้นสุด budget"
        );
        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        return false;
      }

      return true;
    },

    async update(key) {
      try {
        const formValid = await this.isFormValid(key);
        if (!formValid) return;

        const { id, start_date, end_date } = this.budget;
        const form = {
          end_date,
          start_date,
        };

        await budgetService.updateBudget(id, form);

        this.oldValue = { start_date, end_date };

        this.$emit("updated");
        this.$notify(`Budget Duration updated.`);
      } catch (error) {
        this.$dialogs.alert(`Budget Duration update error.`);
      }
    },

    setDefaultValues(start, end) {
      this.budget.end_date = end;
      this.budget.start_date = start;

      this.duration = dayjs(new Date(end)).diff(new Date(start), "days") + 1;
    },
  },
};
</script>

<style></style>

<template>
  <div class="row" v-if="lot">
    <label class="form-control-label">
      <h4>Prize Source</h4>
    </label>
    <div class="col-12" v-if="lot">
      <div class="source-option">
        <div class="radio-custom radio-primary mx-10">
          <input
            type="radio"
            value="prize_theme"
            @change="update"
            name="prize_source"
            :disabled="disabled"
            id="prize_source_prize_theme"
            v-model="lot.prize_source"
          />
          <label for="prize_source_prize_theme">Prize Theme</label>
        </div>

        <select
          name="theme_id"
          @change="update"
          class="form-control"
          v-model="lot.theme_id"
          :disabled="disabled || lot.prize_source !== 'prize_theme'"
        >
          <option value="">Not specified</option>
          <option
            v-for="(value, index) in options"
            :value="value.id"
            :key="index"
          >
            {{ value.id }} - {{ value.name }}
          </option>
        </select>
      </div>

      <div class="source-option">
        <div class="radio-custom radio-primary mx-10">
          <input
            type="radio"
            value="manual"
            @change="update"
            name="prize_source"
            :disabled="disabled"
            id="prize_source_manual"
            v-model="lot.prize_source"
          />
          <label for="prize_source_manual">Manual</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prizeService, prizepoolService } from "@/api/client";

export default {
  props: ["lot"],

  data() {
    return {
      options: [],
    };
  },

  computed: {
    disabled() {
      return !["draft"].includes(this.lot.status);
    },
  },

  methods: {
    async update(event, type, model) {
      try {
        const { id } = this.lot;

        const name = event.target.name;
        const value = model != undefined ? model : event.target.value;

        const form = {};
        form[name] = value;

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await prizeService.updateLot(id, form);

        this.$emit("updated");
        this.$notify("Update Prize source success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error?.message || "Update Prize source error.");
      }
    },

    async fetchPoolList() {
      const themes = await prizepoolService.getPrizeThemeList({
        brief: 1,
        show_all: 0,
      });

      this.options = themes;
    },

    initial() {
      this.fetchPoolList();
    },
  },

  mounted() {
    this.initial();
  },
};
</script>

<style lang="scss" scoped>
.source-option {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1 1 auto;
  margin-bottom: 0.5rem;

  select {
    max-width: 350px;
  }
}
</style>

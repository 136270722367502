var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Progress Bar")]),
        _c("breadcrumb", { attrs: { name: "PROGRESS_BAR_TRANSACTION_LOGS" } }),
        _c("div", { staticClass: "page-header-right" }, [
          _c(
            "div",
            { staticClass: "type-filter" },
            [
              _vm._v(" Type "),
              _c(
                "Selectize",
                {
                  attrs: { multiple: "", settings: _vm.selectizeSetting },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                _vm._l(_vm.options, function (option, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: option } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            option.charAt(0).toUpperCase() + option.slice(1)
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "period-filter" },
            [
              _vm._v(" Selected Period "),
              _c("date-picker", {
                attrs: {
                  range: "",
                  type: "date",
                  "default-value": _vm.fromTo,
                  "value-type": "format",
                  format: "YYYY-MM-DD",
                  placeholder: "Select date range",
                },
                model: {
                  value: _vm.fromTo,
                  callback: function ($$v) {
                    _vm.fromTo = $$v
                  },
                  expression: "fromTo",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "page-header-actions" }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    rows: _vm.list,
                    columns: _vm.columns,
                    totalRows: _vm.totalRecords,
                    "sort-options": _vm.sortOption,
                    "pagination-options": _vm.pagination,
                    "search-options": Object.assign({}, _vm.searchOption, {
                      trigger: "enter",
                    }),
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPerPageChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function (props) {
                        return [
                          props.column.field == "status"
                            ? _c("span", [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toUpperCase")(props.row.status)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c("div", { staticClass: "Excel" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: { click: _vm.exportToExcel },
                          },
                          [
                            _c("i", { staticClass: "fa fa-file-excel-o" }),
                            _vm._v(" Export to Excel "),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
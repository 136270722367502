<template>
  <div v-if="campaign" class="my-30">
    <table>
      <tbody>
        <tr>
          <td>
            <div class="d-flex justify-content-end m-10">
              <label class="form-control-label">
                Collect Quota
              </label>
              <input
                min="0"
                type="number"
                width-150px
                class="form-control"
                ref="collect_quota_input"
                :disabled="disabled"
                v-model="collect_quota"
                @change="update('collect_quota')"
              />
            </div>
          </td>
          <td>
            Remaining <br />
            {{ campaign.remaining_quota | numberWithCommas }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex justify-content-end m-10">
              <label class="form-control-label">
                Max individual Collect
              </label>
              <input
                min="0"
                max="100000000"
                type="number"
                width-150px
                class="form-control"
                ref="max_collect_input"
                :disabled="disabled"
                v-model="max_collect"
                @change="update('max_collect')"
              />
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";

export default {
  name: "CouponCollectQuota",

  props: ["campaign"],

  data() {
    return {
      oldValue: null,
      max_collect: 0,
      collect_quota: 0,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    async isFormValid(key) {
      const max_collect = this.max_collect;
      const collect_quota = this.collect_quota;

      switch (key) {
        case "max_collect":
          if (+max_collect < 0) {
            await this.$dialogs.alert(
              "Max individual Collect ต้องไม่น้อยกว่า 0"
            );

            this.$refs.max_collect_input.focus();
            this.max_collect = this.oldValue.max_collect;
            return false;
          }

          if (+max_collect > 100000000) {
            await this.$dialogs.alert(
              "Max individual Collect ต้องไม่มากกว่า 100,000,000"
            );

            this.$refs.max_collect_input.focus();
            this.max_collect = this.oldValue.max_collect;
            return false;
          }
          break;

        case "collect_quota":
          if (+collect_quota < 0) {
            await this.$dialogs.alert("Collect Quota ต้องไม่น้อยกว่า 0");

            this.$refs.collect_quota_input.focus();
            this.collect_quota = this.oldValue.collect_quota;
            return false;
          }
          break;
      }

      return true;
    },

    async update(key) {
      try {
        const formValid = await this.isFormValid(key);
        if (!formValid) return;

        const body = {};
        body[key] = +this[key];

        await ecouponService.updateCampaign(this.campaign.id, body);

        this.oldValue[key] = this[key];

        this.$emit("updated");
        this.$notify(`Updated.`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Collect Quota update error.`);
      }
    },
  },

  mounted() {
    this.oldValue = Object.assign({}, this.campaign);
    this.max_collect = +this.campaign.max_collect;
    this.collect_quota = +this.campaign.collect_quota;
  },
};
</script>

const messages = {
  ER_EMPRT_name: "กรุณาใส่ค่าใน campaign Name",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("name") > -1:
      message = messages.ER_EMPRT_name;
      break;

    case error.search("period overlap with campaigns") > -1:
      const duplicate_with = error
        .replace(/Error:|period overlap with campaigns/g, "")
        .replace("Error: period overlap with campaigns")
        .trim();
      message = `User Groups overlaps with campaign ${duplicate_with}`;
      break;

    default:
      message = error.replace(/Error:/g, "").trim();
      break;
  }
  return message;
};

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Peddler Management</h1>
      <breadcrumb name="E_COUPON_PEDDLER_LIST"></breadcrumb>
      <div class="page-header-left">
        <div class="mt-4">
          <div class="row mb-4">
            <div class="col-6">
              <div class="d-flex g-1">
                <label class="col-form-label w-120">User ISMS Code</label>
                <input
                  class="form-control w-250"
                  placeholder="All"
                  v-model="s_isms_code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="d-flex g-1">
                <label class="col-form-label w-120">Peddler Log in</label>
                <input
                  class="form-control w-250"
                  placeholder="All"
                  v-model="s_username"
                />
                <button
                  type="button"
                  @click="search"
                  class="btn btn-round btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-header-right">
        <peddler-bulk-add @done="onDone" />
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :columns="columns"
              :rows="list"
              :totalRows="totalRecords"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'peddler_id' },
              }"
              :pagination-options="pagination"
              :search-options="searchOption"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-search="onSearch"
              @on-row-dblclick="onRowDoubleClick"
              @on-per-page-change="onPerPageChange"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";

import { peddlerColumns } from "./model";
import PeddlerBulkAdd from "./components/BulkAdd.vue";

export default {
  name: "PeddlerList",

  mixins: [vueGoodTableMixins],

  components: { PeddlerBulkAdd },

  data() {
    return {
      list: [],
      page: 1,
      s_username: "",
      s_isms_code: "",
      columns: peddlerColumns,
    };
  },

  watch: {
    async "$route.query"() {
      await this.search();
      this.initialTableState();
    },
  },

  methods: {
    onDone() {
      this.search();
    },

    getParams() {
      let params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },

    async search() {
      const params = this.getParams();

      const searchForm = {
        s_username: this.s_username || "All",
        s_isms_code: this.s_isms_code || "All",
      };

      const { data, total_records } = await ecouponService.searchPeddler({
        ...params,
        ...searchForm,
      });

      this.list = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      const params = this.getParams();

      const searchForm = {
        s_username: this.s_username || "All",
        s_isms_code: this.s_isms_code || "All",
      };

      try {
        const response = await ecouponService.downloadPeddler({
          ...params,
          ...searchForm,
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `peddler_all_${this.s_isms_code}_${
          this.s_username
        }_${new Date().getTime()}.xlsx`;

        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onRowDoubleClick({ row }) {
      this.$router
        .push({
          name: "e-coupon-peddler-info",
          params: { id: row.peddler_id },
        })
        .catch((err) => {});
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.search();
  },
};
</script>

<style scoped>
.g-1 {
  gap: 1em;
}

.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}

.Excel {
  margin: auto 1rem;
}
</style>

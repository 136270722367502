<template>
  <nav
    class="site-navbar navbar navbar-default navbar-fixed-top navbar-mega"
    role="navigation"
  >
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
        data-toggle="menubar"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="hamburger-bar"></span>
      </button>
      <button
        type="button"
        class="navbar-toggler collapsed"
        data-target="#site-navbar-collapse"
        data-toggle="collapse"
      >
        <i class="icon wb-more-horizontal" aria-hidden="true"></i>
      </button>
      <div
        class="navbar-brand navbar-brand-center site-gridmenu-toggle"
        data-toggle="gridmenu"
      >
        <img
          class="navbar-brand-logo"
          src="@/assets/logo.png"
          :title="appName"
        />
        <span class="navbar-brand-text hidden-xs-down"> PMI</span>
      </div>
      <button
        type="button"
        class="navbar-toggler collapsed"
        data-target="#site-navbar-search"
        data-toggle="collapse"
      >
        <span class="sr-only">Toggle Search</span>
        <i class="icon wb-search" aria-hidden="true"></i>
      </button>
    </div>

    <div class="navbar-container container-fluid">
      <div
        class="collapse navbar-collapse navbar-collapse-toolbar"
        id="site-navbar-collapse"
      >
        <ul class="nav navbar-toolbar">
          <li class="nav-item hidden-sm-down" id="toggleFullscreen">
            <a
              class="nav-link icon icon-fullscreen"
              data-toggle="fullscreen"
              role="button"
            >
              <span class="sr-only">Toggle fullscreen</span>
            </a>
          </li>
        </ul>

        <ul class="nav navbar-toolbar navbar-right navbar-toolbar-right">
          <li class="nav-item dropdown">
            <a
              class="nav-link navbar-avatar"
              data-toggle="dropdown"
              aria-expanded="false"
              data-animation="scale-up"
              role="button"
            >
              <span class="avatar avatar-online">
                <img
                  :src="`${themePath}/theme/remark/global/portraits/8.jpg`"
                />
                <i></i>
              </span>
            </a>
            <div class="dropdown-menu" role="menu">
              <a class="dropdown-item" href="javascript:void(0)" role="menuitem"
                ><i class="icon wb-user" aria-hidden="true"></i> Profile</a
              >
              <a class="dropdown-item" href="javascript:void(0)" role="menuitem"
                ><i class="icon wb-settings" aria-hidden="true"></i> Settings</a
              >
              <div class="dropdown-divider" role="presentation"></div>
              <router-link to="/logout" class="dropdown-item" role="menuitem"
                ><i class="icon wb-power" aria-hidden="true"></i>
                Logout</router-link
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      appName: "Auction Management System",
      themePath: process.env.VUE_APP_BASE_THEME_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
</style>

export const winnerTableColumns = [
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User ID",
    field: "sfdc_user_id",
  },
  {
    label: "Name",
    field: "firstname",
  },
  {
    label: "Last Name",
    field: "lastname",
  },
  {
    label: "Group Code",
    field: "group_code",
  },
  {
    label: "Lot ID",
    field: "lot_id",
    type: "number",
  },
  {
    label: "Lot Name",
    field: "lot_name",
  },
  {
    label: "Lot Type",
    field: "type",
  },
  {
    label: "Product Name",
    field: "name",
  },
  {
    label: "Date",
    field: "end_date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
  {
    label: "Points used",
    field: "points",
    type: "number",
  },
  {
    label: "Submitted",
    field: "submitted",
    type: "number",
    formatFn: function (value) {
      return +value === 1 ? "Yes" : "No";
    },
  },
  {
    label: "Submit Time",
    field: "submit_timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
];

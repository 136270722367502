<template>
  <div class="d-flex align-items-center">
    <BaseIcon class="anim" :icon="icon" />
    <small class="mx-10">กำลังทำงาน...</small>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: {
      type: Number,
      default: 64
    },
    color: {
      type: String,
      default: "#777"
    },
    icon: {
      type: String,
      default: "autorenew"
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  margin: 0px;
}

.anim {
  animation: rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-bordered mb-0" }, [
    _vm._m(0),
    _c("div", { staticClass: "panel-body", staticStyle: { padding: "1rem" } }, [
      _c(
        "div",
        { staticClass: "qrcode-wrapper" },
        [
          _vm.url
            ? _c("qr-code", { attrs: { text: _vm.url } })
            : _c("div", { staticClass: "qr-placeholder" }),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "list-group" }, [
        _c(
          "a",
          {
            staticClass: "list-group-item list-group-item-action",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$emit("selected", "iframe")
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon mdi mdi-application mdi-18px",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Open as IFrame "),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "list-group-item list-group-item-action",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$emit("selected", "tab")
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon mdi mdi-tab mdi-18px",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Open in new tab "),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "list-group-item list-group-item-action",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$emit("selected", "window")
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon mdi mdi-dock-window mdi-18px",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Open in new window "),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "panel-title px-4" }, [
        _c("i", {
          staticClass: "icon mdi mdi-controller mdi-24px mr-2",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" Preview with... "),
      ]),
      _c("div", { staticClass: "panel-actions panel-actions-keep" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
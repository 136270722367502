var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "flex-fit-content" }, [
          _c("div", { staticClass: "form-group-row" }, [
            _c("div", { staticClass: "left-box" }, [
              _c("p", { staticClass: "mx-10 d-inline" }, [_vm._v("Hidden")]),
              _c("label", { staticClass: "switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.stamp.show_reward_details_section,
                      expression: "stamp.show_reward_details_section",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "true-value": 1,
                    "false-value": 0,
                    disabled: _vm.disabled,
                    name: "show_reward_details_section",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.stamp.show_reward_details_section
                    )
                      ? _vm._i(_vm.stamp.show_reward_details_section, null) > -1
                      : _vm._q(_vm.stamp.show_reward_details_section, 1),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.stamp.show_reward_details_section,
                          $$el = $event.target,
                          $$c = $$el.checked ? 1 : 0
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(null),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.stamp,
                                "show_reward_details_section",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.stamp,
                                "show_reward_details_section",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.stamp,
                            "show_reward_details_section",
                            $$c
                          )
                        }
                      },
                      function ($event) {
                        return _vm.update(
                          $event,
                          "checkbox",
                          _vm.stamp.show_reward_details_section
                        )
                      },
                    ],
                  },
                }),
                _c("span", { staticClass: "slider round" }),
              ]),
              _c("p", { staticClass: "mx-10 d-inline" }, [_vm._v("Shown")]),
            ]),
            _c("div", { staticClass: "right-box" }, [
              _c("label", { staticClass: "mb-0" }, [_vm._v("Text color")]),
              _c("div", { staticClass: "color-picker" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.color,
                      expression: "color",
                    },
                  ],
                  attrs: {
                    type: "text",
                    disabled: _vm.disabledColorPicker,
                    name: "reward_details_text_color",
                  },
                  domProps: { value: _vm.color },
                  on: {
                    change: function ($event) {
                      return _vm.update($event, "color")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.color = $event.target.value
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.color,
                      expression: "color",
                    },
                  ],
                  attrs: {
                    type: "color",
                    disabled: _vm.disabledColorPicker,
                    name: "reward_details_text_color",
                  },
                  domProps: { value: _vm.color },
                  on: {
                    change: function ($event) {
                      return _vm.update($event, "color")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.color = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Reward Details Section")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table" v-if="luckyNumber">
        <tbody>
          <tr>
            <td style="vertical-align: middle">No. {{ index + 1 }}</td>
            <td align="left">
              <div
                class="d-flex align-items-center justify-content-start gap-1rem"
              >
                <label class="mb-0">Lucky No.</label>
                <input
                  min="1"
                  width-100px
                  type="number"
                  ref="lucky_no"
                  class="form-control"
                  :disabled="!canEdit"
                  v-model="luckyNumber.lucky_no"
                  @change="update('lucky_no')"
                />
              </div>
            </td>
            <td colspan="2" align="right">
              <button
                class="btn btn-defautl"
                type="button"
                @click="remove"
                :disabled="!canEdit"
              >
                <i class="icon md-delete" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <label class="mx-5">
                Picture
                <span>Recommended size 300x300 pixel</span>
              </label>

              <div class="form-group">
                <div class="lucky-number-picture-form-upload">
                  <div class="image-form-wrapper">
                    <div class="image-preview">
                      <img
                        v-if="luckyNumber.picture"
                        :src="luckyNumber.picture"
                      />
                      <div v-else class="image-placeholder"></div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      class="form-control"
                      :disabled="!canEdit || luckyNumber.reward_type == 'sfdc'"
                      :ref="`lucky-number-picure-${luckyNumber.id}`"
                      @change="upload(luckyNumber.id)"
                    />
                  </div>
                </div>
              </div>
            </td>
            <td colspan="2">
              <div class="reward-choice-list">
                <div class="reward-choice">
                  <div class="d-flex align-items-center gap-1rem">
                    <input
                      type="radio"
                      value="prizepool"
                      :disabled="!canEdit"
                      v-model.number="luckyNumber.reward_type"
                      :checked="luckyNumber.reward_type == 'prizepool'"
                      @change="update('reward_type', luckyNumber)"
                      :id="`reward-choice-prizepool-${luckyNumber.lucky_no}`"
                    />
                    <label
                      :for="`reward-choice-prizepool-${luckyNumber.lucky_no}`"
                      class="choice-label"
                    >
                      Prize Pool
                    </label>
                  </div>
                  <div class="d-flex align-items-center">
                    <label
                      class="input-label"
                      :for="`reward-choice-theme_id_${luckyNumber.lucky_no}`"
                    >
                      Prize Theme ID
                    </label>
                    <select
                      name="theme_id"
                      class="form-control"
                      v-model.number="luckyNumber.theme_id"
                      @change="update('theme_id', luckyNumber)"
                      :disabled="
                        !canEdit || luckyNumber.reward_type != 'prizepool'
                      "
                      :id="`reward-choice-theme_id_${luckyNumber.lucky_no}`"
                    >
                      <option :value="null || 0 || undefined" disabled>
                        Not Select
                      </option>
                      <option
                        v-for="prize in prizepool"
                        :value="prize.id"
                        :key="prize.id"
                      >
                        {{ prize.id }} - {{ prize.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="reward-choice">
                  <div class="d-flex align-items-center gap-1rem">
                    <input
                      type="radio"
                      value="sfdc"
                      :disabled="!canEdit"
                      v-model.number="luckyNumber.reward_type"
                      :checked="luckyNumber.reward_type === 'sfdc'"
                      @change="update('reward_type', luckyNumber)"
                      :id="`reward-choice-sfdc-${luckyNumber.lucky_no}`"
                    />
                    <label
                      :for="`reward-choice-sfdc-${luckyNumber.lucky_no}`"
                      class="choice-label"
                    >
                      SF Point
                    </label>
                  </div>
                  <div class="d-flex">
                    <input
                      min="0"
                      max="10000"
                      type="number"
                      name="sfdc_point"
                      class="form-control"
                      @change="update('sfdc_point')"
                      v-model.number="luckyNumber.sfdc_point"
                      :disabled="!canEdit || luckyNumber.reward_type != 'sfdc'"
                    />
                  </div>
                </div>
                <div class="reward-choice">
                  <div class="d-flex align-items-center gap-1rem">
                    <input
                      type="radio"
                      value="other"
                      :disabled="!canEdit"
                      v-model.number="luckyNumber.reward_type"
                      :checked="luckyNumber.reward_type === 'other'"
                      @change="update('reward_type', luckyNumber)"
                      :id="`reward-choice-other-${luckyNumber.lucky_no}`"
                    />
                    <label
                      :for="`reward-choice-other-${luckyNumber.lucky_no}`"
                      class="choice-label"
                    >
                      Prize
                    </label>
                  </div>
                  <div class="d-flex flex-column gap-1rem">
                    <div class="d-flex">
                      <label class="input-label">Reward Name</label>
                      <textarea
                        class="form-control"
                        rows="2"
                        ref="reward_name"
                        :disabled="
                          !canEdit || luckyNumber.reward_type != 'other'
                        "
                        v-model="luckyNumber.reward_name"
                        @change="update('reward_name')"
                      />
                    </div>
                    <div class="d-flex justify-content-start">
                      <label class="input-label">
                        <input
                          type="checkbox"
                          v-model="luckyNumber.is_e_reward"
                          @change="update('is_e_reward')"
                          :id="`lucky_number_${luckyNumber.id}`"
                          :disabled="
                            !canEdit || luckyNumber.reward_type != 'other'
                          "
                        />
                        <label
                          :for="`lucky_number_${luckyNumber.id}`"
                          class="mx-2"
                        >
                          E-Reward Code
                        </label>
                      </label>
                      <input
                        type="text"
                        ref="e_reward_code"
                        class="form-control"
                        :disabled="
                          !isEReward ||
                          !canEdit ||
                          luckyNumber.reward_type != 'other'
                        "
                        v-model="luckyNumber.e_reward_code"
                        @change="update('e_reward_code')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { luckyLoginService, progressBarService } from "@/api/client";

export default {
  props: ["luckyNumber", "index", "campaign"],

  data() {
    return {
      oldValue: null,
      prizepool: [],
    };
  },

  computed: {
    isEReward() {
      return +this.luckyNumber.is_e_reward === 1;
    },

    canEdit() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },
  },

  methods: {
    isUsed(no) {
      const matched = this.campaign.lucky_number_list.findIndex(
        (i) => +i.lucky_no === +no && +i.id !== this.luckyNumber.id
      );

      return matched > -1;
    },

    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    async validate(key) {
      const { lucky_no, reward_name, sfdc_point } = this.luckyNumber;

      switch (key) {
        case "lucky_no":
          if (isNaN(parseInt(lucky_no))) {
            await this.$dialogs.alert("Lucky No. ต้องเป็นตัวเลขเท่านั้น");

            this.luckyNumber.lucky_no = this.oldValue.lucky_no;
            this.$refs.lucky_no.focus();

            return false;
          }

          if (!lucky_no) {
            await this.$dialogs.alert("กรุณาระบุ Lucky No.");
            return false;
          }

          if (
            this.isUsed(lucky_no) ||
            this.isUsed(Math.ceil(lucky_no)) ||
            this.isUsed(Math.floor(lucky_no))
          ) {
            this.luckyNumber.lucky_no = this.oldValue.lucky_no;
            await this.$dialogs.alert("เลขนี้ถูกใช้เป็น Lucky No. แล้ว");
            return false;
          }
          break;

        case "reward_name":
          if (!reward_name) {
            await this.$dialogs.alert("กรุณาระบุ Reward Name.");
            return false;
          }
          break;

        case "sfdc_point":
          if (
            isNaN(+sfdc_point) ||
            +sfdc_point < 0 ||
            this.isFloat(sfdc_point)
          ) {
            await this.$dialogs.alert(
              "กรุณาระบุ คะแนน SF point เป็นเลขจำนวนเต็มและมากกว่า 0"
            );
            this.luckyNumber.sfdc_point = this.oldValue.sfdc_point;
            return false;
          }
          break;
      }

      return true;
    },

    async update(key) {
      const isValid = await this.validate(key);
      if (!isValid) return;

      const { id, campaign_id } = this.luckyNumber;

      const form = {};
      form[key] = this.luckyNumber[key];

      await luckyLoginService.updateLuckyNumber(campaign_id, id, form);

      this.oldValue[key] = this.luckyNumber[key];

      this.$emit("updated");
      this.$notify("Updated.");
    },

    async remove() {
      const r = confirm(
        `Delete Lucky No. ${this.luckyNumber.lucky_no} \nPlease confirm!`
      );

      if (r == true) {
        await luckyLoginService.removeLuckyNumber(
          this.luckyNumber.campaign_id,
          this.luckyNumber.id
        );

        this.$emit("updated");
      }
    },

    async upload(luckyNumberId) {
      const ref = this.$refs[`lucky-number-picure-${luckyNumberId}`];
      const file = ref.files[0];

      if (file.size > 1500000) {
        this.$notify("File is too big");
        ref.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("picture", file);

        const { id } = this.campaign;

        await luckyLoginService.uploadLuckyNumberPicture(
          id,
          luckyNumberId,
          form
        );

        this.$emit("updated");
        this.$notify(`Lucky number picture updated.`);
      } catch (error) {
        this.$notify("Upload lucky number picture error");
      }
    },

    async fetchPrizePool() {
      this.prizepool = await progressBarService.getPrizePoolList();
    },
  },

  async mounted() {
    await this.fetchPrizePool();
    this.oldValue = { ...this.luckyNumber };
  },
};
</script>

<style lang="scss" scoped>
.lucky-number-picture-form-upload {
  .image-preview {
    padding: 2px;
    min-height: 200px;
    background-color: #efefef;
  }

  .image-preview,
  input[type="file"] {
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-placeholder {
    min-height: 200px;
    max-width: 375px;
    background: #eee;
    border-radius: 8px 8px 0px 0px;
  }

  input[type="file"] {
    padding: 2rem 1rem;
    border-radius: 1px;
    border: 2px solid #eee;
  }
}

.reward-choice-list {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.reward-choice {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:nth-child(3) {
    align-items: flex-start;
  }

  .choice-label {
    width: 70px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 0px;
  }

  label.input-label {
    width: 196px;
    flex: 1 1 auto;
    font-weight: 300;
    margin-bottom: 0px;
  }

  input[name="sfdc_point"] {
    width: 200px;
  }
}

.gap-1rem {
  gap: 1rem;
}

table tr:nth-child(2) {
  td {
    padding-top: 1rem;
  }
}
</style>

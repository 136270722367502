import qs from "qs";
import HttpRequest from "./request";

export class LeaderBoardProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/game_leaderboard`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async updateCampaign(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async createNewCampaign(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async uploadBanner(id, form) {
    const { data } = await this.upload(`/${id}/upload`, form);
    return data;
  }

  async getUploadStatus(id) {
    const { data } = await this.get(`/${id}/upload_status`);
    return data;
  }

  // Ranks and Rewards //
  async tryUploadRankReward(id, form) {
    const { data } = await this.upload(`/${id}/rank_reward/try_upload`, form);
    return data;
  }

  async tryUploadResultRankReward(id) {
    const { data } = await this.axiosInstance.post(
      `/${id}/rank_reward/try_upload_result`
    );
    return data;
  }

  async tryUploadConfirmRankReward(id) {
    const { data } = await this.axiosInstance.post(
      `/${id}/rank_reward/try_upload_confirm`
    );
    return data;
  }

  async tryUploadCancelRankReward(id) {
    const { data } = await this.axiosInstance.post(
      `/${id}/rank_reward/try_upload_cancel`
    );
    return data;
  }

  // Game Instances //
  async getInstanceList() {
    const { data } = await this.get(`/instance`);
    return data;
  }

  async getCampaignInstance(id) {
    const { data } = await this.get(`/${id}/instance`);
    return data;
  }

  async replaceInstance(id, form) {
    const { data } = await this.axiosInstance.post(
      `/${id}/instance/replace`,
      form
    );
    return data;
  }

  async tryReplaceInstance(id, form) {
    const { data } = await this.axiosInstance.post(
      `/${id}/instance/try_replace`,
      form
    );
    return data;
  }

  async tryReplaceResultInstance(id) {
    const { data } = await this.axiosInstance.post(
      `/${id}/instance/try_replace_result`
    );
    return data;
  }

  async tryReplaceConfirmInstance(id) {
    const { data } = await this.axiosInstance.post(
      `/${id}/instance/try_replace_confirm`
    );
    return data;
  }

  async tryReplaceCancelInstance(id) {
    const { data } = await this.axiosInstance.post(
      `/${id}/instance/try_replace_cancel`
    );
    return data;
  }

  // https://gwapi-sandbox.vivi-th.com/ams/api/game_leaderboard/status/
  async fetchGameLeaderboardStatus() {
    const { data } = await this.get(`/status`);
    return data;
  }

  // https://gwapi-sandbox.vivi-th.com/ams/api/game_leaderboard/status/<campaign id>/info
  async fetchGameLeaderboardInfo(id) {
    const { data } = await this.get(`/status/${id}/info`);
    return data;
  }

  // GET https://gwapi-sandbox.vivi-th.com/ams/api/game_leaderboard/status/<campaign id>/table?start=<ค่าเริ่มที่ 0>&count=…&order_by=…&desc=<0 หรือ 1>&search=<ค่าที่จะ filter>
  async fetchGameLeaderboardTable(id, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/status/${id}/table${queryString}`);
    return data;
  }

  // GET https://gwapi-sandbox.vivi-th.com/ams/api/game_leaderboard/status/<campaign id>/download
  async downloadGameLeaderboard(id) {
    return await this.axiosInstance.get(`/status/${id}/download`, {
      responseType: "blob",
    });
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group col-12" }, [
    _c("div", { attrs: { "my-15px": "" } }, [
      _vm._m(0),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "image-form-wrapper" }, [
          _c("div", { staticClass: "image-preview" }, [
            _vm.picture
              ? _c("img", {
                  staticClass: "img img-fluid",
                  attrs: { src: _vm.picture },
                })
              : _c("div", { staticClass: "image-placeholder" }),
          ]),
          _c("input", {
            ref: "picture",
            staticClass: "form-control",
            attrs: {
              type: "file",
              name: "picture",
              accept: "image/*",
              disabled: _vm.disabled,
            },
            on: { change: _vm.uploadImage },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [
        _vm._v(" Picture "),
        _c("span", { staticClass: "small light" }, [
          _vm._v(" Recommended size 300 x 300 pixels / less than 1 MB "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
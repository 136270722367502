<template>
  <div class="row">
    <div class="col-md-10" v-if="round">
      <label>
        <h5>Ranks and Rewards</h5>
      </label>
      <div class="form-group mb-0">
        <div class="clearfix">
          <p class="float-left">
            <a :href="exampleLink"> Click to download example </a>
          </p>
          <p class="float-right">
            <span class="date mr-10"> Last upload: {{ lastUploadTime }} </span>
            <span class="no-ranks">
              No. Ranks:
              {{ round.reward_count | numberWithCommas }}
            </span>
          </p>
        </div>

        <div>
          <label
            class="btn btn-round btn-upload mx-3"
            :for="`rank-reward-file-upload_${round.round_id}`"
            :disabled="!canUpload"
          >
            Click to upload file
          </label>

          <input
            type="file"
            :disabled="!canUpload"
            @change="onFileSelected"
            class="input-select-file"
            :id="`rank-reward-file-upload_${round.round_id}`"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { staffEngagementService } from "@/api/client";

export default {
  props: ["round"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    campaign() {
      return this.$parent.campaign;
    },
    canUpload() {
      return !["archived", "end", "aborted"].includes(this.campaign.status);
    },
    lastUploadTime() {
      const time = dayjs(this.round.upload_timestamp, "YYYY-MM-DD HH:mm:ss");
      return time.isValid() ? time.format("DD/MM/YYYY HH:mm") : "-";
    },
    exampleLink() {
      return `https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/staff-engagement/ranks-and-rewards/ee-test-reward.xlsx?dt=${new Date().getTime()}`;
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      event.target.value = null;
      this.upload();
    },

    async upload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        await staffEngagementService.uploadRankAndReward(
          this.campaign.id,
          this.round.round_id,
          form
        );

        this.$root.$emit("STAFF_ENGAGEMENT_REFETCH");
        this.$notify("Upload Rank and Reward success");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Rank and Reward error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select-file {
  width: 0px;
  height: 0px;
  padding: 0px;
  visibility: hidden;
}

.btn-upload {
  width: 100%;
  height: 100px;
  font-size: 1.5em;
  font-weight: 300;
  border-width: 0px;
  border-radius: 0.5em;
  background-color: #f4f6f7;

  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled="disabled"] {
    color: #c1c1c1;
    cursor: not-allowed;
    background-color: #e5eaec90;
  }
}
</style>

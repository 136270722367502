<template>
  <div class="form-group row">
    <label class="mx-5">
      <h5 class="col-md-12">Delist Date</h5>
    </label>
    <label class="col-md-2 form-control-label">After end date</label>
    <input
      :disabled="disabled"
      min="0"
      max="365"
      step="1"
      type="number"
      class="form-control col-md-1"
      v-model.number="delistDate.days"
      @change="submit"
      autocomplete="off"
    />
    <label class="col-md-0 form-control-label">days</label>
    <select
      :disabled="disabled"
      class="form-control col-md-1"
      v-model="delistDate.hours"
      @change="submit"
    >
      <option v-for="n in hourNums" :key="n" :value="n">
        {{ getTimeValue(n) }}
      </option>
    </select>
    <label class="col-md-0 form-control-label">hours</label>
    <select
      :disabled="disabled"
      class="form-control col-md-1"
      v-model="delistDate.minutes"
      @change="submit"
    >
      <option v-for="n in minuteNums" :key="n" :value="n">
        {{ getTimeValue(n) }}
      </option>
    </select>
    <label class="col-md-0 form-control-label">minutes</label>
    <label class="col-md-3 form-control-label">{{ delistDateString }}</label>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { leaderBoardService } from "@/api/client";

export default {
  name: "DelistDate",

  props: ["campaign"],

  data() {
    return {
      oldDelistDate: null,
      hourNums: _.range(0, 24, 1),
      minuteNums: _.range(0, 60, 1),
      delistDate: { days: null, hours: null, minutes: null },
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft" || this.campaign.end_date == null;
    },

    delistDateString() {
      const { delist_date } = this.campaign;
      const d = moment(delist_date);

      return d.isValid() ? d.format("DD/MM/YYYY, HH:mm") : "n/a";
    },

    formData() {
      const d = moment(this.campaign.end_date);
      d.add(this.delistDate.days, "days");
      d.add(this.delistDate.hours, "hours");
      d.add(this.delistDate.minutes, "minutes");

      return {
        delist_date: d.format("YYYY-MM-DD HH:mm:00"),
      };
    },
  },

  watch: {
    "campaign.end_date"(n, o) {
      if (n != o && this.campaign.delist_date != null) {
        this.campaign.delist_date = moment(this.campaign.end_date)
          .add(this.delistDate.days, "days")
          .add(this.delistDate.hours, "hours")
          .add(this.delistDate.minutes, "minutes");
      }
    },
  },

  methods: {
    getTimeValue(n) {
      return n < 10 ? `0${n}` : n;
    },

    initDelistDate() {
      const diffDays = moment(this.campaign.delist_date).diff(
        this.campaign.end_date,
        "days"
      );
      const diffHours = moment(this.campaign.delist_date).diff(
        this.campaign.end_date,
        "hours"
      );
      const diffMinutes = moment(this.campaign.delist_date).diff(
        this.campaign.end_date,
        "minutes"
      );

      this.delistDate.days = diffDays;
      this.delistDate.hours = diffHours % 24 || 0;
      this.delistDate.minutes = diffMinutes % diffHours || 0;

      this.oldDelistDate = diffDays;
    },

    async validate() {
      if (+this.delistDate.days < 0) {
        await this.$dialogs.alert("Delist Date ต้องระบุอย่างน้อย 0 วัน");

        this.delistDate.days = this.oldDelistDate;
        return false;
      }

      return true;
    },

    async submit() {
      const isValid = await this.validate();

      if (!isValid) return;

      try {
        const { id } = this.campaign;
        const form = this.formData;

        await leaderBoardService.updateCampaign(id, form);

        this.$emit("updated");
        this.$notify(`Delist date updated.`);
        this.oldDelistDate = this.delistDate.days;
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update delist date error.`);
      }
    },
  },

  mounted() {
    this.initDelistDate();
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-8" }, [
    _c("div", { staticClass: "input-wrapper" }, [
      _vm._m(0),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.story,
            expression: "story",
          },
        ],
        ref: "story",
        staticClass: "form-control",
        attrs: { rows: "3", maxlength: "300", disabled: _vm.disabled },
        domProps: { value: _vm.story },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.story = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Story")]),
      _c("p", [_vm._v("Max 300 characters")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("h5", [_vm._v("Consolidation Prize")]),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("label", { staticClass: "switch" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.campaign.consolidation_prize,
                expression: "campaign.consolidation_prize",
              },
            ],
            attrs: {
              type: "checkbox",
              "true-value": 1,
              "false-value": 0,
              disabled: _vm.disabled,
            },
            domProps: {
              checked: Array.isArray(_vm.campaign.consolidation_prize)
                ? _vm._i(_vm.campaign.consolidation_prize, null) > -1
                : _vm._q(_vm.campaign.consolidation_prize, 1),
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.campaign.consolidation_prize,
                    $$el = $event.target,
                    $$c = $$el.checked ? 1 : 0
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.campaign,
                          "consolidation_prize",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.campaign,
                          "consolidation_prize",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.campaign, "consolidation_prize", $$c)
                  }
                },
                _vm.update,
              ],
            },
          }),
          _c("span", { staticClass: "slider round" }),
        ]),
        _c("p", { staticClass: "mx-10 d-inline" }, [
          _vm._v(
            " " +
              _vm._s(+_vm.campaign.consolidation_prize === 1 ? "ON" : "OFF") +
              " "
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "d-flex align-items-center",
            staticStyle: { "margin-left": "60px" },
          },
          [
            _c(
              "label",
              {
                staticStyle: { width: "100px" },
                attrs: { for: "consolidation_prize_sf_point" },
              },
              [_vm._v(" SF Point ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.consolidation_sf_point,
                  expression: "campaign.consolidation_sf_point",
                },
              ],
              staticClass: "form-control",
              staticStyle: { "max-width": "120px" },
              attrs: {
                min: "0",
                max: "10000",
                type: "number",
                disabled:
                  _vm.disabled || +_vm.campaign.consolidation_prize !== 1,
              },
              domProps: { value: _vm.campaign.consolidation_sf_point },
              on: {
                change: _vm.update,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.campaign,
                    "consolidation_sf_point",
                    $event.target.value
                  )
                },
              },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
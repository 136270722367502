<template>
  <div>
    <label class="form-control-label">
      <h5>Theme Name</h5>
    </label>
    <input
      ref="name"
      type="text"
      v-model="name"
      maxlength="256"
      @change="update"
      class="form-control"
    />
    <nameValidation :$v="$v" />
  </div>
</template>

<script>
import { gameSamuraiService } from "@/api/client";
import warningChangeMixins from "../mixins/warning";
import nameValidation from "../validation/name.vue";
import { FormEditValidateRule as validateRules } from "../validation";

export default {
  props: ["theme"],

  mixins: [warningChangeMixins],

  components: {
    nameValidation,
  },

  data() {
    return { name: "" };
  },

  validations: { name: validateRules.name },

  methods: {
    validate() {
      if (this.$v.name.$invalid) {
        this.name = this.theme.name;
        this.$refs.name.focus();

        throw new Error("กรุณากรอก Theme Name ให้ถูกต้อง");
      }
    },

    async update() {
      try {
        this.validate();

        if (this.shouldWarning) {
          const ok = await this.warning();

          if (!ok) {
            this.name = this.theme.name;
            return;
          }
        }

        const { id } = this.theme;
        const form = { name: this.name };

        await gameSamuraiService.updateThemeConfig(id, form);

        this.$notify(`Theme name updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || `Update Theme name error.`);
      }
    },
  },

  mounted() {
    this.name = this.theme.name;
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding-left: 0px;
  h4 {
    margin-bottom: 0.5em;
  }
}
</style>

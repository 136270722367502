var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("div", { attrs: { "my-15px": "" } }, [
        _vm._m(0),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "d-block" }, [
            _vm.picture
              ? _c("img", {
                  staticClass: "img img-fluid image-preview",
                  attrs: { src: _vm.picture },
                })
              : _vm._e(),
          ]),
          _c("input", {
            ref: "prize_frame_picture",
            staticClass: "form-control",
            attrs: { type: "file", accept: "image/*", disabled: _vm.disabled },
            on: { change: _vm.uploadImage },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h5", [
        _vm._v(" Prize Frame Picture "),
        _c("span", { staticClass: "small light" }, [
          _vm._v(" (recommended size 367 x 369)"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create New Game Instance</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <h5 class="col-md-12">Game Instance Name</h5>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="game.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">Game</h5>
              <div class="col-md-12">
                <select class="form-control col-md-12" v-model="category">
                  <option value="fortune">Cigarette Fortune</option>
                  <option value="advocacy">Cigarette Advocacy</option>
                  <option value="sustainable">Cigarette Sustainable</option>
                  <option value="space">Cigarette Space</option>
                  <option value="run">Cigarette Run</option>
                  <option value="sunset_drive">Sunset Drive</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">User Group</h5>
              <div class="col-md-12">
                <select
                  class="form-control col-md-12"
                  v-model="selectUserGroup"
                >
                  <option
                    v-for="group in usergroups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ ` ${group.id} - ${group.group_code}` }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="createGame"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";
import { userGroupService } from "@/api/client";
import nameValidation from "./validation/name";
import { FormCreateValidateRule as validateRules } from "./validation";
export default {
  name: "Create",
  data() {
    return {
      showErrorNameValidation: false,
      game: [],
      usergroups: [],
      category: null,
      selectUserGroup: null,
    };
  },
  validations: { game: validateRules },
  components: {
    nameValidation,
  },
  created() {
    this.fetchUserGroup();
  },
  methods: {
    async isFormValid() {
      if (this.$v.game.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Game Instance Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }

      if (this.category == null) {
        await this.$dialogs.alert("กรุณากรอก Game ให้ถูกต้อง");
        return false;
      }

      if (this.selectUserGroup == null) {
        await this.$dialogs.alert("กรุณากรอก usergroup ให้ถูกต้อง");
        return false;
      }

      return true;
    },
    async getFormData() {
      return {
        name: this.game.name,
        game: this.category,
        group_id: this.selectUserGroup,
      };
    },
    async createGame() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const body = await this.getFormData();

        await gameService.createGame(body);
        await this.$dialogs.alert("Create Game Instance Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
    async fetchUserGroup() {
      this.usergroups = await userGroupService.getUserGroups();
    },
    close() {
      this.$modal.hide("create-modal");
    },
    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

<template>
  <div>
    <label>
      <h5>Quota Summary</h5>
    </label>
    <input
      type="text"
      ref="name"
      maxlength="255"
      class="form-control"
      v-model="name"
      @change="update"
      :disabled="!canEdit"
    />
    <nameValidation :$v="$v" />
  </div>
</template>

<script>
import { walletService } from "@/api/client";

import nameValidation from "@/views/point/PointQuotaLimit/validation/name.vue";
import { FormEditValidateRule as validateRules } from "@/views/point/PointQuotaLimit/validation";

export default {
  name: "QuotaName",

  props: ["quota"],

  components: {
    nameValidation,
  },

  validations: { name: validateRules.name },

  data() {
    return {
      name: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.quota.status);
    },
  },

  mounted() {
    this.name = this.quota.name;
  },

  methods: {
    async isFormValid() {
      if (this.$v.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Quota Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    async update() {
      const formValid = await this.isFormValid();
      if (!formValid) return;

      const { id } = this.quota;

      await walletService.updateQuotaInfo(id, { name: this.name });
      this.$notify("Update name success.");
      this.$emit("updated");
    },
  },
};
</script>

<style></style>

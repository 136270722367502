<template>
  <div class="col-md-12" v-if="campaign">
    <label class="form-control-label">
      <h4>Campaign Duration</h4>
    </label>
    <label class="d-inline mx-10 form-control-label">
      Start
    </label>
    <div class="d-inline mx-10">
      <DatePicker
        type="date"
        width-150px
        @change="update"
        placeholder="n/a"
        ref="start_date"
        format="YYYY-MM-DD"
        value-type="YYYY-MM-DD"
        :disabled="disabled"
        v-model="campaign.start_date"
      />
    </div>
    <label class="d-inline mx-10 form-control-label">
      End
    </label>
    <div class="d-inline mx-10">
      <DatePicker
        type="date"
        width-150px
        @change="update"
        placeholder="n/a"
        ref="end_date"
        format="YYYY-MM-DD"
        value-type="YYYY-MM-DD"
        :disabled="disabled"
        v-model="campaign.end_date"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  mounted() {
    const { start_date, end_date } = this.campaign;
    this.oldValue = { start_date, end_date };
  },

  methods: {
    async isFormValid() {
      if (
        moment(this.campaign.start_date).diff(
          new Date().setHours(0, 0, 0, 0),
          "days"
        ) < 1
      ) {
        await this.$dialogs.alert(
          "Start date ใส่ค่าได้ตั้งแต่วันที่ในอนาคตเท่านั้น"
        );
        this.$refs.start_date.focus();

        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        return false;
      } else if (
        moment(this.campaign.start_date) > moment(this.campaign.end_date)
      ) {
        await this.$dialogs.alert(
          "กรุณาระบุวันเริ่มที่น้อยกว่าวันสิ้นสุด campaign"
        );
        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        return false;
      }

      return true;
    },

    withInThreeMonth(start_date, end_date) {
      const endDate = moment(end_date);
      const startDate = moment(start_date);

      if (startDate.isValid() && endDate.isValid()) {
        const months = [0, 0, 0].map((o, i) => {
          return moment(start_date).add(i, "month").format("MMYYYY");
        });

        return months.includes(endDate.format("MMYYYY"));
      } else {
        return true;
      }
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const { id, start_date, end_date } = this.campaign;
        const form = {
          end_date: moment(end_date || start_date).format(
            "YYYY-MM-DD 23:59:59"
          ),
          start_date: moment(start_date || end_date).format(
            "YYYY-MM-DD 00:00:00"
          ),
        };

        await progressBarService.updateCampaignInfo(id, form);

        this.oldValue = { start_date, end_date };

        this.$emit("updated");
        this.$notify(`Campaign Duration updated.`);
      } catch (error) {
        this.$dialogs.alert(`Campaign Duration update error.`);
      }
    },

    setDefaultValues(start, end) {
      this.campaign.end_date = end;
      this.campaign.start_date = start;

      this.duration = moment(new Date(end)).diff(new Date(start), "days") + 1;
    },
  },
};
</script>

<style></style>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Game Instance</h1>
      <breadcrumb name="GAME_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :columns="columns"
              :rows="gamelist"
              @on-row-dblclick="onRowDoubleClick"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
              ><template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'status'">
                  {{ props.row.status | toUpperCase }}
                </div>
                <div v-else-if="props.column.field == 'end_date'">
                  {{ props.row.end_date == null ? "n/a" : props.row.end_date }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="900" height="auto">
      <CreateModal @submit="fetchgame" />
    </modal>
  </div>
</template>

<script>
import { gameService } from "@/api/client";
import CreateModal from "@/views/game/CreateModal.vue";
import { gameTableColumns } from "@/views/game/model";
export default {
  name: "GamesInstanceList",

  data() {
    return {
      hideArchive: false,
      game: [],
      gamelist: [],
      columns: gameTableColumns,
      pagination: {
        perPage: 10,
        enabled: true,
      },
      searchOption: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
      },
    };
  },

  components: { CreateModal },

  created() {
    this.fetchgame();
  },

  watch: {
    hideArchive() {
      this.filter();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    onRowDoubleClick({ row }) {
      const { id, game, group_code } = row;

      if (game == "sunset_drive") {
        return this.$router
          .push({
            name: "GamesInstanceSunsetDrive",
            params: { id, group_code },
          })
          .catch((err) => {});
      }

      this.$router
        .push({ name: "GamesInstanceListEdit", params: { id } })
        .catch((err) => {});
    },
    async fetchgame() {
      this.game = await gameService.getGame({ show_all: 1 });
      this.gamelist = await this.game;
    },
    filter() {
      this.hideArchive
        ? (this.gamelist = this.game.filter((g) => g.status != "archived"))
        : (this.gamelist = this.game);
    },
    async reloadTable() {
      await this.fetchgame();
      this.$notify("Updated");
    },
    async openCreateModal() {
      this.$modal.show(`create-modal`);
    },

    onPageChange(params) {
      if (params.total > 0) {
        this.updateParams(this.list.length);
      }
    },

    updateParams(newProps) {
      this.pagination.perPage = Object.assign(
        {},
        this.pagination.perPage,
        newProps
      );
    },

    onSearch(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            searchTerm: params.searchTerm,
          }),
        })
        .catch((err) => {});
    },

    onSortChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            sortBy: params[0].field,
            sortDir: params[0].type,
          }),
        })
        .catch((err) => {});
    },

    onPageChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            perPage: params.currentPerPage,
            page: params.currentPage,
          }),
        })
        .catch((err) => {});
    },

    initialTableState() {
      //default
      if (!this.$route.query.sortBy || !this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: "id",
          type: "desc",
        };

      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = +this.$route.query.page;
      if (this.$route.query.perPage)
        this.pagination.perPage = +this.$route.query.perPage;
    },

    initialTableSearchState() {
      // Searching
      if (this.$route.query.searchTerm)
        this.$refs["table"].globalSearchTerm = this.$route.query.searchTerm;
    },
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>

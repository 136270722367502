<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Winner</h1>
      <breadcrumb name="WINNER_LIST"></breadcrumb>
      <div class="Excel">
        <button @click="exportToExcel" class="btn btn-default">
          <i class="fa fa-file-excel-o"></i>
          Export to Excel
        </button>
      </div>
      <div class="page-header-right">
        Selected Period
        <date-picker
          v-model="from_to"
          type="date"
          value-type="format"
          format="YYYY-MM-DD"
          range
          placeholder="Select date range"
        ></date-picker>
      </div>
      <div class="page-header-actions">
        <button
          type="button"
          @click="showall"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          Show All
        </button>
        <button
          type="button"
          @click="filter = 'luckydraw'"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          Lucky Draw
        </button>
        <button
          type="button"
          @click="filter = 'auction'"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          Auction
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              :columns="columns"
              :rows="listwinner"
              @on-row-click="goToDetail"
              @on-select-all="onSelectAll"
              @on-search="onSearch"
              :search-options="{
                enabled: true,
                placeholder: 'Search this',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: selected,
              }"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="detail-modal" :width="900" height="auto">
      <DetailModal :winner="winner" />
    </modal>
  </div>
</template>

<script>
import { winnerService } from "@/api/client";
import { winnerTableColumns } from "@/views/winner/model";

import DetailModal from "@/views/winner/Detail.vue";

export default {
  name: "WinnerList",

  components: { DetailModal },

  data() {
    const list = [];
    const winner = null;
    const listwinner = [];
    const download = [];
    return {
      from_to: [],
      columns: winnerTableColumns,
      searchfilter: "",
      list,
      checksearchbox: 0,
      download,
      listwinner,
      filter: null,
      winner,
      selected: null,
    };
  },
  created() {
    this.from_to = [this.lastmonth(), this.today()];
  },
  watch: {
    filter() {
      this.showtype();
    },
    from_to() {
      this.update();
    },
    list() {
      this.selected = this.list.length;
    },
  },
  methods: {
    onSelectAll() {
      this.checksearchbox += 1;
    },
    onSearch(params) {
      this.checksearchbox = 0;
      this.searchfilter = params.searchTerm;
      this.checksearchbox += 1;
    },

    async exportToExcel() {
      try {
        const response = await winnerService.download({
          from: this.from_to[0],
          to: this.from_to[1],
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `export_winners_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.error(error);
      }
    },

    showall() {
      this.filter = null;
      this.selected = this.list.length;
    },

    async update() {
      if (this.from_to[1] == null || this.from_to[0] == null) {
        this.winner = await winnerService.getWinnerDate();
      } else {
        this.winner = await winnerService.getWinnerDate(
          this.from_to[0],
          this.from_to[1]
        );
      }
      this.list = this.winner;
      this.listwinner = this.winner;
    },
    showtype() {
      this.selected = null;
      if (this.filter == "auction") {
        this.list = this.winner.filter((g) => g.type == "auction");
      } else if (this.filter == "luckydraw") {
        this.list = this.winner.filter((g) => g.type == "luckydraw");
      } else {
        this.list = this.winner;
      }
      this.listwinner = this.list;
    },
    wrongNumber() {
      return this.isNumeric(this.selected) === false;
    },

    async goToDetail(event) {
      this.winner = event.row;
      this.$modal.show(`detail-modal`);
    },

    lastmonth() {
      var today = new Date();
      var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 30 * 3)
        .toJSON()
        .slice(0, 10);
      return lastmonth;
    },
    today() {
      var today = new Date().toJSON().slice(0, 10);
      return today;
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin-top: 35px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "center" }, [
              _vm._v("Remove " + _vm._s(_vm.image)),
            ]),
          ]),
          _vm._m(1),
        ]),
      ]),
      _c("div", { staticClass: "panel-footer" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-default mx-10",
              attrs: { type: "button" },
              on: { click: _vm.close },
            },
            [
              _c("i", {
                staticClass: "icon md-close",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Cancel "),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.confrimRemove },
            },
            [
              _c("i", {
                staticClass: "icon md-check",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Remove "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c(
        "h3",
        { staticClass: "panel-title", staticStyle: { "text-align": "center" } },
        [_vm._v("Remove SKUs")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "center" }, [
        _vm._v(" You Will NOT be able undo this operation! "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
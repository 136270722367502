<template>
  <div class="row">
    <div class="col-4">
      <p class="light text-right" ml-5>E-Coupon Expiration</p>
    </div>
    <div class="col-8">
      <table class="table table-responsive table-borderless">
        <tr>
          <td>
            <div class="radio-input-row radio-custom radio-primary">
              <input
                type="radio"
                value="days"
                @change="update"
                name="expire_mode"
                :disabled="disabled || reward.reward_type !== 'ecoupon'"
                v-model="reward.expire_mode"
                :id="`expire_mode_days_${reward.id}`"
              />
              <label :for="`expire_mode_days_${reward.id}`">After grant</label>
            </div>
          </td>
          <td>
            <div class="d-flex gap-3 align-items-center">
              <input
                min="0"
                max="9999"
                width-80px
                type="number"
                @change="update"
                name="expire_days"
                class="form-control"
                v-model="reward.expire_days"
                :disabled="
                  disabled ||
                  reward.expire_mode != 'days' ||
                  reward.reward_type !== 'ecoupon'
                "
              />
              <span>days</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="radio-input-row radio-custom radio-primary">
              <input
                type="radio"
                value="date"
                @change="update"
                name="expire_mode"
                :disabled="disabled || reward.reward_type !== 'ecoupon'"
                v-model="reward.expire_mode"
                :id="`expire_mode_date_${reward.id}`"
              />
              <label :for="`expire_mode_date_${reward.id}`">
                Specific date
              </label>
            </div>
          </td>
          <td>
            <DatePicker
              type="date"
              width-120px
              name="expire_date"
              @change="update($event, 'expire_date', reward.expire_date)"
              placeholder="n/a"
              ref="expire_date"
              format="YYYY-MM-DD"
              value-type="YYYY-MM-DD"
              v-model="reward.expire_date"
              :disabled="
                disabled ||
                reward.expire_mode != 'date' ||
                reward.reward_type !== 'ecoupon'
              "
              :disabled-date="disabledBeforeTomorrow"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  components: {
    DatePicker,
  },

  data() {
    return {
      message: {
        error: `Update Expiration Reward #${this.reward.id} error.`,
        success: `Update Expiration Reward #${this.reward.id} success.`,
      },
    };
  },

  methods: {
    disabledBeforeTomorrow(date) {
      const tomorrow = dayjs().add(1, "days").toDate();
      tomorrow.setHours(0, 0, 0, 0);
      return date < tomorrow;
    },
  },
};
</script>

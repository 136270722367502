export const historyTableColumns = [
  {
    label: "Lot ID",
    field: "lot_id",
    type: "number",
  },
  {
    label: "Lot Name",
    field: "lot_name",
  },
  {
    label: "Product Name",
    field: "product_name",
  },
  {
    label: "Type",
    field: "type",
  },
  {
    label: "Start Date",
    field: "start_date",
  },
  {
    label: "End Date",
    field: "end_date",
  },
  {
    label: "No. Winners",
    field: "num_winner",
    type: "number",
  },
  {
    label: "Winning Bid",
    field: "winning_bid",
    type: "number",
  },
];
export const historyTableColumnsExcel = {
  "Lot ID": "lot_id",
  "Product Name": "product_name",
  "Lot Name": "lot_name",
  Type: "type",
  "Start Date": "start_date",
  "End Date": "end_date",
  "No. Winners": "num_winner",
  "Winning Bid": "winning_bid",
};
export const activity_log_auction_TableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User Name",
    field: "firstname",
  },
  {
    label: "Winner",
    field: "winner",
    type: "number",
  },
  {
    label: "Bidding Amount",
    field: "points",
    type: "number",
  },
];
export const activity_log_luckydraw_TableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User Name",
    field: "firstname",
  },
  {
    label: "Winner",
    field: "winner",
  },
  {
    label: "Point used",
    field: "points",
    type: "number",
  },
];

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pool
    ? _c("div", { staticClass: "px-15" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-round dropdown-toggle",
              staticStyle: { "text-transform": "uppercase" },
              attrs: {
                type: "button",
                "data-toggle": "dropdown",
                "aria-expanded": "false",
              },
            },
            [_vm._v(" " + _vm._s(_vm.pool.status) + " ")]
          ),
          _c("div", { staticClass: "dropdown-menu", attrs: { role: "menu" } }, [
            _vm.pool.status == "active"
              ? _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "javascript:void(0)", role: "menuitem" },
                    on: {
                      click: function ($event) {
                        return _vm.update("inactive")
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-archive",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" INACTIVE "),
                  ]
                )
              : _vm._e(),
            _vm.pool.status == "inactive"
              ? _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "javascript:void(0)", role: "menuitem" },
                    on: {
                      click: function ($event) {
                        return _vm.update("active")
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-long-arrow-up",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" ACTIVE "),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme
    ? _c(
        "div",
        { staticClass: "col-md-12", attrs: { id: "prize-theme-pool-options" } },
        [
          _vm._m(0),
          _vm._l(_vm.selected, function (pool, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "option",
                on: {
                  click: function ($event) {
                    return _vm.handleOptionClick(pool)
                  },
                },
              },
              [
                _c("p", { staticClass: "no" }, [_vm._v(_vm._s(index + 1))]),
                _c(
                  "div",
                  {
                    staticClass: "selected-pool",
                    attrs: {
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: "Click to edit",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(pool.id) + " - " + _vm._s(pool.name) + " "
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: {
                      type: "button",
                      disabled: _vm.disabled || !_vm.canRemoveOption,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.removeOption(pool.id)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-delete",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]
            )
          }),
          _c("div", { staticClass: "action" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: {
                  type: "button",
                  disabled: _vm.disabled || !_vm.canAddMoreOption,
                },
                on: { click: _vm.addMoreOption },
              },
              [
                _c("i", {
                  staticClass: "icon md-plus",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Add Pool option "),
              ]
            ),
          ]),
          _c(
            "modal",
            {
              attrs: { name: "pool-picker-modal", width: 400, height: "auto" },
            },
            [
              _c("PoolPicker", {
                attrs: {
                  pools: _vm.availablePools(),
                  name: "pool-picker-modal",
                },
                on: { confirm: _vm.confirmSelect },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("h4", [_vm._v("Pool Option")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-12" }, [
    _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
      _vm._v(" Date Time "),
    ]),
    _c(
      "div",
      { staticClass: "d-inline mx-10" },
      [
        _c("DatePicker", {
          ref: "linenoti_date",
          attrs: {
            type: "datetime",
            "width-250px": "",
            placeholder: "n/a",
            format: "YYYY-MM-DD HH:mm:ss",
            "value-type": "YYYY-MM-DD HH:mm:ss",
            disabled: _vm.disabled,
            confirm: true,
          },
          on: { confirm: _vm.update },
          model: {
            value: _vm.campaign.linenoti_date,
            callback: function ($$v) {
              _vm.$set(_vm.campaign, "linenoti_date", $$v)
            },
            expression: "campaign.linenoti_date",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "col-md-12" }, [
        _c("div", {}, [
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tbody", [
                _c("tr", [
                  _vm._m(0),
                  _c("td", [_vm._v("Name")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.campaign.grandprize_name,
                          expression: "campaign.grandprize_name",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        maxlength: "40",
                        disabled: !_vm.canEdit,
                      },
                      domProps: { value: _vm.campaign.grandprize_name },
                      on: {
                        change: function ($event) {
                          return _vm.update("grandprize_name")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.campaign,
                            "grandprize_name",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("td", { attrs: { rowspan: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column gap-5 px-20" },
                      [
                        _c("div", { staticClass: "reward-choice" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.grandprize_type,
                                expression: "campaign.grandprize_type",
                              },
                            ],
                            attrs: {
                              value: "other",
                              type: "radio",
                              disabled: !_vm.canEdit,
                              name: "grandprize_type",
                              id: "reward-choice-physical-grandprize_type",
                            },
                            domProps: {
                              checked: _vm.campaign.grandprize_type === "other",
                              checked: _vm._q(
                                _vm.campaign.grandprize_type,
                                "other"
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.campaign,
                                    "grandprize_type",
                                    "other"
                                  )
                                },
                                function ($event) {
                                  return _vm.update("grandprize_type")
                                },
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: {
                                for: "reward-choice-physical-grandprize_type",
                              },
                            },
                            [_vm._v(" Physical ")]
                          ),
                        ]),
                        _c("div", { staticClass: "reward-choice" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.grandprize_type,
                                expression: "campaign.grandprize_type",
                              },
                            ],
                            attrs: {
                              value: "sfdc",
                              type: "radio",
                              disabled: !_vm.canEdit,
                              name: "grandprize_type",
                              id: "reward-choice-sf-point-grandprize_type",
                            },
                            domProps: {
                              checked: _vm.campaign.grandprize_type === "sfdc",
                              checked: _vm._q(
                                _vm.campaign.grandprize_type,
                                "sfdc"
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.campaign,
                                    "grandprize_type",
                                    "sfdc"
                                  )
                                },
                                function ($event) {
                                  return _vm.update("grandprize_type")
                                },
                              ],
                            },
                          }),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: {
                                  for: "reward-choice-sf-point-grandprize_type",
                                },
                              },
                              [_vm._v(" SF Point ")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.campaign.grandprize_points,
                                  expression: "campaign.grandprize_points",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { width: "120px" },
                              attrs: {
                                min: 1,
                                max: "1000000",
                                type: "number",
                                disabled:
                                  !_vm.canEdit ||
                                  _vm.campaign.grandprize_type !== "sfdc",
                              },
                              domProps: {
                                value: _vm.campaign.grandprize_points,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.update("grandprize_points")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.campaign,
                                    "grandprize_points",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "reward-choice flex-align-top" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.grandprize_type,
                                  expression: "campaign.grandprize_type",
                                },
                              ],
                              attrs: {
                                value: "ecoupon",
                                type: "radio",
                                disabled: !_vm.canEdit,
                                name: "grandprize_type",
                                id: "reward-choice-e-coupon-grandprize_type",
                              },
                              domProps: {
                                checked:
                                  _vm.campaign.grandprize_type === "ecoupon",
                                checked: _vm._q(
                                  _vm.campaign.grandprize_type,
                                  "ecoupon"
                                ),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    return _vm.$set(
                                      _vm.campaign,
                                      "grandprize_type",
                                      "ecoupon"
                                    )
                                  },
                                  function ($event) {
                                    return _vm.update("grandprize_type")
                                  },
                                ],
                              },
                            }),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-align-top mb-10" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label",
                                      attrs: {
                                        for: "reward-choice-e-coupon-grandprize_type",
                                      },
                                    },
                                    [_vm._v(" E-Coupon ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "face-value-form" },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "input-label",
                                            attrs: {
                                              for: "reward-choice-face-value-grandprize_type",
                                            },
                                          },
                                          [_vm._v(" Face Value ")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value:
                                                _vm.campaign
                                                  .grandprize_face_value,
                                              expression:
                                                "campaign.grandprize_face_value",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            min: "1",
                                            max: "999999",
                                            type: "number",
                                            disabled:
                                              !_vm.canEdit ||
                                              _vm.campaign.grandprize_type !==
                                                "ecoupon",
                                            id: "reward-choice-face-value-grandprize_type",
                                          },
                                          domProps: {
                                            value:
                                              _vm.campaign
                                                .grandprize_face_value,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.update(
                                                "grandprize_face_value"
                                              )
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.campaign,
                                                "grandprize_face_value",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v("bht")]),
                                      ]),
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "input-label",
                                            attrs: {
                                              for: "reward-choice-topup-grandprize_type",
                                            },
                                          },
                                          [_vm._v(" Top Up ")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value:
                                                _vm.campaign.grandprize_topup,
                                              expression:
                                                "campaign.grandprize_topup",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            min: "0",
                                            max: "999999",
                                            type: "number",
                                            disabled:
                                              !_vm.canEdit ||
                                              _vm.campaign.grandprize_type !==
                                                "ecoupon",
                                            id: "reward-choice-topup-grandprize_type",
                                          },
                                          domProps: {
                                            value:
                                              _vm.campaign.grandprize_topup,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.update(
                                                "grandprize_topup"
                                              )
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.campaign,
                                                "grandprize_topup",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v("bht")]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex flex-align-top" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label line-high-1",
                                      attrs: {
                                        for: "reward-choice-custom-text-grandprize_type",
                                      },
                                    },
                                    [
                                      _vm._v(" Custom text"),
                                      _c("br"),
                                      _vm._v("on coupon "),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.campaign.grandprize_custom_text,
                                        expression:
                                          "campaign.grandprize_custom_text",
                                      },
                                    ],
                                    staticClass: "form-control w250",
                                    attrs: {
                                      type: "text",
                                      maxlength: "40",
                                      disabled:
                                        !_vm.canEdit ||
                                        _vm.campaign.grandprize_type !==
                                          "ecoupon",
                                      id: "reward-choice-custom-text-grandprize_type",
                                    },
                                    domProps: {
                                      value:
                                        _vm.campaign.grandprize_custom_text,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.update(
                                          "grandprize_custom_text"
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.campaign,
                                          "grandprize_custom_text",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "reward-choice" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.grandprize_type,
                                expression: "campaign.grandprize_type",
                              },
                            ],
                            attrs: {
                              value: "prizepool",
                              type: "radio",
                              disabled: !_vm.canEdit,
                              name: "grandprize_type",
                              id: "reward-choice-prizepool-grandprize_type",
                            },
                            domProps: {
                              checked:
                                _vm.campaign.grandprize_type === "prizepool",
                              checked: _vm._q(
                                _vm.campaign.grandprize_type,
                                "prizepool"
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.campaign,
                                    "grandprize_type",
                                    "prizepool"
                                  )
                                },
                                function ($event) {
                                  return _vm.update("grandprize_type")
                                },
                              ],
                            },
                          }),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: {
                                  for: "reward-choice-prizepool-grandprize_type",
                                },
                              },
                              [_vm._v(" Prize Pool ")]
                            ),
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "grandprize_theme_id",
                                  attrs: { for: "grandprize_theme_id" },
                                },
                                [_vm._v(" Prize Theme ID ")]
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.campaign.grandprize_theme_id,
                                      expression:
                                        "campaign.grandprize_theme_id",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: "grandprize_theme_id",
                                    id: "grandprize_theme_id",
                                    disabled:
                                      !_vm.canEdit ||
                                      _vm.campaign.grandprize_type !==
                                        "prizepool",
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return _vm._n(val)
                                            })
                                        _vm.$set(
                                          _vm.campaign,
                                          "grandprize_theme_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.update("grandprize_theme_id")
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [_vm._v("Not Select")]
                                  ),
                                  _vm._l(_vm.prizepool, function (prize) {
                                    return _c(
                                      "option",
                                      {
                                        key: prize.id,
                                        domProps: { value: prize.id },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(prize.id) +
                                            " - " +
                                            _vm._s(prize.name) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td"),
                  _c("td", [_vm._v("Details")]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.campaign.grandprize_details,
                          expression: "campaign.grandprize_details",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { rows: "5", disabled: !_vm.canEdit },
                      domProps: { value: _vm.campaign.grandprize_details },
                      on: {
                        change: function ($event) {
                          return _vm.update("grandprize_details")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.campaign,
                            "grandprize_details",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td"),
                  _c("td", [_vm._v("Picture")]),
                  _c(
                    "td",
                    [
                      _c("GrandPrizePicture", {
                        attrs: { campaign: _vm.campaign },
                        on: {
                          updated: function ($event) {
                            return _vm.$emit("updated")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _c("hr"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "px-0" }, [
      _c("h5", [_vm._v("Grand Prize")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h1", { staticClass: "page-title" }, [_vm._v("Advocacy Setting")]),
          _c("breadcrumb", { attrs: { name: "ADVOCACY_LIST" } }),
        ],
        1
      ),
      _c("div", { staticClass: "page-content page-content-table" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "panel" }, [
            _c("nav", { staticClass: "nav nav-pills nav-fill" }, [
              _c(
                "a",
                {
                  class: !_vm.barSelect
                    ? "nav-item nav-link active"
                    : "nav-item nav-link",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      _vm.barSelect = false
                    },
                  },
                },
                [_vm._v("SKUs")]
              ),
              _c(
                "a",
                {
                  class: _vm.barSelect
                    ? "nav-item nav-link active"
                    : "nav-item nav-link",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      _vm.barSelect = true
                    },
                  },
                },
                [_vm._v("Question Pool")]
              ),
            ]),
            _c("div", { staticClass: "panel-body p-0" }, [
              _vm.barSelect
                ? _c("div", [
                    _c("div", { staticClass: "page-content container-fluid" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                          _vm.OnEdit == true
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "panel panel-bordered" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "panel-heading" },
                                      [
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-6",
                                            },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  " Question - " +
                                                    _vm._s(_vm.getquestion.id) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.getquestion.name
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-6",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "px-15" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "btn-group",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-primary btn-round dropdown-toggle",
                                                          staticStyle: {
                                                            "text-transform":
                                                              "uppercase",
                                                          },
                                                          attrs: {
                                                            type: "button",
                                                            "data-toggle":
                                                              "dropdown",
                                                            "aria-expanded":
                                                              "false",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getquestion
                                                                  .status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-menu",
                                                          attrs: {
                                                            role: "menu",
                                                          },
                                                        },
                                                        [
                                                          _vm.getquestion
                                                            .status == "active"
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-item",
                                                                  attrs: {
                                                                    href: "javascript:void(0)",
                                                                    role: "menuitem",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.submitStatus(
                                                                          "inactive"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Inactive "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getquestion
                                                            .status ==
                                                          "inactive"
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-item",
                                                                  attrs: {
                                                                    href: "javascript:void(0)",
                                                                    role: "menuitem",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.submitStatus(
                                                                          "active"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Active "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "back-right",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-round btn-default mx-15",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.OnEdit = false
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Back to List "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("form", { staticClass: "form-horizontal" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-sm-2 col-form-control-label",
                                      },
                                      [_vm._v("Question Name")]
                                    ),
                                    _c("div", { staticClass: "col-sm-6" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.getquestion.name,
                                            expression: "getquestion.name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          maxlength: "50",
                                        },
                                        domProps: {
                                          value: _vm.getquestion.name,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.submitQuestion("name")
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.getquestion,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-sm-2 col-form-control-label",
                                      },
                                      [_vm._v("Question Text")]
                                    ),
                                    _c("div", { staticClass: "col-sm-6" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.getquestion.text,
                                            expression: "getquestion.text",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          maxlength: "64",
                                        },
                                        domProps: {
                                          value: _vm.getquestion.text,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.submitQuestion("text")
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.getquestion,
                                              "text",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-sm-2 col-form-control-label",
                                      },
                                      [_vm._v("Character")]
                                    ),
                                    _c("div", { staticClass: "col-sm-6" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getquestion.character,
                                              expression:
                                                "getquestion.character",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.getquestion,
                                                  "character",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.submitQuestion(
                                                  "character"
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "A" } },
                                            [_vm._v("White Collar")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "B" } },
                                            [_vm._v("Day Hire")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "C" } },
                                            [_vm._v("Self Hire")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "D" } },
                                            [_vm._v("LA29 ")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("label", {
                                      staticClass:
                                        "col-sm-2 col-form-control-label",
                                    }),
                                    _c("div", { staticClass: "col-sm-6" }, [
                                      _c("img", {
                                        staticClass: "preview",
                                        attrs: {
                                          src: _vm.thumbnailChar(
                                            _vm.getquestion.character
                                          ),
                                          alt: "",
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("table", { staticClass: "table" }, [
                                    _vm._m(0),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.getquestion.choices,
                                        function (item, index) {
                                          return _c("tr", { key: index }, [
                                            _c("th", { staticClass: "t1" }, [
                                              _vm._v("No." + _vm._s(index)),
                                            ]),
                                            _c("th", { staticClass: "t2" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-sm-2 col-form-control-label",
                                                    },
                                                    [_vm._v("SKU: ")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-sm-8" },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.sku_id,
                                                              expression:
                                                                "item.sku_id",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  item,
                                                                  "sku_id",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.submitChoices(
                                                                  index
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "null",
                                                                disabled: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Please choose...."
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.skus,
                                                            function (sku) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: sku.id,
                                                                  attrs: {
                                                                    disabled:
                                                                      sku.status ==
                                                                      "inactive",
                                                                    hidden:
                                                                      sku.status ==
                                                                      "inactive",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      sku.id,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        " " +
                                                                          sku.label +
                                                                          " "
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("th", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("toUpperCase")(
                                                      _vm.ShowSku(
                                                        item.sku_id,
                                                        "status"
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("th", [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.ShowSku(
                                                    item.sku_id,
                                                    "thumbnail"
                                                  ),
                                                  alt: "",
                                                },
                                              }),
                                            ]),
                                            _c("th", [
                                              _c(
                                                "div",
                                                { staticClass: "center" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          item.correct_answer,
                                                        expression:
                                                          "item.correct_answer",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-check-input",
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        item.correct_answer
                                                      )
                                                        ? _vm._i(
                                                            item.correct_answer,
                                                            null
                                                          ) > -1
                                                        : item.correct_answer,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              item.correct_answer,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "correct_answer",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "correct_answer",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              item,
                                                              "correct_answer",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.submitChoices(
                                                            index
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                            _c("th", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-round btn-default mx-10",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DeleteChoice(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-trash-o",
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                              ])
                            : _c(
                                "div",
                                [
                                  _c(
                                    "vue-good-table",
                                    {
                                      attrs: {
                                        columns: _vm.columns,
                                        rows: _vm.questions,
                                        "search-options": {
                                          enabled: true,
                                        },
                                        "pagination-options": {
                                          enabled: true,
                                          mode: "records",
                                        },
                                      },
                                      on: {
                                        "on-row-dblclick": _vm.onRowDoubleClick,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "table-row",
                                            fn: function (props) {
                                              return [
                                                props.column.field ==
                                                "character"
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.CharName(
                                                              props.row
                                                                .character
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ])
                                                  : props.column.field ==
                                                    "thumbnail"
                                                  ? _c("span", [
                                                      _c("img", {
                                                        staticClass:
                                                          "imageTable",
                                                        attrs: {
                                                          src: _vm.thumbnailChar(
                                                            props.row.character
                                                          ),
                                                          alt: "",
                                                        },
                                                      }),
                                                    ])
                                                  : props.column.field ==
                                                    "status"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toUpperCase"
                                                            )(props.row.status)
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.formattedRow[
                                                              props.column.field
                                                            ]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3787411366
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "table-actions" },
                                          slot: "table-actions",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-round btn-primary",
                                              attrs: { type: "button" },
                                              on: { click: _vm.CreateQuestion },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon md-plus",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                              _vm._v(" Create "),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("div", [
                    _c("div", { staticClass: "page-content container-fluid" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                          _c("table", { staticClass: "table" }, [
                            _vm._m(1),
                            _c(
                              "tbody",
                              _vm._l(_vm.skus, function (sku, index) {
                                return _c("tr", { key: index }, [
                                  _c("th", { staticClass: "t1" }, [
                                    _vm._v("No." + _vm._s(index + 1)),
                                  ]),
                                  _c("th", { staticClass: "t2" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.skus[index].status,
                                            expression: "skus[index].status",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.skus[index],
                                                "status",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.submit(
                                                "status",
                                                _vm.skus[index].id,
                                                index
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "active",
                                              disabled:
                                                _vm.skus[index].status ==
                                                "active",
                                              hidden:
                                                _vm.skus[index].status ==
                                                "active",
                                            },
                                          },
                                          [_vm._v("ACTIVE")]
                                        ),
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "inactive",
                                              disabled:
                                                _vm.skus[index].status ==
                                                "inactive",
                                              hidden:
                                                _vm.skus[index].status ==
                                                "inactive",
                                            },
                                          },
                                          [_vm._v("INACTIVE")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("th", { staticClass: "t3" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.skus[index].label,
                                          expression: "skus[index].label",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", maxlength: "50" },
                                      domProps: {
                                        value: _vm.skus[index].label,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submit(
                                            "label",
                                            _vm.skus[index].id,
                                            index
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.skus[index],
                                            "label",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("th", { staticClass: "t4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.skus[index].description,
                                          expression: "skus[index].description",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", maxlength: "255" },
                                      domProps: {
                                        value: _vm.skus[index].description,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submit(
                                            "description",
                                            _vm.skus[index].id,
                                            index
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.skus[index],
                                            "description",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("th", { staticClass: "t5" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.skus[index].thumbnail,
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c("th", { staticClass: "t6" }, [
                                    _c("input", {
                                      ref: "fileUpload" + _vm.skus[index].id,
                                      refInFor: true,
                                      staticStyle: { display: "none" },
                                      attrs: {
                                        type: "file",
                                        accept: "image/*",
                                      },
                                      on: { change: _vm.onFileSelected },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-round btn-default mx-10",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.displayRef(
                                              "fileUpload" + _vm.skus[index].id,
                                              _vm.skus[index].id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-file-text-o",
                                          staticStyle: { "font-size": "18px" },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("th", { staticClass: "t7" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-round btn-default mx-10",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(
                                              _vm.skus[index].id,
                                              _vm.skus[index].image
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-trash-o",
                                          staticStyle: { "font-size": "18px" },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-round btn-default mx-10",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.add()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(" Add "),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "modal",
        { attrs: { name: "create-modal", width: 400, height: "auto" } },
        [_c("CreateModal", { on: { submit: _vm.fetchQuestion } })],
        1
      ),
      _c(
        "modal",
        { attrs: { name: "confrim-modal", width: 600, height: "auto" } },
        [
          _c("ConfrimModal", {
            attrs: { idSkus: _vm.RemoveID, image: _vm.RemoveImage },
            on: { sucessRemove: _vm.fetch },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "t1-e", attrs: { scope: "col" } }, [
          _vm._v("Choices"),
        ]),
        _c("th", { staticClass: "t2-e", attrs: { scope: "col" } }),
        _c("th", { staticClass: "t3-e", attrs: { scope: "col" } }),
        _c("th", { staticClass: "t4-e", attrs: { scope: "col" } }),
        _c("th", { staticClass: "t5-e", attrs: { scope: "col" } }, [
          _vm._v("Correct Answer"),
        ]),
        _c("th", { staticClass: "t6-e", attrs: { scope: "col" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "t1", attrs: { scope: "col" } }),
        _c("th", { staticClass: "t2", attrs: { scope: "col" } }, [
          _vm._v("Status"),
        ]),
        _c("th", { staticClass: "t3", attrs: { scope: "col" } }, [
          _vm._v("Label:"),
        ]),
        _c("th", { staticClass: "t4", attrs: { scope: "col" } }, [
          _vm._v(" Description "),
        ]),
        _c("th", { staticClass: "t5", attrs: { scope: "col" } }),
        _c("th", { staticClass: "t6", attrs: { scope: "col" } }),
        _c("th", { staticClass: "t7", attrs: { scope: "col" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
const messages = {
  ER_EMPRT_name: "กรุณาใส่ค่าใน Game Name",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("name") > -1:
      message = messages.ER_EMPRT_name;
      break;
  }
  return message;
};

<template>
  <div class="row">
    <div class="col-md-8">
      <h5 class="px-5 mb-15">Picture</h5>

      <div class="px-15 mb-30">
        <h5 class="light">
          Placeholder
          <span class="small"> (recommended size 628 x 328)</span>
        </h5>
        <div class="d-block">
          <img
            class="img img-fluid image-preview"
            v-if="placeholderImage"
            :src="placeholderImage"
          />
          <div v-else class="img-fluid image-preview"></div>
        </div>
        <input
          type="file"
          accept="image/*"
          class="form-control"
          @change="upload"
          ref="placeholder_url"
          name="placeholder_url"
          :disabled="disabled"
        />
      </div>

      <div class="px-15">
        <h5 class="light">
          Jigsaw
          <span class="small"> (recommended size 628 x 328)</span>
        </h5>
        <div class="d-block">
          <img
            class="img img-fluid image-preview"
            v-if="jigsawImage"
            :src="jigsawImage"
          />
          <div v-else class="img-fluid image-preview"></div>
        </div>
        <input
          type="file"
          accept="image/*"
          class="form-control"
          @change="upload"
          ref="jigsaw_url"
          name="jigsaw_url"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>



<script>
import { weeklyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    placeholderImage() {
      return this.campaign.placeholder_url;
    },

    jigsawImage() {
      return this.campaign.jigsaw_url;
    },

    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async upload(event) {
      this.$root.$emit("weekly::showLoading", true);

      const name = event.target.name;
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();

        const { id } = this.campaign;

        form.set(name, file);

        await weeklyLoginService.uploadCampaignImage(id, form);

        this.$emit("updated");
        this.$notify(`Upload picture success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload picture error.`);
      } finally {
        event.target.files[0].value = "";
        this.$root.$emit("weekly::showLoading", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  width: 420px;
  max-width: 420px;
}
</style>

<template>
  <div class="form-group row">
    <label class="mx-5">
      <h5 class="col-md-12">Post Date</h5>
    </label>
    <label class="col-md-2 form-control-label">Before start date</label>
    <input
      :disabled="disabled"
      min="0"
      max="365"
      step="1"
      type="number"
      class="form-control col-md-1"
      v-model.number="postDate.days"
      @change="submit"
      autocomplete="off"
    />
    <label class="col-md-0 form-control-label">days</label>
    <select
      :disabled="disabled"
      class="form-control col-md-1"
      v-model="postDate.hours"
      @change="submit"
    >
      <option v-for="n in hourNums" :key="n" :value="n">
        {{ getTimeValue(n) }}
      </option>
    </select>
    <label class="col-md-0 form-control-label">hours</label>
    <select
      :disabled="disabled"
      class="form-control col-md-1"
      v-model="postDate.minutes"
      @change="submit"
    >
      <option v-for="n in minuteNums" :key="n" :value="n">
        {{ getTimeValue(n) }}
      </option>
    </select>
    <label class="col-md-0 form-control-label">minutes</label>
    <label class="col-md-3 form-control-label">{{ postDateString }}</label>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { leaderBoardService } from "@/api/client";

export default {
  name: "PostDate",

  props: ["campaign"],

  data() {
    return {
      oldPostDate: null,
      hourNums: _.range(0, 24, 1),
      minuteNums: _.range(0, 60, 1),
      postDate: { days: null, hours: null, minutes: null },
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },

    postDateString() {
      const { post_date } = this.campaign;
      const d = moment(post_date);

      return d.isValid() ? d.format("DD/MM/YYYY, HH:mm") : "n/a";
    },

    formData() {
      const d = moment(this.campaign.start_date);
      d.subtract(this.postDate.days, "days");
      d.subtract(this.postDate.hours, "hours");
      d.subtract(this.postDate.minutes, "minutes");

      return {
        post_date: d.format("YYYY-MM-DD HH:mm:00"),
      };
    },
  },

  watch: {
    "campaign.start_date"(n, o) {
      if (n != o && this.campaign.post_date != null) {
        this.campaign.post_date = moment(this.campaign.start_date)
          .subtract(this.postDate.days, "days")
          .subtract(this.postDate.hours, "hours")
          .subtract(this.postDate.minutes, "minutes");
      }
    },
  },

  methods: {
    getTimeValue(n) {
      return n < 10 ? `0${n}` : n;
    },

    initPostDate() {
      const diffDays = moment(this.campaign.start_date).diff(
        this.campaign.post_date,
        "days"
      );
      const diffHours = moment(this.campaign.start_date).diff(
        this.campaign.post_date,
        "hours"
      );
      const diffMinutes = moment(this.campaign.start_date).diff(
        this.campaign.post_date,
        "minutes"
      );

      this.postDate.days = diffDays;
      this.postDate.hours = diffHours % 24 || 0;
      this.postDate.minutes = diffMinutes % diffHours || 0;

      this.oldPostDate = diffDays;
    },

    async validate() {
      if (+this.postDate.days < 0) {
        await this.$dialogs.alert("Post Date ต้องระบุอย่างน้อย 0 วัน");

        this.postDate.days = this.oldPostDate;
        return false;
      }

      return true;
    },

    async submit() {
      const isValid = await this.validate();

      if (!isValid) return;

      try {
        const { id } = this.campaign;
        const form = this.formData;

        await leaderBoardService.updateCampaign(id, form);

        this.$emit("updated");
        this.$notify(`Post date updated.`);
        this.oldPostDate = this.postDate.days;
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update post date error.`);
      }
    },
  },

  mounted() {
    this.initPostDate();
  },
};
</script>

<style></style>

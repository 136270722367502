<template>
  <div class="col-md-8">
    <div class="ml-10">
      <h4>
        URL
      </h4>
      <table class="table table-responsive table-borderless ml-20">
        <tr>
          <td>Widget link <br />From LINE</td>
          <td>
            <input
              type="text"
              @change="update"
              :disabled="disabled"
              class="form-control"
              v-model="campaign.url_link"
            />
          </td>
        </tr>
        <tr>
          <td>URL link for <br />back button</td>
          <td>
            <input
              type="text"
              @change="update"
              :disabled="disabled"
              class="form-control"
              v-model="campaign.url_back"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { luckyDrawService } from "@/api/client";

export default {
  props: ["campaign"],

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update() {
      try {
        const { id, url_link, url_back } = this.campaign;

        const form = {
          url_link: url_link,
          url_back: url_back,
        };

        await luckyDrawService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table tr td {
  &:nth-child(1) {
    width: 20%;
    text-align: right;
  }
  &:nth-child(2) {
    width: 80%;
  }
}
</style>

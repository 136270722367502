var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _c("div", { staticClass: "panel-body" }, [
          _c("div", { staticClass: "container" }, [
            _c("h4", [_vm._v("Prize")]),
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.detail.id))]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.prize_name))]),
                  _c("td", [_vm._v(_vm._s("-"))]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.prize_lot_id))]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.campaign_type))]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.campaign_id))]),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "table" }, [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("capitalize")(_vm.detail.status))),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.barcode_id))]),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("dateTime")(_vm.detail.issue_date))),
                  ]),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("dateTime")(_vm.detail.deliver_date))),
                  ]),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("dateTime")(_vm.detail.use_date))),
                  ]),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("dateTime")(_vm.detail.expire_date))),
                  ]),
                ]),
              ]),
            ]),
            _c("hr"),
            _c("h4", [_vm._v("User")]),
            _c("table", { staticClass: "table" }, [
              _vm._m(2),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.detail.contact_id))]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.name))]),
                ]),
              ]),
            ]),
            _c("hr"),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center",
              },
              [
                _c("h4", [_vm._v("Contact Info")]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-round",
                    attrs: {
                      id: "btn-deliver",
                      disabled:
                        _vm.detail.status != "claimed" ||
                        _vm.detail.barcode_id != null,
                    },
                    on: { click: _vm.deliver },
                  },
                  [
                    _c("i", {
                      staticClass: "icon mdi mdi-truck-fast mdi-24px",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Deliver "),
                  ]
                ),
              ]
            ),
            _c("table", { staticClass: "table" }, [
              _vm._m(3),
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.detail.firstname || "") +
                            " " +
                            (_vm.detail.lastname || "")
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.detail.telephone))]),
                  _c("td", [_vm._v(_vm._s(_vm.address))]),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "table" }, [
              _vm._m(4),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.detail.id_card_no))]),
                  _c("td", {
                    domProps: { innerHTML: _vm._s(_vm.taxHandling) },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._m(5),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("ID")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Prize Name")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Detail")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Prize Lot ID")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("ID")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Barcode")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Issue Date")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Deliver Date")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Use Date")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Expire Date")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Contact ID")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Contact Name")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", "td-width-30": "" } }, [
          _vm._v("Name"),
        ]),
        _c("th", { attrs: { scope: "col", "td-width-30": "" } }, [
          _vm._v("Tel. No."),
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Address")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", "td-width-60": "" } }, [
          _vm._v("Citizen ID"),
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Tax Handling")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
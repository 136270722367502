<template>
  <div v-if="lot">
    <div class="row">
      <label class="form-control-label">
        <h4>Prize Lot Date</h4>
      </label>
    </div>
    <div class="row">
      <label class="d-inline mx-10 form-control-label">
        Issue
      </label>
      <div class="d-inline mx-10">
        <DatePicker
          width-200px
          type="datetime"
          :confirm="true"
          @change="update('issue_date')"
          placeholder="n/a"
          ref="issue_date"
          format="DD/MM/YYYY HH:mm"
          value-type="YYYY-MM-DD HH:mm"
          :disabled="disabled"
          v-model="lot.issue_date"
        />
      </div>
      <label class="d-inline mx-10 form-control-label">
        Expire
      </label>
      <div class="d-inline mx-10">
        <DatePicker
          width-200px
          type="datetime"
          :confirm="true"
          @change="update('expire_date')"
          placeholder="n/a"
          ref="expire_date"
          format="DD/MM/YYYY HH:mm"
          value-type="YYYY-MM-DD HH:mm"
          :disabled="disabled"
          v-model="lot.expire_date"
        />
      <label class="d-inline mx-10 form-control-label">
        (Expire date here will override Prize settings.)
      </label>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { prizeService } from "@/api/client";

export default {
  props: ["lot"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
      issue_date: null,
      expire_date: null,
    };
  },

  computed: {
    disabled() {
      return this.lot.status != "draft";
    },
  },

  mounted() {
    const { issue_date, expire_date } = this.lot;
    this.oldValue = { issue_date, expire_date };
  },

  methods: {
    async isFormValid(field) {
      switch (field) {
        case "issue_date":
          // ถ้าไม่ระบุวันที่ issue date
          if (!this.lot.issue_date) {
            await this.$dialogs.alert("กรุณาระบุค่า Issue date");

            this.$refs.issue_date.focus();

            const { issue_date } = this.oldValue;
            this.lot.issue_date = issue_date;

            return false;
          }

          // https://stackoverflow.com/a/5646753
          if (
            moment(
              new Date(this.lot.issue_date.replace(/-/g, "/")).setSeconds(0, 0)
            ).diff(new Date().setSeconds(0, 0), "minutes") < 1
          ) {
            await this.$dialogs.alert(
              "Issue date ใส่ค่าได้ตั้งแต่วันที่ในอนาคตเท่านั้น"
            );

            this.$refs.issue_date.focus();

            const { issue_date } = this.oldValue;
            this.lot.issue_date = issue_date;

            return false;
          }

          // ถ้าไม่ระบุวันที่ issue date มากกว่าวันที่ expire date
          if (
            this.lot.expire_date != null &&
            moment(this.lot.issue_date).diff(
              new Date(this.lot.expire_date.replace(/-/g, "/")),
              "minutes"
            ) >= 0
          ) {
            await this.$dialogs.alert(
              "Issue Date ต้องน้อยกว่า Expire Date lot"
            );

            const { issue_date } = this.oldValue;

            this.lot.issue_date = issue_date;

            return false;
          }

          return true;

        case "expire_date":
          // ถ้าไม่ระบุวันที่ expire date
          if (this.oldValue.expire_date && !this.lot.expire_date) {
            await this.$dialogs.alert("ต้องระบุค่า Expire date");

            const { expire_date } = this.oldValue;
            this.lot.expire_date = expire_date;

            return false;
          }

          // ถ้าไม่ระบุวันที่ issue date มากกว่าวันที่ expire date
          if (
            moment(this.lot.issue_date).diff(
              new Date(this.lot.expire_date.replace(/-/g, "/")),
              "minutes"
            ) >= 0
          ) {
            await this.$dialogs.alert("Expire Date ต้องมากกว่า Issue Date");

            const { expire_date } = this.oldValue;

            this.lot.expire_date = expire_date;

            return false;
          }

          return true;
      }
    },

    async update(field) {
      try {
        const formValid = await this.isFormValid(field);
        if (!formValid) return;

        const { id } = this.lot;

        const form = {};
        form[field] = this.lot[field];

        await prizeService.updateLot(id, form);

        this.oldValue[field] = this.lot[field];

        this.$emit("updated");
        this.$notify(`Prize Lot Date updated.`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Prize Lot Date update error.`);
      }
    },
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c("div", { staticClass: "flex-fit-content" }, [
        _c(
          "div",
          { staticClass: "form-group-row" },
          [
            _c("label", { staticClass: "mb-0" }, [_vm._v("Text box 2")]),
            _vm._l(_vm.choices, function (ref) {
              var id = ref.id
              var value = ref.value
              var text = ref.text
              return _c(
                "div",
                { key: id, staticClass: "radio-custom radio-primary mx-10" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.stamp.text2_choice,
                        expression: "stamp.text2_choice",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "text2_choice",
                      disabled: _vm.disabled,
                      id: "text2_choice_" + id,
                    },
                    domProps: {
                      value: value,
                      checked: _vm._q(_vm.stamp.text2_choice, _vm._n(value)),
                    },
                    on: {
                      change: [
                        function ($event) {
                          _vm.$set(_vm.stamp, "text2_choice", _vm._n(value))
                        },
                        _vm.update,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "text2_choice_" + id } }, [
                    _vm._v(_vm._s(text)),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
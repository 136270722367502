<template>
  <div class="page" v-if="admin">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Admin User</h1>
          <breadcrumb
            name="ADMIN_EDIT"
            :withLast.sync="admin.username"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <div class="panel-title">
                  <h4>Admin User Information - {{ admin.id }}</h4>
                </div>
              </div>
              <div class="panel-body">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>Username</h5>
                      <button class="btn btn-primary" type="button">
                        {{ admin.username }}
                      </button>
                    </div>
                    <div class="col-md-3">
                      <h5>Status</h5>
                      <button
                        class="btn btn-round"
                        :class="{
                          'btn-default': +admin.active === 0,
                          'btn-primary': +admin.active === 1,
                        }"
                        type="button"
                        @click="toggleActive"
                      >
                        <i
                          class="icon"
                          :class="{
                            'md-block': +admin.active === 0,
                            'md-check': +admin.active === 1,
                          }"
                          aria-hidden="true"
                        ></i>
                        {{ +admin.active === 1 ? "ACTIVE" : "INACTIVE" }}
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-9">
                      <h5>Description</h5>
                      <textarea
                        class="form-control"
                        rows="3"
                        maxlength="80"
                        @change="update"
                        v-model="admin.description"
                        placeholder="Name or description"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-9">
                      <h5>Role</h5>
                      <select
                        class="form-control"
                        v-model="admin.role"
                        @change="update"
                      >
                        <option
                          v-for="role in ['admin', 'report', 'supervisor']"
                          :key="role"
                          :value="role"
                        >
                          {{ role }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-9">
                      <h5>Password</h5>
                      <div v-if="!formResetActive" class="form-reset-password">
                        <p class="mb-0">Click to reset user password</p>
                        <button
                          class="btn btn-primary btn-round"
                          type="button"
                          @click="formResetActive = true"
                        >
                          Reset
                        </button>
                      </div>

                      <div
                        v-else
                        class="form-reset-password"
                        :class="{ active: formResetActive }"
                      >
                        <p>Enter new password (8 characters)</p>
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="text"
                            maxlength="8"
                            minlength="8"
                            v-model="password"
                          />
                          <button
                            class="btn btn-primary"
                            type="button"
                            @click="changePassword"
                            :disabled="!readyUpdatePassword"
                          >
                            Confirm
                          </button>
                          <button
                            class="btn btn-default"
                            type="button"
                            @click="dismissFormResetPassword"
                          >
                            Cancel
                          </button>
                        </div>
                        <button
                          class="btn btn-link"
                          type="button"
                          @click="autoGeneratePassword"
                        >
                          Click to auto generate
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminService } from "@/api/client";

export default {
  name: "LuckyDrawEdit",

  data() {
    return {
      admin: null,
      password: "",
      formResetActive: false,
    };
  },

  computed: {
    readyUpdatePassword() {
      return !!this.password && this.formResetActive === true;
    },
  },

  methods: {
    goBack() {
      this.$router.push({ name: "AdminList" });
    },

    async getAdminDetail() {
      const id = this.$route.params.id;
      this.admin = await adminService.getById(id);
    },

    async update() {
      try {
        const id = this.$route.params.id;
        await adminService.updateAdmin(id, this.admin);
        this.$notify("Update success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message);
      }
    },

    async toggleActive() {
      try {
        const id = this.$route.params.id;
        const active = this.admin.active === 0 ? 1 : 0;

        await adminService.updateAdmin(id, {
          active,
        });

        this.admin.active = active;

        this.$notify("Change status success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message);
      }
    },

    makeid(length) {
      // https://stackoverflow.com/a/1349426
      var result = "";
      var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    autoGeneratePassword() {
      this.password = this.makeid(8);
    },

    async changePassword() {
      try {
        const id = this.$route.params.id;
        const password = this.password;

        if (!password || password.length < 8) {
          await this.$dialogs.alert("กรุณาระบุอย่างน้อย 8 character");
          return;
        }

        if (password.length > 8) {
          await this.$dialogs.alert(
            "กรุณาระบุ Password ที่จำนวนไม่เกิน 8 character"
          );
          return;
        }

        const options = { cancelLabel: "No", okLabel: "Yes", size: "sm" };

        const { ok } = await this.$dialogs.confirm(
          "Do you want to use the new password ?",
          options
        );

        if (!ok) return;

        await adminService.updateAdmin(id, { password });
        this.$notify("Reset password success.");

        this.dismissFormResetPassword();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message);
      }
    },

    dismissFormResetPassword() {
      this.formResetActive = false;
      this.password = "";
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.readyUpdatePassword) return next();

    try {
      const options = { cancelLabel: "No", okLabel: "Exit" };

      const { ok } = await this.$dialogs.confirm(
        "You haven’t confirmed new password!  Continue to exit this page ?",
        options
      );

      if (ok) {
        next();
      }
    } catch (error) {
      next(false);
    }
  },

  async created() {
    await this.getAdminDetail();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

form .row {
  margin-bottom: 2em;
}

.form-reset-password {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: 16px;

  &.active {
    display: inherit;

    .form-group {
      gap: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Prize Lot</h1>
      <breadcrumb name="PRIZE_SUMMARY_LOT_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="lots"
              :columns="columns"
              @on-row-click="onRowClick"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'status'">
                  {{ props.row.status | toUpperCase }}
                </div>
                <div v-else-if="props.column.field == 'expire_date'">
                  {{
                    props.row.expire_date == null
                      ? "n/a"
                      : props.row.expire_date
                  }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="450" height="auto">
      <CreateModal @submit="fetchLot" />
    </modal>
  </div>
</template>

<script>
import { prizeService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import CreateModal from "@/views/prize/lot/CreateModal.vue";
import { tableColumns } from "@/views/prize/lot/model";

export default {
  name: "PrizeLotTable",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      lots: [],
      hideArchive: true,
      columns: tableColumns,
    };
  },

  components: { CreateModal },

  created() {
    this.fetchLot();
  },

  watch: {
    hideArchive() {
      this.filter();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    onRowClick(event) {
      this.$router
        .push({
          name: "prize-lot-edit",
          params: { id: event.row.id },
        })
        .catch((err) => {});
    },

    async fetchLot(show_all) {
      this.lots = await prizeService.getLotList({
        show_all: show_all,
      });
    },

    filter() {
      this.hideArchive ? this.fetchLot(0) : this.fetchLot(1);
    },

    async reloadTable() {
      await this.fetchLot();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-modal`);
    },
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>

<template>
  <div class="col-md-12" v-if="monthlyshort">
    <label class="mx-5">
      <h4 class="mb-0">Text area</h4>
    </label>

    <div class="ml-10">
      <h5>Text Group 1: Free text</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td colspan="5">
            <input
              type="text"
              class="form-control"
              name="text1_content"
              width-540px
              @change="update($event)"
              v-model="monthlyshort.text1_content"
              :disabled="disabled"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      monthlyshort: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update(event, type) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        const form = {};
        form[name] = this.monthlyshort[name];

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await progressBarService.updateCampaignInfo(id, { monthlyshort: form });

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
  },

  mounted() {
    this.monthlyshort = this.campaign.monthlyshort;
  },
};
</script>

<style lang="scss" scoped>
input[type="text"],
input[type="number"] {
  &:disabled {
    background-color: #efefef;
  }
}

input[type="text"] {
  max-width: 120px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _vm.display
      ? _c("ul", { staticClass: "list-icons mb-0" }, [
          _c("li", { class: { "text-success": _vm.$v.username.required } }, [
            !_vm.$v.username.required
              ? _c("i", {
                  staticClass: "wb-close",
                  attrs: { "aria-hidden": "true" },
                })
              : _c("i", {
                  staticClass: "wb-check",
                  attrs: { "aria-hidden": "true" },
                }),
            _vm._v(" จำเป็นต้องระบุ Username "),
          ]),
          _c(
            "li",
            {
              class: {
                "text-success":
                  _vm.$v.username.required && _vm.$v.username.minLength,
              },
            },
            [
              !_vm.$v.username.required || !_vm.$v.username.minLength
                ? _c("i", {
                    staticClass: "wb-close",
                    attrs: { "aria-hidden": "true" },
                  })
                : _c("i", {
                    staticClass: "wb-check",
                    attrs: { "aria-hidden": "true" },
                  }),
              _vm._v(
                " ต้องระบุอย่างน้อย " +
                  _vm._s(_vm.$v.username.$params.minLength.min) +
                  " ตัวอักษร "
              ),
            ]
          ),
          _c("li", { class: { "text-success": _vm.$v.username.maxLength } }, [
            !_vm.$v.username.maxLength
              ? _c("i", {
                  staticClass: "wb-close",
                  attrs: { "aria-hidden": "true" },
                })
              : _c("i", {
                  staticClass: "wb-check",
                  attrs: { "aria-hidden": "true" },
                }),
            _vm._v(
              " ต้องระบุไม่เกิน " +
                _vm._s(_vm.$v.username.$params.maxLength.max) +
                " ตัวอักษร "
            ),
          ]),
          _c("li", { class: { "text-success": _vm.$v.username.password } }, [
            !_vm.$v.username.password
              ? _c("i", {
                  staticClass: "wb-close",
                  attrs: { "aria-hidden": "true" },
                })
              : _c("i", {
                  staticClass: "wb-check",
                  attrs: { "aria-hidden": "true" },
                }),
            _vm._v(
              " สามารถใส่ อักขระ a-z ตัวเลข 0-9 หรือ . หรือ _ ได้เท่านั้น "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
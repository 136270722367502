export const engagementTableColumns = [
  {
    label: "Campaign ID",
    field: "id",
    type: "number",
  },
  {
    label: "Campaign Name",
    field: "name",
  },
  {
    label: "Start Date",
    field: "start_date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End Date",
    field: "end_date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "No. Round",
    field: "no_round",
    type: "number",
  },
  {
    label: "Status",
    field: "status",
    type: "string",
  },
  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "Modifield By",
    field: "modified_by_username",
  },
];
// timestamp, accept_term_condition_str, branch_code, employee_number
export const participationTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
  },
  {
    label: "Accept Condition",
    field: "accept_term_condition_str",
  },
  {
    label: "Employee ID",
    field: "employee_number",
    type: "number",
  },
  {
    label: "Branch ID",
    field: "branch_code",
    type: "number",
  },
];

export const transactionLogTableColumns = [
  {
    label: "Record ID",
    field: "id",
  },
  {
    label: "Employee ID",
    field: "employee_number",
  },
  {
    label: "Branch ID",
    field: "branch_code",
  },
  {
    label: "Cam ID",
    field: "campaign_id",
    type: "number",
  },
  {
    label: "Round No.",
    field: "display_round_id",
    type: "number",
  },
  {
    label: "TS Start",
    field: "start_timestamp",
  },
  {
    label: "TS Submit",
    field: "submit_timestamp",
  },
  {
    label: "Score",
    field: "score",
    type: "number",
  },
  {
    label: "Rank",
    field: "number",
    type: "number",
  },
  {
    label: "Reward",
    field: "reward_name",
  },
];

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Quiz Step-{{ quizData.step }}</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal row">
            <div class="col-6">
              <div class="form-group row">
                <label class="col-md-0 form-control-label form__label">
                  <h5>Question</h5>
                </label>
                <div class="col-md-12">
                  <textarea
                    rows="4"
                    ref="question"
                    autocomplete="off"
                    v-model="question"
                    @change="updateQuestion"
                    @blur="onQuestionInputBlur"
                    @focus="onQuestionInputFocus"
                    class="form-control form__input"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table class="table table-responsive table-borderless">
                    <thead>
                      <tr>
                        <th>Choice</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(choice, idx) in choices"
                        :key="idx"
                        class="choice"
                      >
                        <td>
                          <label class="form-control-label form__label">
                            <h5>{{ idx + 1 }}</h5>
                          </label>
                        </td>
                        <td>
                          <div class="form-group">
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                :value="choice"
                                class="form-control"
                                :data-choice-id="idx + 1"
                                :ref="`choice${idx + 1}`"
                                @blur="updateChoice"
                              />
                              <div class="input-group-append">
                                <button
                                  class="btn"
                                  type="button"
                                  @click="removeChoice(idx)"
                                  :disabled="
                                    choices.length <= 1 ||
                                    +correct_choice === idx + 1
                                  "
                                >
                                  <i
                                    class="icon md-delete"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-round w-80"
                            @click="addNewChoice"
                            :disabled="choices.length === 4"
                          >
                            +
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"><hr /></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label class="form-control-label form__label">
                            <h5>Correct Answer</h5>
                          </label>
                          <select
                            class="form-control correct-choice"
                            v-model="correct_choice"
                            @change="onCorrectChoiceChange"
                          >
                            <option
                              v-for="(choice, idx) in choices"
                              :key="idx"
                              :value="idx + 1"
                            >
                              {{ idx + 1 }}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="radio-custom radio-primary">
                  <input
                    type="radio"
                    value="none"
                    v-model="image_video"
                    id="question_media_none"
                    @change="updateImageVideoType"
                  />
                  <label for="question_media_none">None</label>
                </div>
              </div>
              <div class="form-group">
                <div class="radio-custom radio-primary pb-15">
                  <input
                    type="radio"
                    value="image"
                    v-model="image_video"
                    id="question_media_image"
                    @change="updateImageVideoType"
                  />
                  <label for="question_media_image">
                    Image -
                    <span>
                      Recommended size 335x189 px / less than 1 MB
                    </span>
                  </label>
                </div>
                <div class="pl-30">
                  <div class="img-url-wrapper">
                    <img :src="image_url" class="img-fluid" />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    @change="uploadImage"
                    :disabled="image_video != 'image'"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="radio-custom radio-primary pb-15">
                  <input
                    type="radio"
                    value="video"
                    v-model="image_video"
                    id="question_media_video"
                    @change="updateImageVideoType"
                  />
                  <label for="question_media_video">Video</label>
                </div>
                <div class="pl-30">
                  <input
                    type="text"
                    ref="video_url"
                    class="form-control"
                    v-model="video_url"
                    @change="updateVideoURL"
                    :disabled="image_video != 'video'"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="close"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dailyloginService } from "@/api/client";

export default {
  props: ["campaginData", "quizData"],
  data() {
    return {
      step: null,
      image_url: null,
      video_url: null,
      image_video: "none",

      correct_choice: 1,
      choices: ["Add your answer here"],

      question: "",
      isUndefinedQuestion: true,
      isUndefinedQuestionActivePopup: false,

      oldValues: null,
    };
  },

  mounted() {
    this.oldValues = this.quizData;

    this.step = this.quizData.step;

    if (this.quizData.question) {
      this.isUndefinedQuestion = false;
      this.question = this.quizData.question;
    } else {
      this.isUndefinedQuestion = true;
      this.question = "Add your question here";
    }

    if (this.quizData.choice1) {
      this.choices[0] = this.quizData.choice1;
    }
    if (this.quizData.choice2) {
      this.choices[1]
        ? this.quizData.choice2
        : this.choices.push(this.quizData.choice2);
    }
    if (this.quizData.choice3) {
      this.choices[2]
        ? this.quizData.choice3
        : this.choices.push(this.quizData.choice3);
    }
    if (this.quizData.choice4) {
      this.choices[3]
        ? this.quizData.choice4
        : this.choices.push(this.quizData.choice4);
    }

    this.correct_choice = this.quizData.correct_choice || 1;

    this.image_video = this.quizData.image_video;
    this.image_url = this.quizData.image_url;
    this.video_url = this.quizData.video_url;
  },

  methods: {
    async addNewChoice() {
      this.choices.push(`Add your answer here`);

      await this.updateMultipleChoice();
    },

    async removeChoice(idx) {
      if (idx + 1 === +this.correct_choice) return;

      if (this.choices.length <= 1) return;

      if (this.correct_choice > idx + 1) {
        this.correct_choice--;
      }

      this.choices.splice(idx, 1);

      await this.updateMultipleChoice();
    },

    async updateMultipleChoice() {
      try {
        const campaignId = this.campaginData.id;

        const form = {
          correct_choice: this.correct_choice,
          choice1: this.choices[0] === undefined ? null : this.choices[0],
          choice2: this.choices[1] === undefined ? null : this.choices[1],
          choice3: this.choices[2] === undefined ? null : this.choices[2],
          choice4: this.choices[3] === undefined ? null : this.choices[3],
        };

        await dailyloginService.editQuizByStep(campaignId, this.step, form);

        this.oldValues.choice1 = this.choices[0];
        this.oldValues.choice2 = this.choices[1];
        this.oldValues.choice3 = this.choices[2];
        this.oldValues.choice4 = this.choices[3];
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Update Choice error.`);
      }
    },

    async updateChoice(event) {
      const idx = event.target.getAttribute("data-choice-id");

      try {
        const value = event.target.value;
        const oldValue = this.oldValues[`choice${idx}`];

        if (value == oldValue) return;

        // if (!value && this.correct_choice == +idx) {
        if (!value) {
          this.$dialogs.alert("Cannot set choice to empty value.").then(() => {
            this.$refs["choice" + idx][0].focus();
            this.$refs["choice" + idx][0].value = oldValue;
          });

          return;
        }

        this.choices[idx - 1] = value;
        this.oldValues[`choice${idx}`] = value;
        await this.updateMultipleChoice();
        this.notifySuccess(`Choice ${idx}`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Update Answer ${idx} error.`);
      }
    },

    async updateCorrectChoice() {
      try {
        const campaignId = this.campaginData.id;
        const form = { correct_choice: this.correct_choice };
        await dailyloginService.editQuizByStep(campaignId, this.step, form);
        this.oldValues.correct_choice = this.correct_choice;
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Update Correct choice error.`);
      }
    },

    async updateQuestion() {
      try {
        if (!this.validateQuestion()) return;

        const campaignId = this.campaginData.id;
        const form = { question: this.question };
        await dailyloginService.editQuizByStep(campaignId, this.step, form);
        this.oldValues.question = this.question;
        this.isUndefinedQuestion = false;
        this.notifySuccess(`question`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Update Campaign Quiz error.`);
      }
    },

    async updateVideoURL() {
      try {
        if (!this.validateVideoURL()) return;

        const campaignId = this.campaginData.id;
        const form = { video_url: this.video_url };
        await dailyloginService.editQuizByStep(campaignId, this.step, form);
        this.oldValues.video_url = this.video_url;
        this.notifySuccess(`Video URL`);
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(`Update Video URL error.`);
      }
    },

    async updateImageVideoType() {
      const campaignId = this.campaginData.id;
      const form = { image_video: this.image_video };
      await dailyloginService.editQuizByStep(campaignId, this.step, form);
      this.oldValues.image_video = this.image_video;
      this.$notify("Quiz updated.");
    },

    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File is too big");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("image", file);

        const campaignId = this.campaginData.id;

        await dailyloginService.uploadQuizImage(campaignId, this.step, form);
        await this.fetchQuiz();
        this.notifySuccess(`Quiz image`);
      } catch (error) {
        console.error(error);
        this.$notify("Upload Quiz image error");
      }
    },

    notifySuccess(field) {
      this.$notify(
        `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
          /_/gi,
          " "
        ) + " updated."
      );
    },

    close() {
      if (!this.validate()) return;
      this.$modal.hide("set-question");
    },

    validate() {
      if (!this.validateQuestion()) return false;
      if (!this.validateChoices()) return false;
      if (!this.validateImageVideoType()) return false;

      return true;
    },

    validateQuestion() {
      if (!this.question) {
        this.$dialogs.alert(`Question is required.`).then(() => {
          this.question = this.oldValues.question;
          this.$refs.question.focus();
        });
        return false;
      }

      return true;
    },

    validateChoices() {
      const idx = this.correct_choice;

      if (this.choices[idx - 1] == ``) {
        this.$dialogs.alert("Cannot set choice to empty value.").then(() => {
          this.$refs["choice" + idx][0].focus();
          this.$refs["choice" + idx][0].value = oldValue;
        });

        return false;
      }

      return true;
    },

    validateVideoURL() {
      if (!this.video_url) {
        this.$dialogs.alert(`Video URL is required.`).then(() => {
          this.video_url = this.oldValues.video_url;
          this.$refs.video_url.focus();
        });
        return false;
      }

      return true;
    },

    validateImageVideoType() {
      if (this.image_video == "image" && !this.image_url) {
        this.$dialogs.alert(`Image url is required.`);
        return false;
      }

      if (this.image_video == "video" && !this.video_url) {
        this.$dialogs.alert(`Video url is required.`);
        return false;
      }

      return true;
    },

    async fetchQuiz() {
      const campaignId = this.campaginData.id;
      const { image_url } = await dailyloginService.getQuizByStep(
        campaignId,
        this.step
      );

      this.image_url = image_url;
      this.oldValues.image_url = image_url;
    },

    async onCorrectChoiceChange() {
      await this.updateCorrectChoice();
      await this.notifySuccess(`Correct choice`);
    },

    onQuestionInputBlur() {
      if (
        !this.question &&
        this.isUndefinedQuestion &&
        !this.isUndefinedQuestionActivePopup
      ) {
        this.isUndefinedQuestionActivePopup = true;
        this.$dialogs.alert(`Question is required.`).then(() => {
          this.isUndefinedQuestionActivePopup = false;
          this.$refs.question.focus();
        });
      }
    },

    onQuestionInputFocus() {
      if (this.isUndefinedQuestion) {
        this.question = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr.choice {
  td {
    .form-group {
      margin-bottom: 0px;
    }

    &:nth-child(2) {
      width: 350px;
    }
  }
}

.correct-choice {
  max-width: 100px;
  display: inline-block;
}

.img-url-wrapper {
  background: #ccc;
  border: 1px solid #e4eaec;
  img {
    display: block;
    margin: 0 auto;
    max-height: 120px;
  }
}
</style>

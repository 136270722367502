var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isNonPanelUser
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "radio-input-row radio-custom radio-primary" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reward.reward_type,
                    expression: "reward.reward_type",
                  },
                ],
                attrs: {
                  type: "radio",
                  value: "sfdc",
                  name: "reward_type_" + _vm.index,
                  id: "reward_type_sfdc_" + _vm.index,
                  disabled: _vm.disabled,
                },
                domProps: { checked: _vm._q(_vm.reward.reward_type, "sfdc") },
                on: {
                  change: [
                    function ($event) {
                      return _vm.$set(_vm.reward, "reward_type", "sfdc")
                    },
                    function ($event) {
                      return _vm.update($event, "reward_type")
                    },
                  ],
                },
              }),
              _c("label", { attrs: { for: "reward_type_sfdc_" + _vm.index } }, [
                _vm._v("SF Point"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reward.sfdc_point,
                expression: "reward.sfdc_point",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: {
              min: "1",
              max: "999999",
              type: "number",
              name: "sfdc_point",
              disabled: _vm.disabled || _vm.reward.reward_type !== "sfdc",
            },
            domProps: { value: _vm.reward.sfdc_point },
            on: {
              change: _vm.update,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.reward, "sfdc_point", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
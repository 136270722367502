var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("h4", { staticClass: "col-md-12" }, [
                _vm._v(_vm._s(_vm.campaign.name)),
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c("p", [
                  _vm._v(
                    " SFDC points will be deducted from the following Contact IDs!! "
                  ),
                ]),
                _c("p", { staticClass: "red" }, [
                  _vm._v(
                    " Note: this operation may fail. Please check transaction log afterward. "
                  ),
                ]),
                _vm.revokedList.length > 0
                  ? _c("div", { staticClass: "mx-10" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.revokedList, function (id) {
                          return _c("li", { key: id }, [
                            _vm._v(" " + _vm._s(id) + " "),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _c("div", { staticClass: "mx-10" }, [
                      _c("p", [_vm._v("-")]),
                    ]),
                _c("p", [
                  _vm._v(" A total of "),
                  _c("span", { staticClass: "blue-600" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("numberWithCommas")(_vm.result.granted_point)
                        ) +
                        " "
                    ),
                  ]),
                  _vm._v(
                    " SFDC points will be granted to users after this operation. "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-close",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.revokedList.length > 0 ? "Cancel" : "Close") +
                    " "
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.confirm },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Confirm "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Update confirmation")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="col-md-6" v-if="campaign">
    <div class="form-group">
      <div my-15px>
        <input
          type="radio"
          value="image"
          :disabled="!canEdit"
          name="picture_or_video"
          id="picture_or_video_image"
          @change="updateImageVideoType"
          v-model="campaign.picture_or_video"
        />

        <label for="picture_or_video_image" class="mx-5">
          Picture - <span>Recommended size 460x224 pixels / less than 1 MB</span>
        </label>

        <div class="form-group">
          <div class="d-block">
            <img
              class="img img-fluid"
              v-if="campaign.picture_url"
              :src="campaign.picture_url"
              :disabled="campaign.picture_or_video != 'image'"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="uploadImage"
            ref="picture_url_upload"
            :disabled="campaign.picture_or_video != 'image' || !canEdit"
          />
        </div>
      </div>

      <div my-15px>
        <input
          type="radio"
          value="video"
          :disabled="!canEdit"
          name="picture_or_video"
          id="picture_or_video_video"
          @change="updateImageVideoType"
          v-model="campaign.picture_or_video"
        />

        <label for="picture_or_video_video" class="mx-5">
          VDO URL
        </label>

        <div class="form-group">
          <div>
            <input
              type="text"
              width-400px
              ref="video_url"
              maxlength="255"
              class="form-control"
              @change="updateVideoURL"
              v-model="campaign.video_url"
              :disabled="campaign.picture_or_video != 'video' || !canEdit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { luckyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },
  },

  methods: {
    validateVideoURL() {
      if (!this.item.video_url) {
        this.$dialogs.alert(`Video URL is required.`).then(() => {
          this.$refs.video_url.focus();
        });
        return false;
      }

      return true;
    },

    async updateVideoURL() {
      try {
        const { id, video_url } = this.campaign;
        const form = { video_url: video_url };

        await luckyLoginService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`Video URL`);
      } catch (error) {
        this.$dialogs.alert(`Update Video URL error.`);
      }
    },

    async updateImageVideoType() {
      const { id, picture_or_video } = this.campaign;
      const form = { picture_or_video: picture_or_video };

      await luckyLoginService.updateCampaignInfo(id, form);
      this.$notify("Task updated.");
    },

    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File is too big");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("image", file);

        const { id } = this.campaign;

        await luckyLoginService.uploadCampaignImage(id, form);

        this.$emit("updated");
        this.$notify(`Campaign picture updated.`);
      } catch (error) {
        this.$notify("Upload campaign picture error");
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Bulk Operation</h1>
      <breadcrumb name="POINT_BULK_OPERATION"></breadcrumb>
      <div class="page-header-right">
        <button
          type="button"
          @click="addTask"
          class="btn btn-icon btn-primary btn-round mx-10"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Task
        </button>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'id', type: 'desc' },
              }"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.label == 'Action'">
                  <button
                    class="btn btn-round btn-upload mx-3"
                    :disabled="!canUpload(props.row)"
                  >
                    <label :for="'file-upload-task-' + props.row.id">
                      Upload
                    </label>
                  </button>

                  <input
                    type="file"
                    @change="onFileSelected"
                    class="input-select-file"
                    :data-task-id="props.row.id"
                    :disabled="!canUpload(props.row)"
                    :id="'file-upload-task-' + props.row.id"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />

                  <button
                    @click="executeTask(props.row.id)"
                    :disabled="!canExecute(props.row)"
                    class="btn btn-round btn-primary mx-3"
                  >
                    Execute
                  </button>

                  <button
                    @click="download(props.row.id)"
                    :disabled="!canDownload(props.row)"
                    class="btn btn-default btn-round mx-3"
                  >
                    Download
                  </button>

                  <button
                    @click="deleteTask(props.row.id)"
                    :disabled="!canDelete(props.row)"
                    class="btn btn-round btn-default mx-3"
                  >
                    Delete
                  </button>
                </div>
                <div v-else-if="props.column.field == 'name'">
                  <input
                    type="text"
                    :value="props.row.name"
                    data-task-key="name"
                    :data-task-id="props.row.id"
                    @focus="tempTask = props.row"
                    @blur="updateTask"
                  />
                </div>
                <div v-else-if="props.column.field == 'status'">
                  {{ props.row.status | toUpperCase }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletService } from "@/api/client";
import { bulkOperationTableColumns } from "@/views/point/model";

export default {
  name: "BulkOperation",

  data() {
    return {
      list: [],
      file: null,
      tempTask: null,
      columns: bulkOperationTableColumns,
    };
  },

  async created() {
    await this.fetchAllTask();
  },

  methods: {
    canUpload(task) {
      return (
        task.status == "empty" ||
        task.status == "loading" ||
        task.status == "ready"
      );
    },

    canExecute(task) {
      return task.status == "ready";
    },

    canDownload(task) {
      return task.status == "ready" || task.status == "done";
    },

    canDelete(task) {
      return (
        task.status == "empty" ||
        task.status == "loading" ||
        task.status == "ready" ||
        task.status == "done"
      );
    },

    async updateTask(event) {
      try {
        const value = event.target.value;
        const id = event.target.getAttribute("data-task-id");
        const key = event.target.getAttribute("data-task-key");

        if (this.tempTask && this.tempTask[key] == value) {
          return;
        }

        const form = {};
        form[key] = value;
        await walletService.updateTask(id, form);
        await this.fetchAllTask();
        this.tempTask = null;
        this.$notify("Change name success");
      } catch (error) {
        console.error(error);
      }
    },

    onFileSelected(event) {
      const id = event.target.getAttribute("data-task-id");
      this.file = event.target.files[0];
      event.target.value = null;
      this.upload(id);
    },

    async fetchAllTask() {
      const tasks = await walletService.getAllBulkOperationTask();
      this.list = tasks;
    },

    async addTask() {
      try {
        const data = await walletService.addNewTask({ name: "New Task" });
        await this.fetchAllTask();
      } catch (error) {
        console.error(error);
      }
    },

    async upload(id) {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("id", id);
        form.append("file", this.file);

        await walletService.bulkOperationUpload(form);
        await this.fetchAllTask();
      } catch (error) {
        console.error(error);
      }
    },

    async download(id) {
      try {
        const response = await walletService.downloadTask(id);

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Bulk_Operation_${id}_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    async executeTask(id) {
      try {
        await walletService.bulkOperationExecute(id);
        await this.fetchAllTask();
      } catch (error) {
        console.error(error);
      }
    },

    async deleteTask(id) {
      try {
        await walletService.deleteTask(id);
        await this.fetchAllTask();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}

button > label {
  margin: 0px;
  cursor: pointer;
}

.input-select-file {
  width: 0px;
  visibility: hidden;
}
</style>

<style lang="scss">
.vgt-left-align.task-name {
  padding: 0px;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;

    input {
      padding: 0px;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      height: 60px;
      padding: 1rem;
      background: transparent;
    }
  }
}
</style>

<template>
  <div class="col-12">
    <table class="table">
      <tbody>
        <tr>
          <td width-150px>End of Campaign Notification</td>
          <td>
            <div class="col-md-8">
              <label class="switch">
                <input
                  type="checkbox"
                  :true-value="1"
                  :false-value="0"
                  @change="update"
                  :disabled="!canSwitchOnOff"
                  v-model="lot.end_notification"
                />
                <span class="slider round"></span>
              </label>
              <p class="mx-10 d-inline">
                {{ +lot.end_notification === 1 ? "ON" : "OFF" }}
              </p>
            </div>
          </td>
          <td>
            <div class="form-group">
              <label class="form-control-label px-0">End targets</label>
              <div class="d-flex">
                <div class="radio-custom radio-primary">
                  <input
                    type="radio"
                    name="end_target"
                    value="participants"
                    v-model="lot.end_target"
                    @change="updateEndTarget"
                    id="end_targets_participants"
                    :disabled="!canSwitchOnOff || +lot.end_notification !== 1"
                  />
                  <label for="end_targets_participants">Participants</label>
                </div>
                <div class="radio-custom radio-primary mx-10">
                  <input
                    type="radio"
                    name="end_target"
                    value="all"
                    v-model="lot.end_target"
                    @change="updateEndTarget"
                    id="end_targets_all_users"
                    :disabled="!canSwitchOnOff || +lot.end_notification !== 1"
                  />
                  <label for="end_targets_all_users">All users</label>
                </div>
              </div>
            </div>

            <label for="lot_end_image" class="mx-5">
              Rich Message
              <span>(Recommended size 670x434 pixels / less than 1 MB)</span>
            </label>

            <div class="form-group">
              <div class="d-block">
                <img
                  class="img img-fluid"
                  v-if="lot.end_image"
                  :src="lot.end_image"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                class="form-control"
                @change="upload"
                :disabled="+lot.end_notification !== 1"
              />
            </div>

            <div class="form-group">
              <button
                type="button"
                @click="clearImage"
                class="btn btn-link"
                :disabled="!lot.end_image"
              >
                <i aria-hidden="true" class="icon md-close"></i>
                Clear image
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { lotService } from "@/api/client";

export default {
  name: "EndCampaignNotificationSettings",

  props: ["lot"],

  computed: {
    canSwitchOnOff() {
      return ["draft", "scheduled"].includes(this.lot.status);
    },
  },

  methods: {
    async update() {
      try {
        const { id, end_notification } = this.lot;

        const form = { end_notification: +end_notification };

        await lotService.editLot(id, form);

        this.$emit("updated");
        this.$notify(
          `End campaign notification ${+end_notification == 1 ? "ON" : "OFF"}`
        );
      } catch (error) {
        this.$dialogs.alert(`End campaign notification update error.`);
      }
    },

    async updateEndTarget() {
      try {
        const { id, end_target } = this.lot;
        const form = { end_target: end_target };

        await lotService.editLot(id, form);

        this.$emit("updated");
        this.$notify(`End campaign notification update success.`);
      } catch (error) {
        this.$dialogs.alert(`End campaign notification update error.`);
      }
    },

    async clearImage() {
      try {
        const { id } = this.lot;
        const form = { end_image: null };

        await lotService.editLot(id, form);
        this.$emit("updated");
        this.$notify("Update End campaign notification image success.");
      } catch (error) {
        console.error(error);
      }
    },

    async upload() {
      try {
        const { id } = this.lot;
        const image = event.target.files[0];

        const form = new FormData();
        form.append("end_image", image);

        await lotService.uploadImage(id, form);

        this.$emit("updated");
        this.$notify("Upload End campaign notification image success.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img.img-fluid {
  max-width: 400px;
  max-height: 400px;
}
</style>

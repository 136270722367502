import qs from "qs";
import HttpRequest from "./request";

export class IllicitBarcodeProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/illicit`);
  }

  async getCodes(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/barcode${queryString}`);
    return data;
  }

  async createNewBarcode(body) {
    const { data } = await this.create(`/barcode`, body);
    return data;
  }

  async getBarcodeById(id) {
    const { data } = await this.get(`/barcode/${id}`);
    return data;
  }

  async updateBarcode(id, body) {
    const { data } = await this.update(`/barcode/${id}`, body);
    return data;
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/log${queryString}`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(`/log/download${queryString}`, {
      responseType: "blob",
    });
  }

  // campaign management api
  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/campaign${queryString}`);
    return data;
  }

  async createCampaign(body) {
    const { data } = await this.create(`/campaign`, body);
    return data;
  }

  async getCampaignById(id) {
    const { data } = await this.get(`/campaign/${id}`);
    return data;
  }

  async updateCampaign(id, body) {
    const { data } = await this.update(`/campaign/${id}`, body);
    return data;
  }
}

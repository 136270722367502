<template>
  <div class="col-md-12" v-if="weekly">
    <label class="mx-5">
      <h4 class="mb-0">Text area</h4>
    </label>

    <div class="ml-10">
      <h5>Text Group 1A</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text1a_content"
              v-model="weekly.text1a_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text1a_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text1a_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text1a_specify"
              :disabled="disabled"
              v-if="weekly.text1a_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 1B</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text1b_content"
              v-model="weekly.text1b_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text1b_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text1b_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text1b_specify"
              :disabled="disabled"
              v-if="weekly.text1b_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 1C</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text1c_content"
              v-model="weekly.text1c_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text1c_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text1c_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text1c_specify"
              :disabled="disabled"
              v-if="weekly.text1c_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 1D</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text1d_content"
              v-model="weekly.text1d_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text1d_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text1d_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text1d_specify"
              :disabled="disabled"
              v-if="weekly.text1d_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 1E</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text1e_content"
              v-model="weekly.text1e_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text1e_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text1e_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text1e_specify"
              :disabled="disabled"
              v-if="weekly.text1e_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 2A</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text2a_content"
              v-model="weekly.text2a_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text2a_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text2a_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text2a_specify"
              :disabled="disabled"
              v-if="weekly.text2a_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 2B</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text2b_content"
              v-model="weekly.text2b_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text2b_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text2b_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text2b_specify"
              :disabled="disabled"
              v-if="weekly.text2b_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 2C</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text2c_content"
              v-model="weekly.text2c_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text2c_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text2c_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text2c_specify"
              :disabled="disabled"
              v-if="weekly.text2c_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 2D</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text2d_content"
              v-model="weekly.text2d_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text2d_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text2d_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text2d_specify"
              :disabled="disabled"
              v-if="weekly.text2d_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />

    <div class="ml-10">
      <h5>Text Group 2E</h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <select
              class="form-control"
              name="text2e_content"
              v-model="weekly.text2e_content"
              @change="update($event)"
              :disabled="disabled"
            >
              <option value="reward_cur">RewardCur</option>
              <option value="target_cur">TargetCur</option>
              <option value="reward_all">RewardAll</option>
              <option value="no_period">No.Period</option>
              <option value="custom">Custom</option>
            </select>
          </td>
          <td v-if="weekly.text2e_content == 'custom'">Specify</td>
          <td colspan="3">
            <input
              type="text"
              class="form-control"
              name="text2e_specify"
              width-250px
              maxlength="100"
              @change="update($event)"
              v-model="weekly.text2e_specify"
              :disabled="disabled"
              v-if="weekly.text2e_content == 'custom'"
            />
          </td>
        </tr>
      </table>
    </div>

    <hr />
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      weekly: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update(event, type) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        const form = {};
        form[name] = this.weekly[name];

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await progressBarService.updateCampaignInfo(id, { weekly: form });

        this.$emit("updated");
        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },
  },

  mounted() {
    this.weekly = this.campaign.weekly;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

table tr td {
  &:nth-child(1) {
    width: 10%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 12%;
  }
  &:nth-child(6) {
    width: 20%;
  }
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

.css-input-text {
  input[type="number"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem;
    margin-right: 6px;
  }
}

input[type="text"],
input[type="color"],
input[type="number"] {
  &:disabled {
    background-color: #efefef;
  }
}

input[type="text"] {
  max-width: 120px;
}

.progress-chart-style-wrapper {
  gap: 2em;
  display: flex;
  margin-left: 2em;
  align-items: center;

  .space {
    margin: 0px 2em;
  }

  .color-picker {
    display: flex;
    align-items: center;
  }
}
</style>

<template>
  <div id="default_layout">
    <navbar></navbar>
    <menubar></menubar>
    <slot></slot>
    <site-footer></site-footer>
  </div>
</template>

<script>
import { toggleBodyClass } from "@/utils/helpers";
import MenuBar from "@/components/layouts/MenuBar.vue";
import NavBar from "@/components/layouts/NavBar.vue";
import SiteFooter from "@/components/layouts/SiteFooter.vue";
export default {
  name: "Layout",
  components: {
    navbar: NavBar,
    menubar: MenuBar,
    siteFooter: SiteFooter,
  },
  mounted() {
    // Remark theme start init here.
    setTimeout(window.Site.run, 3000);

    toggleBodyClass("addClass", "site-menubar-unfold fade-in");
  },
  destroyed() {
    toggleBodyClass("removeClass", "site-menubar-unfold fade-in");
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          {
            staticClass:
              "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
          },
          [
            _c("h1", { staticClass: "page-title" }, [_vm._v("User Group")]),
            _c("breadcrumb", {
              attrs: {
                name: "USERGROUP_EDIT",
                withLast: _vm.usergroup.group_code,
              },
              on: {
                "update:withLast": function ($event) {
                  return _vm.$set(_vm.usergroup, "group_code", $event)
                },
                "update:with-last": function ($event) {
                  return _vm.$set(_vm.usergroup, "group_code", $event)
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "page-header-actions" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-round btn-default mx-10",
              attrs: { type: "button" },
              on: { click: _vm.goBack },
            },
            [
              _c("i", {
                staticClass: "icon md-chevron-left",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Back "),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "page-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
            },
            [
              _c("div", { staticClass: "panel panel-bordered" }, [
                _vm._m(0),
                _c("div", { staticClass: "panel-body" }, [
                  _c("form", { staticClass: "form-horizontal" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          ".is-invalid": _vm.$v.usergroup.auto_number.$error,
                        },
                      },
                      [
                        _vm._m(1),
                        _c("div", { staticClass: "error col-md-12" }, [
                          _vm._v(
                            " This number must match value in Salesforce! Example: a0F25000007ZIgY (15 digits) "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.usergroup.auto_number,
                                  expression: "usergroup.auto_number",
                                },
                              ],
                              ref: "auto_number",
                              staticClass: "form-control form__input",
                              class: {
                                "is-valid":
                                  !_vm.$v.usergroup.auto_number.$invalid,
                              },
                              attrs: {
                                type: "text",
                                maxlength: "255",
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.usergroup.auto_number },
                              on: {
                                change: function ($event) {
                                  return _vm.submit("auto_number")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.usergroup,
                                    "auto_number",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.showErrorAutoNumberValidation
                              ? _c("autoNumberValidation", {
                                  attrs: { $v: _vm.$v },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("h5", { staticClass: "col-md-12" }, [
                        _vm._v("Group Code (View only)​"),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", {}, [
                          _c(
                            "span",
                            { staticClass: "badge badge-lg badge-primary" },
                            [_vm._v(_vm._s(_vm.usergroup.group_code))]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          ".is-invalid":
                            _vm.$v.usergroup.reason_description.$error,
                        },
                      },
                      [
                        _vm._m(2),
                        _c("div", { staticClass: "error col-md-12" }, [
                          _vm._v(
                            " This is the value to be sent to Salesforce. Example: 'ประมูล หรือจับฉลาก ค้าปลีก(GT) RT-00002593' หรือ 'ล๊อกอินสะสม Family Mart' "
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.usergroup.reason_description,
                                expression: "usergroup.reason_description",
                              },
                            ],
                            ref: "reason_description",
                            staticClass: "form-control form__input",
                            class: {
                              "is-valid":
                                !_vm.$v.usergroup.reason_description.$invalid,
                            },
                            attrs: {
                              type: "text",
                              maxlength: "255",
                              autocomplete: "off",
                            },
                            domProps: {
                              value: _vm.usergroup.reason_description,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.submit("reason_description")
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.usergroup,
                                  "reason_description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _vm.showErrorReasondDscriptionValidation
                              ? _c("reasonDescriptionValidation", {
                                  attrs: { $v: _vm.$v },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("h5", { staticClass: "col-md-12" }, [
                        _vm._v("Group Status"),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.usergroup.status,
                                expression: "usergroup.status",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.usergroup,
                                    "status",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.submit("status")
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "active" } }, [
                              _vm._v("ACTIVE"),
                            ]),
                            _c("option", { attrs: { value: "inactive" } }, [
                              _vm._v("INACTIVE"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("User Group Information"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Auto Number")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Reason Description")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="form-group-row row">
    <label>Name</label>
    <input
      type="text"
      name="reward_name"
      @change="update"
      class="form-control"
      :disabled="disabled"
      v-model="reward.reward_name"
    />
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  data() {
    return {
      message: {
        error: `Update name of Reward #${this.reward.id} error.`,
        success: `Update name of Reward #${this.reward.id} success.`,
      },
    };
  },
};
</script>

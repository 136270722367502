<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">VSM Users</h1>
      <breadcrumb name="VSM_USER_MANAGEMENT_LIST"></breadcrumb>
      <div class="page-header-left">
        <div class="mt-4">
          <div class="row mb-4">
            <div class="col-6">
              <div class="d-flex g-1">
                <label class="col-form-label w-120">Role</label>
                <input
                  class="form-control w-250"
                  placeholder="All"
                  v-model="role"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="d-flex g-1">
                <label class="col-form-label w-120">VSM Username</label>
                <input
                  class="form-control w-250"
                  placeholder="All"
                  v-model="username"
                />
                <button
                  type="button"
                  @click="search"
                  class="btn btn-round btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-header-right">
        <VSMUserBulkAdd @done="onDone" />
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :columns="columns"
              :rows="list"
              :totalRows="totalRecords"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'vsm_id' },
              }"
              :pagination-options="pagination"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-search="onSearch"
              @on-row-dblclick="onRowDoubleClick"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VSMUserService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";

import { VSMUsersColumns } from "./model";
import VSMUserBulkAdd from "./components/BulkAdd.vue";

export default {
  name: "VSMUserList",

  mixins: [vueGoodTableMixins],

  components: { VSMUserBulkAdd },

  data() {
    return {
      list: [],
      page: 1,
      role: "",
      username: "",
      columns: VSMUsersColumns,
    };
  },

  watch: {
    async "$route.query"() {
      await this.search();
      this.initialTableState();
    },
  },

  methods: {
    onDone() {
      this.search();
    },

    getParams() {
      let params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },

    async search() {
      const params = this.getParams();

      const searchForm = {
        role: this.role || "All",
        username: this.username || "All",
      };

      const { data, total_records } = await VSMUserService.searchVSMUsers({
        ...params,
        ...searchForm,
      });

      this.list = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      const params = this.getParams();

      const searchForm = {
        username: this.username || "All",
        role: this.role || "All",
      };

      try {
        const response = await VSMUserService.downloadVSMUsers({
          ...params,
          ...searchForm,
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `vsm_user_${this.role || "all"}_${
          this.username || "all"
        }_${new Date().getTime()}.xlsx`;

        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onRowDoubleClick({ row }) {
      this.$router
        .push({
          name: "e-coupon-vsm-user-management-info",
          params: { id: row.vsm_id },
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.initialTableSearchState()
  }
};
</script>

<style scoped>
.g-1 {
  gap: 1em;
}

.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}

.Excel {
  margin: auto 1rem;
}
</style>

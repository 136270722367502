var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-block" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("SF Point conversion ")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.budget.sf_point_conversion,
            expression: "budget.sf_point_conversion",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "number",
          maxlength: "60",
          disabled: _vm.disabled,
          name: "sf_point_conversion",
        },
        domProps: { value: _vm.budget.sf_point_conversion },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.budget, "sf_point_conversion", $event.target.value)
          },
        },
      }),
      _c("p", [_vm._v("Point per Baht")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { attrs: { "my-15px": "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.picture_or_video,
                  expression: "campaign.picture_or_video",
                },
              ],
              attrs: {
                type: "radio",
                value: "image",
                disabled: !_vm.canEdit,
                name: "picture_or_video",
                id: "picture_or_video_image",
              },
              domProps: {
                checked: _vm._q(_vm.campaign.picture_or_video, "image"),
              },
              on: {
                change: [
                  function ($event) {
                    return _vm.$set(_vm.campaign, "picture_or_video", "image")
                  },
                  _vm.updateImageVideoType,
                ],
              },
            }),
            _vm._m(0),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "d-block" }, [
                _vm.campaign.picture_url
                  ? _c("img", {
                      staticClass: "img img-fluid",
                      attrs: {
                        src: _vm.campaign.picture_url,
                        disabled: _vm.campaign.picture_or_video != "image",
                      },
                    })
                  : _vm._e(),
              ]),
              _c("input", {
                ref: "picture_url_upload",
                staticClass: "form-control",
                attrs: {
                  type: "file",
                  accept: "image/*",
                  disabled:
                    _vm.campaign.picture_or_video != "image" || !_vm.canEdit,
                },
                on: { change: _vm.uploadImage },
              }),
            ]),
          ]),
          _c("div", { attrs: { "my-15px": "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.campaign.picture_or_video,
                  expression: "campaign.picture_or_video",
                },
              ],
              attrs: {
                type: "radio",
                value: "video",
                disabled: !_vm.canEdit,
                name: "picture_or_video",
                id: "picture_or_video_video",
              },
              domProps: {
                checked: _vm._q(_vm.campaign.picture_or_video, "video"),
              },
              on: {
                change: [
                  function ($event) {
                    return _vm.$set(_vm.campaign, "picture_or_video", "video")
                  },
                  _vm.updateImageVideoType,
                ],
              },
            }),
            _c(
              "label",
              { staticClass: "mx-5", attrs: { for: "picture_or_video_video" } },
              [_vm._v(" VDO URL ")]
            ),
            _c("div", { staticClass: "form-group" }, [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.campaign.video_url,
                      expression: "campaign.video_url",
                    },
                  ],
                  ref: "video_url",
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "width-400px": "",
                    maxlength: "255",
                    disabled:
                      _vm.campaign.picture_or_video != "video" || !_vm.canEdit,
                  },
                  domProps: { value: _vm.campaign.video_url },
                  on: {
                    change: _vm.updateVideoURL,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.campaign, "video_url", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "mx-5", attrs: { for: "picture_or_video_image" } },
      [
        _vm._v(" Picture - "),
        _c("span", [
          _vm._v("Recommended size 460x224 pixels / less than 1 MB"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
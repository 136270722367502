var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c(
        "div",
        { staticClass: "container-fluid", attrs: { id: "progress-info" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("h4", [
                _c("span", [_vm._v("Progress Update -")]),
                _vm._v(" " + _vm._s(_vm.campaign.id)),
              ]),
            ]),
            _c("div", { staticClass: "col" }, [
              _c("h4", [_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.type)))]),
            ]),
            _c("div", { staticClass: "col" }, [
              _c("h4", [
                _c("span", [_vm._v("Status")]),
                _vm._v(
                  " " + _vm._s(_vm._f("toUpperCase")(_vm.campaign.status))
                ),
              ]),
            ]),
            _c("div", { staticClass: "col" }, [
              _c("h4", [
                _c("span", [_vm._v("SF Point Granted")]),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("numberWithCommas")(_vm.info.point_granted)) +
                    " "
                ),
              ]),
            ]),
            _vm.shouldDisplayLastUpdate
              ? _c("div", { staticClass: "col" }, [
                  _c("h4", [
                    _c("span", [_vm._v("Last Update:")]),
                    _vm._v(" " + _vm._s(_vm.lastUploadTime) + " "),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="d-block">
    <div class="form-group">
      <label>SF Point conversion </label>
      <input
        type="number"
        maxlength="60"
        @change="update"
        class="form-control"
        :disabled="disabled"
        name="sf_point_conversion"
        v-model="budget.sf_point_conversion"
      />
      <p>Point per Baht</p>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: `Update SF Point conversion error.`,
        success: `Update SF Point conversion success.`,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/form.styles.scss";
</style>

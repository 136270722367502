<template>
  <div class="col-md-10" v-if="barcode">
    <div class="mx-5" v-for="(no, index) of barcode.no_targets" :key="index">
      <TargetPrizeImage
        :no="no"
        :campaign="campaign"
        :name="`target_prize_picture${no}`"
        @updated="$emit('updated')"
      />
      <hr />
      <div class="d-flex justify-content-between px-5">
        <div class="d-flex flex-row align-items-center gap-4">
          <div class="flex-grow-1">Prize Expiration</div>
          <div class="radio-custom radio-primary">
            <input
              type="radio"
              value="days"
              :id="`target_expire_mode${no}_days`"
              :name="`target_expire_mode${no}`"
              v-model="barcode[`target_expire_mode${no}`]"
              :disabled="disabled"
              @change="update"
            />
            <label :for="`target_expire_mode${no}_days`"> After grant </label>
          </div>
          <div class="d-flex align-items-center gap-3">
            <input
              type="number"
              class="form-control w-100"
              :name="`target_expire_days${no}`"
              v-model="barcode[`target_expire_days${no}`]"
              @change="update"
              :disabled="
                disabled || barcode[`target_expire_mode${no}`] !== 'days'
              "
            />
            <div>days</div>
          </div>
          <div class="d-flex radio-custom radio-primary">
            <input
              type="radio"
              value="date"
              :id="`target_expire_mode${no}_date`"
              :name="`target_expire_mode${no}`"
              v-model="barcode[`target_expire_mode${no}`]"
              :disabled="disabled"
              @change="update"
            />
            <label :for="`target_expire_mode${no}_date`"> Specific date </label>
          </div>
          <DatePicker
            type="date"
            width-150px
            @change="update"
            placeholder="n/a"
            ref="start_date"
            format="YYYY-MM-DD"
            value-type="YYYY-MM-DD"
            :disabled-date="notBeforeToday"
            v-model="barcode[`target_expire_date${no}`]"
            :disabled="
              disabled || barcode[`target_expire_mode${no}`] !== 'date'
            "
          />
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { progressBarService } from "@/api/client";
import TargetPrizeImage from "./TargetPrizeImage.vue";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
    TargetPrizeImage,
  },

  data() {
    return {
      file: null,
      barcode: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    async update() {
      try {
        const { id } = this.campaign;

        const {
          target_expire_mode1,
          target_expire_days1,
          target_expire_date1,
          target_expire_mode2,
          target_expire_days2,
          target_expire_date2,
          target_expire_mode3,
          target_expire_days3,
          target_expire_date3,
          target_expire_mode4,
          target_expire_days4,
          target_expire_date4,
          target_expire_mode5,
          target_expire_days5,
          target_expire_date5,
        } = this.barcode;

        const form = {
          target_expire_mode1,
          target_expire_days1,
          target_expire_date1,
          target_expire_mode2,
          target_expire_days2,
          target_expire_date2,
          target_expire_mode3,
          target_expire_days3,
          target_expire_date3,
          target_expire_mode4,
          target_expire_days4,
          target_expire_date4,
          target_expire_mode5,
          target_expire_days5,
          target_expire_date5,
        };

        await progressBarService.updateCampaignInfo(id, { barcode: form });

        this.$emit("updated");
        this.$notify(`Barcode Prize target updated`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Barcode Prize target error.`);
      }
    },
  },

  mounted() {
    this.barcode = this.campaign.barcode;
  },
};
</script>

<style lang="scss" scoped>
.radio-custom {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>

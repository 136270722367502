<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table" v-if="task">
        <tbody>
          <tr>
            <td>
              {{ index + 1 }}
            </td>
            <td>
              Name
            </td>
            <td>
              <input
                width-150px
                type="text"
                v-model="task.name"
                :disabled="!canEdit"
                @change="update('name')"
                class="form-control mx-10 d-inline"
              />
            </td>
            <td>Date</td>
            <td>
              <DatePicker
                type="date"
                width-150px
                placeholder="n/a"
                format="YYYY-MM-DD"
                :disabled="!canEdit"
                value-type="YYYY-MM-DD"
                v-model="task.task_date"
                @change="update('task_date')"
              />
            </td>
            <td>Order</td>
            <td>
              <select
                class="form-control"
                :disabled="!canEdit"
                v-model="task.task_order"
                @change="update('task_order')"
              >
                <option :value="null">n/a</option>
                <option
                  v-for="n in taskAmount"
                  :value="n"
                  :key="n"
                  :disabled="isUsed(n)"
                >
                  {{ n }}
                </option>
              </select>
            </td>
            <td>Weight</td>
            <td>
              <input
                width-100px
                type="number"
                ref="task_weight"
                :disabled="!canEdit"
                v-model="task.task_weight"
                @change="update('task_weight')"
                class="form-control mx-10 d-inline"
              />
            </td>
            <td>
              <button
                type="button"
                @click="showTaskDetail"
                class="btn btn-defautl mx-10"
                :disabled="!canEdit"
              >
                <i class="icon md-edit" aria-hidden="true"></i>
              </button>
              <button
                class="btn btn-defautl"
                type="button"
                @click="remove"
                :disabled="!canRemove"
              >
                <i class="icon md-delete" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { luckyLoginService } from "@/api/client";

export default {
  props: ["task", "index", "campaign", "taskAmount"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    canRemove() {
      return ["draft", "scheduled"].includes(this.campaign.status);
    },

    canEdit() {
      return ["draft", "scheduled", "ongoing"].includes(this.campaign.status);
    },
  },

  mounted() {
    this.oldValue = { ...this.task };
  },

  methods: {
    isUsed(no) {
      if (no == this.task.task_order) {
        return false;
      }

      const matched = this.campaign.task_list.findIndex(
        (t) => +t.task_order === +no
      );

      return matched > -1;
    },

    async validate(key) {
      const { name, task_date, task_weight } = this.task;
      const { start_date, end_date } = this.campaign;

      switch (key) {
        case "name":
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Task Name.");
            return false;
          }
          break;

        case "task_date":
          if (
            !moment(task_date).isBetween(
              moment(start_date).add(-1, "days"),
              moment(end_date).add(1, "days")
            )
          ) {
            this.$dialogs.alert(
              `Task Date. ต้องอยู่ระหว่าง ${moment(start_date).format(
                "YYYY-MM-DD"
              )} - ${moment(end_date).format("YYYY-MM-DD")}`
            );
            return false;
          }
          break;

        case "task_weight":
          if (isNaN(parseInt(task_weight))) {
            await this.$dialogs.alert("Task weight ต้องเป็นตัวเลขเท่านั้น");

            this.task.task_weight = this.oldValue.task_weight;
            this.$refs.task_weight.focus();

            return false;
          }

          if (parseInt(task_weight) < 0) {
            await this.$dialogs.alert("Task weight ต้องมากกว่าหรือเท่ากับ 0");
            this.task.task_weight = this.oldValue.task_weight;
            this.$refs.task_weight.focus();
            return false;
          }

          if (!task_weight) {
            this.$dialogs.alert("กรุณาระบุ Task Weight.");
            this.$refs.task_weight.focus();
            return false;
          }
          break;
      }

      return true;
    },

    async update(key) {
      const isValid = await this.validate(key);
      if (!isValid) return;

      const { id, campaign_id } = this.task;

      const form = {};
      form[key] = this.task[key];

      await luckyLoginService.updateTaskInfo(campaign_id, id, form);

      this.oldValue[key] = this.task[key];

      this.$emit("updated");
      this.$notify("Updated.");
    },

    async remove() {
      const r = confirm(`Delete Task "${this.task.name}" \nPlease confirm!`);

      if (r == true) {
        await luckyLoginService.removeTask(this.task.campaign_id, this.task.id);

        this.$emit("updated");
      }
    },

    showTaskDetail() {
      this.$emit("edit", this.task);
    },
  },
};
</script>

<style></style>

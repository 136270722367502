<template>
  <div class="col-md-12" v-if="campaign">
    <div class="d-flex align-items-center gap-3 mb-10">
      <label class="form-control-label px-0">
        <h5>Period Prize</h5>
      </label>
      <label class="switch">
        <input
          type="checkbox"
          :true-value="1"
          :false-value="0"
          :disabled="!canEdit"
          v-model="period_prize"
          @change="togglePeriodPrize"
        />
        <span class="slider round"></span>
      </label>
      <p class="mx-10 mb-5 d-inline">
        {{ +period_prize === 1 ? "ON" : "OFF" }}
      </p>
      <div class="d-flex align-items-center"></div>
    </div>
    <div class="period-prize-items" v-if="+period_prize === 1">
      <div
        class="ml-20"
        v-for="(prize, index) in campaign.period_list"
        :key="prize.id"
      >
        <table class="table table-responsive table-borderless">
          <tbody>
            <tr>
              <td>No. {{ index + 1 }}</td>
              <td>Name</td>
              <td>
                <input
                  type="text"
                  maxlength="40"
                  class="form-control"
                  :disabled="!canEdit"
                  v-model.number="prize.prize_name"
                  @change="update('prize_name', prize)"
                />
              </td>
              <td rowspan="2">
                <div class="d-flex flex-column gap-5 px-20">
                  <div class="reward-choice">
                    <input
                      value="other"
                      type="radio"
                      :disabled="!canEdit"
                      v-model="prize.prize_type"
                      :checked="prize.prize_type === 'other'"
                      @change="update('prize_type', prize)"
                      :name="`reward-choice-${prize.id}`"
                      :id="`reward-choice-physical-${prize.id}`"
                    />
                    <label
                      :for="`reward-choice-physical-${prize.id}`"
                      class="input-label"
                    >
                      Physical
                    </label>
                  </div>
                  <div class="reward-choice">
                    <input
                      value="sfdc"
                      type="radio"
                      :disabled="!canEdit"
                      :checked="prize.prize_type === 'sfdc'"
                      v-model="prize.prize_type"
                      @change="update('prize_type', prize)"
                      :name="`reward-choice-${prize.id}`"
                      :id="`reward-choice-sf-point-${prize.id}`"
                    />
                    <div class="d-flex">
                      <label
                        class="input-label"
                        :for="`reward-choice-sf-point-${prize.id}`"
                      >
                        SF Point
                      </label>
                      <input
                        :min="1"
                        max="1000000"
                        type="number"
                        class="form-control"
                        style="width: 120px"
                        v-model.number="prize.prize_points"
                        @change="update('prize_points', prize)"
                        :disabled="!canEdit || prize.prize_type !== 'sfdc'"
                      />
                    </div>
                  </div>
                  <div class="reward-choice flex-align-top">
                    <input
                      value="ecoupon"
                      type="radio"
                      :disabled="!canEdit"
                      :checked="prize.prize_type === 'ecoupon'"
                      v-model="prize.prize_type"
                      @change="update('prize_type', prize)"
                      :name="`reward-choice-${prize.id}`"
                      :id="`reward-choice-e-coupon-${prize.id}`"
                    />
                    <div>
                      <div class="d-flex flex-align-top mb-10">
                        <label
                          class="input-label"
                          :for="`reward-choice-e-coupon-${prize.id}`"
                        >
                          E-Coupon
                        </label>
                        <div class="face-value-form">
                          <div class="d-flex">
                            <label
                              class="input-label"
                              :for="`reward-choice-face-value-${prize.id}`"
                            >
                              Face Value
                            </label>
                            <input
                              min="1"
                              max="999999"
                              type="number"
                              class="form-control"
                              v-model.number="prize.prize_face_value"
                              @change="update('prize_face_value', prize)"
                              :disabled="
                                !canEdit || prize.prize_type !== 'ecoupon'
                              "
                              :id="`reward-choice-face-value-${prize.id}`"
                            />
                            <span>bht</span>
                          </div>
                          <div class="d-flex">
                            <label
                              class="input-label"
                              :for="`reward-choice-topup-${prize.id}`"
                            >
                              Top Up
                            </label>
                            <input
                              min="0"
                              max="999999"
                              type="number"
                              class="form-control"
                              v-model.number="prize.prize_topup"
                              @change="update('prize_topup', prize)"
                              :disabled="
                                !canEdit || prize.prize_type !== 'ecoupon'
                              "
                              :id="`reward-choice-topup-${prize.id}`"
                            />
                            <span>bht</span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-align-top">
                        <label
                          class="input-label line-high-1"
                          :for="`reward-choice-custom-text-${prize.id}`"
                        >
                          Custom text<br />on coupon
                        </label>
                        <input
                          type="text"
                          maxlength="40"
                          class="form-control w250"
                          v-model="prize.prize_custom_text"
                          @change="update('prize_custom_text', prize)"
                          :disabled="!canEdit || prize.prize_type !== 'ecoupon'"
                          :id="`reward-choice-custom-text-${prize.id}`"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="reward-choice">
                    <input
                      value="prizepool"
                      type="radio"
                      :disabled="!canEdit"
                      v-model="prize.prize_type"
                      :checked="prize.prize_type === 'prizepool'"
                      @change="update('prize_type', prize)"
                      :name="`reward-choice-${prize.id}`"
                      :id="`reward-choice-prizepool-${prize.id}`"
                    />
                    <div class="d-flex">
                      <label
                        :for="`reward-choice-prizepool-${prize.id}`"
                        class="input-label"
                      >
                        Prize Pool
                      </label>
                      <div class="d-flex">
                        <label
                          class="prize_theme_id"
                          :for="`prize_theme_id_${prize.id}`"
                        >
                          Prize Theme ID
                        </label>
                        <select
                          class="form-control"
                          name="prize_theme_id"
                          :id="`prize_theme_id_${prize.id}`"
                          v-model.number="prize.prize_theme_id"
                          @change="update('prize_theme_id', prize)"
                          :disabled="
                            !canEdit || prize.prize_type !== 'prizepool'
                          "
                        >
                          <option :value="null" disabled>
                            Not Select
                          </option>
                          <option
                            v-for="prize in prizepool"
                            :value="prize.id"
                            :key="prize.id"
                          >
                            {{ prize.id }} - {{ prize.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Details</td>
              <td>
                <textarea
                  rows="5"
                  class="form-control"
                  :disabled="!canEdit"
                  v-model.number="prize.prize_details"
                  @change="update('prize_details', prize)"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Picture</td>
              <td>
                <PrizePicture
                  :prize="prize"
                  :campaign="campaign"
                  @updated="$emit('updated')"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { weeklyLoginService, progressBarService } from "@/api/client";

import PrizePicture from "./PrizePicture.vue";

export default {
  name: "PrizeItem",

  props: ["campaign"],

  components: {
    PrizePicture,
  },

  data() {
    return {
      prizepool: [],
      period_prize: 0,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  methods: {
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    async isFormValid(key, prize) {
      const value = prize[key];

      switch (key) {
        case "prize_face_value":
          if (+value < 1 || +value > 999999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Face value เป็นเลขจำนวนเต็ม ที่ 1 - 999,999`
            );
            return false;
          }
          break;

        case "prize_topup":
          if (+value < 0 || +value > 999999 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ Topup เป็นเลขจำนวนเต็ม ที่ 0 - 999,999`
            );
            return false;
          }
          break;

        case "custom_text":
          if (value.length > 40) {
            await this.$dialogs.alert(
              `กรุณาระบุ Custom text ความยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;

        case "prize_points":
          const minimum = 1;
          if (+value < minimum || +value > 1000000 || this.isFloat(value)) {
            await this.$dialogs.alert(
              `กรุณาระบุ SF Point เป็นเลขจำนวนเต็ม ที่ ${minimum} - 1000000`
            );
            return false;
          }
          break;

        case "prize_name":
          if (!value || value.length > 40) {
            await this.$dialogs.alert(
              `กรุณาระบุ Prize Name เป็นข้อความที่ยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;
      }

      return true;
    },

    async update(key, prize) {
      try {
        const formValid = await this.isFormValid(key, prize);
        if (!formValid) return;

        const { id } = this.campaign;

        const form = {};

        form[key] = prize[key];

        await weeklyLoginService.updatePrizeInfo(id, prize.id, form);

        this.$emit("updated");
        this.$notify(`Prize updated.`);
      } catch (error) {
        this.$dialogs.alert(`Prize update error.`);
      }
    },

    async togglePeriodPrize() {
      try {
        const { id } = this.campaign;
        const form = { period_prize: this.period_prize };

        await weeklyLoginService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(
          `${+this.period_prize === 1 ? "Enabled" : "Disabled"}  Period prize.`
        );
      } catch (error) {
        this.$dialogs.alert(`Prize period update error.`);
      }
    },

    async fetchPrizePool() {
      this.prizepool = await progressBarService.getPrizePoolList();
    },
  },

  async mounted() {
    await this.fetchPrizePool();
    this.period_prize = this.campaign.period_prize;
  },
};
</script>

<style lang="scss" scoped>
.reward-choice {
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .d-flex {
    align-items: center;
    justify-content: flex-start;
  }

  .input-label {
    width: 100px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 0px;
  }

  input[type="number"] {
    max-width: 120px;
    margin-right: 1em;
  }

  label.prize_theme_id {
    width: 180px;
    flex: 1 1 auto;
    margin-bottom: 0px;
  }

  .flex-align-top,
  &.flex-align-top {
    align-items: flex-start;
  }

  .face-value-form {
    gap: 0.5em;
    display: flex;
    min-width: 240px;
    flex-direction: column;
  }

  .line-high-1 {
    line-height: 1;
  }

  .w250 {
    width: 250px;
  }
}

td:nth-child(3) {
  min-width: 300px;
}
</style>

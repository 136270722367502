export const logTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
  },
  {
    label: "Lot ID",
    field: "lot_id",
    type: "number",
  },
  {
    label: "Lot Type",
    field: "lot_type",
  },
  {
    label: "Lot Name",
    field: "name",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User Name",
    field: "firstname",
  },
  {
    label: "User Lastname",
    field: "lastname",
  },
  {
    label: "Refund Amount",
    field: "points",
    type: "number",
  },
  {
    label: "Status",
    field: "status",
  },
];

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-bordered mb-0" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "panel-body" },
      [
        _c("ckeditor", {
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-default mx-10",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _c("i", {
              staticClass: "icon md-close",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Cancel "),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-round btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.confirm },
          },
          [
            _c("i", {
              staticClass: "icon md-check",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Confirm "),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Reward Detail")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
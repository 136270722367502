<template>
  <div id="product-type">
    <h4 class="mb-20 ml-20">Product Type</h4>

    <div class="container ml-30">
      <div class="row">
        <div class="col-12">
          <label class="switch">
            <input
              type="checkbox"
              :true-value="'ecoupon'"
              :false-value="'default'"
              @change="update"
              name="product_type"
              v-model="product.product_type"
            />
            <span class="slider round"></span>
          </label>
          <p class="mx-10 d-inline">E-Coupon</p>
        </div>
        <div class="col-12" v-if="product.product_type === 'ecoupon'">
          <table>
            <tr>
              <td>
                <span>Face Value</span>
              </td>
              <td>
                <input
                  min="1"
                  max="999999"
                  type="number"
                  name="ecoupon_face_value"
                  class="form-control w-150"
                  @change="update"
                  v-model="product.ecoupon_face_value"
                />
              </td>
              <td>
                <span class="px-10">Baht</span>
              </td>
              <td class="w-100 h-align-end">
                <span>Topup</span>
              </td>
              <td>
                <input
                  min="0"
                  max="999999"
                  type="number"
                  name="ecoupon_topup"
                  class="form-control w-150"
                  @change="update"
                  v-model="product.ecoupon_topup"
                />
              </td>
              <td>
                <span class="px-10">Baht</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Expire in</span>
              </td>
              <td>
                <input
                  min="1"
                  max="365"
                  type="number"
                  name="ecoupon_expire_in"
                  class="form-control w-150"
                  @change="update"
                  v-model="product.ecoupon_expire_in"
                />
              </td>
              <td colspan="4">
                <span class="px-10">Days after issuing date</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Custom text on coupon</span>
              </td>
              <td colspan="5">
                <div class="d-flex">
                  <input
                    type="text"
                    maxlength="40"
                    autocomplete="off"
                    name="ecoupon_custom_text"
                    class="form-control w-350"
                    @change="update"
                    v-model="product.ecoupon_custom_text"
                  />
                  <span class="px-10">Max 40 char</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productService } from "@/api/client";

export default {
  name: "ProductType",

  props: ["product"],

  methods: {
    async update(event) {
      const { name } = event.currentTarget;

      if (!this.validate(name)) return;

      const form = {};
      form[name] = this.product[name];

      try {
        const { id } = this.$route.params;
        await productService.editProduct(id, form);
        this.$notify(`Product type updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Product type error.`);
      }
    },

    validate(name) {
      const {
        ecoupon_face_value,
        ecoupon_topup,
        ecoupon_expire_in,
        ecoupon_custom_text,
      } = this.product;

      switch (name) {
        case "ecoupon_face_value":
          if (ecoupon_face_value < 1 || ecoupon_face_value > 999999) {
            this.$dialogs.alert(`กรุณาระบุ Face Value ตั้งแต่ 1 ถึง 999,999`);
            return false;
          }
          break;

        case "ecoupon_topup":
          if (ecoupon_topup < 0 || ecoupon_topup > 999999) {
            this.$dialogs.alert(`กรุณาระบุ Topup ตั้งแต่ 0 ถึง 999,999`);
            return false;
          }
          break;

        case "ecoupon_expire_in":
          if (ecoupon_expire_in < 1 || ecoupon_expire_in > 365) {
            this.$dialogs.alert(`กรุณาระบุ Expire in ตั้งแต่ 1 ถึง 365`);
            return false;
          }
          break;

        case "ecoupon_custom_text":
          if (ecoupon_custom_text.length > 40) {
            this.$dialogs.alert(
              `กรุณาระบุ Custom text on coupon ความยาวไม่เกิน 40 ตัวอักษร`
            );
            return false;
          }
          break;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border-radius: 1px;
}

table {
  tr {
    td {
      padding-bottom: 1em;

      &.h-align-end {
        text-align: right;
        padding-right: 1em;
      }

      &:first-child {
        width: 96px;
      }
    }
  }
}
</style>

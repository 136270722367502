<template>
  <div class="flex-fit-content" v-if="stamp">
    <div class="form-group-row">
      <label class="mb-0">Text color</label>

      <div class="color-picker">
        <input
          type="text"
          v-model="color"
          name="text_color"
          :disabled="disabled"
          @change="update($event, 'color')"
        />
        <input
          type="color"
          v-model="color"
          name="text_color"
          :disabled="disabled"
          @change="update($event, 'color')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      color: null,
      message: {
        error: "Update Text color error.",
        success: "Update Text color success.",
      },
    };
  },

  methods: {
    initial() {
      const { text_color } = this.stamp;

      if (!text_color) return;

      this.color = this.parseColorValue(text_color);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
  margin-bottom: 1rem;
  padding: 0px 1.25rem;
}

.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-weight: 500;
    margin-bottom: 0px;
  }
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;

    &:disabled {
      color: #aaa;
      background-color: #efefef;
    }
  }

  input[type="color"] {
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border-left: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

input[type="color"] {
  &:disabled {
    background-color: #efefef;
  }
}
</style>

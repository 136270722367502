var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm._m(0),
    _c("div", { staticClass: "col-8" }, [
      _c("table", { staticClass: "table table-responsive table-borderless" }, [
        _c("tr", [
          _c("td", [
            _c(
              "div",
              { staticClass: "radio-input-row radio-custom radio-primary" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reward.expire_mode,
                      expression: "reward.expire_mode",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    value: "days",
                    name: "expire_mode",
                    disabled:
                      _vm.disabled || _vm.reward.reward_type !== "ecoupon",
                    id: "expire_mode_days_" + _vm.reward.id,
                  },
                  domProps: { checked: _vm._q(_vm.reward.expire_mode, "days") },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(_vm.reward, "expire_mode", "days")
                      },
                      _vm.update,
                    ],
                  },
                }),
                _c(
                  "label",
                  { attrs: { for: "expire_mode_days_" + _vm.reward.id } },
                  [_vm._v("After grant")]
                ),
              ]
            ),
          ]),
          _c("td", [
            _c("div", { staticClass: "d-flex gap-3 align-items-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reward.expire_days,
                    expression: "reward.expire_days",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  min: "0",
                  max: "9999",
                  "width-80px": "",
                  type: "number",
                  name: "expire_days",
                  disabled:
                    _vm.disabled ||
                    _vm.reward.expire_mode != "days" ||
                    _vm.reward.reward_type !== "ecoupon",
                },
                domProps: { value: _vm.reward.expire_days },
                on: {
                  change: _vm.update,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.reward, "expire_days", $event.target.value)
                  },
                },
              }),
              _c("span", [_vm._v("days")]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c(
              "div",
              { staticClass: "radio-input-row radio-custom radio-primary" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reward.expire_mode,
                      expression: "reward.expire_mode",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    value: "date",
                    name: "expire_mode",
                    disabled:
                      _vm.disabled || _vm.reward.reward_type !== "ecoupon",
                    id: "expire_mode_date_" + _vm.reward.id,
                  },
                  domProps: { checked: _vm._q(_vm.reward.expire_mode, "date") },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(_vm.reward, "expire_mode", "date")
                      },
                      _vm.update,
                    ],
                  },
                }),
                _c(
                  "label",
                  { attrs: { for: "expire_mode_date_" + _vm.reward.id } },
                  [_vm._v(" Specific date ")]
                ),
              ]
            ),
          ]),
          _c(
            "td",
            [
              _c("DatePicker", {
                ref: "expire_date",
                attrs: {
                  type: "date",
                  "width-120px": "",
                  name: "expire_date",
                  placeholder: "n/a",
                  format: "YYYY-MM-DD",
                  "value-type": "YYYY-MM-DD",
                  disabled:
                    _vm.disabled ||
                    _vm.reward.expire_mode != "date" ||
                    _vm.reward.reward_type !== "ecoupon",
                  "disabled-date": _vm.disabledBeforeTomorrow,
                },
                on: {
                  change: function ($event) {
                    return _vm.update(
                      $event,
                      "expire_date",
                      _vm.reward.expire_date
                    )
                  },
                },
                model: {
                  value: _vm.reward.expire_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.reward, "expire_date", $$v)
                  },
                  expression: "reward.expire_date",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4" }, [
      _c("p", { staticClass: "light text-right", attrs: { "ml-5": "" } }, [
        _vm._v("E-Coupon Expiration"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="wrap">
    <!-- toggle show / hide -->
    <label for="display" class="my-10 d-none">
      <toggle-button
        id="display"
        :value="false"
        color="#3e8ef7"
        :sync="true"
        :labels="true"
        v-model="display"
      />
      <span class="mx-10">{{ display ? "ซ่อน" : "แสดง" }} validate</span>
    </label>
    <small id="emailHelp" class="form-text text-muted">
      <ul class="list-icons mb-0" v-if="display">
        <!-- minLength -->
        <li
          :class="{ 'text-success': $v.usergroup.reason_description.required }"
        >
          <i
            class="wb-close"
            aria-hidden="true"
            v-if="!$v.usergroup.reason_description.required"
          ></i>
          <i class="wb-check" aria-hidden="true" v-else></i>
          จำเป็นต้องระบุ Reason Description
        </li>

        <!-- maxLength -->
        <li
          :class="{ 'text-success': $v.usergroup.reason_description.maxLength }"
        >
          <i
            class="wb-close"
            aria-hidden="true"
            v-if="!$v.usergroup.reason_description.maxLength"
          ></i>
          <i class="wb-check" aria-hidden="true" v-else></i>
          ต้องระบุไม่เกิน
          {{ $v.usergroup.reason_description.$params.maxLength.max }} ตัวอักษร
        </li>
      </ul>
    </small>
  </div>
</template>

<script>
export default {
  props: ["$v"],
  data() {
    return {
      display: true,
    };
  },
};
</script>
<style lang="scss" scoped>
div.wrap {
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #f3f3f3;
}
</style>

export const reviewTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "User ID",
    field: "sfdc_user_id",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Campaign ID",
    field: "campaign_id",
    type: "number",
  },
  {
    label: "Campaign Name.",
    field: "campaign_name",
  },
  {
    label: "Tries No.",
    field: "tries_no",
    type: "number",
  },
  {
    label: "Answers",
    field: "answer",
  },
  {
    label: "Login No.",
    field: "login_no",
    type: "number",
  },
  {
    label: "Winner?",
    field: "is_winner",
    type: "number",
    formatFn: function (value) {
      return +value === 1 ? "Yes" : "No";
    },
  },
];

export const tableColumns = [
  {
    label: "Pool ID",
    field: "id",
    type: "number",
  },
  {
    label: "Pool Name ",
    field: "name",
  },
  {
    label: "Pool Type",
    field: "type",
  },
  {
    label: "Vendor",
    field: "vendor",
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Last Modified",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  methods: {
    validate(status) {
      const { end_date, start_date } = this.campaign;

      const { target, background_picture, target_reward_count } =
        this.campaign.monthly;

      switch (status) {
        case "scheduled":
          // มีรูป Background upload
          if (!background_picture) {
            this.$dialogs.alert("กรุณาระบุ Banner picture.");
            return false;
          }

          // มีการกำหนด Target and Reward
          if (target_reward_count <= 0) {
            this.$dialogs.alert("กรุณาระบุ Target​ and Reward.");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องยังเป็นอนาคต.");
            return false;
          }

          //  End date >= Start
          if (moment(end_date) < moment(start_date)) {
            this.$dialogs.alert("End date ต้องมากกว่าหรือเท่ากับ Start date.");
            return false;
          }

          // End date >= Start date & ไม่เกิน 3 เดือน
          if (
            Math.abs(moment(start_date).diff(moment(end_date), "month")) >
            3 - 1
          ) {
            this.$dialogs.alert(
              "End date ต้องอยู่ภายในเดือนที่ 3 จาก Start date"
            );
            return false;
          }

          if (!this.withInThreeMonth(start_date, end_date)) {
            this.$dialogs.alert(
              "End date ต้องอยู่ภายในเดือนที่ 3 จาก Start date"
            );
            return false;
          }

          // มี file Target upload และจำนวน Targets ในไฟล์ของ User ทุกคนตรงกับ No. Targets ที่ควรจะเป็น
          // not implement
          break;
      }

      return true;
    },

    withInThreeMonth(start_date, end_date) {
      const endDate = moment(end_date);
      const startDate = moment(start_date);

      if (startDate.isValid() && endDate.isValid()) {
        const months = [0, 0, 0].map((o, i) => {
          return moment(start_date).add(i, "month").format("MMYYYY");
        });

        return months.includes(endDate.format("MMYYYY"));
      } else {
        return true;
      }
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await progressBarService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Transaction Log</h1>
      <breadcrumb name="STAFF_ENGAGEMENT_TRANSACTION_LOGS"></breadcrumb>
      <div class="page-header-right">
        <div class="period-filter">
          Selected Period
          <date-picker
            range
            type="date"
            v-model="fromTo"
            :default-value="fromTo"
            value-type="format"
            format="YYYY-MM-DD"
            placeholder="Select date range"
          ></date-picker>
        </div>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

import vueGoodTableMixins from "@/mixins/vue-good-table";

import { staffEngagementService } from "@/api/client";
import { transactionLogTableColumns } from "@/views/staff-engagement/model";

export default {
  name: "TransactionLog",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      fromTo: [],
      transactions: [],
      columns: transactionLogTableColumns,
    };
  },

  watch: {
    fromTo() {
      this.onPeriodChange();
    },

    transactions(v) {
      this.list = v;
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
    this.$router.replace({ query: null });
  },

  methods: {
    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    isValidPeriod() {
      const { from, to } = this.$route.query;

      if (!from || !dayjs(from).isValid()) {
        return false;
      }

      if (!to || !dayjs(to).isValid()) {
        return false;
      }

      return true;
    },

    async fetch() {
      if (!this.isValidPeriod()) {
        return;
      }

      const params = this.getParams();

      const { data, count } = await staffEngagementService.getTransactionLog(
        params
      );

      this.transactions = data;
      this.totalRecords = count;
    },

    async exportToExcel() {
      try {
        const response = await staffEngagementService.downloadTransactionLog({
          from: this.fromTo[0],
          to: this.fromTo[1],
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `staff_engagement_txlog_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onPeriodChange() {
      const { sortBy, sortDir } = this.$route.query;

      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
            sortDir: sortDir || "desc",
            sortBy: sortBy || "id",
          }),
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;

  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.period-filter {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}

.mx-datepicker-range {
  width: 216px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group col-md-6" }, [
    _vm._m(0),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.theme.award_name,
          expression: "theme.award_name",
        },
      ],
      staticClass: "form-control",
      attrs: {
        type: "text",
        maxlength: "60",
        name: "award_name",
        disabled: _vm.disabled,
      },
      domProps: { value: _vm.theme.award_name },
      on: {
        change: _vm.update,
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.theme, "award_name", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [
        _vm._v(" Award Name "),
        _c("span", { staticClass: "small light" }, [
          _vm._v(" to be shown to user"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
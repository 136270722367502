<template>
  <div class="col-md-8">
    <div class="ml-10">
      <h5>
        Button Action
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>First page</td>
          <td>
            <input
              type="text"
              maxlength="25"
              name="button_action_first_page"
              @change="update($event)"
              v-model="campaign.button_action_first_page"
              :disabled="disabled"
              class="form-control"
            />
          </td>
          <td>Play page</td>
          <td>
            <input
              type="text"
              maxlength="25"
              name="button_action_play_page"
              @change="update($event)"
              v-model="campaign.button_action_play_page"
              :disabled="disabled"
              class="form-control"
            />
          </td>
        </tr>
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="button_action_text_color"
                @change="update($event, 'color')"
                v-model="campaign.button_action_text_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="button_action_text_color"
                @change="update($event, 'color')"
                v-model="campaign.button_action_text_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="button_action_text_size"
                v-model="campaign.button_action_text_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div class="ml-10">
      <h5>
        Already Played
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <input
              type="text"
              maxlength="25"
              name="already_played_text"
              @change="update($event)"
              v-model="campaign.already_played_text"
              :disabled="disabled"
              class="form-control"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="already_played_text_color"
                @change="update($event, 'color')"
                v-model="campaign.already_played_text_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="already_played_text_color"
                @change="update($event, 'color')"
                v-model="campaign.already_played_text_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="already_played_text_size"
                v-model="campaign.already_played_text_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div class="ml-10">
      <h5>
        View winner list / T&C
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text color</td>
          <td>
            <div class="color-picker">
              <input
                type="text"
                name="view_winner_text_color"
                @change="update($event, 'color')"
                v-model="campaign.view_winner_text_color"
                :disabled="disabled"
              />
              <input
                type="color"
                name="view_winner_text_color"
                @change="update($event, 'color')"
                v-model="campaign.view_winner_text_color"
                :disabled="disabled"
              />
            </div>
          </td>
          <td>Text size</td>
          <td>
            <div class="css-input-text">
              <input
                type="number"
                @change="update($event)"
                name="view_winner_text_size"
                v-model="campaign.view_winner_text_size"
                :disabled="disabled"
              />
              rem
            </div>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div class="ml-10">
      <h5>
        Congratulation
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <input
              type="text"
              maxlength="25"
              name="congratulation_text"
              @change="update($event)"
              v-model="campaign.congratulation_text"
              :disabled="disabled"
              class="form-control"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
    <hr />
    <div class="ml-10">
      <h5>
        Consolidation
      </h5>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>Text content</td>
          <td>
            <input
              type="text"
              maxlength="25"
              name="consolidation_text"
              @change="update($event)"
              v-model="campaign.consolidation_text"
              :disabled="disabled"
              class="form-control"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { luckyDrawService } from "@/api/client";

export default {
  props: ["campaign"],

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update(event, type) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = event.target.value;

        if (type != "color" && typeof value === "string" && value.length > 25) {
          await this.$dialogs.alert("text ต้องไม่เกิน 25 ตัวอักษร");
          document.querySelector(`[name="${name}"]`).focus();
          return;
        }

        const form = {};
        form[name] = value;

        if (type == "color") {
          form[name] = value.replace(/#/gi, "");
        }

        await luckyDrawService.updateCampaignInfo(id, form);

        this.$notify(`Update success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update error.`);
      }
    },

    parseColorValue() {
      const {
        button_action_text_color,
        already_played_text_color,
        view_winner_text_color,
      } = this.campaign;

      if (button_action_text_color)
        this.campaign.button_action_text_color = `#${this.campaign.button_action_text_color}`;

      if (already_played_text_color)
        this.campaign.already_played_text_color = `#${this.campaign.already_played_text_color}`;

      if (view_winner_text_color)
        this.campaign.view_winner_text_color = `#${this.campaign.view_winner_text_color}`;
    },
  },

  mounted() {
    this.parseColorValue();
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

table tr td {
  &:nth-child(1) {
    width: 10%;
    text-align: right;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 10%;
    text-align: right;
  }
  &:nth-child(4) {
    width: 20%;
  }
}

.color-picker {
  display: flex;

  input[type="text"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem 0px 0px 0.215rem;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0px;
    transform: translateX(-1px);
    border: 1px solid #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-color: #7777;
    border-radius: 0px 0.215rem 0.215rem 0px;
  }
}

.css-input-text {
  input[type="number"] {
    height: 32px;
    font-size: 1rem;
    max-width: 100px;
    color: #444;
    outline: none;
    padding: 0.429rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7777;
    border-radius: 0.215rem;
    margin-right: 6px;
  }
}

input[type="text"],
input[type="color"],
input[type="number"] {
  &:disabled {
    background-color: #efefef;
  }
}
</style>

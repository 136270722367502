<template>
  <div>
    <label>
      <h5>Quota Amount (coins)</h5>
    </label>
    <input
      min="0"
      ref="amt_max"
      v-money="money"
      @blur="update"
      v-model.lazy="amt_max"
      width-300px
      :disabled="!canEdit"
      class="form-control text-right"
    />
  </div>
</template>

<script>
import { walletService } from "@/api/client";

export default {
  name: "QuotaAmount",

  props: ["quota"],

  data() {
    return {
      amt_max: 0,
      money: {
        thousands: ",",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
    };
  },

  computed: {
    canEdit() {
      return ["draft", "scheduled"].includes(this.quota.status);
    },
  },

  mounted() {
    this.amt_max = this.quota.amt_max;
  },

  methods: {
    async isFormValid() {
      if (this.amt_max < 0) {
        await this.$dialogs.alert("กรุณากรอก Quota Amount ให้ถูกต้อง");
        this.$refs.amt_max.focus();
        return false;
      }
      return true;
    },

    async update() {
      let amt_max = this.amt_max;
      amt_max = amt_max.replace(",", "");

      if (+this.quota.amt_max === +amt_max) {
        return;
      }

      const formValid = await this.isFormValid();
      if (!formValid) return;

      const { id } = this.quota;

      await walletService.updateQuotaInfo(id, { amt_max: +amt_max });
      this.$notify("Change Quota amount success");
      this.$emit("updated");
    },
  },
};
</script>

<style></style>

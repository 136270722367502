import qs from "qs";
import HttpRequest from "./request";

export class HistoryProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/history`);
  }
  async getHistoryDate(from = this.lastmonth(), to = this.today()) {
    const { data } = await this.get(`?from=${from}&to=${to}`);
    return data;
  }

  lastmonth() {
    var today = new Date();
    var lastmonth = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 12)
      .toJSON()
      .slice(0, 10);
    return lastmonth;
  }

  today() {
    var today = new Date().toJSON().slice(0, 10);
    return today;
  }

  async getHistory(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createHistory(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editHistory(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }
  
  async download(ids) {
    return await this.axiosInstance.get(
      `${process.env.VUE_APP_SERVICE_URL}/ams/api/history/download?lots=${ids}`,
      {
        responseType: "blob",
      }
    );
  }
}

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create Staff Engagement Campaign</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <h5 class="col-md-12">Campaign Name</h5>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="campaign.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation
                  :$v="$v.campaign"
                  v-if="showErrorNameValidation"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="createCampaign"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { staffEngagementService } from "@/api/client";
import nameValidation from "./validation/name.vue";
import { responseErrorMapping } from "./model/error";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "Create",

  data() {
    return {
      showErrorNameValidation: false,

      campaign: {
        name: "",
      },
    };
  },

  components: {
    nameValidation,
  },

  validations: { campaign: validateRules },

  methods: {
    async isFormValid() {
      if (this.$v.campaign.name.$invalid) {
        await this.$dialogs.alert("กรุณาระบุ Campaign Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }

      return true;
    },

    close() {
      this.$modal.hide("create-modal");
    },

    async getFormData() {
      const body = {
        name: this.campaign.name,
      };

      return body;
    },

    async createCampaign() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await staffEngagementService.createCampaign(body);
        await this.$dialogs.alert("Create campaign Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-custom {
  padding-top: 0.2rem;
}
</style>

<template>
  <div class="row" v-if="!isNonPanelUser">
    <div class="col-4">
      <div class="radio-input-row radio-custom radio-primary">
        <input
          type="radio"
          value="sfdc"
          v-model="reward.reward_type"
          :name="`reward_type_${index}`"
          :id="`reward_type_sfdc_${index}`"
          @change="update($event, 'reward_type')"
          :disabled="disabled"
        />
        <label :for="`reward_type_sfdc_${index}`">SF Point</label>
      </div>
    </div>
    <div class="col-8">
      <input
        min="1"
        max="999999"
        type="number"
        name="sfdc_point"
        @change="update"
        class="form-control"
        v-model.number="reward.sfdc_point"
        :disabled="disabled || reward.reward_type !== 'sfdc'"
      />
    </div>
  </div>
</template>

<script>
import rewardMixins from "../reward.mixins";

export default {
  mixins: [rewardMixins],

  data() {
    return {
      message: {
        error: `Update SF Point of Reward #${this.reward.id} error.`,
        success: `Update SF Point of Reward #${this.reward.id} success.`,
      },
    };
  },
};
</script>

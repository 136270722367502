<template>
  <div
    class="page vertical-align text-center"
    data-animsition-in="fade-in"
    data-animsition-out="fade-out"
  >
    <div class="page-content vertical-align-middle">
      <h1>
        <small class="text-white">PHILIP MORRIS <br />INTERNATIONAL</small>
      </h1>
      <div class="brand">
        <img class="brand-img w-120" src="@/assets/logo.png" alt="PMI" />
      </div>
      <div class="panel panel-transparent">
        <div class="panel-body">
          <h1><small class="text-white">AUCTION MANAGEMENT SYSTEM</small></h1>
          <form class="login w-350 mx-auto mt-30" @submit.prevent="login">
            <div class="form-group text-left">
              <input
                type="text"
                class="form-control"
                id="username"
                name="username"
                placeholder="Name"
                v-model="username"
              />
            </div>

            <div class="form-group text-left">
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                v-model="password"
                placeholder="Password"
              />
            </div>
            <div class="form-group clearfix"></div>
            <button
              type="submit"
              class="btn btn-primary btn-block btn-lg mt-30"
            >
              Sign in
            </button>
            <p class="mt-30 text-white">Sign into your pages account</p>
          </form>
        </div>
      </div>
      <footer class="page-copyright">
        <a
          v-if="!isProduction"
          class="btn btn-link text-white"
          :href="`https://bitbucket.org/keenagilist/vue_ams_vivi/commits/${gitHash}`"
        >
          {{ gitBranch }} / {{ gitHash }}
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import { toggleBodyClass } from "@/utils/helpers";
export default {
  data() {
    return {
      username: null,
      password: null,
      gitHash: process.env.VUE_APP_GIT_HASH,
      gitBranch: process.env.VUE_APP_GIT_BRANCH,
      isProduction: process.env.NODE_ENV === "production",
    };
  },
  methods: {
    validate() {
      if (!this.username || (!this.username && !this.password)) {
        this.$dialogs.alert(`กรุณาใส่ username และ password`);
        return false;
      }

      if (!this.password) {
        this.$dialogs.alert(`กรุณาใส่ password`);
        return false;
      }

      return true;
    },
    async login() {
      try {
        if (this.validate()) {
          await this.$store.dispatch("auth/login", this.$data);
          window.location = "/";
        }
      } catch (error) {
        this.$dialogs.alert(`username หรือ password ผิด กรุณาลองใหม่อีกครั้ง`);
      }
    },
  },
  mounted() {
    toggleBodyClass("addClass", "page-login-v3 layout-full");
  },
  destroyed() {
    toggleBodyClass("removeClass", "page-login-v3 layout-full");
  },
};
</script>
<style lang="scss" scoped>
.page-login-v3 .panel {
  width: 600px;
  background-color: initial;
}
.page-login-v3 .panel .panel-body {
  padding: 30px 40px 40px;
}
</style>

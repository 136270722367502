<template>
  <div class="page vld-parent" v-if="user">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">VSM Users</h1>
          <breadcrumb
            name="VSM_USER_MANAGEMENT_DETAIL"
            :withLast.sync="user.username"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">VSM Users - {{ user.vsm_id }}</h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <div class="user-form-horizontal" v-if="user">
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-login-input">Username</label>
                    </div>
                    <div class="col-5">
                      <input
                        name="username"
                        class="form-control"
                        autocomplete="false"
                        id="paddler-login-input"
                        v-model="user.username"
                        @change="update"
                      />
                      <p class="my-5 px-3 hint">
                        1-255 characters, a-z, A-Z, 0-9, dot, dash, underscore.
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="d-flex justify-content-end">
                        Last Login {{ user.last_login || "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="user-firstname-input">First Name</label>
                    </div>
                    <div class="col-5">
                      <input
                        name="firstname"
                        class="form-control"
                        id="user-firstname-input"
                        autocomplete="false"
                        v-model="user.firstname"
                        @change="update"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="user-lastname-input">Last Name</label>
                    </div>
                    <div class="col-5">
                      <input
                        name="lastname"
                        class="form-control"
                        id="user-lastname-input"
                        autocomplete="false"
                        v-model="user.lastname"
                        @change="update"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="user-role-input">Role</label>
                    </div>
                    <div class="col-5">
                      <input
                        name="employee_role"
                        class="form-control"
                        id="user-role-input"
                        autocomplete="false"
                        @change="update"
                        :value="user.employee_role.toUpperCase()"
                        @input="
                          user.employee_role = $event.target.value.toUpperCase()
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-status-input">Status</label>
                    </div>
                    <div class="col-5">
                      <select
                        name="status"
                        class="form-control"
                        id="paddler-status-input"
                        @change="update"
                        v-model.number="user.status"
                      >
                        <option value="1">ACTIVE</option>
                        <option value="0">INACTIVE</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label for="paddler-password-input">
                        {{ enable_edit_password ? "New password" : "Password" }}
                      </label>
                    </div>
                    <div class="col-5" v-if="enable_edit_password">
                      <div class="form-update-password">
                        <input
                          name="password"
                          class="form-control"
                          id="paddler-password-input"
                          autocomplete="false"
                          v-model="user.password"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-round"
                          @click="updatePassword"
                        >
                          Commit
                        </button>
                        <button
                          type="button"
                          class="btn btn-defalut btn-round"
                          @click="enable_edit_password = false"
                        >
                          Cancel
                        </button>
                      </div>

                      <p
                        @click="generatePassword"
                        class="auto-generate-password-link"
                      >
                        Auto Generate Password
                      </p>
                    </div>
                    <div class="col-5" v-else>
                      <div class="form-update-password">
                        <span>{{ user.password }}</span>

                        <button
                          class="btn btn-primary btn-round"
                          @click="enable_edit_password = true"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VSMUserService } from "@/api/client";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "VSMUserInformation",

  data() {
    return {
      user: null,
      isLoading: false,
      current_password: "",
      enable_edit_password: false,
    };
  },

  components: {
    Loading,
  },

  watch: {
    enable_edit_password(n) {
      if (n === true) {
        this.current_password = this.user.password;
        return;
      }
      if (n === false) {
        this.user.password = this.current_password;
        this.current_password = "";
        return;
      }
    },
  },

  computed: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async update({ target }) {
      if (!target) return;

      if (target.name == "username") {
        const matched = /^[A-Za-z\d\.\-\_]{1,255}$/gm.test(target.value);
        if (!matched) {
          this.$dialogs.alert(
            `User Login "${target.value}" not match pattern \n "a-z, A-Z, 0-9, dot, dash, underscore"`
          );
          return;
        }
      }

      try {
        const form = {};
        form[target.name] = target.value;

        if (target.name == "status") {
          form[target.name] = +target.value;
        }

        const { id } = this.$route.params;

        await VSMUserService.updateVSMUserInfo(id, form);

        const labelElem = document.querySelector(`label[for=\"${target.id}\"]`);
        this.$notify(`Update ${labelElem.textContent} success.`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Update user information error");

        this.getUserInfo();
      }
    },

    async updatePassword() {
      this.isLoading = true;
      await this.wait(500);

      try {
        await this.update({
          target: {
            name: "password",
            value: this.user.password,
            id: "paddler-password-input",
          },
        });

        this.current_password = this.user.password;
        this.enable_edit_password = false;
      } catch (error) {
        this.$notify("Error! Something weng wrong.");
      } finally {
        this.isLoading = false;
      }
    },

    async generatePassword() {
      this.isLoading = true;
      await this.wait(500);
      try {
        this.user.password = await VSMUserService.generatePassword();
      } catch (error) {
        this.$notify("Error! Something weng wrong.");
      } finally {
        this.isLoading = false;
      }
    },

    async getUserInfo() {
      const id = this.$route.params.id;
      this.user = await VSMUserService.getVSMUserInfo(id);
    },
  },

  async created() {
    await this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.user-form-horizontal {
  .form-control {
    width: 250px;
  }

  .hint {
    color: #bbc1c6;
  }

  .row {
    margin-bottom: 2em;
  }

  .row .col-3 {
    font-weight: 400;
    text-align: right;
    align-items: center;
    justify-content: flex-end;

    > label {
      margin-top: 0.5em;
    }
  }

  .auto-generate-password-link {
    color: #3e8ef7;
    cursor: pointer;
    padding: 0.5em 0px;
    display: inline-block;
    text-decoration: underline;
  }

  .form-update-password {
    gap: 0.5em;
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    > span {
      margin-right: 2em;
    }
  }
}
</style>

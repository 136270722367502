import qs from "qs";
import HttpRequest from "./request";

export class TransactionLogProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/transaction_log`);
  }

  async getTransactionLog(path, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/${path}${queryString}`);
    return data;
  }

  async downloadTransactionLog(path, params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(`/${path}/download${queryString}`, {
      responseType: "blob",
    });
  }
}

<template>
  <div v-if="lot">
    <div class="row">
      <label class="form-control-label">
        <h4>LINE Notification</h4>
      </label>
    </div>

    <div class="row">
      <label class="d-inline mx-10 form-control-label">
        Announcement picture (recommended size 1000x560/less than 1 MB)
      </label>
    </div>
    <div class="form-group">
      <div class="d-block wrapper-image-preview">
        <img
          class="img img-fluid image-preview"
          v-if="picture"
          :src="picture"
        />
      </div>
      <input
        type="file"
        accept="image/*"
        class="form-control"
        @change="uploadImage"
        :disabled="disabled"
      />
    </div>

    <div class="row">
      <div class="form-group col-md-6">
        <h5>
          Line Message
          <span class="small light"> (maximum 40 characters) </span>
        </h5>
        <input
          type="text"
          class="form-control"
          ref="line_msg"
          v-model="lot.line_msg"
          :disabled="disabled"
          @change="update('line_msg')"
        />
      </div>

      <div class="form-group col-md-6">
        <h5>URL</h5>
        <input
          type="text"
          class="form-control"
          ref="url_link"
          v-model="lot.url_link"
          :disabled="disabled"
          @change="update('url_link')"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <h5>Select notification time</h5>
        <div class="form-group">
          <div class="form-group col-md-8">
            <label class="d-inline mx-10">#1 Date Time</label>
            <div class="d-inline mx-10">
              <DatePicker
                type="datetime"
                placeholder="n/a"
                :disabled="!canEditExpiration"
                :disabled-date="disabledBetweenStartEnd"
                v-model="lot.notification_time1"
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm:ss"
                :show-time-panel="showTimePanelnotification_time1"
                @input="handleOpenChange('notification_time1')"
                @clear="handleOpenChange('notification_time1')"
                :confirm="true"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="
                      showTimePanelnotification_time1 =
                        !showTimePanelnotification_time1
                    "
                  >
                    {{
                      showTimePanelnotification_time1
                        ? "select date"
                        : "select time"
                    }}
                  </button>
                </template>
              </DatePicker>
            </div>
          </div>
          <div class="form-group col-md-8">
            <label class="d-inline mx-10">#2 Date Time</label>
            <div class="d-inline mx-10">
              <DatePicker
                type="datetime"
                placeholder="n/a"
                :disabled="!canEditExpiration"
                v-model="lot.notification_time2"
                :disabled-date="disabledBetweenStartEnd"
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm:ss"
                :show-time-panel="showTimePanelnotification_time2"
                @input="handleOpenChange('notification_time2')"
                @clear="handleOpenChange('notification_time2')"
                :confirm="true"
                ><template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="
                      showTimePanelnotification_time2 =
                        !showTimePanelnotification_time2
                    "
                  >
                    {{
                      showTimePanelnotification_time2
                        ? "select date"
                        : "select time"
                    }}
                  </button>
                </template>
              </DatePicker>
            </div>
          </div>
          <div class="form-group col-md-8">
            <label class="d-inline mx-10">#3 Date Time</label>
            <div class="d-inline mx-10">
              <DatePicker
                type="datetime"
                placeholder="n/a"
                :disabled="!canEditExpiration"
                v-model="lot.notification_time3"
                :disabled-date="disabledBetweenStartEnd"
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm:ss"
                :show-time-panel="showTimePanelnotification_time3"
                @input="handleOpenChange('notification_time3')"
                @clear="handleOpenChange('notification_time3')"
                :confirm="true"
                ><template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="
                      showTimePanelnotification_time3 =
                        !showTimePanelnotification_time3
                    "
                  >
                    {{
                      showTimePanelnotification_time3
                        ? "select date"
                        : "select time"
                    }}
                  </button>
                </template>
              </DatePicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { prizeService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["lot"],

  components: {
    DatePicker,
  },

  data() {
    return {
      id: null,
      file: null,
      fileImage: null,
      oldValue: {},
      today: new Date(),
      bodyDateTime: {},
      showTimePanelnotification_time1: false,
      showTimePanelnotification_time2: false,
      showTimePanelnotification_time3: false,
    };
  },

  computed: {
    picture() {
      return this.lot.announcement_picture;
    },

    disabled() {
      return this.lot.status != "draft";
    },

    canEditExpiration() {
      return ["draft"].includes(this.lot.status);
    },
  },

  methods: {
    handleOpenChange(field) {
      this.showTimePanelnotification_time1 = false;
      this.showTimePanelnotification_time2 = false;
      this.showTimePanelnotification_time3 = false;
      if (
        this.lot[field] == "0000-00-00 00:00:00" ||
        this.lot[field] == "null"
      ) {
        this.lot[field] = null;
      }
      this.updateDateTime(field);
    },

    disabledBetweenStartEnd(date) {
      return (
        moment(date) < moment(this.lot.issue_date).subtract(1, "days") ||
        moment(date) > moment(this.lot.expire_date)
      );
    },

    async uploadImage(e) {
      const file = e.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        e.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;

        const form = new FormData();
        form.append("announcement_picture", file);

        await prizeService.uploadPicture(this.lot.id, form);

        this.$emit("updated");
        this.$notify(`Announcement picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload announcement picture error.`);
      }
    },

    async isFormValid(field) {
      switch (field) {
        case "line_msg":
          if (!this.lot.line_msg) {
            await this.$dialogs.alert("กรุณากรอก Line messages ให้ถูกต้อง");
            this.$refs.line_msg.focus();
            this.lot.line_msg = this.oldValue.line_msg;
            return false;
          }

          if (this.lot.line_msg.length > 40) {
            await this.$dialogs.alert(
              "กรุณากรอก Line messages สูงสุดไม่เกิน 40 ตัวอักษร"
            );
            this.$refs.line_msg.focus();
            this.lot.line_msg = this.oldValue.line_msg;
            return false;
          }
          break;

        case "notification_time1":
          if (this.isBetween(this.lot.notification_time1)) {
            await this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );

            this.lot.notification_time1 = this.oldValue.notification_time1;
            return false;
          }
          break;

        case "notification_time2":
          if (this.isBetween(this.lot.notification_time2)) {
            await this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            console.log(this.lot.notification_time2);
            this.lot.notification_time2 = this.oldValue.notification_time2;
            return false;
          }
          break;

        case "notification_time3":
          if (this.isBetween(this.lot.notification_time3)) {
            await this.$dialogs.alert(
              "ต้องอยู่ภายในระหว่างวันเวลาเริ่มต้นและวันเวลาสิ้นสุด"
            );
            this.lot.notification_time3 = this.oldValue.notification_time3;
            return false;
          }
          break;

        default:
          break;
      }

      return true;
    },

    async isFormValidAfterGoing(field) {
      // notification_time1 not today if status ongoing,scheduled

      switch (field) {
        case "notification_time1":
          if (moment(this.lot.notification_time1) <= moment(this.today)) {
            await this.$dialogs.alert(
              "สามารถใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.lot.notification_time1 = this.oldValue.notification_time1;
            return false;
          }
          break;

        case "notification_time2":
          if (moment(this.lot.notification_time2) <= moment(this.today)) {
            await this.$dialogs.alert(
              "สามารถใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.lot.notification_time2 = this.oldValue.notification_time2;
            return false;
          }
          break;

        case "notification_time3":
          if (moment(this.lot.notification_time3) <= moment(this.today)) {
            await this.$dialogs.alert(
              "สามารถใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.lot.notification_time3 = this.oldValue.notification_time3;
            return false;
          }
          break;

        default:
          break;
      }

      return true;
    },

    isBetween(date) {
      return (
        moment(date) < moment(this.lot.issue_date) ||
        moment(date) > moment(this.lot.expire_date)
      );
    },

    setBodyDateTime(field) {
      switch (field) {
        case "notification_time1":
          this.bodyDateTime = {
            notification_time1: this.lot.notification_time1,
          };
          break;
        case "notification_time2":
          this.bodyDateTime = {
            notification_time2: this.lot.notification_time2,
          };
          break;
        case "notification_time3":
          this.bodyDateTime = {
            notification_time3: this.lot.notification_time3,
          };
          break;

        default:
          return;
      }
    },

    async updateDateTime(field) {
      const formValid = await this.isFormValid(field);
      if (!formValid) return;

      if (this.lot.status == "ongoing") {
        const formValidAfterGoing = await this.isFormValidAfterGoing(field);
        if (!formValidAfterGoing) return;
      }

      this.setBodyDateTime(field);

      try {
        await prizeService.updateLot(this.lot.id, this.bodyDateTime);
        this.$notify(`Line notification update.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    async update(field) {
      try {
        const formValid = await this.isFormValid(field);
        if (!formValid) return;

        if (this.lot.status == "ongoing") {
          const formValidAfterGoing = await this.isFormValidAfterGoing(field);
          if (!formValidAfterGoing) return;
        }

        const form = new FormData();
        form.set(field, this.lot[field]);

        const body = Object.fromEntries(form);

        await prizeService.updateLot(this.lot.id, body);

        this.$notify(`Line notification update.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },

  mounted() {
    this.oldValue = Object.assign({}, this.lot);
    this.id = this.lot.id;
  },
};
</script>

<style lang="scss" scoped>
.wrapper-image-preview {
  text-align: center;
  width: 420px;
  min-height: 300px;
  background-color: #efefef;
}

.image-preview {
  padding: 12px;
  width: 420px;
  min-height: 300px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  width: 420px;
  max-height: 300px;
}
</style>

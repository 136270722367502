var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _vm._v(" Start "),
          ]),
          _c(
            "div",
            { staticClass: "d-inline mx-10" },
            [
              _c("DatePicker", {
                ref: "start_date",
                attrs: {
                  "width-200px": "",
                  type: "datetime",
                  confirm: true,
                  placeholder: "n/a",
                  format: "DD/MM/YYYY HH:mm",
                  "value-type": "YYYY-MM-DD HH:mm",
                  disabled: _vm.disabled,
                  "disabled-date": _vm.disabledStartDate,
                  "disabled-time": _vm.disabledStartTime,
                },
                on: {
                  change: function ($event) {
                    return _vm.update("start_date")
                  },
                },
                model: {
                  value: _vm.campaign.start_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "start_date", $$v)
                  },
                  expression: "campaign.start_date",
                },
              }),
            ],
            1
          ),
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _vm._v(" End "),
          ]),
          _c(
            "div",
            { staticClass: "d-inline mx-10" },
            [
              _c("DatePicker", {
                ref: "end_date",
                attrs: {
                  "width-200px": "",
                  type: "datetime",
                  confirm: true,
                  placeholder: "n/a",
                  format: "DD/MM/YYYY HH:mm",
                  "value-type": "YYYY-MM-DD HH:mm",
                  disabled: _vm.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.update("end_date")
                  },
                },
                model: {
                  value: _vm.campaign.end_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "end_date", $$v)
                  },
                  expression: "campaign.end_date",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Campaign Duration")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
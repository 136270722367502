import qs from "qs";
import HttpRequest from "./request";

export class StaffEngagementProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/ee`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async createCampaign(body) {
    const { data } = await this.create("/", body);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async updateCampaignInfo(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async uploadPicture(id, body) {
    const { data } = await this.upload(`/${id}/upload`, body);
    return data;
  }

  async addRound(id) {
    const { data } = await this.create(`/${id}/round`);
    return data;
  }

  async editRound(campaignId, roundId, body) {
    const { data } = await this.update(`/${campaignId}/round/${roundId}`, body);
    return data;
  }

  async removeRound(campaignId, roundId) {
    const { data } = await this.delete(`/${campaignId}/round/${roundId}`);
    return data;
  }

  async addQuestion(campaignId, roundId) {
    const { data } = await this.create(
      `/${campaignId}/round/${roundId}/question`
    );
    return data;
  }

  async getQuestionById(campaignId, roundId, questionId) {
    const { data } = await this.get(
      `/${campaignId}/round/${roundId}/question/${questionId}`
    );
    return data;
  }

  async editQuestion(campaignId, roundId, questionId, body) {
    const { data } = await this.update(
      `/${campaignId}/round/${roundId}/question/${questionId}`,
      body
    );
    return data;
  }

  async removeQuestion(campaignId, roundId, questionId) {
    const { data } = await this.delete(
      `/${campaignId}/round/${roundId}/question/${questionId}`
    );
    return data;
  }

  async addChoice(campaignId, roundId, questionId) {
    const { data } = await this.create(
      `/${campaignId}/round/${roundId}/question/${questionId}/choice`
    );
    return data;
  }

  async editChoice(campaignId, roundId, questionId, choiceId, body) {
    const { data } = await this.update(
      `/${campaignId}/round/${roundId}/question/${questionId}/choice/${choiceId}`,
      body
    );
    return data;
  }

  async removeChoice(campaignId, roundId, questionId, choiceId) {
    const { data } = await this.delete(
      `/${campaignId}/round/${roundId}/question/${questionId}/choice/${choiceId}`
    );
    return data;
  }

  async uploadRankAndReward(campaignId, roundId, form) {
    const { data } = await this.upload(
      `/${campaignId}/round/${roundId}/upload`,
      form
    );
    return data;
  }

  async getParticipation(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/participation${queryString}`);
    return data;
  }

  async downloadParticipation(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/participation/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/transaction_log${queryString}`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/transaction_log/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }
}

<template>
  <div class="d-block">
    <div class="form-group">
      <label>Budget Limit </label>
      <input
        type="number"
        min="0"
        max="10000000"
        @change="update"
        name="budget_limit"
        class="form-control"
        :disabled="disabled"
        v-model="budget.budget_limit"
      />
      <p>Baht</p>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: `Update Budget Limit error.`,
        success: `Update Budget Limit success.`,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/form.styles.scss";
</style>

<template>
  <div id="progress-info" class="container-fluid" v-if="campaign">
    <div class="row">
      <div class="col">
        <h4><span>Progress Update -</span> {{ campaign.id }}</h4>
      </div>
      <div class="col">
        <h4>{{ type | toUpperCase }}</h4>
      </div>
      <div class="col">
        <h4><span>Status</span> {{ campaign.status | toUpperCase }}</h4>
      </div>
      <div class="col">
        <h4>
          <span>SF Point Granted</span>
          {{ info.point_granted | numberWithCommas }}
        </h4>
      </div>
      <div class="col" v-if="shouldDisplayLastUpdate">
        <h4>
          <span>Last Update:</span>
          {{ lastUploadTime }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],
  data() {
    return {
      info: {},
    };
  },
  computed: {
    type() {
      return this.campaign.type == "team" ? "TEAM BARCODE" : this.campaign.type;
    },
    lastUploadTime() {
      const time = moment(
        this.campaign.last_progress_upload,
        "YYYY-MM-DD HH:mm:ss"
      );
      return time.isValid() ? time.format("DD/MM/YYYY HH:mm น.") : "-";
    },
    shouldDisplayLastUpdate() {
      return [
        "monthly",
        "monthlyshort",
        "weekly",
        "task",
        "leaderboard",
        "stamp",
      ].includes(this.campaign.type);
    },
  },
  methods: {
    async fetchProgressInfo() {
      const id = this.$route.params.id;
      this.info = await progressBarService.getProgressUpdateInfo(id);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$on("IMPORT_PROGRESS_UPDATED", async (_) => {
        await this.fetchProgressInfo();
      });

      this.$root.$on("hook:beforeDestroy", () => {
        this.$root.$off("IMPORT_PROGRESS_UPDATED");
      });
    });
  },
  async created() {
    await this.fetchProgressInfo();
  },
};
</script>

<style lang="scss" scoped>
#progress-info {
  margin: 1em;

  h4 {
    margin: 0;
    padding: 0.5em;
    padding-top: 1em;

    span {
      font-weight: 300;
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm._m(1),
          _vm._l(_vm.weekday, function (ref, index) {
            var name = ref.name
            var text = ref.text
            return _c("div", { key: index, staticClass: "w80" }, [
              _c("label", [_vm._v(_vm._s(text))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.gameConfig[name],
                    expression: "gameConfig[name]",
                  },
                ],
                ref: "" + name,
                refInFor: true,
                staticClass: "center form-control",
                attrs: {
                  type: "number",
                  maxlength: "3",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.gameConfig[name] },
                on: {
                  change: function ($event) {
                    return _vm.update("" + name)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.gameConfig, name, $event.target.value)
                  },
                },
              }),
            ])
          }),
        ],
        2
      ),
      _c("hr"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Game Configuration")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-con" }, [
      _c("label", [_vm._v("No. play per day")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./View.vue?vue&type=template&id=bacc570c&"
import script from "./View.vue?vue&type=script&lang=js&"
export * from "./View.vue?vue&type=script&lang=js&"
import style0 from "./View.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bacc570c')) {
      api.createRecord('bacc570c', component.options)
    } else {
      api.reload('bacc570c', component.options)
    }
    module.hot.accept("./View.vue?vue&type=template&id=bacc570c&", function () {
      api.rerender('bacc570c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/history/View.vue"
export default component.exports
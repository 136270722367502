var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group col-12" }, [
    _c("div", { staticClass: "form-group-row" }, [
      _vm._m(0),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.theme.claim_expiration,
            expression: "theme.claim_expiration",
          },
        ],
        staticClass: "form-control",
        attrs: {
          min: "1",
          max: "10000",
          type: "number",
          disabled: _vm.disabled,
          name: "claim_expiration",
        },
        domProps: { value: _vm.theme.claim_expiration },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.theme, "claim_expiration", $event.target.value)
          },
        },
      }),
      _c("span", [_vm._v("days after notifying (end of day)")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("h4", { staticClass: "mx-5" }, [_vm._v("Claim Expiration")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
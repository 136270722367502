var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "label",
      { staticClass: "my-10 d-none", attrs: { for: "display" } },
      [
        _c("toggle-button", {
          attrs: {
            id: "display",
            value: false,
            color: "#3e8ef7",
            sync: true,
            labels: true,
          },
          model: {
            value: _vm.display,
            callback: function ($$v) {
              _vm.display = $$v
            },
            expression: "display",
          },
        }),
        _c("span", { staticClass: "mx-10" }, [
          _vm._v(_vm._s(_vm.display ? "ซ่อน" : "แสดง") + " validate"),
        ]),
      ],
      1
    ),
    _c(
      "small",
      { staticClass: "form-text text-muted", attrs: { id: "emailHelp" } },
      [
        _vm.display
          ? _c("ul", { staticClass: "list-icons mb-0" }, [
              _c(
                "li",
                {
                  class: {
                    "text-success": _vm.$v.usergroup.group_code.required,
                  },
                },
                [
                  !_vm.$v.usergroup.group_code.required
                    ? _c("i", {
                        staticClass: "wb-close",
                        attrs: { "aria-hidden": "true" },
                      })
                    : _c("i", {
                        staticClass: "wb-check",
                        attrs: { "aria-hidden": "true" },
                      }),
                  _vm._v(" จำเป็นต้องระบุ Group Code "),
                ]
              ),
              _c(
                "li",
                {
                  class: {
                    "text-success": _vm.$v.usergroup.group_code.maxLength,
                  },
                },
                [
                  !_vm.$v.usergroup.group_code.maxLength
                    ? _c("i", {
                        staticClass: "wb-close",
                        attrs: { "aria-hidden": "true" },
                      })
                    : _c("i", {
                        staticClass: "wb-check",
                        attrs: { "aria-hidden": "true" },
                      }),
                  _vm._v(
                    " ต้องระบุไม่เกิน " +
                      _vm._s(
                        _vm.$v.usergroup.group_code.$params.maxLength.max
                      ) +
                      " ตัวอักษร "
                  ),
                ]
              ),
              _c(
                "li",
                {
                  class: {
                    "text-success":
                      _vm.$v.usergroup.group_code
                        .alphanumericExceptCommaAndSpace,
                  },
                },
                [
                  !_vm.$v.usergroup.group_code.alphanumericExceptCommaAndSpace
                    ? _c("i", {
                        staticClass: "wb-close",
                        attrs: { "aria-hidden": "true" },
                      })
                    : _c("i", {
                        staticClass: "wb-check",
                        attrs: { "aria-hidden": "true" },
                      }),
                  _vm._v(
                    " ใส่ค่าได้เฉพาะ ตัวเลขและตัวอักษรภาษาอังกฤษเท่านั้น !! "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
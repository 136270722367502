var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.leaderboard
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "ml-10" }, [
          _c("h5", [_vm._v("Rule and Free text")]),
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text line 1 order")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line1_order,
                        expression: "leaderboard.text4_line1_order",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line1_order",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line1_order },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line1_order",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Text reward")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line1_reward,
                        expression: "leaderboard.text4_line1_reward",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line1_reward",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line1_reward },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line1_reward",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text line 2 order")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line2_order,
                        expression: "leaderboard.text4_line2_order",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line2_order",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line2_order },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line2_order",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Text reward")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line2_reward,
                        expression: "leaderboard.text4_line2_reward",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line2_reward",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line2_reward },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line2_reward",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text line 3 order")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line3_order,
                        expression: "leaderboard.text4_line3_order",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line3_order",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line3_order },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line3_order",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Text reward")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line3_reward,
                        expression: "leaderboard.text4_line3_reward",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line3_reward",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line3_reward },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line3_reward",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text line 4 order")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line4_order,
                        expression: "leaderboard.text4_line4_order",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line4_order",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line4_order },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line4_order",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Text reward")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line4_reward,
                        expression: "leaderboard.text4_line4_reward",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line4_reward",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line4_reward },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line4_reward",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text line 5 order")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line5_order,
                        expression: "leaderboard.text4_line5_order",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line5_order",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line5_order },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line5_order",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Text reward")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.leaderboard.text4_line5_reward,
                        expression: "leaderboard.text4_line5_reward",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text4_line5_reward",
                      "width-250px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.leaderboard.text4_line5_reward },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.leaderboard,
                          "text4_line5_reward",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v("Text area")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
const messages = {
  ER_EMPRT_name: "กรุณาใส่ค่าใน Budget Name",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("name") > -1:
      message = messages.ER_EMPRT_name;
      break;

    default:
      message = error.replace(/Error:/g, "").trim();
      break;
  }
  return message;
};

export const campaignTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Campaign Name",
    field: "name",
  },
  {
    label: "Campaign Summary",
    field: "summary",
  },
  {
    label: "Start Date",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End Date",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Duration",
    field: "duration",
    type: "number",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

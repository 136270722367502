var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c(
        "div",
        { staticClass: "container-fluid", attrs: { id: "pool-status-info" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "top-row" }, [
                _c("h4", [_vm._v("Pool Status - " + _vm._s(_vm.info.id))]),
                _c("div", { staticClass: "status-type" }, [
                  _c("h4", [
                    _vm._v(
                      "Status - " +
                        _vm._s(_vm._f("capitalize")(_vm.info.status))
                    ),
                  ]),
                  _c("h4", [
                    _vm._v(
                      "Type - " + _vm._s(_vm._f("capitalize")(_vm.info.type))
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "info-row" }, [
                _c("label", [_vm._v("Vendor")]),
                _c("div", [_vm._v(_vm._s(_vm.info.vendor))]),
              ]),
              _c("div", { staticClass: "info-row" }, [
                _c("label", [_vm._v("Prize Name")]),
                _c("div", [_vm._v(_vm._s(_vm.info.prize_name))]),
              ]),
              _c("div", { staticClass: "info-row" }, [
                _c("label", [_vm._v("Prize Details")]),
                _c("div", {
                  attrs: { "prize-detail": "" },
                  domProps: { innerHTML: _vm._s(_vm.info.description) },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "counter-info" }, [
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Total")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("numberWithCommas")(_vm.info.count_total))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Used")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("numberWithCommas")(_vm.info.count_used))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Unusable")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberWithCommas")(_vm.info.count_unusable)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", { staticClass: "green" }, [_vm._v("Available")]),
                  _c("div", { staticClass: "green" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("numberWithCommas")(_vm.info.count_available)
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Reserved")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberWithCommas")(_vm.info.count_reserved)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Margin")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("numberWithCommas")(_vm.info.count_margin))
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import qs from "qs";
import HttpRequest from "./request";

export class QuestionProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_GAME}/ams/api/question`);
  }

  async getQuestion(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createQuestion(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editQuestion(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async editQuestionChoice(id, idChoice, body) {
    const { data } = await this.update(`/${id}/choice/${idChoice}`, body);
    return data;
  }
}

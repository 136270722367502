<template>
  <div class="panel panel-bordered mb-0">
    <div class="panel-heading">
      <h3 class="panel-title">Reward Detail</h3>
    </div>
    <div class="panel-body">
      <ckeditor v-model="value" />
    </div>
    <div class="panel-footer">
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="close"
        >
          <i class="icon md-close" aria-hidden="true"></i>
          Cancel
        </button>
        <button
          type="button"
          @click="confirm"
          class="btn btn-round btn-primary"
        >
          <i class="icon md-check" aria-hidden="true"></i> Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RewardDetailEditor",

  props: ["detail", "name"],

  data() {
    return {
      value: "",
    };
  },

  methods: {
    close() {
      this.value = "";
      this.$modal.hide(this.name);
    },

    confirm() {
      this.$emit("confirm", this.value);
      this.$modal.hide(this.name, { value: this.value });
    },
  },

  mounted() {
    this.value = this.detail;
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.panel-body {
  padding: 0px;
}

.panel-heading {
  border-bottom: 0px;
}
.panel-footer {
  border-top: 0px;
}
</style>

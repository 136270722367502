<template>
  <div>
    <label class="mx-5">
      <span class="light"> recommended size 300 x 300</span>
    </label>

    <div class="form-group">
      <div class="d-block">
        <img
          class="img img-fluid image-preview"
          v-if="picture"
          :src="picture"
        />
        <div v-else class="img-fluid image-preview"></div>
      </div>
      <input
        type="file"
        accept="image/*"
        class="form-control"
        @change="uploadImage"
        ref="button_picture"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import { weeklyLoginService } from "@/api/client";

export default {
  props: ["prize", "campaign"],

  data() {
    return {
      file: null,
    };
  },
  computed: {
    picture() {
      return this.prize.prize_picture_url;
    },

    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();

        const { id } = this.campaign;
        form.append("prize_picture_url", file);

        await weeklyLoginService.uploadPrizeImage(id, this.prize.id, form);

        this.$emit("updated");
        this.$notify(`Prize order #${this.prize.id} picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(
              `Upload Prize order #${this.prize.id} picture error.`
            );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  width: 300px;
  max-width: 300px;
}
</style>

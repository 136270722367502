<template>
  <section v-if="item" class="pool-item" :class="cardStatusClass">
    <div class="body">
      <div class="info">
        <p class="name">{{ item.id }} - {{ item.name }}</p>
      </div>
    </div>
    <div class="footer">{{ footerText }}</div>
  </section>
</template>

<script>
export default {
  name: "PoolItem",

  props: ["item", "selected"],

  computed: {
    footerText() {
      return this.selected ? "Selected" : "Click to select";
    },

    cardStatusClass() {
      return this.selected ? { selected: true } : { deselected: true };
    },
  },
};
</script>

<style lang="scss" scoped>
.pool-item {
  cursor: pointer;
  margin-bottom: 1em;
  border-radius: 14px;
  border: 1px solid #ccc;

  filter: drop-shadow(0px 8px 8px rgba(98, 98, 98, 0.14));

  .panel-body {
    max-height: 400px;
    overflow-y: auto;
  }

  .body {

    .info {
      padding: 12px;
      
      .name {
        color: #212121;
        margin-bottom: 0.25em;
      }
    }
  }

  .footer {
    color: #fff;
    padding: 0.25em;
    font-weight: 700;
    text-align: center;
    background-color: #ccc;
    border-radius: 0px 0px 14px 14px;
  }
}

.pool-item.selected .footer {
  background-color: #0170c0;
}

.pool-item.deselected .footer {
  background-color: #ccc;
}
</style>

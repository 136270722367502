<template>
  <form class="form-horizontal" v-if="campaign">
    <div class="row">
      <div class="form-group col-md-6">
        <CampaignName :campaign="campaign" />
      </div>
      <div class="form-group col-md-3">
        <StatusDropdown :campaign="campaign" @updated="$emit('updated')" />
      </div>
    </div>
    <hr />

    <div class="row">
      <CampaignDuration :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <BannerImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <BackgroundImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <PeriodDuration :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <TextArea :campaign="campaign" />
    </div>
    <hr />
    <div class="row">
      <TableArea :campaign="campaign" />
    </div>
    <hr />
    <div class="row">
      <RanksAndRewards :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <ProgressUpload :campaign="campaign" @updated="$emit('updated')" />
    </div>
  </form>
</template>

<script>
import TextArea from "./components/TextArea.vue";
import TableArea from "./components/TableArea.vue";
import HeadLineImage from "./components/HeadLineImage.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import BannerImage from "./components/BackgroundImage.vue";
import BackgroundImage from "./components/PageBackgroundImage.vue";
import RanksAndRewards from "./components/RanksAndRewards.vue";
import CampaignDuration from "./components/CampaignDuration.vue";
import PeriodDuration from "./components/PeriodDuration.vue";
import ProgressUpload from "./components/ProgressUpload.vue";
import CampaignName from "@/views/progressbar/components/CampaignName.vue";

export default {
  name: "LeaderBoardForm",

  props: ["campaign"],

  components: {
    TextArea,
    TableArea,
    ProgressUpload,
    CampaignName,
    HeadLineImage,
    StatusDropdown,
    BannerImage,
    BackgroundImage,
    RanksAndRewards,
    CampaignDuration,
    PeriodDuration,
  },
};
</script>

<template>
  <div>
    <div class="page-header-actions">
      <div v-if="status.status == 'done' || !status.status">
        <div class="wrapper-upload-form">
          <label class="btn btn-round btn-upload mx-3" :for="'Bulk-upload'">
            <i class="fa fa-upload" aria-hidden="true"></i>
            Bulk update
          </label>

          <input
            type="file"
            @change="FileBulkUpload"
            class="input-select-file"
            id="Bulk-upload"
          />
        </div>
      </div>
      <div v-else-if="status.status == 'ready' || status.status == 'loading'">
        <label class="label-blue" :hidden="status.status == 'ready'">
          Verifying...
        </label>
        <div>
          <button
            type="button"
            class="btn btn-round btn-primary mx-10"
            @click="getResult"
            :disabled="status.status == 'loading'"
          >
            Commit
          </button>
        </div>
      </div>
      <div v-else-if="status.status == 'running'">
        <label class="label-blue"> Updating... </label>
        <div>
          <button
            type="button"
            class="btn btn-round btn-primary mx-10"
            disabled
          >
            Commit
          </button>
        </div>
      </div>
      <div class="form-group">
        <!-- <a @click="downloadTemplate" href="javascript:;"></a> -->

        <a :href="downloadExampleLink"
          ><i class="fa fa-file-excel-o" aria-hidden="true"></i> Download
          template</a
        >
      </div>
    </div>
    <modal
      name="try-upload-team-target-modal"
      :width="600"
      height="auto"
      :clickToClose="false"
    >
      <TryUploadUserTargetModal
        :result="result"
        @confirm="confirmUpload"
        name="try-upload-team-target-modal"
      />
    </modal>
  </div>
</template>
<script>
import TryUploadUserTargetModal from "./TryUploadUserTargetModal.vue";
import { ecouponService } from "@/api/client";
// import axios from "axios";
export default {
  name: "TeamTargetAndRewardValues",
  data() {
    return {
      campaign: [],
      result: null,
      status: [],
      file: null,
      pullingId: null,
    };
  },
  beforeDestroy() {
    this.stopPullingStatus();
  },
  created() {
    this.getUploadStatus();
  },
  watch: {
    status(n) {
      if (n.status == "loading" && this.pullingId == null) {
        this.startPullingStatus();
        return;
      }

      if (n.status == "ready") {
        this.stopPullingStatus();
        this.result = n;
        return;
      }

      if (n.status == "running" && this.pullingId == null) {
        this.startPullingStatus();
        return;
      }

      if (n.status == "done") {
        this.stopPullingStatus();
        this.status.status = null;
        return;
      }
    },
  },
  components: { TryUploadUserTargetModal },
  computed: {
    downloadExampleLink() {
      return (
        process.env.VUE_APP_WALLET_UPLOAD_DIR +
        `/example/heirarchy_template.xlsx?ts=${new Date().getTime()}`
      );
    },
  },
  methods: {
    async confirmUpload(bool) {
      try {
        await ecouponService.uploadBulkConfrim(bool);
        bool == "y"
          ? this.$notify("Confirm success")
          : this.$notify("Cancel upload");
        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },
    getResult() {
      this.$modal.show(`try-upload-team-target-modal`);
    },
    cancelProcess() {},
    async FileBulkUpload(event) {
      this.file = event.target.files[0];
      event.target.value = null;
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("file", this.file);
        const { task_id } = await ecouponService.uploadBulkVSM(form);
        this.taskID = task_id;
        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Bulk error");
      }
    },
    async getUploadStatus() {
      this.status = await ecouponService.uploadStatus();
    },
    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 3000);
    },
    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select-file {
  width: 0px;
  visibility: hidden;
}

.btn-upload {
  font-weight: 300;
  border-radius: 0.5em;
  background-color: #e5eaec;
  &[disabled="disabled"] {
    color: #c1c1c1;
    cursor: not-allowed;
    background-color: #e5eaec90;
  }
}
</style>

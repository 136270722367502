import qs from "qs";
import HttpRequest from "./request";

export class LuckyDrawProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_WALLET_API_URL}/ld/api`);
  }

  async getCampaignList(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    const { data } = await this.get(`/campaign${queryString}`);
    return data;
  }

  async createCampaign(body) {
    const { data } = await this.create("/campaign", body);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/campaign/${id}`);
    return data;
  }

  async updateCampaignInfo(campaignId, body) {
    const { data } = await this.update(`/campaign/${campaignId}`, body);
    return data;
  }

  async updatePrizeItem(campaignId, body) {
    const { data } = await this.create(
      `/campaign/update_prize/${campaignId}`,
      body
    );
    return data;
  }

  async getTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/ld_campaignreport${queryString}`);
    return data;
  }

  async downloadTransactionLog(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    return await this.axiosInstance.get(
      `/ld_campaignreport/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async fileUpload(form) {
    const { data } = await this.upload(`/campaign/picture_upload`, form);
    return data;
  }

  async prizeUpload(form) {
    const { data } = await this.upload(`/campaign/prize_upload`, form);
    return data;
  }

  async getLUMUserGroup() {
    const { data } = await this.get(`/campaign/getLUMGroup`);
    return data;
  }

  async getActiveThemeList() {
    const { data } = await this.get(`/campaign/getActiveThemeList`);
    return data;
  }
}

const messages = {
  ER_EMPRT_name: "กรุณาใส่ค่าใน campaign Name",
  ER_EMPRT_no_prize: "ค่า No Prize เป็นค่าว่างไม่ได้",
  ER_EMPRT_minimum_points: "ค่า Minimum Points เป็นค่าว่างไม่ได้",
  ER_Date_start: "Start Date ต้องเป็นเวลาในอนาคต",
  ER_EMPRT_start_date: "ค่า Start Date เป็นค่าว่างไม่ได้",
  ER_EMPRT_end_date: "ค่า End Date เป็นค่าว่างไม่ได้",
  ER_Status_product: "Product Item ที่ถูกเลือกไม่ได้อยู่ในสถานะ PUBLISHED",
  ER_Exits_product: "Product Item ไม่ได้ถูกเลือก กรุณาเลือก",
  ER_Status_usergroup: "User Group ที่ถูกเลือกไม่ได้อยู่ในสถานะ ACTIVE",
};

export const responseErrorMapping = function (error) {
  let message = error;

  switch (true) {
    case error.search("name") > -1:
      message = messages.ER_EMPRT_name;
      break;

    case error.search("no_prize cannot be empty") > -1:
      message = messages.ER_EMPRT_no_prize;
      break;

    case error.search("minimum_points cannot be empty") > -1:
      message = messages.ER_EMPRT_minimum_points;
      break;

    case error.search("start_date cannot be empty") > -1:
      message = messages.ER_EMPRT_start_date;
      break;

    case error.search("end_date cannot be empty") > -1:
      message = messages.ER_EMPRT_end_date;
      break;

    case error.search("product_id is not published") > -1:
      message = messages.ER_Status_product;
      break;

    case error.search("product_id not exists") > -1:
      message = messages.ER_Exits_product;
      break;

    case error.search("group_code is not active") > -1:
      message = messages.ER_Status_usergroup;
      break;
    case error.search("period overlap with campaigns") > -1:
      const duplicate_with_campaign = error
        .replace(/Error:|period overlap with campaigns/g, "")
        .replace("period overlap with campaigns")
        .trim();
      message = `User Group ซ้อนทับกันที่ Campaigns ${duplicate_with_campaign}`;
      break;
  }
  return message;
};

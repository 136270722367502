var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.monthlyshort && _vm.status != null
    ? _c(
        "div",
        { staticClass: "col-md-10" },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "sku-box-wrapper" },
            [
              _c("div", { staticClass: "sku-box-action" }, [
                _c("p", [_vm._v("Product Codes")]),
                _c("div", { staticClass: "ryo-switcher" }, [
                  _c("p", { staticClass: "my-0 d-inline" }, [_vm._v(" RYO ")]),
                  _c("label", { staticClass: "switch m0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.monthlyshort.product_code_type,
                          expression: "monthlyshort.product_code_type",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        "true-value": "non_ryo",
                        "false-value": "ryo",
                        disabled: !_vm.canChangeProductCodes,
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.monthlyshort.product_code_type
                        )
                          ? _vm._i(_vm.monthlyshort.product_code_type, null) >
                            -1
                          : _vm._q(
                              _vm.monthlyshort.product_code_type,
                              "non_ryo"
                            ),
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.monthlyshort.product_code_type,
                              $$el = $event.target,
                              $$c = $$el.checked ? "non_ryo" : "ryo"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.monthlyshort,
                                    "product_code_type",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.monthlyshort,
                                    "product_code_type",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.monthlyshort,
                                "product_code_type",
                                $$c
                              )
                            }
                          },
                          _vm.updateProductCodeTypes,
                        ],
                      },
                    }),
                    _c("span", { staticClass: "slider round" }),
                  ]),
                  _c("p", { staticClass: "mx-10 my-0 d-inline" }, [
                    _vm._v(" Non-RYO "),
                  ]),
                ]),
                _vm.action == "ready" && _vm.status.active == 0
                  ? _c("div", { staticClass: "wrapper-upload-form" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-round",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: {
                            click: function ($event) {
                              return _vm.setAction("editing")
                            },
                          },
                        },
                        [_vm._v(" Edit ")]
                      ),
                    ])
                  : _vm.action == "editing" && _vm.status.active == 0
                  ? _c("div", { staticClass: "wrapper-upload-form" }, [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-round btn-primary mx-10",
                            attrs: { type: "button", disabled: _vm.disabled },
                            on: { click: _vm.handleVerify },
                          },
                          [_vm._v(" Verify ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-round btn-default",
                            attrs: { type: "button", disabled: _vm.disabled },
                            on: { click: _vm.handleCancel },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                      ]),
                    ])
                  : _vm.status.active == 1
                  ? _c("div", { staticClass: "wrapper-upload-form" }, [
                      _vm.status.state == "verify"
                        ? _c("label", { staticClass: "label-blue" }, [
                            _vm._v(" Verifying change "),
                          ])
                        : _vm.status.state == "done-verify"
                        ? _c("label", { staticClass: "label-blue" }, [
                            _vm._v(" Click to commit change "),
                          ])
                        : _vm._e(),
                      _vm.status.state == "verify"
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-round btn-primary mx-10",
                                attrs: { type: "button", disabled: "" },
                              },
                              [_vm._v(" Commit ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-round btn-default",
                                attrs: { type: "button", disabled: "" },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ])
                        : _vm.status.state == "done-verify"
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-round btn-primary mx-10",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.disabled,
                                },
                                on: { click: _vm.getResult },
                              },
                              [_vm._v(" Commit ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-round btn-default",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.disabled,
                                },
                                on: { click: _vm.handleCancel },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "Selectize",
                {
                  attrs: {
                    multiple: "",
                    disabled: !_vm.canEdit,
                    settings: _vm.selectizeSetting,
                  },
                  model: {
                    value: _vm.skuSelected,
                    callback: function ($$v) {
                      _vm.skuSelected = $$v
                    },
                    expression: "skuSelected",
                  },
                },
                _vm._l(_vm.skus, function (sku, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: sku } },
                    [_vm._v(" " + _vm._s(sku) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "try-update-sku-modal",
                width: 900,
                height: "auto",
              },
            },
            [
              _c("TryUploadUserTargetModal", {
                attrs: {
                  campaign: _vm.campaign,
                  result: _vm.result,
                  name: "try-update-sku-modal",
                },
                on: { confirm: _vm.confirmUpdate },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Target SKUs (counted toward progress)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
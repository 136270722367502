export default {
  path: "/illicit-barcode",
  name: "IllicitBarcodeIndex",
  component: () => import("@/views/illicit-barcode/Index.vue"),
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaign",
      name: "illicit-barcode-campaign",
      component: () => import("@/views/illicit-barcode/campaign/List.vue"),
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "campaign/edit/:id",
      name: "illicit-barcode-campaign-edit",
      component: () => import("@/views/illicit-barcode/campaign/Edit.vue"),
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "expiration-campaign",
      name: "illicit-barcode-expiration-campaign",
      component: () =>
        import("@/views/illicit-barcode/expiration-campaign/List.vue"),
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "expiration-campaign/edit/:id",
      name: "illicit-barcode-expiration-campaign-edit",
      component: () =>
        import("@/views/illicit-barcode/expiration-campaign/Edit.vue"),
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "transaction-log",
      name: "illicit-barcode-transaction-log",
      component: () =>
        import("@/views/illicit-barcode/transaction-log/List.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "list",
      name: "illicit-barcode-list",
      component: () => import("@/views/illicit-barcode/list/List.vue"),
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
  ],
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("Access Setting")]),
        _c("breadcrumb", { attrs: { name: "E_COUPON_ACCESS_SETTING" } }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
          },
          [
            _c("div", { staticClass: "panel panel-bordered" }, [
              _c("div", { staticClass: "panel-body" }, [
                _c(
                  "form",
                  { staticClass: "form-horizontal" },
                  [
                    _vm._m(0),
                    _vm._m(1),
                    _c(
                      "Selectize",
                      {
                        ref: "groupCode",
                        attrs: {
                          multiple: "",
                          settings: _vm.groupSelectizeSettingGT,
                        },
                        model: {
                          value: _vm.groupGT,
                          callback: function ($$v) {
                            _vm.groupGT = $$v
                          },
                          expression: "groupGT",
                        },
                      },
                      _vm._l(_vm.groupActiveGT, function (group) {
                        return _c(
                          "option",
                          { key: group.id, domProps: { value: group.id } },
                          [
                            _vm._v(
                              _vm._s(" " + group.id + " - " + group.group_code)
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._m(2),
                    _vm._m(3),
                    _c(
                      "Selectize",
                      {
                        ref: "groupCodeWS",
                        attrs: {
                          multiple: "",
                          settings: _vm.groupSelectizeSettingWS,
                        },
                        model: {
                          value: _vm.groupWS,
                          callback: function ($$v) {
                            _vm.groupWS = $$v
                          },
                          expression: "groupWS",
                        },
                      },
                      _vm._l(_vm.groupActiveWS, function (group) {
                        return _c(
                          "option",
                          { key: group.id, domProps: { value: group.id } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  " " + group.id + " - " + group.group_code
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._m(4),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label mx-4" }, [
                        _vm._v(" GT"),
                      ]),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.widget.gt_widget,
                              expression: "widget.gt_widget",
                            },
                          ],
                          staticClass: "form-control",
                          domProps: { value: _vm.widget.gt_widget },
                          on: {
                            change: function ($event) {
                              return _vm.updateLink("gt_widget", "gt")
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.widget,
                                "gt_widget",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label mx-4" }, [
                        _vm._v(" WS"),
                      ]),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.widget.ws_widget,
                              expression: "widget.ws_widget",
                            },
                          ],
                          staticClass: "form-control",
                          domProps: { value: _vm.widget.ws_widget },
                          on: {
                            change: function ($event) {
                              return _vm.updateLink("ws_widget", "ws")
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.widget,
                                "ws_widget",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(5),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("GT ACCESS")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
        _c("h5", [_vm._v("Select user groups")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("WS ACCESS")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
        _c("h5", [_vm._v("Select user groups")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label mt-4" }, [
        _c("h4", [_vm._v("Widget Link for LINE notification")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "page vertical-align text-center",
        attrs: {
          "data-animsition-in": "fade-in",
          "data-animsition-out": "fade-out",
        },
      },
      [
        _c("div", { staticClass: "page-content vertical-align-middle" }, [
          _c("header", [
            _c("h1", { staticClass: "animation-slide-top" }, [_vm._v("404")]),
            _c("p", [_vm._v("Page Not Found !")]),
          ]),
          _c("p", { staticClass: "error-advise" }, [
            _vm._v("YOU SEEM TO BE TRYING TO FIND HIS WAY HOME"),
          ]),
          _c(
            "a",
            { staticClass: "btn btn-primary btn-round", attrs: { href: "/" } },
            [_vm._v("GO TO HOME PAGE")]
          ),
          _c("footer", { staticClass: "page-copyright" }, [
            _c("p", [_vm._v("WEBSITE BY Creation Studio")]),
            _c("p", [_vm._v("© 2018. All RIGHT RESERVED.")]),
            _c("div", { staticClass: "social" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-icon btn-pure",
                  attrs: { href: "javascript:void(0)" },
                },
                [
                  _c("i", {
                    staticClass: "icon bd-twitter",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-icon btn-pure",
                  attrs: { href: "javascript:void(0)" },
                },
                [
                  _c("i", {
                    staticClass: "icon bd-facebook",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-icon btn-pure",
                  attrs: { href: "javascript:void(0)" },
                },
                [
                  _c("i", {
                    staticClass: "icon bd-dribbble",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
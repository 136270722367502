<template>
  <div v-if="columnConfig.column_display">
    <vue-good-table
      ref="table"
      mode="remote"
      :rows="list"
      :columns="columns"
      :totalRows="totalRecords"
      :sort-options="sortOption"
      :search-options="searchOption"
      :pagination-options="pagination"
      @on-search="onSearch"
      @on-sort-change="onSortChange"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <span>
            {{ props.row.status | toUpperCase }}
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="table-actions" v-if="!isAutoUpdate">
        <ImportProgress
          :campaign="campaign"
          :columnConfig="columnConfig"
          @updated="fetch"
        />
        <div class="Excel">
          <button @click="exportToExcel" class="btn btn-default">
            <i class="fa fa-file-excel-o"></i>
            Export to Excel
          </button>
        </div>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";

import { leaderBoardTableColumns } from "@/views/progressbar/model";
import ImportProgress from "@/views/progressbar/ProgressUpdate/LeaderBoard/ImportProgress.vue";

export default {
  name: "LeaderBoardTable",

  props: ["campaign", "columnConfig"],

  components: { ImportProgress },

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      transactions: [],
      sortOption: {
        enabled: true,
        initialSortBy: { field: "rank", type: "asc" },
      },
    };
  },

  watch: {
    transactions(v) {
      this.list = v;
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState(this.sortOption);
  },

  mounted() {
    this.initialTableSearchState();
  },

  computed: {
    columns() {
      if (!this.columnConfig.column_display) {
        return leaderBoardTableColumns;
      }

      return this.columnConfig.column_display.map((o, i) => {
        return {
          label: o,
          field: this.columnConfig.column_key[i],
          sortable: this.columnConfig.column_sortable[i] === 1,
        };
      });
    },
    isAutoUpdate() {
      return this.campaign.leaderboard.progress_upload_manual === 0;
    },
  },

  methods: {
    getParams() {
      let params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    async fetch() {
      const id = this.$route.params.id;
      const params = this.getParams();

      const { data, total_records } =
        await progressBarService.getProgressUpdateDetail(id, params);

      this.transactions = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      try {
        const id = this.$route.params.id;
        const response = await progressBarService.downloadProgressUpdate(id);

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Progress_Update_Leader_Board_${id}_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    await this.fetch();
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
  display: inline-block;
}
</style>

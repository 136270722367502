var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lot
    ? _c("div", { staticClass: "col-12 mx-10" }, [
        _c("label", { staticClass: "label" }, [_vm._v("LUM Groups")]),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "Selectize",
              {
                attrs: {
                  multiple: "",
                  disabled: _vm.disabled,
                  settings: _vm.groupSelectizeSetting,
                },
                model: {
                  value: _vm.lot.lum_group_code_list,
                  callback: function ($$v) {
                    _vm.$set(_vm.lot, "lum_group_code_list", $$v)
                  },
                  expression: "lot.lum_group_code_list",
                },
              },
              _vm._l(_vm.lumUserGroup, function (group) {
                return _c(
                  "option",
                  { key: group.id, domProps: { value: group.group_code } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(" " + group.id + " - " + group.group_name) +
                        " "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
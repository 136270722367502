<template>
  <div class="col-md-12" v-if="pool">
    <div class="form-group-row">
      <label class="mb-0">Vendor</label>

      <select
        name="vendor"
        @change="update"
        v-model="pool.vendor"
        :disabled="disabled"
        class="form-control"
      >
        <option value="" disabled>Select Vendor</option>
        <option v-for="(value, index) in vendors" :value="value" :key="index">
          {{ value }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import formMixins from "../form.mixins";
import { prizepoolService } from "@/api/client";

export default {
  name: "VendorSelector",

  mixins: [formMixins],

  data() {
    return {
      vendors: [],
      message: {
        error: "Update Vendor error.",
        success: "Update Vendor success.",
      },
    };
  },

  methods: {
    async fetchVendor() {
      this.vendors = await prizepoolService.getVendors();
    },

    initial() {
      this.fetchVendor();
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
}

.form-group-row {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-weight: 500;
    margin-bottom: 0px;
  }

  select {
    max-width: 250px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("h5", { staticClass: "px-5 mb-15" }, [_vm._v("Campaign Duration")]),
      _c("div", { staticClass: "d-flex flex-column gap-3" }, [
        _c(
          "div",
          { staticClass: "d-flex gap-3" },
          [
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v("Start"),
            ]),
            _c("date-picker", {
              attrs: {
                type: "date",
                "value-type": "format",
                format: "YYYY-MM-DD",
                disabled: _vm.disabled,
                confirm: true,
                clearable: false,
                placeholder: "Select date",
                "disabled-date": _vm.disabledBeforeToday,
              },
              on: {
                confirm: function ($event) {
                  return _vm.update("start_date")
                },
              },
              model: {
                value: _vm.start_date,
                callback: function ($$v) {
                  _vm.start_date = $$v
                },
                expression: "start_date",
              },
            }),
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v("No. days per period"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.days_per_period,
                  expression: "days_per_period",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control w-100",
              attrs: {
                min: "1",
                max: "365",
                type: "number",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.days_per_period },
              on: {
                change: function ($event) {
                  return _vm.update("days_per_period")
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.days_per_period = _vm._n($event.target.value)
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v("No. period"),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.no_period,
                    expression: "no_period",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "form-control w-100 mr-10",
                attrs: { disabled: _vm.disabled },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.no_period = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.update("no_period")
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
                _c("option", { attrs: { value: "12" } }, [_vm._v("12")]),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "d-flex" }, [
          _c("label", { staticClass: "form-control-label" }, [
            _vm._v(" End Date: " + _vm._s(_vm.endDateString) + " "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create Progress Bar Campaign</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <h5 class="col-md-12">Campaign Name</h5>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="campaign.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation
                  :$v="$v.campaign"
                  v-if="showErrorNameValidation"
                />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">Campaign Type</h5>
              <div class="col-md-12">
                <select
                  ref="type"
                  class="form-control col-md-12"
                  v-model="campaign.type"
                  @change="handleCampaignTypeChange"
                >
                  <option value="" disabled>Please select campaign type</option>
                  <option value="barcode">Barcode</option>
                  <option value="team">Team Barcode</option>
                  <option value="monthly">Monthly</option>
                  <option value="task">Task</option>
                  <option value="leaderboard">Leaderboard</option>
                  <option value="monthlyshort">MonthlyShort</option>
                  <option value="weekly">Weekly</option>
                  <option value="stamp">Stamp</option>
                </select>
                <div
                  v-if="hasSelectedCampaignType && !isSupportNonPanelType"
                  class="my-2"
                >
                  <p class="text-warning">
                    <i class="mdi mdi-alert-outline" aria-hidden="true"></i>
                    Progress Bar ประเภทนี้ยังไม่รองรับ User ประเภท Non-panel
                    (ISMS)
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 d-flex align-items-center gap-5 my-3">
                <h5 class="mb-0">User Type</h5>
                <div class="radio-custom radio-primary">
                  <input
                    type="radio"
                    value="contact_id"
                    id="user_type_contact_id"
                    v-model="campaign.user_type"
                  />
                  <label for="user_type_contact_id">Panel (Contact ID)</label>
                </div>
                <div class="radio-custom radio-primary mx-10">
                  <input
                    type="radio"
                    value="isms_code"
                    id="user_type_isms_code"
                    v-model="campaign.user_type"
                    :disabled="!isSupportNonPanelType"
                  />
                  <label for="user_type_isms_code">Non-Panel (ISMS)</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">User Group</h5>
              <div class="col-md-12">
                <select
                  class="form-control col-md-12"
                  v-model="selectUserGroup"
                  :disabled="campaign.user_type !== 'contact_id'"
                >
                  <option
                    v-for="group in usergroups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ ` ${group.id} - ${group.group_code}` }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="createCampaign"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userGroupService } from "@/api/client";
import { progressBarService } from "@/api/client";
import nameValidation from "./validation/name";
import { responseErrorMapping } from "./model/error";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "Create",

  data() {
    return {
      usergroups: [],
      selectUserGroup: null,
      showErrorNameValidation: false,

      campaign: {
        name: "",
        type: "",
        user_type: "contact_id",
      },
    };
  },

  components: {
    nameValidation,
  },

  validations: { campaign: validateRules },

  watch: {
    "campaign.user_type"(newValue) {
      this.handleUserTypeChange(newValue);
    },
  },

  computed: {
    isSupportNonPanelType() {
      const matched = ["barcode", "stamp", "leaderboard"].includes(
        this.campaign.type
      );

      return matched;
    },
    hasSelectedCampaignType() {
      return this.campaign.type !== "";
    },
  },

  async created() {
    await this.fetchUserGroup();
  },

  methods: {
    async fetchUserGroup() {
      this.usergroups = await userGroupService.getUserGroups();
    },

    async isFormValid() {
      if (this.$v.campaign.name.$invalid) {
        await this.$dialogs.alert("กรุณาระบุ Campaign Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      if (this.$v.campaign.type.$invalid) {
        await this.$dialogs.alert("กรุณาระบุ Campaign Type ให้ถูกต้อง");
        this.$refs.type.focus();
        return false;
      }
      if (
        this.selectUserGroup == null &&
        this.campaign.user_type === "contact_id"
      ) {
        await this.$dialogs.alert("กรุณากรอก User Group ให้ถูกต้อง");
        return false;
      }
      return true;
    },

    close() {
      this.$modal.hide("create-modal");
    },

    async getFormData() {
      const body = {
        name: this.campaign.name,
        type: this.campaign.type,
        user_type: this.campaign.user_type,
      };

      if (this.campaign.user_type === "contact_id") {
        Object.assign(body, { group_id: this.selectUserGroup });
      }

      return body;
    },

    async createCampaign() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await progressBarService.createNewCampaign(body);
        await this.$dialogs.alert("Create campaign Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },

    handleCampaignTypeChange() {
      if (!this.isSupportNonPanelType) {
        this.campaign.user_type = "contact_id";
      }
    },

    handleUserTypeChange(newValue) {
      if (newValue !== "contact_id") {
        this.selectUserGroup = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-custom {
  padding-top: 0.2rem;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gameConfig
    ? _c("div", { staticClass: "page" }, [
        _c("div", { staticClass: "page-main" }, [
          _c("div", { staticClass: "page-header" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
              },
              [
                _c("h1", { staticClass: "page-title" }, [
                  _vm._v("Game Instance"),
                ]),
                _c("breadcrumb", {
                  attrs: { name: "GAME_EDIT", withLast: _vm.gameConfig.name },
                  on: {
                    "update:withLast": function ($event) {
                      return _vm.$set(_vm.gameConfig, "name", $event)
                    },
                    "update:with-last": function ($event) {
                      return _vm.$set(_vm.gameConfig, "name", $event)
                    },
                  },
                }),
                _c("div", { staticClass: "page-title page-header-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-round btn-default mx-10",
                      attrs: { type: "button" },
                      on: { click: _vm.goBack },
                    },
                    [
                      _c("i", {
                        staticClass: "icon md-chevron-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" Back "),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "page-content container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1",
                },
                [
                  _c("div", { staticClass: "panel panel-bordered" }, [
                    _c("div", { staticClass: "panel-heading" }, [
                      _c("h3", { staticClass: "panel-title" }, [
                        _vm._v(
                          " Game Instance Information - " +
                            _vm._s(_vm.gameConfig.id) +
                            " "
                        ),
                      ]),
                      _c("div", {
                        staticClass: "panel-actions panel-actions-keep",
                      }),
                      _c("div", { staticClass: "container px-30" }, [
                        _c("div", { staticClass: "row" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "col-6" }, [
                            _c("h4", [
                              _vm._v(" User Group: "),
                              _c("span", { staticClass: "grey-700" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.group_code ||
                                        _vm.gameConfig.group_code
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "panel-body" }, [
                      _vm.gameConfig
                        ? _c(
                            "form",
                            { staticClass: "form-horizontal" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c("GameInstanceName", {
                                      attrs: { gameConfig: _vm.gameConfig },
                                      on: { updated: _vm.getGameConfig },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-3" },
                                  [
                                    _c("StatusDropdown", {
                                      attrs: { gameConfig: _vm.gameConfig },
                                      on: { updated: _vm.getGameConfig },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("hr"),
                              _c("GameInstanceDuration", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("GameConfiguration", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("h4", [
                                _vm._v("Game Specific Setting - Sunset"),
                              ]),
                              _c("GameLogo", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("SplashScreen", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("StartScreen", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("BackgroundImage", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("CarPicture", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("SideObjectPicture", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("HowtoBackground", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("GameStory", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("br"),
                              _c("GameDuration", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("ObjectConifg", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("ObtaclesConfig", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("BillboardConifg", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                              _c("RedirectURL", {
                                attrs: { gameConfig: _vm.gameConfig },
                                on: { updated: _vm.getGameConfig },
                              }),
                              _c("hr"),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._m(1),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6" }, [
      _c("h4", [_vm._v("Sunset Drive")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
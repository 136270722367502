import Admin from "@/views/admin/Index.vue";
import AdminList from "@/views/admin/List.vue";
import AdminEdit from "@/views/admin/Edit.vue";

export default {
  path: "/admin",
  name: "Admin",
  component: Admin,
  meta: {
    requiresAuth: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/",
      name: "AdminList",
      component: AdminList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/:id",
      name: "AdminEdit",
      component: AdminEdit,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

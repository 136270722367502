export const transactionLogTableColumns = [
  {
    label: "Bid Time",
    field: "bid_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd-MM-yyyy HH:mm:ss",
  },
  {
    label: "Deduct Time",
    field: "deduct_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd-MM-yyyy HH:mm:ss",
  },
  {
    label: "Refund Time",
    field: "refund_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd-MM-yyyy HH:mm:ss",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "ISMS Code",
    field: "isms_code",
  },
  {
    label: "User Name",
    field: "name",
  },
  {
    label: "Lot ID",
    field: "lot_id",
    type: "number",
  },
  {
    label: "Lot Name",
    field: "lot_name",
  },
  {
    label: "Point",
    field: "points",
    type: "number",
  },
  {
    label: "Winner",
    field: "is_winner",
    type: "number",
    formatFn: function (value) {
      return +value === 1 ? "Yes" : "No";
    },
  },
  {
    label: "Status",
    field: "status",
  },
];

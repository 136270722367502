var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("sort")
        },
      },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.text))]),
      this.$route.query.sortBy == _vm.sortBy && this.$route.query.sortDir == 1
        ? _c("BaseIcon", { attrs: { icon: "arrow_downward" } })
        : this.$route.query.sortBy == _vm.sortBy &&
          this.$route.query.sortDir == -1
        ? _c("BaseIcon", { attrs: { icon: "arrow_upward" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
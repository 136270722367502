export const activityColumns = [
  {
    label: "Timestamp",
    field: "scan_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
    formatFn: function (value) {
      return value + " น.";
    },
  },
  {
    label: "PiD",
    field: "peddler_id",
    type: "number",
  },
  {
    label: "PLogin",
    field: "username",
  },
  {
    label: "Peddler Name",
    field: "peddler_name",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Name",
    field: "shop_name",
  },
  {
    label: "QR",
    field: "qrcode",
  },
  {
    label: "No. Coupon",
    field: "total_ecp",
    type: "number",
  },
  {
    label: "Value",
    field: "total_face_value",
    type: "number",
  },
  {
    label: "From ISMS",
    field: "from_isms",
  },
];

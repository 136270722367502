<template>
  <div class="col-md-10" v-if="stamp && status != null">
    <div
      class="wrapper-upload-form"
      v-if="action == 'ready' && status.active == 0"
    >
      <label class="mx-5">
        <h4>Target SKUs (counted toward progress)</h4>
      </label>

      <button
        type="button"
        class="btn btn-primary btn-round"
        :disabled="disabled"
        @click="setAction('editing')"
      >
        Edit
      </button>
    </div>
    <div
      class="wrapper-upload-form"
      v-else-if="action == 'editing' && status.active == 0"
    >
      <label class="mx-5">
        <h4>Target SKUs (counted toward progress)</h4>
      </label>
      <div>
        <button
          type="button"
          class="btn btn-round btn-primary mx-10"
          @click="handleVerify"
          :disabled="disabled"
        >
          Verify
        </button>
        <button
          type="button"
          class="btn btn-round btn-default"
          @click="handleCancel"
          :disabled="disabled"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="wrapper-upload-form" v-else-if="status.active == 1">
      <label class="mx-5">
        <h4>Target SKUs (counted toward progress)</h4>
      </label>

      <label class="label-blue" v-if="status.state == 'verify'">
        Verifying change
      </label>

      <label
        class="label-orange"
        v-else-if="status.state == 'execute' && status.status == 'pending'"
      >
        Executing...
      </label>

      <label
        class="label-blue"
        v-else-if="status.state == 'done-verify' && status.status !== 'failure'"
      >
        Click to commit change
      </label>

      <label
        class="label-red"
        v-else-if="status.state == 'done-verify' && status.status === 'failure'"
      >
        Verifying change failure
      </label>

      <div v-if="status.state == 'verify' && campaign.status != 'draft'">
        <button type="button" class="btn btn-round btn-primary mx-10" disabled>
          Commit
        </button>
        <button type="button" class="btn btn-round btn-default" disabled>
          Cancel
        </button>
      </div>

      <div v-if="status.state == 'execute' && status.status == 'pending'">
        <button type="button" class="btn btn-round btn-primary mx-10" disabled>
          Commit
        </button>
        <button type="button" class="btn btn-round btn-default" disabled>
          Cancel
        </button>
      </div>

      <div v-else-if="status.state == 'done-verify'">
        <button
          type="button"
          class="btn btn-round btn-primary mx-10"
          @click="getResult"
          :disabled="disabled"
          v-if="status.status === 'success'"
        >
          Commit
        </button>
        <button
          type="button"
          class="btn btn-round btn-default"
          @click="handleCancel"
          :disabled="disabled"
        >
          Cancel
        </button>
      </div>
    </div>

    <Selectize
      multiple
      :disabled="!canEdit"
      v-model="skuSelected"
      :settings="selectizeSetting"
    >
      <option v-for="(sku, index) in skus" :key="index" :value="sku.PRO_CODE">
        {{ sku.PRO_NAME_EN + " - " + sku.PRO_CODE }}
      </option>
    </Selectize>

    <modal name="try-update-sku-modal" :width="600" height="auto">
      <TryUploadModal
        :campaign="campaign"
        :result="result"
        @confirm="confirmUpdate"
        name="try-update-sku-modal"
      />
    </modal>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { progressBarService } from "@/api/client";
import TryUploadModal from "./TryUploadModal.vue";

export default {
  props: ["campaign"],

  components: {
    Selectize,
    TryUploadModal,
  },

  data() {
    return {
      skus: [],
      status: null,
      result: null,
      stamp: null,
      skuSelected: [],
      action: "ready",
      pullingId: null,
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        // create: this.add,
      },
    };
  },

  computed: {
    disabled() {
      return ["archived", "end", "aborted"].includes(this.campaign.status);
    },
    canEdit() {
      return (
        this.action == "editing" &&
        !["archived", "end", "aborted"].includes(this.campaign.status)
      );
    },
  },

  watch: {
    status(n, o) {
      if (n.active === 0 && o && ["verify", "execute"].includes(o.state)) {
        this.$notify(`Target SKUs Updated.`);
      }

      if (
        n.active === 1 &&
        n.type == "sku" &&
        n.state == "verify" &&
        this.pullingId === null
      ) {
        this.setAction("ready");
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "sku" &&
        n.state == "execute" &&
        this.pullingId === null
      ) {
        this.startPullingStatus();
        return;
      }

      if (n.active === 1 && n.type == "sku" && n.state == "done-execute") {
        this.setAction("ready");
        this.getUploadStatus();
        return;
      }

      if (n.active === 1 && n.type == "sku" && n.state == "done-verify") {
        this.setAction("ready");
        this.stopPullingStatus();
        return;
      }

      if (n.active === 0) {
        this.setAction("ready");
        this.stopPullingStatus();
        return;
      }
    },
  },

  methods: {
    setAction(action) {
      this.action = action;
    },

    async handleCancel() {
      if (this.status.active === 1) {
        await progressBarService.tryCancelUpdateSKU(this.campaign.id, "stamp");
        this.$notify("Cancel success");
        this.getUploadStatus();
      }

      this.setAction("ready");
      this.skuSelected = this.stamp.sku;
    },

    async handleVerify() {
      if (this.stamp.sku.length === 0 && this.skuSelected.length <= 0) {
        return;
      }
      try {
        this.campaign.status == "draft"
          ? await this.update()
          : await this.tryUpdate();

        await this.getUploadStatus();

        if (this.campaign.status == "draft") {
          this.stamp.sku = this.skuSelected;
        }
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs verify error.`);
      }
    },

    async update() {
      try {
        const form = { sku: this.skuSelected };

        await progressBarService.updateSKU(this.campaign.id, "stamp", form);

        this.$notify(`Start Verifying...`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async tryUpdate() {
      try {
        const form = { sku: this.skuSelected };

        const result = await progressBarService.tryUpdateSKU(
          this.campaign.id,
          "stamp",
          form
        );

        if (result.error) {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        this.getUploadStatus();
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async getResult() {
      try {
        this.result = await progressBarService.tryUpdateSKUResult(
          this.campaign.id,
          "stamp"
        );

        this.$modal.show(`try-update-sku-modal`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async confirmUpdate() {
      try {
        await progressBarService.tryConfirmUpdateSKU(this.campaign.id, "stamp");

        // set stamp SKUs with selected SKUs
        this.stamp.sku = this.skuSelected;

        this.$notify("Executing...");
        this.getUploadStatus();
        this.$emit("updated");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getSKUList() {
      this.skus = await progressBarService.getSKUGroupList();
    },

    async getUploadStatus() {
      this.status = await progressBarService.getUploadStatus(this.campaign.id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 5000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },

    add(sku) {
      return {
        value: sku,
        text: sku,
      };
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  async mounted() {
    this.stamp = this.campaign.stamp;
    this.skuSelected = this.stamp.sku;
  },

  async created() {
    await this.getSKUList();
    await this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
.wrapper-upload-form {
  display: flex;
  margin: 2em auto;
  align-items: center;
  justify-content: space-between;

  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }

  .label-red {
    color: #dc3545;
    font-weight: 500;
  }

  .label-orange {
    color: #dc6d35;
    font-weight: 500;
  }
}
</style>

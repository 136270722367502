var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "input-group mb-2 mr-sm-2" }, [
      _c("div", { staticClass: "input-group-prepend" }, [
        _c("div", { staticClass: "input-group-text" }, [
          _c("span", { staticClass: "material-icons" }, [
            _vm._v(" " + _vm._s(_vm.icon) + " "),
          ]),
        ]),
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected",
            },
          ],
          staticClass: "form-control",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.optionSelected,
            ],
          },
        },
        [
          _vm._t("default"),
          _vm._l(_vm.options, function (ref, idx) {
            var text = ref.text
            var value = ref.value
            return _c("option", { key: idx, domProps: { value: value } }, [
              _vm._v(" " + _vm._s(text) + " "),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
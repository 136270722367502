var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.monthly
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "ml-10 extra-text-and-reward-extra-table" }, [
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Text content 1")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monthly.text6_content1,
                        expression: "monthly.text6_content1",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text6_content1",
                      "width-200px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.monthly.text6_content1 },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.monthly,
                          "text6_content1",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._m(1),
                _c("td", [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "checkbox-custom checkbox-primary" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.monthly.text6_target_extra,
                              expression: "monthly.text6_target_extra",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            "true-value": 1,
                            "false-value": 0,
                            id: "text6_target_extra",
                            name: "text6_target_extra",
                            disabled: _vm.disabled,
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.monthly.text6_target_extra
                            )
                              ? _vm._i(_vm.monthly.text6_target_extra, null) >
                                -1
                              : _vm._q(_vm.monthly.text6_target_extra, 1),
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.monthly.text6_target_extra,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? 1 : 0
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.monthly,
                                        "text6_target_extra",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.monthly,
                                        "text6_target_extra",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.monthly,
                                    "text6_target_extra",
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.update($event)
                              },
                            ],
                          },
                        }),
                        _c("label", { attrs: { for: "enable_quiz_checkbox" } }),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Text content 3")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monthly.text6_content3,
                        expression: "monthly.text6_content3",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text6_content3",
                      "width-200px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.monthly.text6_content3 },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.monthly,
                          "text6_content3",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", { attrs: { align: "right" } }, [
                  _vm._v("Target unit"),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monthly.text6_target_unit,
                        expression: "monthly.text6_target_unit",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text6_target_unit",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.monthly.text6_target_unit },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.monthly,
                          "text6_target_unit",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]
          ),
        ]),
        _c("hr"),
        _vm._m(2),
        _c("div", { staticClass: "ml-10 monthly-summary-table" }, [
          _c(
            "table",
            { staticClass: "table table-responsive table-borderless" },
            [
              _c("tr", [
                _c("td", [_vm._v("Month 1")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monthly.text7_month1,
                        expression: "monthly.text7_month1",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text7_month1",
                      "width-200px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.monthly.text7_month1 },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.monthly,
                          "text7_month1",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Month 2")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monthly.text7_month2,
                        expression: "monthly.text7_month2",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text7_month2",
                      "width-200px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.monthly.text7_month2 },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.monthly,
                          "text7_month2",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [_vm._v("Month 3")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monthly.text7_month3,
                        expression: "monthly.text7_month3",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "text7_month3",
                      "width-200px": "",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.monthly.text7_month3 },
                    on: {
                      change: function ($event) {
                        return _vm.update($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.monthly,
                          "text7_month3",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v("Extra text and Reward Extra"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "right" } }, [
      _c("label", { attrs: { for: "text6_target_extra" } }, [
        _vm._v("Target extra"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Monthly Summary")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [
          _vm._v("LIFF Access Setting"),
        ]),
        _c("breadcrumb", { attrs: { name: "LIFF_ACCESS_SETTINGS" } }),
      ],
      1
    ),
    _c("div", { staticClass: "page-content container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "panel panel-bordered" }, [
            _c("div", { staticClass: "panel-body" }, [
              _c("form", { staticClass: "form-horizontal mb-5" }, [
                _vm._m(0),
                _c("div", { staticClass: "row" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center gap-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.config.delist_days,
                              expression: "config.delist_days",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control w-100",
                          attrs: { min: "0", type: "number" },
                          domProps: { value: _vm.config.delist_days },
                          on: {
                            change: _vm.updateConfig,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.config,
                                "delist_days",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c("span", [_vm._v("day(s)")]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "row-space" }),
                _c("div", { staticClass: "row" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "d-flex gap-5" }, [
                      _c("div", { staticClass: "radio-custom radio-primary" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.order_of_display,
                              expression: "config.order_of_display",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "ascending",
                            id: "order_ascending",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.config.order_of_display,
                              "ascending"
                            ),
                          },
                          on: {
                            change: [
                              function ($event) {
                                return _vm.$set(
                                  _vm.config,
                                  "order_of_display",
                                  "ascending"
                                )
                              },
                              _vm.updateConfig,
                            ],
                          },
                        }),
                        _c("label", { attrs: { for: "order_ascending" } }, [
                          _vm._v("Ascending"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "radio-custom radio-primary mx-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.order_of_display,
                                expression: "config.order_of_display",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              value: "descending",
                              id: "order_descending",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.config.order_of_display,
                                "descending"
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.config,
                                    "order_of_display",
                                    "descending"
                                  )
                                },
                                _vm.updateConfig,
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "order_descending" } }, [
                            _vm._v("Descending"),
                          ]),
                        ]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.order_of_display_by,
                              expression: "config.order_of_display_by",
                            },
                          ],
                          staticClass: "form-control w-200",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config,
                                  "order_of_display_by",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.updateConfig,
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "", disabled: "" } }, [
                            _vm._v("Please select sort by"),
                          ]),
                          _vm._l(_vm.choices, function (choice) {
                            return _c(
                              "option",
                              {
                                key: choice.value,
                                domProps: { value: choice.value },
                              },
                              [_vm._v(" " + _vm._s(choice.name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(3),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Progress Bar")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 col-md-3 offset-md-1" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm._v(" Delisted Date"),
        _c("br"),
        _vm._v("(after campaign ends) "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-2 col-md-3 offset-md-1 d-flex flex-column justify-content-center",
      },
      [_c("div", [_vm._v("Order of display for multiple campaigns")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
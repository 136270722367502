<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Progress Bar</h1>
      <breadcrumb name="PROGRESS_BAR_TRANSACTION_LOGS"></breadcrumb>
      <div class="page-header-right">
        <div class="type-filter">
          Type
          <Selectize multiple v-model="type" :settings="selectizeSetting">
            <option
              v-for="(option, index) in options"
              :key="index"
              :value="option"
            >
              {{ option.charAt(0).toUpperCase() + option.slice(1) }}
            </option>
          </Selectize>
        </div>
        <div class="period-filter">
          Selected Period
          <date-picker
            range
            type="date"
            v-model="fromTo"
            :default-value="fromTo"
            value-type="format"
            format="YYYY-MM-DD"
            placeholder="Select date range"
          ></date-picker>
        </div>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span>
                    {{ props.row.status | toUpperCase }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

import Selectize from "vue2-selectize";
import vueGoodTableMixins from "@/mixins/vue-good-table";

import { progressBarService } from "@/api/client";
import { transactionLogTableColumns } from "@/views/progressbar/model";

export default {
  name: "TransactionLog",

  mixins: [vueGoodTableMixins],

  components: {
    Selectize,
  },

  data() {
    return {
      type: [],
      list: [],
      fromTo: [],
      transactions: [],
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        // create: this.add,
      },
      options: [
        "barcode",
        "monthly",
        "task",
        "leaderboard",
        "team",
        "monthlyshort",
        "weekly",
        "stamp",
      ],
      columns: transactionLogTableColumns,
    };
  },

  watch: {
    type() {
      this.onTypeChange();
    },

    fromTo() {
      this.onPeriodChange();
    },

    transactions(v) {
      this.list = v;
    },

    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
    this.$router.replace({ query: null });
  },

  methods: {
    customLabel(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },

    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      if (this.type.length > 0) {
        params.type = this.type.toString();
      }

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    isValidPeriod() {
      const { from, to } = this.$route.query;

      if (!from || !dayjs(from).isValid()) {
        return false;
      }

      if (!to || !dayjs(to).isValid()) {
        return false;
      }

      return true;
    },

    async fetch() {
      if (!this.isValidPeriod()) {
        return;
      }

      const params = this.getParams();

      const { data, total_records } =
        await progressBarService.getTransactionLog(params);

      this.transactions = data;
      this.totalRecords = total_records;
    },

    async exportToExcel() {
      try {
        const response = await progressBarService.downloadTransactionLog({
          from: this.fromTo[0],
          to: this.fromTo[1],
          type: this.type.toString(),
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `progressbar_txlog_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    onPeriodChange() {
      const { sortBy, sortDir } = this.$route.query;

      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
            sortDir: sortDir || "desc",
            sortBy: sortBy || "event_time",
          }),
        })
        .catch((err) => {});
    },

    onTypeChange() {
      const { sortBy, sortDir } = this.$route.query;

      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            type: this.type.toString(),
            sortDir: sortDir || "desc",
            sortBy: sortBy || "event_time",
          }),
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;

  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.type-filter {
  width: 460px;
  min-width: 460px;
  max-width: 460px;
}

.type-filter,
.period-filter {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}

.mx-datepicker-range {
  width: 216px;
}
</style>

<style lang="scss">
.selectize-control.multi {
  width: 100%;
  display: block;

  .selectize-input {
    > div.active {
      background: #efefef;
      color: #333333;
      border: 0 solid rgba(0, 0, 0, 0);
    }
  }
}
</style>

import HttpRequest from "./request";

export class AdminProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api`);
  }

  async getAdmins() {
    const { data } = await this.get(`/admin_user`);
    return data;
  }

  async createAdmin(form) {
    const { data } = await this.create(`/admin_user`, form);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/admin_user/${id}`);
    return data;
  }

  async updateAdmin(id, form) {
    const { data } = await this.update(`/admin_user/${id}`, form);
    return data;
  }
}

<template>
  <div class="row">
    <div class="col-md-10">
      <label>
        <h5>Questions</h5>
      </label>
    </div>
    <div class="col-md-10">
      <div class="round-question">
        <div
          class="question"
          v-for="(question, index) in round.question"
          :key="question.question_id"
        >
          <p class="no">{{ index + 1 }}.</p>

          <p class="description badge badge-pill badge-primary">
            {{ question.description || "-" }}
          </p>
          <p class="question">{{ question.question || "-" }}</p>
          <p class="choice-count">
            No. choice: <span>{{ question.choice.length }}</span>
          </p>
          <div class="space"></div>
          <div class="actions">
            <button
              class="btn btn-default"
              @click="editQuestion(question.question_id, index, round)"
            >
              <i v-if="isValidStatus" class="fa fa-pencil"></i>
              <i v-else class="fa fa-eye"></i>
            </button>
            <button
              class="btn btn-default"
              @click="removeQuestion(question.question_id)"
              :disabled="!canRemoveQuestion"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>

        <div class="actions">
          <button
            class="btn btn-default"
            @click="addQuestion"
            :disabled="!canAddQuestion"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["round"],

  computed: {
    campaign() {
      return this.$parent.campaign;
    },
    isValidStatus() {
      return !["archived", "end"].includes(this.campaign.status);
    },
    canRemoveQuestion() {
      const moreThanOneQuestion = this.round.question.length > 1;

      return this.isValidStatus && moreThanOneQuestion;
    },
    canAddQuestion() {
      return this.isValidStatus;
    },
  },

  methods: {
    removeQuestion(questionId) {
      this.$root.$emit(
        "STAFF_ENGAGEMENT_REMOVE_QUESTION",
        this.round.round_id,
        questionId
      );
    },

    async addQuestion() {
      this.$root.$emit("STAFF_ENGAGEMENT_ADD_QUESTION", this.round.round_id);
    },

    editQuestion(id, index, round) {
      this.$root.$emit("STAFF_ENGAGEMENT_EDIT_QUESTION", {
        id,
        index,
        round,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.round-question {
  padding: 1rem;

  > .question {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5eaec;

    p {
      margin-bottom: 0px;
    }

    .choice-count {
      color: #b8b8b8;

      span {
        color: #76838f;
      }
    }

    .actions {
      button {
        margin-left: 10px;
      }
    }

    .space {
      flex: 1;
    }
  }

  > .actions {
    padding: 1rem;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.barcode
    ? _c(
        "div",
        { staticClass: "col-md-10" },
        _vm._l(_vm.barcode.no_targets, function (no, index) {
          return _c(
            "div",
            { key: index, staticClass: "mx-5" },
            [
              _c("TargetPrizeImage", {
                attrs: {
                  no: no,
                  campaign: _vm.campaign,
                  name: "target_prize_picture" + no,
                },
                on: {
                  updated: function ($event) {
                    return _vm.$emit("updated")
                  },
                },
              }),
              _c("hr"),
              _c(
                "div",
                { staticClass: "d-flex justify-content-between px-5" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center gap-4" },
                    [
                      _c("div", { staticClass: "flex-grow-1" }, [
                        _vm._v("Prize Expiration"),
                      ]),
                      _c("div", { staticClass: "radio-custom radio-primary" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.barcode["target_expire_mode" + no],
                              expression: "barcode[`target_expire_mode${no}`]",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "days",
                            id: "target_expire_mode" + no + "_days",
                            name: "target_expire_mode" + no,
                            disabled: _vm.disabled,
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.barcode["target_expire_mode" + no],
                              "days"
                            ),
                          },
                          on: {
                            change: [
                              function ($event) {
                                return _vm.$set(
                                  _vm.barcode,
                                  "target_expire_mode" + no,
                                  "days"
                                )
                              },
                              _vm.update,
                            ],
                          },
                        }),
                        _c(
                          "label",
                          {
                            attrs: { for: "target_expire_mode" + no + "_days" },
                          },
                          [_vm._v(" After grant ")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center gap-3" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.barcode["target_expire_days" + no],
                                expression:
                                  "barcode[`target_expire_days${no}`]",
                              },
                            ],
                            staticClass: "form-control w-100",
                            attrs: {
                              type: "number",
                              name: "target_expire_days" + no,
                              disabled:
                                _vm.disabled ||
                                _vm.barcode["target_expire_mode" + no] !==
                                  "days",
                            },
                            domProps: {
                              value: _vm.barcode["target_expire_days" + no],
                            },
                            on: {
                              change: _vm.update,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.barcode,
                                  "target_expire_days" + no,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("div", [_vm._v("days")]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex radio-custom radio-primary" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.barcode["target_expire_mode" + no],
                                expression:
                                  "barcode[`target_expire_mode${no}`]",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              value: "date",
                              id: "target_expire_mode" + no + "_date",
                              name: "target_expire_mode" + no,
                              disabled: _vm.disabled,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.barcode["target_expire_mode" + no],
                                "date"
                              ),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.barcode,
                                    "target_expire_mode" + no,
                                    "date"
                                  )
                                },
                                _vm.update,
                              ],
                            },
                          }),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: "target_expire_mode" + no + "_date",
                              },
                            },
                            [_vm._v(" Specific date ")]
                          ),
                        ]
                      ),
                      _c("DatePicker", {
                        ref: "start_date",
                        refInFor: true,
                        attrs: {
                          type: "date",
                          "width-150px": "",
                          placeholder: "n/a",
                          format: "YYYY-MM-DD",
                          "value-type": "YYYY-MM-DD",
                          "disabled-date": _vm.notBeforeToday,
                          disabled:
                            _vm.disabled ||
                            _vm.barcode["target_expire_mode" + no] !== "date",
                        },
                        on: { change: _vm.update },
                        model: {
                          value: _vm.barcode["target_expire_date" + no],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.barcode,
                              "target_expire_date" + no,
                              $$v
                            )
                          },
                          expression: "barcode[`target_expire_date${no}`]",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("hr"),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
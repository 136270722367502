export const adminTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Username",
    field: "username",
  },
  {
    label: "Role",
    field: "role",
  },
  {
    label: "Status",
    field: "active",
  },
  {
    label: "Last Login",
    field: "login_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Created date",
    field: "created_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

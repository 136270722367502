<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-body">
          <div class="container">
            <h4>Lot</h4>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Start</th>
                  <th scope="col">End</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ winner.lot_id }}</td>
                  <td>{{ winner.lot_name }}</td>
                  <td>{{ winner.start_date | dateTime }} น.</td>
                  <td>{{ winner.end_date | dateTime }} น.</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h4>Winner Info</h4>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">User ID</th>
                  <th scope="col">Contact ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Product Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ winner.sfdc_user_id }}</td>
                  <td>{{ winner.contact_id }}</td>
                  <td>{{ winner.firstname }} {{ winner.lastname }}</td>
                  <td>{{ winner.name }}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h4>Contact Info</h4>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" td-width-30>Name</th>
                  <th scope="col" td-width-30>Tel. No.</th>
                  <th scope="col">Address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ winner.firstname }} {{ winner.lastname }}</td>
                  <td>{{ winner.telephone }}</td>
                  <td>{{ address }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" td-width-60>Citizen ID</th>
                  <th scope="col">Tax Handling</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ winner.citizen_id }}</td>
                  <td v-html="taxHandling"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["winner"],

  computed: {
    address() {
      const {
        house_no,
        road,
        tambon,
        amphoe,
        province,
        zip_code,
      } = this.winner;

      return `${house_no || ""} ${road || ""} ${tambon || ""} ${amphoe || ""} 
      ${province || ""} ${zip_code || ""}`;
    },

    taxHandling() {
      const { email, tax_document } = this.winner;
      if (tax_document == "email") {
        return `Email: <br/> ${email}`;
      } else {
        return tax_document;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[td-width-30] {
  width: 30%;
}

[td-width-60] {
  width: 60%;
}
</style>

<template>
  <div>
    <div class="input-group mb-2 mr-sm-2">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <span class="material-icons">
            {{ icon }}
          </span>
        </div>
      </div>
      <select
        class="form-control"
        :value="value"
        @input="$emit('input', $event.target.value)"
      >
        <slot></slot>
        <option v-for="year in options" :value="year" :key="year">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "BirthdaySelect",
  props: {
    options: {
      type: Array,
      default: []
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group {
  .input-group-prepend {
    border-right: none;
    .input-group-text {
      background-color: transparent;
    }
  }
  .form-control {
    appearance: none;
    border-left: none;
    &:focus {
      border-color: #ced4da;
      border-left-color: transparent;
    }
  }
}
</style>

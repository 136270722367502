import qs from "qs";
import HttpRequest from "./request";

export class DailyloginProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_SERVICE_URL}/ams/api/dailylogin`);
  }

  async getDailylogin(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`${queryString}`);
    return data;
  }

  async getById(id) {
    const { data } = await this.get(`/${id}`);
    return data;
  }

  async createDailylogin(body) {
    const { data } = await this.create("", body);
    return data;
  }

  async editDailylogin(id, body) {
    const { data } = await this.update(`/${id}`, body);
    return data;
  }

  async getQuizByStep(id, step) {
    const { data } = await this.get(`/${id}/quiz/${step}`);
    return data;
  }

  async editQuizByStep(id, step, body) {
    const { data } = await this.update(`/${id}/quiz/${step}`, body);
    return data;
  }

  async uploadQuizImage(id, step, body) {
    const { data } = await this.upload(`/${id}/quiz/${step}/upload`, body);
    return data;
  }
}

<template>
  <div v-if="lot" class="mx-10 col-12">
    <label class="form-label">
      <h5 class="mb-0">Description</h5>
    </label>
    <ckeditor @blur="update" v-model="value" :read-only="!canEdit" />
  </div>
</template>

<script>
import { prizeService } from "@/api/client";

export default {
  props: ["lot"],

  data() {
    return {
      value: "",
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.lot.status);
    },
  },

  methods: {
    async update() {
      if (this.value == this.lot.prize_description) return;

      try {
        const { id } = this.lot;
        const body = { prize_description: this.value };

        await prizeService.updateLot(id, body);

        this.$notify(`Prize description updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Prize description error.`);
      }
    },

    initial() {
      this.value = this.lot.prize_description;
    },
  },

  mounted() {
    this.initial();
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.leaderboard
    ? _c("div", { staticClass: "col-md-10" }, [
        _vm._m(0),
        _c("div", { staticClass: "progress-upload-switcher-wrapper" }, [
          _c("p", { staticClass: "my-0 d-inline" }, [_vm._v("Auto")]),
          _c("label", { staticClass: "switch m0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.leaderboard.progress_upload_manual,
                  expression: "leaderboard.progress_upload_manual",
                },
              ],
              attrs: {
                type: "checkbox",
                "true-value": 1,
                "false-value": 0,
                disabled: _vm.disabled,
              },
              domProps: {
                checked: Array.isArray(_vm.leaderboard.progress_upload_manual)
                  ? _vm._i(_vm.leaderboard.progress_upload_manual, null) > -1
                  : _vm._q(_vm.leaderboard.progress_upload_manual, 1),
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.leaderboard.progress_upload_manual,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.leaderboard,
                            "progress_upload_manual",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.leaderboard,
                            "progress_upload_manual",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.leaderboard, "progress_upload_manual", $$c)
                    }
                  },
                  _vm.update,
                ],
              },
            }),
            _c("span", { staticClass: "slider round" }),
          ]),
          _c("p", { staticClass: "mx-10 my-0 ml-20 d-inline" }, [
            _vm._v("Manual"),
          ]),
        ]),
        +_vm.leaderboard.progress_upload_manual === 0
          ? _c(
              "div",
              { staticClass: "target-sku-wrapper" },
              [_c("TargetSKU", { attrs: { campaign: _vm.campaign } })],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Progress Upload")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page vld-parent" v-if="campaign">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="WEEKLY_LOGIN_CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Campaign Information - {{ campaign.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="campaign">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <CampaignName
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <CampaignDescription
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                  <hr />

                  <CampaignDuration
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                  <hr />

                  <UserGroup :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <Picture :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <PrizeItem :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <GrandPrize :campaign="campaign" @updated="fetchCampaign" />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { weeklyLoginService } from "@/api/client";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Picture from "./components/Picture.vue";
import UserGroup from "./components/UserGroup.vue";
import PrizeItem from "./components/PrizeItem.vue";
import GrandPrize from "./components/GrandPrize.vue";
import CampaignName from "./components/CampaignName.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import CampaignDuration from "./components/CampaignDuration.vue";
import CampaignDescription from "./components/Description.vue";

export default {
  name: "CampaignEdit",

  data() {
    return {
      campaign: null,
      isLoading: false,
    };
  },

  components: {
    Loading,
    Picture,
    UserGroup,
    PrizeItem,
    GrandPrize,
    CampaignName,
    StatusDropdown,
    CampaignDuration,
    CampaignDescription,
  },

  computed: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await weeklyLoginService.getById(id);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$root.$on("weekly::showLoading", (loading) => {
        this.isLoading = loading;
      });

      this.$root.$on("hook:beforeDestroy", () => {
        this.$root.$off("weekly::showLoading");
      });
    });
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}
</style>

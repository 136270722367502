<template>
  <div class="page" v-if="quota">
    <div class="page-main">
      <PageHeader :quota="quota" />
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">Quota Information - {{ quota.id }}</h3>
                <div class="panel-actions panel-actions-keep">
                  Quota Type : <b>{{ discountType }}</b>
                </div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <QuotaName :quota="quota" @updated="fetchQuota" />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown :quota="quota" @updated="fetchQuota" />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <CampaignList :quota="quota" @updated="fetchQuota" />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <QuotaAmount :quota="quota" @updated="fetchQuota" />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-8">
                      <QuotaLimitPeriod :quota="quota" @updated="fetchQuota" />
                    </div>
                  </div>
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletService } from "@/api/client";
import QuotaName from "./components/QuotaName.vue";
import PageHeader from "./components/PageHeader.vue";
import QuotaAmount from "./components/QuotaAmount.vue";
import CampaignList from "./components/CampaignList.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import QuotaLimitPeriod from "./components/QuotaLimitPeriod.vue";

export default {
  name: "LuckyLoginEdit",

  data() {
    return {
      quota: null,
    };
  },

  components: {
    QuotaName,
    PageHeader,
    QuotaAmount,
    CampaignList,
    StatusDropdown,
    QuotaLimitPeriod,
  },

  computed: {
    discountType() {
      let type = "-";

      switch (this.quota.qt_type) {
        case "points":
          type = "Get coin";
          break;
        case "coins":
          type = "Discount";
          break;
      }

      return type;
    },
  },

  methods: {
    async fetchQuota() {
      const id = this.$route.params.id;
      this.quota = await walletService.getQuotaLimitById(id);
    },
  },

  async created() {
    await this.fetchQuota();
  },
};
</script>

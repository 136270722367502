<template>
  <div class="flex-fit-content" v-if="stamp">
    <div class="form-group-row">
      <label class="mb-0">Text box 1</label>

      <input
        type="text"
        name="text1"
        maxlength="255"
        v-model="stamp.text1"
        @change="update"
        :disabled="disabled"
        class="form-control"
      />
    </div>
  </div>
</template>

<script>
import stampFormMixins from "../stamp.form.mixins";

export default {
  props: ["campaign"],

  mixins: [stampFormMixins],

  data() {
    return {
      message: {
        error: "Update Text box 1 error.",
        success: "Update Text box 1 success.",
      },
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "ongoing"].includes(this.campaign.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-fit-content {
  flex: 1 1 auto;
  margin-bottom: 2rem;
  padding: 0px 1.25rem;
}

.form-group-row {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-weight: 500;
    margin-bottom: 0px;
  }

  input {
    max-width: 250px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name
    ? _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { attrs: { "my-15px": "" } }, [
            _c("label", { staticClass: "mx-5" }, [
              _c("h4", [
                _vm._v(" #" + _vm._s(_vm.no) + " Targets Prize "),
                _c("span", { staticClass: "small light" }, [
                  _vm._v(" Recommended Size (300 x 300px) / less than 1 MB "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-group img-upload-wrapper" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-center bg-light" },
                [
                  _vm.picture
                    ? _c("img", {
                        staticClass: "img img-fluid image-preview",
                        attrs: { src: _vm.picture },
                      })
                    : _c("div", { staticClass: "image-placeholder" }),
                ]
              ),
              _c("input", {
                ref: "target-prize-image",
                staticClass: "form-control",
                attrs: {
                  type: "file",
                  accept: "image/*",
                  disabled: _vm.disabled,
                },
                on: { change: _vm.uploadImage },
              }),
              _vm.picture
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-round btn-default btn-remove-image",
                      attrs: { type: "button", disabled: _vm.disabled },
                      on: { click: _vm.removeImage },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-trash-o",
                        staticStyle: { "font-size": "18px" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
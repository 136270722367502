<template>
  <form class="form-horizontal" v-if="campaign">
    <div class="row">
      <div class="form-group col-md-6">
        <CampaignName :campaign="campaign" />
      </div>
      <div class="form-group col-md-3">
        <StatusDropdown :campaign="campaign" @updated="$emit('updated')" />
      </div>
    </div>
    <hr />

    <div class="row">
      <CampaignDuration :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <BackgroundImage :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <TargetAndReward :campaign="campaign" />
    </div>
    <hr />
    <div class="row">
      <TargetPrize :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <TargetSKU :campaign="campaign" />
    </div>
    <hr />
    <div class="row">
      <TargetAndRewardValues :campaign="campaign" @updated="$emit('updated')" />
    </div>
    <hr />
    <div class="row">
      <ProgressAndTargetReset :campaign="campaign" />
    </div>
  </form>
</template>

<script>
import TargetSKU from "./components/TargetSKU.vue";
import TargetPrize from "./components/TargetPrize.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import TargetAndReward from "./components/TargetAndReward.vue";
import BackgroundImage from "./components/BackgroundImage.vue";
import CampaignDuration from "./components/CampaignDuration.vue";
import CampaignName from "@/views/progressbar/components/CampaignName.vue";
import TargetAndRewardValues from "./components/TargetAndRewardValues.vue";
import ProgressAndTargetReset from "./components/ProgressAndTargetReset.vue";

export default {
  name: "BarcodeForm",

  props: ["campaign"],

  components: {
    TargetSKU,
    TargetPrize,
    CampaignName,
    StatusDropdown,
    TargetAndReward,
    BackgroundImage,
    CampaignDuration,
    TargetAndRewardValues,
    ProgressAndTargetReset,
  },
};
</script>

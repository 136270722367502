<template>
  <div id="vsm-user-bulk-upload">
    <div v-if="!status.status || status.status == 'done'">
      <div class="wrapper-upload-form">
        <label
          class="btn btn-round btn-primary w-150 mb-5"
          for="bulk-add-input"
        >
          <i class="fa fa-upload" aria-hidden="true"></i>
          Bulk Update
        </label>

        <input
          type="file"
          id="bulk-add-input"
          class="input-select-file"
          @change="handleFileUploadChange"
        />
      </div>
    </div>
    <div v-else-if="status.status == 'ready' || status.status == 'loading'">
      <label class="label-blue" :hidden="status.status == 'ready'">
        Verifying...
      </label>
      <div>
        <button
          type="button"
          class="btn btn-round btn-primary w-150 mb-5"
          @click="showConfirmPopup"
          :disabled="status.status == 'loading'"
        >
          Commit
        </button>
      </div>
    </div>
    <div v-else-if="status.status == 'running'">
      <label class="label-blue"> Updating... </label>
      <div>
        <button
          type="button"
          class="btn btn-round btn-primary w-150 mb-5"
          disabled
        >
          Commit
        </button>
      </div>
    </div>
    <div class="form-group">
      <a :href="downloadExampleLink">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Download template
      </a>
    </div>

    <modal
      :width="600"
      height="auto"
      :clickToClose="false"
      name="try-bulk-add-vsm-user-modal"
    >
      <TryBulkAddModal
        :result="result"
        @confirm="confirmUpload"
        name="try-bulk-add-vsm-user-modal"
      />
    </modal>
  </div>
</template>

<script>
import { VSMUserService } from "@/api/client";
import TryBulkAddModal from "./TryBulkAddModal.vue";

export default {
  name: "VSMUserBulkUpdate",

  components: { TryBulkAddModal },

  data() {
    return {
      file: null,
      status: {},
      result: null,
      pullingId: null,
    };
  },

  watch: {
    status(n) {
      if (n.status == "loading" && this.pullingId == null) {
        this.startPullingStatus();
        return;
      }
      if (n.status == "ready") {
        this.stopPullingStatus();
        this.result = n;
        return;
      }
      if (n.status == "running" && this.pullingId == null) {
        this.startPullingStatus();
        return;
      }
      if (n.status == "done") {
        this.stopPullingStatus();
        this.status = {};

        this.$emit("done");
        return;
      }
    },
  },

  computed: {
    downloadExampleLink() {
      return `${
        process.env.VUE_APP_WALLET_UPLOAD_DIR
      }/example/vsm_user.xlsx?ts=${new Date().getTime()}`;
    },
  },

  methods: {
    async confirmUpload(isConfirm) {
      try {
        await VSMUserService.executeBulkUpdate(isConfirm);

        isConfirm === true
          ? this.$notify("Confirm success")
          : this.$notify("Cancel upload");

        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    showConfirmPopup() {
      this.$modal.show(`try-bulk-add-vsm-user-modal`);
    },

    cancelProcess() {},

    async handleFileUploadChange({ target }) {
      this.file = target.files[0];
      target.value = null;

      try {
        if (!this.file) return;

        const form = new FormData();
        form.append("file", this.file);

        const { task_id } = await VSMUserService.bulkUpdate(form);

        this.taskID = task_id;

        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Upload Bulk error");
      }
    },

    async getUploadStatus() {
      this.status = await VSMUserService.checkBulkUpdateStatus();
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 3000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  created() {
    this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
#vsm-user-bulk-upload {
  top: 30%;
  right: -25px;
  min-width: 180px;
  position: absolute;
}

.input-select-file {
  width: 0px;
  visibility: hidden;
}

.btn-primary {
  &[disabled="disabled"] {
    color: #c1c1c1;
    cursor: not-allowed;
    border: 1px solid #c1c1c1;
    background-color: #e5eaec90;
  }
}
</style>

<template>
  <div class="mt-10 mb-15">
    <p class="mt-5 mb-15">SKU Discount</p>
    <div v-if="item.sku_id_list">
      <div
        v-for="({ sku_id, discount, sku_cp_id }, index) in list"
        :key="sku_id"
      >
        <table class="table">
          <tr>
            <td>
              <span class="d-flex align-items-center">
                No. {{ index + 1 }}
              </span>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <span class="px-10">name</span>
                <select
                  class="form-control"
                  @input="updateSkuId(sku_cp_id, discount)"
                >
                  <option
                    :key="master.sku_id"
                    :value="master.sku_id"
                    v-for="master in SKUMaster"
                    :disabled="isUsed(master.sku_id)"
                    :selected="master.sku_id == sku_id"
                  >
                    {{ master.sku_name }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <span class="px-10">Max discount per unit</span>
                <input
                  min="1"
                  width-100px
                  type="number"
                  :value="discount"
                  class="form-control"
                  @input="updateDiscount(sku_cp_id, sku_id)"
                />
                <span class="px-10">Bht.</span>
              </div>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-default"
                @click="removeSkuDiscount(sku_id)"
              >
                <i class="icon md-delete" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>
        </table>
      </div>

      <div class="action">
        <button
          type="button"
          class="btn btn-default"
          @click="addSKU"
          :disabled="item.sku_id_list.length >= SKUMaster.length"
        >
          <i class="icon md-plus" aria-hidden="true"> </i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { walletService } from "@/api/client";

export default {
  name: "SKUDiscountSettings",

  props: ["item"],

  data() {
    return {
      list: [],
      SKUMaster: [],
    };
  },

  watch: {
    "item.sku_id_list"(n) {
      const a = n.map((o) => {
        const f = this.list.findIndex((l) => l.sku_cp_id == o.sku_cp_id);
        if (f < 0) {
          return { ...o, index: n.length - 1 };
        } else {
          return { ...o, index: f };
        }
      });

      this.list = _.sortBy(a, (o) => o.index);
    },
  },

  methods: {
    async fetchSKUMaster() {
      this.SKUMaster = await walletService.findMasterSku();
    },

    isUsed(skuId) {
      const index = this.item.sku_id_list.findIndex((i) => i.sku_id === skuId);
      return index > -1;
    },

    getAvailableSKU() {
      const master = this.SKUMaster.map((i) => i.sku_id);
      const selected = this.item.sku_id_list.map((i) => i.sku_id);
      const diff = _.xor(master, selected, _.isEqual);
      return diff.length > 0 ? diff[0] : -1;
    },

    async addSKU() {
      const skuId = this.getAvailableSKU();

      await walletService.createSku({
        sku_id: skuId,
        discount: 1,
        cp_id: this.item.id,
      });

      this.$notify("Updated");
      this.$emit("updated");
    },

    async removeSkuDiscount(skuId) {
      await walletService.deleteSkuFromDiscount(skuId, this.item.id);

      this.$notify("Updated");
      this.$emit("updated");
    },

    updateSkuId(sku_cp_id, discount) {
      const skuId = event.currentTarget.value;
      this.update(+sku_cp_id, {
        sku_id: +skuId,
        discount: +discount,
        sku_cp_id: +sku_cp_id,
      });
    },

    updateDiscount(sku_cp_id, skuId) {
      const discount = event.currentTarget.value;
      this.update(+sku_cp_id, {
        sku_id: +skuId,
        discount: +discount,
      });
    },

    async update(sku_cp_id, { sku_id, discount }) {
      const cp_id = this.item.id;
      await walletService.updateSkuDiscount(sku_cp_id, {
        cp_id,
        sku_id,
        discount,
      });
      this.$notify("Updated");
      this.$emit("updated");
    },
  },

  mounted() {
    this.list = this.item.sku_id_list;
  },

  async created() {
    await this.fetchSKUMaster();
  },
};
</script>

<style lang="scss" scoped>
td {
  &:first-child {
    vertical-align: middle;
  }
}
</style>

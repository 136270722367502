export default {
  computed: {
    shouldWarning() {
      const { environment_list } = this.theme;
      return Array.isArray(environment_list) && environment_list.length > 0;
    },
  },
  methods: {
    async warning() {
      const { environment_list } = this.theme;

      const usedBy = Array.isArray(environment_list)
        ? environment_list.toString().replace(/\,/g, ", ").toUpperCase()
        : environment_list;

      const options = {
        cancelLabel: "Cancel Change",
        okLabel: "Commit Change",
      };

      const { ok } = await this.$dialogs.confirm(
        `Warning!: This change will immediately effect the following environments: ${usedBy}`,
        options
      );

      return ok;
    },
  },
};

import qs from "qs";
import HttpRequest from "./request";

export class VSMUserProvider extends HttpRequest {
  constructor() {
    super(`${process.env.VUE_APP_WALLET_API_URL}/ecp/api`);
  }

  async searchVSMUsers(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const { data } = await this.get(`/campaign/vsm/list${queryString}`);
    return data;
  }

  async downloadVSMUsers(params) {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return await this.axiosInstance.get(
      `/campaign/vsm/list/download${queryString}`,
      {
        responseType: "blob",
      }
    );
  }

  async getVSMUserInfo(id) {
    const { data } = await this.get(`/campaign/vsm/view/${id}`);
    return data;
  }

  async updateVSMUserInfo(id, form) {
    const { data } = await this.update(`/campaign/vsm/update/${id}`, form);
    return data;
  }

  async generatePassword() {
    const { data } = await this.get(`/campaign/vsm/genpassword`);
    return data;
  }

  async bulkUpdate(form) {
    const { data } = await this.upload(`/campaign/vsm/upload`, form);
    return data;
  }

  async checkBulkUpdateStatus() {
    const { data } = await this.get(`/campaign/vsm/upload_status`);
    return data;
  }

  async executeBulkUpdate(confirm) {
    const { data } = await this.get(
      `/campaign/vsm/execute?confirm=${confirm === true ? "y" : "n"}`
    );
    return data;
  }
}

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Point Quota Limit</h1>
      <breadcrumb name="POINT_QUOTA_LIMIT"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-row-dblclick="goToEdit"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'amt_max'">
                  {{ props.row.amt_max | numberWithCommas }}
                </div>
                <div v-else-if="props.column.field == 'amt_remain'">
                  <span :class="{ red: props.row.amt_remain <= 0 }">
                    {{ props.row.amt_remain | numberWithCommas }}
                  </span>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="900" height="auto">
      <CreateModal @submit="fetch" />
    </modal>
  </div>
</template>

<script>
import { walletService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import CreateModal from "@/views/point/PointQuotaLimit/CreateModal.vue";
import { pointQuotaLimitTableColumns } from "@/views/point/model";

export default {
  name: "PointQuotaLimit",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      campaigns: [],
      hideArchive: false,
      columns: pointQuotaLimitTableColumns,
    };
  },

  components: { CreateModal },

  created() {
    this.fetch();
  },

  watch: {
    hideArchive() {
      this.filter();
    },

    campaigns(v) {
      this.list = v;
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    goToEdit(event) {
      this.$router
        .push({
          name: "PointCampaignPointQuotaLimitEdit",
          params: { id: event.row.id },
        })
        .catch((err) => {});
    },

    async fetch(show_all) {
      this.campaigns = await walletService.getQuotaList({
        show_all: show_all,
      });
    },

    filter() {
      this.hideArchive
        ? (this.list = this.campaigns.filter(
            (p) => p.status.toUpperCase() != "ARCHIVED"
          ))
        : (this.list = this.campaigns);
    },

    async reloadTable() {
      await this.fetch();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-modal`);
    },
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }

  td .red {
    color: red;
  }
}
</style>

<template>
  <div class="col-md-8" v-if="name">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            #{{ no }} Targets Prize
            <span class="small light">
              Recommended Size (300 x 300px) / less than 1 MB
            </span>
          </h4>
        </label>

        <div class="form-group img-upload-wrapper">
          <div class="d-flex justify-content-center bg-light">
            <img
              class="img img-fluid image-preview"
              v-if="picture"
              :src="picture"
            />
            <div v-else class="image-placeholder"></div>
          </div>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="uploadImage"
            :disabled="disabled"
            ref="target-prize-image"
          />

          <button
            v-if="picture"
            type="button"
            @click="removeImage"
            :disabled="disabled"
            class="btn btn-round btn-default btn-remove-image"
          >
            <i class="fa fa-trash-o" style="font-size: 18px"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["no", "name", "campaign"],

  data() {
    return {
      file: null,
    };
  },
  computed: {
    picture() {
      return this.campaign.barcode[this.name];
    },

    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    clearInputRef() {
      this.$nextTick(() => {
        this.$refs["target-prize-image"].value = "";
      });
    },

    async removeImage() {
      const options = { cancelLabel: "No", okLabel: "Yes", size: "sm" };

      const { ok } = await this.$dialogs.confirm(
        "Do you want to remove this prize picture?",
        options
      );

      if (!ok) return;

      try {
        const { id } = this.campaign;

        const form = {
          barcode: {
            [this.name]: null,
          },
        };

        await progressBarService.updateCampaignInfo(id, form);

        this.clearInputRef();
        this.$emit("updated");
        this.$notify("Picture was removed.");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Remove Target prize picture error.`);
      }
    },

    async uploadImage(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append(this.name, file);

        const { id } = this.campaign;

        await progressBarService.fileUpload(id, "barcode", form);

        this.clearInputRef();
        this.$emit("updated");
        this.$notify(`Target prize picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Target prize picture error.`);
      }
    },
  },

  mounted() {
    this.barcode = this.campaign.barcode;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
}

.image-preview,
input[type="file"] {
  max-width: 420px;
}

.image-placeholder {
  padding: 12px;
  max-width: 420px;
  min-height: 200px;
}

.img-upload-wrapper {
  width: 420px;
  position: relative;

  .btn-remove-image {
    right: 1rem;
    bottom: 1rem;
    position: absolute;
    box-shadow: 1px 2px 6px #9e9e9e;

    &:disabled {
      opacity: 1;
      color: #76838f80;
    }
  }
}
</style>

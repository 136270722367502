export class UserGroupModel {
  group_code = "";
  auto_number = "";
  description = "";
  reason_description = "";
  status = "active";
}
export const userGroupTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
    sortable: true,
    tdClass: 'text-center',
  },
  {
    label: "Auto Number",
    field: "auto_number",
    sortable: true,
    width: "220px",
    tdClass: 'text-center',
  },
  {
    label: "Group Code",
    field: "group_code",
    sortable: true,
    width: "180px",
    tdClass: 'text-center',
  },
  {
    label: "Reason Description",
    field: "reason_description",
    sortable: true,
    width: "30%",
  },
  {
    label: "Status",
    field: "status",
    sortable: true,
    tdClass: 'text-center',
  },
  {
    label: "Created date",
    field: "created_time",
    sortable: true,
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss", // expects 2018-03-16
    dateOutputFormat: "dd/MM/yyyy HH:mm", // outputs Mar 16th 2018
  },
  {
    label: "Last Modified",
    field: "modified_time",
    sortable: true,
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss", // expects 2018-03-16
    dateOutputFormat: "dd/MM/yyyy HH:mm", // outputs Mar 16th 2018
  },
  {
    label: "Modified by",
    field: "modified_by_username",
    sortable: true,
    tdClass: 'text-center',
  },
];

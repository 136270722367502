var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { attrs: { "d-inline-block": "", "width-150px": "" } }, [
            _vm._v("Redirect URL"),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.campaign.redirect_url,
                expression: "campaign.redirect_url",
              },
            ],
            staticClass: "form-control mx-10 d-inline",
            attrs: { type: "text", "width-400px": "", disabled: !_vm.canEdit },
            domProps: { value: _vm.campaign.redirect_url },
            on: {
              change: _vm.update,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.campaign, "redirect_url", $event.target.value)
              },
            },
          }),
          _vm._v(" (from result page) "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
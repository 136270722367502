<template>
  <div class="panel panel-bordered mb-0">
    <div class="panel-heading">
      <h3 class="panel-title">Create Weekly Login Campaign</h3>
    </div>
    <div class="panel-body">
      <form class="form-horizontal">
        <div
          class="form-group row"
          :class="{ '.is-invalid': $v.campaign.name.$error }"
        >
          <label class="col-md-0 form-control-label form__label"
            ><h5>Campaign Name</h5>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              ref="name"
              class="form-control form__input"
              v-model="campaign.name"
              autocomplete="off"
              @input="showValid('name')"
            />
            <nameValidation :$v="$v" v-if="showErrorNameValidation" />
          </div>
        </div>
        <div class="form-group row">
          <h5 class="col-md-12">Copy From</h5>
          <div class="col-md-12">
            <select class="form-control col-md-12" v-model="copyFromId">
              <option value="" disabled>Select Copy From</option>
              <option
                v-for="campaign in campaigns"
                :key="campaign.id"
                :value="campaign.id"
              >
                {{ ` ${campaign.id} - ${campaign.name}` }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="panel-footer">
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="close"
        >
          <i class="icon md-close" aria-hidden="true"></i>
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="addCampaign"
        >
          <i class="icon md-check" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { weeklyLoginService } from "@/api/client";

import nameValidation from "./validation/name";
import { responseErrorMapping } from "./model/error";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "CreateModal",

  props: ["name"],

  components: {
    nameValidation,
  },

  validations: { campaign: validateRules },

  data() {
    return {
      campaign: {},
      campaigns: [],
      copyFromId: null,
      showErrorNameValidation: false,
    };
  },

  methods: {
    async isFormValid() {
      if (this.$v.campaign.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Campaign Name ให้ถูกต้อง");
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    close() {
      this.$modal.hide(this.name);
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },

    async getFormData() {
      // เลือก copy from
      if (this.copyFromId) {
        const sourceCampaign = await weeklyLoginService.getById(
          this.copyFromId
        );

        return { ...sourceCampaign, name: this.campaign.name };
      }

      // ไม่ได้เลือก copy from
      else {
        return {
          name: this.campaign.name,
        };
      }
    },

    async addCampaign() {
      try {
        const body = await this.getFormData();
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await weeklyLoginService.createCampaign(body);
        await this.$dialogs.alert("Create campaign Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },

  async mounted() {
    this.campaigns = await weeklyLoginService.getCampaignList();
  },
};
</script>

<template>
  <div id="target-campaign" class="row">
    <div class="col-md-12">
      <label class="form-control-label">
        <h4>Target Campaigns</h4>
      </label>

      <div class="row-and-action">
        <div
          class="target-records"
          v-for="(target, index) in budget.campaign_list"
          :key="target.id"
        >
          <div class="no">No.{{ index + 1 }}</div>

          <label>Application</label>

          <div class="input-group">
            <input
              type="text"
              readonly="true"
              class="form-control"
              :disabled="disabled"
              :value="getApplicationName(target)"
            />

            <div
              v-if="!disabled"
              class="input-group-append"
              @click="handleTargetClick(target)"
            >
              <span class="input-group-text">
                <i class="icon mdi mdi-open-in-new" aria-hidden="true"></i>
              </span>
            </div>
          </div>

          <label>App Campaign ID</label>

          <div class="input-group">
            <input
              type="text"
              readonly="true"
              class="form-control"
              :disabled="disabled"
              :value="target.campaign_id"
            />
            <div
              v-if="!disabled"
              class="input-group-append"
              @click="handleTargetClick(target)"
            >
              <span class="input-group-text">
                <i class="icon mdi mdi-open-in-new" aria-hidden="true"></i>
              </span>
            </div>
          </div>

          <div class="action">
            <button
              type="button"
              :disabled="disabled"
              class="btn btn-default"
              @click.stop="showConfirmDelete(target)"
            >
              <i class="icon md-delete" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div class="add-action">
          <button
            type="button"
            class="btn btn-round btn-primary"
            @click="addNewTarget"
            :disabled="disabled"
          >
            <i aria-hidden="true" class="icon md-plus"></i> Add Target
          </button>
        </div>
      </div>
    </div>

    <modal name="application-picker-modal" :width="500" height="auto">
      <ApplicationPicker
        :target="target"
        @confirm="confirmSelect"
        :applications="applications"
        name="application-picker-modal"
      />
    </modal>
  </div>
</template>

<script>
import { budgetService } from "@/api/client";
import ApplicationPicker from "./ApplicationPicker.vue";

export default {
  props: ["budget"],

  components: { ApplicationPicker },

  data() {
    return {
      target: null,
      applications: [],

      message: {
        error: "Update Target campaign error.",
        success: "Update Target campaign success.",
      },
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.budget.status);
    },
  },

  methods: {
    getApplicationName(target) {
      const found = this.applications.find((o) => o.app_id == target.app_id);
      return found ? found.display_name : "Not Selected";
    },

    handleTargetClick(target) {
      this.target = target;
      this.$modal.show(`application-picker-modal`);
    },

    confirmSelect(item) {
      this.update(item);
    },

    showConfirmDelete(target) {
      const application = this.getApplicationName(target);

      const r = confirm(
        `Do you really want to delete campaign ${application} - ${target.campaign_id} ?`
      );

      if (r === true) {
        this.deleteTarget(target.id);
      }
    },

    async getBudgetApplication() {
      this.applications = await budgetService.getBudgetApplication();
    },

    async addNewTarget() {
      try {
        await budgetService.createTargetCampaign(this.budget.id);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(this.message.error);
      }
    },

    async deleteTarget(id) {
      try {
        await budgetService.deleteTargetCampaign(this.budget.id, id);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(this.message.error);
      }
    },

    async update({ id, app_id, campaign_id }) {
      try {
        const form = { app_id, campaign_id };

        if (!app_id || !campaign_id) return;

        await budgetService.updateTargetCampaign(this.budget.id, id, form);

        this.$emit("updated");
        this.$notify(this.message.success);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || this.message.error);
      }
    },

    async initial() {
      await this.getBudgetApplication();
    },
  },

  mounted() {
    this.initial();
  },
};
</script>

<style lang="scss" scoped>
#target-campaign {
  .target-records {
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: 4px;
    padding: 0.5rem 1rem;

    .no {
      margin-right: 1rem;
    }

    label {
      margin-bottom: 0px;
    }

    .input-group {
      cursor: pointer;
      max-width: 280px;
      margin-right: 2rem;

      input {
        &:hover {
          border-color: #3e8ef7;
        }

        &[readonly] {
          background-color: #0000;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      .input-group-text {
        color: #fff;
        box-shadow: none;
        background-color: #3e8ef7;
        border-color: #3e8ef7;
      }
    }
  }

  .row-and-action {
    margin-left: 2rem;
  }

  .add-action {
    padding: 1rem 0px;
  }
}
</style>

<template>
  <input
    ref="file"
    type="file"
    class="dropify"
    :data-errors-position="'outside'"
    :accept="options.accept || 'image/*'"
    :data-default-file="defaultFile || ''"
    :data-max-file-size="options.maxFileSize || '2M'"
    :data-allowed-file-extensions="
      options.accept.replace(/\.|,/g, '') || 'image/*'
    "
    @change="$emit('change', $event)"
  />
</template>

<script>
export default {
  name: "FileUpload",
  props: ["options", "defaultFile"],
  data() {
    return {
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happended.",
      },
      error: {
        fileSize: "The file size is too big ({{ value }} max).",
        minWidth: "The image width is too small ({{ value }}}px min).",
        maxWidth: "The image width is too big ({{ value }}}px max).",
        minHeight: "The image height is too small ({{ value }}}px min).",
        maxHeight: "The image height is too big ({{ value }}px max).",
        imageFormat: "The image format is not allowed ({{ value }} only).",
      },
    };
  },
  watch: {
    defaultFile(value) {
      this.destroyDropify();
    },
  },
  mounted() {
    const vm = this;
    $(this.$refs.file)
      .dropify({
        defaultFile: this.defaultFile,
        messages: this.messages,
      })
      .on("dropify.beforeClear", function (event, element) {
        return confirm("Do you really want to delete?");
      })
      .on("dropify.afterClear", function (event, element) {
        vm.$emit("remove", event);
      });
  },
  methods: {
    destroyDropify() {
      const dropify = $(this.$refs.file).data("dropify");
      dropify.resetPreview();
      // dropify.clearElement();
      dropify.settings.defaultFile = this.defaultFile;
      dropify.destroy();
      dropify.init();
    },
  },
};
</script>

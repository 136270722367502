export const themesTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Theme Name",
    field: "name",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss", // expects 2018-03-16
    dateOutputFormat: "dd/MM/yyyy HH:mm", // outputs Mar 16th 2018
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    label: "Modified by",
    field: "modified_by_username",
  },
  {
    label: "Published to",
    field: "published_to",
  },
];

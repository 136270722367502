var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c(
        "div",
        { staticClass: "container-fluid", attrs: { id: "pool-status-info" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "top-row" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.info.name) + " - " + _vm._s(_vm.info.id)),
                ]),
                _c("div", { staticClass: "status-type" }, [
                  _c("h4", [
                    _vm._v(
                      "Status - " +
                        _vm._s(_vm._f("toUpperCase")(_vm.info.status))
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "counter-info" }, [
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Limit (bht)")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("numberWithCommas")(_vm.info.budget_limit))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("Balance (bht)")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm._f("numberWithCommas")(_vm.info.balance))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-column" }, [
                  _c("label", [_vm._v("No. Reward Granted")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberWithCommas")(_vm.info.no_reward_granted)
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.barcode
    ? _c("div", { staticClass: "col-md-10" }, [
        _c("div", { staticClass: "mx-5" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("h4", [
              _vm._v(
                " Targets and Rewards (targets are cumulative, rewards are incremental) "
              ),
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("span", { staticClass: "w100" }, [_vm._v("No. Targets:")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.barcode.no_targets,
                      expression: "barcode.no_targets",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "form-control w100",
                  attrs: { name: "no_targets", disabled: _vm.disabled },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return _vm._n(val)
                          })
                        _vm.$set(
                          _vm.barcode,
                          "no_targets",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.update,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                  _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="form-group col-md-6">
    <label class="mx-5">
      <h4>
        Award Name
        <span class="small light"> to be shown to user</span>
      </h4>
    </label>
    <input
      type="text"
      maxlength="60"
      @change="update"
      name="award_name"
      class="form-control"
      :disabled="disabled"
      v-model="theme.award_name"
    />
  </div>
</template>

<script>
import formMixins from "../form.mixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      message: {
        error: `Update Award name error.`,
        success: `Update Award name success.`,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}
</style>

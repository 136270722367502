var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quota
    ? _c("div", { staticClass: "px-15" }, [
        _c("h4", [_vm._v("Status")]),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-round dropdown-toggle",
              staticStyle: { "text-transform": "uppercase" },
              attrs: {
                type: "button",
                "data-toggle": "dropdown",
                "aria-expanded": "false",
              },
            },
            [_vm._v(" " + _vm._s(_vm.quota.status) + " ")]
          ),
          _c(
            "div",
            { staticClass: "dropdown-menu", attrs: { role: "menu" } },
            [
              _vm.quota.status == "draft"
                ? _c("DropdownItemStatus", {
                    attrs: { text: "Activate", icon: "md-check" },
                    on: {
                      onSelect: function ($event) {
                        return _vm.update("scheduled")
                      },
                    },
                  })
                : _vm._e(),
              _vm.quota.status == "draft"
                ? _c("DropdownItemStatus", {
                    attrs: { text: "Archive", icon: "md-archive" },
                    on: {
                      onSelect: function ($event) {
                        return _vm.update("archived")
                      },
                    },
                  })
                : _vm._e(),
              _vm.quota.status == "scheduled"
                ? _c("DropdownItemStatus", {
                    attrs: { text: "Deactivate", icon: "md-long-arrow-down" },
                    on: {
                      onSelect: function ($event) {
                        return _vm.update("draft")
                      },
                    },
                  })
                : _vm._e(),
              _vm.quota.status == "archived"
                ? _c("DropdownItemStatus", {
                    attrs: { text: "Unarchive", icon: "md-long-arrow-down" },
                    on: {
                      onSelect: function ($event) {
                        return _vm.update("draft")
                      },
                    },
                  })
                : _vm._e(),
              _vm.quota.status == "tracking"
                ? _c("DropdownItemStatus", {
                    attrs: { text: "Release", icon: "md-arrow-right" },
                    on: {
                      onSelect: function ($event) {
                        return _vm.update("released")
                      },
                    },
                  })
                : _vm._e(),
              _vm.quota.status == "reached"
                ? _c("DropdownItemStatus", {
                    attrs: { text: "Release", icon: "md-arrow-right" },
                    on: {
                      onSelect: function ($event) {
                        return _vm.update("released")
                      },
                    },
                  })
                : _vm._e(),
              !_vm.quota.status
                ? _c("DropdownItemStatus", { attrs: { text: "Nothing" } })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
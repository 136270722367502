var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h1", { staticClass: "page-title" }, [_vm._v("Campaign")]),
          _c("breadcrumb", { attrs: { name: "CAMPAIGN_LIST" } }),
          _c("div", { staticClass: "page-header-actions" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-icon btn-dark btn-outline btn-round mx-10",
                attrs: { type: "button" },
                on: { click: _vm.reloadTable },
              },
              [
                _c("i", {
                  staticClass: "icon md-refresh-alt",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Reload table "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-dark btn-outline mx-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.hideArchive = !_vm.hideArchive
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.hideArchive ? "Show all" : "Hide Archive") +
                    " "
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.openCreateModal },
              },
              [
                _c("i", {
                  staticClass: "icon md-plus",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "page-content page-content-table" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "panel" }, [
            _c(
              "div",
              { staticClass: "panel-body p-0" },
              [
                _c("vue-good-table", {
                  ref: "table",
                  attrs: {
                    rows: _vm.list,
                    columns: _vm.columns,
                    "sort-options": _vm.sortOption,
                    "search-options": _vm.searchOption,
                    "pagination-options": _vm.pagination,
                  },
                  on: {
                    "on-row-click": _vm.goToEdit,
                    "on-search": _vm.onSearch,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPageChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function (props) {
                        return [
                          props.column.field == "status"
                            ? _c("span", [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toUpperCase")(props.row.status)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "modal",
        { attrs: { name: "create-modal", width: 900, height: "auto" } },
        [_c("CreateModal", { on: { submit: _vm.fetch } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
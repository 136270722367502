<template>
  <div class="row" v-if="campaign">
    <label class="d-inline mx-10 form-control-label">
      Start
    </label>
    <div class="d-inline mx-10">
      <DatePicker
        type="date"
        width-150px
        @change="update"
        placeholder="n/a"
        ref="start_date"
        format="YYYY-MM-DD"
        value-type="YYYY-MM-DD"
        :disabled="disabled"
        v-model="campaign.start_date"
      />
    </div>
    <div class="d-inline mx-10">No. days</div>
    <div class="d-inline mx-10">
      <input
        width-100px
        type="number"
        min="1"
        max="999"
        ref="duration"
        @change="update"
        v-model="duration"
        :disabled="disabled"
        class="form-control"
      />
    </div>
    <div class="d-inline mx-10">
      <p>End Date: {{ campaign.end_date | DATE_HUGE }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { luckyLoginService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      duration: 1,
      oldValue: null,
    };
  },

  computed: {
    minDate() {
      return new Date();
    },

    disabled() {
      return this.campaign.status != "draft";
    },
  },

  watch: {
    duration(_, __) {
      this.campaign.end_date = this.calculateEndDate();
    },
  },

  mounted() {
    const { start_date, end_date } = this.campaign;
    this.duration =
      moment(new Date(end_date)).diff(new Date(start_date), "days") + 1;

    this.oldValue = { start_date, end_date };
  },

  methods: {
    async isFormValid() {
      if (
        moment(this.campaign.start_date).diff(
          new Date().setHours(0, 0, 0, 0),
          "days"
        ) < 1
      ) {
        await this.$dialogs.alert(
          "Start date ใส่ค่าได้ตั้งแต่วันที่ในอนาคตเท่านั้น"
        );
        this.$refs.start_date.focus();

        const { start_date, end_date } = this.oldValue;
        this.setDefaultValues(start_date, end_date);

        return false;
      }

      if (this.duration <= 0) {
        await this.$dialogs.alert("No. days ต้องมากกว่า 0 ให้ถูกต้อง");
        this.$refs.duration.focus();
        return false;
      }

      if (this.duration > 999) {
        await this.$dialogs.alert("No. days ต้องไม่มากกว่า 999 ให้ถูกต้อง");
        this.$refs.duration.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const { id, start_date } = this.campaign;
        const end_date = moment(this.calculateEndDate()).format("YYYY-MM-DD");
        const form = {
          end_date: end_date,
          start_date: start_date,
        };

        await luckyLoginService.updateCampaignInfo(id, form);

        this.oldValue = { start_date, end_date };

        this.$emit("updated");
        this.$notify(`Campaign Duration updated.`);
      } catch (error) {
        this.$dialogs.alert(`Campaign Duration update error.`);
      }
    },

    calculateEndDate() {
      const { start_date } = this.campaign;
      return moment(new Date(start_date)).add(+this.duration - 1, `days`);
    },

    setDefaultValues(start, end) {
      this.campaign.end_date = end;
      this.campaign.start_date = start;

      this.duration = moment(new Date(end)).diff(new Date(start), "days") + 1;
    },
  },
};
</script>

<style></style>

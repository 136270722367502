<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Point Campaign</h1>
      <breadcrumb name="POINT_CAMPAIGN_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          @click="reloadTable"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>
        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>
        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              @on-row-dblclick="goToEdit"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span>
                    {{ props.row.status | toUpperCase }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="900" height="auto">
      <CreateModal @submit="fetch" />
    </modal>
  </div>
</template>

<script>
import { walletService } from "@/api/client";
import CreateModal from "@/views/point/CreateModal.vue";
import { campaignTableColumns } from "@/views/point/model";

export default {
  name: "CampaignList",
  data() {
    return {
      list: [],
      campaigns: [],
      hideArchive: false,
      pagination: {
        perPage: 10,
        enabled: true,
      },
      searchOption: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
      },
      columns: campaignTableColumns,
    };
  },

  components: { CreateModal },

  async created() {
    await this.fetch();
  },

  watch: {
    hideArchive() {
      this.filter();
    },

    campaigns(v) {
      this.list = v;
    },

    async "$route.query"() {
      this.initialTableState();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    onPageChange(params) {
      if (params.total > 0) {
        this.updateParams(this.list.length);
      }
    },

    goToEdit(event) {
      this.$router
        .push({ name: "PointCampaignEdit", params: { id: event.row.id } })
        .catch((err) => {});
    },

    updateParams(newProps) {
      this.pagination.perPage = Object.assign(
        {},
        this.pagination.perPage,
        newProps
      );
    },

    filter() {
      this.hideArchive
        ? (this.list = this.campaigns.filter(
            (p) => p.status.toUpperCase() != "ARCHIVED"
          ))
        : (this.list = this.campaigns);
    },

    async reloadTable() {
      await this.fetch();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-modal`);
    },

    async fetch() {
      this.campaigns = await walletService.getCampaignList({ show_all: 1 });
    },

    onSearch(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            searchTerm: params.searchTerm,
          }),
        })
        .catch((err) => {});
    },

    onSortChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            sortBy: params[0].field,
            sortDir: params[0].type,
          }),
        })
        .catch((err) => {});
    },

    onPageChange(params) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            perPage: params.currentPerPage,
            page: params.currentPage,
          }),
        })
        .catch((err) => {});
    },

    initialTableState() {
      //default
      if (!this.$route.query.sortBy || !this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: "id",
          type: "desc",
        };

      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = +this.$route.query.page;
      if (this.$route.query.perPage)
        this.pagination.perPage = +this.$route.query.perPage;
    },

    initialTableSearchState() {
      // Searching
      if (this.$route.query.searchTerm)
        this.$refs["table"].globalSearchTerm = this.$route.query.searchTerm;
    },
  },
};
</script>

<style></style>

import History from "@/views/history/Index.vue";
import HistoryList from "@/views/history/List.vue";
import HistoryView from "@/views/history/View.vue";

export default {
  path: "/history",
  name: "History",
  component: History,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/",
      name: "HistoryList",
      component: HistoryList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "view/:id",
      name: "HistoryView",
      component: HistoryView,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

<template>
  <div class="col-md-10">
    <label class="mx-5">
      <h4>Ranks and Rewards</h4>
    </label>
    <div class="form-group">
      <div class="clearfix">
        <p class="float-left">
          <a
            href="https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/game-leaderboard/game-leaderboard-test-upload.xlsx"
          >
            Click to download example
          </a>
        </p>
        <p class="float-right">
          <span class="date mr-10"> Last upload: {{ lastUploadTime }} </span>
          <span class="no-records">
            No. ranks:
            {{ campaign.num_rank_reward | numberWithCommas }}
          </span>
        </p>
      </div>

      <div v-if="status != null">
        <div class="wrapper-upload-form" v-if="status.active === 0">
          <label class="label-blue">
            Click to choose new file to upload
          </label>

          <label
            class="btn btn-round btn-upload btn-primary"
            for="rank-reward-file-upload"
            :disabled="disabled"
          >
            Edit
          </label>

          <input
            type="file"
            :disabled="disabled"
            @change="onFileSelected"
            class="input-select-file"
            id="rank-reward-file-upload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="
            status.active === 1 &&
            status.type == 'reward' &&
            status.state != 'execute'
          "
        >
          <label>
            {{ filename }}
          </label>

          <label class="label-blue" v-if="status.state == 'verify'">
            Verifying change
          </label>

          <label class="label-blue" v-else-if="status.state == 'done-verify'">
            Click to commit change
          </label>

          <div v-if="status.state == 'verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              disabled
            >
              Commit
            </button>
            <button type="button" class="btn btn-round btn-default" disabled>
              Cancel
            </button>
          </div>
          <div v-else-if="status.state == 'done-verify'">
            <button
              type="button"
              class="btn btn-round btn-primary mx-10"
              @click="getResult"
              :disabled="disabled"
            >
              Commit
            </button>
            <button
              type="button"
              class="btn btn-round btn-default"
              @click="cancelProcess"
              :disabled="disabled"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          class="wrapper-upload-form"
          v-else-if="
            status.active === 1 &&
            status.type == 'reward' &&
            status.state == 'execute'
          "
        >
          <label>
            {{ filename }}
          </label>

          <label class="label-blue">
            Processing change.
          </label>

          <button type="button" class="btn btn-round btn-primary" disabled>
            Edit
          </button>
        </div>
      </div>
    </div>
    <modal name="try-upload-rank-reward-modal" :width="400" height="auto">
      <TryUploadConfirmModal
        :campaign="campaign"
        :result="result"
        @confirm="confirmUpload"
        name="try-upload-rank-reward-modal"
      />
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { leaderBoardService } from "@/api/client";
import TryUploadConfirmModal from "./TryUploadConfirmModal.vue";

export default {
  name: "RankAndReward",

  props: ["campaign"],

  components: { TryUploadConfirmModal },

  data() {
    return {
      file: null,
      result: null,
      status: null,
      pullingId: null,
    };
  },

  computed: {
    disabled() {
      return ["archived", "end", "aborted"].includes(this.campaign.status);
    },
    lastUploadTime() {
      const time = moment(
        this.campaign.last_reward_upload,
        "YYYY-MM-DD HH:mm:ss"
      );
      return time.isValid() ? time.format("DD/MM/YYYY HH:mm") : "-";
    },
    filename() {
      return this.file ? this.file.name : "-";
    },
  },

  watch: {
    status(n) {
      if (
        n.active === 1 &&
        n.type == "reward" &&
        n.state == "verify" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "reward" &&
        n.state == "execute" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "reward" &&
        n.state == "verify" &&
        n.status == "pending" &&
        this.pullingId == null
      ) {
        this.startPullingStatus();
        return;
      }

      if (n.active === 0 || (n.type == "reward" && n.state == "done-verify")) {
        this.stopPullingStatus();
        return;
      }
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      event.target.value = null;

      this.tryUpload();
    },

    async tryUpload() {
      try {
        if (!this.file) return;
        const form = new FormData();
        form.append("excel", this.file);

        await leaderBoardService.tryUploadRankReward(
          this.campaign.id,

          form
        );

        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getResult() {
      try {
        const result = await leaderBoardService.tryUploadResultRankReward(
          this.campaign.id
        );

        if (!result.error) {
          this.result = result;
          this.$modal.show(`try-upload-rank-reward-modal`);
        } else {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async confirmUpload() {
      try {
        await leaderBoardService.tryUploadConfirmRankReward(this.campaign.id);

        this.$notify("Confirm success");
        this.getUploadStatus();
        this.$emit("updated");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async cancelProcess() {
      try {
        await leaderBoardService.tryUploadCancelRankReward(this.campaign.id);

        this.$notify("Cancel success");
        this.getUploadStatus();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getUploadStatus() {
      this.status = await leaderBoardService.getUploadStatus(this.campaign.id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 3000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  async created() {
    this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
.input-select-file {
  width: 0px;
  visibility: hidden;
}

.wrapper-upload-form {
  display: flex;
  margin: 2em auto;
  align-items: center;
  justify-content: space-evenly;

  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }

  .btn-upload {
    padding: 0.5em 2em;

    &[disabled="disabled"] {
      color: #c1c1c1;
      cursor: not-allowed;
      background-color: #e5eaec90;
    }
  }
}
</style>

import { staffEngagementService } from "@/api/client";

export default {
  props: ["round"],

  data() {
    return {
      message: {
        error: "Update error.",
        success: "Update success.",
      },
    };
  },
  computed: {
    campaign() {
      return this.$parent.campaign;
    },
    disabled() {
      return !["draft"].includes(this.campaign.status);
    },
  },
  methods: {
    // event: original event
    // model: input v-model
    async update(event, model) {
      try {
        const { id } = this.campaign;

        const name = event.target.name;
        const value = model != undefined ? model : event.target.value;

        const form = {};
        form[name] = value;

        const roundId = this.round.round_id;

        await staffEngagementService.editRound(id, roundId, form);

        this.$notify(this.message.success);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(this.message.error);
      }
    },

    async uploadImage(event) {
      const file = event.target.files[0];

      try {
        if (!file) return;

        this.validateFileUpload(event);

        const { id } = this.campaign;

        const name = event.target.name;

        const form = new FormData();
        form.append(name, file);

        await staffEngagementService.uploadPicture(id, form);

        this.$emit("updated");
        this.$notify(this.message.success);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error.message || this.message.error);
      } finally {
        event.target.value = "";
      }
    },

    async removeImage(form) {
      try {
        const { id } = this.campaign;
        await staffEngagementService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Updated");
      } catch (error) {
        this.$notify("Remove Picture error");
      }
    },

    validateFileUpload() {
      return;
    },

    initial() {
      return;
    },
  },

  mounted() {
    this.initial();
  },
};

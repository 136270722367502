var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("h4", { staticClass: "col-md-12" }, [_vm._v("Recipients")]),
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "ml-10 my-5" }, [
        _c("div", { staticClass: "form-group mb-0" }, [
          _c(
            "label",
            {
              staticClass: "btn btn-default mx-10",
              class: { disabled: !_vm.canEdit },
              attrs: { for: "prize_bulk_upload" },
            },
            [
              _c("i", {
                staticClass: "icon md-upload",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Bulk upload "),
            ]
          ),
          _vm.canEdit
            ? _c("input", {
                staticClass: "invisible",
                attrs: {
                  type: "file",
                  id: "prize_bulk_upload",
                  accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
                on: { change: _vm.onFileSelected },
              })
            : _vm._e(),
        ]),
        _c(
          "a",
          {
            staticClass: "btn btn-link btn-round",
            attrs: { target: "_blank", href: _vm.exampleFileUri },
          },
          [
            _c("i", { staticClass: "fa fa-file-excel-o" }),
            _vm._v(" Click to download example "),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "col-8" }, [
      _c("p", [
        _c("span", [_vm._v("Last upload " + _vm._s(_vm.lastUploadTime))]),
        _c("span", [
          _vm._v(
            "No. Prize " + _vm._s(_vm._f("numberWithCommas")(_vm.lot.num_prize))
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
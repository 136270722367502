<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ title }}
          </h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <p v-html="message"></p>
          </div>
        </div>
      </div>
      <div class="panel-footer p-10">
        <div class="d-flex justify-content-between px-30">
          <button
            type="button"
            class="btn btn-round btn-primary w-100"
            @click="$emit('confirm')"
          >
            <i class="icon md-check" aria-hidden="true"></i> OK
          </button>
          <button
            type="button"
            class="btn btn-round btn-default mx-10 w-100"
            @click="close"
          >
            <i class="icon md-close" aria-hidden="true"></i>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseConfirmModal",

  props: ["title", "message"],

  methods: {
    close() {
      this.$emit("cancel");

      const modalName = this.$attrs.name;
      this.$modal.hide(modalName);
    },
  },
};
</script>

import moment from "moment";

export const columns = [
  {
    label: "รหัสคูปอง",
    field: "ecp_id",
    type: "number",
  },
  {
    label: "มูลค่า",
    field: "face_value",
    type: "number",
  },
  {
    label: "Top up",
    field: "topup",
    type: "number",
  },
  {
    label: "ซื้อขั้นต่ำ (แถว)",
    field: "minimum_purchase",
  },
  {
    label: "วันหมดอายุ",
    field: "end_date",
    formatFn: function (value) {
      const date = moment(value);
      return date.isValid() ? date.format("YYYY/MM/DD HH:mm") : "";
    },
  },
  {
    label: "สถานะ",
    field: "ecp_status",
    formatFn: function (_, row) {
      return row.status;
    },
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "ชื่อร้านค้า",
    field: "shop_name",
  },
  {
    label: "DC",
    field: "group_type",
  },
  {
    label: "จังหวัด",
    field: "province",
  },
  {
    label: "อำเภอ",
    field: "district",
  },
  {
    label: "ตำบล",
    field: "subdistrict",
  },
  {
    label: "Role",
    field: "role",
  },
];

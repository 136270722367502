var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "my-30" }, [
        _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
            _vm._v(" Coupon collect expiration "),
          ]),
          _c(
            "div",
            { staticClass: "d-inline mx-10" },
            [
              _c("DatePicker", {
                ref: "coupon_collection_expiration",
                attrs: {
                  type: "datetime",
                  "width-200px": "",
                  confirm: true,
                  clearable: false,
                  placeholder: "n/a",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "value-type": "format",
                  disabled: _vm.disabled,
                },
                on: { confirm: _vm.update },
                model: {
                  value: _vm.coupon_collection_expiration,
                  callback: function ($$v) {
                    _vm.coupon_collection_expiration = $$v
                  },
                  expression: "coupon_collection_expiration",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
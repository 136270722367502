<template>
  <div class="col-md-12">
    <label class="d-inline mx-10 form-control-label">
      Date Time
    </label>
    <div class="d-inline mx-10">
      <DatePicker
        type="datetime"
        width-250px
        placeholder="n/a"
        ref="linenoti_date"
        format="YYYY-MM-DD HH:mm:ss"
        value-type="YYYY-MM-DD HH:mm:ss"
        :disabled="disabled"
        v-model="campaign.linenoti_date"
        @confirm="update"
        :confirm="true"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { luckyDrawService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  mounted() {
    const { linenoti_date } = this.campaign;
    this.oldValue = { linenoti_date };
  },

  methods: {
    async isFormValid() {
      const { start_date, end_date, linenoti_date } = this.campaign;

      var compareDate = moment(linenoti_date);
      var startDate = moment(start_date);
      var endDate = moment(end_date);

      if (!compareDate.isBetween(startDate, endDate)) {
        await this.$dialogs.alert("ช่วงเวลาจะต้องอยู่ระหว่าง Start กับ End");
        const { linenoti_date } = this.oldValue;
        this.setDefaultValues(linenoti_date);

        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const { id, linenoti_date } = this.campaign;
        const form = { linenoti_date: linenoti_date };

        await luckyDrawService.updateCampaignInfo(id, form);

        this.oldValue = { linenoti_date };

        this.$emit("updated");
        this.$notify(`Line Reminder date updated.`);
      } catch (error) {
        this.$dialogs.alert(`Line Reminder date update error.`);
      }
    },

    setDefaultValues(linenoti_date) {
      this.campaign.linenoti_date = linenoti_date;
    },
  },
};
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row vld-parent" },
    [
      _c("loading", {
        attrs: { active: _vm.isLoading, "is-full-page": false },
      }),
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "panel panel-bordered mb-0" }, [
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("h5", [
                _vm._v(" ISMS: "),
                _c("span", [_vm._v(_vm._s(_vm.detail.isms_code))]),
              ]),
              _c("h5", [
                _vm._v(" Value: "),
                _c("span", [_vm._v(" " + _vm._s(_vm.detail.total_value))]),
              ]),
              _c("h5", [
                _vm._v(" COUPON_ID: "),
                _c("span", [_vm._v(" " + _vm._s(_vm.detail.coupon_id))]),
              ]),
              _c("h5", [
                _vm._v(" Status: "),
                _c("span", [_vm._v(_vm._s(_vm.detail.job_status))]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer p-10" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center px-10 gap-5" },
            [
              _c("div", { staticClass: "action-button" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-round btn-primary w-120 mb-5",
                    attrs: { type: "button" },
                    on: { click: _vm.retry },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-refresh",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Retry "),
                  ]
                ),
                _c("p", { staticClass: "hint-text" }, [
                  _vm._v(' Check status by clicking at "Refresh Status" '),
                ]),
              ]),
              _vm.shouldDisplayStopRequestButton
                ? _c("div", { staticClass: "action-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-round btn-default mb-5",
                        attrs: { type: "button" },
                        on: { click: _vm.cancel },
                      },
                      [
                        _c("i", {
                          staticClass: "icon md-close",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" Stop Request "),
                      ]
                    ),
                    _c("p", { staticClass: "hint-text" }, [
                      _vm._v("Make sure current request has stopped."),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
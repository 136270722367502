<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">CCB2B Log</h1>
      <breadcrumb name="E_COUPON_CCB2B_LOG"></breadcrumb>
      <div class="page-header-right"></div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :columns="columns"
              :rows="list"
              :totalRows="totalRecords"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'task_id', type: 'desc' },
              }"
              :pagination-options="pagination"
              :search-options="searchOption"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-search="onSearch"
              @on-row-dblclick="onRowDoubleClick"
              @on-per-page-change="onPerPageChange"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { ccb2bLogColumns } from "@/views/ecoupon/ccb2b-log/model";

export default {
  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      page: 1,
      columns: ccb2bLogColumns,
    };
  },

  watch: {
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  methods: {
    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
          }),
        })
        .catch((err) => {});
    },

    getParams() {
      let params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }
      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },

    async fetch() {
      const params = this.getParams();

      const { data, total_records } = await ecouponService.getCCB2BLog(params);

      this.list = data;
      this.totalRecords = total_records;
    },

    onRowDoubleClick(event) {
      this.$router
        .push({
          name: "e-coupon-ccb2b-log-detail",
          params: { id: event.row.task_id },
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
</style>

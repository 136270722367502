export const tableColumns = [
  {
    label: "Theme ID",
    field: "id",
    type: "number",
  },
  {
    label: "Theme Name ",
    field: "name",
  },
  {
    label: "Pool ID",
    field: "pool_id_list",
    sortable: false,
    formatFn: (value) => (Array.isArray(value) ? value.toString() : ""),
  },
  {
    label: "Status",
    field: "status",
    formatFn: (value) => (typeof value == "string" ? value.toUpperCase() : ""),
  },
  {
    label: "Last Modified",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

<template>
  <div class="px-15" v-if="lot">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ lot.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="lot.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="lot.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="lot.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="lot.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { prizeService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["lot"],

  methods: {
    validate(status) {
      const {
        lot_name,
        prize_name,
        expire_date,
        issue_date,
        prize_source,
        picture,
        notification_time1,
        notification_time2,
        notification_time3,
      } = this.lot;

      switch (status) {
        case "scheduled":
          // Prize Lot Name ต้องมี
          if (!lot_name) {
            this.$dialogs.alert("กรุณาระบุ Lot name.");
            return false;
          }

          // Issue Date
          // ถ้าระบุวันที่ issue date ไม่ใช่วันที่ในอนาคต
          // https://stackoverflow.com/a/5646753
          if (
            moment(
              new Date(issue_date.replace(/-/g, "/")).setSeconds(0, 0)
            ).diff(new Date().setSeconds(0, 0), "minutes") < 1
          ) {
            this.$dialogs.alert("Issue date ต้องเป็นวันที่ในอนาคตเท่านั้น");

            return false;
          }

          // ถ้าระบุวันที่ issue date มากกว่าวันที่ expire date
          if (
            expire_date != null &&
            moment(issue_date).diff(
              new Date(expire_date.replace(/-/g, "/")),
              "minutes"
            ) >= 0
          ) {
            this.$dialogs.alert("Issue Date ต้องน้อยกว่า Expire Date lot");

            return false;
          }

          // ถ้าไม่ระบุวันที่ issue date
          if (!issue_date) {
            this.$dialogs.alert("กรุณาระบุค่า Issue date");

            return false;
          }

          // ถ้าไม่ระบุวันที่ expire date
          if (!expire_date) {
            this.$dialogs.alert("กรุณาระบุค่า Expire date");

            return false;
          }

          if (prize_source == "manual") {
            // Prize Name ต้องมี
            if (!prize_name) {
              this.$dialogs.alert("กรุณาระบุ Prize name.");
              return false;
            }

            // มีรูป Picture
            if (!picture) {
              this.$dialogs.alert("กรุณาระบุ picture.");
              return false;
            }

            if (
              moment(notification_time1).isValid() &&
              (moment(notification_time1) < moment(issue_date) ||
                moment(notification_time1) > moment(expire_date))
            ) {
              this.$dialogs.alert(
                "Notification time #1 ช่วงเวลาจะต้องอยู่ระหว่าง Prize Lot Date Issue กับ Expire"
              );

              return false;
            }

            if (
              moment(notification_time3).isValid() &&
              (moment(notification_time2) < moment(issue_date) ||
                moment(notification_time2) > moment(expire_date))
            ) {
              this.$dialogs.alert(
                "Notification time #2 ช่วงเวลาจะต้องอยู่ระหว่าง Prize Lot Date Issue กับ Expire"
              );

              return false;
            }

            if (
              moment(notification_time3).isValid() &&
              (moment(notification_time3) < moment(issue_date) ||
                moment(notification_time3) > moment(expire_date))
            ) {
              this.$dialogs.alert(
                "Notification time #3 ช่วงเวลาจะต้องอยู่ระหว่าง Prize Lot Date Issue กับ Expire"
              );

              return false;
            }
          }

          break;
      }

      return true;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.lot;
        const form = {
          status: status,
        };

        await prizeService.updateLot(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

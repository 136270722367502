var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-md-3" }),
              _c("label", { staticClass: "col-md-9" }, [
                _vm._v(
                  " This number must match value in Salesforce! Example: a0F25000007ZIgY (15 digits)"
                ),
              ]),
              _c(
                "label",
                { staticClass: "col-md-3 form-control-label form__label" },
                [_vm._v("Auto Number")]
              ),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.usergroup.auto_number,
                        expression: "usergroup.auto_number",
                      },
                    ],
                    ref: "auto_number",
                    staticClass: "form-control form__input",
                    class: {
                      "is-valid": !_vm.$v.usergroup.auto_number.$invalid,
                    },
                    attrs: {
                      type: "text",
                      maxlength: "255",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.usergroup.auto_number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.usergroup,
                          "auto_number",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.showErrorAutoNumberValidation
                    ? _c("autoNumberValidation", { attrs: { $v: _vm.$v } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-md-3" }),
              _c("label", { staticClass: "col-md-9" }, [
                _vm._v(" Once created, cannot be changed !"),
              ]),
              _c(
                "label",
                { staticClass: "col-md-3 form-control-label form__label" },
                [_vm._v(" Group Code ")]
              ),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("input-tag", {
                    ref: "group_code",
                    staticClass: "form-control form__input",
                    class: {
                      "is-valid": !_vm.$v.usergroup.group_code.$invalid,
                    },
                    attrs: {
                      limit: _vm.limit,
                      maxlength: "255",
                      "add-tag-on-blur": true,
                    },
                    model: {
                      value: _vm.groupCode,
                      callback: function ($$v) {
                        _vm.groupCode = $$v
                      },
                      expression: "groupCode",
                    },
                  }),
                  _vm.showErrorGroupCodeValidation
                    ? _c("groupCodeValidation", { attrs: { $v: _vm.$v } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-md-3" }),
              _c("label", { staticClass: "col-md-9" }, [
                _vm._v(
                  " This is the value to be sent to Salesforce. Example: 'ประมูล หรือจับฉลาก ค้าปลีก(GT) RT-00002593' หรือ 'ล๊อกอินสะสม Family Mart' "
                ),
              ]),
              _c(
                "label",
                { staticClass: "col-md-3 form-control-label form__label" },
                [_vm._v(" Reason Description ")]
              ),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.usergroup.reason_description,
                        expression: "usergroup.reason_description",
                      },
                    ],
                    ref: "reason_description",
                    staticClass: "form-control form__input",
                    class: {
                      "is-valid": !_vm.$v.usergroup.reason_description.$invalid,
                    },
                    attrs: {
                      type: "text",
                      maxlength: "255",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.usergroup.reason_description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.usergroup,
                          "reason_description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.showErrorReasondDscriptionValidation
                    ? _c("reasonDescriptionValidation", {
                        attrs: { $v: _vm.$v },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-close",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Cancel "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button", disabled: _vm.$v.$invalid },
                on: { click: _vm.addUserGroup },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("Create User Group Form"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
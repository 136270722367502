import Vue from "vue";
import { DateTime } from "luxon";

// create a directive to transform the model value
Vue.directive("date-full", {
  twoWay: true, // this transformation applies back to the vm
  bind: function() {
    let date = DateTime.fromISO(this.el.value)
      .setLocale("th-TH")
      .toLocaleString(DateTime.DATE_FULL);
    this.handler = function() {
      this.set(date);
    }.bind(this);
    this.el.addEventListener("input", this.handler);
  },
  unbind: function() {
    this.el.removeEventListener("input", this.handler);
  }
});

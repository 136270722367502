<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  methods: {
    validate(status) {
      const { end_date, start_date } = this.campaign;

      const {
        background_picture,
        progressbar_picture,
        target_picture_reached,
        target_picture_not_reached,
        team_reward_count,
        team_assignment_count,
      } = this.campaign.team;

      switch (status) {
        case "scheduled":
          // มีรูป Background upload
          if (!background_picture) {
            this.$dialogs.alert("กรุณาระบุ Background picture.");
            return false;
          }

          // มีรูป Progress Bar upload
          if (!progressbar_picture) {
            this.$dialogs.alert("กรุณาระบุ Progress Bar.");
            return false;
          }
          // มีรูป Target upload  ทั้งคู่
          if (!target_picture_not_reached) {
            this.$dialogs.alert("กรุณาระบุ Targets and Rewards - Unreached.");
            return false;
          }

          // มีรูป Target upload  ทั้งคู่
          if (!target_picture_reached) {
            this.$dialogs.alert("กรุณาระบุ Targets and Rewards - Reached.");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องยังเป็นอนาคต.");
            return false;
          }

          //  End date >= Start
          if (moment(end_date) < moment(start_date)) {
            this.$dialogs.alert("End date ต้องมากกว่าหรือเท่ากับ Start date.");
            return false;
          }

          // มี file Target upload และ file User Team Assignment upload
          if (!team_reward_count || team_reward_count <= 0) {
            this.$dialogs.alert("กรุณาระบุ Team Target & Reward Values");
            return false;
          }

          // มี file Target upload และ file User Team Assignment upload
          if (!team_assignment_count || team_assignment_count <= 0) {
            this.$dialogs.alert("กรุณาระบุ Users Team Assignment");
            return false;
          }

          break;
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await progressBarService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

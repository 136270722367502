<template>
  <div class="px-15" v-if="gameConfig">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase;"
      >
        {{ gameConfig.status }}
      </button>

      <div class="dropdown-menu" role="menu">
        <a
          v-if="gameConfig.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>

        <a
          v-if="gameConfig.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>

        <a
          v-if="gameConfig.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="gameConfig.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('paused')"
        >
          <i class="icon mdi mdi-pause" aria-hidden="true"></i>Pause
        </a>

        <a
          v-if="gameConfig.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('end')"
        >
          <i class="icon mdi mdi-stop" aria-hidden="true"></i>Stop
        </a>

        <a
          v-if="gameConfig.status == 'paused'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('ongoing')"
        >
          <i class="icon mdi mdi-restart" aria-hidden="true"></i>Restart
        </a>

        <a
          v-if="gameConfig.status == 'paused'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('end')"
        >
          <i class="icon mdi mdi-stop" aria-hidden="true"></i>Stop
        </a>

        <a
          v-if="gameConfig.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { gameService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  methods: {
    validate(status) {
      const { start_date, name, sunset_drive } = this.gameConfig;
      const { object, obstacle, game_duration } = sunset_drive;

      switch (status) {
        case "scheduled":
          // Name
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign name");
            return false;
          }

          if (name.length > 256) {
            this.$dialogs.alert(
              "Campaign name มีความยาวตัวอักษรไม่เกิน 256 ตัว"
            );
          }

          // Date
          if (!start_date) {
            this.$dialogs.alert("กรุณากรอก Start date");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องยังเป็นอนาคต.");
            return false;
          }

          const allOccurrences = [
            ...object.map((o) => o.no_occurrence),
            ...obstacle.map((o) => o.no_occurrence),
          ];

          const totalOccurrences = allOccurrences.reduce(
            (total, o) => total + o,
            0
          );

          if (totalOccurrences > (game_duration - 5) * 6) {
            this.$dialogs.alert(
              "จำนวน object มีมากเกินไปสำหรับ Game duration."
            );
            return false;
          }

          break;
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort game instance นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.gameConfig;

        const form = {
          status: status,
        };

        await httpService.put(`/game/${id}`, form);

        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

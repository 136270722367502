import { helpers } from "vuelidate/lib/validators";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

const password = helpers.regex("password", /^[a-z0-9_.]+$/);

export const FormCreateValidateRule = {
  username: {
    password,
    required,
    minLength: minLength(8),
    maxLength: maxLength(20),
  },
};

<template>
  <div class="page" v-if="campaign">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="PROGRESS_BAR_CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              :disabled="!canPreview"
              @click="openPreviewModal"
              class="btn btn-round btn-primary mx-10"
            >
              Preview
            </button>
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading pt-5">
                <div class="panel-title campaign-type-group">
                  <h4 class="my-0">Campaign Information - {{ campaign.id }}</h4>
                  <p class="my-0">
                    User Type: {{ campaign.user_type | progressbarUserType }}
                  </p>
                </div>
                <div class="panel-actions panel-actions-keep"></div>
                <div class="panel-title campaign-type-group">
                  <p>{{ type | toUpperCase }}</p>
                  <p>
                    User Group:
                    {{
                      campaign.user_type === "contact_id"
                        ? campaign.group.group_code
                        : "-"
                    }}
                  </p>
                </div>
              </div>
              <div class="panel-body">
                <div v-if="campaign.type == 'barcode'">
                  <BarcodeForm :campaign="campaign" @updated="fetchCampaign" />
                  <hr />
                </div>

                <div v-if="campaign.type == 'team'">
                  <TeamForm :campaign="campaign" @updated="fetchCampaign" />
                  <hr />
                </div>

                <div v-if="campaign.type == 'monthly'">
                  <MonthlyForm :campaign="campaign" @updated="fetchCampaign" />
                  <hr />
                </div>

                <div v-if="campaign.type == 'monthlyshort'">
                  <MonthlyShortForm
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                  <hr />
                </div>

                <div v-if="campaign.type == 'task'">
                  <TaskForm :campaign="campaign" @updated="fetchCampaign" />
                  <hr />
                </div>

                <div v-if="campaign.type == 'leaderboard'">
                  <LeaderBoardForm
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                </div>

                <div v-if="campaign.type == 'stamp'">
                  <StampForm
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                </div>

                <div v-if="campaign.type == 'weekly'">
                  <WeeklyForm :campaign="campaign" @updated="fetchCampaign" />
                </div>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="preview-campaign-modal" :width="400" height="auto">
      <PreviewPopup :campaign="campaign" />
    </modal>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

import TaskForm from "@/views/progressbar/Task/TaskForm.vue";
import TeamForm from "@/views/progressbar/Team/TeamForm.vue";
import StampForm from "@/views/progressbar/Stamp/StampForm.vue";
import WeeklyForm from "@/views/progressbar/Weekly/WeeklyForm.vue";
import BarcodeForm from "@/views/progressbar/Barcode/BarcodeForm.vue";
import MonthlyForm from "@/views/progressbar/Monthly/MonthlyForm.vue";
import PreviewPopup from "@/views/progressbar/components/PreviewPopup.vue";
import CampaignName from "@/views/progressbar/components/CampaignName.vue";
import StatusDropdown from "@/views/progressbar/components/StatusDropdown.vue";
import MonthlyShortForm from "@/views/progressbar/MonthlyShort/MonthlyShortForm.vue";
import LeaderBoardForm from "@/views/progressbar/LeaderBoard/LeaderBoardForm.vue";
import CampaignDuration from "@/views/progressbar/components/CampaignDuration.vue";

export default {
  name: "ProgressBarEdit",

  data() {
    return {
      campaign: null,
    };
  },

  components: {
    TaskForm,
    TeamForm,
    StampForm,
    WeeklyForm,
    BarcodeForm,
    MonthlyForm,
    CampaignName,
    PreviewPopup,
    StatusDropdown,
    LeaderBoardForm,
    MonthlyShortForm,
    CampaignDuration,
  },

  computed: {
    canPreview() {
      return ["scheduled", "ongoing"].includes(this.campaign.status);
    },
    type() {
      return this.campaign.type == "team" ? "TEAM BARCODE" : this.campaign.type;
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await progressBarService.getById(id);
    },

    async openPreviewModal() {
      this.$modal.show(`preview-campaign-modal`);
    },
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading {
  padding-top: 15px;
}
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}

.campaign-type-group {
  display: flex;
  padding-top: 5px;
  padding-bottom: 0px;
  justify-content: space-between;

  p {
    font-size: 18px;
    color: #37474f;
    margin-bottom: 0px;
  }
}
</style>

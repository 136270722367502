var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("table", { staticClass: "table" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", { attrs: { "width-150px": "" } }, [
            _vm._v("Outbid Notification"),
          ]),
          _c("td", [
            _c("div", { staticClass: "col-md-8" }, [
              _c("label", { staticClass: "switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lot.outbid_notification,
                      expression: "lot.outbid_notification",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "true-value": 1,
                    "false-value": 0,
                    disabled: !_vm.canSwitchOnOff,
                  },
                  domProps: {
                    checked: Array.isArray(_vm.lot.outbid_notification)
                      ? _vm._i(_vm.lot.outbid_notification, null) > -1
                      : _vm._q(_vm.lot.outbid_notification, 1),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.lot.outbid_notification,
                          $$el = $event.target,
                          $$c = $$el.checked ? 1 : 0
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.lot,
                                "outbid_notification",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.lot,
                                "outbid_notification",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.lot, "outbid_notification", $$c)
                        }
                      },
                      _vm.update,
                    ],
                  },
                }),
                _c("span", { staticClass: "slider round" }),
              ]),
              _c("p", { staticClass: "mx-10 d-inline" }, [
                _vm._v(
                  " " +
                    _vm._s(+_vm.lot.outbid_notification === 1 ? "ON" : "OFF") +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { authService } from "../api/client";

export default {
  namespaced: true,

  state: {
    status: "",
    token: localStorage.getItem("accessToken") || "",
    user: {},
    role: null,
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user, role }) {
      state.status = "success";
      state.token = token;
      state.user = user;
      state.role = role;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    unauthorized(state) {
      state.status = "unauthorized";
    },
  },

  actions: {
    async login({ commit }, payload) {
      commit("auth_request");

      try {
        const { username, password } = payload;

        const result = await authService.login(username, password);

        const { token, user, role } = result.data;

        localStorage.setItem("accessToken", token);
        commit("auth_success", { token, user, role });
        return { token, user };
      } catch (error) {
        commit("auth_error");
        localStorage.removeItem("accessToken");
        throw error;
      }
    },
    async logout({ commit }) {
      authService.logout();
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("accessToken");
        resolve();
      });
    },
    unauthorized({ commit }) {
      return new Promise((resolve, reject) => {
        commit("unauthorized");
        resolve();
      });
    },
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    basePath: (state) => {
      let basePath = "/";
      switch (state.role) {
        case "admin":
          basePath = "/product";
          break;
        case "report":
          basePath = "/winner";
          break;
        case "supervisor":
          basePath = "/winner";
          break;
      }

      return basePath;
    },
  },
};

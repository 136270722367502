import { required, minLength, maxLength } from "vuelidate/lib/validators";

export const FormCreateValidateRule = {
  name: {
    required,
    minLength: minLength(0),
    maxLength: maxLength(10000),
  },
  type: {
    required,
  },
};

export const FormEditValidateRule = {
  name: {
    required,
    minLength: minLength(0),
    maxLength: maxLength(10000),
  },
  type: {
    required,
  },
};

<template>
  <div class="card card-block card-inverse bg-primary text-center">
    <div class="block">
      <i class="icon md-alert-circle-o" aria-hidden="true"></i>
      <h3 class="grey-200">
        Session token was expired.
      </h3>
      <p class="font-size-16">
        please sign in again.
      </p>
    </div>
    <div
      class="card-footer card-footer-transparent card-footer-bordered text-muted"
    >
      <button
        @click="logout()"
        type="button"
        class="btn btn-block btn-round btn-outline btn-default grey-200"
      >
        Go to login page.
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnauthorizedModal",
  methods: {
    logout() {
      this.$router.push({ name: "Logout" });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
}

.icon.md-alert-circle-o {
  font-size: 100px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lot
    ? _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm.lot
          ? _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "source-option" }, [
                _c("div", { staticClass: "radio-custom radio-primary mx-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lot.prize_source,
                        expression: "lot.prize_source",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      value: "prize_theme",
                      name: "prize_source",
                      disabled: _vm.disabled,
                      id: "prize_source_prize_theme",
                    },
                    domProps: {
                      checked: _vm._q(_vm.lot.prize_source, "prize_theme"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(
                            _vm.lot,
                            "prize_source",
                            "prize_theme"
                          )
                        },
                        _vm.update,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "prize_source_prize_theme" } }, [
                    _vm._v("Prize Theme"),
                  ]),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lot.theme_id,
                        expression: "lot.theme_id",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "theme_id",
                      disabled:
                        _vm.disabled || _vm.lot.prize_source !== "prize_theme",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.lot,
                            "theme_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.update,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Not specified"),
                    ]),
                    _vm._l(_vm.options, function (value, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: value.id } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(value.id) +
                              " - " +
                              _vm._s(value.name) +
                              " "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "source-option" }, [
                _c("div", { staticClass: "radio-custom radio-primary mx-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lot.prize_source,
                        expression: "lot.prize_source",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      value: "manual",
                      name: "prize_source",
                      disabled: _vm.disabled,
                      id: "prize_source_manual",
                    },
                    domProps: {
                      checked: _vm._q(_vm.lot.prize_source, "manual"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(_vm.lot, "prize_source", "manual")
                        },
                        _vm.update,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "prize_source_manual" } }, [
                    _vm._v("Manual"),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Prize Source")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row vld-parent">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-body">
          <div class="form-group">
            <h5>
              ISMS: <span>{{ detail.isms_code }}</span>
            </h5>
            <h5>
              Value: <span> {{ detail.total_value }}</span>
            </h5>
            <h5>
              COUPON_ID: <span> {{ detail.coupon_id }}</span>
            </h5>
            <h5>
              Status: <span>{{ detail.job_status }}</span>
            </h5>
          </div>
        </div>
      </div>
      <div class="panel-footer p-10">
        <div class="d-flex justify-content-center px-10 gap-5">
          <div class="action-button">
            <button
              type="button"
              class="btn btn-round btn-primary w-120 mb-5"
              @click="retry"
            >
              <i class="icon md-refresh" aria-hidden="true"></i> Retry
            </button>
            <p class="hint-text">
              Check status by clicking at "Refresh Status"
            </p>
          </div>
          <div class="action-button" v-if="shouldDisplayStopRequestButton">
            <button
              type="button"
              class="btn btn-round btn-default mb-5"
              @click="cancel"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Stop Request
            </button>
            <p class="hint-text">Make sure current request has stopped.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecouponService } from "@/api/client";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["detail"],

  components: {
    Loading,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    shouldDisplayStopRequestButton() {
      return !["UPLOADED", "UPLOADING", "READY_TO_UPLOAD"].includes(this.detail.job_status);
    }
  },

  methods: {
    async retry() {
      try {
        this.isLoading = true;
        await ecouponService.ccb2bLogRetry(this.detail.job_id);
        this.$emit("retried");
        this.$notify("กำลังดำเนินการ กรุณากด Refresh เพื่อดู status");
        this.$modal.hide(`ccb2b-log-detail-modal`);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async cancel() {
      try {
        this.isLoading = true;
        await ecouponService.ccb2bLogCancel(this.detail.job_id);
        this.$emit("cancelled");
        this.$modal.hide(`ccb2b-log-detail-modal`);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h5 > span {
  color: #666;
}

.action-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.hint-text {
  color: #999;
  font-size: smaller;
  margin-bottom: 5px;
}
</style>

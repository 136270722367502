var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("VSM Management")]),
        _c("breadcrumb", { attrs: { name: "VSM_MANAGEMENT" } }),
        _c("bulkUpload"),
        _c("div", { staticClass: "page-header-left" }, [
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                _vm._v("Employee username"),
              ]),
              _c("div", { staticClass: "col-sm-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.employeeUsername,
                      expression: "employeeUsername",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "All" },
                  domProps: { value: _vm.employeeUsername },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.employeeUsername = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                _vm._v(" User ISMS Code"),
              ]),
              _c("div", { staticClass: "col-sm-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userCode,
                      expression: "userCode",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "All" },
                  domProps: { value: _vm.userCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.userCode = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-round btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.searchUserCode },
                },
                [_vm._v(" Search ")]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "page-content page-content-table" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "panel" }, [
          _c(
            "div",
            { staticClass: "panel-body p-0" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    rows: _vm.list,
                    columns: _vm.columns,
                    totalRows: _vm.totalRecords,
                    "sort-options": _vm.sortOption,
                    "pagination-options": _vm.pagination,
                    isLoading: _vm.loadingTable,
                    "search-options": Object.assign({}, _vm.searchOption, {
                      trigger: "enter",
                    }),
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-sort-change": _vm.onSortChange,
                    "on-page-change": _vm.onPageChange,
                    "on-per-page-change": _vm.onPerPageChange,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c("div", { staticClass: "Excel" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: { click: _vm.exportToExcel },
                          },
                          [
                            _c("i", { staticClass: "fa fa-file-excel-o" }),
                            _vm._v(" Export to Excel "),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("div", { attrs: { "my-15px": "" } }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("ckeditor", {
              attrs: {
                "read-only": _vm.disabled,
                config: {
                  extraPlugins: "colorbutton",
                },
              },
              on: { blur: _vm.submit },
              model: {
                value: _vm.term_condition,
                callback: function ($$v) {
                  _vm.term_condition = $$v
                },
                expression: "term_condition",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v(" Terms & Condition ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
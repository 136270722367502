import ProgressBar from "@/views/progressbar/Index.vue";
import ProgressBarList from "@/views/progressbar/List.vue";
import ProgressBarEdit from "@/views/progressbar/Edit.vue";
import TransactionLog from "@/views/progressbar/TransactionLog.vue";
import ProgressUpdateList from "@/views/progressbar/ProgressUpdate/List.vue";
import ProgressUpdateDetail from "@/views/progressbar/ProgressUpdate/Detail.vue";

export default {
  path: "/progress-bar",
  name: "ProgressBar",
  component: ProgressBar,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "campaigns",
      name: "ProgressBarList",
      component: ProgressBarList,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "campaigns/edit/:id",
      name: "ProgressBarEdit",
      component: ProgressBarEdit,
      meta: {
        requiresAuth: true,
        roles: ["admin"],
      },
    },
    {
      path: "update",
      name: "ProgressUpdateList",
      component: ProgressUpdateList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "update/:id",
      name: "ProgressUpdateDetail",
      component: ProgressUpdateDetail,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "transaction-log",
      name: "ProgressBarTransactionLog",
      component: TransactionLog,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "table",
          { staticClass: "table" },
          _vm._l(
            _vm.gameConfig.sunset_drive.obstacle,
            function (obstacle, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v("No. " + _vm._s(index + 1))]),
                _c("td", { staticClass: "center w-400" }, [
                  _c("p", [_vm._v(_vm._s(_vm.obstacleName[index]))]),
                  _c("img", {
                    staticClass: "img w-100",
                    class: { "w-250 h-auto": index == 2 },
                    attrs: { src: obstacle.picture },
                  }),
                ]),
                _c("td", { staticClass: "v-align-middle" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mb-10" },
                      [
                        _c("p", { staticClass: "w-120 mx-10 mb-0" }, [
                          _vm._v("No. Occurrence"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: obstacle.no_occurrence,
                              expression: "obstacle.no_occurrence",
                            },
                          ],
                          staticClass: "form-control w-100",
                          attrs: {
                            min: "0",
                            type: "number",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: obstacle.no_occurrence },
                          on: {
                            change: function ($event) {
                              return _vm.update(index)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                obstacle,
                                "no_occurrence",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mb-10" },
                      [
                        _c("p", { staticClass: "w-120 mx-10 mb-0" }, [
                          _vm._v("Penalty per object"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: obstacle.penalty_per_object,
                              expression: "obstacle.penalty_per_object",
                            },
                          ],
                          staticClass: "form-control w-100",
                          attrs: {
                            max: "0",
                            min: "-999",
                            type: "number",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: obstacle.penalty_per_object },
                          on: {
                            change: function ($event) {
                              return _vm.update(index)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                obstacle,
                                "penalty_per_object",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ])
            }
          ),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Obstacles")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _c(
          "div",
          {
            staticClass: "panel-heading",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.togglePanelBody.apply(null, arguments)
              },
            },
          },
          [
            _c("h3", { staticClass: "panel-title" }, [
              _vm._v(
                "Round " +
                  _vm._s(_vm.index + 1) +
                  " - " +
                  _vm._s(_vm.round.name)
              ),
            ]),
            _c("div", { staticClass: "panel-actions panel-actions-keep" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default mr-5",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.togglePanelBody.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isPanelBodyVisible
                    ? _c("i", { staticClass: "mdi mdi-chevron-up" })
                    : _c("i", { staticClass: "mdi mdi-chevron-down" }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { disabled: !_vm.canRemoveRound },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.removeRound(_vm.round)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-trash" })]
              ),
            ]),
          ]
        ),
        _vm.isPanelBodyVisible
          ? _c(
              "div",
              { staticClass: "panel-body" },
              [
                _c("RoundName", { attrs: { round: _vm.round } }),
                _c("RoundDuration", { attrs: { round: _vm.round } }),
                _c("RanksAndRewards", { attrs: { round: _vm.round } }),
                _c("RoundQuestions", { attrs: { round: _vm.round } }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "panel-footer p-10" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Product</h1>
      <breadcrumb name="PRODUCT_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          @click="reloadTable"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>
        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>
        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="panel">
              <div class="panel-body p-0">
                <vue-good-table
                  :columns="columns"
                  :rows="list"
                  :search-options="{ enabled: true }"
                  :sort-options="sortOption"
                  :pagination-options="pagination"
                  @on-row-click="goToEdit"
                  @on-sort-change="onSortChange"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPageChange"
                >
                  <template slot="table-row" slot-scope="props">
                    <div
                      v-if="props.column.field == 'description'"
                      v-html="props.row.description.substr(0, 60)"
                    ></div>
                    <div v-else-if="props.column.field == 'image'">
                      <span v-for="n in 4" :key="n" class="mx-5">
                        <img
                          v-if="props.row[`thumbnail${n}`]"
                          :src="props.row[`thumbnail${n}`]"
                        />
                      </span>
                    </div>
                    <div v-else-if="props.column.field == 'status'">
                      {{ props.row.status | toUpperCase }}
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12"></div>
        </div>
      </div>
    </div>

    <modal name="create-product-modal" :width="900" height="auto">
      <CreateModal @submit="fetchProducts" />
    </modal>
  </div>
</template>

<script>
import { productService } from "@/api/client";
import { ProductTableColumns } from "@/views/product/model";
import CreateModal from "@/views/product/Create.vue";

export default {
  name: "ProductList",

  data() {
    const list = [];
    const products = [];
    const hideArchive = true;
    const publicPath = process.env.BASE_URL;

    return {
      list,
      products,
      publicPath,
      hideArchive,
      columns: ProductTableColumns,
      pagination: {
        enabled: true,
      },
      sortOption: {
        enabled: true,
      },
    };
  },

  components: { CreateModal },

  created() {
    this.fetchProducts();
  },

  beforeMount() {
    this.initialTableState();
  },

  watch: {
    products(newProducts, oldProducts) {
      this.filter();
    },

    hideArchive(newProducts, oldProducts) {
      this.filter();
    },

    async "$route.query"() {
      this.initialTableState();
    },
  },

  methods: {
    filter() {
      this.hideArchive
        ? (this.list = this.products.filter((p) => p.status != "archived"))
        : (this.list = this.products);
    },

    async fetchProducts() {
      this.products = await productService.getProducts({ show_all: 1 });
    },

    goToEdit(event) {
      this.$router.push({ name: "ProductEdit", params: { id: event.row.id } });
    },

    async reloadTable() {
      await this.fetchProducts();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-product-modal`);
    },

    onSortChange(params) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          sortBy: params[0].field,
          sortDir: params[0].type,
        }),
      });
    },

    onPageChange(params) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          perPage: params.currentPerPage,
          page: params.currentPage,
        }),
      });
    },

    initialTableState() {
      // Sorting
      if (this.$route.query.sortBy || this.$route.query.sortDir)
        this.sortOption.initialSortBy = {
          field: this.$route.query.sortBy,
          type: this.$route.query.sortDir || "asc",
        };

      // Paging
      if (this.$route.query.page)
        this.pagination.setCurrentPage = parseInt(this.$route.query.page);
      if (this.$route.query.perPage)
        this.pagination.perPage = parseInt(this.$route.query.perPage);
    },
  },
};
</script>

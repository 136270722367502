<template>
  <div class="page vld-parent">
    <loading :active="isLoading" :is-full-page="false" />
    <div class="page-header">
      <h1 class="page-title">Transaction Log</h1>
      <breadcrumb name="E_COUPON_TRANSACTION_LOG"></breadcrumb>
      <div class="page-header-right">
        <div class="download-txlog">
          <span>Select daily txlog</span>
          <select class="form-control w-200" v-model="txlogsSelected">
            <option v-for="(file, index) in txlogs" :value="file" :key="index">
              {{ file }}
            </option>
          </select>
          <button
            @click="downloadLog"
            class="btn btn-default"
            :disabled="txlogs.length <= 0 || !txlogsSelected"
          >
            <i class="fa fa-file-excel-o"></i>
            Download
          </button>
        </div>

        <div class="select-period">
          <span> Select Period</span>
          <date-picker
            range
            type="date"
            ref="period"
            v-model="fromTo"
            :editable="false"
            value-type="format"
            format="YYYY-MM-DD"
            @clear="fromTo = []"
            @open="onPeriodFocus"
            @change="onPeriodChange"
            :disabled="!canSelectPeriod"
            placeholder="Select date range"
          />
        </div>
      </div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :columns="columns"
              :rows="list"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-search="onSearch"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <div slot="table-actions">
                <div v-if="taskStatus === null" class="export-fetching-state">
                  <i class="mdi mdi-loading mdi-spin mdi-24px"></i>
                  <p class="my-0">Fetching status...</p>
                </div>

                <div v-else-if="taskStatus.status == null" class="Excel">
                  <button class="btn btn-default" @click="createTask">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>

                <div
                  v-else-if="taskStatus.status == 'preparing'"
                  class="export-preparing-state"
                >
                  <p>Preparing Export...</p>
                  <button
                    class="btn btn-warning"
                    @click="$modal.show('task-confrim-cancel-modal')"
                  >
                    Cancel
                  </button>
                </div>

                <div
                  v-else-if="taskStatus.status == 'ready'"
                  class="export-preparing-state"
                >
                  <button class="btn btn-primary" @click="downloadExport">
                    Download Export
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal List -->
    <modal
      :width="500"
      height="auto"
      :clickToClose="false"
      draggable=".panel-heading"
      name="run-task-confrim-modal"
    >
      <ConfirmModal
        title="Warning!"
        @confirm="confirmCreateTask"
        :message="createTaskConfirmMessage"
        name="run-task-confrim-modal"
      />
    </modal>

    <modal
      :width="500"
      height="auto"
      :clickToClose="false"
      draggable=".panel-heading"
      name="task-confrim-cancel-modal"
    >
      <ConfirmModal
        title="Warning!"
        @confirm="confirmCancelTask"
        name="task-confrim-cancel-modal"
        message="Cancel the current export operation?"
      />
    </modal>

    <modal
      :width="500"
      height="auto"
      :clickToClose="false"
      draggable=".panel-heading"
      name="task-confrim-change-period-modal"
    >
      <ConfirmModal
        title="Warning!"
        @cancel="cancelPeriodChange"
        @confirm="confirmPeriodChange"
        name="task-confrim-change-period-modal"
        message="Existing export file will be deleted."
      />
    </modal>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { ecpEcouponService, ecouponService } from "@/api/client";
import ConfirmModal from "@/components/shared/BaseConfirmModal.vue";
import { transactionLogTableColumns as columns } from "@/views/ecoupon/transaction-log/model";

export default {
  mixins: [vueGoodTableMixins],

  data() {
    return {
      columns,
      list: [],
      fromTo: [],
      field: "qrcode",
      type: 1,
      page: 1,
      txlogs: [],
      txlogsSelected: "",
      createTaskConfirmMessage: "",

      isLoading: false,
      pullingId: null,
      taskStatus: null,

      disabledPeriod: false,
    };
  },

  components: {
    Loading,
    ConfirmModal,
  },

  watch: {
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },

    fromTo() {
      this.onPeriodChange();
    },

    taskStatus({ status }) {
      if (status == "preparing" && this.pullingId == null) {
        this.startPullingStatus();
        return;
      }

      if (status == "ready") {
        this.stopPullingStatus();
        return;
      }

      if (status === null && this.pullingId != null) {
        this.stopPullingStatus();
        return;
      }
    },
  },

  computed: {
    canSelectPeriod() {
      return (
        !this.disabledPeriod &&
        this.taskStatus != null &&
        this.taskStatus.status != "preparing"
      );
    },
  },

  methods: {
    onPeriodChange() {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: 1,
            from: this.fromTo[0],
            to: this.fromTo[1],
          }),
        })
        .catch((err) => {});
    },

    getParams() {
      let params = {
        from: this.fromTo[0],
        to: this.fromTo[1],
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }
      if (searchTerm) {
        params.search = searchTerm;
      }

      params.count = pageSize || 10;

      return params;
    },

    async fetch() {
      try {
        if (this.fromTo.length != 2) return;
        this.isLoading = true;
        const params = this.getParams();
        const { data, total_records } =
          await ecpEcouponService.getTransactionLog(params);
        this.list = data;
        this.totalRecords = total_records;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchTransactionLog() {
      const { data } = await ecouponService.getDWZipFolderList();
      if (data && Array.isArray(data)) {
        this.txlogs = data;
      }
    },

    downloadLog() {
      if (!this.txlogsSelected) return;
      ecouponService.downloadDWgetzipfile(this.txlogsSelected);
    },

    async onPeriodFocus() {
      if (this.taskStatus && this.taskStatus.status == "ready") {
        this.disabledPeriod = true;
        this.$modal.show("task-confrim-change-period-modal");
      }
    },

    confirmPeriodChange() {
      this.$modal.hide("task-confrim-change-period-modal");
      this.disabledPeriod = false;
      this.cancelTask();
    },

    cancelPeriodChange() {
      this.disabledPeriod = false;

      setTimeout(() => {
        this.$refs.period.closePopup();
        document.querySelector(".mx-datepicker-main").style.opacity = 0;
      }, 25);

      setTimeout(() => {
        document.querySelector(".mx-datepicker-main").style.opacity = 1;
      }, 100);
    },

    async confirmCancelTask() {
      this.cancelTask();
      this.$modal.hide("task-confrim-cancel-modal");
    },

    async confirmCreateTask() {
      this.taskStatus = {
        status: "preparing",
      };
      this.runTask();
      this.$modal.hide("run-task-confrim-modal");
      this.startPullingStatus();
    },

    async createTask() {
      if (this.fromTo.length != 2) {
        this.$nextTick(() => {
          this.$refs.period.$el.querySelectorAll('[name="date"]')[0].focus();
        });
        return;
      }

      try {
        this.isLoading = true;
        const params = this.getParams();
        const { message } = await ecpEcouponService.createTask(params);

        this.createTaskConfirmMessage = message;
        this.$modal.show("run-task-confrim-modal");
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async runTask() {
      if (this.fromTo.length != 2) return;

      const params = this.getParams();
      await ecpEcouponService.runTask(params);
    },

    async cancelTask() {
      this.taskStatus = {
        status: null,
      };
      await ecpEcouponService.cancelTask();
    },

    async getTaskStatus() {
      const result = await ecpEcouponService.getTaskStatus();
      this.taskStatus = result;
    },

    async downloadExport() {
      ecpEcouponService.downloadLog();
    },

    startPullingStatus(n) {
      this.stopPullingStatus();
      this.pullingId = setInterval(this.getTaskStatus, n || 4000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },
  },

  async mounted() {
    await this.fetchTransactionLog();
    this.startPullingStatus();
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  min-height: 140px;
}
.page-header-right {
  top: 40px;
  bottom: 0;
  right: 30px;
  text-align: right;
  position: absolute;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
.download-txlog,
.select-period {
  gap: 1em;
  display: flex;
  margin-bottom: 1em;
  align-items: center;
}
.download-txlog {
  justify-content: flex-end;
}

.export-preparing-state {
  gap: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 1em;

  p {
    // color: #007bff;
    color: #eb6709;
    margin-bottom: 0px;
  }

  button {
    &:focus {
      // border: 1px solid red;
    }
  }
}

.export-fetching-state {
  gap: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 1em;

  p {
    margin-bottom: 4px;
  }

  i,
  p {
    color: #eb6709;
  }
}

.mdi-spin:before {
  animation: mdi-spin 0.8s infinite linear;
}
</style>

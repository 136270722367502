var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "flex-fit-content" }, [
          _c("div", { staticClass: "form-group-row" }, [
            _c("div", { staticClass: "left-box" }, [
              _c("p", { staticClass: "mx-10 d-inline" }, [_vm._v("Hidden")]),
              _c("label", { staticClass: "switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.stamp.show_barcode_scanner,
                      expression: "stamp.show_barcode_scanner",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "true-value": 1,
                    "false-value": 0,
                    disabled: _vm.disabled,
                    name: "show_barcode_scanner",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.stamp.show_barcode_scanner)
                      ? _vm._i(_vm.stamp.show_barcode_scanner, null) > -1
                      : _vm._q(_vm.stamp.show_barcode_scanner, 1),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.stamp.show_barcode_scanner,
                          $$el = $event.target,
                          $$c = $$el.checked ? 1 : 0
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(null),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.stamp,
                                "show_barcode_scanner",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.stamp,
                                "show_barcode_scanner",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.stamp, "show_barcode_scanner", $$c)
                        }
                      },
                      function ($event) {
                        return _vm.update(
                          $event,
                          "checkbox",
                          _vm.stamp.show_barcode_scanner
                        )
                      },
                    ],
                  },
                }),
                _c("span", { staticClass: "slider round" }),
              ]),
              _c("p", { staticClass: "mx-10 d-inline" }, [_vm._v("Shown")]),
            ]),
            !_vm.isNonPanelUser
              ? _c("div", { staticClass: "right-box" }, [
                  _c("label", { staticClass: "mb-0" }, [
                    _vm._v("Barcode Scanner Group"),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.stamp.barcode_scanner_group,
                          expression: "stamp.barcode_scanner_group",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "barcode_scanner_group",
                        disabled: _vm.disabledGroupSelector,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.stamp,
                              "barcode_scanner_group",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.update,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "gt" } }, [_vm._v("GT")]),
                      _c("option", { attrs: { value: "lamp" } }, [
                        _vm._v("LAMP"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [_vm._v("Barcode Scanner")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
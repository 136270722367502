<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Preview Campaign</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <h4 class="col-md-12">{{ campaign.name }}</h4>
              <div class="col-md-12">
                <input
                  type="text"
                  autocomplete="off"
                  v-model="contactId"
                  class="form-control"
                  :placeholder="placeholder"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="createLinkPreview"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewPopup",

  props: ["campaign"],

  data() {
    return { contactId: "" };
  },

  computed: {
    campaignType() {
      if (this.campaign.type == "barcode") {
        return "barcodepb";
      } else if (this.campaign.type == "monthly") {
        return "monthlypb";
      } else {
        return this.campaign.type;
      }
    },

    placeholder() {
      return this.campaign.user_type === "isms_code"
        ? "ISMS Code"
        : "Contact Id";
    },
  },

  methods: {
    close() {
      this.$modal.hide("preview-campaign-modal");
    },

    getParams() {
      if (!this.contactId) {
        return "TESTUSER-";
      }

      return this.campaign.user_type === "isms_code"
        ? "TESTUSER-ISMS"
        : "TESTUSER-";
    },

    createLinkPreview() {
      const endpoint = process.env.VUE_APP_PROGRESS_BAR_PREVIEW_URL;

      const param = this.getParams();

      window.open(
        `${endpoint}/${this.campaignType}/${this.campaign.id}?query=${param}${this.contactId}`,
        "_blank"
      );
    },
  },
};
</script>

<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>

        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>

        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>

        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { staffEngagementService } from "@/api/client";

export default {
  props: ["campaign"],

  methods: {
    validate(status) {
      const {
        name,
        lobby_banner,
        hero_banner,
        article,
        quiz_banner,
        round,
        end_date,
        start_date,
      } = this.campaign;

      const {} = this.campaign;

      switch (status) {
        case "scheduled":
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign Name");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องยังเป็นอนาคต.");
            return false;
          }

          if (dayjs(end_date) < dayjs(start_date)) {
            this.$dialogs.alert("End date ต้องมากกว่าหรือเท่ากับ Start date.");
            return false;
          }

          if (!lobby_banner) {
            this.$dialogs.alert("กรุณาระบุรูป Lobby banner");
            return false;
          }

          if (!hero_banner) {
            this.$dialogs.alert("กรุณาระบุรูป Hero banner");
            return false;
          }

          if (!article) {
            this.$dialogs.alert("กรุณาระบุรูป Article");
            return false;
          }

          if (!quiz_banner) {
            this.$dialogs.alert("กรุณาระบุรูป Quiz & Leaderboard");
            return false;
          }

          if (!this.checkRoundName()) return false;

          break;
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await staffEngagementService.updateCampaignInfo(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },

    checkRoundName() {
      if (this.campaign.round.length === 0) return true;

      const roundWithoutNameIndex = this.campaign.round.findIndex(
        (round) => !round.name
      );

      if (roundWithoutNameIndex > -1) {
        this.$dialogs.alert(
          `กรุณาระบุชื่อ Round name ของ Round ที่ #${roundWithoutNameIndex + 1}`
        );

        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>

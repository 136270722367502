var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "page" }, [
        _c(
          "div",
          { staticClass: "page-header" },
          [
            _c("h1", { staticClass: "page-title" }, [_vm._v("Progress Bar")]),
            _c("breadcrumb", {
              attrs: {
                name: "PROGRESS_BAR_UPDATE_DETAIL",
                withLast: _vm.campaign.name,
              },
              on: {
                "update:withLast": function ($event) {
                  return _vm.$set(_vm.campaign, "name", $event)
                },
                "update:with-last": function ($event) {
                  return _vm.$set(_vm.campaign, "name", $event)
                },
              },
            }),
            _c("div", { staticClass: "page-header-right" }),
            _c("div", { staticClass: "page-header-actions" }),
          ],
          1
        ),
        _c("div", { staticClass: "page-content page-content-table" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "panel" }, [
              _c(
                "div",
                { staticClass: "panel-body p-0" },
                [
                  _c("ProgressInfo", { attrs: { campaign: _vm.campaign } }),
                  _vm.campaign.type == "barcode"
                    ? _c("BarcodeTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "stamp"
                    ? _c("StampTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "team"
                    ? _c("TeamBarcodeTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "monthly"
                    ? _c("MonthlyTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "monthlyshort"
                    ? _c("MonthlyshortTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "weekly"
                    ? _c("WeeklyTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "task"
                    ? _c("TaskTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                  _vm.campaign.type == "leaderboard"
                    ? _c("LeaderBoardTable", {
                        attrs: {
                          campaign: _vm.campaign,
                          columnConfig: _vm.columnConfig,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="row">
      <label class="form-control-label">
        <h4>Billboard Pictures</h4>
      </label>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table">
          <tr
            v-for="(o, index) in gameConfig.sunset_drive.billboard"
            :key="index"
          >
            <td class="w-100">No. {{ index + 1 }}</td>
            <td class="center w-400">
              <p>Picture</p>
              <div class="form-group">
                <div class="d-block">
                  <img
                    class="img img-fluid image-preview"
                    v-if="o.picture"
                    :src="o.picture"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="upload"
                  :disabled="disabled"
                  :data-index="index"
                />
              </div>
            </td>
            <td class="v-align-middle w-200">
              <div>
                <div class="d-flex align-items-center">
                  <p class="mx-10 mb-0">Appear on</p>
                  <input
                    min="0"
                    type="number"
                    class="form-control w-100"
                    :disabled="disabled"
                    @change="update(index)"
                    v-model="o.appear_on"
                  />
                </div>
              </div>
            </td>
            <td class="v-align-middle px-50">
              <button
                type="button"
                class="btn btn-default"
                :disabled="disabled"
                @click="remove(index)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </table>

        <div class="action">
          <button
            class="btn btn-default"
            :disabled="
              gameConfig.sunset_drive.billboard.length >= 6 || disabled
            "
            @click="add"
            type="button"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return {};
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },

  methods: {
    async update(index) {
      try {
        const valid = await this.validate(index);
        if (!valid) return;

        const { id } = this.gameConfig;
        const { appear_on } = this.gameConfig.sunset_drive.billboard[index];

        await httpService.put(
          `/game/${id}/sunset_drive/billboard/${index + 1}`,
          {
            appear_on: +appear_on,
          }
        );

        this.$emit("updated");
        this.$notify(`Update Billboard config success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Billboard config error.`);
      }
    },

    async upload(event) {
      const file = event.target.files[0];

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append("picture", file);

        const { id } = this.gameConfig;
        const index = event.target.getAttribute("data-index");

        await httpService.post(
          `/game/${id}/sunset_drive/billboard/${+index + 1}/upload`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$emit("updated");
        this.$notify(`Billboard picture updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Billboard picture error.`);
      }
    },

    async add() {
      if (this.gameConfig.sunset_drive.billboard.length > 6) return;

      try {
        await httpService.post(
          `/game/${this.gameConfig.id}/sunset_drive/billboard`
        );

        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Add Billboard error.`);
      }
    },

    async remove(index) {
      try {
        await httpService.delete(
          `/game/${this.gameConfig.id}/sunset_drive/billboard/${+index + 1}`
        );

        this.$emit("updated");
        this.$notify(`Rmove Billboard success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Remove Billboard error.`);
      }
    },

    async validate(index) {
      const { game_duration } = this.gameConfig.sunset_drive;
      const { appear_on } = this.gameConfig.sunset_drive.billboard[index];

      if (appear_on < 0 || appear_on > game_duration || isNaN(+appear_on)) {
        await this.$dialogs.alert(
          "กรุณาระบุ Appear on: ใส่ค่าเป็น วินาที ที่จะแสดง โดยต้องอยู่ระหว่าง 0 ถึง Game Duration"
        );
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
table.table {
  margin-left: 2em;
}
td.center {
  text-align: center;
}
td.v-align-middle {
  vertical-align: middle;
}

.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 100px;
  background-color: #eaeaea;
}

.image-preview {
  max-width: 150px;
}

.form-group {
  > .d-block,
  > input[type="file"] {
    width: 320px;
    background-color: #f9f9f9;
  }
}

.action {
  padding-left: 4em;
}
</style>

<template>
  <div class="col-8">
    <label class="form-control-label">
      <h4>Game Duration (seconds)</h4>
    </label>
    <div class="input-wrapper">
      <input
        min="0"
        max="600"
        type="number"
        @change="update"
        ref="game_duration"
        :disabled="disabled"
        v-model="game_duration"
        class="form-control w-150"
      />
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return {
      game_duration: null,
    };
  },

  computed: {
    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },

  mounted() {
    this.game_duration = this.gameConfig.sunset_drive.game_duration;
  },

  methods: {
    async isFormValid() {
      const duration = this.game_duration;
      if (duration && (+duration < 0 || +duration > 600)) {
        await this.$dialogs.alert(
          "กรุณาระบุ Game duration ระหว่าง 0 - 600 วินาที"
        );
        this.$refs.game_duration.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await httpService.put(`/game/${this.gameConfig.id}`, {
          sunset_drive: { game_duration: this.game_duration },
        });

        this.$notify(`Game duration updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Game duration error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding: 0px;

  h4 {
    margin-bottom: 0.5em;
  }
}
</style>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create New Product</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ 'form-group--error': $v.product.name.$error }"
            >
              <label class="col-md-3 form-control-label form__label"
                >Product Name</label
              >
              <div class="col-md-9">
                <input
                  ref="name"
                  type="text"
                  class="form-control form__input"
                  :class="{ 'is-valid': !$v.product.name.$invalid }"
                  v-model="product.name"
                  autocomplete="off"
                />
                <NameValidation :$v="$v" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label"
                >Product Description</label
              >
              <div class="col-md-9">
                <ckeditor
                  :config="editorConfig"
                  v-model="product.description"
                ></ckeditor>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              @click="addProduct"
              class="btn btn-round btn-primary"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultEditorConfig, ProductModel } from "./model";
import { productService } from "@/api/client";
import NameValidation from "./validation/name.vue";
import {
  errorMessage,
  FormEditValidateRule as validateRules,
} from "./validation";

export default {
  name: "Create",
  data() {
    return {
      product: new ProductModel(),
      editorConfig: defaultEditorConfig,
      submitStatus: null,
    };
  },
  validations: { product: validateRules },
  components: {
    NameValidation,
  },
  methods: {
    async isFormValid() {
      if (this.$v.product.name.$invalid) {
        await this.$dialogs.alert(errorMessage.name.default);
        this.$refs.name.focus();
        return false;
      }

      return true;
    },
    async addProduct() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const response = await productService.createProduct(this.product);
        await this.$dialogs.alert("Create Product Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify(error);
      }
    },
    close() {
      this.$modal.hide("create-product-modal");
    },
  },
};
</script>

<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Access Setting</h1>
      <breadcrumb name="E_COUPON_ACCESS_SETTING"></breadcrumb>
    </div>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <div class="panel panel-bordered">
            <div class="panel-body">
              <form class="form-horizontal">
                <div class="row">
                  <label class="form-control-label">
                    <h4>GT ACCESS</h4>
                  </label>
                </div>

                <div class="row">
                  <label class="d-inline mx-10 form-control-label">
                    <h5>Select user groups</h5>
                  </label>
                </div>
                <Selectize
                  multiple
                  ref="groupCode"
                  v-model="groupGT"
                  :settings="groupSelectizeSettingGT"
                >
                  <option
                    v-for="group in groupActiveGT"
                    :key="group.id"
                    :value="group.id"
                    >{{ ` ${group.id} - ${group.group_code}` }}</option
                  >
                </Selectize>

                <div class="row">
                  <label class="form-control-label">
                    <h4>WS ACCESS</h4>
                  </label>
                </div>
                <div class="row">
                  <label class="d-inline mx-10 form-control-label">
                    <h5>Select user groups</h5>
                  </label>
                </div>

                <Selectize
                  multiple
                  ref="groupCodeWS"
                  v-model="groupWS"
                  :settings="groupSelectizeSettingWS"
                >
                  <option
                    v-for="group in groupActiveWS"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ ` ${group.id} - ${group.group_code}` }}
                  </option>
                </Selectize>
                <div class="row">
                  <label class="form-control-label mt-4">
                    <h4>Widget Link for LINE notification</h4>
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-form-label mx-4"> GT</label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      v-model="widget.gt_widget"
                      @change="updateLink('gt_widget', 'gt')"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label mx-4"> WS</label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      v-model="widget.ws_widget"
                      @change="updateLink('ws_widget', 'ws')"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="panel-footer">
              <div class="d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { ecouponService } from "@/api/client";
export default {
  name: "ECouponAccessSetting",
  data() {
    return {
      groupType: [],
      groupActive: [],
      groupActiveGT: [],
      groupActiveWS: [],
      groupWS: [],
      groupGT: [],
      widget: {},
      groupSelectizeSettingGT: {
        select: [],
        plugins: ["remove_button"],
        onChange: this.addGTGroup,
        onItemRemove: this.deleteGTGroup,
      },
      groupSelectizeSettingWS: {
        select: [],
        plugins: ["remove_button"],
        onChange: this.addWSGroup,
        onItemRemove: this.deleteWSGroup,
      },
    };
  },
  components: {
    Selectize,
  },
  created() {
    this.fetch();
  },
  methods: {
    resetValue() {
      this.groupActiveWS = [];
      this.groupActiveGT = [];
      this.groupWS = [];
      this.groupGT = [];
      this.groupType = [];
    },
    setOption() {
      // Freeze Oject data not connecting
      this.groupActiveWS = this.groupActive.map((item) => {
        return Object.freeze(item);
      });
      this.groupActiveGT = this.groupActive.map((item) => {
        return Object.freeze(item);
      });
    },
    setValue() {
      const WS = this.groupType.filter((e) => e.group_type == "ws");
      const GT = this.groupType.filter((e) => e.group_type == "gt");
      WS.forEach((e) => {
        this.groupWS.push(e.group_id);
        this.groupActiveWS.push({ id: e.group_id, group_code: e.group_code });
      });
      GT.forEach((e) => {
        this.groupGT.push(e.group_id);
        this.groupActiveGT.push({ id: e.group_id, group_code: e.group_code });
      });
    },
    async fetch() {
      this.resetValue();
      this.groupActive = await ecouponService.getGroupActive();
      this.groupType = await ecouponService.getGroupType();
      this.widget = await ecouponService.getWidget();
      this.setOption();
      this.setValue();
    },
    async addGTGroup(ids) {
      if (ids.length > this.groupGT.length) {
        const result = ids[ids.length - 1];
        try {
          await ecouponService.addGroupGT(result);
          this.$notify(`GT Access Group updated.`);
          this.fetch();
        } catch (error) {
          console.log(error);
        }
      }
      this.groupGT = ids;
    },
    async deleteGTGroup(id) {
      try {
        await ecouponService.deleteGroupGT(id);
        this.$notify(`GT Access Group updated.`);
        this.fetch();
      } catch (error) {
        console.log(error);
      }
    },
    async addWSGroup(ids) {
      if (ids.length > this.groupWS.length) {
        const result = ids[ids.length - 1];
        try {
          await ecouponService.addGroupWS(result);
          this.$notify(`WS Access Group updated.`);
          this.fetch();
        } catch (error) {
          console.log(error);
        }
      }
      this.groupWS = ids;
    },
    async deleteWSGroup(id) {
      try {
        await ecouponService.deleteGroupWS(id);
        this.$notify(`WS Access Group updated.`);
        this.fetch();
      } catch (error) {
        console.log(error);
      }
    },
    async updateLink(field, type) {
      try {
        const form = new FormData();
        form.set("url", this.widget[field]);
        form.set("type", type);
        const body = Object.fromEntries(form);
        await ecouponService.updateWidget(body);
        this.$notify(`Widget Link update.`);
        this.fetch();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <label class="form-control-label">
          <h4>
            Car Skin
            <span class="small light"> (recommended size 539 x 285 )</span>
          </h4>
        </label>
      </div>
    </div>
    <div class="row">
      <div
        v-for="({ name, text }, index) in cars"
        :key="index"
        class="col-md-4"
      >
        <div v-if="index !== 4" class="form-group">
          <div my-15px>
            <label class="form-control-label">
              <h5>
                {{ text }}
                <span class="small light"></span>
              </h5>
            </label>

            <div class="form-group">
              <div class="d-block">
                <img
                  class="img img-fluid image-preview"
                  v-if="picture"
                  :src="getProductImageUrl(index)"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                class="form-control"
                @change="upload"
                :ref="`${name}`"
                :name="`${name}`"
                :disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

const httpService = gameService.axiosInstance;

export default {
  props: ["gameConfig"],

  data() {
    return {
      file: null,
      cars: [
        {
          name: "car_left_picture",
          text: "Left-1",
        },
        {
          name: "car_center_picture",
          text: "Center-1",
        },
        {
          name: "car_right_picture",
          text: "Right-1",
        },
        {
          name: "car_left_picture2",
          text: "Left-2",
        },
        {
          name: null,
          text: null,
        },
        {
          name: "car_right_picture2",
          text: "Right-2",
        },
      ],
    };
  },
  computed: {
    picture() {
      return this.gameConfig.sunset_drive;
    },

    disabled() {
      return !["draft", "scheduled", "paused"].includes(this.gameConfig.status);
    },
  },
  methods: {
    async upload(event) {
      const file = event.target.files[0];
      const field = event.target.name;

      if (file.size > 1000000) {
        this.$notify("File shall not exceed 1 MB");
        event.target.files[0].value = "";
        return;
      }

      try {
        if (!file) return;
        const form = new FormData();
        form.append(field, file);

        const { id } = this.gameConfig;

        await httpService.post(`/game/${id}/sunset_drive/upload`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const car = this.cars.find((o) => o.name == field);

        this.$emit("updated");
        this.$notify(`Upload Car ${car.text} picture success.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload car picture error.`);
      }
    },
    getProductImageUrl(index) {
      let url = "";

      switch (index) {
        case 0:
          url = this.gameConfig.sunset_drive.car_left_picture[0];
          break;
        case 1:
          url = this.gameConfig.sunset_drive.car_center_picture;
          break;
        case 2:
          url = this.gameConfig.sunset_drive.car_right_picture[0];
          break;
        case 3:
          url = this.gameConfig.sunset_drive.car_left_picture[1];
          break;
        case 5:
          url = this.gameConfig.sunset_drive.car_right_picture[1];
          break;
      }

      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}

.image-preview {
  padding: 12px;
  min-height: 200px;
  background-color: #eaeaea;
}

.image-preview,
input[type="file"] {
  height: auto;
  max-width: 100%;
}

.form-control-label {
  padding: 0px;

  h4 {
    margin-bottom: 0.5em;
  }
}
</style>

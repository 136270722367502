<template>
  <a
    role="menuitem"
    class="dropdown-item"
    href="javascript:void(0)"
    @click="$emit('onSelect')"
  >
    <i class="icon" :class="icon" aria-hidden="true"> </i>
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "DropdownItemStatus",
  props: ["text", "icon"],
};
</script>

<style></style>

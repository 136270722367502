var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "form-group row" }, [
        _c("h5", { staticClass: "col-md-12" }, [_vm._v("User Groups")]),
        _c(
          "div",
          { staticClass: "col-md-8 col-sm-12" },
          [
            _c(
              "Selectize",
              {
                attrs: {
                  multiple: "",
                  disabled: _vm.disabled,
                  settings: _vm.groupSelectizeSetting,
                },
                model: {
                  value: _vm.campaign.group_id_list,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "group_id_list", $$v)
                  },
                  expression: "campaign.group_id_list",
                },
              },
              _vm._l(_vm.userGroup, function (group) {
                return _c(
                  "option",
                  { key: group.id, domProps: { value: group.id } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(" " + group.id + " - " + group.group_code) +
                        " "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="form-group row" v-if="campaign">
    <h5 class="col-md-12">User Groups</h5>
    <div class="col-md-8 col-sm-12">
      <Selectize
        multiple
        :disabled="disabled"
        :settings="groupSelectizeSetting"
        v-model="campaign.group_id_list"
      >
        <option v-for="group in userGroup" :key="group.id" :value="group.id">
          {{ ` ${group.id} - ${group.group_code}` }}
        </option>
      </Selectize>
    </div>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { luckyLoginService, userGroupService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    Selectize,
  },

  data() {
    return {
      userGroup: [],
      groupSelectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        onChange: this.update,
      },
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  async created() {
    await this.fetchUserGroup();
  },

  methods: {
    async update(ids) {
      try {
        const { id } = this.campaign;
        const form = { group_id_list: ids.map((id) => +id) };

        await luckyLoginService.updateCampaignInfo(id, form);

        this.$emit("updated");
        this.$notify(`User group Updated.`);
      } catch (error) {
        this.$dialogs.alert(`User group update error.`);
      }
    },

    async fetchUserGroup() {
      this.userGroup = await userGroupService.getUserGroups();
    },
  },
};
</script>

<style></style>

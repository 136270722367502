var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm._m(0),
    _c("div", { staticClass: "col-8" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.reward.ecoupon_minimum_purchase,
            expression: "reward.ecoupon_minimum_purchase",
          },
        ],
        staticClass: "form-control",
        attrs: {
          min: "0",
          "width-120px": "",
          type: "number",
          name: "ecoupon_minimum_purchase",
          disabled: _vm.disabled || _vm.reward.reward_type !== "ecoupon",
        },
        domProps: { value: _vm.reward.ecoupon_minimum_purchase },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.reward,
              "ecoupon_minimum_purchase",
              $event.target.value
            )
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4" }, [
      _c("p", { staticClass: "light text-right", attrs: { "ml-5": "" } }, [
        _vm._v("Minimum Purchase"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { required, minLength, between } from "vuelidate/lib/validators";
import { moment } from "vuelidate/lib/validators";

export const lotModel = {
  name: "",
  type: "",
  product_name: "",
  start_date: "",
  end_date: "",
  post_date: "",
  delist_date: "",
  status: "draft",
  created_time: "",
  stamodified_time: "",
  pinned: 0,
  modified_by_username: "",
  group_id_list: [0, 0],
  group_list: [[]],
};

export const LotTableColumns = [
  {
    label: "Lot Id",
    field: "id",
    type: "number",
    tdClass: "text-center",
    sortable: true,
  },
  {
    label: "Lot Name",
    field: "name",
  },
  {
    label: "Product Name",
    field: "product_name",
  },
  {
    label: "Lot Type",
    field: "type",
  },
  {
    label: "Post Date",
    field: "post_date",
    hidden: true,
    sortable: false,
  },
  {
    label: "Delist Date",
    field: "delist_date",
    hidden: true,
    sortable: false,
  },
  {
    label: "Start Date",
    field: "start_date",
  },
  {
    label: "End Date",
    field: "end_date",
  },
  {
    label: "Duration",
    field: "duration",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Last Modified",
    field: "modified_time",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss", // expects 2018-03-16
    dateOutputFormat: "dd/MM/yyyy HH:mm", // outputs Mar 16th 2018
  },
  {
    label: "Modified by",
    field: "modified_by_username",
  },
  {
    label: "Pinned",
    field: "pinned",
    tdClass: "text-center",
  },
];

export const fileUploadOptions = {
  accept: ".jpeg, .jpg, .png",
  maxFileSize: "500K",
};

export const defaultEditorConfig = {
  toolbarGroups: [
    { name: "clipboard", groups: ["clipboard", "undo"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
    },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],
};

export const campaignTableColumns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Campaign Name",
    field: "name",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Status",
    field: "status",
    formatFn: function (value) {
      return value.toUpperCase();
    },
  },
  {
    label: "Start",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Last Modified",
    field: "modified_time",
  },
  {
    label: "Modified By",
    field: "modified_by_username",
  },
];

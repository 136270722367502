export const transactionLogTableColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "ContactID",
    field: "contact_id",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "User Group",
    field: "group_code",
  },
  {
    label: "Campaign ID",
    field: "campaign_id",
    type: "number",
  },
  {
    label: "Prize Name",
    field: "prize_name",
  },
  {
    label: "SF Points",
    field: "points",
    type: "number",
  },
  {
    label: "Coupon Value",
    field: "face_value",
    type: "number",
  },
  {
    label: "Prize Theme ID",
    field: "theme_id",
    type: "number",
  },
  {
    label: "Reward Request ID",
    field: "request_id",
    type: "number",
  },
  {
    label: "Prize Status",
    field: "status",
    formatFn: function (value) {
      return value.toUpperCase();
    },
  },
];

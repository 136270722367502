<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">VSM Management</h1>
      <breadcrumb name="VSM_MANAGEMENT"></breadcrumb>

      <bulkUpload></bulkUpload>

      <!-- <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>
      </div> -->
      <div class="page-header-left">
        <div class="mt-10">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Employee username</label>
            <div class="col-sm-4">
              <input
                class="form-control"
                placeholder="All"
                v-model="employeeUsername"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label"> User ISMS Code</label>
            <div class="col-sm-4">
              <input
                class="form-control"
                placeholder="All"
                v-model="userCode"
              />
            </div>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="searchUserCode"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              :isLoading="loadingTable"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-default">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { ecouponService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { vsmManagementTableColumns } from "@/views/ecoupon/vsm-management/model";
import bulkUpload from "./components/bulkUpload.vue";
export default {
  mixins: [vueGoodTableMixins],
  data() {
    return {
      list: [],
      columns: vsmManagementTableColumns,
      loadingTable: false,
      canUpload: false,
      username: "All",
      code: "All",
      vsm: [],
      employeeUsername: null,
      userCode: null,
    };
  },
  components: { bulkUpload },
  watch: {
    vsm(v) {
      this.list = v;
    },
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },
  created() {
    // this.fetch();
  },
  methods: {
    async searchUserCode() {
      await this.searchUsername();
      await this.searchCode();
      this.fetch();
    },
    async searchUsername() {
      try {
        const response = await ecouponService.getVSMUsername(
          this.employeeUsername
        );
        if (_.isEmpty(response)) {
          return (this.username = this.employeeUsername);
        }
        this.username = response
          .map((value) => {
            return value.username;
          })
          .toString();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Seacrh Error");
      }
    },
    async searchCode() {
      try {
        const response = await ecouponService.getVSMISMSode(this.userCode);
        if (_.isEmpty(response)) {
          return (this.code = this.userCode);
        }
        this.code = response
          .map((value) => {
            return value.isms_code;
          })
          .toString();
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Seacrh Error");
      }
    },
    getParams() {
      let params = {
        s_username: this.username,
        s_isms_code: this.code,
      };

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },
    async exportToExcel() {
      const params = this.getParams();
      try {
        const response = await ecouponService.downloadVSM(params);
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `VSM_management${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },
    async fetch() {
      this.loadingTable = true;
      const params = this.getParams();

      const { data, total_records } = await ecouponService.getVSM(params);

      this.vsm = data;
      this.totalRecords = total_records;
      this.loadingTable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}

.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ckeditor", {
    attrs: { config: _vm.mergedConfig, "read-only": _vm.readOnly },
    on: { blur: _vm.handleBlur, input: _vm.handleInput },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
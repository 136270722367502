export const tableColumns = [
  {
    label: "PrizeID",
    field: "id",
    type: "number",
  },
  {
    label: "Prize Name ",
    field: "prize_name",
  },
  {
    label: "Date Issued",
    field: "issue_date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Lot ID",
    field: "prize_lot_id",
    type: "number",
  },
  {
    label: "Type",
    field: "campaign_type",
  },
  {
    label: "ID",
    field: "campaign_id",
    type: "number",
  },
  {
    label: "Contact ID",
    field: "contact_id",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Expire Date",
    field: "expire_date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Barcode ID",
    field: "barcode_id",
  },
  {
    label: "Required Address",
    field: "require_address",
  },
];

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("form", { staticClass: "form-horizontal" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "form-group col-md-6" },
            [_c("CampaignName", { attrs: { campaign: _vm.campaign } })],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group col-md-3" },
            [
              _c("StatusDropdown", {
                attrs: { campaign: _vm.campaign },
                on: {
                  updated: function ($event) {
                    return _vm.$emit("updated")
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("CampaignDuration", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("BannerImage", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("BackgroundImage", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
            _c("BackgroundColor", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("Textbox1", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
            _c("Textbox2", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("TextColor", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("NoStamp", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("StampEmptyImage", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
            _c("StampTakenImage", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("NumbersOnEmptyStamp", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("RewardList", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("RewardDetailsSection", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("BarcodeScanner", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
            _c("ScannerImage", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("Audience", {
              attrs: { campaign: _vm.campaign },
              on: {
                updated: function ($event) {
                  return _vm.$emit("updated")
                },
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [_c("TargetSKU", { attrs: { campaign: _vm.campaign } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page" v-if="detail">
    <div class="page-header">
      <h1 class="page-title">Budget Status</h1>

      <breadcrumb
        name="BUDGET_CONTROL_STATUS_DETAIL"
        :withLast.sync="detail.name"
      >
      </breadcrumb>

      <div class="page-header-right"></div>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-round btn-default mx-10"
          @click="goBack"
        >
          <i class="icon md-chevron-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>

    <div class="page-content page-content-table">
      <StatusInfo :info="detail"></StatusInfo>

      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              mode="remote"
              :rows="list"
              :columns="columns"
              :isLoading="loadingTable"
              :totalRows="totalRecords"
              :sort-options="sortOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              :search-options="{ ...searchOption, trigger: 'enter' }"
            >
              <div slot="table-actions">
                <div class="Excel">
                  <button @click="exportToExcel" class="btn btn-primary">
                    <i class="fa fa-file-excel-o"></i>
                    Export to Excel
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'status'">
                  {{ props.row.status | capitalize }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { detailTableColumns } from "@/views/budget-control/status/model";
import StatusInfo from "@/views/budget-control/status/components/Info.vue";

export default {
  name: "BudgetStatusDetail",

  mixins: [vueGoodTableMixins],

  components: { StatusInfo },

  data() {
    return {
      detail: null,

      list: [],
      fromTo: [],
      download: [],
      loadingTable: false,
      columns: detailTableColumns,
    };
  },

  watch: {
    async "$route.query"() {
      await this.fetch();
      this.initialTableState();
    },
  },

  methods: {
    goBack() {
      this.$router.push("/budget-control/status");
    },

    getParams() {
      const params = {};

      const { sortBy, sortDir, perPage, page, searchTerm } = this.$route.query;

      const pageSize = perPage || this.pagination.perPage;

      if (sortBy) {
        params.order_by = sortBy;
      }

      if (sortDir) {
        params.desc = sortDir == "desc" ? 1 : 0;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      if (page) {
        params.start = page ? (page - 1) * pageSize : 0;
      }

      params.count = pageSize || 10;

      return params;
    },

    async fetch() {
      this.loadingTable = true;

      try {
        const { id } = this.$route.params;
        const params = this.getParams();

        const response = await budgetService.getBudgetStatusTable(id, params);
        const { data, total_records } = response;

        this.list = data;
        this.totalRecords = total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    async exportToExcel() {
      try {
        const { id } = this.$route.params;
        const params = this.getParams();

        const response = await budgetService.downloadBudgetStatusTable(
          id,
          params
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });

        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `export_budget_status_info_${id}_${new Date().getTime()}.xlsx`;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    async getBudgetStatusDetail() {
      const id = this.$route.params.id;
      const response = await budgetService.getBudgetStatusInfo(id);
      this.detail = { id: id, ...response };
    },

    onBulkUpdate() {
      this.fetch();
      this.getBudgetStatusDetail();
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.$nextTick(() => {
      this.$router.replace({ query: null }).catch((err) => {});
      this.initialTableSearchState();
    });
  },

  created() {
    this.getBudgetStatusDetail();
  },
};
</script>

<style scoped>
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
.Excel {
  margin: auto 1rem;
}
</style>

<template>
  <div class="row">
    <h4 class="col-md-12">Recipients</h4>

    <div class="col-4">
      <div class="ml-10 my-5">
        <div class="form-group mb-0">
          <label
            for="prize_bulk_upload"
            class="btn btn-default mx-10"
            :class="{ disabled: !canEdit }"
          >
            <i class="icon md-upload" aria-hidden="true"></i> Bulk upload
          </label>
          <input
            type="file"
            v-if="canEdit"
            class="invisible"
            id="prize_bulk_upload"
            @change="onFileSelected"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>

        <a
          target="_blank"
          :href="exampleFileUri"
          class="btn btn-link btn-round"
        >
          <i class="fa fa-file-excel-o"></i>
          Click to download example
        </a>
      </div>
    </div>

    <div class="col-8">
      <p>
        <span>Last upload {{ lastUploadTime }}</span>
        <span>No. Prize {{ lot.num_prize | numberWithCommas }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { prizeService } from "@/api/client";

export default {
  name: "PrizeBulkUpload",

  props: ["lot"],

  data() {
    return {
      file: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.lot.status);
    },

    exampleFileUri() {
      return `${
        process.env.VUE_APP_GAME_WORLD_BUCKET_URI
      }/ams/prize_summary/prize-summary-test-upload-e-reward.xlsx?ts=${new Date().getTime()}`;
    },

    lastUploadTime() {
      const time = moment(this.lot.last_prize_upload, "YYYY-MM-DD HH:mm:ss");
      return time.isValid() ? time.format("DD/MM/YYYY HH:mm") : "-";
    },
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];

      if (!this.file) return;
      event.target.value = null;

      this.upload();
    },

    async upload() {
      try {
        if (!this.file) return;

        const form = new FormData();

        form.append("excel", this.file);
        await prizeService.bulkUpload(this.lot.id, form);

        this.$emit("uploaded");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Bulk upload error");
      }
    },
  },
};
</script>

<style scoped>
[type="file"] {
  width: 50px;
}
</style>

<template>
  <div class="col-md-8" v-if="monthly">
    <div class="form-group">
      <div my-15px>
        <label class="mx-5">
          <h4>
            Progress & Target Reset
          </h4>
        </label>

        <div class="d-flex">
          <div class="radio-custom radio-primary">
            <input
              type="radio"
              name="progress_reset"
              value="none"
              v-model="monthly.progress_reset"
              @change="update"
              :disabled="disabled"
              id="reset_none"
            />
            <label for="reset_none">none</label>
          </div>
          <div class="radio-custom radio-primary mx-10">
            <input
              type="radio"
              name="progress_reset"
              value="month"
              v-model="monthly.progress_reset"
              @change="update"
              :disabled="disabled"
              id="reset_every_month"
            />
            <label for="reset_every_month">Every month</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";

export default {
  props: ["campaign"],

  data() {
    return {
      file: null,
      monthly: null,
    };
  },
  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },
  methods: {
    async update() {
      try {
        const { id } = this.campaign;
        const form = { progress_reset: this.monthly.progress_reset };

        await progressBarService.updateCampaignInfo(id, { monthly: form });

        this.$emit("updated");
        this.$notify(`Progress and target reset`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Progress and target reset error.`);
      }
    },
  },

  mounted() {
    this.monthly = this.campaign.monthly;
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: gray;
  font-weight: 300;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page-main" }, [
        _c("div", { staticClass: "page-header" }, [
          _c(
            "div",
            {
              staticClass:
                "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
            },
            [
              _c("h1", { staticClass: "page-title" }, [_vm._v("Campaign")]),
              _c("breadcrumb", {
                attrs: { name: "CAMPAIGN_EDIT", withLast: _vm.campaign.name },
                on: {
                  "update:withLast": function ($event) {
                    return _vm.$set(_vm.campaign, "name", $event)
                  },
                  "update:with-last": function ($event) {
                    return _vm.$set(_vm.campaign, "name", $event)
                  },
                },
              }),
              _c("div", { staticClass: "page-header-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-round btn-default mx-10",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("i", {
                      staticClass: "icon md-chevron-left",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Back "),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "page-content container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1",
              },
              [
                _c("div", { staticClass: "panel panel-bordered" }, [
                  _c("div", { staticClass: "panel-heading" }, [
                    _c("h3", { staticClass: "panel-title" }, [
                      _vm._v(
                        " Campaign Information - " +
                          _vm._s(_vm.campaign.id) +
                          " "
                      ),
                    ]),
                    _c("div", {
                      staticClass: "panel-actions panel-actions-keep",
                    }),
                  ]),
                  _c("div", { staticClass: "panel-body" }, [
                    _c("form", { staticClass: "form-horizontal" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-6" },
                          [
                            _vm._m(0),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.name,
                                  expression: "campaign.name",
                                },
                              ],
                              ref: "campaignName",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                maxlength: "255",
                                disabled: _vm.isDisablename,
                              },
                              domProps: { value: _vm.campaign.name },
                              on: {
                                change: _vm.submitname,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.campaign,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("nameValidation", { attrs: { $v: _vm.$v } }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group col-md-3" }, [
                          _c("div", { staticClass: "px-15" }, [
                            _c("div", { staticClass: "btn-group" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-round dropdown-toggle",
                                  staticStyle: {
                                    "text-transform": "uppercase",
                                  },
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.campaign.status) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { role: "menu" },
                                },
                                [
                                  _vm.campaign.status == "draft"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href: "javascript:void(0)",
                                            role: "menuitem",
                                          },
                                          on: { click: _vm.setStatusscheduled },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon md-long-arrow-up",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v("Publish "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.campaign.status == "draft"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href: "javascript:void(0)",
                                            role: "menuitem",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setStatus("archived")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon md-archive",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v("Archive "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.campaign.status == "scheduled"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href: "javascript:void(0)",
                                            role: "menuitem",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setStatus("draft")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon md-long-arrow-down",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v("Unpublished "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.campaign.status == "ongoing"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href: "javascript:void(0)",
                                            role: "menuitem",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setStatus("aborted")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon md-border-color",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v("Abort "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.campaign.status == "aborted"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href: "javascript:void(0)",
                                            role: "menuitem",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon md-archive",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v("Nothing "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.campaign.status == "end"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href: "javascript:void(0)",
                                            role: "menuitem",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon md-archive",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v("Nothing "),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("hr"),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("h5", { staticClass: "col-md-12" }, [
                          _vm._v("Description (Option)"),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.description,
                                expression: "campaign.description",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              rows: "4",
                              maxlength: "5000",
                              disabled: _vm.isDisableDescription,
                            },
                            domProps: { value: _vm.campaign.description },
                            on: {
                              change: function ($event) {
                                return _vm.submit("description")
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.campaign,
                                  "description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "form-group row" },
                        [
                          _c("h5", { staticClass: "col-md-12" }, [
                            _vm._v("Campaign Duration"),
                          ]),
                          _c(
                            "label",
                            { staticClass: "col-md-1 form-control-label" },
                            [_vm._v("Start")]
                          ),
                          _c("date-picker", {
                            attrs: {
                              type: "date",
                              disabled: _vm.isDisableDuration,
                              "value-type": "format",
                              format: "YYYY-MM-DD",
                              placeholder: "Select date",
                            },
                            on: { change: _vm.submitDay },
                            model: {
                              value: _vm.start_date,
                              callback: function ($$v) {
                                _vm.start_date = $$v
                              },
                              expression: "start_date",
                            },
                          }),
                          _c("label", { staticClass: "form-control-label" }, [
                            _vm._v("No.days"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Duration,
                                expression: "Duration",
                              },
                            ],
                            staticClass: "col-md-1",
                            attrs: {
                              type: "number",
                              disabled: _vm.isDisableDuration,
                              min: "1",
                              max: "365",
                            },
                            domProps: { value: _vm.Duration },
                            on: {
                              change: _vm.submitDay,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.Duration = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "label",
                            { staticClass: "col-md-4 form-control-label" },
                            [
                              _vm._v(
                                "End Date: " +
                                  _vm._s(_vm.date_moment(_vm.end_date))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("h5", { staticClass: "col-md-12" }, [
                          _vm._v("User Groups"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "Selectize",
                              {
                                ref: "groupCode",
                                attrs: {
                                  disabled: _vm.isCanChangeDisabled,
                                  multiple: "",
                                  settings: _vm.groupSelectizeSetting,
                                },
                                model: {
                                  value: _vm.campaign.group_id_list,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.campaign, "group_id_list", $$v)
                                  },
                                  expression: "campaign.group_id_list",
                                },
                              },
                              _vm._l(_vm.groups, function (group) {
                                return _c(
                                  "option",
                                  {
                                    key: group.id,
                                    domProps: { value: group.id },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        " " +
                                          group.id +
                                          " - " +
                                          group.group_code
                                      )
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm.showgrouppre
                              ? _c("gropValidation", {
                                  attrs: { group: _vm.groupre },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("hr"),
                      _c("div", { staticClass: "form-inline row" }, [
                        _c("h5", { staticClass: "col-md-12" }, [
                          _vm._v("Immediate points"),
                        ]),
                        _c("div", { staticClass: "col-md-8" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.enable_immediate_points,
                                  expression: "enable_immediate_points",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: !_vm.canSetImmediateCampaign,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.enable_immediate_points
                                )
                                  ? _vm._i(_vm.enable_immediate_points, null) >
                                    -1
                                  : _vm.enable_immediate_points,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.enable_immediate_points,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.enable_immediate_points =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.enable_immediate_points = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.enable_immediate_points = $$c
                                    }
                                  },
                                  _vm.onImmediateCampaignChange,
                                ],
                              },
                            }),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _c("p", { staticClass: "mx-10 d-inline" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.enable_immediate_points ? "ON" : "OFF"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.immediate_points,
                                expression: "immediate_points",
                              },
                            ],
                            ref: "immediate_points",
                            staticClass: "form-control",
                            attrs: {
                              min: "1",
                              max: "100000",
                              type: "number",
                              disabled: !_vm.enable_immediate_points,
                            },
                            domProps: { value: _vm.immediate_points },
                            on: {
                              blur: _vm.setImmediateCampaign,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.immediate_points = $event.target.value
                              },
                            },
                          }),
                          _c("span", [_vm._v(" points")]),
                        ]),
                        _vm._m(1),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-md-2 form-control-label text-left d-block",
                              },
                              [_vm._v(" Text Area 1 ")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.immediate_text1,
                                  expression: "immediate_text1",
                                },
                              ],
                              staticClass: "form-control col-md-6",
                              attrs: {
                                type: "text",
                                disabled: !_vm.enable_immediate_points,
                              },
                              domProps: { value: _vm.immediate_text1 },
                              on: {
                                blur: _vm.setImmediateCampaign,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.immediate_text1 = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-md-2 form-control-label text-left d-block",
                              },
                              [_vm._v(" URL Link ")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.immediate_link,
                                  expression: "immediate_link",
                                },
                              ],
                              staticClass: "form-control col-md-6",
                              attrs: {
                                type: "text",
                                disabled: !_vm.enable_immediate_points,
                              },
                              domProps: { value: _vm.immediate_link },
                              on: {
                                blur: _vm.setImmediateCampaign,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.immediate_link = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-md-2 form-control-label text-left d-block",
                              },
                              [_vm._v("Text Area 2")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.immediate_text2,
                                  expression: "immediate_text2",
                                },
                              ],
                              staticClass: "form-control col-md-6",
                              attrs: {
                                type: "text",
                                disabled: !_vm.enable_immediate_points,
                              },
                              domProps: { value: _vm.immediate_text2 },
                              on: {
                                blur: _vm.setImmediateCampaign,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.immediate_text2 = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "form-group row" },
                        [
                          _c("h5", { staticClass: "col-md-12" }, [
                            _vm._v("Special Dates"),
                          ]),
                          _vm._l(_vm.special_dates, function (special, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "form-inline" },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-control-label",
                                      staticStyle: { width: "80px" },
                                    },
                                    [_vm._v("No." + _vm._s(index + 1))]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: special.day,
                                          expression: "special.day",
                                        },
                                      ],
                                      staticClass: "form-control-label",
                                      staticStyle: { width: "100px" },
                                      attrs: { disabled: _vm.isDisableSpecial },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              special,
                                              "day",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.editSpecial,
                                        ],
                                      },
                                    },
                                    _vm._l(_vm.listDay, function (day) {
                                      return _c(
                                        "option",
                                        {
                                          key: day,
                                          attrs: {
                                            hidden: _vm.checkrepeat(day),
                                            disabled: _vm.checkrepeat(day),
                                          },
                                          domProps: { value: day },
                                        },
                                        [_vm._v(_vm._s(day))]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" Day "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-control-label",
                                      staticStyle: { width: "280px" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.date_moment(
                                              _vm.calend(
                                                _vm.start_date,
                                                special.day
                                              )
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: special.special_points,
                                        expression: "special.special_points",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      disabled: _vm.isDisableSpecial,
                                      min: "1",
                                      max: "1000000",
                                    },
                                    domProps: { value: special.special_points },
                                    on: {
                                      change: _vm.editSpecial,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          special,
                                          "special_points",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" points "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-round btn-default mx-10",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.isDisableSpecial,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removelist(index)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-trash" })]
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-round btn-default mx-10",
                          attrs: {
                            type: "button",
                            disabled: _vm.isDisableSpecial,
                          },
                          on: { click: _vm.addspecialday },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(" Add "),
                        ]
                      ),
                      _c("hr"),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("h5", { staticClass: "col-md-8" }, [
                          _vm._v("Coin Exchange"),
                        ]),
                        _c(
                          "h5",
                          {
                            staticClass: "col-md-4",
                            class: { over: _vm.maximum_coins < 0 },
                          },
                          [
                            _vm._v(
                              " Max remaining coins " +
                                _vm._s(_vm.maximum_coins) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "col-md-8" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.isDisable_enablecoins,
                              },
                              domProps: { checked: _vm.enable_coins },
                              on: {
                                click: function ($event) {
                                  _vm.enable_coins = !_vm.enable_coins
                                },
                                change: _vm.chage_enable_coins,
                              },
                            }),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              attrs: { hidden: _vm.isDisable_coins },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "checkbox-custom checkbox-primary",
                                },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      id: "enable_quiz_checkbox",
                                      disabled: _vm.isDisable_enablecoins,
                                    },
                                    domProps: { checked: _vm.enable_quiz },
                                    on: {
                                      change: _vm.onEnableQuizChange,
                                      click: function ($event) {
                                        _vm.enable_quiz = !_vm.enable_quiz
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "enable_quiz_checkbox" } },
                                    [_vm._v("Enable Quiz")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "form-inline",
                                attrs: { hidden: _vm.isDisable_coins },
                              },
                              [
                                _c("div", { staticClass: "form-group mb-10" }, [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [_vm._v("Step 1")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coins[0].from_coins,
                                        expression: "coins[0].from_coins",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      disabled: _vm.isDisable_enablecoins,
                                      min: "1",
                                      max: "1000000 ",
                                    },
                                    domProps: {
                                      value: _vm.coins[0].from_coins,
                                    },
                                    on: {
                                      change: _vm.editcoin_exchange,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.coins[0],
                                          "from_coins",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [_vm._v("coins")]
                                  ),
                                  _c("i", {
                                    staticClass: "fas fa-arrow-right",
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coins[0].to_points,
                                        expression: "coins[0].to_points",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      disabled: _vm.isDisable_enablecoins,
                                      min: "1",
                                      max: "1000000 ",
                                    },
                                    domProps: { value: _vm.coins[0].to_points },
                                    on: {
                                      change: _vm.editcoin_exchange,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.coins[0],
                                          "to_points",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" points "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mx-20",
                                      attrs: { hidden: _vm.isDisable_coins },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-round btn-primary",
                                          attrs: {
                                            type: "button",
                                            disabled: !_vm.enable_quiz,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setupQuestion(1)
                                            },
                                          },
                                        },
                                        [_vm._v(" ? ")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group mb-10" }, [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [_vm._v("Step 2")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coins[1].from_coins,
                                        expression: "coins[1].from_coins",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      disabled: _vm.isDisable_enablecoins,
                                      min: "1",
                                      max: "1000000 ",
                                    },
                                    domProps: {
                                      value: _vm.coins[1].from_coins,
                                    },
                                    on: {
                                      change: _vm.editcoin_exchange,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.coins[1],
                                          "from_coins",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [_vm._v("coins")]
                                  ),
                                  _c("i", {
                                    staticClass: "fas fa-arrow-right",
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coins[1].to_points,
                                        expression: "coins[1].to_points",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      disabled: _vm.isDisable_enablecoins,
                                      max: "1000000 ",
                                    },
                                    domProps: { value: _vm.coins[1].to_points },
                                    on: {
                                      change: _vm.editcoin_exchange,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.coins[1],
                                          "to_points",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" points "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mx-20",
                                      attrs: { hidden: _vm.isDisable_coins },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-round btn-primary",
                                          attrs: {
                                            type: "button",
                                            disabled: !_vm.enable_quiz,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setupQuestion(2)
                                            },
                                          },
                                        },
                                        [_vm._v(" ? ")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [_vm._v("Step 3")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coins[2].from_coins,
                                        expression: "coins[2].from_coins",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      disabled: _vm.isDisable_enablecoins,
                                      max: "1000000 ",
                                    },
                                    domProps: {
                                      value: _vm.coins[2].from_coins,
                                    },
                                    on: {
                                      change: _vm.editcoin_exchange,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.coins[2],
                                          "from_coins",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [_vm._v("coins")]
                                  ),
                                  _c("i", {
                                    staticClass: "fas fa-arrow-right",
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coins[2].to_points,
                                        expression: "coins[2].to_points",
                                      },
                                    ],
                                    staticClass: "form-control mx-sm-3",
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      disabled: _vm.isDisable_enablecoins,
                                      max: "1000000 ",
                                    },
                                    domProps: { value: _vm.coins[2].to_points },
                                    on: {
                                      change: _vm.editcoin_exchange,
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.coins[2],
                                          "to_points",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" points "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mx-20",
                                      attrs: { hidden: _vm.isDisable_coins },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-round btn-primary",
                                          attrs: {
                                            type: "button",
                                            disabled: !_vm.enable_quiz,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setupQuestion(3)
                                            },
                                          },
                                        },
                                        [_vm._v(" ? ")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm.btneditCoins
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-round btn-primary",
                                    attrs: { type: "button" },
                                    on: { click: _vm.editcoin_exchange },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-edit",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" Edit "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._m(2),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "set-question",
            width: 900,
            height: "auto",
            clickToClose: false,
          },
        },
        [
          _c("QuestionModal", {
            attrs: { quizData: _vm.quizData, campaginData: _vm.campaign },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h5", [_vm._v("Campaign Name")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-footer" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
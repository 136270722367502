<template>
  <div class="site-menubar">
    <div class="site-menubar-body">
      <ul class="site-menu" data-plugin="menu">
        <li
          v-for="(menu, idx) in menus"
          :key="idx"
          :class="getMenuClass(menu)"
          @click.stop="handleClick"
        >
          <span v-if="menu.category">{{ menu.text }}</span>

          <router-link
            v-if="!menu.hasSub && !menu.category"
            :to="menu.link"
            class="animsition-link"
          >
            <i class="site-menu-icon" :class="menu.icon" aria-hidden="true"></i>
            <span class="site-menu-title">{{ menu.text }}</span>
          </router-link>

          <a v-if="menu.hasSub" href="javascript:void(0)">
            <i class="site-menu-icon" :class="menu.icon" aria-hidden="true"></i>
            <span class="site-menu-title">{{ menu.text }}</span>
            <span class="site-menu-arrow"></span>
          </a>
          <ul v-if="menu.hasSub" class="site-menu-sub">
            <li
              v-for="(sub, sidx) in menu.subs"
              :key="sidx"
              class="site-menu-item"
            >
              <router-link :to="sub.link" class="animsition-link sub-link">
                <span class="site-menu-title">{{ sub.text }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import { adminMenu, specialAdminMenu } from "@/config/menus.js";

export default {
  data() {
    return {
      menus: [],
    };
  },

  methods: {
    getMenuClass(menu) {
      let classes = menu.class;

      if (menu.hasSub) {
        const r = menu.subs.filter((m) => m.link == this.$route.path);
        if (r.length > 0) {
          classes = `${menu.class} open active`;
        }
      } else {
        if (menu.link == this.$route.path) {
          classes = `${menu.class} active`;
        }
      }

      return classes;
    },

    handleClick(e) {
      if ($(e.target).hasClass(`sub-link`)) {
        return;
      }

      if (
        $(e.currentTarget).hasClass(`has-sub`) &&
        $(e.target).closest(`.site-menu-sub`).length <= 0
      ) {
        $(e.currentTarget).toggleClass(`open`);
      }
    },
  },

  created() {
    const role = this.$store.state.auth.role;

    switch (role) {
      case "admin":
        this.menus = adminMenu;
        break;
      case "report":
        this.menus = specialAdminMenu;
        break;
      case "supervisor":
        this.menus = specialAdminMenu;
        break;
    }
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stamp
    ? _c(
        "div",
        { staticClass: "col-md-6", attrs: { id: "progressbar-scanner-image" } },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("div", { attrs: { "my-15px": "" } }, [
              _vm._m(0),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "image-preview" }, [
                  _vm.picture
                    ? _c("img", {
                        staticClass: "img img-fluid",
                        attrs: { src: _vm.picture },
                      })
                    : _c("div", { staticClass: "image-placeholder" }),
                ]),
                _c("input", {
                  ref: "scanner_picture",
                  staticClass: "form-control",
                  attrs: {
                    type: "file",
                    accept: "image/*",
                    name: "scanner_picture",
                    disabled: _vm.disabled,
                  },
                  on: { change: _vm.uploadImage },
                }),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mx-5" }, [
      _c("h4", [
        _vm._v(" Picture "),
        _c("span", { staticClass: "small light" }, [
          _vm._v(" (recommended size 335 x 50 pixels ) "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Budget Status</h1>

      <breadcrumb name="BUDGET_CONTROL_STATUS_LIST"></breadcrumb>

      <div class="page-header-right"></div>
      <div class="page-header-actions"></div>
    </div>

    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <label
              v-for="filter in filters"
              :key="filter.status"
              class="btn btn-round mr-5"
              :class="{
                'btn-primary': filter.active,
                'btn-default': !filter.active,
              }"
            >
              {{ filter.status | toUpperCase }}
              <input
                type="checkbox"
                v-model="filter.active"
                @change="fetch"
                style="visibility: hidden; width: 0px"
              />
            </label>
          </div>
          <div class="col-6"></div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-row-click="onRowClick"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { tableColumns } from "@/views/budget-control/status/model";

export default {
  name: "BudgetStatus",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      columns: tableColumns,

      filters: [
        {
          active: true,
          status: "ongoing",
        },
        {
          active: false,
          status: "paused",
        },
        {
          active: false,
          status: "end",
        },
        {
          active: false,
          status: "aborted",
        },
      ],
    };
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  methods: {
    getParams() {
      const status = this.filters.filter((o) => o.active).map((o) => o.status);

      const params = { status: status.toString() };

      return params;
    },

    async fetch() {
      const params = this.getParams();
      this.list = await budgetService.getBudgetStatus(params);
    },

    onRowClick(event) {
      this.$router
        .push({
          name: "budget-status-detail",
          params: { id: event.row.id },
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.panel {
  margin-top: 3px;
}
</style>

<template>
  <div class="page">
    <div class="page-main">
      <div class="page-header">
        <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
          <h1 class="page-title">Campaign</h1>
          <breadcrumb
            name="CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-lg-12 col-xl-10 col-xxl-8 offset-xxl-1 offset-xl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Campaign Information - {{ campaign.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label class="form-control-label">
                        <h5>Campaign Name</h5>
                      </label>
                      <input
                        type="text"
                        ref="campaignName"
                        maxlength="255"
                        :disabled="isDisablename"
                        class="form-control"
                        v-model="campaign.name"
                        @change="submitname"
                      />
                      <nameValidation :$v="$v" />
                    </div>
                    <div class="form-group col-md-3">
                      <div class="px-15">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-primary btn-round dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            style="text-transform: uppercase;"
                          >
                            {{ campaign.status }}
                          </button>
                          <div class="dropdown-menu" role="menu">
                            <a
                              v-if="campaign.status == 'draft'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatusscheduled"
                            >
                              <i
                                class="icon md-long-arrow-up"
                                aria-hidden="true"
                              ></i
                              >Publish
                            </a>
                            <a
                              v-if="campaign.status == 'draft'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('archived')"
                            >
                              <i class="icon md-archive" aria-hidden="true"></i
                              >Archive
                            </a>
                            <a
                              v-if="campaign.status == 'scheduled'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('draft')"
                            >
                              <i
                                class="icon md-long-arrow-down"
                                aria-hidden="true"
                              ></i
                              >Unpublished
                            </a>

                            <a
                              v-if="campaign.status == 'ongoing'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                              @click="setStatus('aborted')"
                            >
                              <i
                                class="icon md-border-color"
                                aria-hidden="true"
                              ></i
                              >Abort
                            </a>
                            <a
                              v-if="campaign.status == 'aborted'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                            >
                              <i class="icon md-archive" aria-hidden="true"></i
                              >Nothing
                            </a>
                            <a
                              v-if="campaign.status == 'end'"
                              class="dropdown-item"
                              href="javascript:void(0)"
                              role="menuitem"
                            >
                              <i class="icon md-archive" aria-hidden="true"></i
                              >Nothing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">Description (Option)</h5>
                    <div class="col-md-12">
                      <textarea
                        class="form-control"
                        rows="4"
                        maxlength="5000"
                        :disabled="isDisableDescription"
                        v-model="campaign.description"
                        @change="submit('description')"
                      ></textarea>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">Campaign Duration</h5>
                    <label class="col-md-1 form-control-label">Start</label>
                    <date-picker
                      v-model="start_date"
                      type="date"
                      :disabled="isDisableDuration"
                      value-type="format"
                      format="YYYY-MM-DD"
                      placeholder="Select date"
                      @change="submitDay"
                    ></date-picker>
                    <label class="form-control-label">No.days</label>
                    <input
                      type="number"
                      :disabled="isDisableDuration"
                      class="col-md-1"
                      min="1"
                      v-model="Duration"
                      @change="submitDay"
                      max="365"
                    />
                    <label class="col-md-4 form-control-label"
                      >End Date: {{ date_moment(end_date) }}</label
                    >
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">User Groups</h5>
                    <div class="col-md-12">
                      <Selectize
                        :disabled="isCanChangeDisabled"
                        multiple
                        ref="groupCode"
                        v-model="campaign.group_id_list"
                        :settings="groupSelectizeSetting"
                      >
                        <option
                          v-for="group in groups"
                          :key="group.id"
                          :value="group.id"
                          >{{ ` ${group.id} - ${group.group_code}` }}</option
                        >
                      </Selectize>
                      <gropValidation :group="groupre" v-if="showgrouppre" />
                    </div>
                  </div>
                  <hr />
                  <div class="form-inline row">
                    <h5 class="col-md-12">Immediate points</h5>
                    <div class="col-md-8">
                      <label class="switch">
                        <input
                          type="checkbox"
                          v-model="enable_immediate_points"
                          :disabled="!canSetImmediateCampaign"
                          @change="onImmediateCampaignChange"
                        />
                        <span class="slider round"></span>
                      </label>
                      <p class="mx-10 d-inline">
                        {{ enable_immediate_points ? "ON" : "OFF" }}
                      </p>
                    </div>
                    <div class="col-4">
                      <input
                        min="1"
                        max="100000"
                        type="number"
                        class="form-control"
                        ref="immediate_points"
                        v-model="immediate_points"
                        @blur="setImmediateCampaign"
                        :disabled="!enable_immediate_points"
                      />
                      <span> points</span>
                    </div>
                    <div class="col-12"><br /></div>
                    <div class="col-12">
                      <div class="row mb-2">
                        <label
                          class="col-md-2 form-control-label text-left d-block"
                        >
                          Text Area 1
                        </label>
                        <input
                          v-model="immediate_text1"
                          type="text"
                          class="form-control col-md-6"
                          @blur="setImmediateCampaign"
                          :disabled="!enable_immediate_points"
                        />
                      </div>
                      <div class="row mb-2">
                        <label
                          class="col-md-2 form-control-label text-left d-block"
                        >
                          URL Link
                        </label>
                        <input
                          v-model="immediate_link"
                          type="text"
                          class="form-control col-md-6"
                          @blur="setImmediateCampaign"
                          :disabled="!enable_immediate_points"
                        />
                      </div>
                      <div class="row mb-2">
                        <label
                          class="col-md-2 form-control-label text-left d-block"
                          >Text Area 2</label
                        >
                        <input
                          v-model="immediate_text2"
                          type="text"
                          class="form-control col-md-6"
                          @blur="setImmediateCampaign"
                          :disabled="!enable_immediate_points"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-12">Special Dates</h5>
                    <div
                      class="form-inline"
                      v-for="(special, index) in special_dates"
                      :key="index"
                    >
                      <div class="form-group">
                        <label class="form-control-label" style="width: 80px;"
                          >No.{{ index + 1 }}</label
                        >
                        <select
                          v-model="special.day"
                          :disabled="isDisableSpecial"
                          class="form-control-label"
                          @change="editSpecial"
                          style="width: 100px;"
                        >
                          <option
                            v-for="day in listDay"
                            :key="day"
                            :value="day"
                            :hidden="checkrepeat(day)"
                            :disabled="checkrepeat(day)"
                            >{{ day }}</option
                          >
                        </select>
                        Day
                        <label class="form-control-label" style="width: 280px;">
                          {{ date_moment(calend(start_date, special.day)) }}
                        </label>
                        <input
                          type="number"
                          :disabled="isDisableSpecial"
                          min="1"
                          max="1000000"
                          v-model="special.special_points"
                          class="form-control mx-sm-3"
                          @change="editSpecial"
                        />
                        points
                        <button
                          type="button"
                          class="btn btn-round btn-default mx-10"
                          v-on:click="removelist(index)"
                          :disabled="isDisableSpecial"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn-round btn-default mx-10"
                    v-on:click="addspecialday"
                    type="button"
                    :disabled="isDisableSpecial"
                  >
                    <i class="fa fa-plus"></i>
                    Add
                  </button>
                  <hr />
                  <div class="form-group row">
                    <h5 class="col-md-8">Coin Exchange</h5>
                    <h5 class="col-md-4" :class="{ over: maximum_coins < 0 }">
                      Max remaining coins {{ maximum_coins }}
                    </h5>
                    <div class="col-md-8">
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="enable_coins"
                          @click="enable_coins = !enable_coins"
                          :disabled="isDisable_enablecoins"
                          @change="chage_enable_coins"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group" :hidden="isDisable_coins">
                        <div class="checkbox-custom checkbox-primary">
                          <input
                            type="checkbox"
                            :checked="enable_quiz"
                            id="enable_quiz_checkbox"
                            @change="onEnableQuizChange"
                            :disabled="isDisable_enablecoins"
                            @click="enable_quiz = !enable_quiz"
                          />
                          <label for="enable_quiz_checkbox">Enable Quiz</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div>
                        <div class="form-inline" :hidden="isDisable_coins">
                          <div class="form-group mb-10">
                            <label class="form-control-label">Step 1</label>
                            <input
                              type="number"
                              :disabled="isDisable_enablecoins"
                              class="form-control mx-sm-3"
                              min="1"
                              v-model="coins[0].from_coins"
                              @change="editcoin_exchange"
                              max="1000000 "
                            />
                            <label class="form-control-label">coins</label>
                            <i class="fas fa-arrow-right"></i>
                            <input
                              type="number"
                              :disabled="isDisable_enablecoins"
                              min="1"
                              v-model="coins[0].to_points"
                              class="form-control mx-sm-3"
                              @change="editcoin_exchange"
                              max="1000000 "
                            />
                            points
                            <div class="mx-20" :hidden="isDisable_coins">
                              <button
                                type="button"
                                :disabled="!enable_quiz"
                                @click="setupQuestion(1)"
                                class="btn btn-round btn-primary"
                              >
                                ?
                              </button>
                            </div>
                          </div>
                          <div class="form-group mb-10">
                            <label class="form-control-label">Step 2</label>
                            <input
                              type="number"
                              class="form-control mx-sm-3"
                              :disabled="isDisable_enablecoins"
                              min="1"
                              v-model="coins[1].from_coins"
                              @change="editcoin_exchange"
                              max="1000000 "
                            />
                            <label class="form-control-label">coins</label>
                            <i class="fas fa-arrow-right"></i>
                            <input
                              type="number"
                              min="1"
                              v-model="coins[1].to_points"
                              :disabled="isDisable_enablecoins"
                              class="form-control mx-sm-3"
                              @change="editcoin_exchange"
                              max="1000000 "
                            />
                            points
                            <div class="mx-20" :hidden="isDisable_coins">
                              <button
                                type="button"
                                :disabled="!enable_quiz"
                                @click="setupQuestion(2)"
                                class="btn btn-round btn-primary"
                              >
                                ?
                              </button>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="form-control-label">Step 3</label>
                            <input
                              type="number"
                              class="form-control mx-sm-3"
                              min="1"
                              v-model="coins[2].from_coins"
                              :disabled="isDisable_enablecoins"
                              @change="editcoin_exchange"
                              max="1000000 "
                            />
                            <label class="form-control-label">coins</label>
                            <i class="fas fa-arrow-right"></i>
                            <input
                              type="number"
                              min="1"
                              v-model="coins[2].to_points"
                              :disabled="isDisable_enablecoins"
                              class="form-control mx-sm-3"
                              @change="editcoin_exchange"
                              max="1000000 "
                            />
                            points
                            <div class="mx-20" :hidden="isDisable_coins">
                              <button
                                type="button"
                                :disabled="!enable_quiz"
                                @click="setupQuestion(3)"
                                class="btn btn-round btn-primary"
                              >
                                ?
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="btn btn-round btn-primary"
                          @click="editcoin_exchange"
                          v-if="btneditCoins"
                        >
                          <i class="fas fa-edit" aria-hidden="true"></i> Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="set-question" :width="900" height="auto" :clickToClose="false">
      <QuestionModal :quizData="quizData" :campaginData="campaign" />
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { responseErrorMapping } from "./model/error";
import nameValidation from "./validation/name";
import gropValidation from "./validation/grop";
import QuestionModal from "@/views/campaign/QuestionModal.vue";
import { FormCreateValidateRule as validateRules } from "./validation";
import { dailyloginService, userGroupService } from "@/api/client";
import Selectize from "vue2-selectize";
export default {
  name: "CampaignEdit",
  data() {
    return {
      campaign: [],
      special_dates: [],
      success: true,
      showgrouppre: false,
      btneditCoins: false,
      chagevalue: false,
      addspecial: [],
      campaignOld: [],
      ask: false,
      groupre: [],
      maximum_coins: null,
      isCanChangeDisabled: true,
      isEditorDisabled: false,
      start_date: null,
      submit_dat: true,
      isDisable_enablecoins: true,
      isDisable_coins: true,
      isDisablename: true,
      isDisableDescription: true,
      isDisableDuration: true,
      isDisableGroups: true,
      isDisableSpecial: true,
      end_date: null,
      Duration: null,
      oldDuration: null,
      coins: [
        {
          from_coins: null,
          to_points: null,
        },
        {
          from_coins: null,
          to_points: null,
        },
        {
          from_coins: null,
          to_points: null,
        },
      ],
      groups: [],
      listDay: [],
      alreadyselect: [],
      settingsSpecial: {
        selected: [],
        onChange: this.editSpecial,
      },
      groupSelectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        onChange: this.setGroupIdList,
      },
      quizData: null,
      enable_quiz: null,
      enable_coins: null,
      immediate_text1: "อย่าลืมกดเข้ามารับคะแนนใหม่วันพรุ่งนี้",
      immediate_link: "",
      immediate_text2: `ร่วมสนุกได้ตั้งแต่วันที่...`,
      immediate_points: 1,
      enable_immediate_points: false,
      canSetImmediateCampaign: false,
    };
  },
  validations: { campaign: validateRules },
  watch: {
    start_date() {
      if (this.start_date != null) {
        this.end_date = this.calend(this.start_date, this.Duration);
      }
    },
    Duration() {
      if (this.Duration != null && this.Duration != "") {
        this.end_date = this.calend(this.start_date, this.Duration);
        this.maximum_coins_func();
      }
    },
  },
  
  components: {
    Selectize,
    QuestionModal,
    gropValidation,
    nameValidation,
  },

  async created() {
    this.id = await this.$route.params.id;
    this.fetchcampaign();
  },

  methods: {
    async setupQuestion(idx) {
      const campaignId = this.campaign.id;
      this.quizData = await dailyloginService.getQuizByStep(campaignId, idx);
      this.$modal.show(`set-question`);
    },

    async repeatedlygroup(repeatedly) {
      var overlap = repeatedly
        .replace(/Error:|period overlap with campaigns/g, "")
        .replace("period overlap with campaigns")
        .trim();
      var id = overlap.split(",");
      var lengthgroupselect = this.groupSelectizeSetting.selected.length;
      var listcam = [];
      var re = [];
      for (var i = 0; i < id.length; i++) {
        listcam[i] = await dailyloginService.getById(id[i]);
      }
      for (var j = 0; j < lengthgroupselect; j++) {
        for (i = 0; i < id.length; i++) {
          if (
            listcam[i].group_id_list.includes(
              this.groupSelectizeSetting.selected[j]
            )
          ) {
            re.push(this.groupSelectizeSetting.selected[j]);
            break;
          }
        }
      }
      this.groupre = re;
      this.showgrouppre = true;
    },
    async setStatusscheduled() {
      this.fetchcampaign();
      if (new Date(this.start_date).getTime() - new Date().getTime() < 0)
        return this.alertForm("Start Date ต้องเป็นวันข้างหน้า ");
      this.setStatus("scheduled");
    },
    chage_enable_coins() {
      if (!this.enable_coins) {
        this.isDisable_coins = true;
        this.campaign.enable_coin_exchange = 0;
        this.btneditCoins = false;
      } else {
        this.isDisable_coins = false;
        this.campaign.enable_coin_exchange = 1;
        this.enable_quiz = this.campaign.enable_quiz;
      }
      this.maximum_coins_func();
      this.submit("enable_coin_exchange");
    },
    onEnableQuizChange() {
      if (this.enable_quiz === true) {
        this.campaign.enable_quiz = 1;
      } else if (this.enable_quiz === false) {
        this.campaign.enable_quiz = 0;
      }
      this.submit("enable_quiz");
    },
    editSpecial() {
      this.selectspecialday();
      for (var i = 0; i < this.campaign.special_date_list.length; i++) {
        if (
          this.checknull(this.campaign.special_date_list[i].day) ||
          this.checknull(this.campaign.special_date_list[i].special_points)
        ) {
          return;
        }
        if (
          this.campaign.special_date_list[i].special_points <= 0 ||
          this.campaign.special_date_list[i].special_points > 1000000
        ) {
          this.campaign.special_date_list[i].special_points = "";
          return this.alertForm(
            "No." +
              (i + 1) +
              " เลือกใส่  points ในแต่ละ special date ได้ ตั้งแต่ 1 - 1,000,000"
          );
        }
        if (
          this.campaign.special_date_list[i].day < 0 ||
          this.campaign.special_date_list[i].day % 1 != 0
        ) {
          this.campaign.special_date_list[i].day = "";
          return this.alertForm(
            "เลือกใส่ Day No." + (i + 1) + "จำนวนเต็ม 1 ถึง " + this.Duration
          );
        }
      }
      this.submit("special_date_list");
      if (!this.submit_dat) {
        this.submitDay();
        this.submit_dat = true;
      }
      this.success = true;
    },
    editcoin_exchange() {
      this.maximum_coins_func();
      if (!this.isDisable_coins) {
        var check = true;
        check = this.coins_ex();
        if (!check) return;
        var list = this.coins.filter(
          (p) => !this.checknull(p.from_coins) && !this.checknull(p.to_points)
        );
        this.campaign.coin_exchange_list = list;
        this.submit("coin_exchange_list");
      }
    },
    async setGroupIdList(ids) {
      this.campaign.group_id_list = ids;
      this.submit("group_id_list");
    },
    coins_ex() {
      var check = true;
      for (var j = 0; j < this.coins.length; j++) {
        if (
          !this.checknull(this.coins[j].from_coins) &&
          this.checknull(this.coins[j].to_points)
        ) {
          return (check = false);
        }
        if (
          this.checknull(this.coins[j].from_coins) &&
          !this.checknull(this.coins[j].to_points)
        ) {
          return (check = false);
        }
        if (
          !(
            this.checknull(this.coins[j].from_coins) ||
            this.checknull(this.coins[j].to_points)
          )
        ) {
          if (
            this.coins[j].from_coins < 0 ||
            this.coins[j].from_coins > 1000000
          ) {
            this.coins[j].from_coins = "";
            this.alertForm("Coin Exchange Coins มีค่าตั้งแต่ 0-1,000,000");
            return (check = false);
          }
          if (
            this.coins[j].to_points < 0 ||
            this.coins[j].to_points > 1000000
          ) {
            this.coins[j].to_points = "";
            this.alertForm("Coin Exchange Points มีค่าตั้งแต่ 0-1,000,000");
            return (check = false);
          }
        }
      }
      return check;
    },
    async submitDay() {
      this.ask = false;
      var remove = false;
      if (this.start_date <= new Date().toJSON().slice(0, 10)) {
        this.$dialogs.alert("ไม่สามารถ set ให้มีค่าที่เป็นอดีตได้");
        this.start_date = this.campaign.start_date;
        this.Duration = this.oldDuration;
        return;
      }
      this.maximum_coins_func();
      if (
        this.start_date == null ||
        this.end_date == null ||
        this.Duration == null ||
        this.Duration == ""
      )
        return;
      if (this.Duration % 1 != 0 && this.Duration > 0) {
        this.Duration = this.oldDuration;
        return this.alertForm("set ค่าเป็นจำนวนเต็มเท่านั้น ");
      }
      if (this.Duration <= 0 || this.Duration > 365) {
        this.Duration = this.oldDuration;
        return this.alertForm("สามารถ set ตั้งแต่ 1 - 365 เท่านั้น");
      }
      for (var j = this.campaign.special_date_list.length - 1; j >= 0; j--) {
        if (this.campaign.special_date_list[j].day > this.Duration) {
          if (!this.ask) {
            remove = await this.$dialogs
              .confirm("มี Specail Date เกิน Duration ต้องการจะลบใช่ไหม")
              .then(function (dialogs) {
                if (dialogs.ok == undefined) {
                  dialogs.ok = false;
                }
                return dialogs.ok;
              });
            this.ask = true;
          }
          if (!remove) {
            this.Duration = this.oldDuration;
            return;
          }

          this.campaign.special_date_list.splice(j, 1);
        }
      }
      if (remove) {
        this.success = false;
        this.maximum_coins_func();
        this.editSpecial();
        if (!this.success) {
          this.fecthDay();
          return (this.submit_dat = false);
        }
      }
      this.campaign.start_date = this.start_date;
      this.campaign.end_date = this.end_date;
      this.submit("start_date");
      this.submit("end_date");
      this.fecthDay();
      this.oldDuration =
        (moment(this.end_date) - moment(this.start_date)) /
          (24 * 60 * 60 * 1000) +
        1;
    },
    checknull(value) {
      if (value == "") {
        return true;
      } else if (value == null) {
        return true;
      } else if (value == undefined) {
        return true;
      } else {
        return false;
      }
    },
    date_moment(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },
    maximum_coins_func() {
      if (!this.enable_coins) {
        this.maximum_coins =
          this.Duration - this.campaign.special_date_list.length;
      } else {
        this.maximum_coins =
          this.Duration -
          this.campaign.special_date_list.length -
          this.coins[0].from_coins -
          this.coins[1].from_coins -
          this.coins[2].from_coins;
      }
    },
    fecthcoins(num) {
      for (var i = 0; i < 3; i++) {
        if (i + 1 <= num) {
          this.coins[i] = this.campaign.coin_exchange_list[i];
        }
      }
    },
    async alertForm(Text) {
      await this.$dialogs.alert(Text);
    },
    removelist(index) {
      this.campaign.special_date_list.splice(index, 1);
      this.maximum_coins_func();
      this.editSpecial();
    },
    addspecialday() {
      if (this.campaign.special_date_list.length >= this.Duration)
        return this.alertForm(
          "Special Date ได้มากที่สุดเท่ากับ จำนวนวันใน campaign (duration)"
        );
      this.campaign.special_date_list.push({
        day: null,
        special_points: null,
      });
      this.maximum_coins_func();
      this.chagevalue = true;
    },
    async setStatus(status) {
      try {
        const form = new FormData();
        form.set("status", status);
        const body = JSON.stringify(Object.fromEntries(form));
        await dailyloginService.editDailylogin(
          this.campaign.id,
          JSON.parse(body)
        );
        this.$dialogs.alert("Change status success");
        this.showgrouppre = false;
        await this.fetchcampaign();
      } catch (error) {
        console.error(error);
        if (error.response) {
          this.$dialogs.alert(responseErrorMapping(error.response.data));
          this.repeatedlygroup(error.response.data);
        } else {
          this.$notify(error);
        }
      }
    },
    checkCanSetImmediateCampaign() {
      const status = this.campaign.status.toUpperCase();
      this.canSetImmediateCampaign = status == "DRAFT";
    },
    async onImmediateCampaignChange() {
      if (this.enable_immediate_points) {
        this.isDisableSpecial = true;
        this.isDisable_enablecoins = true;
        this.$nextTick(() => this.$refs.immediate_points.focus());
        return;
      }

      if (
        !this.enable_immediate_points &&
        this.campaign.status.toUpperCase() == "DRAFT"
      ) {
        this.isDisableSpecial = false;
        this.isDisable_enablecoins = false;
      }

      await this.setImmediateCampaign();
    },
    async setImmediateCampaign() {
      if (
        this.immediate_link == this.campaign.link &&
        this.immediate_text1 == this.campaign.text1 &&
        this.immediate_text2 == this.campaign.text2 &&
        this.immediate_points == this.campaign.immediate_points &&
        this.enable_immediate_points ==
          (this.campaign.enable_immediate_points == 1)
      ) {
        return;
      }

      await dailyloginService.editDailylogin(this.campaign.id, {
        link: this.immediate_link,
        text1: this.immediate_text1,
        text2: this.immediate_text2,
        immediate_points: +this.immediate_points,
        enable_immediate_points: this.enable_immediate_points ? 1 : 0,
      });

      this.campaign.link = this.immediate_link;
      this.campaign.text1 = this.immediate_text1;
      this.campaign.text2 = this.immediate_text2;
      this.campaign.immediate_points = this.immediate_points;
      this.campaign.enable_immediate_points = this.enable_immediate_points;

      this.$notify("Campaign Update");
    },
    goBack() {
      this.$router.go(-1);
    },
    async fetchcampaign() {
      this.campaign = await dailyloginService.getById(this.id);
      this.campaignOld = await dailyloginService.getById(this.id);
      this.start_date = await this.campaign.start_date;
      this.Duration = await this.caltime(
        this.campaign.end_date,
        this.campaign.start_date
      );
      this.oldDuration = this.Duration;
      this.groupSelectizeSetting.selected = this.campaign.group_id_list;
      this.groups = await userGroupService.getUserGroups();
      this.special_dates = await this.campaign.special_date_list;
      this.fecthcoins(this.campaign.coin_exchange_list.length);
      this.enable_coins =
        (await this.campaign.enable_coin_exchange) == 0 ? false : true;
      this.enable_quiz = this.campaign.enable_quiz == 0 ? false : true;
      this.maximum_coins_func();
      this.isDisable_coins = this.enable_coins == false ? true : false;
      this.disbleDRAFT(!(this.campaign.status.toUpperCase() == "DRAFT"));
      this.disbleSCHEDULED(
        !(
          this.campaign.status.toUpperCase() == "SCHEDULED" ||
          this.campaign.status.toUpperCase() == "DRAFT"
        )
      );
      this.immediate_points = this.campaign.immediate_points;
      this.enable_immediate_points =
        +this.campaign.enable_immediate_points === 1;
      this.isDisableSpecial =
        this.enable_immediate_points ||
        this.campaign.status.toUpperCase() != "DRAFT";
      this.isDisable_enablecoins =
        this.enable_immediate_points ||
        this.campaign.status.toUpperCase() != "DRAFT";

      this.immediate_link = this.campaign.link || this.immediate_link;
      this.immediate_text1 = this.campaign.text1 || this.immediate_text1;
      this.immediate_text2 = this.campaign.text2 || this.immediate_text2;

      this.checkCanSetImmediateCampaign();
      this.groups = await this.groups.filter((p) => p.status == "active");
      this.fecthDay();
      this.selectspecialday();
    },
    fecthDay() {
      this.listDay = [];
      for (var i = 1; i <= this.Duration; i++) {
        this.listDay.push(i);
      }
    },
    selectspecialday() {
      this.alreadyselect = [];
      for (var i in this.campaign.special_date_list) {
        this.alreadyselect.push(this.campaign.special_date_list[i].day);
      }
    },
    checkrepeat(day) {
      var check = this.alreadyselect.includes(day);
      return check;
    },
    disbleDRAFT(bool) {
      this.isDisable_enablecoins = bool;
      this.isDisablename = bool;
      this.isCanChangeDisabled = bool;
      this.isDisableDuration = bool;
      this.isDisableGroups = bool;
      this.isDisableSpecial = bool;
    },
    disbleSCHEDULED(bool) {
      this.isDisablename = bool;
      this.isDisableDescription = bool;
    },
    caltime(to, from) {
      var dur =
        new Date(to).getTime() - new Date(from).getTime() + 24 * 60 * 60 * 1000;
      return dur / (24 * 60 * 60 * 1000);
    },
    calend(from, dur) {
      var end = moment(from).add(dur - 1, "days");
      return end;
    },
    async submitname() {
      if (this.$v.campaign.name.$invalid) {
        this.alertForm("กรุณากรอก Campaign Name ให้ถูกต้อง");
        this.campaign.name = this.campaignOld.name;
        this.$refs.campaignName.focus();
        return;
      }
      this.submit("name");
    },
    async submit(field) {
      try {
        const form = {};
        form[field] = this.campaign[field];

        await dailyloginService.editDailylogin(this.campaign.id, form);
        this.$notify(
          `${field.charAt(0).toUpperCase() + field.slice(1)}`.replace(
            /_/gi,
            " "
          ) + " updated."
        );
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$notify("Campaign Update error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  margin-left: 2%;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch input[disabled="disabled"] + .slider {
  background-color: #cccccc;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.over {
  color: red;
}
</style>

<template>
  <div class="page" v-if="campaign">
    <div class="page-header">
      <h1 class="page-title">Progress Bar</h1>
      <breadcrumb
        name="PROGRESS_BAR_UPDATE_DETAIL"
        :withLast.sync="campaign.name"
      ></breadcrumb>
      <div class="page-header-right"></div>
      <div class="page-header-actions"></div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <ProgressInfo :campaign="campaign" />
            
            <BarcodeTable
              v-if="campaign.type == 'barcode'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <StampTable
              v-if="campaign.type == 'stamp'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <TeamBarcodeTable
              v-if="campaign.type == 'team'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <MonthlyTable
              v-if="campaign.type == 'monthly'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <MonthlyshortTable
              v-if="campaign.type == 'monthlyshort'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <WeeklyTable
              v-if="campaign.type == 'weekly'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <TaskTable
              v-if="campaign.type == 'task'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
            <LeaderBoardTable
              v-if="campaign.type == 'leaderboard'"
              :campaign="campaign"
              :columnConfig="columnConfig"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBarService } from "@/api/client";
import ProgressInfo from "@/views/progressbar/ProgressUpdate/ProgressInfo.vue";

import TaskTable from "@/views/progressbar/ProgressUpdate/TaskTable.vue";
import StampTable from "@/views/progressbar/ProgressUpdate/StampTable.vue";
import WeeklyTable from "@/views/progressbar/ProgressUpdate/WeeklyTable.vue";
import BarcodeTable from "@/views/progressbar/ProgressUpdate/BarcodeTable.vue";
import MonthlyTable from "@/views/progressbar/ProgressUpdate/MonthlyTable.vue";
import LeaderBoardTable from "@/views/progressbar/ProgressUpdate/LeaderBoardTable.vue";
import TeamBarcodeTable from "@/views/progressbar/ProgressUpdate/TeamBarcodeTable.vue";
import MonthlyshortTable from "@/views/progressbar/ProgressUpdate/MonthlyshortTable.vue";

export default {
  name: "ProgressUpdateDetail",

  components: {
    ProgressInfo,
    TaskTable,
    StampTable,
    WeeklyTable,
    BarcodeTable,
    MonthlyTable,
    LeaderBoardTable,
    TeamBarcodeTable,
    MonthlyshortTable
  },

  data() {
    return {
      campaign: {},
      columnConfig: {},
    };
  },

  methods: {
    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await progressBarService.getById(id);
    },

    async fetchColumnConfig() {
      const id = this.$route.params.id;
      this.columnConfig = await progressBarService.getColumnConfig(id);
    },
  },

  async created() {
    await this.fetchCampaign();
    await this.fetchColumnConfig();
  },
};
</script>

<style scoped>
.page-header-right {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 30px;
}
.page-header-actions {
  margin-top: 40px;
  position: absolute;
  bottom: 20px;
}
.panel {
  margin-top: 3px;
}
.show_entries {
  margin-top: 20px;
}
</style>

<template>
  <div class="page" v-if="gameConfig">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Game Instance</h1>
          <breadcrumb
            name="GAME_EDIT"
            :withLast.sync="gameConfig.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Game Instance Information - {{ gameConfig.id }}
                </h3>

                <div class="panel-actions panel-actions-keep"></div>

                <div class="container px-30">
                  <div class="row">
                    <div class="col-6">
                      <h4>Sunset Drive</h4>
                    </div>
                    <div class="col-6">
                      <h4>
                        User Group:
                        <span class="grey-700">
                          {{ group_code || gameConfig.group_code }}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="gameConfig">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <GameInstanceName
                        :gameConfig="gameConfig"
                        @updated="getGameConfig"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :gameConfig="gameConfig"
                        @updated="getGameConfig"
                      />
                    </div>
                  </div>
                  <hr />

                  <GameInstanceDuration
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <GameConfiguration
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <h4>Game Specific Setting - Sunset</h4>

                  <GameLogo :gameConfig="gameConfig" @updated="getGameConfig" />

                  <SplashScreen
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />

                  <StartScreen
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />

                  <BackgroundImage
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <CarPicture
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <SideObjectPicture
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <HowtoBackground
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <GameStory
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />

                  <br />
                  <GameDuration
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <ObjectConifg
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <ObtaclesConfig
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <BillboardConifg
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />

                  <RedirectURL
                    :gameConfig="gameConfig"
                    @updated="getGameConfig"
                  />
                  <hr />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameService } from "@/api/client";

import GameLogo from "./components/GameLogo.vue";
import GameStory from "./components/GameStory.vue";
import CarPicture from "./components/CarPicture.vue";
import RedirectURL from "./components/RedirectURL.vue";
import GameDuration from "./components/GameDuration.vue";
import StartScreen from "./components/StartScreen.vue";
import SplashScreen from "./components/SplashScreen.vue";
import ObjectConifg from "./components/ObjectConifg.vue";
import ObtaclesConfig from "./components/ObtaclesConfig.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import GameInstanceName from "./components/InstanceName.vue";
import GameInstanceDuration from "./components/Duration.vue";
import BackgroundImage from "./components/BackgroundImage.vue";
import BillboardConifg from "./components/BillboardConifg.vue";
import HowtoBackground from "./components/HowtoBackground.vue";
import GameConfiguration from "./components/Configuration.vue";
import SideObjectPicture from "./components/SideObjectPicture.vue";

export default {
  name: "SunsetDrive",

  props: ["group_code"],

  data() {
    return {
      gameConfig: null,
    };
  },

  components: {
    GameLogo,
    GameStory,
    CarPicture,
    RedirectURL,
    StartScreen,
    SplashScreen,
    GameDuration,
    ObjectConifg,
    ObtaclesConfig,
    StatusDropdown,
    BackgroundImage,
    BillboardConifg,
    HowtoBackground,
    GameInstanceName,
    GameConfiguration,
    SideObjectPicture,
    GameInstanceDuration,
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async getGameConfig() {
      const id = this.$route.params.id;
      this.gameConfig = await gameService.getById(id);
    },
  },

  async created() {
    await this.getGameConfig();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}
</style>

import { render, staticRenderFns } from "./StartScreen.vue?vue&type=template&id=de6c3764&scoped=true&"
import script from "./StartScreen.vue?vue&type=script&lang=js&"
export * from "./StartScreen.vue?vue&type=script&lang=js&"
import style0 from "./StartScreen.vue?vue&type=style&index=0&id=de6c3764&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de6c3764",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de6c3764')) {
      api.createRecord('de6c3764', component.options)
    } else {
      api.reload('de6c3764', component.options)
    }
    module.hot.accept("./StartScreen.vue?vue&type=template&id=de6c3764&scoped=true&", function () {
      api.rerender('de6c3764', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/game/sunset-drive/components/StartScreen.vue"
export default component.exports
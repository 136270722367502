var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
          _vm._v(" Start "),
        ]),
        _c(
          "div",
          { staticClass: "d-inline mx-10" },
          [
            _c("DatePicker", {
              ref: "start_date",
              attrs: {
                type: "date",
                "width-150px": "",
                placeholder: "n/a",
                format: "YYYY-MM-DD",
                "value-type": "YYYY-MM-DD",
                disabled: _vm.disabled,
              },
              on: { change: _vm.update },
              model: {
                value: _vm.campaign.start_date,
                callback: function ($$v) {
                  _vm.$set(_vm.campaign, "start_date", $$v)
                },
                expression: "campaign.start_date",
              },
            }),
          ],
          1
        ),
        _c("label", { staticClass: "d-inline mx-10 form-control-label" }, [
          _vm._v(" End "),
        ]),
        _c(
          "div",
          { staticClass: "d-inline mx-10" },
          [
            _c("DatePicker", {
              ref: "end_date",
              attrs: {
                type: "date",
                "width-150px": "",
                placeholder: "n/a",
                format: "YYYY-MM-DD",
                "value-type": "YYYY-MM-DD",
                disabled: _vm.disabled,
              },
              on: { change: _vm.update },
              model: {
                value: _vm.campaign.end_date,
                callback: function ($$v) {
                  _vm.$set(_vm.campaign, "end_date", $$v)
                },
                expression: "campaign.end_date",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Campaign Duration")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }